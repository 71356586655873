// @flow
// $FlowFixMe
import { Column, Text, Table, Dropdown, Icon, Menu } from '@8base/boost';
import React from 'react';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FolderOpen } from '@material-ui/icons';
// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeProjectListItemProps = {
  projectsList?: Object[],
  onOpenProjectClick: OpenWorkspaceHandler,
  createButton?: React$Node,
  organization: any,
};

const ProjectImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 26px;
  margin-right: 20px;
  height: 26px;
  border-radius: 40px;
`;

const StyledMyProjectsIcon = styled(FolderOpen)`
  flex-shrink: 0;
  object-fit: cover;
  width: 26px;
  margin-right: 20px;
  height: 26px;
  border-radius: 28px;
  border: 1px solid #e8eff5;
  background-color: #abb1b8;
  color: white;
  font-size: 26px !important;
  padding: 2px;
`;

const copyWorkspaceIdToClipboard = (workspace) => {
  const el = document.createElement('textarea');
  el.value = workspace.id;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  if (!!document.body) {
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    // $FlowFixMe
    document.body.removeChild(el);

    toast.success('Project ID successfully copied to the clipboard');
  }
};

const listTable = (myProjectsList, onOpenWorkspaceClick, organization) => {
  const openProject = (event, project) => {
    if (!event.metaKey && !event.ctrlKey) {
      event.preventDefault();
      onOpenWorkspaceClick({ projectId: project.id });
    }
  };

  return (
    <Table>
      <Table.Header columns="2fr 1.5fr 1fr 1fr 0.5fr">
        <Table.HeaderCell>NAME</Table.HeaderCell>
        <Table.HeaderCell>PLAN</Table.HeaderCell>
        <Table.HeaderCell>CREATED ON</Table.HeaderCell>
        <Table.HeaderCell>TEAM MEMBERS</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Header>
      <Table.Body
        data={ myProjectsList }
        css={ css`
          min-height: 100px;
        ` }
      >
        { (project) => (
          <Table.BodyRow
            css={ css`
              cursor: pointer;
            ` }
            columns="2fr 1.5fr 1fr 1fr 0.5fr"
            key={ project.id }
          >
            <Table.BodyCell onClick={ (event) => openProject(event, project) }>
              { project.image && project.image.downloadUrl && (
                <ProjectImage
                  src={ project.image && project.image.downloadUrl }
                />
              ) }
              <If condition={ !project.image && !project.image?.downloadUrl }>
                <StyledMyProjectsIcon />
              </If>

              <Text
                css={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontWeight: '600',
                  width: '20ch',
                }}
              >
                { project.name }
              </Text>
            </Table.BodyCell>
            <Table.BodyCell onClick={ (event) => openProject(event, project) }>
              { project?.plan?.name }
            </Table.BodyCell>
            <Table.BodyCell onClick={ (event) => openProject(event, project) }>
              { DateTime.fromISO(project?.createdAt).toFormat('LLL d, yyyy') }
            </Table.BodyCell>
            <Table.BodyCell onClick={ (event) => openProject(event, project) }>
              { project?.projectUsers?.count }
            </Table.BodyCell>
            <Table.BodyCell>
              <Dropdown defaultOpen={ false }>
                <Dropdown.Head>
                  <Icon name="More" color="GRAY_30" />
                </Dropdown.Head>
                <Dropdown.Body pin="right">
                  <Menu>
                    <Menu.Item
                      onClick={ () =>
                        onOpenWorkspaceClick({ projectId: project.id }) }
                    >
                      Open
                    </Menu.Item>
                    <Menu.Item
                      onClick={ () => copyWorkspaceIdToClipboard(project) }
                    >
                      Copy Project ID to Clipboard
                    </Menu.Item>
                  </Menu>
                </Dropdown.Body>
              </Dropdown>
            </Table.BodyCell>
          </Table.BodyRow>
        ) }
      </Table.Body>
    </Table>
  );
};

export const DeveloperHomeProjectListItem = ({
  projectsList = [],
  onOpenProjectClick,
  createButton,
  organization,
}: DeveloperHomeProjectListItemProps) => {
  return (
    <Column gap="xl" stretch alignItems="stretch">
      <Column gap="lg" stretch alignItems="stretch">
        { createButton }
        { listTable(projectsList, onOpenProjectClick, organization) }
      </Column>
    </Column>
  );
};
