// @flow
import fp from 'lodash/fp';
import fpUtils from 'utils/fp';
import { createSelector } from 'reselect';
import { type FieldTypes } from 'store/dataBuilder/dataBuilder.constants';


type TableRef = $Shape<{
  id: string,
  name: string,
  displayName: string,
  isSystem: boolean,
  fields: *,
}>

type FileFieldTypeAttributes = $Shape<{
  id: string,
  format: string,
  maxSize: number,
  typeRestrictions: string[],
}>

type TextFieldTypeAttributes = $Shape<{
  format: string,
  fieldSize: number,
}>

type NumberFieldTypeAttributes = $Shape<{
  id: string,
  format: string,
  precision: number,
  currency: string,
  minValue: number,
  maxValue: number,
  isBigInt: boolean,
}>

type SwitchFieldTypeAttributes = $Shape<{
  id: string,
  format: string,
  listOptions: string[]
}>

type DateFieldTypeAttributes = $Shape<{
  id: string,
  format: string,
}>

type FieldTypeAttributes =
    FileFieldTypeAttributes
  & TextFieldTypeAttributes
  & NumberFieldTypeAttributes
  & DateFieldTypeAttributes
  & SwitchFieldTypeAttributes
  | Object

export type ApolloTableField = $Shape<{|
  id: string,
  table: TableRef,
  name: string,
  displayName: string,
  description: string,
  fieldType: FieldTypes, // TODO: move types to the current file
  fieldTypeAttributes: FieldTypeAttributes,
  isSystem: boolean,
  isList: boolean,
  isMeta: boolean,
  isRequired: boolean,
  isUnique: ?boolean,
  defaultValue: ?string,
  relation: $Shape<{
    id: string,
    refTable: TableRef,
    relationTableName: string,
    relationFieldName: string,
    refTable: TableRef,
    refFieldName: string,
    refFieldDisplayName: string,
    refFieldIsList: boolean,
    refFieldIsRequired: boolean,
    refField:$Shape<{
      name: string,
    }>,
  }>,
|}>

export const getTableField = (tableField: ApolloTableField) => tableField;

export const getFieldType = createSelector(getTableField, fpUtils.safeGet('fieldType'));
export const getFieldName = createSelector(getTableField, fpUtils.safeGet('name'));
export const getFieldIsList = createSelector(getTableField, fpUtils.safeGet('isList'));
export const getFieldIsMeta = createSelector(getTableField, fpUtils.safeGet('isMeta'));
export const getFieldIsRequired = createSelector(getTableField, fpUtils.safeGet('isRequired'));
export const getFieldDefaultValue = createSelector(getTableField, fpUtils.safeGet('defaultValue'));
export const getFieldRawDisplayName = createSelector(getTableField, fpUtils.safeGet('displayName'));

export const getFieldTypeAttributes = createSelector(getTableField, fpUtils.safeGet('fieldTypeAttributes'));
export const getFieldFormat = createSelector(getFieldTypeAttributes, fpUtils.safeGet('format'));


export const getFieldDisplayName = createSelector(
  getFieldRawDisplayName,
  getFieldName,
  (disaplyName, name) => disaplyName || name,
);

export const getFieldListOptions = createSelector(
  getFieldTypeAttributes,
  fpUtils.safeGet('listOptions'),
);

// $FlowFixMe
export const getInnerFields = createSelector(
  getFieldTypeAttributes,
  fpUtils.safeGet('innerFields'),
);

export const getInnerFieldByName = createSelector(
  getInnerFields,
  (_, name: string) => name,
  (innerFields, name) => fp.pipe(
    fp.find(
      fp.pipe(fp.get('name'), fp.equals(name)),
    ),
    field => fp.isUndefined(field) ? {} : field,
  )(innerFields),
);

export const isMetaField = createSelector(
  getFieldIsMeta,
  (isMeta) => isMeta,
);

export const isNestedField = createSelector(
  getFieldType,
  (type) => type === 'SMART',
);

export const isRelationField = createSelector(
  getFieldType,
  (type) => type === 'RELATION',
);

/** Number field meta */
export const getNumberPrecision = createSelector(
  getFieldTypeAttributes,
  fpUtils.safeGet('precision'),
);

export const getNumberMaxValue = createSelector(
  getFieldTypeAttributes,
  fpUtils.safeGet('maxValue'),
);

export const getNumberMinValue = createSelector(
  getFieldTypeAttributes,
  fpUtils.safeGet('minValue'),
);

/** Date field meta */
export const getIsDateTimeFormat = createSelector(
  getFieldFormat,
  fp.equals('DATETIME'),
);

export const getTextFieldSize = createSelector(
  getFieldTypeAttributes,
  fpUtils.safeGet('fieldSize'),
);

/** Realtion field meta */
export const getRelation = createSelector(getTableField, fp.get('relation'));
export const getRefTable = createSelector(getRelation, fp.get('refTable'));
export const getRefFieldName = createSelector(getRelation, fp.get('refFieldName'));
export const getRefFieldDisplayName = createSelector(getRelation, fp.get('refFieldDisplayName'));
export const getRefTableDisplayName = createSelector(getRefTable, fp.get('displayName'));
export const getRefTableName = createSelector(getRefTable, fp.get('name'));

