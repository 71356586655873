// @flow
import React from 'react';
import styled from '@emotion/styled';
import { AsyncContent, Column, Heading, Row, Text } from '@8base/boost';
import { FolderOpen, ArrowBack } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
// $FlowFixMe
import { useHistory, Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { isFirefoxBrowser } from 'utils';

import { APP_URL, useBuildUrl } from 'common/routing';
import { PROJECT_KIND } from 'common/constants/projectKind';
import * as R from 'ramda';
import { useNavigationHistory } from 'providers/HistoryProvider/HistoryConstext';

const DeveloperHomeNavUserInfoWrapperTag = styled.div`
  display:flex;
  height: 150px;
  padding: 5px 25px;
  border-bottom: 1px solid ${props => props.theme.COLORS.GRAY_20};
`;

const StyledButtonBack = styled(Link)`
  display: flex;
  color: ${props => props.theme.COLORS.BLACK};
  transition: all .3s ease-in-out;
  h4 {
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.COLORS.BLUE};
    h4 {
      color: ${props => props.theme.COLORS.BLUE};
    }
  }
`;

const StyledProjectKindText = styled(Text)`
  text-transform: uppercase;
  background-color: ${props => props.theme.COLORS.GRAY_20};
  color: ${props => props.theme.COLORS.BLACK};
  margin: 2px;
  font-weight: bold;
`;

export const ProjectHomeNavInfo = ({ projectInfo, loading }: {projectInfo: any, loading: boolean}) => {
  const history = useHistory();
  const buildUrl = useBuildUrl();
  const projectId = projectInfo?.id;
  const defaultBackUrl = buildUrl(APP_URL.developerHomeProjects, { pathParams: { projectId }});
  const { previousPage } = useNavigationHistory();

  const goBack = () => {
    if (history.length <= 2 || isFirefoxBrowser() || !previousPage) {
      history.replace(defaultBackUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <DeveloperHomeNavUserInfoWrapperTag>
      <AsyncContent loading={ loading } stretch>
        <Row id="row" justifyContent="start" alignItems="start" gap="md" css={ css`padding:10px;min-width: 180px;align-items:baseline;` }>
          <StyledButtonBack css={ css`margin-right:0 !important;top:-5px;position:relative;` } onClick={ goBack }
          >
            <ArrowBack fontSize="large" />
          </StyledButtonBack>
          <Column id="column" alignItems="center" gap="none" css={ css`word-break: break-all;min-width: 150px;` }>
            <Avatar css={ css`margin-bottom: 10px !important;` }
              style={{ width: 48, height: 48, backgroundColor: '#ABB1B8', marginTop: '10px' }}
              src={ R.propOr(null, 'downloadUrl', projectInfo?.image) }
            >
              <FolderOpen style={{ width: 32, height: 32 }} />
            </Avatar>
            <Heading
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 180, textAlign: 'center' }}
              type="h2"
            >
              { projectInfo?.name }
            </Heading>
            <Choose>
              <When condition={ projectInfo?.kind === PROJECT_KIND.fullstack }>
                <Row alignItems="center">
                  <StyledProjectKindText>fs</StyledProjectKindText>
                  <Text>Full-Stack</Text>
                </Row>
              </When>
              <When condition={ projectInfo?.kind === PROJECT_KIND.backend }>
                <Row alignItems="center">
                  <StyledProjectKindText>be</StyledProjectKindText>
                  <Text>Backend</Text>
                </Row>
              </When>
            </Choose>
          </Column>
        </Row>
      </AsyncContent>
    </DeveloperHomeNavUserInfoWrapperTag>
  );
};
