import gql from 'graphql-tag';

export * from './teamInvitations';
export * from './teamMembers';
export * from './users';
export * from './roles';
export * from './billing';
export * from './workspaces';
export * from './tables';
export * from './ci';
export * from './organizationInvitations';
export * from './projectInvitations';
export * from './notifications';
export * from './projects';

export const API_TOKEN_DELETE_MUTATION = gql`
  mutation ApiTokenDelete($id: ID!) {
    apiTokenDelete(data: { id: $id }) {
      success
    }
  }
`;

export const API_TOKEN_UPDATE_MUTATION = gql`
  mutation ApiTokenUpdate($data: ApiTokenUpdateInput!) {
    apiTokenUpdate(data: $data) {
      id
      name
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

export const API_TOKEN_CREATE_MUTATION = gql`
  mutation ApiTokenCreate($data: ApiTokenCreateInput!) {
    apiTokenCreate(data: $data) {
      id
      name
      token
    }
  }
`;

export const ENVIRONMENT_VARIABLE_DELETE_MUTATION = gql`
  mutation EnvironmentVariableDelete($id: ID!) {
    environmentVariableDelete(data: { id: $id }){
      success
    }
  }
`;

export const ENVIRONMENT_VARIABLE_UPDATE_MUTATION = gql`
  mutation EnvironmentVariableUpdate($data: EnvironmentVariableUpdateInput!, $filter: EnvironmentVariableKeyFilter) {
    environmentVariableUpdate(data: $data, filter: $filter) {
      id
      name
      value
    }
  }
`;

export const ENVIRONMENT_VARIABLE_CREATE_MUTATION = gql`
  mutation EnvironmentVariableCreate($data: EnvironmentVariableCreateInput!) {
    environmentVariableCreate(data: $data) {
      id
      name
      value
    }
  }
`;
