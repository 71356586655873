import { AsyncContent, Column, Row, Text } from '@8base/boost';
import { css } from '@emotion/core';
import * as R from 'ramda';
import React from 'react';
import { Trans } from 'utils/translate';
import { useProjectPlan } from 'graphql/hooks';


import styled from '@emotion/styled';

import { PlanName } from 'common/PlanName';
import { PaymentsDetails } from './PaymentsDetails';

import { DateTime } from 'luxon';
import { CenteredCardBody, StyledCard, StyledCardHeader, StyledCardTitleText } from './StyledComponents';

const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
   color:  ${props => props.theme.COLORS.GRAY_50}
`;


const PlanCard = ({ title, icon, link, projectId, apiHost }: *) => {
  const { projectPlan, loading } = useProjectPlan(projectId, apiHost);

  const paymentDetails = R.prop('paymentDetail', projectPlan);
  const plan = R.prop('plan', projectPlan);
  const paymentDate = DateTime.fromISO(R.propOr('', 'paymentDate', plan)).toLocaleString(DateTime.DATE_SHORT);
  const amountDue = R.propOr(0, 'overagesPrice', projectPlan) + R.propOr(0, 'price', plan);

  return (
    <StyledCard>
      <StyledCardHeader style={{ marginBottom: 14 }}>
        <Row alignItems="center">
          { icon }
          <StyledCardTitleText>
            { title }
          </StyledCardTitleText>
        </Row>
        { link }
      </StyledCardHeader>
      <CenteredCardBody>
        <AsyncContent loading={ loading }>
          <Choose>
            <When condition={ plan }>
              <Column stretch>
                <PlanName css={ css`margin-bottom:-5px !important;` } plan={ plan } />
                <Row alignItems="baseline">
                  <Text css={ css`font-size:40px;` } >${ R.prop('price', plan) }</Text>
                  <If condition={ R.prop('price', plan) >= 0 }>
                    <Text css={ css`font-size:16px;` } color="SECONDARY_TEXT_COLOR">/ mo.</Text>
                  </If>
                </Row>
                <DividerNav />
                <Row>
                  <Column >
                    <Text color="LIGHT_TEXT_COLOR">
                      <Trans i18nKey="projects.dashboard.planCard.amountDue">
                        Amount Due
                      </Trans>
                    </Text>
                    <Text css={ css`font-size:20px;` } >${ amountDue }</Text>
                  </Column>
                  <Column>
                    <Text color="LIGHT_TEXT_COLOR">
                      <Trans i18nKey="projects.dashboard.planCard.dueDate">
                        Due Date
                      </Trans>
                    </Text>
                    <Choose>
                      <When condition={ paymentDate }>
                        <Text css={ css`font-size:20px;` } >{ paymentDate }</Text>
                      </When>
                    </Choose>
                  </Column>
                </Row>
                <DividerNav />

                <PaymentsDetails card={ paymentDetails } />
              </Column>
            </When>
            <Otherwise>
              <StyledCardContentText>
                <Trans i18nKey="projects.dashboard.noData">No data</Trans>
              </StyledCardContentText>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </CenteredCardBody>
    </StyledCard>
  );
};

export { PlanCard };
