// @flow

import React from 'react';
import * as R from 'ramda';
// $FlowFixMe waiting for update Flows
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/core';
import { useQuery } from 'react-apollo';
import { Icon, Row, Dropdown, Text, Column, Link, Scrollable, Tooltip } from '@8base/boost';
import { i18n } from 'i18n';
import videoPreview from 'images/video-preview.png';
import { getGuidesByPathname, getVideosByPathname } from 'utils/help';
import { Trans } from 'utils/translate';
import * as LINKS from 'config/links';
import { ENDPOINT_URI, LEARNING_CENTER } from 'common/constants/apolloOperationContextOptions';
import { getLearningCenterUrl } from 'utils/processEnv';
import { useProject } from '../providers/ProjectProvider/useProject';

import { LEARNING_CENTER_QUERY, PROJECT_FRONTEND_QUERY } from 'graphql/queries';

const videoPreviewClassName = css`
  background-color: rgba(60,87,118,0.6);
  box-shadow: 0 2px 3px 0 rgba(20,24,29,0.6);
  border-radius: 4px;
  border: 1px solid #4DA1FF;
`;

const videoPreviewOverlayClassName = css`
  align-items: center;
  background-color: rgba(0,0,0,.1);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const videoPreviewDynoClassName = css`
  width: 32px;
  height: 32px;
`;

const dividerClassName = css`
  margin: 4px 0 12px 0;
  border-top: 1px solid #E9EFF4;
  width: 100%;
`;

const VideoPreview = ({ yid, title, duration }) => (
  <Column>
    <Link css={ css`position: relative; display: flex;` } href={ `https://www.youtube.com/watch?v=${yid}` } target="_blank" rel="noopener noreferrer">
      <img css={ videoPreviewClassName } src={ `https://img.youtube.com/vi/${yid}/default.jpg` } alt="" />
      <div css={ videoPreviewOverlayClassName }>
        <img css={ videoPreviewDynoClassName } src={ videoPreview } alt="" />
      </div>
    </Link>
    <Link href={ `https://www.youtube.com/watch?v=${yid}` } target="_blank" rel="noopener noreferrer">
      <span css={ css`font-weight: 600;` }>{ title }</span> ({ duration })
    </Link>
  </Column>
);

type LearningCenterProps = {
  withLearningMode: boolean;
}

export const LearningCenter = ({
  withLearningMode,
}: LearningCenterProps) => {
  const { project } = useProject();
  const { pathname } = useLocation();
  const projectId = R.prop('id', project);
  const apiHost = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'apiHost'], project);

  const { data } = useQuery(LEARNING_CENTER_QUERY, {
    context: {
      [ENDPOINT_URI]: getLearningCenterUrl(),
      [LEARNING_CENTER]: true,
      noBatch: true,
    },
  });

  const { frontend } = useQuery(PROJECT_FRONTEND_QUERY, {
    skip: !project,
    fetchPolicy: 'network-only',
    context: {
      [ENDPOINT_URI]: apiHost,
    },
    variables: { projectId },
  });
  const frontendWorkspaces = frontend?.frontendWorkspaces?.items ?? [];

  const guides = getGuidesByPathname(data, pathname).slice(0, 3);
  const videos = getVideosByPathname(data, pathname).slice(0, 5);
  let showInstructions = false;

  if (project) {
    const allWorkspaces = [...project?.backendWorkspaces?.items, ...frontendWorkspaces];
    showInstructions = allWorkspaces.some(workspace => workspace.is8BaseAuthEnabled);
  }

  return (
    <Dropdown defaultOpen={ false }>
      <Dropdown.Head { ...E2E.$props('root.learningCenterToggle') } { ...PT.$props('mainNav.learningCenterBtn') }>
        <Tooltip
          cursor="pointer"
          modifiers={{ offset: { offset: '0, 4' }}}
          message={ i18n.t('root.learningCenter.help') }
        >
          <Row alignItems="center">
            <Choose>
              <When condition={ withLearningMode }>
                <Icon name="ActiveHelp" color="BLUE_30" />
              </When>
              <Otherwise>
                <Icon name="Help" color="PRIMARY_TEXT_COLOR" />
              </Otherwise>
            </Choose>
          </Row>
        </Tooltip>
      </Dropdown.Head>
      <Dropdown.Body { ...E2E.$props('root.learningCenterBody') } pin="right" offset="md" background="white" css={ css`white-space: nowrap;` }>
        <Row css={ css`padding: 12px 32px;` } gap="xl">
          <If condition={ videos.length > 0 }>
            <Column>
              <Text color="SECONDARY_TEXT_COLOR" css={ css`font-size: 1.2rem;` }>
                <Trans i18nKey="root.learningCenter.watchVideos">Watch Videos</Trans>
              </Text>
              <Scrollable css={ css`height: 208px;` }>
                <Column>
                  { videos.map(({ id, ...rest }) => <VideoPreview key={ id } { ...rest } />) }
                </Column>
              </Scrollable>
            </Column>
          </If>
          <Column>
            <If condition={ guides.length > 0 }>
              <Text color="SECONDARY_TEXT_COLOR" css={ css`font-size: 1.2rem;` }>
                <Trans i18nKey="root.learningCenter.guides">Guides</Trans>
              </Text>
              { guides.map(({ id, title, href }) => (
                <Link key={ id } href={ href } target="_blank" rel="noopener noreferrer">
                  { title }
                </Link>
              )) }
              <div css={ dividerClassName } />
            </If>
            <Text color="SECONDARY_TEXT_COLOR" css={ css`font-size: 1.2rem;` }>Support</Text>
            <Link href={ LINKS.DOCS_URL } target="_blank" rel="noopener noreferrer">
              <Trans i18nKey="root.learningCenter.documentation">Documentation</Trans>
            </Link>
            <Link target="_blank" rel="noopener noreferrer">
              <Trans i18nKey="root.learningCenter.resources">Resources</Trans>
            </Link>
            <If condition={ showInstructions }>
              <Link css={ css`display: flex;align-items: center;` } href={ LINKS.TEMPLATE_INSTRUCTIONS } target="_blank" rel="noopener noreferrer">
                <Trans i18nKey="root.learningCenter.templateInstructions">View Template Instructions</Trans>
              </Link>
            </If>
            <Link href={ LINKS.DISCOURSE_URL } target="_blank" rel="noopener noreferrer">
              <Trans i18nKey="root.learningCenter.community">Community</Trans>
            </Link>
            <Link href={ LINKS.SUPPORT_URL } target="_blank" rel="noopener noreferrer">
              <Trans i18nKey="root.learningCenter.contactSupport">Contact Support</Trans>
            </Link>
          </Column>
        </Row>
      </Dropdown.Body>
    </Dropdown>
  );
};
