import Cookies from 'js-cookie';
import { AUTH_KEY } from '../AuthenticationProvider';

export class CookieStorage {
  mainDomain: string;

  constructor(customDomain?: string) {
    this.mainDomain = customDomain || this.getCurrentDomain();
  }

  getCurrentDomain(): string {
    const parts = window.location.hostname.split('.');
    return parts.length > 2 ? parts.slice(1).join('.') : parts.join('.');
  }

  getItem(storageKey: string): string | undefined {
    const item = Cookies.get(storageKey);
    return item || undefined;
  }

  setItem(storageKey: string, value: string): void {
    Cookies.set(storageKey, value, { domain: this.mainDomain });
  }

  removeItem(storageKey: string): void {
    this.setItem(AUTH_KEY, '{}');
    Cookies.remove(storageKey);
  }
}
