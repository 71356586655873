import React from 'react';
import { Text } from '@8base/boost';
import css from '@emotion/css';

const Title = ({ bottom, children, top, weight }) =>
  (
    <Text css={ css`
      width: 100%;
      margin-bottom: ${bottom || '10px'};
      margin-top: ${top || '10px'};
      font-weight: ${weight || 'normal'};
    ` }>
      { children }
    </Text>
  );

export { Title };
