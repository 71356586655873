/* eslint-disable react/jsx-key */

import React from 'react';
import { Column, Heading, Icon, Row, Text } from '@8base/boost';
import { css, keyframes } from '@emotion/core';
import { FolderOpen, DesktopWindows } from '@material-ui/icons';
import styled from '@emotion/styled';
import { i18n } from 'i18n';

export const LOADER_TYPE = {
  project: 'project',
  frontend: 'frontend',
  backend: 'backend',
  fullstackOnlyOneOption: 'fullstack-only-one-option',
  fullstackAllFeatures: 'fullstack-all-features',
};

const ColumnContainer = styled(Column)`
  gap: 10px;
`;

const Message = styled('div')({
  fontFamily: 'Poppins !important',
  alignSelf: 'stretch',
  marginTop: '5px',
  color: '#CFD7DD',
  fontSize: '11px',
  lineHeight: '115%',
});

const StepLabel = styled('div')({
  marginLeft: '42px',
  display: 'flow-root',
  width: '100px',
});

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: initial;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const borderColorFadeIn = keyframes`
  0% {
    border-color: #CFD7DD;
  }
  100% {
    border-color: #0080FF;
    box-shadow: 0px 0px 0px 5px rgba(226,241,255,255);
  }
`;

const colorMessage = keyframes`
  0% {
    color: #CFD7DD;
  }
  100% {
    color: #0080FF;
  }
`;

const widthFadeIn = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const marginAnimate = keyframes`
  to {
    margin-left: 0;
  }
`;
const VerticalDivider = styled('div')`
  box-sizing: border-box;
  height: 6px;
  width: 1px;
  border: 1px solid #C4CDD2;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


const AllDone = styled('div')`
  align-items: center;
  background-color: #0080FF;
  border-radius: 100%;
  display: flex;
  height: ${({ size }) => `${size}px`};
  justify-content: center;
  left: 50%;
  margin-left: 14px;
  margin-top: -5px;
  position: absolute;
  transform: translate(-50%);
  width: ${({ size }) => `${size}px`};

  & > * {
    align-items: center;
    border-radius: 100%;
    color: #fff;
    display: flex;
    height: calc(100% + 2px);
    justify-content: center;
    margin-top: -25px
    position: absolute;
    width: calc(100% + 2px);
  }
`;

const Circle = styled('div')`
  color: #fff;
  border: 2px solid #CFD7DD;
  border-radius: 100%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%);
  left: 50%;


  animation: ${borderColorFadeIn} 2s ease-in ${({ delay }) => delay}s normal forwards;

  & > * {
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-color: #0080FF;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadeIn} 1s ease-in ${({ delay }) => delay}s normal forwards;
    opacity: 0;
  }
`;

const Rectangle = styled('div')`
  width:  ${({ width }) => width ? width : 96}px;
  height: 2px;
  background-color: #CFD7DD;
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  z-index: 100;

  & > * {
    width: 0;
    height: 2px;
    background-color: #0080FF;
    animation: ${widthFadeIn} 2s ease-in ${({ delay }) => delay}s normal forwards;
  }
`;
const ColumnBase = styled(Column)`
  font-size: 3rem;
`;
const RowBase = styled(Row)`
  height: 40px;
`;
const ColumnMessage = styled(Column)`
  & > *:not(:first-of-type) {
    position: absolute; padding-top: 140px!important;
  }
`;
const MessageBase = styled(Message)`
  color: #5D6975 !important;
`;
const ColumnDone = styled(Column)`
  font-size: 3rem;
  position:absolute;
  padding-top:0;
`;

export const ProjectLoader = ({ isDone, name, template }) => {
  const renderTemplate = TEMPLATES[template];
  return (
    <ColumnContainer alignItems="center" >
      { !isDone &&
      <>
        <ColumnBase alignItems="center">
          <Row>
            <If condition={ template.includes('FRONTEND') }>
              <DesktopWindows style={{ marginTop: '0px', fontSize: 45 }} />
            </If>
            <If condition={ !template.includes('FRONTEND') }>
              <FolderOpen style={{ marginTop: '0px', fontSize: 45 }} />
            </If>
          </Row>

          <Heading type="h2">
            { template.includes('FRONTEND')
              ? i18n.t('projectLoader.creatingFrontend', { defaultValue: 'Creating Frontend' })
              : i18n.t('projectLoader.creating', { defaultValue: 'Creating Project' }) }
          </Heading>

          <Text >
            { name }
          </Text>
        </ColumnBase>
      </> }

      <RowBase alignItems="center">
        { !isDone && renderTemplate.points() }
      </RowBase>

      { !isDone &&
      <StepLabel css={ css ` text-align:center;` } >
        <Row alignItems="center">
          { renderTemplate.labels() }
        </Row>
      </StepLabel> }
      <br />
      { !isDone && (
        <ColumnMessage alignItems="center">
          <MessageBase>
            { i18n.t('projectLoader.subtitle1', { defaultValue: 'We are setting up your project. Please wait, this should only take a moment.' }) }
          </MessageBase>
        </ColumnMessage>
      ) }
      { isDone &&
      <ColumnDone alignItems="center">
        <Row>
          <FolderOpen style={{ marginTop: '0px', fontSize: 45 }} />
          <AllDone size={ 25 }>
            <Icon name="Check" size="xs" />
          </AllDone>
        </Row>
        <Heading type="h2">
          { i18n.t('projectLoader.done', { defaultValue: 'All done!' }) }
        </Heading>
        <Text>
          { i18n.t('projectLoader.doneDescription', { name }) }
        </Text>
      </ColumnDone> }

    </ColumnContainer>
  );
};

const TEMPLATES = {
  ALL_FEATURES: {
    points: () => (
      <Row alignItems="center">
        <Circle css={ css`margin-left: -320px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 2 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: -256px; animation: ${fadeOut} 250ms ` } delay={ 2 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: -192px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 4 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: -128px; animation: ${fadeOut} 250ms` } delay={ 4 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: -64px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 6 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 0px; animation: ${fadeOut} 250ms ` } delay={ 6 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: 64px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 8 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 128px; animation: ${fadeOut} 250ms ` } delay={ 8 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: 192px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 10 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 256px; animation: ${fadeOut} 250ms ` } delay={ 10 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: 320px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 12 }>
          <Icon name="Check" size="xs" />
        </Circle>
      </Row>
    ),
    labels: () => (
      <>
        <div css={ css`margin-left: -327px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css` animation: ${colorMessage} 2s ease-in 2s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step1', { defaultValue: 'Setting up computing environment' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 55px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css` animation: ${colorMessage} 2s ease-in 4s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step2', { defaultValue: 'Creating database' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 54px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;width: 78px;` }>
            { i18n.t('projectLoader.fullstack.step3', { defaultValue: 'Creating user interface' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 40px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;` }>
            { i18n.t('projectLoader.title3frontend', { defaultValue: 'Adding integrated authorization' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 40px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 10s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step5', { defaultValue: 'Setting up subscriptions' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 56px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 12s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step6', { defaultValue: 'Finalizing settings' }) }
          </Message>
        </div>
      </>
    ),
  },
  FRONTEND_AUTH: {
    points: () => [...Array(5)].map((_, index) => {
      return (<>
        <Circle css={ css`margin-left: ${-234 + (index * 116)}px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 2 * (index + 1) }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: ${-176 + (index * 116)}px; width: ${index === 4 ? 0 : 88}; animation: ${fadeOut} 250ms ` } delay={ 2 * (index + 1) } width={ 88 }>
          <div />
        </Rectangle>
      </>);
    }),
    labels: () => (
      <>
        <div css={ css`margin-left: -246px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 2s normal forwards;width: 78px;` }>
            { i18n.t('projectLoader.title1frontend', { defaultValue: 'Creating user interface' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 44px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 4s normal forwards;` }>
            { i18n.t('projectLoader.title2frontend', { defaultValue: 'Applying template' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 40px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 4s normal forwards;` }>
            { i18n.t('projectLoader.title3frontend', { defaultValue: 'Adding integrated authorization' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 27px; margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;` }>
            { i18n.t('projectLoader.title4frontend', { defaultValue: 'Setting up subscriptions' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 46px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 8s normal forwards;` }>
            { i18n.t('projectLoader.title5frontend', { defaultValue: 'Finalizing settings' }) }
          </Message>
        </div>
      </>
    ),
  },
  FRONTEND_NO_AUTH: {
    points: () => [...Array(3)].map((_, index) => {
      return (<>
        <Circle css={ css`margin-left: ${-120 + (index * 116)}px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 2 * (index + 1) }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: ${-62 + (index * 116)}px; width: ${index === 2 ? 0 : 88}; animation: ${fadeOut} 250ms ` } delay={ 2 * (index + 1) } width={ 88 }>
          <div />
        </Rectangle>
      </>);
    }),
    labels: () => (
      <>
        <div css={ css`margin-left: -130px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 2s normal forwards;width: 78px;` }>
            { i18n.t('projectLoader.title1frontend', { defaultValue: 'Creating user interface' }) }
          </Message>
        </div>
        <div css={ css`margin-left:31px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;` }>
            { i18n.t('projectLoader.title4frontend', { defaultValue: 'Setting up subscriptions' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 44px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 8s normal forwards;` }>
            { i18n.t('projectLoader.title5frontend', { defaultValue: 'Finalizing settings' }) }
          </Message>
        </div>
      </>
    ),
  },
  FULLSTACK: {
    points: () => (
      <>
        <Circle css={ css`margin-left: -192px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 2 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: -128px; animation: ${fadeOut} 250ms ` } delay={ 2 }>
          <div />
        </Rectangle>

        <Circle css={ css`margin-left: -64px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 4 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 0; animation: ${fadeOut} 250ms` } delay={ 4 }>
          <div />
        </Rectangle>

        <Circle css={ css`margin-left: 64px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 6 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 128px; animation: ${fadeOut} 250ms ` } delay={ 6 }>
          <div />
        </Rectangle>

        <Circle css={ css`margin-left: 192px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 8 }>
          <Icon name="Check" size="xs" />
        </Circle>
      </>
    ),
    labels: () => (
      <>
        <div css={ css`margin-left: -200px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 2s normal forwards;` }>
            { i18n.t('projectLoader.title1', { defaultValue: 'Setting up computing environment' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 56px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 4s normal forwards;` }>
            { i18n.t('projectLoader.title2', { defaultValue: 'Creating database' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 55%;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;` }>
            { i18n.t('projectLoader.title3', { defaultValue: 'Setting up subscriptions' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 55%;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 8s normal forwards;` }>
            { i18n.t('projectLoader.title4', { defaultValue: 'Finalizing settings' }) }
          </Message>
        </div>
      </>
    ),
  },
  BACKEND_NO_AUTH: {
    points: () => (
      <>
        <Circle css={ css`margin-left: -192px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 2 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: -128px; animation: ${fadeOut} 250ms ` } delay={ 2 }>
          <div />
        </Rectangle>

        <Circle css={ css`margin-left: -64px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 4 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 0; animation: ${fadeOut} 250ms` } delay={ 4 }>
          <div />
        </Rectangle>

        <Circle css={ css`margin-left: 64px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 6 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 128px; animation: ${fadeOut} 250ms ` } delay={ 6 }>
          <div />
        </Rectangle>

        <Circle css={ css`margin-left: 192px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 8 }>
          <Icon name="Check" size="xs" />
        </Circle>
      </>
    ),
    labels: () => (
      <>
        <div css={ css`margin-left: -200px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 2s normal forwards;` }>
            { i18n.t('projectLoader.title1', { defaultValue: 'Setting up computing environment' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 56px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 4s normal forwards;` }>
            { i18n.t('projectLoader.title2', { defaultValue: 'Creating database' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 55%;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;` }>
            { i18n.t('projectLoader.title3', { defaultValue: 'Setting up subscriptions' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 55%;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 8s normal forwards;` }>
            { i18n.t('projectLoader.title4', { defaultValue: 'Finalizing settings' }) }
          </Message>
        </div>
      </>
    ),
  },
  BACKEND: {
    points: () => [...Array(5)].map((_, index) => {
      return (<>
        <Circle css={ css`margin-left: ${-234 + (index * 116)}px; animation: ${marginAnimate} 500ms ` } size={ 32 } delay={ 2 * (index + 1) }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: ${-176 + (index * 116)}px; width: ${index === 4 ? 0 : 88}; animation: ${fadeOut} 250ms ` } delay={ 2 * (index + 1) } width={ 88 }>
          <div />
        </Rectangle>
      </>);
    }),
    labels: () => (
      <>
        <div css={ css`margin-left: -242px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 2s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step1', { defaultValue: 'Setting up computing environment' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 44px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 4s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step2', { defaultValue: 'Creating database' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 39px;margin-top:0px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 6s normal forwards;` }>
            { i18n.t('projectLoader.title3frontend', { defaultValue: 'Adding integrated authorization' }) }
          </Message>
        </div>

        <div css={ css`margin-left: 29px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 8s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step5', { defaultValue: 'Setting up subscriptions' }) }
          </Message>
        </div>
        <div css={ css`margin-left: 42px;margin-top:-15px; ` } >
          <VerticalDivider />
          <Message css={ css`animation: ${colorMessage} 2s ease-in 10s normal forwards;` }>
            { i18n.t('projectLoader.fullstack.step6', { defaultValue: 'Finalizing settings' }) }
          </Message>
        </div>
      </>
    ),
  },
};
