import { AsyncContent, Choose, Column, Row, Text, Button, useModal } from '@8base/boost';
import { css } from '@emotion/core';
import React from 'react';
import { Trans } from 'utils/translate';

import styled from '@emotion/styled';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { useProjectInfo, useProjectFrontend } from 'graphql/hooks';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import * as R from 'ramda';
import { CenteredCardBody, StyledCard, StyledCardHeader, StyledCardTitleText } from './StyledComponents';
import { IntegratedAuthConfirmationDialog } from 'dialogs/IntegratedAuthConfirmationDialog';
import { mapRegionToEndPoint } from 'utils';

type QuotasCardProps = {
  quotasData: Object,
  metricsData: Object
};

const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
  color:  ${props => props.theme.COLORS.GRAY_50}
`;

const StyledCardBodyText = styled(Text)`
   font-size: 13px !important;
   line-height:50px !important;
   text-transform: capitalize;
   color:  ${props => props.theme.COLORS.GRAY_60}
`;

const StyledOpenButton = styled(Button)`
  color: ${props => props.theme.COLORS.WHITE};
  background-color: ${props => props.theme.COLORS.BLUE_30};
`;

const DashboardCard = ({ title, icon, link, data, projectInfo, frontendWorkspaces, loading, plans, apiHost, ...rest }: QuotasCardProps) => {
  const { openModal } = useModal();
  const { __typename, ...otherData } = data || {};
  const items = Object.entries(otherData);
  const projectId = R.prop('id', projectInfo);
  const organizationId = R.path(['organization', 'id'], projectInfo);
  const authProfiles = R.path(['authenticationInfo'], projectInfo);
  const region = mapRegionToEndPoint(R.path(['region'], projectInfo));
  const isFrontend = title.toUpperCase().includes('FRONTEND');
  const hasFrontends = frontendWorkspaces?.count || 0;
  const { refetch } = useProjectInfo(projectId, apiHost);
  const { refetch: refetchFrontend } = useProjectFrontend(projectId);
  const { openModal: openAuthModal } = useModal(IntegratedAuthConfirmationDialog.ID);

  const onCreateNewWorkspace = () => {
    openModal(WorkspaceCreateDialog.ID,
      {
        organizationId,
        projectId,
        kind: WORKSPACE_KIND.frontend,
        planData: plans,
        authProfiles,
        region,
        onSuccess: async(workspaceId) => {
          refetch();
          refetchFrontend();
          openAuthModal(IntegratedAuthConfirmationDialog.ID, { kind: WORKSPACE_KIND.frontend });
        },
      },
    );
  };

  const toSentenceCase = camelCase => {
    if (camelCase) {
      const result = camelCase.replace(/([A-Z])/g, ' $1');
      return result[0].toUpperCase() + result.substring(1).toLowerCase();
    }
    return '';
  };

  return (
    <StyledCard { ...rest }>
      <StyledCardHeader style={{ marginBottom: 14 }}>
        <Row alignItems="center">
          { icon }
          <StyledCardTitleText>
            { title }
          </StyledCardTitleText>
        </Row>
        { link }
      </StyledCardHeader>
      <CenteredCardBody>
        <AsyncContent loading={ loading }>
          <Choose>
            <When condition={ hasFrontends === 0 && isFrontend }>
              <Row stretch alignItems="center" justifyContent="between">
                <Column gap="md" alignItems="center" stretch>
                  <Row css={ css`align-self:start;` }>
                    <StyledCardContentText>
                      <Trans i18nKey="projects.dashboard.noFrontendLinked">There are no frontends linked to this project</Trans>
                    </StyledCardContentText>
                  </Row>
                  <DividerNav />
                  <StyledOpenButton onClick={ () => onCreateNewWorkspace(WorkspaceCreateDialog.ID) } variant="contained">
                    <Trans i18nKey="projects.dashboard.addFrontend">Add a Frontend</Trans>
                  </StyledOpenButton>
                </Column>
              </Row>
            </When>
            <Otherwise>
              <Choose>
                <When condition={ data }>
                  <Column stretch>
                    { items.map(([key, value], index) => {
                      return (
                        <Column css={ css`height: 40px; display: initial; margin-bottom:0 !important;` } key={ key } padding="none" gap="none" stretch>
                          <Row css={ css`margin-bottom:0 !important;` } stretch alignItems="center" padding="none" justifyContent="between" gap="none">
                            { /*TODO: remove hardcode when query update*/ }
                            <StyledCardBodyText>{ `${toSentenceCase(key === 'field' ? 'fields' : key)}` }</StyledCardBodyText>
                            <StyledCardBodyText>{ `${value}` }</StyledCardBodyText>
                          </Row>
                          <If condition={ index < items.length - 1 }>
                            <DividerNav />
                          </If>
                        </Column>
                      );
                    }) }
                  </Column>
                </When>
                <Otherwise>
                  <StyledCardContentText>
                    <Trans i18nKey="projects.dashboard.noData">No data</Trans>
                  </StyledCardContentText>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </CenteredCardBody>
    </StyledCard>
  );
};

export { DashboardCard };
