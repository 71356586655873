// @flow

import { createSelector } from 'reselect';
import * as R from 'ramda';

import type { Selector } from 'reselect';

type BillingMetricUsageQuotas = {
  value: number,
  displayName: string,
  name: string,
  description: string,
  showPriority: number,
  unit: string,
}

interface ProjectData {
  metricUsagesQuotas?: {
    items: []
  },
  metricUsages?: {
    items: []
  },
  backendWorkspaces?: {
    items: []
  },
  frontendWorkspaces?: {
    items: []
  },
}


export const getData = (data: ProjectData): ProjectData => data;

export const getSystemField: Selector<
ProjectData,
  any,
  ProjectData
> = createSelector(
  getData,
  (data: ProjectData) => R.pathOr({ ...data }, ['system'], data),
);

export const getQuotas: Selector<
  ProjectData,
  any,
  BillingMetricUsageQuotas
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['metricUsagesQuotas', 'items']),
    R.map(({ limitMetric, value }) => ({ value, ...limitMetric })),
    R.sortBy(R.prop('showPriority')),
  )(data),
);

export const getMetrics: Selector<
  ProjectData,
  any,
  Object
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['metricUsages', 'items']),
    R.reduce((result, metric) => R.assoc(R.path(['limitMetric', 'name'], metric), R.prop('value', metric), result), {}),
  )(data),
);

export const getFrontendWorkspaces: Selector<
  ProjectData,
  any,
  Array<any>
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['frontendWorkspaces', 'items']),
  )(data),
);

export const getBackendWorkspaces: Selector<
  ProjectData,
  any,
  Array<any>
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['backendWorkspaces', 'items']),
  )(data),
);

export const getProjectDeployments: Selector<
  ProjectData,
  any,
  Array<any>
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['deploymentsProject', 'items']),
  )(data),
);

export const getOverages: Selector<
  ProjectData,
  any,
  Object
  > = createSelector(
    getSystemField,
    (data: ProjectData) => R.pipe(
      R.pathOr([], ['metricUsages', 'items']),
      R.reduce((result, metric) => R.assoc(R.path(['limitMetric', 'name'], metric), R.prop(['overage'], metric), result), {}),
    )(data),
  );


export const getOveragesValues: Selector<
  ProjectData,
  any,
  Object
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['metricUsages', 'items']),
    R.reduce((result, metric) => R.assoc(R.path(['limitMetric', 'name'], metric), R.path(['overage', 'value'], metric), result), {}),
  )(data),
);

export const getTotalOverages: Selector<
  ProjectData,
  any,
  Number
> = createSelector(
  getSystemField,
  (data: ProjectData) => R.pipe(
    R.pathOr([], ['metricUsages', 'items']),
    R.reduce((result, metric) => result + R.pathOr(0, ['overage', 'value'], metric), 0),
  )(data),
);
