// @flow
import React from 'react';
import styled from '@emotion/styled';

type ProjectPageContentProps = {
  nav: React$Node;
  content: React$Node;
}

export const ProjectMainPlateTag = styled.div`
  display: flex;
  background-color: ${props => props.theme.COLORS.WHITE};
  height: calc(100vh - ${props => props.theme.SIZES.USER_FLOW.headerMdHeight});
`;

export const ProjectMainPlate = ({ nav, content }: ProjectPageContentProps) => (
  <ProjectMainPlateTag>
    { nav }
    { content }
  </ProjectMainPlateTag>
);
