import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { injectStripe } from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Loader, If } from '@8base/boost';
import MuiButton from '@material-ui/core/Button';
import MuiTypography from '@material-ui/core/Typography';

import * as LINKS from 'config/links';
import { useUI } from 'providers/UIProvider';
import { useOrganizationPaymentDetailsUpdate, useUserPaymentDetailsUpdate } from 'graphql/hooks';
import { StripeCardFormFields } from 'dialogs/BillingUpdateDialog/components/StripeCardFormFields';
import { PaymentDetail } from './PaymentDetail';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 16px;

  a {
    color: #0874F9;
  }
`;

const Text = withStyles({
  root: {
    color: '#A5AFB6',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    marginBottom: '16px',
    textAlign: 'center',
  },
})(MuiTypography);

const Button = styled(MuiButton)(
  ({ isActive }) => css`
    background-color: ${ isActive ? '#0874F9 !important' : '#E8EFF5 !important'};
    color: ${ isActive ? '#FFF !important' : '#A5AFB6 !important'};
    height: 40px;
    width: 100% !important;

    &:hover {
      color: #FFF !important;
      background-color: #0874F9 !important;
    }

    &:disabled {
      background-color: #E8EFF5 !important;
    }

    &:active {
      background-color: #0874F9 !important;
    }
  `,
);

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const PaymentCard = ({ isFormInvalid, plan, stripe, onFormSubmit, currentCard }) => {
  const [showCardForm, setShowCardForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { state: { dialog }} = useUI();

  const [updateCardMutation] = useUserPaymentDetailsUpdate();
  const [updateOrganizationCardMutation] = useOrganizationPaymentDetailsUpdate(dialog.organizationId);

  const onSubmit = async (data) => {
    setIsLoading(true);
    window.trackEvent('Billing', 'Update Payment Details');

    try {
      const response = await stripe.createToken({
        type: 'card',
        name: data.cardHolder,
        address_line1: data.street1,
        address_line2: data.street2,
        address_city: data.city,
        address_state: data.state,
        address_zip: data.zip,
        address_country: data.address_country,
      });

      const token = R.path(['token'], response);
      const cardToken = R.prop('id', token);
      if (cardToken) {
        if (dialog.organizationId) {
          const variables = { data: { cardToken, organizationId: dialog.organizationId }};
          await updateOrganizationCardMutation({ variables });
        } else {
          await updateCardMutation({ variables: { data: { cardToken }}});
        }
        setShowCardForm(false);
        onFormSubmit();
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentCard) {
      setShowCardForm(false);
    }
  }, [currentCard]);

  return (
    <Container>
      <If condition={ showCardForm }>
        <StripeCardFormFields />
      </If>
      <If condition={ !showCardForm }>
        <PaymentDetail card={ currentCard } price={ plan?.price } onUpdateCard={ setShowCardForm } />
      </If>
      <Footer>
        <Text>
          By clicking “Submit Changes” you agree to our <a href={ LINKS.USER_AGREEMENT_URL } target="_blank" rel="noopener noreferrer">Terms of Service</a>.
        </Text>
        <Button
          isActive={ !isFormInvalid }
          disabled={ isFormInvalid || (showCardForm && isLoading) }
          type={ showCardForm ? 'button' : 'submit' }
          onClick={ showCardForm ? onSubmit : () => {} }
        >
          <If condition={ isLoading }>
            <Loader size="sm" />
          </If>
          <If condition={ !isLoading }>
            Submit Payment
          </If>
        </Button>
      </Footer>
    </Container>
  );
};


export default R.compose(injectStripe)(PaymentCard);
