import gql from 'graphql-tag';

const ENV_INVITE_DETAILS_FRAGMENT = gql`
  fragment EnvInvitationDetails on SystemInboxEventEnvironmentInvitationDetails {
    status
    environmentName
    workspace {
      id
      name
      apiHost
      avatar {
        id
        downloadUrl
      }
    }
    invitedBy {
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    project {
      apiHost
      id
      kind
      name
    }
  }
`;

const ORG_INVITE_DETAILS_FRAGMENT = gql`
  fragment OrgInvitationDetails on SystemInboxEventOrganizationInvitationDetails {
    status
    invitedBy {
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    organization {
      id
      name
      avatar {
        id
        downloadUrl
      }
    }
  }
`;

const NOTIFICATION_DETAILS_FRAGMENT = gql`
  fragment NotificationDetails on SystemInboxEventNotificationDetailsType {
    details
    kind
  }
`;

export const INBOX_ITEM_FRAGMENT = gql`
  fragment InboxItem on SystemInboxEventItem {
    id
    isCompleted
    createdAt
    type
    details {
      ...EnvInvitationDetails
      ...OrgInvitationDetails
      ...NotificationDetails
    }
  }

  ${ENV_INVITE_DETAILS_FRAGMENT}
  ${ORG_INVITE_DETAILS_FRAGMENT}
  ${NOTIFICATION_DETAILS_FRAGMENT}
`;
