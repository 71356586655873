/* eslint-disable react-hooks/rules-of-hooks */
// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';

import { HIDE_TOAST_ERROR_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { PROJECTS_USER_LIST_QUERY } from 'graphql/queries';

import { apolloProjectsListSelectors as projectsListSelectors } from 'graphql/selectors';

// $FlowFixMe
import { useEffect, useState } from 'react';

export const useProjectsList = (organizationId: string, options?: QueryHookOptions) => {
  const [myProjectsList, setMyProjectsList] = useState([]);
  const [sharedProjectsList, setSharedProjectsList] = useState([]);

  const { data, loading, ...rest }: QueryResult<any, any> = useQuery(PROJECTS_USER_LIST_QUERY, {
    skip: false,
    fetchPolicy: 'network-only',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
    },
    ...options,
    variables: {
      ...options,
      organizationId: organizationId !== undefined ? organizationId : '',
    },
  });

  useEffect(() => {
    if (loading) {
      setMyProjectsList([]);
      setSharedProjectsList([]);
    } else {
      setMyProjectsList(projectsListSelectors.getProjects(data));
      setSharedProjectsList(projectsListSelectors.getSharedProjects(data));
    }
  }, [loading, data]);

  return {
    ...rest,
    loading,
    myProjects: myProjectsList,
    sharedProjects: sharedProjectsList,
  };
};
