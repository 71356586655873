// @flow

import { Icon, Row, Text } from '@8base/boost';
import React from 'react';
import styled from '@emotion/styled';

type CollapseTitleProps = {
  toggleOpen: () => void;
  isOpen: boolean;
  title: React$Node;
  style?: any;
  iconPosition?: 'left' | 'right' | 'none'
}


const StyledIcon = styled(Icon)(props => ({
  color: props.theme.COLORS.BLACK,
  transform: props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
  transition: 'all .3s ease',
}));

const StyledRow = styled(Row)`
  & > .CollapseTitle-Text {
    transition: color .3s ease;
  }

  &:hover > * {
    color: ${({ theme }) => theme.COLORS.BLUE_20}
  }
`;

const CapitalizedTitle = styled(Text)`
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  text-transform: capitalize !important;
`;

export const CollapseTitle = ({
  toggleOpen,
  isOpen,
  title,
  style,
  iconPosition,

}: CollapseTitleProps) => {

  return (
    <StyledRow color="BLACK" cursor="pointer" alignItems="center" onClick={ toggleOpen } style={ style } stretch >
      <Choose>
        <When condition={ iconPosition === 'left' }>
          <StyledIcon isOpen={ isOpen } name="ChevronDown" size="xs" color="LIGHT_TEXT_COLOR" />
          <CapitalizedTitle kind="overline-2" className="CollapseTitle-Text" >{ title }</CapitalizedTitle>
        </When>
        <When condition={ iconPosition === 'right' }>
          <CapitalizedTitle kind="overline-2" className="CollapseTitle-Text" >{ title }</CapitalizedTitle>
          <StyledIcon isOpen={ isOpen } name="ChevronDown" size="xs" color="LIGHT_TEXT_COLOR" />
        </When>
        <Otherwise>
          <CapitalizedTitle kind="overline-2" className="CollapseTitle-Text" >{ title }</CapitalizedTitle>
        </Otherwise>
      </Choose>
    </StyledRow>
  );
};
