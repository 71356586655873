export const ORGANIZATION_TYPES = {
  community: 'Community',
  agency: 'Agency',
  company: 'Company',
  individual: 'Individual',
};

/**
 * Values of organization role
 */
export const ORGANIZATION_ROLE = {
  owner: 'owner',
  admin: 'admin',
  manager: 'manager',
  developer: 'developer',
};

/**
 * Object to display organization role
 */
export const ORGANIZATION_ROLE_DISPLAY_STR = {
  [ORGANIZATION_ROLE.owner]: 'Owner',
  [ORGANIZATION_ROLE.admin]: 'Administrator',
  [ORGANIZATION_ROLE.manager]: 'Manager',
  [ORGANIZATION_ROLE.developer]: 'Developer',
};

export const ORGANIZATION_ROLE_LIST = Object.values(ORGANIZATION_ROLE);


/**
 * Role select option for manager role in organization
 */
export const FULL_ACCESS_ROLE_OPTION = [{ value: 'fullAccess', label: 'Full Access' }];

/**
 * Values of user statuses
 */
export const USER_STATUSES = {
  active: 'active',
  invitationPending: 'invitationPending',
  inactive: 'inactive',
};

/**
 * Object to display user statuses
 */
export const USER_STATUSES_DISPLAY_STR = {
  [USER_STATUSES.active]: 'Active',
  [USER_STATUSES.invitationPending]: 'Pending',
  [USER_STATUSES.inactive]: 'Inactive',
};
