// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { css } from '@emotion/core';
import { Column, Text } from '@8base/boost';

import { Trans } from 'utils/translate';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
import { useWorkspace } from 'providers/WorkspaceProvider';

import { BillingCardBody } from './BillingCardBody';
import { useWorkspacePaymentDetails } from 'graphql/hooks';
import { StyledText } from './StyledComponents';
import styled from '@emotion/styled';
import { Card, CardContent } from '@material-ui/core';


type BillingWorkspaceUsageProps = {
  countWorksPaces: string,
  countUsers: string,
  price: string,
  nextPaymentDate: string,
  fromPaymentDate: string
};

const StyledPrice = styled('div')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 42px;
  margin-top: 16px;
`;


export const BillingWorkspaceUsage = ({ countUsers, countWorksPaces, price, nextPaymentDate, fromPaymentDate }: BillingWorkspaceUsageProps) => {
  const workspace = useWorkspace();
  const { plan, remainTrialDays } = useBillingInfo();
  const { card } = useWorkspacePaymentDetails();

  return (
    <Card variant="outlined" css={ css`
      margin-top: 23.0px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.04);
    ` }>
      <CardContent style={{ padding: 24 }}>
        <Column>
          <Text css={ css`font-size: 14px; font-weight: 600; margin-bottom: 0 !important;` }>
            <Trans i18nKey="project.billing.workspaceUsage.title">Estimated Due</Trans>
          </Text>

          <StyledText color="LIGHT_TEXT_COLOR" >
            <Trans i18nKey="project.billing.workspaceUsage.note">
            This is an estimate of the amount you owe based on your current month-to-date usage after credits & prepayments.
            </Trans>
          </StyledText>
          <StyledPrice>
            ${ price }
          </StyledPrice>
        </Column>
      </CardContent>
      <BillingCardBody
        plan={ plan }
        card={ card }
        remainTrialDays={ remainTrialDays }
        workspaceName={ workspace.name }
        showPlanDetails={ !workspace.organization }
        countUsers={ countUsers }
        nextPaymentDate={ nextPaymentDate }
        fromPaymentDate={ fromPaymentDate }
        countWorksPaces={ countWorksPaces }
      />
    </Card>
  );
};

