// @flow
// $FlowFixMe
import React, { useCallback, useMemo, useState } from 'react';

import { Button, Card, Column, Heading, Icon, Input, Row, TableBuilder, Text, useModal } from '@8base/boost';
// $FlowFixMe
import { useHistory, useParams } from 'react-router-dom';
import { APP_URL, useBuildUrl } from 'common/routing';
import debounce from 'lodash/debounce';

import { css } from '@emotion/core';
import { i18n } from 'i18n';
import * as R from 'ramda';
import { Trans } from 'utils/translate';
import { useMutation, useQuery } from 'react-apollo';
import { TableGroupTitle } from './TableGroupTitle';
import { TableCellActions, TableCellStatus, TableCellUser } from 'pro/TableCells';
import { BILLING_LICENSE_CONST_QUERY } from 'graphql/queries';
import { WORKSPACE_SHARE_WITH_ENVS_MUTATION } from 'graphql/mutations';
import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_DISPLAY_STR, USER_STATUSES } from 'common/constants/organization';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { useOrganization, useUserAccountInfo } from 'graphql/hooks';
// $FlowFixMe
import { SquareAvatars } from 'common/components';
import { TeamMemberRemoveFromOrganizationDialog } from 'dialogs/TeamMemberRemoveFromOrganizationDialog';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';
import { getUserRoleAtOrganization } from 'utils/organization';

import { TeamMemberInviteToProjectOrganizationDialog } from 'dialogs/TeamMemberInviteToProjectOrganizationDialog';
import { getDateFromFirstDayOfMonth } from 'utils/date';

const INVITED = 'Invited';
const OWNERS = 'Owners';
const MEMBERS = 'Members';

const TEAM_TABLE_COLUMNS = [
  {
    name: 'user',
    title: <Trans i18nKey="shared.user">User</Trans>,
    sortEnable: false,
    width: 'minmax(140px, 320px)',
  },
  {
    name: 'projects',
    title: <Trans i18nKey="shared.project">Projects</Trans>,
    width: 'minmax(192px, 1fr)',
  },
  {
    name: 'status',
    title: <Trans i18nKey="shared.status">Status</Trans>,
    width: 'minmax(0, 180px)',
  },
  {
    name: 'role',
    title: <Trans i18nKey="shared.role">Role</Trans>,
    width: 'minmax(0, 180px)',
    sortEnable: false,
  },
  {
    name: 'actions',
    title: '',
    sortEnable: false,
    width: '70px',
  },
];

const groupByStatus = R.groupBy((member) => {
  if (member.status === USER_STATUSES.invitationPending) {
    return INVITED;
  }

  if (member.status === USER_STATUSES.active &&
    (member.role === ORGANIZATION_ROLE.owner || member.role === ORGANIZATION_ROLE.admin)) {
    return OWNERS;
  }

  return MEMBERS;
});

const sortedGroups = (data) => {
  const groups = groupByStatus(data);
  const order = [OWNERS, MEMBERS, INVITED];
  const sortedGroups = {};

  order.forEach(groupName => {
    if (groups[groupName] && groups[groupName].length > 0) {
      sortedGroups[groupName] = groups[groupName];
    }
  });

  return sortedGroups;
};

const UsersTableCell = ({
  organizationId,
  organizationName,
  column,
  cellData,
  permissionRole,
}) => {
  const { openModal } = useModal();
  const buildUrl = useBuildUrl();
  const history = useHistory();
  const { id, firstName, lastName, email, role, avatar, status, projects } = cellData;

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const onOpenUserProfile = useCallback(() => {
    const url = buildUrl(APP_URL.organizationSettingsTeamUser, { pathParams: { organizationId, userId: id }});
    history.push(url);

  }, [buildUrl, history, id, organizationId]);

  const userProjectList = useMemo(() => {
    const projectsIdList = (projects && projects.map(el => el.id)) || [];

    return projects
      .filter(el => projectsIdList.includes(el.id))
      .map(el => {
        return {
          ...el,
          srcUrl: (el.image && el.image.downloadUrl) || workspaceDefaultLogo,
          tooltipMessage: el.name,
        };
      });
  }, [projects]);

  const onRemoveFromOrganization = useCallback(() => {
    openModal(TeamMemberRemoveFromOrganizationDialog.ID, {
      organizationId,
      organizationName,
      firstName,
      lastName,
      email,
      userWorkspaceList: userProjectList,
    });
  }, [email, firstName, lastName, openModal, organizationId, organizationName, userProjectList]);

  const actions = useMemo(() => {
    if (role === ORGANIZATION_ROLE.owner) {
      return [];
    }

    if (userAccountInfo && userAccountInfo.email === email) {
      return [
        {
          title: i18n.t('shared.leave', { defaultValue: 'Leave' }),
          handler: onRemoveFromOrganization,
        },
      ];
    }

    if (permissionRole === ORGANIZATION_ROLE.developer) {
      return [];
    }

    if (permissionRole === ORGANIZATION_ROLE.manager && role !== ORGANIZATION_ROLE.developer) {
      return [];
    }

    return [
      {
        title: i18n.t('organization.team.userProfile'),
        handler: onOpenUserProfile,
      },
      {
        title: i18n.t('shared.remove', { defaultValue: 'Remove' }),
        handler: onRemoveFromOrganization,
      },
    ];
  }, [email, onOpenUserProfile, onRemoveFromOrganization, permissionRole, role, userAccountInfo]);

  switch (column.name) {
    case 'user':
      return (
        <TableCellUser
          firstName={ firstName }
          lastName={ lastName }
          src={ R.prop('downloadUrl', avatar) }
          email={ email }
          onClick={ onOpenUserProfile }
        />);

    case 'status':
      return <TableCellStatus status={ status } />;

    case 'role':
      return <Text ellipsis>{ ORGANIZATION_ROLE_DISPLAY_STR[role] }</Text>;

    case 'projects':
      return (
        <SquareAvatars
          data={ userProjectList }
          status={ status }
          placement="bottom"
        />
      );

    case 'actions': {
      if (role === ORGANIZATION_ROLE.owner) {
        return null;
      }

      if (userAccountInfo && userAccountInfo.email === email) {
        return <TableCellActions actions={ actions } />;
      }

      if (permissionRole === ORGANIZATION_ROLE.developer) {
        return null;
      }

      if (permissionRole === ORGANIZATION_ROLE.manager && role !== ORGANIZATION_ROLE.developer) {
        return null;
      }

      return <TableCellActions actions={ actions } />;
    }
    default:
      return null;
  }
};

const renderCell =
  ({ organizationId, organizationName, permissionRole, updateTeamMemberWorkspaceEnvironments }) =>
    (column, cellData) =>
      (<UsersTableCell
        organizationName={ organizationName }
        organizationId={ organizationId }
        column={ column }
        cellData={ cellData }
        permissionRole={ permissionRole }
        updateTeamMemberWorkspaceEnvironments={ updateTeamMemberWorkspaceEnvironments }
      />);

const isMatchingUser = (user, query) => {
  const firstName = user.firstName || '';
  const lastName = user.lastName || '';
  const email = user.email || '';

  const queryToProcess = query.toLowerCase().trim();

  const isMatchingFirstName = firstName.toLowerCase().trim().includes(queryToProcess);
  const isMatchingLastName = lastName.toLowerCase().trim().includes(queryToProcess);
  const isMatchingFullName = `${firstName} ${lastName}`.toLowerCase().trim().includes(queryToProcess);
  const isMatchingEmail = email.toLowerCase().trim().includes(queryToProcess);

  return isMatchingFirstName || isMatchingLastName || isMatchingFullName || isMatchingEmail;
};

const useDebounceSearch = () => {
  const [query, setQuery] = useState('');
  const [input, setInputValue] = useState('');
  const debounceSearch = useMemo(() => debounce(setQuery, 300), []);
  const onChange = (newValue) => {
    debounceSearch(newValue);
    setInputValue(newValue);
  };

  return [input, query, onChange];
};

export const OrganizationTeamTable = () => {
  const { organizationId } = useParams();

  //Preload metrics
  useQuery(BILLING_LICENSE_CONST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId,
      date: getDateFromFirstDayOfMonth(),
    },
    skip: !organizationId,
  });

  const [updateTeamMemberWorkspaceEnvironments] = useMutation(WORKSPACE_SHARE_WITH_ENVS_MUTATION);

  const { organization } = useOrganization(organizationId);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  const [searchValue, query, debounceSearch] = useDebounceSearch();
  const { openModal } = useModal();
  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const permissionRole = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);


  const onAddUserClick = useCallback(() => {
    if (!organization || !organization.id) {
      return null;
    }

    openModal(TeamMemberInviteToProjectOrganizationDialog.ID, { organizationId: organization.id, permissionRole });
  }, [openModal, organization, permissionRole]);

  const userList = useMemo(() => {
    const list = R.pathOr([], ['users'], organization);

    if (query) {
      return list.filter(user => isMatchingUser(user, query));
    }

    return list;
  }, [organization, query]);

  return (
    <Column stretch gap="lg">
      <Heading type="h1">{ i18n.t('shared.team') }</Heading>
      <Card stretch>
        <Card.Header padding="md">
          <Row stretch alignItems="center">
            <Row stretch alignItems="center">
              <Heading type="h2">
                { i18n.t('organization.team.organizationTeamMembers') }
              </Heading>
            </Row>
            <Row gap="md" stretch alignItems="center" justifyContent="end">
              { isSearchBarOpen && (
                <Input css={ css`max-width: 320px;
                  width: 100%;` }
                clearable
                placeholder={ i18n.t('shared.dotSearch') }
                value={ searchValue }
                onChange={ debounceSearch }
                />
              ) }
              <Icon onClick={ () => setIsSearchBarOpen(val => !val) } cursor="pointer" name="SearchBold" />
              <Button
                onClick={ onAddUserClick }
                color="neutral"
                size="sm"
              >
                <Icon name="InviteTeamMember" color="BLUE_30" size="xs" />
                <Text color="PRIMARY" weight="semibold">{ i18n.t('shared.new', { defaultValue: 'New' }) }</Text>
              </Button>
            </Row>
          </Row>
        </Card.Header>
        <Card.Body padding="none">
          <TableBuilder
            data={ userList }
            groupBy={ (data) => sortedGroups(data) }
            columns={ TEAM_TABLE_COLUMNS }
            renderCell={ renderCell({
              organizationId,
              organizationName: R.pathOr('', ['name'], organization),
              permissionRole,
              updateTeamMemberWorkspaceEnvironments,
            }) }
            columnGap=""
            renderGroupTitle={ (title, data) => (
              <TableGroupTitle
                title={ title }
                counter={ data && data.length }
              />
            ) }
          />
        </Card.Body>
      </Card>
    </Column>
  );
};
