import { css } from '@emotion/core';
import styled from '@emotion/styled';


export const UserFlowPageTitle = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 125px;

  ${props => {
  if (!props.withoutOffset) {
    return css`margin-top: 7rem;`;
  }
}}

  @media (max-width: 992px) {
    height: auto;
    margin-bottom: 2.4rem;
  }
`;

