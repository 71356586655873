import React, { useCallback, useMemo } from 'react';
import { Radio, Tooltip } from '@8base/boost';
import styled from '@emotion/styled';
import { PlanRadioItemTag, ItemsContainer, showCompleteItem, hiddenCompleteItem } from './CssUtils';

import { PLANS } from 'graphql/constants/billing';

import { SharedRadioItem } from './SharedRadioItem';

const RadioGroupScrollableTag = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  top: -10px;
`;

const ItemTag = styled(PlanRadioItemTag)`
  width: 100%;
  transition: min-height 0.3s ease-in;
  overflow: hidden;
  display: block !important;

  &.large {
    min-height: 120px;
  }

  &.short {
    display: flex !important;
    min-height: 0;
    height: 50px;
  }
`;

const PlanRadioItem = ({ active, disabled, disabledTooltipText, children, isTouched }) => {
  const itemTag = (
    <ItemTag active={ active } disabled={ disabled } className={ !isTouched ? 'large' : 'short' }>
      { children }
    </ItemTag>
  );

  if (disabled) {
    return (
      <Tooltip
        message={ (
          <div >
            { disabledTooltipText }
          </div>
        ) }
        cursor="default"
      >
        { itemTag }
      </Tooltip>
    );
  }

  return itemTag;
};

const getAvailablePlans = (plans = [], with8base, isFreePlanAvailable) => {
  let availablePlans = plans;

  if (with8base) {
    availablePlans = [...availablePlans, {
      name: '8base',
      displayName: '8base',
      price: 0,
      id: '8base',
      description: 'Unlimited plan for the 8base team.',
    }];
  }

  if (!isFreePlanAvailable) {
    availablePlans = availablePlans.filter(({ name }) => name !== PLANS.freeTier);
  }

  return availablePlans;
};

export const HowStartProjectRadioGroupField = ({ plans, isFreePlanAvailable, card, with8base, input, ...props }) => {
  const { kindSelected } = props;

  const availablePlans = useMemo(() => {
    return getAvailablePlans(plans, with8base, isFreePlanAvailable);
  }, [isFreePlanAvailable, plans, with8base]);

  const getIsDisabled = useCallback((planName) => {
    return false;
  }, []);

  const getDisabledTooltipText = useCallback((planName) => {
    if (planName !== PLANS.freeTier) {
      return 'Update your payment details by clicking on the link below.';
    }
  }, []);

  const onChange = useCallback(value => {
    const plan = availablePlans.find(({ id }) => id === value) || {};
    const isPlanDisabled = getIsDisabled(plan.name);

    if (!isPlanDisabled) {
      input && input.onChange(value);
    }

  }, [availablePlans, getIsDisabled, input]);

  const isTouched = kindSelected === 'fullstack' && input.value === 'fromScratch';

  return (
    <RadioGroupScrollableTag>
      <ItemsContainer
        { ...props }
        input={{ ...input, onChange }}
        direction="row"
        gap="md"
        alignItems="stretch"
        stretch
      >
        <Radio.Item
          key="withAccelerators"
          value="withAccelerators"
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ props.isBackendOnly ? false : checked }
              disabled={ getIsDisabled('With-Accelerators') }
              disabledTooltipText={ getDisabledTooltipText('With-Accelerators') }
              isTouched={ props.isBackendOnly ? false : isTouched }
              className={ isTouched ? hiddenCompleteItem : showCompleteItem }
            >
              <SharedRadioItem
                name="With-Accelerators"
                displayName="Jumpstart with Accelerators"
                description="Kick off with pre-built services and components such as authentication, user management, notifications, and more. Explore, adapt, and build faster without starting from zero."
                isTouched={ isTouched }
                textAlignment="center"
                icon="rocket"
              />
            </PlanRadioItem>
          ) }
        </Radio.Item>
        <Radio.Item
          key="fromScratch"
          value="fromScratch"
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ props.isBackendOnly ? false : checked }
              disabled={ getIsDisabled('From-Scratch') }
              disabledTooltipText={ getDisabledTooltipText('From-Scratch') }
              isTouched={ props.isBackendOnly ? false : isTouched }
              className={ isTouched ? hiddenCompleteItem : showCompleteItem }
            >
              <SharedRadioItem
                name="From-Scratch"
                displayName="Build from Scratch"
                description="Begin with a clean slate and shape your project from scratch."
                isTouched={ isTouched }
                textAlignment="center"
                icon="tool"
              />
            </PlanRadioItem>
          ) }
        </Radio.Item>

      </ItemsContainer>
    </RadioGroupScrollableTag>
  );
};
