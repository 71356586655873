import React from 'react';
import styled from '@emotion/styled';
import { BRANDS_IMAGES } from 'common/constants/brands';

const BankCardImageTag = styled.img`
  height: 26px;
  margin-right: 10px;
`;

const Title = styled.h4`
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: 16px;
`;
const CardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;
const Layout = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 20px;
`;
const CardName = styled.p`
  color: #323C47;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const PlanInfo = styled.p`
  color: #5D6975;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
const Button = styled.button`
  color: #0973F8;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;


export const PaymentDetail = ({ card, price, onUpdateCard }) => {
  const bankCardBrandImage = !!card ? BRANDS_IMAGES.find(({ name }) => name === card.brand) || {} : {};

  const handleUpdateCard = (evt) => {
    evt.preventDefault();
    onUpdateCard(true);
  };

  return (
    <div>
      <Title>Payment Details</Title>
      <Layout>
        <div>
          <CardContainer>
            <BankCardImageTag src={ bankCardBrandImage.image } alt={ bankCardBrandImage.name } />
            <CardName>{ card.brand } ending in { card.last4 }</CardName>
          </CardContainer>
          <PlanInfo>This card will be charged monthly for an amount of ${ price } starting today.</PlanInfo>
        </div>
        <Button onClick={ handleUpdateCard }>update</Button>
      </Layout>
    </div>
  );
};

export default PaymentDetail;
