// @flow
// $FlowFixMe
import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Column,
  Row,
  Scrollable,
  Icon,
  Text,
  Loader,
  useModal,
} from '@8base/boost';
import { FolderOpen } from '@material-ui/icons';
// import { AccessAlarm, ThreeDRotation } from "@material-ui/icons";
import { Collapse } from 'react-collapse';
import { css } from '@emotion/core';

import { NavLink as RouterLink } from 'common/router';
import { APP_URL, useBuildUrl } from 'common/routing';
import { CreateOrganizationDialog } from 'dialogs/CreateOrganizationDialog';
import { useOrganizationsList } from 'graphql/hooks';
import { Trans } from 'utils/translate';
import { useUndoneInboxCount } from 'graphql/hooks/useInboxList';

import { CollapseTitle } from './CollapseTitle';
import { CountTag } from 'common/components';
import { ORGANIZATION_TYPES } from 'common/constants/organization';

import documentation from 'images/documentation.svg';
import academy from 'images/8base-academy.svg';
import discourse from 'images/discourse.svg';
import tutorials from 'images/tutorials.svg';
import sdk from 'images/sdk.svg';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';

import InboxIcon from '@material-ui/icons/InboxOutlined';

import * as R from 'ramda';

// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

const DeveloperHomeNavContentTag = styled.div`
  overflow: auto;
  padding: 20px;
`;

const offset = '12px';

const menuItemOffsetCss = css`
  padding-top: ${offset};
  padding-bottom: ${offset};
`;

const topOffsetCss = css`
  padding-top: ${offset};
`;

const activeLinkCss = (props) => css`
  &.active {
    color: ${props.theme.COLORS.BLUE_20} !important;
    background: ${props.theme.COLORS.WHITE};
  }
`;

const hoverCss = (props) => css`
  & > *:not(${CountTag}) {
    transition: all .3s ease;
  }

  &:hover > *:not(${CountTag}) {
    color: ${props.theme.COLORS.BLUE_20}
  }
`;

const MenuRouterLink = styled(RouterLink)`
  border-radius: 4px;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;

  ${hoverCss}
  ${activeLinkCss}
`;

const StyledMyProjectsIcon = styled(FolderOpen)``;

const StyledRowLink = styled(Row)`
  ${hoverCss}
`;

const StyledText = styled('span')`
  line-height: 1.6rem;
`;

const ResourceIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const WorkspaceImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 40px; 
  width: 16px;
  height: 16px;
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
`;

const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const RESOURCES_LINKS = [{
  label: 'Documentation',
  link: 'https://docs.8base.com/',
  icon: documentation,
}, {
  label: '8base Academy',
  link: 'https://www.8base.com/8base-academy',
  icon: academy,
}, {
  label: 'Community',
  link: 'https://community.8base.com/',
  icon: discourse,
}, {
  label: 'Tutorials',
  link: 'https://docs.8base.com/backend/getting-started/quick-start',
  icon: tutorials,
}, {
  label: 'SDK',
  link: 'https://docs.8base.com/backend/development-tools/sdk',
  icon: sdk,
}];


type DeveloperHomeNavContentProps = {
  onOpenWorkspaceClick?: OpenWorkspaceHandler,
};


export const DeveloperHomeNavContent = ({ _onOpenWorkspaceClick }: DeveloperHomeNavContentProps) => {
  const { openModal } = useModal();

  const [isOrganizationsOpen, setIsOrganizationsOpen] = useState(false);
  const buildUrl = useBuildUrl();

  const { organizationsList, loading } = useOrganizationsList();

  const organizationsAgencyList = R.filter(
    (organization) =>
      organization.type.toLowerCase() === ORGANIZATION_TYPES.agency.toLowerCase(),
    organizationsList.items,
  );

  const { count: undoneInboxCount, loading: loadingInboxCount } =
    useUndoneInboxCount();

  const toggleOrganizationsOpen = useCallback(() => {
    setIsOrganizationsOpen(!isOrganizationsOpen);
  }, [isOrganizationsOpen]);

  const onCreateOrganizationClick = useCallback(() => {
    openModal(CreateOrganizationDialog.ID);
  }, [openModal]);

  return (
    <Scrollable>
      <DeveloperHomeNavContentTag>
        <Column gap="sm">
          <Row
            cursor="pointer"
            alignItems="center"
            tagName={ MenuRouterLink }
            to={ buildUrl(APP_URL.developerHomeInbox) }
          >
            <InboxIcon style={{ fontSize: 25, marginRight: '10px' }} />
            <StyledText css={ css`text-transform: capitalize;` }>Inbox</StyledText>
            { !loadingInboxCount && undoneInboxCount > 0 && <CountTag size="sm" color={ !!undoneInboxCount ? 'RED' : 'GRAY' }>{ undoneInboxCount }</CountTag> }
          </Row>

          <DividerNav />

          <Row
            cursor="pointer"
            alignItems="center"
            css={ css`margin: 10px 15px !important;` }
          >
            <Text kind="overline-2">Projects</Text>
          </Row>
          <Row
            offsetX="md"
            to={ buildUrl(APP_URL.developerHomeProjects) }
            tagName={ MenuRouterLink }
            exact
            cursor="pointer"
            alignItems="center"
            css={ css` margin-top: ${offset}; ` }
            { ...E2E.$props('developerHome.projects.projects') }
          >
            <StyledMyProjectsIcon fontSize="large">FolderOpen</StyledMyProjectsIcon>
            <StyledText css={{ fontWeight: 600 }} >
              <Trans i18nKey="developerHome.projects.myProjects">
                My Projects
              </Trans>
            </StyledText>
          </Row>

          <Column gap="none" stretch css={ menuItemOffsetCss } >
            <CollapseTitle
              title={ <Trans i18nKey="developerHome.sidebar.organization">Organizations</Trans> }
              toggleOpen={ toggleOrganizationsOpen }
              style={{ paddingLeft: '16px' }}
              isOpen={ isOrganizationsOpen }
              iconPosition="left"
            />
            <Collapse
              isOpened={ isOrganizationsOpen }
              css={ css`
                width: 100%;
              ` }
            >
              <Column css={ topOffsetCss } gap="none">
                { organizationsAgencyList.length > 0 &&
                  organizationsAgencyList.map((organization) => (
                    <Row
                      key={ organization.id }
                      tagName={ MenuRouterLink }
                      to={ buildUrl(APP_URL.developerHomeOrganization, { pathParams: { organizationId: organization.id }}) }
                      alignItems="center"
                      stretch
                      cursor="pointer"
                    >
                      <WorkspaceImage
                        src={ (organization.image && organization.image.downloadUrl) || workspaceDefaultLogo }
                      />
                      <StyledText ellipsis>
                        { organization.name }
                      </StyledText>
                    </Row>
                  )) }
                { !loading && organizationsAgencyList.length === 0 && (
                  <Row css={ menuItemOffsetCss } offsetX="md" alignItems="center">
                    <StyledText color="GRAY_40" >
                      You are not part of an organization.
                    </StyledText>
                  </Row>
                ) }
                { loading && organizationsAgencyList.length === 0 && (
                  <Row css={ menuItemOffsetCss } offsetX="md" alignItems="center">
                    <StyledText color="GRAY_40" >
                      Loading
                    </StyledText>
                    <Loader css={ css`width: 16px; height: 16px;` } size="sm" color="LIGHT_TEXT_COLOR" />
                  </Row>
                ) }

                <Row
                  offsetX="md"
                  onClick={ onCreateOrganizationClick }
                  cursor="pointer"
                  alignItems="center"
                  css={ css` margin-top: ${offset}; ` }
                  { ...E2E.$props('developerHome.sidebar.createNewOrganization') }
                >
                  <Icon color="PRIMARY" name="Add" size="sm" css={ css`width: 16px; height: 16px;` } />
                  <StyledText color="PRIMARY">
                    <Trans i18nKey="developerHome.sidebar.createNewOrganization">
                      Create New Organization
                    </Trans>
                  </StyledText>
                </Row>
              </Column>
            </Collapse>
          </Column>

          <DividerNav />

          <Column gap="none" offsetLeft="md" css={ menuItemOffsetCss }>
            <Row
              cursor="pointer"
              alignItems="center"
              css={ css`margin: 10px 0 !important;` }
            >
              <Text kind="overline-2">Resources</Text>
            </Row>

            <Column css={ topOffsetCss } gap="none">
              { RESOURCES_LINKS.map(({ label, link, icon }, index) => (
                <StyledRowLink
                  key={ label }
                  alignItems="center"
                  css={ index !== RESOURCES_LINKS.length - 1 ? menuItemOffsetCss : topOffsetCss }
                  cursor="pointer"
                  tagName="a"
                  href={ link }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ResourceIcon src={ icon } alt={ label } />
                  <StyledText>{ label }</StyledText>
                </StyledRowLink>
              )) }
            </Column>
          </Column>
        </Column>
      </DeveloperHomeNavContentTag>
    </Scrollable>
  );
};

