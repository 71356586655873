// @flow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';
import { APP_URL } from './url';
import { useCallbackUrl } from '../../utils/hooks/useCallbackUrl';

export type GuestRouteProps ={
  key?: string,
  path?: string,
  exact?: boolean,
  strict?: boolean,

  component?: React$ComponentType<*>,
  render?: (Object) => React$Node,
}

export const GuestRoute = ({ component: Component, render, children, ...rest }: GuestRouteProps) => {
  const { isAuthorized, authClient } = useAuth();
  const { setCallback } = useCallbackUrl();
  const url = new URL(window.location.href);
  const logoutFlag = url.searchParams.get('logout') || null;
  // const source = url.searchParams.get('src') || '8base';
  setCallback(window.location);

  return (
    <Route { ...rest }
      render={ props => {
        if (isAuthorized) {
          if (logoutFlag === 'true') {
            authClient.purgeState();
            return <div />;
          }

          return (
            <Redirect
              to={{
                pathname: APP_URL.developerHome,
              }}
            />
          );
        }

        if (Component) {
          return <Component { ...props } />;
        }
      } }
    />
  );
};
