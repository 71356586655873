// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useMemo } from 'react';
import { Dialog, Grid, Column, Text, Button } from '@8base/boost';
import { useAuth } from '@8base-react/auth';
import { Trans } from 'utils/translate';
// $FlowFixMe waiting for update Flow
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';

import { i18n } from 'i18n';
import {
  ENDPOINT_URI,
  IGNORE_LIMIT_METRIC_ERROR,
  TOAST_SUCCESS_MESSAGE,
} from 'common/constants/apolloOperationContextOptions';
import { APP_URL, buildUrl } from 'common/routing';
import { TEAM_INVITATIONS_DETAILS_QUERY } from 'graphql/queries';
import { useQueryParams } from 'utils/hooks';
import {
  TEAM_INVITATION_ACCEPT_MUTATION,
} from '../graphql/mutations';

const getInviteInfo = (data: any) => {
  const invitation = data && data.system && data.system.memberInvitation;

  if (!invitation) {
    return {
      invitedByName: '',
      invitedByEmail: '',
      projectName: '',
      accepted: null,
    };
  }

  const { invitedBy, accepted } = invitation;

  const invitedByName = `${invitedBy.firstName || ''} ${invitedBy.lastName || ''}`.trim();
  const invitedByEmail = invitedBy.email;
  const projectName = invitedBy?.projectName || '';
  const projectId = invitedBy?.projectId || '';
  const isAnswered = !!accepted;

  return {
    invitedByName,
    invitedByEmail,
    projectId,
    projectName,
    isAnswered,
  };
};

export const ProjectAcceptInviteDialog = () => {
  const auth = useAuth();
  const [queryParams] = useQueryParams();

  const invitationId = useMemo(
    () => queryParams.project_invite_id,
    [queryParams],
  );

  const hostInvitation = useMemo(
    () => queryParams.host,
    [queryParams],
  );

  const history = useHistory();
  const location = useLocation();

  const isOnboarding = !!matchPath(location.pathname, {
    path: APP_URL.onboarding,
  });

  const { data } = useQuery(TEAM_INVITATIONS_DETAILS_QUERY, {
    skip:
      !invitationId ||
      isOnboarding ||
      !auth.isAuthorized ||
      !auth.isEmailVerified,
    variables: {
      id: invitationId,
    },
  });

  const [acceptOrDeclineInvitation, { loading: acceptLoading }] = useMutation(
    TEAM_INVITATION_ACCEPT_MUTATION,
    {
      context: {
        [IGNORE_LIMIT_METRIC_ERROR]: true,
        [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
      },
    },
  );

  const onJoin = useCallback(async (projectId) => {
    try {
      await acceptOrDeclineInvitation({
        context: {
          [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
          [ENDPOINT_URI]: hostInvitation,
        },
        variables: {
          data: {
            id: invitationId,
            accepted: true,
          },
        },
      });
      if (projectId) {
        const url = buildUrl(APP_URL.projectHome, {
          pathParams: { projectId },
        });
        history.replace(url);
      }
    } catch (e) {}
  }, [acceptOrDeclineInvitation, history, invitationId, hostInvitation]);

  const onDecline = useCallback(async () => {
    try {
      await acceptOrDeclineInvitation({
        context: {
          [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.declineSuccessfull'),
        },
        variables: {
          id: invitationId,
          accepted: false,
        },
      });
    } finally {
      history.replace(location.pathname);
    }
  }, [acceptOrDeclineInvitation, history, invitationId, location.pathname]);

  const onClose = useCallback(async () => {
    history.replace(location.pathname);
  }, [history, location.pathname]);

  const {
    invitedByName,
    invitedByEmail,
    projectId,
    projectName,
    isAnswered,
    acceptedMessage,
  } = useMemo(() => {
    const info = getInviteInfo(data);
    return {
      ...info,
      acceptedMessage: info.isAnswered
        ? 'But this invitation was answered already'
        : 'Join now to start collaborating',
    };
  }, [data]);

  return (
    <Dialog
      isOpen={ !!invitationId && !!data }
      size="sm"
      padding="xl"
      shouldCloseOnOverlayClick={ false }
    >
      <Dialog.Body>
        <Column gap="xl">
          <Grid.Layout stretch columns="350px" justifyContent="center">
            <Grid.Box>
              <Text color="SECONDARY_TEXT_COLOR" align="center">
                <Trans i18nKey="home.projectInvite.hasInvited">
                  { invitedByName }{ ' ' }
                  <Text weight="semi-bold" css={{ wordBreak: 'break-all' }}>
                    ({ invitedByEmail })
                  </Text>{ ' ' }
                  <Text weight="semi-bold">{ projectName }</Text>
                  <Text>{ acceptedMessage }</Text>
                </Trans>
              </Text>
            </Grid.Box>
          </Grid.Layout>
          <Grid.Layout
            stretch
            columns={ `repeat(${isAnswered ? 1 : 2}, 12rem)` }
            justifyContent="center"
            gap="md"
          >
            { isAnswered && (
              <Grid.Box>
                <Button
                  stretch
                  color="neutral"
                  variant="outlined"
                  onClick={ onClose }
                >
                  <Trans i18nKey="shared.close">Close</Trans>
                </Button>
              </Grid.Box>
            ) }
            { !isAnswered && (
              <>
                <Grid.Box>
                  <Button
                    stretch
                    color="neutral"
                    variant="outlined"
                    loading={ acceptLoading }
                    onClick={ onDecline }
                  >
                    <Trans i18nKey="shared.decline">Decline</Trans>
                  </Button>
                </Grid.Box>
                <Grid.Box>
                  <Button
                    stretch
                    color="primary"
                    type="submit"
                    loading={ acceptLoading }
                    onClick={ () => onJoin(projectId) }
                  >
                    <Trans i18nKey="shared.join">Join</Trans>
                  </Button>
                </Grid.Box>
              </>
            ) }
          </Grid.Layout>
        </Column>
      </Dialog.Body>
    </Dialog>
  );
};

ProjectAcceptInviteDialog.ID = 'ProjectAcceptInviteDialog';
