/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-closing-tag-location */
// @flow
// $FlowFixMe
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
// $FlowFixMe
import { AsyncContent, Column, Row, useModal, Input, Icon } from '@8base/boost';
// $FlowFixMe waiting for update Flow
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import ListIcon from '@material-ui/icons/List';
import { FolderOpen } from '@material-ui/icons';
import AppsIcon from '@material-ui/icons/Apps';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { APP_URL, useBuildUrl } from 'common';
import {
  useProjectsList,
  useOrganization,
  useUserAccountInfo,
} from 'graphql/hooks';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { PlusButton as StyledPlusButton } from 'common/components';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { apolloProjectsListSelectors } from 'graphql/selectors';
import { Trans } from 'utils/translate';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import {
  DeveloperHomeContentPlate,
  DeveloperHomeProjects,
} from '../../../components';
import { TeamMemberRemoveFromOrganizationDialog } from 'dialogs/TeamMemberRemoveFromOrganizationDialog';
import { DeveloperHomeOrganizationIndividualHeader } from '../components/DeveloperHomeOrganizationIndividualHeader';
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';
import {
  getUserRoleAtOrganization,
  getUserListFromOrganization,
} from 'utils/organization';
// $FlowFixMe
import { useApiHost } from 'providers';
import { TeamMemberInviteToProjectOrganizationDialog } from 'dialogs/TeamMemberInviteToProjectOrganizationDialog';
import { IntegratedAuthConfirmationDialog } from 'dialogs/IntegratedAuthConfirmationDialog';

import { useUI, SET_DIALOG, DIALOGS_IDS } from 'providers/UIProvider';

const VerticalDivider = styled('div')`
  border-left: 1px solid #cfd7de;
  height: 32px;
`;

type DeveloperHomeProjectsContainerProps = {
  onOpenProjectClick?: OpenWorkspaceHandler,
};

const StyledMyProjectsIcon = styled(FolderOpen)`
  font-size: 35px !important;
`;

const StyledMyProjectText = styled('div')`
  color: #323c47;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
`;

const WhiteContentPlate = styled(DeveloperHomeContentPlate)`
  width: calc(100% - 370px);
  background-color: ${(props) => props.theme.COLORS.WHITE};
`;

export const DeveloperHomeProjectsContainer = ({
  onOpenProjectClick,
}: DeveloperHomeProjectsContainerProps) => {
  const { organizationId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const buildUrl = useBuildUrl();
  const history = useHistory();
  const { openModal } = useModal();
  const { organization } = useOrganization(organizationId, {
    fetchPolicy: 'network-only',
  });
  const { dispatch } = useUI();

  const { myProjects, sharedProjects, loading } =
    useProjectsList(organizationId);

  const searchInput = useRef(null);
  const [myProjectsList, setMyProjectsList] = useState([]);
  const [sharedProjectsList, setSharedProjectsList] = useState([]);
  const [search, setSearch] = useState('');
  const [listView, setListView] = useState(false);
  const { openModal: openAuthModal } = useModal(IntegratedAuthConfirmationDialog.ID);
  const { setApiHost } = useApiHost();

  const onFocusSearch = useCallback(() => {
    if (!searchInput.current) {
      return;
    }
    searchInput.current.focus();
  }, [searchInput]);

  const onClearSearch = useCallback(() => {
    setSearch('');
    onFocusSearch();
    setMyProjectsList(
      apolloProjectsListSelectors.getSharedProjectsList(myProjects, {
        withAlphabetSorting: true,
      }),
    );
    setSharedProjectsList(
      apolloProjectsListSelectors.getSharedProjectsList(sharedProjects, {
        withAlphabetSorting: true,
      }),
    );
  }, [onFocusSearch, myProjects, sharedProjects]);

  const onCreateProjectClick = useCallback(
    (kind?: string) => () => {
      dispatch({
        type: SET_DIALOG,
        payload: {
          isOpen: true,
          title: t('auth.confirmDialog.title'),
          message: t('auth.confirmDialog.text'),
          organizationName: R.pathOr('', ['name'], organization),
          organizationId,
          type: DIALOGS_IDS.PROJECT_CREATE,
        },
      });
    },
    [openAuthModal, openModal, organization, organizationId],
  );

  const onOpenProjectDetail = ({ projectId, apiHost }) => {
    setApiHost(apiHost);
    history.push(
      buildUrl(APP_URL.projectDashboard, { pathParams: { projectId }}),
    );
  };

  useEffect(() => {
    if (!loading) {
      setMyProjectsList(
        apolloProjectsListSelectors.getSharedProjectsList(myProjects, {
          withAlphabetSorting: true,
        }),
      );
      setSharedProjectsList(
        apolloProjectsListSelectors.getSharedProjectsList(sharedProjects, {
          withAlphabetSorting: true,
        }),
      );
    }
  }, [myProjects, sharedProjects, loading]);

  const onQueryChange = useCallback(
    (value) => {
      if (value !== '') {
        setMyProjectsList(
          apolloProjectsListSelectors
            .getSharedProjectsList(myProjects, { withAlphabetSorting: true })
            .filter((item) =>
              item.name.toLowerCase().includes(value.toLowerCase()),
            ),
        );
        setSharedProjectsList(
          apolloProjectsListSelectors
            .getSharedProjectsList(sharedProjects, {
              withAlphabetSorting: true,
            })
            .filter((item) =>
              item.name.toLowerCase().includes(value.toLowerCase()),
            ),
        );
      } else {
        setMyProjectsList(
          apolloProjectsListSelectors.getSharedProjectsList(myProjects, {
            withAlphabetSorting: true,
          }),
        );
        setSharedProjectsList(
          apolloProjectsListSelectors.getSharedProjectsList(sharedProjects, {
            withAlphabetSorting: true,
          }),
        );
      }
      setSearch(value);
    },
    [myProjects, sharedProjects],
  );

  const isEmptySearchQuery = R.isEmpty(search);

  const handleViewClick = (isList) => {
    setListView(isList);
  };

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const userRoleAtOrganization = useMemo(
    () => getUserRoleAtOrganization(userAccountInfo, organization),
    [organization, userAccountInfo],
  );

  const onUserClick = useCallback(() => {
    history.push(
      buildUrl(APP_URL.organizationSettingsTeam, {
        pathParams: { organizationId },
      }),
      { prevLocation: location },
    );
  }, [buildUrl, history, organizationId, location]);

  const onAddUserClick = useCallback(() => {
    if (!organization || !organization.id) {
      return null;
    }

    openModal(TeamMemberInviteToProjectOrganizationDialog.ID, {
      organizationId: organization.id,
      permissionRole: userRoleAtOrganization,
    });
  }, [openModal, organization, userRoleAtOrganization]);

  const userList = useMemo(
    () => getUserListFromOrganization(organization),
    [organization],
  );

  const onClickSettings = useCallback(() => {
    history.push(
      buildUrl(APP_URL.organizationSettingsGeneral, {
        pathParams: { organizationId },
      }),
      { prevLocation: location },
    );
  }, [buildUrl, history, organizationId, location]);

  const onRemoveFromOrganization = useCallback(() => {
    openModal(TeamMemberRemoveFromOrganizationDialog.ID, {
      organizationName: R.pathOr('', ['name'], organization),
      organizationId,
      userWorkspaceList: [],
      firstName: userAccountInfo.firstName,
      lastName: userAccountInfo.lastName,
      email: userAccountInfo.email,
    });
  }, [userAccountInfo, openModal, organizationId, organization]);

  return (
    <AsyncContent stretch loading={ loading }>
      <WhiteContentPlate { ...E2E.$props('developerHome.workspaces.root') }>
        <Column
          gap="lg"
          css={ css`
            width: 100%;
            min-width: 350px;
          ` }
          alignItems="stretch"
        >
          <Column
            gap="md"
            css={ css`
              width: 100%;
            ` }
            alignItems="stretch"
          >
            <Row>
              <Choose>
                <When condition={ organizationId && !!organization }>
                  { organizationId && !!organization && (
                    <DeveloperHomeOrganizationIndividualHeader
                      name={ organization.name }
                      type={ organization.type }
                      onUserClick={ onUserClick }
                      image={ organization.image && organization.image.downloadUrl }
                      description={ organization.description }
                      onClickSettings={ onClickSettings }
                      userList={ userList }
                      onRemoveFromOrganization={ onRemoveFromOrganization }
                      onAddUserClick={ onAddUserClick }
                      permissionRole={ userRoleAtOrganization }
                    />
                  ) }
                </When>
              </Choose>
            </Row>
          </Column>
          <Row justifyContent="between" alignItems="center">
            <Row alignItems="center">
              <StyledMyProjectsIcon>FolderOpen</StyledMyProjectsIcon>
              <Choose>
                <When condition={ organizationId && !!organization }>
                  { organizationId && !!organization && (
                    <StyledMyProjectText>
                      Organization Projects
                    </StyledMyProjectText>
                  ) }
                </When>
                <Otherwise>
                  { <StyledMyProjectText>My Projects</StyledMyProjectText> }
                </Otherwise>
              </Choose>
            </Row>
            <Row>
              <Input
                placeholder={ t('shared.searchProjects') }
                insideRef={ searchInput }
                leftIcon={ <>
                  <If condition={ isEmptySearchQuery }>
                    <Icon
                      name="TopSearch"
                      color="GRAY_40"
                      css={ css`
                          width: 16px;
                          height: 16px;
                        ` }
                      onClick={ onFocusSearch }
                    />
                  </If>
                  <If condition={ false }>
                    <Icon
                      name="Cancel"
                      color="GRAY_40"
                      css={ css`
                          width: 16px;
                          height: 16px;
                        ` }
                      onClick={ onClearSearch }
                    />
                  </If>
                  </> }
                value={ search }
                onChange={ onQueryChange }
              />
              <Row
                css={ css`
                  border-style: solid;
                  border-width: 1px;
                  border-radius: 5px;
                  border-color: #cfd7de;
                ` }
              >
                <AppsIcon
                  onClick={ (e) => handleViewClick(false) }
                  style={{
                    color: listView ? '#D1D7DD' : '#323C47',
                    fontSize: 19,
                    marginLeft: '10px',
                    marginTop: '6px',
                    cursor: 'pointer',
                  }}
                />
                <VerticalDivider />
                <ListIcon
                  onClick={ (e) => handleViewClick(true) }
                  style={{
                    color: listView ? '#323C47' : '#D1D7DD',
                    fontSize: 19,
                    marginTop: '6px',
                    marginRight: '10px',
                    cursor: 'pointer',
                  }}
                />
              </Row>
            </Row>
          </Row>

          <DeveloperHomeProjects
            myProjectsList={ myProjectsList }
            isCompany={ !!organizationId }
            sharedProjectsList={ sharedProjectsList }
            listView={ listView }
            onOpenProjectClick={ onOpenProjectDetail }
            organization={ organization }
            createButton={ <>
              <If
                condition={ userRoleAtOrganization === ORGANIZATION_ROLE.owner ||
                    userRoleAtOrganization === ORGANIZATION_ROLE.admin ||
                    organizationId === undefined }
              >
                <StyledPlusButton
                  onClick={ onCreateProjectClick(WORKSPACE_KIND.project) }
                  { ...E2E.$props('developerHome.workspaces.createNewProject') }
                >
                  <Trans i18nKey="developerHome.workspaces.createNewProject">
                      Create Project
                  </Trans>
                </StyledPlusButton>
              </If>
              </> }
          />
        </Column>
      </WhiteContentPlate>
    </AsyncContent>
  );
};
