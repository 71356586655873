// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
// $FlowFixMe
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { Dialog, useModal } from '@8base/boost';
import { Form } from '@8base-react/forms';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useUserAccountInfo } from 'graphql/hooks';
import { APP_URL, useBuildUrl } from 'common/routing';
import { apolloSelectors } from 'graphql/selectors';
import { TOAST_SUCCESS_MESSAGE, DIALOG_ID, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { useWorkspace } from 'providers/WorkspaceProvider';
import { getUserRoleAtOrganization } from 'utils/organization';
import { TeamMemberInviteDialogContent } from './TeamMemberInviteDialog.Content';
import { checkIs8baseUser } from '../utils/help';
import { ORGANIZATION_ROLE } from 'common/constants/organization';

const INVITE_USER_DIALOG_ID = 'INVITE_USER_DIALOG_ID';

const ROLES_LIST_QUERY = gql`
  query TeamMemberInviteDialog_RolesList {
    rolesList {
      items {
        id
        name
        description
      }
    }
  }
`;

const INVITE_MEMBERS_MUTATION = gql`
  mutation InviteMembers($data: SystemInviteMembersInput!, $force: Boolean) {
    system {
      inviteMembers(data: $data, force: $force) {
        id
      }
    }
  }
`;

const getRoleOptions = R.pipe(
  apolloSelectors.rolesList.getRolesList,
  R.reject(R.propEq('name', 'Guest')),
  R.map(({ name, id }) => ({ label: name, value: id })),
);

const TeamMemberInviteDialog = () => {
  const history = useHistory();
  const buildUrl = useBuildUrl();
  const workspace = useWorkspace();
  const { t } = useTranslation();

  const { closeModal, args: { opensInvitations = false }} = useModal(INVITE_USER_DIALOG_ID);

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
  });

  const permissionRole = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, workspace.organization),
  [workspace.organization, userAccountInfo]);

  const [inviteUser] = useMutation(INVITE_MEMBERS_MUTATION, {
    notifyOnNetworkStatusChange: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: t('inviteUserDialog.success'),
      [DIALOG_ID]: INVITE_USER_DIALOG_ID,
    },
    refetchQueries: [
      'TeamMembersList',
    ],
    awaitRefetchQueries: true,
  });

  const { data: rolesData, loading: rolesLoading } = useQuery(ROLES_LIST_QUERY, {
    skip: !workspace.id || permissionRole === ORGANIZATION_ROLE.developer,
  });

  const onClose = useCallback(() => {
    closeModal(INVITE_USER_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async (data) => {
    const force = checkIs8baseUser({ email: R.path(['recipients', '0', 'email'], data) });
    await inviteUser({ variables: { data, force }});

    window.trackEvent('Team Members', 'Invite member');

    onClose();

    if (opensInvitations) {
      history.push(buildUrl(APP_URL.settingsTeamInvitations));
    }
  }, [buildUrl, history, inviteUser, onClose, opensInvitations]);


  return (
    <Dialog id={ INVITE_USER_DIALOG_ID } onClose={ onClose } { ...E2E.$props('dialogs.teamMemberInvite.body') }>
      <Form onSubmit={ onSubmit } >
        { ({ handleSubmit, submitting, pristine, dirtySinceLastSubmit }) => {
          return (
            <form onSubmit={ handleSubmit }>
              <TeamMemberInviteDialogContent
                onClose={ onClose }
                rolesOptions={ getRoleOptions(rolesData) }
                rolesLoading={ rolesLoading }
                submitDisabled={ (pristine || submitting) && !dirtySinceLastSubmit }
                submitLoading={ submitting }
              />
            </form>
          );
        } }
      </Form>
    </Dialog>
  );
};

TeamMemberInviteDialog.ID = INVITE_USER_DIALOG_ID;

export { TeamMemberInviteDialog };
