export const INBOX_TYPE = {
  envInvite: 'EnvironmentInvitation',
  orgInvite: 'OrganizationInvitation',
  projectInvite: 'ProjectInvitation',
  notification: 'Notification',
};

export const INBOX_NOTIFICATION_KIND = {
  appBuilderBeta: 'AppBuilderBeta',
};

export const INBOX_STATUS = {
  sent: 'sent',
  accepted: 'accepted',
  declined: 'declined',
};
