import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18n
  .use(LanguageDetector)
  .use(XHR)
  .init({
    fallbackLng: 'en',

    // have a common namespace used around the full app
    defaultNS: 'translation',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export { i18n };
