import React from 'react';
import {
  Card, Row, Column, Grid,
} from '@8base/boost';
import { css } from '@emotion/core';
import { Trans } from 'utils/translate';

import { BillingCurrentPlanResponse, BillingDetailsResponse } from 'graphql/__generated__';
import { StyledText } from './StyledComponents';
import styled from '@emotion/styled';


type BillingCardBodyProps = {
  plan: BillingCurrentPlanResponse,
  card?: BillingDetailsResponse,
  remainTrialDays?: number,
  workspaceName?: string;
  nextPaymentDate?: string;
  fromPaymentDate?: string;
  showPlanDetails?: boolean;
}

const StyledDataText = styled('div')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const BillingCardBody = ({
  plan,
  card,
  remainTrialDays,
  overagesPrice = 0,
  workspaceName,
  showPlanDetails = false,
  countWorksPaces,
  countUsers,
  nextPaymentDate,
  fromPaymentDate,
  ...rest
}: BillingCardBodyProps) => {

  return (
    <Card.Footer { ...rest }>

      <Row alignItems="center" css={ css`margin-bottom: 40px` }>

        <Grid.Layout columns="1fr 1fr 1fr 1fr" >
          <Column>
            <Row>
              <StyledText css={ css`  margin-right: 1px!important;` } >
                <Trans i18nKey="project.billing.estimate.period">
                    Billing Period
                </Trans>
              </StyledText>
            </Row>
            <StyledDataText>
              { fromPaymentDate } - { nextPaymentDate }
            </StyledDataText>
          </Column>

          <Column css={ css`  padding-left: 40px;` }>
            <Row>
              <StyledText css={ css`  margin-right: 1px!important;` } >
                <Trans i18nKey="project.billing.estimate.nextPayment">
                    Next Payment Date
                </Trans>
              </StyledText>
            </Row>
            <StyledDataText>
              { nextPaymentDate }
            </StyledDataText>
          </Column>

          <Column css={ css`  padding-left: 40px;` }>
            <Row>
              <StyledText color="LIGHT_TEXT_COLOR" css={ css`  margin-right: 1px!important;` } >
                <Trans i18nKey="project.billing.estimate.totalWorkspaces">
                    Total Workspaces
                </Trans>
              </StyledText>
            </Row>
            <StyledDataText>
              { countWorksPaces }
            </StyledDataText>
          </Column>

          <Column css={ css`  padding-left: 40px;` }>
            <Row>
              <StyledText css={ css`  margin-right: 1px!important;` } >
                <Trans i18nKey="project.billing.estimate.total">
                    Total Users
                </Trans>
              </StyledText>
            </Row>
            <StyledDataText>
              { countUsers }
            </StyledDataText>
          </Column>

        </Grid.Layout>
      </Row>
    </Card.Footer>
  );
};
