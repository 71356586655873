// @flow
// $FlowFixMe waiting for update Flow
import {
  Row, Text,
} from '@8base/boost';
import css from '@emotion/css';
import React from 'react';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

// $FlowFixMe waiting for update Flow
import { Redirect, useParams } from 'react-router-dom';

import { APP_URL, useBuildUrl } from 'common/routing';
import { SettingsPageContainer as SettingsPage } from './containers/settingsPage.container';


export const ProjectSettings = ({ shouldRedirect }: *) => {
  const buildUrl = useBuildUrl();
  const { projectId } = useParams();

  if (shouldRedirect) {
    const fallbackUrl = buildUrl(APP_URL.projectDashboard, {
      pathParams: {
        projectId,
      },
      ignoreWorkspaceId: true,
    });

    return <Redirect to={ fallbackUrl } />;
  }

  return (
    <div>
      <Row
        justifyContent="between"
        alignItems="stretch"
        css={ css`
        width: 100%;
        min-width: 350px;
      ` }
      >
        <Row alignItems="center">
          <SettingsOutlinedIcon style={{ fontSize: 32 }} />
          <Text kind="h1">Settings</Text>
        </Row>
      </Row>
      <SettingsPage projectId={ projectId } />
    </div>
  );
};
