// @flow

import React from 'react';
import { Dialog, Button, useModal } from '@8base/boost';
import { i18n } from 'i18n';

const { Header, Body, Footer } = Dialog;

const CONFIRM_DIALOG = 'CONFIRM_DIALOG';

const ConfirmDialog = () => {
  const {
    closeModal,
    args: {
      confirmButton = i18n.t('shared.yes'),
      cancelButton = i18n.t('shared.no'),
      okButtonColor = 'danger',
      title = i18n.t('shared.confirm'),
      message = i18n.t(''),
      loading,
      onConfirm,
    },
  } = useModal(CONFIRM_DIALOG);

  const onClose = () => closeModal(CONFIRM_DIALOG);

  const onConfirmHandle = async () => {
    try {
      await onConfirm();
    } catch (e) {}
    onClose();
  };

  return (
    <Dialog id={ CONFIRM_DIALOG } size="sm">
      <Header title={ title } onClose={ onClose } />
      <Body>{ message }</Body>
      <Footer>
        <Button color="neutral" variant="outlined" onClick={ onClose }>
          { cancelButton }
        </Button>
        <Button
          type="submit"
          loading={ loading }
          onClick={ onConfirmHandle }
          color={ okButtonColor }
        >
          { confirmButton }
        </Button>
      </Footer>
    </Dialog>
  );
};

ConfirmDialog.ID = CONFIRM_DIALOG;

export { ConfirmDialog };
