import { Table, Text } from '@8base/boost';
import {
  TableCellActions,
  TableCellStatus,
  TableCellUserAvatar,
} from '../../../../../pro';
import React from 'react';
import styled from '@emotion/styled';
import { Title } from './Title';
import { useTranslation } from 'react-i18next';

const { BodyCell, BodyRow, Body, HeaderCell, Header } = Table;

const StyledAvatarContainer = styled('div')`
  min-width: 32px;
`;

const StyledHeader = styled(Header)`
  align-items: end;
`;

const ProjectMembersTable = ({
  headers,
  data,
  title,
  loading,
  showOptions,
  handlers: { deleteUser, cancelInvitation, editInvitation, resendInvitation },
}: props) => {
  const { t } = useTranslation();

  const actions = {
    owner: [],
    member: [
      {
        id: 'delete',
        title: t('shared.delete'),
        handler: (data) => deleteUser(data),
      },
    ],
    guest: [
      {
        id: 'edit',
        title: t('shared.edit'),
        handler: (data) => editInvitation(data),
      },
      {
        id: 'resendInvitation',
        title: t('settings.general.resendInvitation'),
        handler: (data) => resendInvitation(data),
      },
      {
        id: 'cancelInvitation',
        title: t('shared.cancelInvitation'),
        handler: (data) => cancelInvitation(data),
      },
    ],
  };

  const getUserType = (status, role) => {
    return ['active', 'inactive'].indexOf(status) >= 0
      ? role?.items?.filter((rol) => rol.name === 'owner').length === 1
        ? 'owner'
        : 'member'
      : 'guest';
  };

  return (
    <>
      <Title weight="bold" top="32px" bottom="12px">
        { title }
      </Title>
      <Table css={{ height: 'auto' }}>
        <StyledHeader columns="minmax(0,1fr) minmax(0,1fr) 1.5fr 130px 65px">
          { headers.map((header, key) => (
            <HeaderCell key={ key }>{ header }</HeaderCell>
          )) }
          <HeaderCell />
        </StyledHeader>
        <Body data={ data } loading={ loading }>
          { ({ email, firstName, lastName, avatar, roles, status }: member) => {
            return (
              <BodyRow columns="minmax(0,1fr) minmax(0,1fr) 1.5fr 130px 65px" key={ email }>
                <BodyCell>
                  <StyledAvatarContainer>
                    <TableCellUserAvatar
                      firstName={ firstName || email }
                      lastName={ lastName || '' }
                      src={ avatar?.downloadUrl }
                    />
                  </StyledAvatarContainer>
                  <Text
                    css={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    { firstName || email } { lastName || '' }
                  </Text>
                </BodyCell>
                <BodyCell>
                  <Text
                    css={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    { email }
                  </Text>
                </BodyCell>
                <BodyCell
                  css={{
                    display: 'flex',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Text ellipsis>
                    { roles?.items?.map((rol) => rol.name).join(', ') }
                  </Text>
                </BodyCell>
                <BodyCell>
                  <TableCellStatus status={ status } />
                </BodyCell>
                <BodyCell>
                  <Choose>
                    <When condition={ showOptions && (getUserType(status, roles) !== 'owner') }>
                      <TableCellActions
                        actions={ actions[getUserType(status, roles)].map(
                          (item) => {
                            return {
                              ...item,
                              handler: () => item.handler({ email, firstName, lastName, avatar, role: roles }),
                            };
                          },
                        ) }
                      />
                    </When>
                    <Otherwise>
                      <Text />
                    </Otherwise>
                  </Choose>
                </BodyCell>
              </BodyRow>
            );
          } }
        </Body>
      </Table>
    </>
  );
};
export { ProjectMembersTable };
