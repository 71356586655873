// @flow
import { useQuery, type QueryHookOptions } from 'react-apollo';
import { useAuth } from '@8base-react/auth';
import * as R from 'ramda';

import { USER_PAYMENT_DETAILS_QUERY } from 'graphql/queries';

// TODO: add types to query: QueryResult<Query, Variables>

const getCard = R.path(['system', 'memberPaymentDetails']);

export const useUserPaymentDetails = (options?: QueryHookOptions = {}) => {
  const { isAuthorized } = useAuth();

  const {
    data,
    loading,
  } = useQuery(USER_PAYMENT_DETAILS_QUERY, {
    ...options,
    skip: !isAuthorized || options.skip,
  });

  return {
    loading,
    card: getCard(data),
  };
};
