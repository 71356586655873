import React, { useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { Heading, Text, Column, Button } from '@8base/boost';

import { Trans } from 'utils/translate';
import * as LINKS from 'config/links';

import mobileImage from 'images/Mobile.svg';

const Container = styled('div')`
  background-color: #FFF;
  display: flex;
  width: 36rem;
  position: fixed;
  left: 50%;
  top: 50%;
  flex-direction: column;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const MobileImage = styled('img')`
  width: 100%;
`;

export const Mobile = () => {
  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  return (
    <Container>
      <Column gap="lg" alignItems="center">
        <MobileImage src={ mobileImage } />
        <Heading type="h2">
          <Trans i18nKey="mobile.heading">
          Oops! Our platform is optimized<br />for desktop use.
          </Trans>
        </Heading>
        <Text>
          <Trans i18nKey="mobile.textMiddle">
          For the best experience, please access 8base<br />from a desktop or laptop.
          </Trans>
        </Text>
        <Text>
          <Trans i18nKey="mobile.textEnd">
          Thanks for your understanding!
          </Trans>
        </Text>
        <Button tagName="a" href={ LINKS.LANDING_URL }>
          <Trans i18nKey="mobile.action">
          Back to 8base.com
          </Trans>
        </Button>
      </Column>
    </Container>
  );
};
