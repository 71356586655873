import React, { useCallback } from 'react';
import { Radio, Tooltip } from '@8base/boost';
import styled from '@emotion/styled';

import { PlanRadioItemTag, ItemsContainer } from './CssUtils';

import { PLANS } from 'graphql/constants/billing';

import {
  AUTH_PROJECT_KIND,
} from 'common/constants/authProjectKind';

import { SharedRadioItem } from './SharedRadioItem';

const RadioGroupScrollableTag = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  padding-top: 5px;
  top: -10px;
`;

const PlanRadioItem = ({ active, disabled, disabledTooltipText, children }) => {
  const itemTag = (
    <PlanRadioItemTag
      active={ active }
      disabled={ disabled }
      alignItems="baseline"
    >
      { children }
    </PlanRadioItemTag>
  );

  if (disabled) {
    return (
      <Tooltip
        message={ (
          <div >
            { disabledTooltipText }
          </div>
        ) }
        cursor="default"
      >
        { itemTag }
      </Tooltip>
    );
  }

  return itemTag;
};

export const KindOfAuthenticationRadioGroupField = ({ plans, isFreePlanAvailable, card, with8base, input, ...props }) => {

  const getIsDisabled = useCallback((planName) => {
    return false;
  }, []);

  const getDisabledTooltipText = useCallback((planName) => {
    if (planName !== PLANS.freeTier) {
      return 'Update your payment details by clicking on the link below.';
    }
  }, []);

  return (
    <RadioGroupScrollableTag>
      <ItemsContainer
        { ...props }
        input={{ ...input }}
        direction="row"
        gap="md"
        alignItems="stretch"
        stretch
      >
        <Radio.Item
          key="withAccelerators"
          value={ AUTH_PROJECT_KIND.coded_login }
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ checked }
              disabled={ getIsDisabled('With-Accelerators') }
              disabledTooltipText={ getDisabledTooltipText('With-Accelerators') }
              alignItems="flex-start"
            >
              <SharedRadioItem
                hasIcons={ false }
                name="With-Accelerators"
                displayName="In-App Authentication"
                description="Let your app control and manage the entire authentication process. Ideal for tailored experiences."
                display="block"
                textAlign="left"
                alignItems="baseline"
              />
            </PlanRadioItem>
          ) }
        </Radio.Item>
        <Radio.Item
          key="fromScratch_auth"
          value={ AUTH_PROJECT_KIND.hosted_login }
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ checked }
              disabled={ getIsDisabled('From-Scratch') }
              disabledTooltipText={ getDisabledTooltipText('From-Scratch') }
              alignItems="flex-start"
            >
              <SharedRadioItem
                hasIcons={ false }
                name="From-Scratch"
                displayName="Hosted Authentication"
                description="Leverage a cloud-hosted authentication interface that's maintained and secured for you. Seamless integration with minimal setup."
                display="block"
                textAlign="left"
                alignItems="baseline"
              />
            </PlanRadioItem>
          ) }
        </Radio.Item>
        <Radio.Item
          key="fromScratch"
          value={ AUTH_PROJECT_KIND.no_authentication }
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ checked }
              disabled={ getIsDisabled('From-Scratch') }
              disabledTooltipText={ getDisabledTooltipText('From-Scratch') }
              alignItems="flex-start"
            >
              <SharedRadioItem
                hasIcons={ false }
                name="From-Scratch"
                displayName="DIY Authentication"
                description="Start from the ground up. Code and configure your personalized authentication mechanism."
                display="block"
                textAlign="left"
                alignItems="baseline"
              />
            </PlanRadioItem>
          ) }
        </Radio.Item>

      </ItemsContainer>
    </RadioGroupScrollableTag>
  );
};
