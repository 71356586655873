// @flow
// $FlowFixMe
import React from 'react';
import { Row, Column, Text } from '@8base/boost';
import styled from '@emotion/styled';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';


const WorkspaceImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;

  width: 32px;
  height: 32px;
  margin-right: 18px;
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
  border-radius: 5px;
`;

export const ProjectCard = ({ image, kind, workspaceName, workspaceId }:*) => {


  return (
    <Row alignItems="center">
      <WorkspaceImage src={ (image && image.downloadUrl) || workspaceDefaultLogo } />
      <Column gap="none" css={{ overflow: 'hidden' }}>
        <Text kind="small-1" color="GRAY_50">
          { workspaceName }
        </Text>
      </Column>
    </Row>);
};
