import React from 'react';
import { Form } from '@8base-react/forms';
import { Dialog, Button, useModal, Card } from '@8base/boost';
import { i18n } from 'i18n';
import * as R from 'ramda';

import { TOAST_SUCCESS_MESSAGE, PROJECT_ID, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { ProjectInfoFields } from 'common/ProjectInfo';
import { useProjectUpdate } from '../graphql/hooks/useProjectUpdate';

const PROJECT_UPDATE_DIALOG_ID = 'PROJECT_EDIT_DIALOG';

const ProjectUpdateDialog = () => {
  const {
    closeModal,
    args: {
      projectId,
      projectName,
      projectDescription,
      projectImage,
      apiHost,
      webSocketUri,
      isOwner,
      region,
      kind,
    },
  } = useModal(PROJECT_UPDATE_DIALOG_ID);

  const [projectUpdate] = useProjectUpdate(projectId, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('projectUpdateDialog.successfullyUpdated'),
      [ENDPOINT_URI]: apiHost,
      [PROJECT_ID]: projectId,
    },
  });

  const onClose = () => closeModal(PROJECT_UPDATE_DIALOG_ID);

  const onSubmit = async (data) => {
    const { image } = data;

    const getNewImage = () => {
      if (image && projectImage) {
        if (image.downloadUrl === projectImage.downloadUrl) {
          return undefined;
        }
      }

      if (image && image.fileId) {
        return {
          create: {
            fileId: image.fileId,
            filename: image.filename,
            public: true,
          },
        };
      }

      return undefined;
    };

    const imageData = getNewImage();

    const newData = R.omit(['region'], data);

    await projectUpdate({ variables: {
      data: {
        ...newData,
        id: projectId,
        image: imageData,
      },
    }});

    onClose();
  };

  return (
    <Dialog id={ PROJECT_UPDATE_DIALOG_ID } size="xl" onClose={ onClose }>
      <Form
        onSubmit={ onSubmit }
        initialValues={{
          name: projectName,
          image: projectImage,
          description: projectDescription,
          region,
        }}
      >
        { ({ handleSubmit, invalid, pristine, submitting }) => (
          <form onSubmit={ handleSubmit }>
            <Dialog.Header title="Project Info" onClose={ onClose } />
            <Dialog.Body padding="none">
              <Card.Section>
                <ProjectInfoFields
                  workspaceId={ projectId }
                  apiHost={ apiHost }
                  isOwner={ isOwner }
                  webSocketUri={ webSocketUri }
                  e2eWorkspaceNameFieldProps={ E2E.$props('dialogs.workspaceUpdate.workspaceNameInput') }
                  e2eWorkspaceIdInputProps={ PT.$props('dialogs.workspaceUpdate.copyWorkspaceIdInput') }
                  kind={ kind }
                />
              </Card.Section>

            </Dialog.Body>
            <Dialog.Footer>
              <Button type="button" color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>
                { i18n.t('shared.cancel') }
              </Button>
              <Button
                type="submit"
                disabled={ !isOwner || invalid || pristine }
                loading={ submitting }
                { ...E2E.$props('dialogs.workspaceUpdate.saveChangesBtn') }
              >
                { i18n.t('shared.saveChanges') }
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

ProjectUpdateDialog.ID = PROJECT_UPDATE_DIALOG_ID;

export { ProjectUpdateDialog };
