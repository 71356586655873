import gql from 'graphql-tag';

export const PROJECT_CREATE_MUTATION = gql`
  mutation CreateProject($data: SystemProjectCreateMutationInput!) {
    system {
      projectCreate(data: $data) {
        sessionId
      }
    }
  }
`;

export const PROJECT_DELETE_MUTATION = gql`
  mutation projectDelete($projectId: ID!) {
    system {
      projectDelete(projectId: $projectId) {
        sessionId
      }
      __typename
    }
  }
`;


export const PROJECT_UPDATE_MUTATION = gql`
  mutation ProjectUpdate($data: SystemProjectUpdateMutationInput!) {
    system {
      projectUpdate(data: $data) {
        id
        name
        description
        image {
          id
          downloadUrl
        }
      }
    }
  }
`;


export const PROJECT_SHARED_ORGANIZATION_MUTATION = gql`
 mutation projectShareOrganization($email: String! $projectId: ID!) {
    system {
      organizationProjectUserShare(
        email: $email
        projectId: $projectId
      ) {
        success
      }
    }
  }
`;

export const PROJECT_SHARED_ENV_ORGANIZATION_MUTATION = gql`
 mutation projectShareOrganization($email: String! $projectId: ID! $environmentRoles: [SystemOrganizationProjectEnvironmentRolesInput]) {
    system {
      organizationProjectUserShare(
        email: $email
        projectId: $projectId
        environmentRoles: $environmentRoles
      ) {
        success
      }
    }
  }
`;

export const PROJECT_ORGANIZATION_USER_REMOVE = gql`
  mutation OrganizationProjectUserRemove($email: String! $projectId: ID!) {
    system {
      organizationProjectUserRemove(email: $email, projectId: $projectId) {
        success
      }
    }
  }
`;

