// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';


import * as R from 'ramda';
import { DateTime } from 'luxon';
import { useQuery } from 'react-apollo';
import { useAuth } from '@8base-react/auth';


import {
  BILLING_CURRENT_PLAN_QUERY,
  BILLING_PLANS_QUERY,
} from 'graphql/queries';
import { IGNORE_WORKSPACE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { mapRegionToEndPoint } from 'utils';

import { useWorkspace } from '../WorkspaceProvider';

const BillingContext = React.createContext(null);

type BillingProviderProps = {
  children: React$Node,
  skip: bool,
};


const setContextValue = ({
  billingCurrentPlanLoading,
  plansDataLoading,
  plansData,
  billingCurrentPlan,
  workspacesListData,
  workspace,
}) => {
  const loading = billingCurrentPlanLoading;

  let contextValue = { loading, plans: [] };

  if (!loading) {
    const plans = R.pathOr([], ['system', 'userBillingConfiguration', 'availablePlans'], plansData);
    const plan = R.prop('billingCurrentPlan', billingCurrentPlan);

    const planDescription = R.find(R.propEq('id', R.prop('id', plan)), plans);

    contextValue = R.assoc('plans', plans, contextValue);
    contextValue = R.assoc('plan', { ...planDescription, ...plan }, contextValue);

    const trialEndDt = R.prop('trialEnd', plan);

    if (trialEndDt) {
      const { days } = DateTime.fromISO(trialEndDt).diffNow('days').toObject();

      contextValue = R.assoc('remainTrialDays', Math.ceil(days), contextValue);
    }

    contextValue = R.assoc('isFreePlanAvailable', R.pathOr(0, ['system', 'userBillingConfiguration', 'isFreePlanAvailable'], plansData), contextValue);
    contextValue = R.assoc('refetchPlansData', R.prop('refetch', plansData), contextValue);
  }

  return contextValue;
};

const BillingProvider = ({ children, skip }: BillingProviderProps) => {
  const workspace = useWorkspace();
  const auth = useAuth();

  const [prevRegionEndpoint, setPrevRegionEndpoint] = useState(mapRegionToEndPoint(workspace.region));
  const [regionEndpoint, setCurrentRegionEndpoint] = useState(mapRegionToEndPoint(workspace.region));

  const setRegionEndpoint = useCallback((currentEndpoint) => {
    setPrevRegionEndpoint(regionEndpoint);
    setCurrentRegionEndpoint(currentEndpoint);
  }, [regionEndpoint]);

  const {
    data: billingCurrentPlan,
    loading: billingCurrentPlanLoading,
  } = useQuery(BILLING_CURRENT_PLAN_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !auth.isAuthorized || !auth.isEmailVerified || !workspace.id || skip,
  });

  const {
    data: plansData,
    loading: plansDataLoading,
    refetch: plansDataRefetch,
  } = useQuery(BILLING_PLANS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !auth.isAuthorized,
    context: {
      [IGNORE_WORKSPACE]: true,
      [ENDPOINT_URI]: regionEndpoint,
    },
  });

  useEffect(() => {
    if (prevRegionEndpoint === regionEndpoint) {
      return;
    }
    plansDataRefetch().then(() => {
      setPrevRegionEndpoint(regionEndpoint);
    });
  }, [prevRegionEndpoint, regionEndpoint, plansDataRefetch]);

  const contextValue = useMemo(() => {
    return setContextValue({
      billingCurrentPlanLoading,
      plansDataLoading,
      plansData,
      billingCurrentPlan,
      workspace,
    });
  }, [billingCurrentPlanLoading, plansDataLoading, plansData, billingCurrentPlan, workspace]);

  return (
    <BillingContext.Provider
      value={{
        ...contextValue,
        setRegionEndpoint,
        regionEndpoint,
        refetchPlansData: plansDataRefetch,
        loading: contextValue.loading,
        planDataLoading: prevRegionEndpoint !== regionEndpoint || plansDataLoading,
      }}
    >
      { children }
    </BillingContext.Provider>
  );
};

export { BillingContext, BillingProvider };

