import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import {
  Button,
  Column,
  Dialog,
  InputField,
  Paragraph,
  Text,
  useModal,
  Loader,
} from '@8base/boost';
import { css } from '@emotion/core';
import { Form, Field } from '@8base-react/forms';
import {
  CHECK_PROJECT_DELETE_QUERY, PROJECTS_USER_LIST_QUERY,
} from 'graphql/queries';
import { useProjectsList } from '../graphql/hooks';
import { WORKSPACE_DELETE_MUTATION } from 'graphql/mutations';
import { deleteWorkspaceFromCache } from 'utils/apollo/updateCache';
import { Trans } from 'utils/translate';
import { i18n } from '../i18n';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APP_URL } from 'common/routing';

const FRONTEND_DELETE_DIALOG_ID = 'FRONTEND_DELETE_DIALOG_ID';

const POLL_INTERVAL = 8000;

const DELETE_PROJECT_STATUS_SUCCESS = 'complete_success';
const DELETE_PROJECT_STATUS_ERROR = 'complete_error';


const FrontEndDeleteDialog = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState();

  const {
    closeModal,
    args: { workspaceId,
      onSuccess,
      projectId, projectName, organizationId },
  } = useModal(FRONTEND_DELETE_DIALOG_ID);
  const organizationID = organizationId ? organizationId : projectId;
  const { refetch } = useProjectsList(organizationID);

  const onClose = useCallback(() => {
    closeModal(FRONTEND_DELETE_DIALOG_ID);
  }, [closeModal]);

  const onCheckWorkspaceDelete = useCallback(
    async (completedData) => {
      if (!completedData) {
        setSessionId(null);
      }
      const status = R.path(
        ['system', 'asyncSessionStatus', 'status'],
        completedData,
      );

      switch (status) {
        case DELETE_PROJECT_STATUS_SUCCESS: {
          const onFinish = () => {
            setSessionId(null);
            toast.success('Project deleted successfully');
            setIsLoading(false);
            if (history.location.pathname.includes('settings')) {
              refetch().then(() => history.replace(APP_URL.developerHomeProjects));
            } else {
              refetch().then(() => closeModal());
            }
            onClose();
          };
          if (refetch) {
            // $FlowFixMe
            refetch().then(() => onFinish());
          } else {
            onFinish();
          }
          break;
        }
        case DELETE_PROJECT_STATUS_ERROR: {
          setSessionId(null);
          setIsLoading(false);
          toast.error('Something went wrong. Please contact support@8base.com.');
          setSessionId(null);
          break;
        }
        default: {
        }
      }
    },
    [closeModal, history, onClose, refetch],
  );

  useQuery(CHECK_PROJECT_DELETE_QUERY, {
    variables: { sessionId },
    skip: !sessionId,
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: PROJECTS_USER_LIST_QUERY,
      variables: { organizationId },
    }],
    fetchPolicy: 'network-only',
    pollInterval: sessionId ? POLL_INTERVAL : 0,
    onCompleted: (data) => onCheckWorkspaceDelete(data),
  });

  const [workspaceDelete, { loading }] = useMutation(WORKSPACE_DELETE_MUTATION, {
    update: (cache) => {
      deleteWorkspaceFromCache({ workspaceId, cache });
    },
  });

  const validate = useCallback(
    (value) =>
      value === projectName ? null : i18n.t('validation.valueNoMatch'),
    [projectName],
  );

  const onSubmit = useCallback(
    async () => {
      try {
        setIsLoading(true);
        await workspaceDelete({
          variables: { data: { workspaceId }},
        });

        setIsLoading(false);
        onClose();
        if (typeof onSuccess === 'function') {
          onSuccess(true);
        }
      } catch (e) {
        throw e;
      }
    },
    [onClose, onSuccess, workspaceDelete, workspaceId],
  );

  return (
    <Dialog
      id={ FRONTEND_DELETE_DIALOG_ID }
      size="md"
      onClose={ onClose }
      shouldCloseOnOverlayClick={ !loading }
      shouldCloseOnEscPress={ !loading }
    >
      <Form onSubmit={ onSubmit }>
        { ({ handleSubmit, invalid, pristine, submitting }) => {
          if (isLoading) {
            return (
              <div style={{ padding: '20px', display: 'flex', justifyItems: 'center', alignContent: 'center' }}>
                <Loader stretch />
              </div>
            );
          } else {
            return (
              <form onSubmit={ handleSubmit }>
                <Dialog.Header
                  title={ i18n.t('settings.general.FrontEndDeleteDialog.title') }
                  onClose={ onClose }
                />
                <Dialog.Body>
                  <Column gap="lg">
                    <Paragraph
                      css={ css`
                        width: 100%;
                      ` }
                      align="center"
                    >
                      <Trans i18nKey="settings.general.FrontEndDeleteDialog.note">
                        Are you sure you want to delete <Text weight="bold">{ projectName }</Text>.<br />
                        This action is irreversible and will delete all associated information.
                      </Trans>
                    </Paragraph>
                    <Field
                      name="name"
                      type="text"
                      placeholder={ i18n.t(
                        'settings.general.projectDeleteDialog.placeholder') }
                      validate={ validate }
                      component={ InputField }
                    />
                  </Column>
                </Dialog.Body>
                <Dialog.Footer>
                  <Button
                    type="button"
                    color="neutral"
                    variant="outlined"
                    disabled={ submitting || loading || isLoading }
                    onClick={ onClose }
                  >
                    <Trans i18nKey="shared.cancel">Cancel</Trans>
                  </Button>
                  <Button
                    color="danger"
                    type="submit"
                    disabled={ invalid || pristine }
                    loading={ isLoading }
                  >
                    <Trans i18nKey="shared.delete">Delete</Trans>
                  </Button>
                </Dialog.Footer>
              </form>
            );
          }
        } }
      </Form>
    </Dialog>
  );
};

FrontEndDeleteDialog.ID = FRONTEND_DELETE_DIALOG_ID;

export default FrontEndDeleteDialog;
