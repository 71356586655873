import { Column, Text } from '@8base/boost';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { CountTag } from 'common/components';
import { APP_URL, useBuildUrl } from 'common/routing';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Trans } from 'utils/translate';

//icons
import { ReactComponent as BackendIcon } from 'images/icons/icon-data-cloud.svg';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import TeamIcon from '@material-ui/icons/GroupOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { ReactComponent as DashboardIcon } from 'images/icons/icon-dashboard.svg';

const offset = '0px';

const StyledText = styled(Text)`
  line-height: 18px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
`;

const activeLinkCss = (props) => css`
  &.active {
    color: ${props.theme.COLORS.BLUE_20} !important;
    background: ${props.theme.COLORS.WHITE};
  }

  &.active > span {
    color: ${props.theme.COLORS.BLUE_20} !important;
  }
`;

const hoverCss = (props) => css`
  & > *:not(${CountTag}) {
    transition: all .3s ease;
  }

  &:hover > *:not(${CountTag}) {
    color: ${props.theme.COLORS.BLUE_20}
  }
`;

const MenuRouterLink = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 0 !important;
  cursor: pointer;
  display:flex;
  align-items:center;

  ${hoverCss}
  ${activeLinkCss}
`;

export const ProjectHomeNavContent = ({ isOwner, isOwnerOrAdminOrganization }) => {
  const { projectId } = useParams();
  const buildUrl = useBuildUrl();
  const history = useHistory();
  const menuLocationChanged = (location:string) => {
    history.replace(location);
  };

  return (
    <Column css={ css`padding: 24px 24px 24px 24px;height:100%;` } gap="lg">
      <MenuRouterLink
        onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.projectDashboard, { pathParams: { projectId }})) }
        cursor="pointer"
        className={ history.location.pathname === `${APP_URL.projectDashboard.replace(':projectId', projectId)}` ? 'active' : '' }
        css={ css` margin-top: ${offset}; ` }
        { ...E2E.$props('projects.dashboard') }
      >
        <DashboardIcon fontSize="large" />
        <StyledText>
          <Trans i18nKey="projects.dashboard.leftMenu">
            Dashboard
          </Trans>
        </StyledText>
      </MenuRouterLink>
      <MenuRouterLink
        cursor="pointer"
        onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.projectBackend, { pathParams: { projectId }})) }
        className={ history.location.pathname === `${APP_URL.projectBackend.replace(':projectId', projectId)}` ? 'active' : '' }
        css={ css` margin-top: ${offset}; ` }
        { ...E2E.$props('projects.backend') }
      >
        <BackendIcon fontSize="large" />
        <StyledText>
          <Trans i18nKey="projects.backend">
            Backend
          </Trans>
        </StyledText>
      </MenuRouterLink>
      <MenuRouterLink
        cursor="pointer"
        onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.projectUI, { pathParams: { projectId }})) }
        className={ history.location.pathname === `${APP_URL.projectUI.replace(':projectId', projectId)}` ? 'active' : '' }
        css={ css` margin-top: ${offset}; ` }
        { ...E2E.$props('projects.userInterfaces') }
      >
        <DesktopWindowsIcon fontSize="large" />
        <StyledText>
          <Trans i18nKey="projects.userInterfaces">
            Frontends
          </Trans>
        </StyledText>
      </MenuRouterLink>
      <MenuRouterLink
        cursor="pointer"
        onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.projectTeam, { pathParams: { projectId }})) }
        className={ history.location.pathname === `${APP_URL.projectTeam.replace(':projectId', projectId)}` ? 'active' : '' }
        css={ css` margin-top: ${offset}; ` }
        { ...E2E.$props('projects.team') }
      >
        <TeamIcon fontSize="large" />
        <StyledText>
          <Trans i18nKey="projects.team">
            Team
          </Trans>
        </StyledText>
      </MenuRouterLink>
      <If condition={ isOwner || isOwnerOrAdminOrganization }>
        <MenuRouterLink
          onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.projectBilling, { pathParams: { projectId }})) }
          className={ history.location.pathname === `${APP_URL.projectBilling.replace(':projectId', projectId)}` ? 'active' : '' }
          cursor="pointer"
          css={ css` margin-top: ${offset}; ` }
          { ...E2E.$props('projects.billing') }
        >
          <MonetizationOnOutlinedIcon fontSize="large" />
          <StyledText>
            <Trans i18nKey="projects.billing">
              Billing
            </Trans>
          </StyledText>
        </MenuRouterLink>
      </If>
      <If condition={ isOwner || isOwnerOrAdminOrganization }>
        <MenuRouterLink
          cursor="pointer"
          onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.projectSettings, { pathParams: { projectId }})) }
          className={ history.location.pathname === `${APP_URL.projectSettings.replace(':projectId', projectId)}` ? 'active' : '' }
          css={ css` margin-top: ${offset}; ` }
          { ...E2E.$props('projects.settings') }
        >
          <SettingsOutlinedIcon fontSize="large" />
          <StyledText>
            <Trans i18nKey="projects.settings">
              Settings
            </Trans>
          </StyledText>
        </MenuRouterLink>
      </If>
    </Column>
  );
};
