// @flow

export const DB_BASE_URL = '/data';

export const DB_DATA_TYPES = {
  schema: 'schema',
  data: 'data',
};

export const DB_RESERVED_PATHS = {
  newFiled: 'new',
  newTable: 'new',
};

export const DB_MAIN_FORM_NAMES = {
  dataBuilderSchemaHandler: 'DataBuilderSchemaHandler',
  dataBuilderField: 'DataBuilderField',
  dataBuilderMainSchemaHead: 'DataBuilderMainSchemaHead',
  dataViewerRecordEditor: 'DataViewerRecordEditor',
};

export const DB_DEFAULT_TABLE_NAME = 'Untitled Table';

export const SUPPORTED_FIELD_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  FILE: 'FILE',
  RELATION: 'RELATION',
  SWITCH: 'SWITCH',
  SMART: 'SMART',
  JSON: 'JSON',
  GEO: 'GEO',
};

export const NOT_SUPPORTED_FIELD_TYPES = {
  ID: 'ID',
  UUID: 'UUID',
  MISSING_RELATION: 'MISSING_RELATION',
  ONE_WAY_RELATION: 'ONE_WAY_RELATION',
};

export const FIELD_TYPES = {
  ...SUPPORTED_FIELD_TYPES,
  ...NOT_SUPPORTED_FIELD_TYPES,
};

export type FieldTypes = $Values<typeof FIELD_TYPES>;

export type SwitchFormats = 'ON_OFF' | 'YES_NO' | 'TRUE_FALSE' | 'ACTIVE_INACTIVE' | 'HIGH_LOW' | 'SMART' | string;

export const SWITCH_FORMATS: { [key: SwitchFormats]: SwitchFormats } = {
  ON_OFF: 'ON_OFF',
  YES_NO: 'YES_NO',
  TRUE_FALSE: 'TRUE_FALSE',
  ACTIVE_INACTIVE: 'ACTIVE_INACTIVE',
  HIGH_LOW: 'HIGH_LOW',
  CUSTOM: 'CUSTOM',
};

export const TEXT_FORMATS = {
  UNFORMATTED: 'UNFORMATTED',
  NAME: 'NAME',
  ADDRESS: 'ADDRESS',
  SSN: 'SSN',
  EIN: 'EIN',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  MARKDOWN: 'MARKDOWN',
  HTML: 'HTML',
};

export const SMART_FORMATS = {
  ADDRESS: 'ADDRESS',
  PHONE: 'PHONE',
};

export const NUMBER_FORMATS = {
  NUMBER: 'NUMBER',
  CURRENCY: 'CURRENCY',
  PERCENTAGE: 'PERCENTAGE',
  FRACTION: 'FRACTION',
  SCIENTIFIC: 'SCIENTIFIC',
};

export const FILE_FORMATS = {
  FILE: 'FILE',
  IMAGE: 'IMAGE',
};

export const DATE_FORMATS = {
  DATE: 'DATE',
  DATETIME: 'DATE/TIME',
};

export const GEO_FORMATS = {
  GEOMETRY: 'GEOMETRY',
  POINT: 'POINT',
  LINESTRING: 'LINESTRING',
  POLYGON: 'POLYGON',
};

export const DEFAULT_FORMAT_BY_FIELD_TYPE = {
  [FIELD_TYPES.TEXT]: TEXT_FORMATS.UNFORMATTED,
  [FIELD_TYPES.FILE]: FILE_FORMATS.FILE,
  [FIELD_TYPES.DATE]: DATE_FORMATS.DATE,
  [FIELD_TYPES.SWITCH]: SWITCH_FORMATS.ON_OFF,
  [FIELD_TYPES.SMART]: SMART_FORMATS.ADDRESS,
  [FIELD_TYPES.NUMBER]: NUMBER_FORMATS.NUMBER,
  [FIELD_TYPES.GEO]: GEO_FORMATS.POINT,
};

export type TextFormats = $Keys<typeof TEXT_FORMATS>;

export const TRUTHY = true;
export const FALSY = false;
export const UNDEFINED_DEFAULT_VALUE = null;
export const UNDEFINED_DEFAULT_LABEL = 'Undefined';

/** list of the initial values for the all formats except CUSTOM */
export const switchDefaultValues: {[key: SwitchFormats]: Object} = {
  ON_OFF: {
    On: TRUTHY,
    Off: FALSY,
  },
  YES_NO: {
    Yes: TRUTHY,
    No: FALSY,
  },
  TRUE_FALSE: {
    True: TRUTHY,
    False: FALSY,
  },
  ACTIVE_INACTIVE: {
    Active: TRUTHY,
    Inactive: FALSY,
  },
  HIGH_LOW: {
    High: TRUTHY,
    Low: FALSY,
  },
};

export const RELATION_MODE = Object.freeze({
  ForeignKey: 'ForeignKey',
  Pivot: 'Pivot',
});

export const RELATION_TYPES = {
  oneToOne: 'oneToOne',
  manyToOne: 'manyToOne',
  manyToMany: 'manyToMany',
  oneToMany: 'oneToMany',
};

export const RELATION_LABELS = {
  [RELATION_TYPES.oneToOne]: 'one-to-one',
  [RELATION_TYPES.manyToOne]: 'many-to-one',
  [RELATION_TYPES.manyToMany]: 'many-to-many',
  [RELATION_TYPES.oneToMany]: 'one-to-many',
};
