// @flow
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

const ENVIRONMENT_SETTINGS_UPDATE_MUTATION = gql`
  mutation EnvironmentSettings($data: EnvironmentSetupInput) {
    system {
      environmentSetup(data: $data) {
        success
      }
    }
  }
`;

export const useUpdateEnvironmentSettings = () => {
  const [update, { data, loading }] = useMutation(ENVIRONMENT_SETTINGS_UPDATE_MUTATION);

  return { update, data, loading };
};
