// @flow

import gql from 'graphql-tag';

export const PROJECTS_USER_LIST_QUERY = gql`
  query ProjectsList($organizationId: ID!) {
    system {
      projectsUserListQuery(organizationId: $organizationId) {
        myProjects {
          items {
            id
            name
            apiHost
            createdAt
            description
            lastAccess
            kind
            apiHost
            image {
              downloadUrl
              id
            }
            region
            organization {
              id
              name
            }
            owner {
              avatar {
                id
                downloadUrl
              }
              firstName
              lastName
              email
            }
            plan {
              id
              name
              displayName
            }
            usersCount
          }
          count
        }
        sharedProjects {
          items {
            id
            name
            apiHost
            createdAt
            description
            lastAccess
            kind
            apiHost
            image {
              downloadUrl
              id
            }
            region
            organization {
              id
              name
            }
            owner {
              avatar {
                id
                downloadUrl
              }
              firstName
              lastName
              email
            }
            usersCount
          }
          count
        }
      }
    }
  }
`;

export const PROJECT_INFO_QUERY = gql`
  query projectInfoQuery($projectId: ID!) {
    system {
      projectInfoDetails(projectId: $projectId) {
        apiHost
        authenticationInfo {
          environmentName
          environmentId
          authProfile{
            id
            name
            type
          }
        }
        id
        name
        overagesPrice
        description
        createdAt
        kind
        lastAccess
        region
        apiHost
        backendUtilization {
          tables
          field
          databaseRows
          functions
          roles
          authProfiles
          fileStorageSize
        }
        environmentsProject {
          items {
            id
            name
            workspaceId
            workspaceName
            worksApiHost
            tables
            functions
            fields
          }
        }
        backendWorkspaces {
          count
          items {
            is8BaseAuthEnabled
            apiHost
            id
            name
            description
            createdAt
            lastAccess
            region
            kind
            webSocket
            image {
              downloadUrl
              id
            }
            summaryABInfo {
              CDN
              assets
              customStates
              functions
              layouts
              libraries
              pages
              requests
              resources
            }
            lastDeployABInfo {
              dateDeploy
              statusDeploy
              urlDeploy
              versionDeploy
              versionFrontEnd
              userDeploy {
                email
                firstName
                lastName
              }
            }
          }
        }
        image {
          id
          downloadUrl
        }
        organization {
          id
          description
          name
          type
        }
        plan {
          id
          name
          price
          status
          displayName
          trialEnd
          paymentDate
        }
        paymentDetail {
          last4
          expMonth
          expYear
          brand
          origin
        }
        metricUsagesQuotas {
          items {
            value
            limitMetric {
              description
              displayName
              name
              showPriority
              tooltip
              unit
            }
          }
          count
        }
        owner {
          avatar {
            id
            downloadUrl
          }
          firstName
          lastName
          email
        }
        metricUsages {
          items {
            limitMetric {
              description
              displayName
              name
              showPriority
              tooltip
              unit
            }
            overage {
              value
              warning
            }
            value
          }
          count
        }
      }
    }
  }
`;

export const PROJECT_PLAN_QUERY = gql`
  query projectPlan($projectId: ID!) {
    system {
      projectPlan(projectId: $projectId) {
        plan{
          id
          name
          price
          status
          displayName
          trialEnd
          paymentDate
          nextPlan {
            displayName
            id
            name
            price
          }
        }
        paymentDetail {
          last4
          expMonth
          expYear
          brand
          origin
        }
        overagesPrice
      }
    }
  }
`;

export const PROJECT_QUOTAS_QUERY = gql`
  query projectQuotas($projectId: ID!) {
    system {
      projectQuotas(projectId: $projectId) {
        metricUsagesQuotas {
          items {
            value
            limitMetric {
              description
              displayName
              name
              showPriority
              tooltip
              unit
            }
          }
          count
        }
        metricUsages {
          items {
            limitMetric {
              description
              displayName
              name
              showPriority
              tooltip
              unit
            }
            overage {
              value
              warning
            }
            value
          }
          count
        }
      }
    }
  }
`;

export const PROJECT_FRONTEND_QUERY = gql`
  query projectFrontend($projectId: ID!) {
    system {
      projectFrontend(projectId: $projectId) {
        frontendUtilization {
          assets
          customStates
          functions
          layouts
          libraries
          pages
          resources
        }
        frontendWorkspaces {
          count
          items {
            is8BaseAuthEnabled
            apiHost
            id
            name
            description
            createdAt
            lastAccess
            region
            kind
            webSocket
            image {
              downloadUrl
              id
            }
            summaryABInfo {
              CDN
              assets
              customStates
              functions
              layouts
              libraries
              pages
              requests
              resources
            }
            lastDeployABInfo {
              dateDeploy
              statusDeploy
              urlDeploy
              versionDeploy
              versionFrontEnd
              userDeploy {
                email
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_DEPLOYMENTS_QUERY = gql`
  query projectDeployments($projectId: ID!) {
    system {
      projectDeployments(projectId: $projectId) {
        deploymentsProject {
          items {
            workspaceId
            name
            dateDeploy
            statusDeploy
            urlDeploy
            versionDeploy
            versionFrontEnd
          }
        }
      }
    }
  }
`;

export const CHECK_PROJECT_CREATION_QUERY = gql`
  query CheckProjectCreation($sessionId: String!) {
    system {
      asyncSessionStatus(sessionId: $sessionId) {
        result
        status
      }
    }
  }
`;

export const CHECK_PROJECT_DELETE_QUERY = gql`
  query CheckProjectDelete($sessionId: String!) {
    system{
      asyncSessionStatus(sessionId: $sessionId) {
        result
        status
      }
    }
  }
`;
