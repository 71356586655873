import React, { useState } from 'react';
import { Dialog, useModal } from '@8base/boost';
import { Avatar, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import styled from '@emotion/styled';
import interfaceDefaultLogo from '../images/interface-default-avatar.svg';
import { UserListItemCard } from '../routes/project/routes/UI/components/UserListItemCard';

const StyledHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 16px;
  height: 40px;
  border-radius: 8px 8px 0 0;
  background-color: #E9EEF2;
`;

const StyledTitle = styled('span')`
  height: 22px;
  width: 428.82px;
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

const StyledBody = styled('div')`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const StyledSubtitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSubtitle = styled('span')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
`;

const StyledMembersCount = styled('div')`
  width: 100%;
  color: #5B636C;
  font-family: Poppins,serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;


const StyledContainer = styled('div')`
  border-top: 1px #D1D7DD solid;
  border-bottom: 1px #D1D7DD solid;
  margin-bottom: -1px;
`;

const USER_INTERFACE_MEMBERS_DIALOG = 'USER_INTERFACE_MEMBERS_DIALOG';

const UserInterfaceMembersDialog = () => {
  const { closeModal,
    args: {
      workspace,
      users = [],
      usersCount,
    }} = useModal(USER_INTERFACE_MEMBERS_DIALOG);
  const [searchText, setSearchText] = useState('');

  return (
    <Dialog
      id={ USER_INTERFACE_MEMBERS_DIALOG }
      size="lg"
      onClose={ () => closeModal(USER_INTERFACE_MEMBERS_DIALOG) }
    >
      <StyledHeader>
        <StyledTitle>Team Members</StyledTitle>
        <IconButton onClick={ () => closeModal(USER_INTERFACE_MEMBERS_DIALOG) }>
          <Close />
        </IconButton>
      </StyledHeader>

      <StyledBody>

        <StyledSubtitleContainer>
          <Avatar
            alt="frontend logo"
            style={{ height: 32, width: 32, backgroundColor: '#ABB1B8' }}
            src={ workspace?.image || interfaceDefaultLogo }
            imgProps={{ style: { height: 20, width: 20 }}}
          />
          <StyledSubtitle>{ workspace?.name }</StyledSubtitle>
        </StyledSubtitleContainer>

        <div style={{ width: '100%' }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search by name"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={ searchText }
            onChange={ (e) => { setSearchText((e?.target?.value || '').toLowerCase()); } }
          />
        </div>

        <StyledMembersCount>
          { usersCount === 1 && <>{usersCount} members</> }
          { usersCount >= 2 && <>{usersCount} member</> }
        </StyledMembersCount>

        <div style={{ width: '100%', height: 420, overflowY: 'auto' }}>
          { users
            .filter(user => `${user?.firstName} ${user?.lastName}`.toLowerCase().includes(searchText))
            .map((user) => (
              <StyledContainer key={ user?.id }>
                <UserListItemCard user={ user || {} } />
              </StyledContainer>
            )) }
        </div>
      </StyledBody>

    </Dialog>
  );
};

UserInterfaceMembersDialog.ID = USER_INTERFACE_MEMBERS_DIALOG;
export default UserInterfaceMembersDialog;
