import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { RadioGroupField } from '@8base/boost';

export const ImageDisplay = styled.div`
  align-items: center;
  border-radius: 3.6px;
  height: 32px;
  justify-content: center;
  width: 36px;
`;

export const ToggleContainer = styled.div`
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  overflow: hidden;

  &.show {
    opacity: 1;
    max-height: 490px;
  }

  &.hideee {
    opacity: 0;
    max-height: 0;
  }
`;
export const PlanRadioItemTag = styled.div`
  align-items: ${({ alignItems }) => alignItems ? `${alignItems} !important` : 'center'};
  background-color: ${(props) => props.active ? '#EDF5FF' : '#F4F8FA' };
  border-radius: 8px;
  border: ${(props) => props.active ? '1px solid var(--grays-gray-40, #A3AFB7)' : '0px'};
  border: ${({ active }) => active ? '1px solid' : 'none' };
  box-shadow: ${(props) => props.active ? '0px 2px 4px 0px rgba(50, 50, 93, 0.20)' : ''};
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  padding: 12px;
  width: 100%;

  &:hover {
    border: 1px solid var(--grays-gray-40, #A3AFB7);
    box-shadow: 0px 2px 4px 0px rgba(50, 50, 93, 0.20);
    border-radius: 8px;
    background-color: #EDF5FF;
  }
`;

export const itemClass = css`
  background-color: ${(props) => props.active ? '#EDF5FF' : '#F4F8FA' };`;

export const showCompleteItem = css`
  min-height: 185px;
`;

export const hiddenCompleteItem = css`
  min-height: 0px;
`;

export const ItemsContainer = styled(RadioGroupField)`
  display: grid;
  gridTemplateColumns: 1fr 1fr;
  gap: 20px;
  margin: 0 auto;

  div div {
    display: flex;
    align-items: center;
    gap: 16px;

    label {
      height: 100%;
      margin-right: 0 !important;
      width: 100%;
    }
  }
`;
