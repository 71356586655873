/* eslint-disable react-hooks/rules-of-hooks */
// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';

import { HIDE_TOAST_ERROR_MESSAGE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { PROJECT_PLAN_QUERY } from 'graphql/queries';

export const useProjectPlan = (projectId: string, apiHost?: string, options?: QueryHookOptions) => {

  const { isAuthorized } = useAuth();

  const { data, ...rest }: QueryResult<any, any> = useQuery(PROJECT_PLAN_QUERY, {
    skip: !projectId || !isAuthorized,
    fetchPolicy: 'cache-and-network',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [ENDPOINT_URI]: apiHost,
    },
    ...options,
    variables: {
      ...options,
      projectId,
    },
  });

  const projectPlan: any | null = (data && data.system && data.system.projectPlan) || null;
  return {
    projectPlan, ...rest,
  };
};
