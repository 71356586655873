// @flow

import gql from 'graphql-tag';


export const BACKEND_ENVIRONMENTS_LIST_QUERY = gql`
  query environmentsListByWorkspace($workspaceId: String) {
  system {
    environmentsList(workspaceId: $workspaceId) {
      items {
        id
        name
      }
    }
  }
}
  `;

