import gql from 'graphql-tag';

const TEAM_INVITATIONS_DETAILS_FRAGMENT = gql`
  fragment InvitationDetailInfo on SystemMemberInvitation {
    id
    firstName
    lastName
    email
    isRegistered
    apiHost
    invitedBy {
      email
      firstName
      lastName
      workspaceName
      projectId
      projectName
    }
  }
`;

export const TEAM_INVITATIONS_DETAILS_QUERY = gql`
  query InvitationDetails($id: String!) {
    system {
      memberInvitation(id: $id) {
        ...InvitationDetailInfo
      }
    }
  }

  ${TEAM_INVITATIONS_DETAILS_FRAGMENT}
`;
