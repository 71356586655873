// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import qs from 'qs';

import type { Selector } from 'reselect';
import type { ApolloRolesListResponse } from './rolesList.selectors';
import { PROFILE_TYPE } from '../constants';
import { getHostedLoginUrlBase } from 'utils/processEnv';
import { environmentAccessor, MASTER_ENVIRONMENT } from 'utils/environmentAccessor';
import type { SystemWorkspaceItem } from '../__generated__';

export type ApolloAuthenticationProfile = $Shape<{
  id: string,
  name: string,
  secret: string,
  clientId: string,
  domain: string,
  type: string,
  selfSignupEnabled: boolean,
  selfSignupEmailDomains: string[],
  roles: ApolloRolesListResponse,
  attributes?: {
    clientAuthDomain: string,
  }
}>;

export const getAuthneticationProfile: Selector<
  ApolloAuthenticationProfile,
  any,
  ApolloAuthenticationProfile,
> = (authenticationProfile) => authenticationProfile;

export const getId: Selector<
  ApolloAuthenticationProfile,
  any,
  string,
> = createSelector(
  getAuthneticationProfile,
  R.prop('id'),
);

export const getName: Selector<
  ApolloAuthenticationProfile,
  any,
  string,
> = createSelector(
  getAuthneticationProfile,
  R.prop('name'),
);

export const getSecret: Selector<
  ApolloAuthenticationProfile,
  any,
  string,
> = createSelector(
  getAuthneticationProfile,
  R.prop('secret'),
);

export const getClientId: Selector<
  ApolloAuthenticationProfile,
  any,
  string,
> = createSelector(
  getAuthneticationProfile,
  R.prop('clientId'),
);

export const getDomain: Selector<
  ApolloAuthenticationProfile,
  any,
  string,
> = createSelector(
  getAuthneticationProfile,
  R.prop('domain'),
);

export const getSelfSignUpEmailDomains: Selector<
  ApolloAuthenticationProfile,
  any,
  string[],
> = createSelector(
  getAuthneticationProfile,
  R.propOr([], 'selfSignUpEmailDomains'),
);

export const getType: Selector<
  ApolloAuthenticationProfile,
  any,
  boolean,
> = createSelector(
  getAuthneticationProfile,
  R.prop('type'),
);

export const isSelfSignUpEnabled: Selector<
  ApolloAuthenticationProfile,
  any,
  boolean,
> = createSelector(
  getAuthneticationProfile,
  R.prop('selfSignUpEnabled'),
);

export const getRoles: Selector<
  ApolloAuthenticationProfile,
  any,
  ApolloRolesListResponse,
> = createSelector(
  getAuthneticationProfile,
  R.prop('roles'),
);

export const getCognitoClientAuthDomain: Selector<
  ApolloAuthenticationProfile,
  any,
  string | void,
  > = createSelector(
    getAuthneticationProfile,
    R.path(['attributes', 'clientAuthDomain']),
  );

export const getCognitoLoginUrl: Selector<
  ApolloAuthenticationProfile,
  any,
  string | void,
  > = createSelector(
    getCognitoClientAuthDomain,
    getClientId,
    (clientAuthDomain, clientId) => clientAuthDomain && `${clientAuthDomain}/oauth2/authorize?${qs.stringify({
      client_id: clientId,
      response_type: 'token',
      state: 'STATE',
      redirect_uri: '<YOUR_CALLBACK_URL>',
    }, {
      encode: false,
    })}`,
  );

export const getCognitoHostedLoginUrl: Selector<
  SystemWorkspaceItem,
  any,
  string | void,
  > = createSelector(
    (workspace) => workspace,
    (workspace) => workspace.id && `${getHostedLoginUrlBase()}/login?${qs.stringify({
      apiHost: workspace.apiHost,
      authProfile: '<YOUR_8BASE_AUTHENTICATION_PROFILE_ID>',
      workspace: workspace.id,
      environment: environmentAccessor.getEnvironment(workspace.id) || MASTER_ENVIRONMENT,
      redirectURI: '<YOUR_CALLBACK_URL>',
    }, {
      encode: false,
    })}`,
  );

export const isCognitoDefault: Selector<
  ApolloAuthenticationProfile,
  any,
  boolean,
  > = createSelector(
    getAuthneticationProfile,
    authProfile => authProfile && authProfile.type === PROFILE_TYPE['8BASE_COGNITO'],
  );


export const isLegacyDefault: Selector<
  ApolloAuthenticationProfile,
  any,
  boolean,
  > = createSelector(
    getAuthneticationProfile,
    authProfile => authProfile && authProfile.type === PROFILE_TYPE['8BASE'],
  );
