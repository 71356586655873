import React, { useState } from 'react';
import { Avatar, IconButton } from '@material-ui/core';
import { CreateOutlined, Close } from '@material-ui/icons';
import styled from '@emotion/styled';


const StyledUserCardItemContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  & :hover {
    background-color: #F5FBFF;
  }
`;

const StyledUserInfoContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledUserCardItemActions = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledUserName = styled('span')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
`;

const StyledUserEmail = styled('span')`
  color: #5B636C;
  font-family: Poppins,serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const UserListItemCard = ({ user = {}}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(false);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <StyledUserCardItemContainer onMouseEnter={ handleMouseEnter } onMouseLeave={ handleMouseLeave }>
      <div style={{ display: 'flex', gap: 12 }}>
        <Avatar alt="profile avatar" src={ user?.avatarUrl } > { `${user?.firstName?.[0]}${user.lastName?.[0]}` }</Avatar>
        <StyledUserInfoContainer>
          <StyledUserName>
            { `${user?.firstName} ${user.lastName}` }
          </StyledUserName>
          <StyledUserEmail>
            { user?.email }
          </StyledUserEmail>
        </StyledUserInfoContainer>
      </div>
      <StyledUserCardItemActions>
        { isHovering && (
          <>
            <IconButton size="small">
              <CreateOutlined style={{ width: 20, height: 20 }} />
            </IconButton>
            <IconButton size="small">
              <Close style={{ width: 20, height: 20 }} />
            </IconButton>
          </>
        ) }
      </StyledUserCardItemActions>
    </StyledUserCardItemContainer>
  );
};
