import gql from 'graphql-tag';

export const ORGANIZATION_BASE_INFO_FRAGMENT = gql`
  fragment OrganizationItemBaseInfo on SystemOrganizationBaseItem {
    id
    name
    type
    description
    image {
      id
      downloadUrl
    }
  }
`;


export const ORGANIZATION_SHORT_INFO_FRAGMENT = gql`
  fragment OrganizationItemShortInfo on SystemOrganizationItem {
    id
    name
    type
    description
    image {
      id
      downloadUrl
    }
  }
`;


export const ORGANIZATION_FULL_INFO_FRAGMENT = gql`
  fragment OrganizationItemFullInfo on SystemOrganizationItem {
    ...OrganizationItemShortInfo
    users {
      id
      firstName
      lastName
      email
      role
      status
      projects {
        id
        image {
          downloadUrl
        }
        name
        kind
        description
      }
      avatar {
        id
        downloadUrl
      }
    }
  }

  ${ORGANIZATION_SHORT_INFO_FRAGMENT}
`;
