// @flow

import { Icon, Row, Text } from '@8base/boost';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';


const variantCss = (props) => {
  if (props.variant === 'default') {
    return css`
      border: 1px dashed ${props.theme.COLORS.BLUE_20};
      border-radius: 8px;
    `;
  }
};

const StyledRow = styled(Row)`
  padding: 20px;
  cursor: pointer;

  & > * {
    transition: color .3s ease-in-out;
  }

  &:hover > * {
    color: ${({ theme }) => theme.COLORS.BLUE_20};
  }

  ${variantCss}
`;


type PlusButtonProps = {
  onClick: () => void;
  children: React$Node;
  variant?: 'default' | 'inline'
}

export const PlusButton = ({
  children,
  onClick,
  variant = 'default',
  ...rest
}: PlusButtonProps) => {
  return (
    <StyledRow
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      onClick={ onClick }
      { ...rest }
      variant={ variant }
    >
      <Icon color="PRIMARY" name="Add" size="sm" css={ css`width: 16px; height: 16px;` } />
      <Text color="PRIMARY" weight="semibold">
        { children }
      </Text>
    </StyledRow>
  );
};
