// @flow
// $FlowFixMe
import * as R from 'ramda';
import { createSelector, type Selector } from 'reselect';
// $FlowFixMe
import type { ProjectsListQuery, SystemProjectItem, SystemProjectListResponse } from '../__generated__';

export const getProjectsListResponse = (projectsListResponse: SystemProjectListResponse = { items: [], count: 0 }) => projectsListResponse;

export const getProjectsList: Selector<
  SystemProjectListResponse,
  any,
  SystemProjectItem[],
> = createSelector(
  getProjectsListResponse,
  (_, options) => ({ withAlphabetSorting: (options && options.withAlphabetSorting) || false }),
  (ProjectsListResponse, { withAlphabetSorting }) => R.pipe(
    R.propOr([], 'items'),
    R.when(
      () => withAlphabetSorting,
      R.sort(({ name: a }, { name: b }) => a && a.localeCompare(b)),
    ),
  )(ProjectsListResponse),
);

export const hasWorkspaces: Selector<
  SystemProjectListResponse,
  any,
  boolean,
  > = createSelector(
    getProjectsList,
    projectsList => projectsList.length > 0,
  );

export const getProjectsCount: Selector<
  SystemProjectListResponse,
  any,
  number,
> = createSelector(
  getProjectsListResponse,
  R.prop('count'),
);

export const getProject: Selector<
  SystemProjectListResponse,
  any,
  SystemProjectItem,
> = createSelector(
  getProjectsList,
  (_, id: string) => id,
  (ProjectsList, id) => R.find(R.propEq('id', id), ProjectsList),
);

export const hasOnlyOneProject: Selector<
  SystemProjectListResponse,
  any,
  boolean,
> = createSelector(
  getProjectsList,
  R.propEq('length', 1),
);

export const hasProjectWithId: Selector<
  SystemProjectListResponse,
  any,
  boolean,
> = createSelector(
  getProjectsList,
  (_, projectId) => projectId,
  (projectsList, projectId) => R.any(R.propEq('id', projectId), projectsList),
);

export const doesntHaveProjects: Selector<
  SystemProjectListResponse,
  any,
  boolean,
> = createSelector(
  getProjectsList,
  R.propEq('length', 0),
);

export const getSharedProjectsList: Selector<
  any,
  { withAlphabetSorting: boolean } | void,
  any[],
> = createSelector(
  getProjectsList,
  (sharedProjectsList) => sharedProjectsList,
);

export const getMyProjectsList: Selector<
  any,
  { withAlphabetSorting: boolean } | void,
  any[],
> = createSelector(
  getProjectsList,
  (sharedProjectsList) => sharedProjectsList,
);

export const getProjectName: Selector<
  SystemProjectListResponse,
  any,
  string,
> = createSelector(
  getProject,
  R.propOr(null, 'name'),
);

/**
 * return merged object of `data.system.workspacesList` and `data.system.workspacesFrontendList` if kind is `undefined`
 */
export const getProjects: Selector<
  ProjectsListQuery | null | typeof undefined,
  any,
  SystemProjectListResponse,
> = createSelector(
  [
    (data) => {
      const projectsList = R.pathOr({ items: [], count: 0 }, ['system', 'projectsUserListQuery', 'myProjects'], data);
      return projectsList;
    },
  ],
  (projectsList) => {
    return projectsList;
  },
);

export const getSharedProjects: Selector<
  ProjectsListQuery | null | typeof undefined,
  any,
  SystemProjectListResponse,
> = createSelector(
  [
    (data) => {
      const sharedProjectsList = R.pathOr({ items: [], count: 0 }, ['system', 'projectsUserListQuery', 'sharedProjects'], data);
      return sharedProjectsList;
    },
  ],
  (sharedProjectsList) => {
    return sharedProjectsList;
  },
);
