// @type
import _ from 'lodash';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import { setDisplayName, compose, withHandlers } from 'recompose';
import deepKeys from 'deep-keys';

type WithFormattedRegisteredFieldsSubmitConfig = {
  validFieldNames: ?string[],
  validFieldPaths: ?string[],
  changeValuesFunc: ?() => void,
  formName?: string,
}


type FormValuesParams = {
  values: Object,
  registeredFields: string[],
}& WithFormattedRegisteredFieldsSubmitConfig


type FirstLevelFormValuesParams = {
  values: Object,
  registeredFields: string[],
  validFieldNames: ?string[],
}

export const findRegisteredFields = fp.memoize(
  (formName: string) => fp.pipe(
    fp.get(['form', formName, 'registeredFields']),
    fp.keys,
  ),
);

export const hasValidFieldName = (fieldName: string) : boolean =>
  fp.includes(
    fp.pipe(
      fp.split('.'),
      fp.last,
    )(fieldName),
  );

export const firstPathName = (fieldName: string): string =>
  fp.pipe(
    fp.split('.'),
    fp.first,
  )(fieldName);

export const findRegisteredFormValues =
  ({ values, registeredFields, validFieldNames = [], validFieldPaths = [] } : FirstLevelFormValuesParams) : Object => {
    return _.reduce(
      values,
      (accum, fieldValue, fieldName) => {
        const isFormHasField = fp.includes(firstPathName(fieldName), registeredFields);
        const isValidFieldName = fp.includes(firstPathName(fieldName), validFieldNames);

        return (isFormHasField || isValidFieldName)
          ? fp.set(fieldName, fieldValue, accum)
          : accum;
      },
      {},
    );
  };

export const findRegisteredDeepFormValues =
  ({ values, registeredFields, validFieldNames = [], validFieldPaths = [] } : FormValuesParams) : Object => {
    const result = {};
    const variablesKeys = deepKeys(values);

    _.forEach(variablesKeys, (fieldName: string) => {
      const value = _.get(values, fieldName);

      const isFormHasField = _.includes(registeredFields, fieldName);
      const isValidFieldName = hasValidFieldName(fieldName)(validFieldNames);
      const isValidFieldPaths = _.includes(validFieldPaths, fieldName);

      if (isFormHasField || isValidFieldName || isValidFieldPaths) _.set(result, fieldName, value);
    });

    return result;
  };

/**
 * replace redux-form handleSubmit values with values omitted by registered form fields and exception rules
 *
 * @param {Array} validFieldNames common field names that should not be omitted ([id, __typename, ...])
 * @param {Array} validFieldPaths paths that should not been omitted ([full.path.id])
 * @param {string} formName name of form, that is not name of the current parent form
 * */
export const withFormattedRegisteredFieldsSubmit = ({ validFieldNames = [], validFieldPaths = ['id'], formName } : WithFormattedRegisteredFieldsSubmitConfig = {}) => compose(
  setDisplayName('withExtendedFormSubmit'),
  connect(
    (state, ownProps) => ({
      registeredFields: findRegisteredFields(formName || ownProps.form)(state),
    }),
  ),
  withHandlers({
    handleSubmit: props => (submittingFunction) => {
      const { handleSubmit, registeredFields } = props;

      const functionWithFormattedValues = (values: Object) => {
        const formattedValues = findRegisteredDeepFormValues({ values, registeredFields, validFieldNames, validFieldPaths });

        return submittingFunction(formattedValues);
      };

      return handleSubmit(functionWithFormattedValues);
    },
  }),
);

/**
 * Omit submit data by registered fields only on the first level of the deep structure
 */
export const withOmitUnregistered = ({ validFieldNames = [], formName } : WithFormattedRegisteredFieldsSubmitConfig = {}) => compose(
  setDisplayName('withExtendedFormSubmit'),
  connect(
    (state, ownProps) => ({
      registeredFields: findRegisteredFields(formName || ownProps.form)(state),
    }),
  ),
  withHandlers({
    handleSubmit: props => (submittingFunction) => {
      const { handleSubmit, registeredFields } = props;

      const functionWithFormattedValues = (values: Object) => {
        const formattedValues = findRegisteredFormValues({ values, registeredFields, validFieldNames });

        return submittingFunction(formattedValues);
      };

      return handleSubmit(functionWithFormattedValues);
    },
  }),
);

