// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {|
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A date-time string at UTC, such as `2007-12-25T16:15:30Z`, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any,
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any,
  /** The `BigInt` scalar type represents whole numeric values. */
  BigInt: any,
  /** A date string, such as `2007-12-25`, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any,
  /** A time string at UTC, such as `16:15:30Z`, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any,
|};

export const AggregationFunctionTypeValues = Object.freeze({
  Avg: 'AVG',
  Sum: 'SUM',
  Count: 'COUNT',
  Min: 'MIN',
  Max: 'MAX',
  GroupConcat: 'GROUP_CONCAT',
  AnyValue: 'ANY_VALUE',
  StddevPop: 'STDDEV_POP',
  StddevSamp: 'STDDEV_SAMP',
  VarPop: 'VAR_POP',
  VarSamp: 'VAR_SAMP'
});


export type AggregationFunctionType = $Values<typeof AggregationFunctionTypeValues>;

export type ApiToken = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  name?: ?$ElementType<Scalars, 'String'>,
  roles?: ?RoleListResponse,
  _description?: ?$ElementType<Scalars, 'String'>,
|};


export type ApiTokenRolesArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
|};

export type ApiToken_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  token?: ?StringPredicate,
  createdBy?: ?User_PermissionFilter,
  roles?: ?Role_PermissionRelationFilter,
  AND?: ?Array<ApiToken_PermissionFilter>,
  OR?: ?Array<ApiToken_PermissionFilter>,
|};

export type ApiToken_PermissionRelationFilter = {|
  some?: ?ApiToken_PermissionFilter,
  every?: ?ApiToken_PermissionFilter,
  none?: ?ApiToken_PermissionFilter,
|};

/** ApiTokens create input */
export type ApiTokenCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  roles?: ?ApiTokensRolesRelationInput,
|};

/** ApiTokens delete input */
export type ApiTokenDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type ApiTokenFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  token?: ?StringPredicate,
  createdBy?: ?UserFilter,
  roles?: ?RoleRelationFilter,
  AND?: ?Array<ApiTokenFilter>,
  OR?: ?Array<ApiTokenFilter>,
|};

export type ApiTokenGroupBy = {|
  query: ApiTokenGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type ApiTokenGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  name?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  roles?: ?RoleGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type ApiTokenKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** ApiTokenListResponse output */
export type ApiTokenListResponse = {|
  /** List items */
  items: Array<ApiToken>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** ApiTokenManyResponse output */
export type ApiTokenManyResponse = {|
  /** List items */
  items: Array<ApiToken>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const ApiTokenOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC',
  TokenAsc: 'token_ASC',
  TokenDesc: 'token_DESC'
});


/** No longer supported. Use `sort` instead. */
export type ApiTokenOrderBy = $Values<typeof ApiTokenOrderByValues>;

/** ApiTokens subscription payload */
export type ApiTokenPayload = {|
  mutation: MutationType,
  node?: ?ApiToken,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?ApiToken,
|};

export type ApiTokenRelationFilter = {|
  some?: ?ApiTokenFilter,
  every?: ?ApiTokenFilter,
  none?: ?ApiTokenFilter,
|};

/** API Token Response */
export type ApiTokenResponse = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  name?: ?$ElementType<Scalars, 'String'>,
  roles?: ?RoleListResponse,
  token: $ElementType<Scalars, 'String'>,
|};


/** API Token Response */
export type ApiTokenResponseRolesArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
|};

/** Roles create input from apiTokens */
export type ApiTokens_RoleCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersRelationInput,
  permissions?: ?PermissionsInput,
  apiTokens?: ?RolesApiTokensRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesRelationInput,
  teamMembers?: ?RolesTeamMembersRelationInput,
|};

/** Roles update input from apiTokens */
export type ApiTokens_RoleUpdateInput = {|
  filter?: ?RoleKeyFilter,
  data: RoleUpdateInput,
|};

export type ApiTokenSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  name?: ?SortOrder,
  createdBy?: ?UserSort,
|};

/** ApiTokens relation input */
export type ApiTokensRolesRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?ApiTokens_RoleCreateInput>,
|};

/** ApiTokens relation input */
export type ApiTokensRolesUpdateRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  disconnect?: ?Array<RoleKeyFilter>,
  reconnect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?ApiTokens_RoleCreateInput>,
  update?: ?Array<?ApiTokens_RoleUpdateInput>,
|};

/** ApiTokens subscription filter */
export type ApiTokenSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?ApiTokenFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** ApiTokens update input */
export type ApiTokenUpdateByFilterInput = {|
  name?: ?Array<?UpdateByFilterStringInput>,
  token?: ?Array<?UpdateByFilterStringInput>,
|};

/** ApiTokens update input */
export type ApiTokenUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  roles?: ?ApiTokensRolesUpdateRelationInput,
|};

/** Application */
export type Application = {|
  appType: $ElementType<Scalars, 'String'>,
  createdAt: $ElementType<Scalars, 'DateTime'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  status: ApplicationStatusEnum,
|};

/** ApplicationDeleteMutationInput */
export type ApplicationDeleteMutationInput = {|
  id: $ElementType<Scalars, 'String'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** Application install input */
export type ApplicationInstallInput = {|
  appType: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status?: ?ApplicationStatusEnum,
  displayName?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
|};

/** ApplicationListResponse output */
export type ApplicationListResponse = {|
  /** List items */
  items: Array<Application>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const ApplicationStatusEnumValues = Object.freeze({
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
});


/** Application Status Enum */
export type ApplicationStatusEnum = $Values<typeof ApplicationStatusEnumValues>;

/** Application update input */
export type ApplicationUpdateInput = {|
  id: $ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  status?: ?ApplicationStatusEnum,
|};

/** Async Session */
export type AsyncSession = {|
  sessionId: $ElementType<Scalars, 'String'>,
|};

export type AsyncSessionStatusResponse = {|
  message?: ?$ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
|};

/** Auth response */
export type Auth = {|
  accessToken?: ?$ElementType<Scalars, 'String'>,
  accessTokenExpiresAt?: ?$ElementType<Scalars, 'Int'>,
  idToken?: ?$ElementType<Scalars, 'String'>,
  idTokenExpiresAt?: ?$ElementType<Scalars, 'Int'>,
  refreshToken?: ?$ElementType<Scalars, 'String'>,
|};

export type AuthenticationProfile = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
  secret?: ?$ElementType<Scalars, 'String'>,
  managementDomain?: ?$ElementType<Scalars, 'String'>,
  clientId?: ?$ElementType<Scalars, 'String'>,
  databaseName?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  selfSignUpEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  selfSignUpEmailDomains?: ?Array<?$ElementType<Scalars, 'String'>>,
  roles?: ?RoleListResponse,
  audiences?: ?Array<?$ElementType<Scalars, 'String'>>,
  attributes?: ?AuthenticationProfileAttributes,
  _description?: ?$ElementType<Scalars, 'String'>,
|};


export type AuthenticationProfileRolesArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
|};

export type AuthenticationProfile_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  type?: ?StringPredicate,
  secret?: ?StringPredicate,
  managementDomain?: ?StringPredicate,
  clientId?: ?StringPredicate,
  databaseName?: ?StringPredicate,
  domain?: ?StringPredicate,
  selfSignUpEnabled?: ?BoolPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  roles?: ?Role_PermissionRelationFilter,
  AND?: ?Array<AuthenticationProfile_PermissionFilter>,
  OR?: ?Array<AuthenticationProfile_PermissionFilter>,
|};

export type AuthenticationProfile_PermissionRelationFilter = {|
  some?: ?AuthenticationProfile_PermissionFilter,
  every?: ?AuthenticationProfile_PermissionFilter,
  none?: ?AuthenticationProfile_PermissionFilter,
|};

/** Authentication Profile Attributes */
export type AuthenticationProfileAttributes = CognitoAuthProfileAttributes;

/** Authentication profile connection options */
export type AuthenticationProfileConnectionOptions = {|
  google?: ?GoogleOptions,
  facebook?: ?FacebookOptions,
  github?: ?GithubOptions,
|};

/** Authentication profile connection options input */
export type AuthenticationProfileConnectionsOptionsInput = {|
  google?: ?GoogleOptionsInput,
  facebook?: ?FacebookOptionsInput,
  github?: ?GithubOptionsInput,
|};

/** AuthenticationProfiles create input */
export type AuthenticationProfileCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
  secret?: ?$ElementType<Scalars, 'String'>,
  managementDomain?: ?$ElementType<Scalars, 'String'>,
  clientId?: ?$ElementType<Scalars, 'String'>,
  databaseName?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  selfSignUpEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  selfSignUpEmailDomains?: ?Array<?$ElementType<Scalars, 'String'>>,
  roles?: ?AuthenticationProfilesRolesRelationInput,
  audiences?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

/** AuthenticationProfiles create many input */
export type AuthenticationProfileCreateManyInput = {|
  name: $ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
  secret?: ?$ElementType<Scalars, 'String'>,
  managementDomain?: ?$ElementType<Scalars, 'String'>,
  clientId?: ?$ElementType<Scalars, 'String'>,
  databaseName?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  selfSignUpEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  selfSignUpEmailDomains?: ?Array<?$ElementType<Scalars, 'String'>>,
  roles?: ?AuthenticationProfilesRolesManyRelationInput,
  audiences?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

/** AuthenticationProfiles delete input */
export type AuthenticationProfileDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type AuthenticationProfileFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  type?: ?StringPredicate,
  secret?: ?StringPredicate,
  managementDomain?: ?StringPredicate,
  clientId?: ?StringPredicate,
  databaseName?: ?StringPredicate,
  domain?: ?StringPredicate,
  selfSignUpEnabled?: ?BoolPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  roles?: ?RoleRelationFilter,
  AND?: ?Array<AuthenticationProfileFilter>,
  OR?: ?Array<AuthenticationProfileFilter>,
|};

export type AuthenticationProfileGroupBy = {|
  query: AuthenticationProfileGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type AuthenticationProfileGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  name?: ?Array<GroupByField>,
  type?: ?Array<GroupByField>,
  secret?: ?Array<GroupByField>,
  managementDomain?: ?Array<GroupByField>,
  clientId?: ?Array<GroupByField>,
  databaseName?: ?Array<GroupByField>,
  domain?: ?Array<GroupByField>,
  selfSignUpEnabled?: ?Array<GroupByField>,
  selfSignUpEmailDomains?: ?Array<GroupByField>,
  audiences?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  roles?: ?RoleGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type AuthenticationProfileKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** AuthenticationProfileListResponse output */
export type AuthenticationProfileListResponse = {|
  /** List items */
  items: Array<AuthenticationProfile>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** AuthenticationProfileManyResponse output */
export type AuthenticationProfileManyResponse = {|
  /** List items */
  items: Array<AuthenticationProfile>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const AuthenticationProfileOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC',
  TypeAsc: 'type_ASC',
  TypeDesc: 'type_DESC',
  SecretAsc: 'secret_ASC',
  SecretDesc: 'secret_DESC',
  ManagementDomainAsc: 'managementDomain_ASC',
  ManagementDomainDesc: 'managementDomain_DESC',
  ClientIdAsc: 'clientId_ASC',
  ClientIdDesc: 'clientId_DESC',
  DatabaseNameAsc: 'databaseName_ASC',
  DatabaseNameDesc: 'databaseName_DESC',
  DomainAsc: 'domain_ASC',
  DomainDesc: 'domain_DESC',
  SelfSignUpEnabledAsc: 'selfSignUpEnabled_ASC',
  SelfSignUpEnabledDesc: 'selfSignUpEnabled_DESC'
});


/** No longer supported. Use `sort` instead. */
export type AuthenticationProfileOrderBy = $Values<typeof AuthenticationProfileOrderByValues>;

/** AuthenticationProfiles subscription payload */
export type AuthenticationProfilePayload = {|
  mutation: MutationType,
  node?: ?AuthenticationProfile,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?AuthenticationProfile,
|};

export type AuthenticationProfileRelationFilter = {|
  some?: ?AuthenticationProfileFilter,
  every?: ?AuthenticationProfileFilter,
  none?: ?AuthenticationProfileFilter,
|};

/** Roles create input from authenticationProfiles */
export type AuthenticationProfiles_RoleCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersRelationInput,
  permissions?: ?PermissionsInput,
  apiTokens?: ?RolesApiTokensRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesRelationInput,
  teamMembers?: ?RolesTeamMembersRelationInput,
|};

/** Roles update input from authenticationProfiles */
export type AuthenticationProfiles_RoleUpdateInput = {|
  filter?: ?RoleKeyFilter,
  data: RoleUpdateInput,
|};

export type AuthenticationProfileSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  name?: ?SortOrder,
  type?: ?SortOrder,
  secret?: ?SortOrder,
  managementDomain?: ?SortOrder,
  clientId?: ?SortOrder,
  databaseName?: ?SortOrder,
  domain?: ?SortOrder,
  selfSignUpEnabled?: ?SortOrder,
  createdBy?: ?UserSort,
|};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesManyRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
|};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?AuthenticationProfiles_RoleCreateInput>,
|};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesUpdateRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  disconnect?: ?Array<RoleKeyFilter>,
  reconnect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?AuthenticationProfiles_RoleCreateInput>,
  update?: ?Array<?AuthenticationProfiles_RoleUpdateInput>,
|};

/** AuthenticationProfiles subscription filter */
export type AuthenticationProfileSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?AuthenticationProfileFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateByFilterInput = {|
  name?: ?Array<?UpdateByFilterStringInput>,
  type?: ?Array<?UpdateByFilterStringInput>,
  secret?: ?Array<?UpdateByFilterStringInput>,
  managementDomain?: ?Array<?UpdateByFilterStringInput>,
  clientId?: ?Array<?UpdateByFilterStringInput>,
  databaseName?: ?Array<?UpdateByFilterStringInput>,
  domain?: ?Array<?UpdateByFilterStringInput>,
  selfSignUpEnabled?: ?Array<?UpdateByFilterBooleanSwitchInput>,
  selfSignUpEmailDomains?: ?Array<?UpdateByFilterListStringInput>,
  audiences?: ?Array<?UpdateByFilterListStringInput>,
|};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
  secret?: ?$ElementType<Scalars, 'String'>,
  managementDomain?: ?$ElementType<Scalars, 'String'>,
  clientId?: ?$ElementType<Scalars, 'String'>,
  databaseName?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  selfSignUpEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  selfSignUpEmailDomains?: ?Array<?$ElementType<Scalars, 'String'>>,
  roles?: ?AuthenticationProfilesRolesUpdateRelationInput,
  audiences?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type AuthenticationSetting = {|
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  createdBy?: ?User,
  allowedCallbacks?: ?Array<?$ElementType<Scalars, 'String'>>,
  allowedWebOrigins?: ?Array<?$ElementType<Scalars, 'String'>>,
  allowedLogouts?: ?Array<?$ElementType<Scalars, 'String'>>,
  connections?: ?AuthenticationProfileConnectionOptions,
  _description?: ?$ElementType<Scalars, 'String'>,
|};

export type AuthenticationSettingFilter = {|
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  AND?: ?Array<AuthenticationSettingFilter>,
  OR?: ?Array<AuthenticationSettingFilter>,
|};

/** AuthenticationSettings subscription payload */
export type AuthenticationSettingPayload = {|
  mutation: MutationType,
  node?: ?AuthenticationSetting,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?AuthenticationSetting,
|};

/** AuthenticationSettings subscription filter */
export type AuthenticationSettingSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?AuthenticationSettingFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** AuthenticationSettings update input */
export type AuthenticationSettingUpdateInput = {|
  allowedCallbacks?: ?Array<?$ElementType<Scalars, 'String'>>,
  allowedWebOrigins?: ?Array<?$ElementType<Scalars, 'String'>>,
  allowedLogouts?: ?Array<?$ElementType<Scalars, 'String'>>,
  connections?: ?AuthenticationProfileConnectionsOptionsInput,
|};

/** TeamMembers update input from avatar */
export type Avatar_TeamMemberUpdateInput = {|
  filter?: ?TeamMemberKeyFilter,
  data: TeamMemberUpdateInput,
|};

/** Users create input from avatar */
export type Avatar_UserCreateInput = {|
  email: $ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?UsersAvatarRelationInput,
  roles?: ?UsersRolesRelationInput,
|};

/** Users update input from avatar */
export type Avatar_UserUpdateInput = {|
  filter?: ?UserKeyFilter,
  data: UserUpdateInput,
|};

export type AwsSignInfoResponse = {|
  fields: $ElementType<Scalars, 'JSON'>,
  path: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
|};


export type BigIntPredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'BigInt'>,
  not_equals?: ?$ElementType<Scalars, 'BigInt'>,
  in?: ?Array<$ElementType<Scalars, 'BigInt'>>,
  not_in?: ?Array<$ElementType<Scalars, 'BigInt'>>,
  lt?: ?$ElementType<Scalars, 'BigInt'>,
  lte?: ?$ElementType<Scalars, 'BigInt'>,
  gt?: ?$ElementType<Scalars, 'BigInt'>,
  gte?: ?$ElementType<Scalars, 'BigInt'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<BigIntPredicateHaving>,
  OR?: ?Array<BigIntPredicateHaving>,
|};

export type BillingCurrentPlanResponse = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  nextPlan?: ?BillingNextPlanResponse,
  paymentDate?: ?$ElementType<Scalars, 'DateTime'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
  status?: ?WorkspaceStatus,
  trialEnd?: ?$ElementType<Scalars, 'DateTime'>,
|};

export const BillingDetailsOriginValues = Object.freeze({
  Member: 'member',
  Organization: 'organization',
  Workspace: 'workspace'
});


export type BillingDetailsOrigin = $Values<typeof BillingDetailsOriginValues>;

export type BillingDetailsResponse = {|
  brand?: ?$ElementType<Scalars, 'String'>,
  expMonth?: ?$ElementType<Scalars, 'Int'>,
  expYear?: ?$ElementType<Scalars, 'Int'>,
  last4?: ?$ElementType<Scalars, 'String'>,
  origin: BillingDetailsOrigin,
|};

/** BillingDetailsUpdateMutationInput */
export type BillingDetailsUpdateMutationInput = {|
  cardToken: $ElementType<Scalars, 'String'>,
|};

export type BillingInvoiceItem = {|
  amountDue?: ?$ElementType<Scalars, 'Float'>,
  amountPaid?: ?$ElementType<Scalars, 'Float'>,
  amountRemaining?: ?$ElementType<Scalars, 'Float'>,
  description?: ?$ElementType<Scalars, 'String'>,
  endingBalance?: ?$ElementType<Scalars, 'Float'>,
  id: $ElementType<Scalars, 'ID'>,
  invoicePdf?: ?$ElementType<Scalars, 'String'>,
  number?: ?$ElementType<Scalars, 'String'>,
  organization?: ?BillingInvoiceItemOrganizationInfo,
  paid?: ?$ElementType<Scalars, 'Boolean'>,
  periodEnd?: ?$ElementType<Scalars, 'DateTime'>,
  periodStart?: ?$ElementType<Scalars, 'DateTime'>,
  plan?: ?BillingInvoiceItemPlanInfo,
  status?: ?$ElementType<Scalars, 'String'>,
  total?: ?$ElementType<Scalars, 'Float'>,
  workspace?: ?BillingInvoiceItemWorkspaceInfo,
|};

export type BillingInvoiceItemOrganizationInfo = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type BillingInvoiceItemPlanInfo = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type BillingInvoiceItemWorkspaceInfo = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export const BillingInvoicesListFilterTypeValues = Object.freeze({
  Customer: 'CUSTOMER',
  Workspace: 'WORKSPACE'
});


export type BillingInvoicesListFilterType = $Values<typeof BillingInvoicesListFilterTypeValues>;

/** BillingInvoicesListResponse output */
export type BillingInvoicesListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<BillingInvoiceItem>,
|};

export type BillingLimitMetricItem = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  showPriority?: ?$ElementType<Scalars, 'Int'>,
  tooltip?: ?$ElementType<Scalars, 'String'>,
  unit?: ?$ElementType<Scalars, 'String'>,
|};

export type BillingMetricOverageItem = {|
  value?: ?$ElementType<Scalars, 'Float'>,
  warning?: ?$ElementType<Scalars, 'String'>,
|};

export type BillingMetricUsageItem = {|
  limitMetric?: ?BillingLimitMetricItem,
  overage?: ?BillingMetricOverageItem,
  value?: ?$ElementType<Scalars, 'Float'>,
|};

export type BillingMetricUsageQuotaItem = {|
  limitMetric?: ?BillingLimitMetricItem,
  value?: ?$ElementType<Scalars, 'Float'>,
|};

/** BillingMetricUsageQuotasListResponse output */
export type BillingMetricUsageQuotasListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<BillingMetricUsageQuotaItem>,
|};

export type BillingMetricUsagesListFilter = {|
  entryDate: DateTimePredicate,
|};

/** BillingMetricUsagesListResponse output */
export type BillingMetricUsagesListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<BillingMetricUsageItem>,
|};

export type BillingNextPlanResponse = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
|};

export type BillingPlanBaseInfo = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  isCustom?: ?$ElementType<Scalars, 'Boolean'>,
  isLegacy?: ?$ElementType<Scalars, 'Boolean'>,
  limitMetrics?: ?Array<?BillingPlanLimitMetricItem>,
  name?: ?$ElementType<Scalars, 'String'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
|};

export type BillingPlanLimitMetricItem = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  hardLimit?: ?$ElementType<Scalars, 'Float'>,
  name?: ?$ElementType<Scalars, 'String'>,
  overagePrice?: ?$ElementType<Scalars, 'Int'>,
  softLimit?: ?$ElementType<Scalars, 'Float'>,
|};

/** BillingPlanUpdateMutationInput */
export type BillingPlanUpdateMutationInput = {|
  planId: $ElementType<Scalars, 'ID'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
|};

export type BoolPredicate = {|
  equals?: ?$ElementType<Scalars, 'Boolean'>,
  not_equals?: ?$ElementType<Scalars, 'Boolean'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BoolPredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'Boolean'>,
  not_equals?: ?$ElementType<Scalars, 'Boolean'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<BoolPredicateHaving>,
  OR?: ?Array<BoolPredicateHaving>,
|};

/** ChangePasswordInput */
export type ChangePasswordInput = {|
  authProfileId: $ElementType<Scalars, 'ID'>,
  email: $ElementType<Scalars, 'String'>,
  newPassword: $ElementType<Scalars, 'String'>,
  oldPassword: $ElementType<Scalars, 'String'>,
  refreshToken: $ElementType<Scalars, 'String'>,
|};

/** Authentication Profile Attributes for Cognito */
export type CognitoAuthProfileAttributes = {|
  clientAuthDomain?: ?$ElementType<Scalars, 'String'>,
|};

export const ComputedFieldModeValues = Object.freeze({
  Stored: 'STORED',
  Virtual: 'VIRTUAL'
});


/** Computed field mode */
export type ComputedFieldMode = $Values<typeof ComputedFieldModeValues>;

/** Custom Table Field Type */
export type CustomTableField = {|
  computedMode?: ?ComputedFieldMode,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType?: ?FieldType,
  fieldTypeAttributes?: ?FieldTypeAttributes,
  isList: $ElementType<Scalars, 'Boolean'>,
  isRequired: $ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};


/** Date Field Attributes */
export type DateFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
|};

export const DatePartFunctionTypeValues = Object.freeze({
  Date: 'DATE',
  Time: 'TIME',
  Week: 'WEEK',
  WeekDay: 'WEEK_DAY',
  WeekOfYear: 'WEEK_OF_YEAR',
  Year: 'YEAR',
  YearWeek: 'YEAR_WEEK',
  DayName: 'DAY_NAME',
  DayOfMonth: 'DAY_OF_MONTH',
  DayOfWeek: 'DAY_OF_WEEK',
  DayOfYear: 'DAY_OF_YEAR',
  Quarter: 'QUARTER',
  Day: 'DAY',
  Month: 'MONTH',
  MonthName: 'MONTH_NAME',
  Hour: 'HOUR',
  Minute: 'MINUTE',
  Second: 'SECOND',
  Microsecond: 'MICROSECOND',
  LastDay: 'LAST_DAY'
});


export type DatePartFunctionType = $Values<typeof DatePartFunctionTypeValues>;

export type DatePredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'Date'>,
  not_equals?: ?$ElementType<Scalars, 'Date'>,
  in?: ?Array<$ElementType<Scalars, 'Date'>>,
  not_in?: ?Array<$ElementType<Scalars, 'Date'>>,
  lt?: ?$ElementType<Scalars, 'Date'>,
  lte?: ?$ElementType<Scalars, 'Date'>,
  gt?: ?$ElementType<Scalars, 'Date'>,
  gte?: ?$ElementType<Scalars, 'Date'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<DatePredicateHaving>,
  OR?: ?Array<DatePredicateHaving>,
|};

export type DateRelativePredicates = {|
  gt?: ?DateRelativePredicateType,
  gte?: ?DateRelativePredicateType,
  lt?: ?DateRelativePredicateType,
  lte?: ?DateRelativePredicateType,
|};

export type DateRelativePredicateType = {|
  op?: ?RelativePredicateOpEnum,
  unit?: ?RelativePredicateUnitEnum,
  value: $ElementType<Scalars, 'String'>,
|};


export type DateTimePredicate = {|
  equals?: ?$ElementType<Scalars, 'DateTime'>,
  gt?: ?$ElementType<Scalars, 'DateTime'>,
  gte?: ?$ElementType<Scalars, 'DateTime'>,
  in?: ?Array<$ElementType<Scalars, 'DateTime'>>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  lt?: ?$ElementType<Scalars, 'DateTime'>,
  lte?: ?$ElementType<Scalars, 'DateTime'>,
  not_equals?: ?$ElementType<Scalars, 'DateTime'>,
  not_in?: ?Array<$ElementType<Scalars, 'DateTime'>>,
  relative?: ?DateRelativePredicates,
|};

export type DateTimePredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'DateTime'>,
  not_equals?: ?$ElementType<Scalars, 'DateTime'>,
  in?: ?Array<$ElementType<Scalars, 'DateTime'>>,
  not_in?: ?Array<$ElementType<Scalars, 'DateTime'>>,
  lt?: ?$ElementType<Scalars, 'DateTime'>,
  lte?: ?$ElementType<Scalars, 'DateTime'>,
  gt?: ?$ElementType<Scalars, 'DateTime'>,
  gte?: ?$ElementType<Scalars, 'DateTime'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<DateTimePredicateHaving>,
  OR?: ?Array<DateTimePredicateHaving>,
|};

export const DateTypeFormatEnumValues = Object.freeze({
  Date: 'DATE',
  Datetime: 'DATETIME'
});


/** Date Type Format Enum */
export type DateTypeFormatEnum = $Values<typeof DateTypeFormatEnumValues>;

/** DeployDataResponse */
export type DeployDataResponse = {|
  uploadBuildUrl: $ElementType<Scalars, 'String'>,
  uploadMetaDataUrl: $ElementType<Scalars, 'String'>,
  buildName: $ElementType<Scalars, 'String'>,
|};

/** DeployingBuildInput */
export type DeployingBuildInput = {|
  buildName: $ElementType<Scalars, 'String'>,
  options?: ?DeployOptions,
|};

export const DeployModeEnumValues = Object.freeze({
  Full: 'FULL',
  OnlyPlugins: 'ONLY_PLUGINS',
  OnlyProject: 'ONLY_PROJECT',
  Functions: 'FUNCTIONS',
  Migrations: 'MIGRATIONS'
});


export type DeployModeEnum = $Values<typeof DeployModeEnumValues>;

/** DeployOptions */
export type DeployOptions = {|
  mode?: ?DeployModeEnum,
  pluginNames?: ?Array<?$ElementType<Scalars, 'String'>>,
  extensionNames?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export const DeployStatusEnumValues = Object.freeze({
  Deploying: 'deploying',
  CompleteError: 'complete_error',
  CompleteSuccess: 'complete_success',
  Compiling: 'compiling',
  Preparing: 'preparing',
  Initialize: 'initialize'
});


export type DeployStatusEnum = $Values<typeof DeployStatusEnumValues>;

/** DeployStatusResult */
export type DeployStatusResult = {|
  status: DeployStatusEnum,
  message?: ?$ElementType<Scalars, 'String'>,
|};

export type EnvironmentBackupItem = {|
  name: $ElementType<Scalars, 'String'>,
  size: $ElementType<Scalars, 'Float'>,
|};

export type EnvironmentItem = {|
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
|};

/** EnvironmentSetupInput */
export type EnvironmentSetupInput = {|
  deleteLock?: ?$ElementType<Scalars, 'Boolean'>,
  introspection?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type EnvironmentVariable = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  name?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
  _description?: ?$ElementType<Scalars, 'String'>,
|};

/** EnvironmentVariables create input */
export type EnvironmentVariableCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

/** EnvironmentVariables create many input */
export type EnvironmentVariableCreateManyInput = {|
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

/** EnvironmentVariables delete input */
export type EnvironmentVariableDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type EnvironmentVariableFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  value?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  AND?: ?Array<EnvironmentVariableFilter>,
  OR?: ?Array<EnvironmentVariableFilter>,
|};

export type EnvironmentVariableGroupBy = {|
  query: EnvironmentVariableGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type EnvironmentVariableGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  name?: ?Array<GroupByField>,
  value?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type EnvironmentVariableKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** EnvironmentVariableListResponse output */
export type EnvironmentVariableListResponse = {|
  /** List items */
  items: Array<EnvironmentVariable>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** EnvironmentVariableManyResponse output */
export type EnvironmentVariableManyResponse = {|
  /** List items */
  items: Array<EnvironmentVariable>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const EnvironmentVariableOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC',
  ValueAsc: 'value_ASC',
  ValueDesc: 'value_DESC'
});


/** No longer supported. Use `sort` instead. */
export type EnvironmentVariableOrderBy = $Values<typeof EnvironmentVariableOrderByValues>;

/** EnvironmentVariables subscription payload */
export type EnvironmentVariablePayload = {|
  mutation: MutationType,
  node?: ?EnvironmentVariable,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?EnvironmentVariable,
|};

export type EnvironmentVariableSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  name?: ?SortOrder,
  value?: ?SortOrder,
  createdBy?: ?UserSort,
|};

/** EnvironmentVariables subscription filter */
export type EnvironmentVariableSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?EnvironmentVariableFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateByFilterInput = {|
  name?: ?Array<?UpdateByFilterStringInput>,
  value?: ?Array<?UpdateByFilterStringInput>,
|};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
|};

/** Facebook connection params */
export type FacebookOptions = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  app_id: $ElementType<Scalars, 'String'>,
  app_secret: $ElementType<Scalars, 'String'>,
|};

/** Facebook connection params input */
export type FacebookOptionsInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  app_id: $ElementType<Scalars, 'String'>,
  app_secret: $ElementType<Scalars, 'String'>,
|};

/** Field Data Features */
export type FieldDataFeatures = {|
  create: $ElementType<Scalars, 'Boolean'>,
  sort: $ElementType<Scalars, 'Boolean'>,
  update: $ElementType<Scalars, 'Boolean'>,
|};

/** Field Schema Features */
export type FieldSchemaFeatures = {|
  delete: $ElementType<Scalars, 'Boolean'>,
  update: $ElementType<Scalars, 'Boolean'>,
|};

export const FieldTypeValues = Object.freeze({
  Date: 'DATE',
  File: 'FILE',
  Geo: 'GEO',
  Id: 'ID',
  Json: 'JSON',
  MissingRelation: 'MISSING_RELATION',
  Number: 'NUMBER',
  OneWayRelation: 'ONE_WAY_RELATION',
  Relation: 'RELATION',
  Smart: 'SMART',
  Switch: 'SWITCH',
  Text: 'TEXT',
  Uuid: 'UUID'
});


/** Field types */
export type FieldType = $Values<typeof FieldTypeValues>;

/** Field Type Attributes */
export type FieldTypeAttributes = DateFieldTypeAttributes | FileFieldTypeAttributes | GeoFieldTypeAttributes | MissingRelationFieldTypeAttributes | NumberFieldTypeAttributes | SmartFieldTypeAttributes | SwitchFieldTypeAttributes | TextFieldTypeAttributes | UuidFieldTypeAttributes;

/** Field Type Attributes Input */
export type FieldTypeAttributesInput = {|
  autoIncrement?: ?$ElementType<Scalars, 'Boolean'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  expiration?: ?$ElementType<Scalars, 'Int'>,
  fieldSize?: ?$ElementType<Scalars, 'Int'>,
  format?: ?$ElementType<Scalars, 'String'>,
  isBigInt?: ?$ElementType<Scalars, 'Boolean'>,
  listOptions?: ?Array<$ElementType<Scalars, 'String'>>,
  maxSize?: ?$ElementType<Scalars, 'Int'>,
  maxValue?: ?$ElementType<Scalars, 'Float'>,
  minValue?: ?$ElementType<Scalars, 'Float'>,
  precision?: ?$ElementType<Scalars, 'Int'>,
  srid?: ?$ElementType<Scalars, 'Int'>,
  typeRestrictions?: ?Array<$ElementType<Scalars, 'String'>>,
|};

export type File = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  fileId?: ?$ElementType<Scalars, 'String'>,
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  shareUrl?: ?$ElementType<Scalars, 'String'>,
  provider?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  /** No longer supported */
  uploaded?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  /** No longer supported */
  uploadUrl?: ?$ElementType<Scalars, 'String'>,
  /** No longer supported. Use `system.fileUploadSignInfo.AwsSignInfoResponse.fields` instead. */
  fields?: ?$ElementType<Scalars, 'JSON'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  settings_menuBarLogo?: ?SettingListResponse,
  settings_landingPageImage?: ?SettingListResponse,
  users_avatar?: ?UserListResponse,
  teamMembers_avatar?: ?TeamMemberListResponse,
  previewUrl?: ?$ElementType<Scalars, 'String'>,
  /** No longer supported. Use `downloadUrl` instead. */
  downloadStorageUrl?: ?$ElementType<Scalars, 'String'>,
  _description?: ?$ElementType<Scalars, 'String'>,
|};


export type FileSettings_MenuBarLogoArgs = {|
  filter?: ?SettingFilter,
  orderBy?: ?Array<?SettingOrderBy>,
  sort?: ?Array<SettingSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?SettingGroupBy,
|};


export type FileSettings_LandingPageImageArgs = {|
  filter?: ?SettingFilter,
  orderBy?: ?Array<?SettingOrderBy>,
  sort?: ?Array<SettingSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?SettingGroupBy,
|};


export type FileUsers_AvatarArgs = {|
  filter?: ?UserFilter,
  orderBy?: ?Array<?UserOrderBy>,
  sort?: ?Array<UserSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?UserGroupBy,
|};


export type FileTeamMembers_AvatarArgs = {|
  filter?: ?TeamMemberFilter,
  orderBy?: ?Array<?TeamMemberOrderBy>,
  sort?: ?Array<TeamMemberSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?TeamMemberGroupBy,
|};

export type File_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  fileId?: ?StringPredicate,
  downloadUrl?: ?StringPredicate,
  shareUrl?: ?StringPredicate,
  provider?: ?StringPredicate,
  public?: ?BoolPredicate,
  uploaded?: ?BoolPredicate,
  filename?: ?StringPredicate,
  uploadUrl?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  settings_menuBarLogo?: ?Setting_PermissionRelationFilter,
  settings_landingPageImage?: ?Setting_PermissionRelationFilter,
  users_avatar?: ?User_PermissionRelationFilter,
  teamMembers_avatar?: ?TeamMember_PermissionRelationFilter,
  AND?: ?Array<File_PermissionFilter>,
  OR?: ?Array<File_PermissionFilter>,
|};

/** Files create input */
export type FileCreateInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarRelationInput,
|};

/** Files create many input */
export type FileCreateManyInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarManyRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarManyRelationInput,
|};

/** Files delete input */
export type FileDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** FileFieldsPermissions create input */
export type FileFieldsPermissions = {|
  createdAt?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt?: ?$ElementType<Scalars, 'Boolean'>,
  fileId?: ?$ElementType<Scalars, 'Boolean'>,
  downloadUrl?: ?$ElementType<Scalars, 'Boolean'>,
  shareUrl?: ?$ElementType<Scalars, 'Boolean'>,
  provider?: ?$ElementType<Scalars, 'Boolean'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  uploaded?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'Boolean'>,
  uploadUrl?: ?$ElementType<Scalars, 'Boolean'>,
  fields?: ?$ElementType<Scalars, 'Boolean'>,
  meta?: ?$ElementType<Scalars, 'Boolean'>,
  mods?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** File Field Attributes */
export type FileFieldTypeAttributes = {|
  expiration?: ?$ElementType<Scalars, 'Int'>,
  format: $ElementType<Scalars, 'String'>,
  maxSize?: ?$ElementType<Scalars, 'Int'>,
  showTitle?: ?$ElementType<Scalars, 'Boolean'>,
  showUrl?: ?$ElementType<Scalars, 'Boolean'>,
  typeRestrictions?: ?Array<$ElementType<Scalars, 'String'>>,
|};

export type FileFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  fileId?: ?StringPredicate,
  downloadUrl?: ?StringPredicate,
  shareUrl?: ?StringPredicate,
  provider?: ?StringPredicate,
  public?: ?BoolPredicate,
  uploaded?: ?BoolPredicate,
  filename?: ?StringPredicate,
  uploadUrl?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  settings_menuBarLogo?: ?SettingRelationFilter,
  settings_landingPageImage?: ?SettingRelationFilter,
  users_avatar?: ?UserRelationFilter,
  teamMembers_avatar?: ?TeamMemberRelationFilter,
  AND?: ?Array<FileFilter>,
  OR?: ?Array<FileFilter>,
|};

export type FileGroupBy = {|
  query: FileGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type FileGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  fileId?: ?Array<GroupByField>,
  downloadUrl?: ?Array<GroupByField>,
  shareUrl?: ?Array<GroupByField>,
  provider?: ?Array<GroupByField>,
  public?: ?Array<GroupByField>,
  uploaded?: ?Array<GroupByField>,
  filename?: ?Array<GroupByField>,
  uploadUrl?: ?Array<GroupByField>,
  fields?: ?Array<GroupByField>,
  meta?: ?Array<GroupByField>,
  mods?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  settings_menuBarLogo?: ?SettingGroupByQuery,
  settings_landingPageImage?: ?SettingGroupByQuery,
  users_avatar?: ?UserGroupByQuery,
  teamMembers_avatar?: ?TeamMemberGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type FileKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  fileId?: ?$ElementType<Scalars, 'String'>,
|};

/** FileListResponse output */
export type FileListResponse = {|
  /** List items */
  items: Array<File>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** FileManyResponse output */
export type FileManyResponse = {|
  /** List items */
  items: Array<File>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const FileOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  FileIdAsc: 'fileId_ASC',
  FileIdDesc: 'fileId_DESC',
  DownloadUrlAsc: 'downloadUrl_ASC',
  DownloadUrlDesc: 'downloadUrl_DESC',
  ShareUrlAsc: 'shareUrl_ASC',
  ShareUrlDesc: 'shareUrl_DESC',
  ProviderAsc: 'provider_ASC',
  ProviderDesc: 'provider_DESC',
  PublicAsc: 'public_ASC',
  PublicDesc: 'public_DESC',
  UploadedAsc: 'uploaded_ASC',
  UploadedDesc: 'uploaded_DESC',
  FilenameAsc: 'filename_ASC',
  FilenameDesc: 'filename_DESC',
  UploadUrlAsc: 'uploadUrl_ASC',
  UploadUrlDesc: 'uploadUrl_DESC',
  FieldsAsc: 'fields_ASC',
  FieldsDesc: 'fields_DESC',
  MetaAsc: 'meta_ASC',
  MetaDesc: 'meta_DESC',
  ModsAsc: 'mods_ASC',
  ModsDesc: 'mods_DESC'
});


/** No longer supported. Use `sort` instead. */
export type FileOrderBy = $Values<typeof FileOrderByValues>;

/** Files subscription payload */
export type FilePayload = {|
  mutation: MutationType,
  node?: ?File,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?File,
|};

export type FileSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  fileId?: ?SortOrder,
  downloadUrl?: ?SortOrder,
  shareUrl?: ?SortOrder,
  provider?: ?SortOrder,
  public?: ?SortOrder,
  uploaded?: ?SortOrder,
  filename?: ?SortOrder,
  uploadUrl?: ?SortOrder,
  createdBy?: ?UserSort,
|};

export type FileStackSignInfoResponse = {|
  apiKey: $ElementType<Scalars, 'String'>,
  path: $ElementType<Scalars, 'String'>,
  policy: $ElementType<Scalars, 'String'>,
  signature: $ElementType<Scalars, 'String'>,
|};

/** Files relation input */
export type FilesTeamMembers_AvatarManyRelationInput = {|
  connect?: ?Array<TeamMemberKeyFilter>,
|};

/** Files relation input */
export type FilesTeamMembers_AvatarRelationInput = {|
  connect?: ?Array<TeamMemberKeyFilter>,
|};

/** Files relation input */
export type FilesTeamMembers_AvatarUpdateRelationInput = {|
  connect?: ?Array<TeamMemberKeyFilter>,
  disconnect?: ?Array<TeamMemberKeyFilter>,
  reconnect?: ?Array<TeamMemberKeyFilter>,
  update?: ?Array<?Avatar_TeamMemberUpdateInput>,
|};

/** Files subscription filter */
export type FileSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?FileFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** Files relation input */
export type FilesUsers_AvatarManyRelationInput = {|
  connect?: ?Array<UserKeyFilter>,
|};

/** Files relation input */
export type FilesUsers_AvatarRelationInput = {|
  connect?: ?Array<UserKeyFilter>,
  create?: ?Array<?Avatar_UserCreateInput>,
|};

/** Files relation input */
export type FilesUsers_AvatarUpdateRelationInput = {|
  connect?: ?Array<UserKeyFilter>,
  disconnect?: ?Array<UserKeyFilter>,
  reconnect?: ?Array<UserKeyFilter>,
  create?: ?Array<?Avatar_UserCreateInput>,
  update?: ?Array<?Avatar_UserUpdateInput>,
|};

export const FileTypeFormatEnumValues = Object.freeze({
  File: 'FILE',
  Image: 'IMAGE'
});


/** File Type Format Enum */
export type FileTypeFormatEnum = $Values<typeof FileTypeFormatEnumValues>;

/** Files update input */
export type FileUpdateByFilterInput = {|
  fileId?: ?Array<?UpdateByFilterStringInput>,
  downloadUrl?: ?Array<?UpdateByFilterStringInput>,
  shareUrl?: ?Array<?UpdateByFilterStringInput>,
  provider?: ?Array<?UpdateByFilterStringInput>,
  public?: ?Array<?UpdateByFilterBooleanSwitchInput>,
  uploaded?: ?Array<?UpdateByFilterBooleanSwitchInput>,
  filename?: ?Array<?UpdateByFilterStringInput>,
  uploadUrl?: ?Array<?UpdateByFilterStringInput>,
  fields?: ?Array<?UpdateByFilterJsonInput>,
  meta?: ?Array<?UpdateByFilterJsonInput>,
  mods?: ?Array<?UpdateByFilterJsonInput>,
|};

/** Files update input */
export type FileUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarUpdateRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarUpdateRelationInput,
|};

export type FileUploadInfoResponse = {|
  apiKey: $ElementType<Scalars, 'String'>,
  path: $ElementType<Scalars, 'String'>,
  policy: $ElementType<Scalars, 'String'>,
  signature: $ElementType<Scalars, 'String'>,
|};

export type FloatPredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'Float'>,
  not_equals?: ?$ElementType<Scalars, 'Float'>,
  in?: ?Array<$ElementType<Scalars, 'Float'>>,
  not_in?: ?Array<$ElementType<Scalars, 'Float'>>,
  lt?: ?$ElementType<Scalars, 'Float'>,
  lte?: ?$ElementType<Scalars, 'Float'>,
  gt?: ?$ElementType<Scalars, 'Float'>,
  gte?: ?$ElementType<Scalars, 'Float'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<FloatPredicateHaving>,
  OR?: ?Array<FloatPredicateHaving>,
|};

/** FunctionInfo */
export type FunctionInfo = {|
  application?: ?Application,
  description?: ?$ElementType<Scalars, 'String'>,
  functionType: FunctionType,
  name: $ElementType<Scalars, 'String'>,
|};

/** FunctionInfoFilter */
export type FunctionInfoFilter = {|
  description?: ?$ElementType<Scalars, 'String'>,
  functionType?: ?FunctionType,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export const FunctionInfoOrderByValues = Object.freeze({
  DescriptionAsc: 'description_ASC',
  DescriptionDesc: 'description_DESC',
  FunctionTypeAsc: 'functionType_ASC',
  FunctionTypeDesc: 'functionType_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC'
});


/** FunctionInfoOrderBy */
export type FunctionInfoOrderBy = $Values<typeof FunctionInfoOrderByValues>;

/** FunctionListResponse output */
export type FunctionListResponse = {|
  /** List items */
  items: Array<FunctionInfo>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

/** FunctionResolverInfo */
export type FunctionResolverInfo = {|
  ...FunctionInfo,
  ...{|
    application?: ?Application,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: FunctionType,
    gqlType: $ElementType<Scalars, 'String'>,
    name: $ElementType<Scalars, 'String'>,
  |}
|};

/** FunctionTaskInfo */
export type FunctionTaskInfo = {|
  ...FunctionInfo,
  ...{|
    application?: ?Application,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: FunctionType,
    name: $ElementType<Scalars, 'String'>,
    scheduleExpression?: ?$ElementType<Scalars, 'String'>,
  |}
|};

/** FunctionTriggerInfo */
export type FunctionTriggerInfo = {|
  ...FunctionInfo,
  ...{|
    application?: ?Application,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: FunctionType,
    name: $ElementType<Scalars, 'String'>,
    operation: $ElementType<Scalars, 'String'>,
    tableName: $ElementType<Scalars, 'String'>,
    type: $ElementType<Scalars, 'String'>,
  |}
|};

export const FunctionTypeValues = Object.freeze({
  Resolver: 'resolver',
  Schedule: 'schedule',
  Task: 'task',
  Trigger: 'trigger',
  Webhook: 'webhook'
});


/** FunctionType */
export type FunctionType = $Values<typeof FunctionTypeValues>;

/** FunctionWebhookInfo */
export type FunctionWebhookInfo = {|
  ...FunctionInfo,
  ...{|
    application?: ?Application,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: FunctionType,
    httpMethod: $ElementType<Scalars, 'String'>,
    name: $ElementType<Scalars, 'String'>,
    workspaceFullPath: $ElementType<Scalars, 'String'>,
    workspaceRelativePath: $ElementType<Scalars, 'String'>,
  |}
|};

/** Geo Field Attributes */
export type GeoFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
  srid?: ?$ElementType<Scalars, 'Int'>,
|};

/** Github connection params */
export type GithubOptions = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  client_id: $ElementType<Scalars, 'String'>,
  client_secret: $ElementType<Scalars, 'String'>,
|};

/** Github connection params input */
export type GithubOptionsInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  client_id: $ElementType<Scalars, 'String'>,
  client_secret: $ElementType<Scalars, 'String'>,
|};

/** Google connection params */
export type GoogleOptions = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  client_id: $ElementType<Scalars, 'String'>,
  client_secret: $ElementType<Scalars, 'String'>,
|};

/** Google connection params input */
export type GoogleOptionsInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  client_id: $ElementType<Scalars, 'String'>,
  client_secret: $ElementType<Scalars, 'String'>,
|};

/** GraphQLCreateFileCustomInput */
export type GraphQlCreateFileCustomInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** GraphQLCreateFileItemInput */
export type GraphQlCreateFileItemInput = {|
  create?: ?GraphQlCreateFileCustomInput,
|};

/** GraphQLFileItemResponse */
export type GraphQlFileItemResponse = {|
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type GroupByField = {|
  as?: ?$ElementType<Scalars, 'String'>,
  fn?: ?Array<?GroupByFieldFunction>,
|};

export type GroupByFieldFunction = {|
  aggregate?: ?AggregationFunctionType,
  distinct?: ?$ElementType<Scalars, 'Boolean'>,
  datePart?: ?DatePartFunctionType,
  abs?: ?$ElementType<Scalars, 'Boolean'>,
  ceil?: ?$ElementType<Scalars, 'Boolean'>,
  crc32?: ?$ElementType<Scalars, 'Boolean'>,
  floor?: ?$ElementType<Scalars, 'Boolean'>,
  mod?: ?$ElementType<Scalars, 'Int'>,
  round?: ?$ElementType<Scalars, 'Int'>,
  sign?: ?$ElementType<Scalars, 'Boolean'>,
  truncate?: ?$ElementType<Scalars, 'Int'>,
  left?: ?$ElementType<Scalars, 'Int'>,
  right?: ?$ElementType<Scalars, 'Int'>,
  locate?: ?LocateFunctionArguments,
  substring?: ?SubstringFunctionArguments,
  replace?: ?ReplaceFunctionArguments,
  reverse?: ?$ElementType<Scalars, 'Boolean'>,
  trim?: ?TrimFunctionArguments,
  ltrim?: ?$ElementType<Scalars, 'Boolean'>,
  rtrim?: ?$ElementType<Scalars, 'Boolean'>,
  lower?: ?$ElementType<Scalars, 'Boolean'>,
  upper?: ?$ElementType<Scalars, 'Boolean'>,
  ascii?: ?$ElementType<Scalars, 'Boolean'>,
  hex?: ?$ElementType<Scalars, 'Boolean'>,
  bitLength?: ?$ElementType<Scalars, 'Boolean'>,
  charLength?: ?$ElementType<Scalars, 'Boolean'>,
  length?: ?$ElementType<Scalars, 'Boolean'>,
  like?: ?PatternFunctionArguments,
  notLike?: ?PatternFunctionArguments,
  lpad?: ?StringPadFunctionArguments,
  rpad?: ?StringPadFunctionArguments,
  ifNull?: ?$ElementType<Scalars, 'String'>,
  isNull?: ?$ElementType<Scalars, 'Boolean'>,
  nullIf?: ?$ElementType<Scalars, 'String'>,
|};

export type GroupByResponse = {|
  ID?: ?$ElementType<Scalars, 'ID'>,
  String?: ?$ElementType<Scalars, 'String'>,
  Int?: ?$ElementType<Scalars, 'Int'>,
  BigInt?: ?$ElementType<Scalars, 'BigInt'>,
  Float?: ?$ElementType<Scalars, 'Float'>,
  DateTime?: ?$ElementType<Scalars, 'DateTime'>,
  Date?: ?$ElementType<Scalars, 'Date'>,
  Boolean?: ?$ElementType<Scalars, 'Boolean'>,
  JSON?: ?$ElementType<Scalars, 'JSON'>,
  GroupIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  UserGroup: UserListResponse,
  FileGroup: FileListResponse,
  RoleGroup: RoleListResponse,
  ApiTokenGroup: ApiTokenListResponse,
  EnvironmentVariableGroup: EnvironmentVariableListResponse,
  AuthenticationProfileGroup: AuthenticationProfileListResponse,
  TeamMemberGroup: TeamMemberListResponse,
|};


export type GroupByResponseUserGroupArgs = {|
  filter?: ?UserFilter,
  orderBy?: ?Array<?UserOrderBy>,
  sort?: ?Array<UserSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?UserGroupBy,
|};


export type GroupByResponseFileGroupArgs = {|
  filter?: ?FileFilter,
  orderBy?: ?Array<?FileOrderBy>,
  sort?: ?Array<FileSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?FileGroupBy,
|};


export type GroupByResponseRoleGroupArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
|};


export type GroupByResponseApiTokenGroupArgs = {|
  filter?: ?ApiTokenFilter,
  orderBy?: ?Array<?ApiTokenOrderBy>,
  sort?: ?Array<ApiTokenSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?ApiTokenGroupBy,
|};


export type GroupByResponseEnvironmentVariableGroupArgs = {|
  filter?: ?EnvironmentVariableFilter,
  orderBy?: ?Array<?EnvironmentVariableOrderBy>,
  sort?: ?Array<EnvironmentVariableSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?EnvironmentVariableGroupBy,
|};


export type GroupByResponseAuthenticationProfileGroupArgs = {|
  filter?: ?AuthenticationProfileFilter,
  orderBy?: ?Array<?AuthenticationProfileOrderBy>,
  sort?: ?Array<AuthenticationProfileSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?AuthenticationProfileGroupBy,
|};


export type GroupByResponseTeamMemberGroupArgs = {|
  filter?: ?TeamMemberFilter,
  orderBy?: ?Array<?TeamMemberOrderBy>,
  sort?: ?Array<TeamMemberSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?TeamMemberGroupBy,
|};

export type GroupBySort = {|
  alias: $ElementType<Scalars, 'String'>,
  direction: SortOrder,
|};

export type GroupIdentifiersGroupByField = {|
  as: $ElementType<Scalars, 'String'>,
|};

export type Having = {|
  alias?: ?$ElementType<Scalars, 'String'>,
  id?: ?IdPredicateHaving,
  string?: ?StringPredicateHaving,
  int?: ?IntPredicateHaving,
  bigint?: ?BigIntPredicateHaving,
  float?: ?FloatPredicateHaving,
  bool?: ?BoolPredicateHaving,
  date?: ?DatePredicateHaving,
  datetime?: ?DateTimePredicateHaving,
  AND?: ?Array<Having>,
  OR?: ?Array<Having>,
|};

export type IdPredicate = {|
  equals?: ?$ElementType<Scalars, 'ID'>,
  not_equals?: ?$ElementType<Scalars, 'ID'>,
  in?: ?Array<$ElementType<Scalars, 'ID'>>,
  not_in?: ?Array<$ElementType<Scalars, 'ID'>>,
  contains?: ?$ElementType<Scalars, 'ID'>,
  not_contains?: ?$ElementType<Scalars, 'ID'>,
  starts_with?: ?$ElementType<Scalars, 'ID'>,
  not_starts_with?: ?$ElementType<Scalars, 'ID'>,
  ends_with?: ?$ElementType<Scalars, 'ID'>,
  not_ends_with?: ?$ElementType<Scalars, 'ID'>,
  lt?: ?$ElementType<Scalars, 'ID'>,
  lte?: ?$ElementType<Scalars, 'ID'>,
  gt?: ?$ElementType<Scalars, 'ID'>,
  gte?: ?$ElementType<Scalars, 'ID'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type IdPredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'ID'>,
  not_equals?: ?$ElementType<Scalars, 'ID'>,
  in?: ?Array<$ElementType<Scalars, 'ID'>>,
  not_in?: ?Array<$ElementType<Scalars, 'ID'>>,
  contains?: ?$ElementType<Scalars, 'ID'>,
  not_contains?: ?$ElementType<Scalars, 'ID'>,
  starts_with?: ?$ElementType<Scalars, 'ID'>,
  not_starts_with?: ?$ElementType<Scalars, 'ID'>,
  ends_with?: ?$ElementType<Scalars, 'ID'>,
  not_ends_with?: ?$ElementType<Scalars, 'ID'>,
  lt?: ?$ElementType<Scalars, 'ID'>,
  lte?: ?$ElementType<Scalars, 'ID'>,
  gt?: ?$ElementType<Scalars, 'ID'>,
  gte?: ?$ElementType<Scalars, 'ID'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<IdPredicateHaving>,
  OR?: ?Array<IdPredicateHaving>,
|};

/** ImportedTable */
export type ImportedTable = {|
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
|};

/** Inbox Events List Filter */
export type InboxEventsListFilter = {|
  isCompleted?: ?IntPredicate,
|};

/** Table Create Index Input */
export type IndexCreateInput = {|
  columns: Array<TableIndexColumnInput>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  tableId: $ElementType<Scalars, 'ID'>,
  type: TableIndexType,
|};

/** Table Delete Index Input */
export type IndexDeleteInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

/** Table Update Index Input */
export type IndexUpdateInput = {|
  columns?: ?Array<TableIndexColumnInput>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?TableIndexType,
|};

export type IntPredicate = {|
  equals?: ?$ElementType<Scalars, 'Int'>,
  gt?: ?$ElementType<Scalars, 'Int'>,
  gte?: ?$ElementType<Scalars, 'Int'>,
  in?: ?Array<$ElementType<Scalars, 'Int'>>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  lt?: ?$ElementType<Scalars, 'Int'>,
  lte?: ?$ElementType<Scalars, 'Int'>,
  not_equals?: ?$ElementType<Scalars, 'Int'>,
  not_in?: ?Array<$ElementType<Scalars, 'Int'>>,
|};

export type IntPredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'Int'>,
  not_equals?: ?$ElementType<Scalars, 'Int'>,
  in?: ?Array<$ElementType<Scalars, 'Int'>>,
  not_in?: ?Array<$ElementType<Scalars, 'Int'>>,
  lt?: ?$ElementType<Scalars, 'Int'>,
  lte?: ?$ElementType<Scalars, 'Int'>,
  gt?: ?$ElementType<Scalars, 'Int'>,
  gte?: ?$ElementType<Scalars, 'Int'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<IntPredicateHaving>,
  OR?: ?Array<IntPredicateHaving>,
|};

export type IntrospectionQueryResponse = {|
  url: $ElementType<Scalars, 'String'>,
|};

/** Invited By Name */
export type InvitedByName = {|
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  workspaceName?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
|};

/** InvokeData */
export type InvokeData = {|
  functionName: $ElementType<Scalars, 'String'>,
  inputArgs?: ?$ElementType<Scalars, 'String'>,
|};

/** InvokeFunctionResponse */
export type InvokeFunctionResponse = {|
  responseData: $ElementType<Scalars, 'String'>,
|};


export type LocateFunctionArguments = {|
  str: $ElementType<Scalars, 'String'>,
  pos?: ?$ElementType<Scalars, 'Int'>,
|};

/** LoginResponse */
export type LoginResponse = {|
  auth?: ?Auth,
  success?: ?$ElementType<Scalars, 'Boolean'>,
  workspaces?: ?Array<WorkspaceInfo>,
|};

/** MissingRelation */
export type MissingRelation = {|
  table: $ElementType<Scalars, 'String'>,
|};

/** MissingRelation Field Attributes */
export type MissingRelationFieldTypeAttributes = {|
  missingTable: $ElementType<Scalars, 'String'>,
|};

export type Mutation = {|
  apiTokenCreate: ApiTokenResponse,
  apiTokenDelete?: ?SuccessResponse,
  apiTokenDeleteByFilter?: ?SuccessResponse,
  apiTokenDestroy?: ?SuccessResponse,
  apiTokenDestroyByFilter?: ?SuccessResponse,
  apiTokenRestore: ApiToken,
  apiTokenUpdate: ApiToken,
  apiTokenUpdateByFilter: ApiTokenManyResponse,
  applicationDelete?: ?SuccessResponse,
  applicationInstall?: ?Application,
  applicationUpdate?: ?Application,
  authenticationProfileCreate: AuthenticationProfile,
  authenticationProfileCreateMany: AuthenticationProfileManyResponse,
  authenticationProfileDelete?: ?SuccessResponse,
  authenticationProfileDeleteByFilter?: ?SuccessResponse,
  authenticationProfileDestroy?: ?SuccessResponse,
  authenticationProfileDestroyByFilter?: ?SuccessResponse,
  authenticationProfileRestore: AuthenticationProfile,
  authenticationProfileUpdate: AuthenticationProfile,
  authenticationProfileUpdateByFilter: AuthenticationProfileManyResponse,
  authenticationSettingsUpdate: AuthenticationSetting,
  billingDetailsUpdate?: ?BillingDetailsResponse,
  billingPlanUpdate?: ?BillingCurrentPlanResponse,
  deploy?: ?$ElementType<Scalars, 'Boolean'>,
  environmentVariableCreate: EnvironmentVariable,
  environmentVariableCreateMany: EnvironmentVariableManyResponse,
  environmentVariableDelete?: ?SuccessResponse,
  environmentVariableDeleteByFilter?: ?SuccessResponse,
  environmentVariableDestroy?: ?SuccessResponse,
  environmentVariableDestroyByFilter?: ?SuccessResponse,
  environmentVariableRestore: EnvironmentVariable,
  environmentVariableUpdate: EnvironmentVariable,
  environmentVariableUpdateByFilter: EnvironmentVariableManyResponse,
  fieldCreate: TableField,
  fieldDelete: SuccessResponse,
  fieldUpdate: TableField,
  fieldUpdatePosition: SuccessResponse,
  fileCreate: File,
  fileCreateMany: FileManyResponse,
  fileDelete?: ?SuccessResponse,
  fileDeleteByFilter?: ?SuccessResponse,
  fileDestroy?: ?SuccessResponse,
  fileDestroyByFilter?: ?SuccessResponse,
  fileRestore: File,
  fileUpdate: File,
  fileUpdateByFilter: FileManyResponse,
  indexCreate: TableIndex,
  indexDelete?: ?SuccessResponse,
  indexUpdate: TableIndex,
  invoke?: ?InvokeFunctionResponse,
  prepareDeploy: DeployDataResponse,
  roleCreate: Role,
  roleCreateMany: RoleManyResponse,
  roleDelete?: ?SuccessResponse,
  roleDeleteByFilter?: ?SuccessResponse,
  roleDestroy?: ?SuccessResponse,
  roleDestroyByFilter?: ?SuccessResponse,
  roleRestore: Role,
  roleUpdate: Role,
  roleUpdateByFilter: RoleManyResponse,
  sendInvitationTo8base?: ?SuccessResponse,
  settingsUpdate: Setting,
  system?: ?SystemMutation,
  tableCreate: Table,
  tableDelete: SuccessResponse,
  tableUpdate: Table,
  teamInvitationDelete?: ?SuccessResponse,
  teamInvitationDeleteByFilter?: ?SuccessResponse,
  teamInvitationDestroy?: ?SuccessResponse,
  teamInvitationDestroyByFilter?: ?SuccessResponse,
  teamInvitationRestore: TeamInvitation,
  teamMemberDelete?: ?SuccessResponse,
  teamMemberDeleteByFilter?: ?SuccessResponse,
  teamMemberDestroy?: ?SuccessResponse,
  teamMemberDestroyByFilter?: ?SuccessResponse,
  teamMemberRestore: TeamMember,
  teamMemberUpdate: TeamMember,
  teamMemberUpdateByFilter: TeamMemberManyResponse,
  userChangePassword?: ?SuccessResponse,
  userCreate: User,
  userCreateMany: UserManyResponse,
  userDelete?: ?SuccessResponse,
  userDeleteByFilter?: ?SuccessResponse,
  userDestroy?: ?SuccessResponse,
  userDestroyByFilter?: ?SuccessResponse,
  userLogin?: ?LoginResponse,
  userPasswordForgot?: ?SuccessResponse,
  userPasswordForgotConfirm?: ?SuccessResponse,
  userRefreshToken?: ?Auth,
  userRestore: User,
  userSignUp: User,
  userSignUpResend?: ?SuccessResponse,
  userSignUpWithPassword: User,
  userSignUpWithToken: User,
  userUpdate: User,
  userUpdateByFilter: UserManyResponse,
  userVerificationEmailResend?: ?SuccessResponse,
  viewCreate: Table,
  viewUpdate: Table,
  workspaceCreate?: ?WorkspaceCreateResponse,
  workspaceCreateAsync?: ?WorkspaceCreateResponse,
  workspaceDelete?: ?SuccessResponse,
  workspaceLeave?: ?SuccessResponse,
  workspaceUpdate?: ?WorkspaceUpdateResponse,
|};


export type MutationApiTokenCreateArgs = {|
  data: ApiTokenCreateInput,
|};


export type MutationApiTokenDeleteArgs = {|
  data?: ?ApiTokenDeleteInput,
  filter?: ?ApiTokenKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationApiTokenDeleteByFilterArgs = {|
  filter: ApiTokenFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationApiTokenDestroyArgs = {|
  filter?: ?ApiTokenKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationApiTokenDestroyByFilterArgs = {|
  filter: ApiTokenFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationApiTokenRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationApiTokenUpdateArgs = {|
  data: ApiTokenUpdateInput,
  filter?: ?ApiTokenKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationApiTokenUpdateByFilterArgs = {|
  data: ApiTokenUpdateByFilterInput,
  filter?: ?ApiTokenFilter,
|};


export type MutationApplicationDeleteArgs = {|
  data: ApplicationDeleteMutationInput,
|};


export type MutationApplicationInstallArgs = {|
  data: ApplicationInstallInput,
|};


export type MutationApplicationUpdateArgs = {|
  data: ApplicationUpdateInput,
|};


export type MutationAuthenticationProfileCreateArgs = {|
  data: AuthenticationProfileCreateInput,
|};


export type MutationAuthenticationProfileCreateManyArgs = {|
  data: Array<?AuthenticationProfileCreateManyInput>,
|};


export type MutationAuthenticationProfileDeleteArgs = {|
  data?: ?AuthenticationProfileDeleteInput,
  filter?: ?AuthenticationProfileKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationAuthenticationProfileDeleteByFilterArgs = {|
  filter: AuthenticationProfileFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationAuthenticationProfileDestroyArgs = {|
  filter?: ?AuthenticationProfileKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationAuthenticationProfileDestroyByFilterArgs = {|
  filter: AuthenticationProfileFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationAuthenticationProfileRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationAuthenticationProfileUpdateArgs = {|
  data: AuthenticationProfileUpdateInput,
  filter?: ?AuthenticationProfileKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationAuthenticationProfileUpdateByFilterArgs = {|
  data: AuthenticationProfileUpdateByFilterInput,
  filter?: ?AuthenticationProfileFilter,
|};


export type MutationAuthenticationSettingsUpdateArgs = {|
  data: AuthenticationSettingUpdateInput,
|};


export type MutationBillingDetailsUpdateArgs = {|
  data: BillingDetailsUpdateMutationInput,
|};


export type MutationBillingPlanUpdateArgs = {|
  data: BillingPlanUpdateMutationInput,
|};


export type MutationDeployArgs = {|
  data?: ?DeployingBuildInput,
|};


export type MutationEnvironmentVariableCreateArgs = {|
  data: EnvironmentVariableCreateInput,
|};


export type MutationEnvironmentVariableCreateManyArgs = {|
  data: Array<?EnvironmentVariableCreateManyInput>,
|};


export type MutationEnvironmentVariableDeleteArgs = {|
  data?: ?EnvironmentVariableDeleteInput,
  filter?: ?EnvironmentVariableKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationEnvironmentVariableDeleteByFilterArgs = {|
  filter: EnvironmentVariableFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationEnvironmentVariableDestroyArgs = {|
  filter?: ?EnvironmentVariableKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationEnvironmentVariableDestroyByFilterArgs = {|
  filter: EnvironmentVariableFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationEnvironmentVariableRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationEnvironmentVariableUpdateArgs = {|
  data: EnvironmentVariableUpdateInput,
  filter?: ?EnvironmentVariableKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationEnvironmentVariableUpdateByFilterArgs = {|
  data: EnvironmentVariableUpdateByFilterInput,
  filter?: ?EnvironmentVariableFilter,
|};


export type MutationFieldCreateArgs = {|
  data: TableFieldCreateInput,
|};


export type MutationFieldDeleteArgs = {|
  data: TableFieldDeleteInput,
|};


export type MutationFieldUpdateArgs = {|
  data: TableFieldUpdateInput,
|};


export type MutationFieldUpdatePositionArgs = {|
  data: TableFieldPositionUpdateInput,
|};


export type MutationFileCreateArgs = {|
  data: FileCreateInput,
|};


export type MutationFileCreateManyArgs = {|
  data: Array<?FileCreateManyInput>,
|};


export type MutationFileDeleteArgs = {|
  data?: ?FileDeleteInput,
  filter?: ?FileKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationFileDeleteByFilterArgs = {|
  filter: FileFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationFileDestroyArgs = {|
  filter?: ?FileKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationFileDestroyByFilterArgs = {|
  filter: FileFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationFileRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationFileUpdateArgs = {|
  data: FileUpdateInput,
  filter?: ?FileKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationFileUpdateByFilterArgs = {|
  data: FileUpdateByFilterInput,
  filter?: ?FileFilter,
|};


export type MutationIndexCreateArgs = {|
  data: IndexCreateInput,
|};


export type MutationIndexDeleteArgs = {|
  data: IndexDeleteInput,
|};


export type MutationIndexUpdateArgs = {|
  data: IndexUpdateInput,
|};


export type MutationInvokeArgs = {|
  data?: ?InvokeData,
|};


export type MutationRoleCreateArgs = {|
  data: RoleCreateInput,
|};


export type MutationRoleCreateManyArgs = {|
  data: Array<?RoleCreateManyInput>,
|};


export type MutationRoleDeleteArgs = {|
  data?: ?RoleDeleteInput,
  filter?: ?RoleKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationRoleDeleteByFilterArgs = {|
  filter: RoleFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationRoleDestroyArgs = {|
  filter?: ?RoleKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationRoleDestroyByFilterArgs = {|
  filter: RoleFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationRoleRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationRoleUpdateArgs = {|
  data: RoleUpdateInput,
  filter?: ?RoleKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationRoleUpdateByFilterArgs = {|
  data: RoleUpdateByFilterInput,
  filter?: ?RoleFilter,
|};


export type MutationSendInvitationTo8baseArgs = {|
  inviteEmail: $ElementType<Scalars, 'String'>,
|};


export type MutationSettingsUpdateArgs = {|
  data: SettingUpdateInput,
|};


export type MutationTableCreateArgs = {|
  data: TableCreateInput,
|};


export type MutationTableDeleteArgs = {|
  data: TableDeleteInput,
|};


export type MutationTableUpdateArgs = {|
  data: TableUpdateInput,
|};


export type MutationTeamInvitationDeleteArgs = {|
  data?: ?TeamInvitationDeleteInput,
  filter?: ?TeamInvitationKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamInvitationDeleteByFilterArgs = {|
  filter: TeamInvitationFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamInvitationDestroyArgs = {|
  filter?: ?TeamInvitationKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamInvitationDestroyByFilterArgs = {|
  filter: TeamInvitationFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamInvitationRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationTeamMemberDeleteArgs = {|
  data?: ?TeamMemberDeleteInput,
  filter?: ?TeamMemberKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamMemberDeleteByFilterArgs = {|
  filter: TeamMemberFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamMemberDestroyArgs = {|
  filter?: ?TeamMemberKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamMemberDestroyByFilterArgs = {|
  filter: TeamMemberFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamMemberRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationTeamMemberUpdateArgs = {|
  data: TeamMemberUpdateInput,
  filter?: ?TeamMemberKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationTeamMemberUpdateByFilterArgs = {|
  data: TeamMemberUpdateByFilterInput,
  filter?: ?TeamMemberFilter,
|};


export type MutationUserChangePasswordArgs = {|
  data: ChangePasswordInput,
|};


export type MutationUserCreateArgs = {|
  data: UserCreateInput,
|};


export type MutationUserCreateManyArgs = {|
  data: Array<?UserCreateManyInput>,
|};


export type MutationUserDeleteArgs = {|
  data?: ?UserDeleteInput,
  filter?: ?UserKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationUserDeleteByFilterArgs = {|
  filter: UserFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationUserDestroyArgs = {|
  filter?: ?UserKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationUserDestroyByFilterArgs = {|
  filter: UserFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationUserLoginArgs = {|
  data: UserLoginInput,
|};


export type MutationUserPasswordForgotArgs = {|
  data: PasswordForgotInput,
|};


export type MutationUserPasswordForgotConfirmArgs = {|
  data: PasswordForgotConfirmInput,
|};


export type MutationUserRefreshTokenArgs = {|
  data: RefreshTokenInput,
|};


export type MutationUserRestoreArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type MutationUserSignUpArgs = {|
  user: UserCreateInput,
  authProfileId?: ?$ElementType<Scalars, 'ID'>,
|};


export type MutationUserSignUpResendArgs = {|
  data: SignUpResendInput,
|};


export type MutationUserSignUpWithPasswordArgs = {|
  user: UserCreateInput,
  password: $ElementType<Scalars, 'String'>,
  authProfileId?: ?$ElementType<Scalars, 'ID'>,
|};


export type MutationUserSignUpWithTokenArgs = {|
  user: UserCreateInput,
  authProfileId?: ?$ElementType<Scalars, 'ID'>,
|};


export type MutationUserUpdateArgs = {|
  data: UserUpdateInput,
  filter?: ?UserKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  destroyDetached?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationUserUpdateByFilterArgs = {|
  data: UserUpdateByFilterInput,
  filter?: ?UserFilter,
|};


export type MutationUserVerificationEmailResendArgs = {|
  authProfileId?: ?$ElementType<Scalars, 'ID'>,
  data: VerificationEmailResendInput,
|};


export type MutationViewCreateArgs = {|
  data: ViewCreateInput,
|};


export type MutationViewUpdateArgs = {|
  data: ViewUpdateInput,
|};


export type MutationWorkspaceCreateArgs = {|
  data: WorkspaceCreateMutationInput,
|};


export type MutationWorkspaceCreateAsyncArgs = {|
  data: WorkspaceCreateMutationInput,
|};


export type MutationWorkspaceDeleteArgs = {|
  data: WorkspaceDeleteMutationInput,
|};


export type MutationWorkspaceLeaveArgs = {|
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type MutationWorkspaceUpdateArgs = {|
  data: WorkspaceUpdateMutationInput,
|};

export const MutationTypeValues = Object.freeze({
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Destroy: 'destroy'
});


export type MutationType = $Values<typeof MutationTypeValues>;

export const NotificationStatusTypeValues = Object.freeze({
  Done: 'done'
});


export type NotificationStatusType = $Values<typeof NotificationStatusTypeValues>;

/** Number Field Attributes */
export type NumberFieldTypeAttributes = {|
  autoIncrement?: ?$ElementType<Scalars, 'Boolean'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  format: $ElementType<Scalars, 'String'>,
  isBigInt?: ?$ElementType<Scalars, 'Boolean'>,
  maxValue?: ?$ElementType<Scalars, 'Float'>,
  minValue?: ?$ElementType<Scalars, 'Float'>,
  precision?: ?$ElementType<Scalars, 'Int'>,
|};

export const NumberTypeFormatEnumValues = Object.freeze({
  Currency: 'CURRENCY',
  Fraction: 'FRACTION',
  Number: 'NUMBER',
  Percentage: 'PERCENTAGE',
  Scientific: 'SCIENTIFIC'
});


/** Number Type Format Enum */
export type NumberTypeFormatEnum = $Values<typeof NumberTypeFormatEnumValues>;

export type OrganizationUserInvitationResponse = {|
  invitationId: $ElementType<Scalars, 'String'>,
|};

/** PasswordForgotConfirmInput */
export type PasswordForgotConfirmInput = {|
  authProfileId: $ElementType<Scalars, 'ID'>,
  code: $ElementType<Scalars, 'String'>,
  email: $ElementType<Scalars, 'String'>,
  newPassword: $ElementType<Scalars, 'String'>,
|};

/** PasswordForgotInput */
export type PasswordForgotInput = {|
  authProfileId: $ElementType<Scalars, 'ID'>,
  email: $ElementType<Scalars, 'String'>,
|};

export type PatternFunctionArguments = {|
  pattern: $ElementType<Scalars, 'String'>,
  escape?: ?$ElementType<Scalars, 'String'>,
|};

export type Permission = {|
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  createdBy?: ?User,
  appId?: ?$ElementType<Scalars, 'String'>,
  resourceType?: ?$ElementType<Scalars, 'String'>,
  resource?: ?$ElementType<Scalars, 'String'>,
  permission?: ?$ElementType<Scalars, 'JSON'>,
  role?: ?Role,
  _description?: ?$ElementType<Scalars, 'String'>,
|};

export type Permission_PermissionFilter = {|
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  appId?: ?StringPredicate,
  resourceType?: ?StringPredicate,
  resource?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  role?: ?Role_PermissionFilter,
  AND?: ?Array<Permission_PermissionFilter>,
  OR?: ?Array<Permission_PermissionFilter>,
|};

export type Permission_PermissionRelationFilter = {|
  some?: ?Permission_PermissionFilter,
  every?: ?Permission_PermissionFilter,
  none?: ?Permission_PermissionFilter,
|};

export type PermissionFilter = {|
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  appId?: ?StringPredicate,
  resourceType?: ?StringPredicate,
  resource?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  role?: ?RoleFilter,
  AND?: ?Array<PermissionFilter>,
  OR?: ?Array<PermissionFilter>,
|};

export type PermissionGroupByQuery = {|
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  appId?: ?Array<GroupByField>,
  resourceType?: ?Array<GroupByField>,
  resource?: ?Array<GroupByField>,
  permission?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  role?: ?RoleGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

/** Permission Filter */
export type PermissionInputFilter = {|
  resourceType?: ?PermissionResourceTypeEnum,
  action?: ?$ElementType<Scalars, 'String'>,
  resource?: ?$ElementType<Scalars, 'String'>,
  applicationName?: ?$ElementType<Scalars, 'String'>,
|};

/** PermissionListResponse output */
export type PermissionListResponse = {|
  /** List items */
  items: Array<Permission>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** Permissions subscription payload */
export type PermissionPayload = {|
  mutation: MutationType,
  node?: ?Permission,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?Permission,
|};

export type PermissionRelationFilter = {|
  some?: ?PermissionFilter,
  every?: ?PermissionFilter,
  none?: ?PermissionFilter,
|};

export const PermissionResourceTypeEnumValues = Object.freeze({
  Data: 'data',
  Custom: 'custom'
});


export type PermissionResourceTypeEnum = $Values<typeof PermissionResourceTypeEnumValues>;

/** custom permissions input */
export type PermissionsCustom = {|
  data?: ?PermissionsCustomData,
  logic?: ?PermissionsCustomLogic,
  users?: ?PermissionsCustomUsers,
  settings?: ?PermissionsCustomSettings,
|};

export type PermissionsCustomData = {|
  schemaManagement?: ?PermissionsCustomDataSchemaManagement,
  viewerAccess?: ?PermissionsCustomDataViewerAccess,
|};

export type PermissionsCustomDataSchemaManagement = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomDataViewerAccess = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomLogic = {|
  view?: ?PermissionsCustomLogicView,
  deploy?: ?PermissionsCustomLogicDeploy,
  invoke?: ?PermissionsCustomLogicInvoke,
  logs?: ?PermissionsCustomLogicLogs,
|};

export type PermissionsCustomLogicDeploy = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomLogicInvoke = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomLogicLogs = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomLogicView = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomSettings = {|
  workspaceAdministration?: ?PermissionsCustomSettingsWorkspaceAdministration,
|};

export type PermissionsCustomSettingsWorkspaceAdministration = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsCustomUsers = {|
  teamAdministration?: ?PermissionsCustomUsersTeamAdministration,
|};

export type PermissionsCustomUsersTeamAdministration = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

/** Schema tables permissions input */
export type PermissionsData = {|
  Users?: ?PermissionsDataUsers,
  Files?: ?PermissionsDataFiles,
  Roles?: ?PermissionsDataRoles,
  TeamInvitations?: ?PermissionsDataTeamInvitations,
  TeamMembers?: ?PermissionsDataTeamMembers,
|};

export type PermissionsDataFiles = {|
  create?: ?PermissionsDataFilesCreate,
  read?: ?PermissionsDataFilesRead,
  update?: ?PermissionsDataFilesUpdate,
  delete?: ?PermissionsDataFilesDelete,
  destroy?: ?PermissionsDataFilesDestroy,
|};

export type PermissionsDataFilesCreate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataFilesDelete = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  restore?: ?$ElementType<Scalars, 'Boolean'>,
  review?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataFilesDestroy = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataFilesRead = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?File_PermissionFilter,
  fields?: ?FileFieldsPermissions,
|};

export type PermissionsDataFilesUpdate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?File_PermissionFilter,
  fields?: ?FileFieldsPermissions,
|};

export type PermissionsDataRoles = {|
  create?: ?PermissionsDataRolesCreate,
  read?: ?PermissionsDataRolesRead,
  update?: ?PermissionsDataRolesUpdate,
  delete?: ?PermissionsDataRolesDelete,
  destroy?: ?PermissionsDataRolesDestroy,
|};

export type PermissionsDataRolesCreate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataRolesDelete = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  restore?: ?$ElementType<Scalars, 'Boolean'>,
  review?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataRolesDestroy = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataRolesRead = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?Role_PermissionFilter,
  fields?: ?RoleFieldsPermissions,
|};

export type PermissionsDataRolesUpdate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?Role_PermissionFilter,
  fields?: ?RoleFieldsPermissions,
|};

export type PermissionsDataTeamInvitations = {|
  create?: ?PermissionsDataTeamInvitationsCreate,
  read?: ?PermissionsDataTeamInvitationsRead,
  update?: ?PermissionsDataTeamInvitationsUpdate,
  delete?: ?PermissionsDataTeamInvitationsDelete,
  destroy?: ?PermissionsDataTeamInvitationsDestroy,
|};

export type PermissionsDataTeamInvitationsCreate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataTeamInvitationsDelete = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  restore?: ?$ElementType<Scalars, 'Boolean'>,
  review?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataTeamInvitationsDestroy = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataTeamInvitationsRead = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?TeamInvitation_PermissionFilter,
  fields?: ?TeamInvitationFieldsPermissions,
|};

export type PermissionsDataTeamInvitationsUpdate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?TeamInvitation_PermissionFilter,
  fields?: ?TeamInvitationFieldsPermissions,
|};

export type PermissionsDataTeamMembers = {|
  create?: ?PermissionsDataTeamMembersCreate,
  read?: ?PermissionsDataTeamMembersRead,
  update?: ?PermissionsDataTeamMembersUpdate,
  delete?: ?PermissionsDataTeamMembersDelete,
  destroy?: ?PermissionsDataTeamMembersDestroy,
|};

export type PermissionsDataTeamMembersCreate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataTeamMembersDelete = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  restore?: ?$ElementType<Scalars, 'Boolean'>,
  review?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataTeamMembersDestroy = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataTeamMembersRead = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?TeamMember_PermissionFilter,
  fields?: ?TeamMemberFieldsPermissions,
|};

export type PermissionsDataTeamMembersUpdate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?TeamMember_PermissionFilter,
  fields?: ?TeamMemberFieldsPermissions,
|};

export type PermissionsDataUsers = {|
  create?: ?PermissionsDataUsersCreate,
  read?: ?PermissionsDataUsersRead,
  update?: ?PermissionsDataUsersUpdate,
  delete?: ?PermissionsDataUsersDelete,
  destroy?: ?PermissionsDataUsersDestroy,
|};

export type PermissionsDataUsersCreate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataUsersDelete = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  restore?: ?$ElementType<Scalars, 'Boolean'>,
  review?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataUsersDestroy = {|
  allow: $ElementType<Scalars, 'Boolean'>,
|};

export type PermissionsDataUsersRead = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?User_PermissionFilter,
  fields?: ?UserFieldsPermissions,
|};

export type PermissionsDataUsersUpdate = {|
  allow: $ElementType<Scalars, 'Boolean'>,
  filter?: ?User_PermissionFilter,
  fields?: ?UserFieldsPermissions,
|};

/** PermissionsInput create input */
export type PermissionsInput = {|
  data?: ?PermissionsData,
  custom?: ?PermissionsCustom,
|};

/** Permissions subscription filter */
export type PermissionSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?PermissionFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

export type Query = {|
  apiToken?: ?ApiToken,
  apiTokensList: ApiTokenListResponse,
  application?: ?Application,
  applicationsList?: ?ApplicationListResponse,
  asyncSessionStatus?: ?AsyncSessionStatusResponse,
  authenticationProfile?: ?AuthenticationProfile,
  authenticationProfilesList: AuthenticationProfileListResponse,
  authenticationSettings?: ?AuthenticationSetting,
  billingCurrentPlan?: ?BillingCurrentPlanResponse,
  billingDetails?: ?BillingDetailsResponse,
  billingInvoicesList: BillingInvoicesListResponse,
  billingMetricUsageQuotasList: BillingMetricUsageQuotasListResponse,
  billingMetricUsagesList: BillingMetricUsagesListResponse,
  companyName?: ?$ElementType<Scalars, 'String'>,
  deployStatus: DeployStatusResult,
  environmentVariable?: ?EnvironmentVariable,
  environmentVariablesList: EnvironmentVariableListResponse,
  file?: ?File,
  fileUploadInfo?: ?FileUploadInfoResponse,
  filesList: FileListResponse,
  functionsList?: ?FunctionListResponse,
  logs?: ?Array<?$ElementType<Scalars, 'String'>>,
  role?: ?Role,
  rolesList: RoleListResponse,
  settings?: ?Setting,
  system?: ?SystemQuery,
  table?: ?Table,
  tableField?: ?TableField,
  tablesList: TableListResponse,
  teamMember?: ?TeamMember,
  teamMembersList: TeamMemberListResponse,
  user?: ?User,
  userBillingConfiguration: UserBillingConfigurationResponse,
  userInvitationsList?: ?UserInvitationList,
  usersList: UserListResponse,
  viewDryRun?: ?ViewDryRunOutput,
  workspacesList?: ?WorkspaceListResponse,
|};


export type QueryApiTokenArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryApiTokensListArgs = {|
  filter?: ?ApiTokenFilter,
  orderBy?: ?Array<?ApiTokenOrderBy>,
  sort?: ?Array<ApiTokenSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?ApiTokenGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryApplicationArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type QueryAsyncSessionStatusArgs = {|
  sessionId: $ElementType<Scalars, 'String'>,
|};


export type QueryAuthenticationProfileArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryAuthenticationProfilesListArgs = {|
  filter?: ?AuthenticationProfileFilter,
  orderBy?: ?Array<?AuthenticationProfileOrderBy>,
  sort?: ?Array<AuthenticationProfileSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?AuthenticationProfileGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryBillingCurrentPlanArgs = {|
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryBillingInvoicesListArgs = {|
  after?: ?$ElementType<Scalars, 'ID'>,
  before?: ?$ElementType<Scalars, 'ID'>,
  by?: ?BillingInvoicesListFilterType,
  limit?: ?$ElementType<Scalars, 'Int'>,
|};


export type QueryBillingMetricUsageQuotasListArgs = {|
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryBillingMetricUsagesListArgs = {|
  filter?: ?BillingMetricUsagesListFilter,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryDeployStatusArgs = {|
  buildName: $ElementType<Scalars, 'String'>,
|};


export type QueryEnvironmentVariableArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryEnvironmentVariablesListArgs = {|
  filter?: ?EnvironmentVariableFilter,
  orderBy?: ?Array<?EnvironmentVariableOrderBy>,
  sort?: ?Array<EnvironmentVariableSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?EnvironmentVariableGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryFileArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  fileId?: ?$ElementType<Scalars, 'String'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryFilesListArgs = {|
  filter?: ?FileFilter,
  orderBy?: ?Array<?FileOrderBy>,
  sort?: ?Array<FileSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?FileGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryFunctionsListArgs = {|
  applicationId?: ?$ElementType<Scalars, 'String'>,
  filter?: ?FunctionInfoFilter,
  orderBy?: ?Array<?FunctionInfoOrderBy>,
|};


export type QueryLogsArgs = {|
  functionName: $ElementType<Scalars, 'String'>,
  applicationId?: ?$ElementType<Scalars, 'String'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  startTime?: ?$ElementType<Scalars, 'DateTime'>,
  endTime?: ?$ElementType<Scalars, 'DateTime'>,
|};


export type QueryRoleArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryRolesListArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryTableArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};


export type QueryTableFieldArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};


export type QueryTablesListArgs = {|
  filter?: ?TableListFilter,
|};


export type QueryTeamMemberArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryTeamMembersListArgs = {|
  filter?: ?TeamMemberFilter,
  orderBy?: ?Array<?TeamMemberOrderBy>,
  sort?: ?Array<TeamMemberSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?TeamMemberGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryUserArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryUserBillingConfigurationArgs = {|
  kind?: ?$ElementType<Scalars, 'String'>,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  region?: ?$ElementType<Scalars, 'String'>,
|};


export type QueryUsersListArgs = {|
  filter?: ?UserFilter,
  orderBy?: ?Array<?UserOrderBy>,
  sort?: ?Array<UserSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?UserGroupBy,
  withDeleted?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryViewDryRunArgs = {|
  sql: $ElementType<Scalars, 'String'>,
|};

/** RefreshTokenInput */
export type RefreshTokenInput = {|
  authProfileId?: ?$ElementType<Scalars, 'ID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  refreshToken: $ElementType<Scalars, 'String'>,
|};

/** Relation */
export type Relation = {|
  refField?: ?TableField,
  refFieldDisplayName?: ?$ElementType<Scalars, 'String'>,
  refFieldIsList?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldIsRequired?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldName?: ?$ElementType<Scalars, 'String'>,
  refTable: Table,
  relationFieldName?: ?$ElementType<Scalars, 'String'>,
  relationTableName?: ?$ElementType<Scalars, 'String'>,
|};

/** Relation Create Input */
export type RelationCreateInput = {|
  refFieldDisplayName?: ?$ElementType<Scalars, 'String'>,
  refFieldIsList: $ElementType<Scalars, 'Boolean'>,
  refFieldIsRequired: $ElementType<Scalars, 'Boolean'>,
  refFieldName?: ?$ElementType<Scalars, 'String'>,
  refTableId: $ElementType<Scalars, 'ID'>,
|};

/** Relation Update Input */
export type RelationUpdateInput = {|
  refFieldDisplayName?: ?$ElementType<Scalars, 'String'>,
  refFieldIsList?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldIsRequired?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldName?: ?$ElementType<Scalars, 'String'>,
  refTableId?: ?$ElementType<Scalars, 'ID'>,
|};

export const RelativePredicateOpEnumValues = Object.freeze({
  Add: 'ADD',
  Sub: 'SUB'
});


/** Relative Date Predicate Operation Enum */
export type RelativePredicateOpEnum = $Values<typeof RelativePredicateOpEnumValues>;

export const RelativePredicateUnitEnumValues = Object.freeze({
  Day: 'DAY',
  DayHour: 'DAY_HOUR',
  DayMicrosecond: 'DAY_MICROSECOND',
  DayMinute: 'DAY_MINUTE',
  DaySecond: 'DAY_SECOND',
  Hour: 'HOUR',
  HourMicrosecond: 'HOUR_MICROSECOND',
  HourMinute: 'HOUR_MINUTE',
  HourSecond: 'HOUR_SECOND',
  Microsecond: 'MICROSECOND',
  Minute: 'MINUTE',
  MinuteMicrosecond: 'MINUTE_MICROSECOND',
  MinuteSecond: 'MINUTE_SECOND',
  Month: 'MONTH',
  Quarter: 'QUARTER',
  Second: 'SECOND',
  SecondMicrosecond: 'SECOND_MICROSECOND',
  Week: 'WEEK',
  Year: 'YEAR',
  YearMonth: 'YEAR_MONTH'
});


/** Relative Date Predicate Unit Enum */
export type RelativePredicateUnitEnum = $Values<typeof RelativePredicateUnitEnumValues>;

export type ReplaceFunctionArguments = {|
  from: $ElementType<Scalars, 'String'>,
  to: $ElementType<Scalars, 'String'>,
|};

export type ResizeImageDirectiveCropOptions = {|
  height: $ElementType<Scalars, 'Int'>,
  offsetX?: ?$ElementType<Scalars, 'Int'>,
  offsetY?: ?$ElementType<Scalars, 'Int'>,
  width: $ElementType<Scalars, 'Int'>,
|};

export type ResizeImageDirectiveFlipOptions = {|
  horizontally?: ?$ElementType<Scalars, 'Boolean'>,
  vertically?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type ResizeImageDirectiveResizeOptions = {|
  height?: ?$ElementType<Scalars, 'Int'>,
  width?: ?$ElementType<Scalars, 'Int'>,
|};

export type Role = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  name?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?UserListResponse,
  apiTokens?: ?ApiTokenListResponse,
  authenticationProfiles?: ?AuthenticationProfileListResponse,
  teamMembers?: ?TeamMemberListResponse,
  permissions?: ?PermissionListResponse,
  _description?: ?$ElementType<Scalars, 'String'>,
  membersCount: $ElementType<Scalars, 'Int'>,
|};


export type RoleUsersArgs = {|
  filter?: ?UserFilter,
  orderBy?: ?Array<?UserOrderBy>,
  sort?: ?Array<UserSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?UserGroupBy,
|};


export type RoleApiTokensArgs = {|
  filter?: ?ApiTokenFilter,
  orderBy?: ?Array<?ApiTokenOrderBy>,
  sort?: ?Array<ApiTokenSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?ApiTokenGroupBy,
|};


export type RoleAuthenticationProfilesArgs = {|
  filter?: ?AuthenticationProfileFilter,
  orderBy?: ?Array<?AuthenticationProfileOrderBy>,
  sort?: ?Array<AuthenticationProfileSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?AuthenticationProfileGroupBy,
|};


export type RoleTeamMembersArgs = {|
  filter?: ?TeamMemberFilter,
  orderBy?: ?Array<?TeamMemberOrderBy>,
  sort?: ?Array<TeamMemberSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?TeamMemberGroupBy,
|};


export type RolePermissionsArgs = {|
  filter?: ?PermissionInputFilter,
|};

export type Role_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  description?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  users?: ?User_PermissionRelationFilter,
  permissions?: ?Permission_PermissionRelationFilter,
  apiTokens?: ?ApiToken_PermissionRelationFilter,
  authenticationProfiles?: ?AuthenticationProfile_PermissionRelationFilter,
  teamMembers?: ?TeamMember_PermissionRelationFilter,
  AND?: ?Array<Role_PermissionFilter>,
  OR?: ?Array<Role_PermissionFilter>,
|};

export type Role_PermissionRelationFilter = {|
  some?: ?Role_PermissionFilter,
  every?: ?Role_PermissionFilter,
  none?: ?Role_PermissionFilter,
|};

/** Roles create input */
export type RoleCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersRelationInput,
  permissions?: ?PermissionsInput,
  apiTokens?: ?RolesApiTokensRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesRelationInput,
  teamMembers?: ?RolesTeamMembersRelationInput,
|};

/** Roles create many input */
export type RoleCreateManyInput = {|
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersManyRelationInput,
  apiTokens?: ?RolesApiTokensManyRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesManyRelationInput,
  teamMembers?: ?RolesTeamMembersManyRelationInput,
|};

/** Roles delete input */
export type RoleDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** RoleFieldsPermissions create input */
export type RoleFieldsPermissions = {|
  createdAt?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'Boolean'>,
  description?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type RoleFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  name?: ?StringPredicate,
  description?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  users?: ?UserRelationFilter,
  permissions?: ?PermissionRelationFilter,
  apiTokens?: ?ApiTokenRelationFilter,
  authenticationProfiles?: ?AuthenticationProfileRelationFilter,
  teamMembers?: ?TeamMemberRelationFilter,
  AND?: ?Array<RoleFilter>,
  OR?: ?Array<RoleFilter>,
|};

export type RoleGroupBy = {|
  query: RoleGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type RoleGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  name?: ?Array<GroupByField>,
  description?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  users?: ?UserGroupByQuery,
  permissions?: ?PermissionGroupByQuery,
  apiTokens?: ?ApiTokenGroupByQuery,
  authenticationProfiles?: ?AuthenticationProfileGroupByQuery,
  teamMembers?: ?TeamMemberGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type RoleKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** RoleListResponse output */
export type RoleListResponse = {|
  /** List items */
  items: Array<Role>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** RoleManyResponse output */
export type RoleManyResponse = {|
  /** List items */
  items: Array<Role>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const RoleOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC',
  DescriptionAsc: 'description_ASC',
  DescriptionDesc: 'description_DESC',
  SystemTypeAsc: 'systemType_ASC',
  SystemTypeDesc: 'systemType_DESC'
});


/** No longer supported. Use `sort` instead. */
export type RoleOrderBy = $Values<typeof RoleOrderByValues>;

/** Roles subscription payload */
export type RolePayload = {|
  mutation: MutationType,
  node?: ?Role,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?Role,
|};

export type RoleRelationFilter = {|
  some?: ?RoleFilter,
  every?: ?RoleFilter,
  none?: ?RoleFilter,
|};

/** ApiTokens update input from roles */
export type Roles_ApiTokenUpdateInput = {|
  filter?: ?ApiTokenKeyFilter,
  data: ApiTokenUpdateInput,
|};

/** AuthenticationProfiles create input from roles */
export type Roles_AuthenticationProfileCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
  secret?: ?$ElementType<Scalars, 'String'>,
  managementDomain?: ?$ElementType<Scalars, 'String'>,
  clientId?: ?$ElementType<Scalars, 'String'>,
  databaseName?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  selfSignUpEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  selfSignUpEmailDomains?: ?Array<?$ElementType<Scalars, 'String'>>,
  roles?: ?AuthenticationProfilesRolesRelationInput,
  audiences?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

/** AuthenticationProfiles update input from roles */
export type Roles_AuthenticationProfileUpdateInput = {|
  filter?: ?AuthenticationProfileKeyFilter,
  data: AuthenticationProfileUpdateInput,
|};

/** TeamMembers update input from roles */
export type Roles_TeamMemberUpdateInput = {|
  filter?: ?TeamMemberKeyFilter,
  data: TeamMemberUpdateInput,
|};

/** Users create input from roles */
export type Roles_UserCreateInput = {|
  email: $ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?UsersAvatarRelationInput,
  roles?: ?UsersRolesRelationInput,
|};

/** Users update input from roles */
export type Roles_UserUpdateInput = {|
  filter?: ?UserKeyFilter,
  data: UserUpdateInput,
|};

/** Roles relation input */
export type RolesApiTokensManyRelationInput = {|
  connect?: ?Array<ApiTokenKeyFilter>,
|};

/** Roles relation input */
export type RolesApiTokensRelationInput = {|
  connect?: ?Array<ApiTokenKeyFilter>,
|};

/** Roles relation input */
export type RolesApiTokensUpdateRelationInput = {|
  connect?: ?Array<ApiTokenKeyFilter>,
  disconnect?: ?Array<ApiTokenKeyFilter>,
  reconnect?: ?Array<ApiTokenKeyFilter>,
  update?: ?Array<?Roles_ApiTokenUpdateInput>,
|};

/** Roles relation input */
export type RolesAuthenticationProfilesManyRelationInput = {|
  connect?: ?Array<AuthenticationProfileKeyFilter>,
|};

/** Roles relation input */
export type RolesAuthenticationProfilesRelationInput = {|
  connect?: ?Array<AuthenticationProfileKeyFilter>,
  create?: ?Array<?Roles_AuthenticationProfileCreateInput>,
|};

/** Roles relation input */
export type RolesAuthenticationProfilesUpdateRelationInput = {|
  connect?: ?Array<AuthenticationProfileKeyFilter>,
  disconnect?: ?Array<AuthenticationProfileKeyFilter>,
  reconnect?: ?Array<AuthenticationProfileKeyFilter>,
  create?: ?Array<?Roles_AuthenticationProfileCreateInput>,
  update?: ?Array<?Roles_AuthenticationProfileUpdateInput>,
|};

export type RoleSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  name?: ?SortOrder,
  description?: ?SortOrder,
  createdBy?: ?UserSort,
|};

/** Roles relation input */
export type RolesTeamMembersManyRelationInput = {|
  connect?: ?Array<TeamMemberKeyFilter>,
|};

/** Roles relation input */
export type RolesTeamMembersRelationInput = {|
  connect?: ?Array<TeamMemberKeyFilter>,
|};

/** Roles relation input */
export type RolesTeamMembersUpdateRelationInput = {|
  connect?: ?Array<TeamMemberKeyFilter>,
  disconnect?: ?Array<TeamMemberKeyFilter>,
  reconnect?: ?Array<TeamMemberKeyFilter>,
  update?: ?Array<?Roles_TeamMemberUpdateInput>,
|};

/** Roles subscription filter */
export type RoleSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?RoleFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** Roles relation input */
export type RolesUsersManyRelationInput = {|
  connect?: ?Array<UserKeyFilter>,
|};

/** Roles relation input */
export type RolesUsersRelationInput = {|
  connect?: ?Array<UserKeyFilter>,
  create?: ?Array<?Roles_UserCreateInput>,
|};

/** Roles relation input */
export type RolesUsersUpdateRelationInput = {|
  connect?: ?Array<UserKeyFilter>,
  disconnect?: ?Array<UserKeyFilter>,
  reconnect?: ?Array<UserKeyFilter>,
  create?: ?Array<?Roles_UserCreateInput>,
  update?: ?Array<?Roles_UserUpdateInput>,
|};

/** Roles update input */
export type RoleUpdateByFilterInput = {|
  name?: ?Array<?UpdateByFilterStringInput>,
  description?: ?Array<?UpdateByFilterStringInput>,
  systemType?: ?Array<?UpdateByFilterStringInput>,
|};

/** Roles update input */
export type RoleUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersUpdateRelationInput,
  permissions?: ?PermissionsInput,
  apiTokens?: ?RolesApiTokensUpdateRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesUpdateRelationInput,
  teamMembers?: ?RolesTeamMembersUpdateRelationInput,
|};

/** Schema Origin */
export type SchemaOrigin = {|
  provider?: ?$ElementType<Scalars, 'String'>,
  type: SchemaOriginType,
|};

export const SchemaOriginTypeValues = Object.freeze({
  Local: 'LOCAL',
  Remote: 'REMOTE',
  View: 'VIEW'
});


/** Schema Origin Type Enum */
export type SchemaOriginType = $Values<typeof SchemaOriginTypeValues>;

export type Setting = {|
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  createdBy?: ?User,
  passwordMinLength?: ?$ElementType<Scalars, 'Int'>,
  passwordRequireNumbers?: ?$ElementType<Scalars, 'Boolean'>,
  passwordRequireSpecial?: ?$ElementType<Scalars, 'Boolean'>,
  passwordRequireUppercase?: ?$ElementType<Scalars, 'Boolean'>,
  passwordRequireLowercase?: ?$ElementType<Scalars, 'Boolean'>,
  passwordUpdateInterval?: ?$ElementType<Scalars, 'Int'>,
  rememberDevice?: ?$ElementType<Scalars, 'String'>,
  language?: ?$ElementType<Scalars, 'String'>,
  dateFormat?: ?$ElementType<Scalars, 'String'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  menuBarLogo?: ?File,
  landingPageImage?: ?File,
  vanityUrl?: ?$ElementType<Scalars, 'String'>,
  buttonLinkColor?: ?$ElementType<Scalars, 'String'>,
  userInterfaceStyle?: ?$ElementType<Scalars, 'String'>,
  menuBarBGColor?: ?$ElementType<Scalars, 'String'>,
  menuBarIconsColor?: ?$ElementType<Scalars, 'String'>,
  bgColor?: ?$ElementType<Scalars, 'String'>,
  containerColor?: ?$ElementType<Scalars, 'String'>,
  leftNavColor?: ?$ElementType<Scalars, 'String'>,
  _description?: ?$ElementType<Scalars, 'String'>,
|};

export type Setting_PermissionFilter = {|
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  passwordMinLength?: ?IntPredicate,
  passwordRequireNumbers?: ?BoolPredicate,
  passwordRequireSpecial?: ?BoolPredicate,
  passwordRequireUppercase?: ?BoolPredicate,
  passwordRequireLowercase?: ?BoolPredicate,
  passwordUpdateInterval?: ?IntPredicate,
  rememberDevice?: ?StringPredicate,
  language?: ?StringPredicate,
  dateFormat?: ?StringPredicate,
  currency?: ?StringPredicate,
  timezone?: ?StringPredicate,
  vanityUrl?: ?StringPredicate,
  buttonLinkColor?: ?StringPredicate,
  userInterfaceStyle?: ?StringPredicate,
  menuBarBGColor?: ?StringPredicate,
  menuBarIconsColor?: ?StringPredicate,
  bgColor?: ?StringPredicate,
  containerColor?: ?StringPredicate,
  leftNavColor?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  menuBarLogo?: ?File_PermissionFilter,
  landingPageImage?: ?File_PermissionFilter,
  AND?: ?Array<Setting_PermissionFilter>,
  OR?: ?Array<Setting_PermissionFilter>,
|};

export type Setting_PermissionRelationFilter = {|
  some?: ?Setting_PermissionFilter,
  every?: ?Setting_PermissionFilter,
  none?: ?Setting_PermissionFilter,
|};

export type SettingFilter = {|
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  passwordMinLength?: ?IntPredicate,
  passwordRequireNumbers?: ?BoolPredicate,
  passwordRequireSpecial?: ?BoolPredicate,
  passwordRequireUppercase?: ?BoolPredicate,
  passwordRequireLowercase?: ?BoolPredicate,
  passwordUpdateInterval?: ?IntPredicate,
  rememberDevice?: ?StringPredicate,
  language?: ?StringPredicate,
  dateFormat?: ?StringPredicate,
  currency?: ?StringPredicate,
  timezone?: ?StringPredicate,
  vanityUrl?: ?StringPredicate,
  buttonLinkColor?: ?StringPredicate,
  userInterfaceStyle?: ?StringPredicate,
  menuBarBGColor?: ?StringPredicate,
  menuBarIconsColor?: ?StringPredicate,
  bgColor?: ?StringPredicate,
  containerColor?: ?StringPredicate,
  leftNavColor?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  menuBarLogo?: ?FileFilter,
  landingPageImage?: ?FileFilter,
  AND?: ?Array<SettingFilter>,
  OR?: ?Array<SettingFilter>,
|};

export type SettingGroupBy = {|
  query: SettingGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type SettingGroupByQuery = {|
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  passwordMinLength?: ?Array<GroupByField>,
  passwordRequireNumbers?: ?Array<GroupByField>,
  passwordRequireSpecial?: ?Array<GroupByField>,
  passwordRequireUppercase?: ?Array<GroupByField>,
  passwordRequireLowercase?: ?Array<GroupByField>,
  passwordUpdateInterval?: ?Array<GroupByField>,
  rememberDevice?: ?Array<GroupByField>,
  language?: ?Array<GroupByField>,
  dateFormat?: ?Array<GroupByField>,
  currency?: ?Array<GroupByField>,
  timezone?: ?Array<GroupByField>,
  vanityUrl?: ?Array<GroupByField>,
  buttonLinkColor?: ?Array<GroupByField>,
  userInterfaceStyle?: ?Array<GroupByField>,
  menuBarBGColor?: ?Array<GroupByField>,
  menuBarIconsColor?: ?Array<GroupByField>,
  bgColor?: ?Array<GroupByField>,
  containerColor?: ?Array<GroupByField>,
  leftNavColor?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  menuBarLogo?: ?FileGroupByQuery,
  landingPageImage?: ?FileGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

/** SettingListResponse output */
export type SettingListResponse = {|
  /** List items */
  items: Array<Setting>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

export const SettingOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  PasswordMinLengthAsc: 'passwordMinLength_ASC',
  PasswordMinLengthDesc: 'passwordMinLength_DESC',
  PasswordRequireNumbersAsc: 'passwordRequireNumbers_ASC',
  PasswordRequireNumbersDesc: 'passwordRequireNumbers_DESC',
  PasswordRequireSpecialAsc: 'passwordRequireSpecial_ASC',
  PasswordRequireSpecialDesc: 'passwordRequireSpecial_DESC',
  PasswordRequireUppercaseAsc: 'passwordRequireUppercase_ASC',
  PasswordRequireUppercaseDesc: 'passwordRequireUppercase_DESC',
  PasswordRequireLowercaseAsc: 'passwordRequireLowercase_ASC',
  PasswordRequireLowercaseDesc: 'passwordRequireLowercase_DESC',
  PasswordUpdateIntervalAsc: 'passwordUpdateInterval_ASC',
  PasswordUpdateIntervalDesc: 'passwordUpdateInterval_DESC',
  RememberDeviceAsc: 'rememberDevice_ASC',
  RememberDeviceDesc: 'rememberDevice_DESC',
  LanguageAsc: 'language_ASC',
  LanguageDesc: 'language_DESC',
  DateFormatAsc: 'dateFormat_ASC',
  DateFormatDesc: 'dateFormat_DESC',
  CurrencyAsc: 'currency_ASC',
  CurrencyDesc: 'currency_DESC',
  TimezoneAsc: 'timezone_ASC',
  TimezoneDesc: 'timezone_DESC',
  VanityUrlAsc: 'vanityUrl_ASC',
  VanityUrlDesc: 'vanityUrl_DESC',
  ButtonLinkColorAsc: 'buttonLinkColor_ASC',
  ButtonLinkColorDesc: 'buttonLinkColor_DESC',
  UserInterfaceStyleAsc: 'userInterfaceStyle_ASC',
  UserInterfaceStyleDesc: 'userInterfaceStyle_DESC',
  MenuBarBgColorAsc: 'menuBarBGColor_ASC',
  MenuBarBgColorDesc: 'menuBarBGColor_DESC',
  MenuBarIconsColorAsc: 'menuBarIconsColor_ASC',
  MenuBarIconsColorDesc: 'menuBarIconsColor_DESC',
  BgColorAsc: 'bgColor_ASC',
  BgColorDesc: 'bgColor_DESC',
  ContainerColorAsc: 'containerColor_ASC',
  ContainerColorDesc: 'containerColor_DESC',
  LeftNavColorAsc: 'leftNavColor_ASC',
  LeftNavColorDesc: 'leftNavColor_DESC'
});


/** No longer supported. Use `sort` instead. */
export type SettingOrderBy = $Values<typeof SettingOrderByValues>;

/** Settings subscription payload */
export type SettingPayload = {|
  mutation: MutationType,
  node?: ?Setting,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?Setting,
|};

export type SettingRelationFilter = {|
  some?: ?SettingFilter,
  every?: ?SettingFilter,
  none?: ?SettingFilter,
|};

export type SettingSort = {|
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  passwordMinLength?: ?SortOrder,
  passwordRequireNumbers?: ?SortOrder,
  passwordRequireSpecial?: ?SortOrder,
  passwordRequireUppercase?: ?SortOrder,
  passwordRequireLowercase?: ?SortOrder,
  passwordUpdateInterval?: ?SortOrder,
  rememberDevice?: ?SortOrder,
  language?: ?SortOrder,
  dateFormat?: ?SortOrder,
  currency?: ?SortOrder,
  timezone?: ?SortOrder,
  vanityUrl?: ?SortOrder,
  buttonLinkColor?: ?SortOrder,
  userInterfaceStyle?: ?SortOrder,
  menuBarBGColor?: ?SortOrder,
  menuBarIconsColor?: ?SortOrder,
  bgColor?: ?SortOrder,
  containerColor?: ?SortOrder,
  leftNavColor?: ?SortOrder,
  createdBy?: ?UserSort,
  menuBarLogo?: ?FileSort,
  landingPageImage?: ?FileSort,
|};

/** Settings subscription filter */
export type SettingSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?SettingFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** Settings update input */
export type SettingUpdateInput = {|
  passwordMinLength?: ?$ElementType<Scalars, 'Int'>,
  passwordRequireNumbers?: ?$ElementType<Scalars, 'Boolean'>,
  passwordRequireSpecial?: ?$ElementType<Scalars, 'Boolean'>,
  passwordRequireUppercase?: ?$ElementType<Scalars, 'Boolean'>,
  passwordRequireLowercase?: ?$ElementType<Scalars, 'Boolean'>,
  passwordUpdateInterval?: ?$ElementType<Scalars, 'Int'>,
  rememberDevice?: ?$ElementType<Scalars, 'String'>,
  language?: ?$ElementType<Scalars, 'String'>,
  dateFormat?: ?$ElementType<Scalars, 'String'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  vanityUrl?: ?$ElementType<Scalars, 'String'>,
  buttonLinkColor?: ?$ElementType<Scalars, 'String'>,
  userInterfaceStyle?: ?$ElementType<Scalars, 'String'>,
  menuBarBGColor?: ?$ElementType<Scalars, 'String'>,
  menuBarIconsColor?: ?$ElementType<Scalars, 'String'>,
  bgColor?: ?$ElementType<Scalars, 'String'>,
  containerColor?: ?$ElementType<Scalars, 'String'>,
  leftNavColor?: ?$ElementType<Scalars, 'String'>,
|};

/** SignUpResendInput */
export type SignUpResendInput = {|
  email: $ElementType<Scalars, 'String'>,
|};

/** Smart Field Attributes */
export type SmartFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
  innerFields?: ?Array<?CustomTableField>,
|};

export const SmartTypeFormatEnumValues = Object.freeze({
  Address: 'ADDRESS',
  Phone: 'PHONE'
});


/** Smart Type Format Enum */
export type SmartTypeFormatEnum = $Values<typeof SmartTypeFormatEnumValues>;

export const SortOrderValues = Object.freeze({
  Asc: 'ASC',
  Desc: 'DESC'
});


/** SortOrder */
export type SortOrder = $Values<typeof SortOrderValues>;

export type StringPadFunctionArguments = {|
  len: $ElementType<Scalars, 'Int'>,
  str: $ElementType<Scalars, 'String'>,
|};

export type StringPredicate = {|
  equals?: ?$ElementType<Scalars, 'String'>,
  not_equals?: ?$ElementType<Scalars, 'String'>,
  in?: ?Array<$ElementType<Scalars, 'String'>>,
  not_in?: ?Array<$ElementType<Scalars, 'String'>>,
  contains?: ?$ElementType<Scalars, 'String'>,
  not_contains?: ?$ElementType<Scalars, 'String'>,
  starts_with?: ?$ElementType<Scalars, 'String'>,
  not_starts_with?: ?$ElementType<Scalars, 'String'>,
  ends_with?: ?$ElementType<Scalars, 'String'>,
  not_ends_with?: ?$ElementType<Scalars, 'String'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type StringPredicateHaving = {|
  equals?: ?$ElementType<Scalars, 'String'>,
  not_equals?: ?$ElementType<Scalars, 'String'>,
  in?: ?Array<$ElementType<Scalars, 'String'>>,
  not_in?: ?Array<$ElementType<Scalars, 'String'>>,
  contains?: ?$ElementType<Scalars, 'String'>,
  not_contains?: ?$ElementType<Scalars, 'String'>,
  starts_with?: ?$ElementType<Scalars, 'String'>,
  not_starts_with?: ?$ElementType<Scalars, 'String'>,
  ends_with?: ?$ElementType<Scalars, 'String'>,
  not_ends_with?: ?$ElementType<Scalars, 'String'>,
  is_empty?: ?$ElementType<Scalars, 'Boolean'>,
  is_not_empty?: ?$ElementType<Scalars, 'Boolean'>,
  AND?: ?Array<StringPredicateHaving>,
  OR?: ?Array<StringPredicateHaving>,
|};

export const StringTrimModeValues = Object.freeze({
  Both: 'BOTH',
  Leading: 'LEADING',
  Trailing: 'TRAILING'
});


export type StringTrimMode = $Values<typeof StringTrimModeValues>;

export type Subscription = {|
  ApiTokens?: ?ApiTokenPayload,
  AuthenticationProfiles?: ?AuthenticationProfilePayload,
  AuthenticationSettings?: ?AuthenticationSettingPayload,
  EnvironmentVariables?: ?EnvironmentVariablePayload,
  Files?: ?FilePayload,
  Permissions?: ?PermissionPayload,
  Roles?: ?RolePayload,
  Settings?: ?SettingPayload,
  TeamInvitations?: ?TeamInvitationPayload,
  TeamMembers?: ?TeamMemberPayload,
  Users?: ?UserPayload,
|};


export type SubscriptionApiTokensArgs = {|
  filter?: ?ApiTokenSubscriptionFilter,
|};


export type SubscriptionAuthenticationProfilesArgs = {|
  filter?: ?AuthenticationProfileSubscriptionFilter,
|};


export type SubscriptionAuthenticationSettingsArgs = {|
  filter?: ?AuthenticationSettingSubscriptionFilter,
|};


export type SubscriptionEnvironmentVariablesArgs = {|
  filter?: ?EnvironmentVariableSubscriptionFilter,
|};


export type SubscriptionFilesArgs = {|
  filter?: ?FileSubscriptionFilter,
|};


export type SubscriptionPermissionsArgs = {|
  filter?: ?PermissionSubscriptionFilter,
|};


export type SubscriptionRolesArgs = {|
  filter?: ?RoleSubscriptionFilter,
|};


export type SubscriptionSettingsArgs = {|
  filter?: ?SettingSubscriptionFilter,
|};


export type SubscriptionTeamInvitationsArgs = {|
  filter?: ?TeamInvitationSubscriptionFilter,
|};


export type SubscriptionTeamMembersArgs = {|
  filter?: ?TeamMemberSubscriptionFilter,
|};


export type SubscriptionUsersArgs = {|
  filter?: ?UserSubscriptionFilter,
|};

export type SubstringFunctionArguments = {|
  pos: $ElementType<Scalars, 'Int'>,
  len?: ?$ElementType<Scalars, 'Int'>,
|};

export type SuccessResponse = {|
  success?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type SuccessWithMessageResponse = {|
  message?: ?$ElementType<Scalars, 'String'>,
  success?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** Switch Field Attributes */
export type SwitchFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
  listOptions?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export const SwitchTypeFormatEnumValues = Object.freeze({
  ActiveInactive: 'ACTIVE_INACTIVE',
  Custom: 'CUSTOM',
  HighLow: 'HIGH_LOW',
  OnOff: 'ON_OFF',
  TrueFalse: 'TRUE_FALSE',
  YesNo: 'YES_NO'
});


/** Switch Type Format Enum */
export type SwitchTypeFormatEnum = $Values<typeof SwitchTypeFormatEnumValues>;

/** Application */
export type SystemApplication = {|
  appType: $ElementType<Scalars, 'String'>,
  createdAt: $ElementType<Scalars, 'DateTime'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  status: SystemApplicationStatusEnum,
|};

/** ApplicationDeleteMutationInput */
export type SystemApplicationDeleteMutationInput = {|
  id: $ElementType<Scalars, 'String'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** Application install input */
export type SystemApplicationInstallInput = {|
  appType: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status?: ?SystemApplicationStatusEnum,
  displayName?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
|};

/** SystemApplicationListResponse output */
export type SystemApplicationListResponse = {|
  /** List items */
  items: Array<SystemApplication>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const SystemApplicationStatusEnumValues = Object.freeze({
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
});


/** Application Status Enum */
export type SystemApplicationStatusEnum = $Values<typeof SystemApplicationStatusEnumValues>;

/** Application update input */
export type SystemApplicationUpdateInput = {|
  id: $ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  status?: ?SystemApplicationStatusEnum,
|};

export type SystemAsyncSessionStatusResponse = {|
  result?: ?$ElementType<Scalars, 'JSON'>,
  status: $ElementType<Scalars, 'String'>,
|};

export type SystemBillingCurrentPlanResponse = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  nextPlan?: ?SystemBillingNextPlanResponse,
  paymentDate?: ?$ElementType<Scalars, 'DateTime'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
  status?: ?SystemWorkspaceStatus,
  trialEnd?: ?$ElementType<Scalars, 'DateTime'>,
|};

export const SystemBillingDetailsOriginValues = Object.freeze({
  Member: 'member',
  Organization: 'organization',
  Workspace: 'workspace'
});


export type SystemBillingDetailsOrigin = $Values<typeof SystemBillingDetailsOriginValues>;

export type SystemBillingDetailsResponse = {|
  brand?: ?$ElementType<Scalars, 'String'>,
  expMonth?: ?$ElementType<Scalars, 'Int'>,
  expYear?: ?$ElementType<Scalars, 'Int'>,
  last4?: ?$ElementType<Scalars, 'String'>,
  origin: SystemBillingDetailsOrigin,
|};

/** BillingDetailsUpdateMutationInput */
export type SystemBillingDetailsUpdateMutationInput = {|
  cardToken: $ElementType<Scalars, 'String'>,
|};

export type SystemBillingInvoiceItem = {|
  amountDue?: ?$ElementType<Scalars, 'Float'>,
  amountPaid?: ?$ElementType<Scalars, 'Float'>,
  amountRemaining?: ?$ElementType<Scalars, 'Float'>,
  description?: ?$ElementType<Scalars, 'String'>,
  endingBalance?: ?$ElementType<Scalars, 'Float'>,
  id: $ElementType<Scalars, 'ID'>,
  invoicePdf?: ?$ElementType<Scalars, 'String'>,
  number?: ?$ElementType<Scalars, 'String'>,
  organization?: ?SystemBillingInvoiceItemOrganizationInfo,
  paid?: ?$ElementType<Scalars, 'Boolean'>,
  periodEnd?: ?$ElementType<Scalars, 'DateTime'>,
  periodStart?: ?$ElementType<Scalars, 'DateTime'>,
  plan?: ?SystemBillingInvoiceItemPlanInfo,
  status?: ?$ElementType<Scalars, 'String'>,
  total?: ?$ElementType<Scalars, 'Float'>,
  workspace?: ?SystemBillingInvoiceItemWorkspaceInfo,
|};

export type SystemBillingInvoiceItemOrganizationInfo = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemBillingInvoiceItemPlanInfo = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemBillingInvoiceItemWorkspaceInfo = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export const SystemBillingInvoicesListFilterTypeValues = Object.freeze({
  Customer: 'CUSTOMER',
  Workspace: 'WORKSPACE'
});


export type SystemBillingInvoicesListFilterType = $Values<typeof SystemBillingInvoicesListFilterTypeValues>;

/** SystemBillingInvoicesListResponse output */
export type SystemBillingInvoicesListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemBillingInvoiceItem>,
|};

export type SystemBillingLimitMetricItem = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  showPriority?: ?$ElementType<Scalars, 'Int'>,
  tooltip?: ?$ElementType<Scalars, 'String'>,
  unit?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemBillingMetricOverageItem = {|
  value?: ?$ElementType<Scalars, 'Float'>,
  warning?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemBillingMetricUsageItem = {|
  limitMetric?: ?SystemBillingLimitMetricItem,
  overage?: ?SystemBillingMetricOverageItem,
  value?: ?$ElementType<Scalars, 'Float'>,
|};

export type SystemBillingMetricUsageQuotaItem = {|
  limitMetric?: ?SystemBillingLimitMetricItem,
  value?: ?$ElementType<Scalars, 'Float'>,
|};

/** SystemBillingMetricUsageQuotasListResponse output */
export type SystemBillingMetricUsageQuotasListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemBillingMetricUsageQuotaItem>,
|};

export type SystemBillingMetricUsagesListFilter = {|
  entryDate: DateTimePredicate,
|};

/** SystemBillingMetricUsagesListResponse output */
export type SystemBillingMetricUsagesListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemBillingMetricUsageItem>,
|};

export type SystemBillingNextPlanResponse = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
|};

export type SystemBillingPlanBaseInfo = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  isCustom?: ?$ElementType<Scalars, 'Boolean'>,
  isLegacy?: ?$ElementType<Scalars, 'Boolean'>,
  limitMetrics?: ?Array<?SystemBillingPlanLimitMetricItem>,
  name?: ?$ElementType<Scalars, 'String'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
|};

export type SystemBillingPlanBaseScalarInfo = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  pdf?: ?$ElementType<Scalars, 'String'>,
  price?: ?$ElementType<Scalars, 'Int'>,
|};

export type SystemBillingPlanLimitMetricItem = {|
  displayName?: ?$ElementType<Scalars, 'String'>,
  hardLimit?: ?$ElementType<Scalars, 'Float'>,
  name?: ?$ElementType<Scalars, 'String'>,
  overagePrice?: ?$ElementType<Scalars, 'Int'>,
  softLimit?: ?$ElementType<Scalars, 'Float'>,
|};

/** BillingPlanUpdateMutationInput */
export type SystemBillingPlanUpdateMutationInput = {|
  planId: $ElementType<Scalars, 'ID'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
|};

export const SystemBranchEnvironmentModeValues = Object.freeze({
  Full: 'FULL',
  System: 'SYSTEM'
});


export type SystemBranchEnvironmentMode = $Values<typeof SystemBranchEnvironmentModeValues>;

export type SystemCacheEvictResponse = {|
  evicted: Array<?$ElementType<Scalars, 'String'>>,
|};

export const SystemCiCommitModeValues = Object.freeze({
  Full: 'FULL',
  OnlyMigrations: 'ONLY_MIGRATIONS',
  OnlyProject: 'ONLY_PROJECT'
});


/** Ci Commit Mode */
export type SystemCiCommitMode = $Values<typeof SystemCiCommitModeValues>;

/** Ci Status */
export type SystemCiStatusOutput = {|
  status: $ElementType<Scalars, 'String'>,
  migrations?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

/** CloudLogs Entry */
export type SystemCloudLogsEntry = {|
  message?: ?$ElementType<Scalars, 'String'>,
  timestamp?: ?$ElementType<Scalars, 'DateTime'>,
|};

export const SystemComputedFieldModeValues = Object.freeze({
  Stored: 'STORED',
  Virtual: 'VIRTUAL'
});


/** Computed field mode */
export type SystemComputedFieldMode = $Values<typeof SystemComputedFieldModeValues>;

/** Custom Table Field Type */
export type SystemCustomTableField = {|
  computedMode?: ?SystemComputedFieldMode,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType?: ?SystemFieldType,
  fieldTypeAttributes?: ?SystemFieldTypeAttributes,
  isList: $ElementType<Scalars, 'Boolean'>,
  isRequired: $ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** Date Field Attributes */
export type SystemDateFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
|};

export const SystemDateTypeFormatEnumValues = Object.freeze({
  Date: 'DATE',
  Datetime: 'DATETIME'
});


/** Date Type Format Enum */
export type SystemDateTypeFormatEnum = $Values<typeof SystemDateTypeFormatEnumValues>;

/** DeployDataResponse */
export type SystemDeployDataResponse = {|
  uploadBuildUrl: $ElementType<Scalars, 'String'>,
  uploadMetaDataUrl: $ElementType<Scalars, 'String'>,
  buildName: $ElementType<Scalars, 'String'>,
|};

/** DeployingBuildInput */
export type SystemDeployingBuildInput = {|
  buildName: $ElementType<Scalars, 'String'>,
  options?: ?SystemDeployOptions,
|};

export const SystemDeployModeEnumValues = Object.freeze({
  Full: 'FULL',
  OnlyPlugins: 'ONLY_PLUGINS',
  OnlyProject: 'ONLY_PROJECT',
  Functions: 'FUNCTIONS',
  Migrations: 'MIGRATIONS'
});


export type SystemDeployModeEnum = $Values<typeof SystemDeployModeEnumValues>;

/** DeployOptions */
export type SystemDeployOptions = {|
  mode?: ?SystemDeployModeEnum,
  pluginNames?: ?Array<?$ElementType<Scalars, 'String'>>,
  extensionNames?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export const SystemDeployStatusEnumValues = Object.freeze({
  Deploying: 'deploying',
  CompleteError: 'complete_error',
  CompleteSuccess: 'complete_success',
  Compiling: 'compiling',
  Preparing: 'preparing',
  Initialize: 'initialize'
});


export type SystemDeployStatusEnum = $Values<typeof SystemDeployStatusEnumValues>;

/** SystemDeployStatusResult */
export type SystemDeployStatusResult = {|
  status: SystemDeployStatusEnum,
  message?: ?$ElementType<Scalars, 'String'>,
|};

/** SystemEnvironmentBackupListResponse output */
export type SystemEnvironmentBackupListResponse = {|
  /** List items */
  items: Array<EnvironmentBackupItem>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export type SystemEnvironmentMember = {|
  email: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  avatar?: ?SystemEnvironmentMemberAvatar,
  permissions?: ?SystemEnvironmentMemberPermissionsList,
  roles?: ?SystemEnvironmentMemberRolesList,
|};

export type SystemEnvironmentMemberAvatar = {|
  id?: ?$ElementType<Scalars, 'String'>,
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemEnvironmentMemberFilter = {|
  email: $ElementType<Scalars, 'String'>,
|};

export type SystemEnvironmentMemberPermission = {|
  resourceType?: ?$ElementType<Scalars, 'String'>,
  resource?: ?$ElementType<Scalars, 'String'>,
  permission?: ?$ElementType<Scalars, 'JSON'>,
|};

export type SystemEnvironmentMemberPermissionsList = {|
  items?: ?Array<SystemEnvironmentMemberPermission>,
  count: $ElementType<Scalars, 'Int'>,
|};

export type SystemEnvironmentMemberRole = {|
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
|};

export type SystemEnvironmentMemberRoleIdFilterPredicate = {|
  equals?: ?$ElementType<Scalars, 'ID'>,
  not_equals?: ?$ElementType<Scalars, 'ID'>,
|};

export type SystemEnvironmentMemberRolesList = {|
  items?: ?Array<SystemEnvironmentMemberRole>,
  count: $ElementType<Scalars, 'Int'>,
|};

export type SystemEnvironmentMembersFilter = {|
  email: $ElementType<Scalars, 'String'>,
|};

export type SystemEnvironmentMembersListFilter = {|
  roleId?: ?SystemEnvironmentMemberRoleIdFilterPredicate,
  email?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
|};

/** SystemEnvironmentMembersListResponse output */
export type SystemEnvironmentMembersListResponse = {|
  /** List items */
  items: Array<SystemEnvironmentMember>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export type SystemEnvironmentMembersListSort = {|
  isOwner?: ?SortOrder,
  status?: ?SortOrder,
  email?: ?SortOrder,
  firstName?: ?SortOrder,
  lastName?: ?SortOrder,
|};

export type SystemEnvironmentMemberUpdateData = {|
  roles?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type SystemEnvironmentRoleBaseInfo = {|
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
|};

export type SystemEnvironmentRoleList = {|
  assignedRoles?: ?Array<?SystemEnvironmentRoleBaseInfo>,
  environmentId: $ElementType<Scalars, 'String'>,
  environmentName: $ElementType<Scalars, 'String'>,
  exists?: ?$ElementType<Scalars, 'Boolean'>,
  roles?: ?Array<?SystemEnvironmentRoleBaseInfo>,
|};

export type SystemEnvironmentSettings = {|
  deleteLock?: ?$ElementType<Scalars, 'Boolean'>,
  fileManagementProvider?: ?SystemFileManagerProviderTypeEnum,
  introspection?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** SystemEnvironmentsListResponse output */
export type SystemEnvironmentsListResponse = {|
  /** List items */
  items: Array<EnvironmentItem>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

/** Field Data Features */
export type SystemFieldDataFeatures = {|
  create: $ElementType<Scalars, 'Boolean'>,
  sort: $ElementType<Scalars, 'Boolean'>,
  update: $ElementType<Scalars, 'Boolean'>,
|};

/** Field Schema Features */
export type SystemFieldSchemaFeatures = {|
  delete: $ElementType<Scalars, 'Boolean'>,
  update: $ElementType<Scalars, 'Boolean'>,
|};

export const SystemFieldTypeValues = Object.freeze({
  Date: 'DATE',
  File: 'FILE',
  Geo: 'GEO',
  Id: 'ID',
  Json: 'JSON',
  MissingRelation: 'MISSING_RELATION',
  Number: 'NUMBER',
  OneWayRelation: 'ONE_WAY_RELATION',
  Relation: 'RELATION',
  Smart: 'SMART',
  Switch: 'SWITCH',
  Text: 'TEXT',
  Uuid: 'UUID'
});


/** Field types */
export type SystemFieldType = $Values<typeof SystemFieldTypeValues>;

/** Field Type Attributes */
export type SystemFieldTypeAttributes = SystemDateFieldTypeAttributes | SystemFileFieldTypeAttributes | SystemGeoFieldTypeAttributes | SystemMissingRelationFieldTypeAttributes | SystemNumberFieldTypeAttributes | SystemSmartFieldTypeAttributes | SystemSwitchFieldTypeAttributes | SystemTextFieldTypeAttributes | SystemUuidFieldTypeAttributes;

/** Field Type Attributes Input */
export type SystemFieldTypeAttributesInput = {|
  autoIncrement?: ?$ElementType<Scalars, 'Boolean'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  expiration?: ?$ElementType<Scalars, 'Int'>,
  fieldSize?: ?$ElementType<Scalars, 'Int'>,
  format?: ?$ElementType<Scalars, 'String'>,
  isBigInt?: ?$ElementType<Scalars, 'Boolean'>,
  listOptions?: ?Array<$ElementType<Scalars, 'String'>>,
  maxSize?: ?$ElementType<Scalars, 'Int'>,
  maxValue?: ?$ElementType<Scalars, 'Float'>,
  minValue?: ?$ElementType<Scalars, 'Float'>,
  precision?: ?$ElementType<Scalars, 'Int'>,
  srid?: ?$ElementType<Scalars, 'Int'>,
  typeRestrictions?: ?Array<$ElementType<Scalars, 'String'>>,
|};

export type SystemFileBaseInfo = {|
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

/** File Field Attributes */
export type SystemFileFieldTypeAttributes = {|
  expiration?: ?$ElementType<Scalars, 'Int'>,
  format: $ElementType<Scalars, 'String'>,
  maxSize?: ?$ElementType<Scalars, 'Int'>,
  showTitle?: ?$ElementType<Scalars, 'Boolean'>,
  showUrl?: ?$ElementType<Scalars, 'Boolean'>,
  typeRestrictions?: ?Array<$ElementType<Scalars, 'String'>>,
|};

export const SystemFileManagerProviderTypeEnumValues = Object.freeze({
  Aws: 'aws',
  Filestack: 'filestack',
  Uploadcare: 'uploadcare'
});


export type SystemFileManagerProviderTypeEnum = $Values<typeof SystemFileManagerProviderTypeEnumValues>;

export const SystemFileTypeFormatEnumValues = Object.freeze({
  File: 'FILE',
  Image: 'IMAGE'
});


/** File Type Format Enum */
export type SystemFileTypeFormatEnum = $Values<typeof SystemFileTypeFormatEnumValues>;

/** FileUploadByUrlInput */
export type SystemFileUploadByUrlInput = {|
  url: $ElementType<Scalars, 'String'>,
|};

/** FileUploadByUrlResponse */
export type SystemFileUploadByUrlResponse = {|
  fileId: $ElementType<Scalars, 'String'>,
  meta: $ElementType<Scalars, 'JSON'>,
|};

export type SystemFileUploadSignInfo = AwsSignInfoResponse | FileStackSignInfoResponse | UploadcareSignInfoResponse;

export type SystemFrontendPlanIdItem = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};

/** FunctionInfo */
export type SystemFunctionInfo = {|
  application?: ?SystemApplication,
  description?: ?$ElementType<Scalars, 'String'>,
  functionType: SystemFunctionType,
  name: $ElementType<Scalars, 'String'>,
|};

/** FunctionInfoFilter */
export type SystemFunctionInfoFilter = {|
  description?: ?$ElementType<Scalars, 'String'>,
  functionType?: ?SystemFunctionType,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export const SystemFunctionInfoOrderByValues = Object.freeze({
  DescriptionAsc: 'description_ASC',
  DescriptionDesc: 'description_DESC',
  FunctionTypeAsc: 'functionType_ASC',
  FunctionTypeDesc: 'functionType_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC'
});


/** FunctionInfoOrderBy */
export type SystemFunctionInfoOrderBy = $Values<typeof SystemFunctionInfoOrderByValues>;

/** SystemFunctionListResponse output */
export type SystemFunctionListResponse = {|
  /** List items */
  items: Array<SystemFunctionInfo>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

/** FunctionResolverInfo */
export type SystemFunctionResolverInfo = {|
  ...SystemFunctionInfo,
  ...{|
    application?: ?SystemApplication,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: SystemFunctionType,
    gqlType: $ElementType<Scalars, 'String'>,
    name: $ElementType<Scalars, 'String'>,
  |}
|};

/** FunctionTaskInfo */
export type SystemFunctionTaskInfo = {|
  ...SystemFunctionInfo,
  ...{|
    application?: ?SystemApplication,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: SystemFunctionType,
    name: $ElementType<Scalars, 'String'>,
    scheduleExpression?: ?$ElementType<Scalars, 'String'>,
  |}
|};

/** FunctionTriggerInfo */
export type SystemFunctionTriggerInfo = {|
  ...SystemFunctionInfo,
  ...{|
    application?: ?SystemApplication,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: SystemFunctionType,
    name: $ElementType<Scalars, 'String'>,
    operation: $ElementType<Scalars, 'String'>,
    tableName: $ElementType<Scalars, 'String'>,
    type: $ElementType<Scalars, 'String'>,
  |}
|};

export const SystemFunctionTypeValues = Object.freeze({
  Resolver: 'resolver',
  Schedule: 'schedule',
  Task: 'task',
  Trigger: 'trigger',
  Webhook: 'webhook'
});


/** FunctionType */
export type SystemFunctionType = $Values<typeof SystemFunctionTypeValues>;

/** FunctionWebhookInfo */
export type SystemFunctionWebhookInfo = {|
  ...SystemFunctionInfo,
  ...{|
    application?: ?SystemApplication,
    description?: ?$ElementType<Scalars, 'String'>,
    functionType: SystemFunctionType,
    httpMethod: $ElementType<Scalars, 'String'>,
    name: $ElementType<Scalars, 'String'>,
    workspaceFullPath: $ElementType<Scalars, 'String'>,
    workspaceRelativePath: $ElementType<Scalars, 'String'>,
  |}
|};

/** Diff Environment Input */
export type SystemGenerateEnvironmentOutput = {|
  url?: ?$ElementType<Scalars, 'String'>,
|};

/** Geo Field Attributes */
export type SystemGeoFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
  srid?: ?$ElementType<Scalars, 'Int'>,
|};

export type SystemInboxEventDetailsUnion = SystemInboxEventEnvironmentInvitationDetails | SystemInboxEventNotificationDetailsType | SystemInboxEventOrganizationInvitationDetails | SystemInboxEventWorkspaceTransferDetails;

export type SystemInboxEventEnvironmentInvitationDetails = {|
  environmentName?: ?$ElementType<Scalars, 'String'>,
  invitedBy?: ?SystemInboxEventInvitedBy,
  status?: ?SystemInboxEventStatusEnum,
  uuid?: ?$ElementType<Scalars, 'String'>,
  workspace?: ?SystemInboxEventWorkspace,
|};

export type SystemInboxEventInvitedBy = {|
  avatar?: ?GraphQlFileItemResponse,
  email?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemInboxEventItem = {|
  createdAt: $ElementType<Scalars, 'DateTime'>,
  details?: ?SystemInboxEventDetailsUnion,
  id: $ElementType<Scalars, 'ID'>,
  isCompleted?: ?$ElementType<Scalars, 'Boolean'>,
  type?: ?SystemInboxEventTypeEnum,
|};

export type SystemInboxEventNotificationDetailsType = {|
  details?: ?$ElementType<Scalars, 'JSON'>,
  kind?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemInboxEventOrganization = {|
  avatar?: ?GraphQlFileItemResponse,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemInboxEventOrganizationInvitationDetails = {|
  invitedBy?: ?SystemInboxEventInvitedBy,
  organization?: ?SystemInboxEventOrganization,
  status?: ?SystemInboxEventStatusEnum,
  uuid?: ?$ElementType<Scalars, 'String'>,
|};

/** SystemInboxEventsListResponse output */
export type SystemInboxEventsListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemInboxEventItem>,
|};

export const SystemInboxEventStatusEnumValues = Object.freeze({
  Accepted: 'accepted',
  Declined: 'declined',
  Sent: 'sent'
});


export type SystemInboxEventStatusEnum = $Values<typeof SystemInboxEventStatusEnumValues>;

export type SystemInboxEventTransferFinalizer = {|
  email: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemInboxEventTransferParticipant = {|
  avatar?: ?GraphQlFileItemResponse,
  identity: $ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type: SystemTransferParticipantType,
|};

export const SystemInboxEventTypeEnumValues = Object.freeze({
  EnvironmentInvitation: 'EnvironmentInvitation',
  Notification: 'Notification',
  OrganizationInvitation: 'OrganizationInvitation',
  WorkspaceTransfer: 'WorkspaceTransfer'
});


export type SystemInboxEventTypeEnum = $Values<typeof SystemInboxEventTypeEnumValues>;

export type SystemInboxEventWorkspace = {|
  apiHost?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?GraphQlFileItemResponse,
  id: $ElementType<Scalars, 'ID'>,
  kind?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemInboxEventWorkspaceTransferDetails = {|
  finalizer?: ?SystemInboxEventTransferFinalizer,
  from?: ?SystemInboxEventTransferParticipant,
  status?: ?SystemInboxEventStatusEnum,
  to?: ?SystemInboxEventTransferParticipant,
  uuid?: ?$ElementType<Scalars, 'String'>,
  workspace?: ?SystemInboxEventWorkspace,
|};

/** Table Create Index Input */
export type SystemIndexCreateInput = {|
  columns: Array<SystemTableIndexColumnInput>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  tableId: $ElementType<Scalars, 'ID'>,
  type: TableIndexType,
|};

/** Table Delete Index Input */
export type SystemIndexDeleteInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

/** Table Update Index Input */
export type SystemIndexUpdateInput = {|
  columns?: ?Array<SystemTableIndexColumnInput>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?TableIndexType,
|};

/** Invited By Name */
export type SystemInvitedByName = {|
  email?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  workspaceName?: ?$ElementType<Scalars, 'String'>,
|};

/** Invite members input */
export type SystemInviteMembersInput = {|
  recipients: Array<SystemInviteRecipientInput>,
|};

/** Invite recipient input */
export type SystemInviteRecipientInput = {|
  email: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  roles?: ?Array<$ElementType<Scalars, 'ID'>>,
|};

/** InvokeData */
export type SystemInvokeData = {|
  functionName: $ElementType<Scalars, 'String'>,
  inputArgs?: ?$ElementType<Scalars, 'String'>,
|};

/** InvokeFunctionResponse */
export type SystemInvokeFunctionResponse = {|
  responseData: $ElementType<Scalars, 'String'>,
|};

/** LogsListFiltered Response */
export type SystemLogsListFilteredResponse = {|
  items?: ?Array<?SystemCloudLogsEntry>,
  count?: ?$ElementType<Scalars, 'Int'>,
  nextToken?: ?$ElementType<Scalars, 'String'>,
|};

/**
 * Member - list of workspaces you are member of
 * Owner - list of workspaces you are owner of
 * OrganizationUser - list of organizations you are part of
 */
export type SystemMemberAccountDeleteDetails = {|
  member?: ?Array<?SystemOrganizationWorkspaceItem>,
  organizationUser?: ?Array<?SystemOrganizationBaseItem>,
  owner?: ?Array<?SystemOrganizationWorkspaceItem>,
|};

export type SystemMemberAccountDeleteResponse = {|
  details?: ?SystemMemberAccountDeleteDetails,
  success: $ElementType<Scalars, 'Boolean'>,
|};

export type SystemMemberAccountInfo = {|
  aboutMe?: ?$ElementType<Scalars, 'String'>,
  addressLine1?: ?$ElementType<Scalars, 'String'>,
  addressLine2?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?GraphQlFileItemResponse,
  city?: ?$ElementType<Scalars, 'String'>,
  companyName?: ?$ElementType<Scalars, 'String'>,
  country?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  email: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  githubUsername?: ?$ElementType<Scalars, 'String'>,
  isDeveloper?: ?$ElementType<Scalars, 'Boolean'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  learningMode?: ?$ElementType<Scalars, 'Boolean'>,
  linkedInUsername?: ?$ElementType<Scalars, 'String'>,
  projectDescription?: ?$ElementType<Scalars, 'String'>,
  role?: ?$ElementType<Scalars, 'String'>,
  state?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  twitterUsername?: ?$ElementType<Scalars, 'String'>,
  type?: ?SystemUserType,
  website?: ?$ElementType<Scalars, 'String'>,
  zipCode?: ?$ElementType<Scalars, 'String'>,
  isEmailVerified?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemMemberAccountUpsertDataInput = {|
  aboutMe?: ?$ElementType<Scalars, 'String'>,
  addressLine1?: ?$ElementType<Scalars, 'String'>,
  addressLine2?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?GraphQlCreateFileItemInput,
  city?: ?$ElementType<Scalars, 'String'>,
  companyName?: ?$ElementType<Scalars, 'String'>,
  country?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  githubUsername?: ?$ElementType<Scalars, 'String'>,
  isDeveloper?: ?$ElementType<Scalars, 'Boolean'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  learningMode?: ?$ElementType<Scalars, 'Boolean'>,
  linkedInUsername?: ?$ElementType<Scalars, 'String'>,
  projectDescription?: ?$ElementType<Scalars, 'String'>,
  role?: ?$ElementType<Scalars, 'String'>,
  state?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  twitterUsername?: ?$ElementType<Scalars, 'String'>,
  type?: ?SystemUserType,
  website?: ?$ElementType<Scalars, 'String'>,
  zipCode?: ?$ElementType<Scalars, 'String'>,
|};

/** Member Invitation */
export type SystemMemberInvitation = {|
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  acceptedOn?: ?$ElementType<Scalars, 'DateTime'>,
  apiHost?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  invitedBy?: ?SystemInvitedByName,
  isRegistered?: ?$ElementType<Scalars, 'Boolean'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  resentOn?: ?$ElementType<Scalars, 'DateTime'>,
|};

/** Member invitation accept input */
export type SystemMemberInvitationAcceptInput = {|
  accepted: $ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'String'>,
|};

/** Member Invitation Accept Response */
export type SystemMemberInvitationAcceptResponse = {|
  success: $ElementType<Scalars, 'Boolean'>,
|};

/** Cancel members invitations input */
export type SystemMemberInvitationCancelInput = {|
  email: $ElementType<Scalars, 'String'>,
|};

/** Resend member invitation input */
export type SystemMemberInvitationResendInput = {|
  email: $ElementType<Scalars, 'ID'>,
|};

export type SystemMemberInvitationsList = {|
  count?: ?$ElementType<Scalars, 'Int'>,
  items?: ?Array<?SystemMemberInvitation>,
|};

/** MemberPaymentDetailsUpdateMutationInput */
export type SystemMemberPaymentDetailsUpdateMutationInput = {|
  cardToken?: ?$ElementType<Scalars, 'String'>,
|};

/** MissingRelation Field Attributes */
export type SystemMissingRelationFieldTypeAttributes = {|
  missingTable: $ElementType<Scalars, 'String'>,
|};

export type SystemMutation = {|
  applicationDelete?: ?SuccessResponse,
  applicationInstall?: ?SystemApplication,
  applicationUpdate?: ?SystemApplication,
  billingContextCacheEvict?: ?SystemCacheEvictResponse,
  billingDetailsUpdate?: ?SystemBillingDetailsResponse,
  billingPlanUpdate?: ?SystemBillingCurrentPlanResponse,
  ciCommit?: ?AsyncSession,
  ciInstall?: ?SuccessResponse,
  deploy?: ?$ElementType<Scalars, 'Boolean'>,
  environmentBackup?: ?AsyncSession,
  environmentBranch?: ?AsyncSession,
  environmentDelete?: ?SuccessResponse,
  environmentDeleteAsync?: ?AsyncSession,
  environmentMemberDelete: SuccessResponse,
  environmentMemberUpdate?: ?SystemEnvironmentMember,
  environmentRestore?: ?AsyncSession,
  environmentSetup?: ?SuccessResponse,
  fieldCreate: SystemTableField,
  fieldDelete: SuccessResponse,
  fieldUpdate: SystemTableField,
  fieldUpdatePosition: SuccessResponse,
  fileUploadByUrl: SystemFileUploadByUrlResponse,
  indexCreate: SystemTableIndex,
  indexDelete?: ?SuccessResponse,
  indexUpdate: SystemTableIndex,
  inviteMembers: Array<?SystemTeamInvitationDetails>,
  invoke?: ?SystemInvokeFunctionResponse,
  memberAccountDelete?: ?SystemMemberAccountDeleteResponse,
  memberAccountUpsert?: ?SystemMemberAccountInfo,
  memberInvitationAccept: SystemMemberInvitationAcceptResponse,
  memberInvitationCancel?: ?SuccessResponse,
  memberInvitationResend?: ?SuccessResponse,
  memberPaymentDetailsUpdate?: ?SystemPaymentDetailsResponse,
  notificationUpdate?: ?SuccessResponse,
  organizationInviteUser?: ?OrganizationUserInvitationResponse,
  organizationInviteUserAccept?: ?SuccessResponse,
  organizationInviteUserCancel?: ?SuccessResponse,
  organizationPaymentDetailsUpdate?: ?SystemPaymentDetailsResponse,
  organizationUpdate?: ?SystemOrganizationItem,
  organizationUserRemove?: ?SuccessResponse,
  organizationUserUpdate?: ?SystemOrganizationUserInfo,
  organizationWorkspaceUserRemove?: ?SuccessResponse,
  organizationWorkspaceUserShare?: ?SuccessResponse,
  prepareDeploy: SystemDeployDataResponse,
  rolePermissionsCacheEvict?: ?SystemCacheEvictResponse,
  tableCreate: SystemTable,
  tableDelete: SuccessResponse,
  tableUpdate: SystemTable,
  viewCreate: SystemTable,
  viewUpdate: SystemTable,
  workspaceCreate?: ?SystemWorkspaceCreateResponse,
  workspaceCreateAsync?: ?SystemWorkspaceCreateResponse,
  workspaceDelete?: ?SuccessResponse,
  workspaceLeave?: ?SuccessResponse,
  workspacePaymentDetailsUpdate?: ?SystemPaymentDetailsResponse,
  workspaceTransferAbort?: ?SuccessResponse,
  workspaceTransferAccept?: ?SuccessResponse,
  workspaceTransferNotificationSend?: ?SuccessResponse,
  workspaceTransferRegister?: ?SuccessWithMessageResponse,
  workspaceUpdate?: ?SystemWorkspaceUpdateResponse,
|};


export type SystemMutationApplicationDeleteArgs = {|
  data: SystemApplicationDeleteMutationInput,
|};


export type SystemMutationApplicationInstallArgs = {|
  data: SystemApplicationInstallInput,
|};


export type SystemMutationApplicationUpdateArgs = {|
  data: SystemApplicationUpdateInput,
|};


export type SystemMutationBillingContextCacheEvictArgs = {|
  keys: Array<$ElementType<Scalars, 'String'>>,
|};


export type SystemMutationBillingDetailsUpdateArgs = {|
  data: SystemBillingDetailsUpdateMutationInput,
|};


export type SystemMutationBillingPlanUpdateArgs = {|
  data: SystemBillingPlanUpdateMutationInput,
|};


export type SystemMutationCiCommitArgs = {|
  mode?: ?SystemCiCommitMode,
  build?: ?$ElementType<Scalars, 'String'>,
  migrationNames?: ?Array<?$ElementType<Scalars, 'String'>>,
|};


export type SystemMutationDeployArgs = {|
  data?: ?SystemDeployingBuildInput,
|};


export type SystemMutationEnvironmentBackupArgs = {|
  environmentName: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationEnvironmentBranchArgs = {|
  name: $ElementType<Scalars, 'String'>,
  mode?: ?SystemBranchEnvironmentMode,
|};


export type SystemMutationEnvironmentDeleteArgs = {|
  environmentName: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationEnvironmentDeleteAsyncArgs = {|
  environmentName: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationEnvironmentMemberDeleteArgs = {|
  filter?: ?SystemEnvironmentMembersFilter,
|};


export type SystemMutationEnvironmentMemberUpdateArgs = {|
  filter?: ?SystemEnvironmentMembersFilter,
  data?: ?SystemEnvironmentMemberUpdateData,
|};


export type SystemMutationEnvironmentRestoreArgs = {|
  environmentName: $ElementType<Scalars, 'String'>,
  backup: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationEnvironmentSetupArgs = {|
  data?: ?EnvironmentSetupInput,
|};


export type SystemMutationFieldCreateArgs = {|
  data: SystemTableFieldCreateInput,
|};


export type SystemMutationFieldDeleteArgs = {|
  data: SystemTableFieldDeleteInput,
|};


export type SystemMutationFieldUpdateArgs = {|
  data: SystemTableFieldUpdateInput,
|};


export type SystemMutationFieldUpdatePositionArgs = {|
  data: SystemTableFieldPositionUpdateInput,
|};


export type SystemMutationFileUploadByUrlArgs = {|
  data: SystemFileUploadByUrlInput,
|};


export type SystemMutationIndexCreateArgs = {|
  data: SystemIndexCreateInput,
|};


export type SystemMutationIndexDeleteArgs = {|
  data: SystemIndexDeleteInput,
|};


export type SystemMutationIndexUpdateArgs = {|
  data: SystemIndexUpdateInput,
|};


export type SystemMutationInviteMembersArgs = {|
  data: SystemInviteMembersInput,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type SystemMutationInvokeArgs = {|
  data?: ?SystemInvokeData,
|};


export type SystemMutationMemberAccountUpsertArgs = {|
  data?: ?SystemMemberAccountUpsertDataInput,
|};


export type SystemMutationMemberInvitationAcceptArgs = {|
  data: SystemMemberInvitationAcceptInput,
|};


export type SystemMutationMemberInvitationCancelArgs = {|
  data: SystemMemberInvitationCancelInput,
|};


export type SystemMutationMemberInvitationResendArgs = {|
  data: SystemMemberInvitationResendInput,
|};


export type SystemMutationMemberPaymentDetailsUpdateArgs = {|
  data: SystemMemberPaymentDetailsUpdateMutationInput,
|};


export type SystemMutationNotificationUpdateArgs = {|
  id: $ElementType<Scalars, 'String'>,
  status: NotificationStatusType,
|};


export type SystemMutationOrganizationInviteUserArgs = {|
  email: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  organizationId: $ElementType<Scalars, 'ID'>,
  role: $ElementType<Scalars, 'String'>,
  workspaceRoles?: ?Array<?SystemOrganizationWorkspaceWithEnvironmentRolesInput>,
|};


export type SystemMutationOrganizationInviteUserAcceptArgs = {|
  invitationId: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationOrganizationInviteUserCancelArgs = {|
  invitationId: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationOrganizationPaymentDetailsUpdateArgs = {|
  data: SystemOrganizationPaymentDetailsUpdateMutationInput,
|};


export type SystemMutationOrganizationUpdateArgs = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  image?: ?GraphQlCreateFileItemInput,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?SystemOrganizationTypeEnum,
|};


export type SystemMutationOrganizationUserRemoveArgs = {|
  email: $ElementType<Scalars, 'String'>,
  organizationId: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationOrganizationUserUpdateArgs = {|
  email: $ElementType<Scalars, 'String'>,
  organizationId: $ElementType<Scalars, 'ID'>,
  role: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationOrganizationWorkspaceUserRemoveArgs = {|
  email: $ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'String'>,
|};


export type SystemMutationOrganizationWorkspaceUserShareArgs = {|
  email: $ElementType<Scalars, 'String'>,
  environmentRoles?: ?Array<?SystemOrganizationWorkspaceEnvironmentRolesInput>,
  workspaceId: $ElementType<Scalars, 'ID'>,
|};


export type SystemMutationRolePermissionsCacheEvictArgs = {|
  keys: Array<$ElementType<Scalars, 'String'>>,
|};


export type SystemMutationTableCreateArgs = {|
  data: SystemTableCreateInput,
|};


export type SystemMutationTableDeleteArgs = {|
  data: SystemTableDeleteInput,
|};


export type SystemMutationTableUpdateArgs = {|
  data: SystemTableUpdateInput,
|};


export type SystemMutationViewCreateArgs = {|
  data: SystemViewCreateInput,
|};


export type SystemMutationViewUpdateArgs = {|
  data: SystemViewUpdateInput,
|};


export type SystemMutationWorkspaceCreateArgs = {|
  data: SystemWorkspaceCreateMutationInput,
|};


export type SystemMutationWorkspaceCreateAsyncArgs = {|
  data: SystemWorkspaceCreateMutationInput,
|};


export type SystemMutationWorkspaceDeleteArgs = {|
  data: SystemWorkspaceDeleteMutationInput,
|};


export type SystemMutationWorkspaceLeaveArgs = {|
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type SystemMutationWorkspacePaymentDetailsUpdateArgs = {|
  data?: ?SystemWorkspacePaymentDetailsUpdateMutationInput,
|};


export type SystemMutationWorkspaceTransferAbortArgs = {|
  workspaceId: $ElementType<Scalars, 'ID'>,
|};


export type SystemMutationWorkspaceTransferAcceptArgs = {|
  cardToken?: ?$ElementType<Scalars, 'String'>,
  planId: $ElementType<Scalars, 'ID'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
  workspaceName?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemMutationWorkspaceTransferNotificationSendArgs = {|
  workspaceId: $ElementType<Scalars, 'ID'>,
|};


export type SystemMutationWorkspaceTransferRegisterArgs = {|
  to: $ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
|};


export type SystemMutationWorkspaceUpdateArgs = {|
  data: SystemWorkspaceUpdateMutationInput,
|};

/** Number Field Attributes */
export type SystemNumberFieldTypeAttributes = {|
  autoIncrement?: ?$ElementType<Scalars, 'Boolean'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  format: $ElementType<Scalars, 'String'>,
  isBigInt?: ?$ElementType<Scalars, 'Boolean'>,
  maxValue?: ?$ElementType<Scalars, 'Float'>,
  minValue?: ?$ElementType<Scalars, 'Float'>,
  precision?: ?$ElementType<Scalars, 'Int'>,
|};

export const SystemNumberTypeFormatEnumValues = Object.freeze({
  Currency: 'CURRENCY',
  Fraction: 'FRACTION',
  Number: 'NUMBER',
  Percentage: 'PERCENTAGE',
  Scientific: 'SCIENTIFIC'
});


/** Number Type Format Enum */
export type SystemNumberTypeFormatEnum = $Values<typeof SystemNumberTypeFormatEnumValues>;

export type SystemOrganizationBaseItem = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?SystemFileBaseInfo,
  name: $ElementType<Scalars, 'String'>,
  type?: ?SystemOrganizationTypeEnum,
|};

export type SystemOrganizationInvitation = {|
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  email: $ElementType<Scalars, 'String'>,
  emailFrom: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  firstNameFrom?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  lastNameFrom?: ?$ElementType<Scalars, 'String'>,
  organization: SystemOrganizationBaseItem,
  role: $ElementType<Scalars, 'String'>,
|};

export type SystemOrganizationItem = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?SystemOrganizationItemImage,
  name: $ElementType<Scalars, 'String'>,
  type?: ?SystemOrganizationTypeEnum,
  users?: ?Array<?SystemOrganizationUserInfo>,
|};

export type SystemOrganizationItemImage = {|
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

/** OrganizationPaymentDetailsUpdateMutationInput */
export type SystemOrganizationPaymentDetailsUpdateMutationInput = {|
  cardToken: $ElementType<Scalars, 'String'>,
  organizationId: $ElementType<Scalars, 'ID'>,
|};

/** SystemOrganizationsListAllResponse output */
export type SystemOrganizationsListAllResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemOrganizationItem>,
|};

/** SystemOrganizationsListResponse output */
export type SystemOrganizationsListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemOrganizationItem>,
|};

export const SystemOrganizationTypeEnumValues = Object.freeze({
  Agency: 'agency',
  Community: 'community',
  Company: 'company',
  Individual: 'individual',
});


export type SystemOrganizationTypeEnum = $Values<typeof SystemOrganizationTypeEnumValues>;

export type SystemOrganizationUserInfo = {|
  avatar?: ?GraphQlFileItemResponse,
  email: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  workspaces?: ?Array<?SystemOrganizationWorkspaceItem>,
|};

/** environmentId to add to with roles (array of ids). */
export type SystemOrganizationWorkspaceEnvironmentRolesInput = {|
  environmentId: $ElementType<Scalars, 'String'>,
  roles?: ?Array<?$ElementType<Scalars, 'ID'>>,
|};

export type SystemOrganizationWorkspaceItem = {|
  apiHost?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?SystemFileBaseInfo,
  kind: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  organization?: ?SystemOrganizationBaseItem,
|};

export type SystemOrganizationWorkspaceWithEnvironmentRolesInput = {|
  environmentRoles?: ?Array<?SystemOrganizationWorkspaceEnvironmentRolesInput>,
  workspaceId: $ElementType<Scalars, 'ID'>,
|};

export const SystemPaymentDetailsOriginValues = Object.freeze({
  Member: 'member',
  Organization: 'organization',
  Workspace: 'workspace'
});


export type SystemPaymentDetailsOrigin = $Values<typeof SystemPaymentDetailsOriginValues>;

export type SystemPaymentDetailsResponse = {|
  brand?: ?$ElementType<Scalars, 'String'>,
  expMonth?: ?$ElementType<Scalars, 'Int'>,
  expYear?: ?$ElementType<Scalars, 'Int'>,
  last4?: ?$ElementType<Scalars, 'String'>,
  origin: SystemPaymentDetailsOrigin,
|};

/** Diff Environment Input */
export type SystemPlanEnvironmentOutput = {|
  url?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemQuery = {|
  application?: ?SystemApplication,
  applicationsList?: ?SystemApplicationListResponse,
  asyncSessionStatus?: ?SystemAsyncSessionStatusResponse,
  billingCurrentPlan?: ?SystemBillingCurrentPlanResponse,
  billingDetails?: ?SystemBillingDetailsResponse,
  billingInvoicesList: SystemBillingInvoicesListResponse,
  billingMetricUsageQuotasList: SystemBillingMetricUsageQuotasListResponse,
  billingMetricUsagesList: SystemBillingMetricUsagesListResponse,
  ciGenerate?: ?SystemGenerateEnvironmentOutput,
  ciGenerateAsync?: ?AsyncSession,
  ciPlan?: ?SystemPlanEnvironmentOutput,
  ciStatus?: ?SystemCiStatusOutput,
  deployStatus: SystemDeployStatusResult,
  environmentBackupsList?: ?SystemEnvironmentBackupListResponse,
  environmentMember?: ?SystemEnvironmentMember,
  environmentMembersList?: ?SystemEnvironmentMembersListResponse,
  environmentSettings?: ?SystemEnvironmentSettings,
  environmentsList?: ?SystemEnvironmentsListResponse,
  fileUploadSignInfo?: ?SystemFileUploadSignInfo,
  functionsList?: ?SystemFunctionListResponse,
  getEnvironmentRoles?: ?Array<?SystemEnvironmentRoleList>,
  getFrontendPlanId?: ?SystemFrontendPlanIdItem,
  inboxEventsList?: ?SystemInboxEventsListResponse,
  introspection?: ?IntrospectionQueryResponse,
  logs?: ?Array<?$ElementType<Scalars, 'String'>>,
  logsList?: ?Array<?SystemCloudLogsEntry>,
  logsListFiltered?: ?SystemLogsListFilteredResponse,
  memberAccount?: ?SystemMemberAccountInfo,
  memberBillingHistory: SystemBillingInvoicesListResponse,
  memberInvitation?: ?SystemMemberInvitation,
  memberInvitationsList?: ?SystemMemberInvitationsList,
  memberPaymentDetails?: ?SystemPaymentDetailsResponse,
  organizationBillingHistory: SystemBillingInvoicesListResponse,
  organizationById?: ?SystemOrganizationItem,
  organizationInvitationById?: ?SystemOrganizationInvitation,
  organizationPaymentDetails?: ?SystemPaymentDetailsResponse,
  organizationsListAll?: ?SystemOrganizationsListAllResponse,
  organizationsListByUser?: ?SystemOrganizationsListResponse,
  table?: ?SystemTable,
  tableField?: ?SystemTableField,
  tablesList: SystemTableListResponse,
  userBillingConfiguration: SystemUserBillingConfigurationResponse,
  userInvitationsList?: ?SystemUserInvitationList,
  viewDryRun?: ?SystemViewDryRunOutput,
  workspaceBillingHistory: SystemBillingInvoicesListResponse,
  workspacePaymentDetails?: ?SystemPaymentDetailsResponse,
  workspaceTransferInfo?: ?SystemWorkspaceTransferItem,
  workspacesFrontendList?: ?SystemWorkspaceListResponse,
  workspacesList?: ?SystemWorkspaceListResponse,
|};


export type SystemQueryApplicationArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryAsyncSessionStatusArgs = {|
  sessionId: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryBillingCurrentPlanArgs = {|
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};


export type SystemQueryBillingInvoicesListArgs = {|
  after?: ?$ElementType<Scalars, 'ID'>,
  before?: ?$ElementType<Scalars, 'ID'>,
  by?: ?SystemBillingInvoicesListFilterType,
  limit?: ?$ElementType<Scalars, 'Int'>,
|};


export type SystemQueryBillingMetricUsageQuotasListArgs = {|
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};


export type SystemQueryBillingMetricUsagesListArgs = {|
  filter?: ?SystemBillingMetricUsagesListFilter,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};


export type SystemQueryCiGenerateArgs = {|
  tables?: ?Array<$ElementType<Scalars, 'String'>>,
  sourceEnvironmentId?: ?$ElementType<Scalars, 'String'>,
  targetEnvironmentId?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryCiGenerateAsyncArgs = {|
  tables?: ?Array<$ElementType<Scalars, 'String'>>,
  sourceEnvironmentId?: ?$ElementType<Scalars, 'String'>,
  targetEnvironmentId?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryCiPlanArgs = {|
  tables?: ?Array<$ElementType<Scalars, 'String'>>,
  sourceEnvironmentId?: ?$ElementType<Scalars, 'String'>,
  targetEnvironmentId?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryDeployStatusArgs = {|
  buildName: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryEnvironmentBackupsListArgs = {|
  environmentName?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryEnvironmentMemberArgs = {|
  filter?: ?SystemEnvironmentMemberFilter,
|};


export type SystemQueryEnvironmentMembersListArgs = {|
  filter?: ?SystemEnvironmentMembersListFilter,
  sort?: ?Array<SystemEnvironmentMembersListSort>,
  first?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};


export type SystemQueryFunctionsListArgs = {|
  applicationId?: ?$ElementType<Scalars, 'String'>,
  filter?: ?SystemFunctionInfoFilter,
  orderBy?: ?Array<?SystemFunctionInfoOrderBy>,
|};


export type SystemQueryGetEnvironmentRolesArgs = {|
  email?: ?$ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryInboxEventsListArgs = {|
  filter?: ?InboxEventsListFilter,
  first?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};


export type SystemQueryLogsArgs = {|
  functionName: $ElementType<Scalars, 'String'>,
  applicationId?: ?$ElementType<Scalars, 'String'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  startTime?: ?$ElementType<Scalars, 'DateTime'>,
  endTime?: ?$ElementType<Scalars, 'DateTime'>,
|};


export type SystemQueryLogsListArgs = {|
  functionName: $ElementType<Scalars, 'String'>,
  applicationId?: ?$ElementType<Scalars, 'String'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  startTime?: ?$ElementType<Scalars, 'DateTime'>,
  endTime?: ?$ElementType<Scalars, 'DateTime'>,
|};


export type SystemQueryLogsListFilteredArgs = {|
  startTime: $ElementType<Scalars, 'DateTime'>,
  endTime?: ?$ElementType<Scalars, 'DateTime'>,
  filter?: ?$ElementType<Scalars, 'String'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  startToken?: ?$ElementType<Scalars, 'String'>,
  resource?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryMemberBillingHistoryArgs = {|
  first?: ?$ElementType<Scalars, 'Int'>,
  hideOrganizationInvoices?: ?$ElementType<Scalars, 'Boolean'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};


export type SystemQueryMemberInvitationArgs = {|
  id: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryOrganizationBillingHistoryArgs = {|
  first?: ?$ElementType<Scalars, 'Int'>,
  hideWorkspaceInvoices?: ?$ElementType<Scalars, 'Boolean'>,
  organizationId: $ElementType<Scalars, 'ID'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};


export type SystemQueryOrganizationByIdArgs = {|
  organizationId: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryOrganizationInvitationByIdArgs = {|
  invitationId: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryOrganizationPaymentDetailsArgs = {|
  organizationId: $ElementType<Scalars, 'ID'>,
|};


export type SystemQueryTableArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryTableFieldArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};


export type SystemQueryTablesListArgs = {|
  filter?: ?SystemTableListFilter,
|};


export type SystemQueryUserBillingConfigurationArgs = {|
  kind?: ?$ElementType<Scalars, 'String'>,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  region?: ?$ElementType<Scalars, 'String'>,
|};


export type SystemQueryViewDryRunArgs = {|
  sql: $ElementType<Scalars, 'String'>,
|};


export type SystemQueryWorkspaceBillingHistoryArgs = {|
  first?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};


export type SystemQueryWorkspaceTransferInfoArgs = {|
  workspaceId: $ElementType<Scalars, 'ID'>,
|};

/** Relation */
export type SystemRelation = {|
  refField?: ?SystemTableField,
  refFieldDisplayName?: ?$ElementType<Scalars, 'String'>,
  refFieldIsList?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldIsRequired?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldName?: ?$ElementType<Scalars, 'String'>,
  refTable: SystemTable,
  relationFieldName?: ?$ElementType<Scalars, 'String'>,
  relationTableName?: ?$ElementType<Scalars, 'String'>,
|};

/** Relation Create Input */
export type SystemRelationCreateInput = {|
  refFieldDisplayName?: ?$ElementType<Scalars, 'String'>,
  refFieldIsList: $ElementType<Scalars, 'Boolean'>,
  refFieldIsRequired: $ElementType<Scalars, 'Boolean'>,
  refFieldName?: ?$ElementType<Scalars, 'String'>,
  refTableId: $ElementType<Scalars, 'ID'>,
|};

/** Relation Update Input */
export type SystemRelationUpdateInput = {|
  refFieldDisplayName?: ?$ElementType<Scalars, 'String'>,
  refFieldIsList?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldIsRequired?: ?$ElementType<Scalars, 'Boolean'>,
  refFieldName?: ?$ElementType<Scalars, 'String'>,
  refTableId?: ?$ElementType<Scalars, 'ID'>,
|};

/** Schema Origin */
export type SystemSchemaOrigin = {|
  provider?: ?$ElementType<Scalars, 'String'>,
  type: SystemSchemaOriginType,
|};

export const SystemSchemaOriginTypeValues = Object.freeze({
  Local: 'LOCAL',
  Remote: 'REMOTE',
  View: 'VIEW'
});


/** Schema Origin Type Enum */
export type SystemSchemaOriginType = $Values<typeof SystemSchemaOriginTypeValues>;

/** Smart Field Attributes */
export type SystemSmartFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
  innerFields?: ?Array<?SystemCustomTableField>,
|};

export const SystemSmartTypeFormatEnumValues = Object.freeze({
  Address: 'ADDRESS',
  Phone: 'PHONE'
});


/** Smart Type Format Enum */
export type SystemSmartTypeFormatEnum = $Values<typeof SystemSmartTypeFormatEnumValues>;

/** Switch Field Attributes */
export type SystemSwitchFieldTypeAttributes = {|
  format: $ElementType<Scalars, 'String'>,
  listOptions?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export const SystemSwitchTypeFormatEnumValues = Object.freeze({
  ActiveInactive: 'ACTIVE_INACTIVE',
  Custom: 'CUSTOM',
  HighLow: 'HIGH_LOW',
  OnOff: 'ON_OFF',
  TrueFalse: 'TRUE_FALSE',
  YesNo: 'YES_NO'
});


/** Switch Type Format Enum */
export type SystemSwitchTypeFormatEnum = $Values<typeof SystemSwitchTypeFormatEnumValues>;

/** Table */
export type SystemTable = {|
  application?: ?SystemApplication,
  attributes?: ?SystemTableAttributes,
  dataFeatures: SystemTableDataFeatures,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  fields?: ?Array<SystemTableField>,
  fieldsForIndexing?: ?Array<?SystemTableField>,
  id: $ElementType<Scalars, 'ID'>,
  indexes?: ?Array<SystemTableIndex>,
  isSystem: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  origin: SystemSchemaOrigin,
  schemaFeatures: SystemTableSchemaFeatures,
|};

/** Table Attributes */
export type SystemTableAttributes = SystemViewAttributes;

/** Table Create Input */
export type SystemTableCreateInput = {|
  applicationId?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
|};

/** Table Data Features */
export type SystemTableDataFeatures = {|
  create: $ElementType<Scalars, 'Boolean'>,
  delete: $ElementType<Scalars, 'Boolean'>,
  update: $ElementType<Scalars, 'Boolean'>,
|};

/** Table Delete Input */
export type SystemTableDeleteInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

/** TableField */
export type SystemTableField = {|
  computedMode?: ?SystemComputedFieldMode,
  dataFeatures: SystemFieldDataFeatures,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType: SystemFieldType,
  fieldTypeAttributes?: ?SystemFieldTypeAttributes,
  id: $ElementType<Scalars, 'ID'>,
  isList: $ElementType<Scalars, 'Boolean'>,
  isMeta: $ElementType<Scalars, 'Boolean'>,
  isRequired: $ElementType<Scalars, 'Boolean'>,
  isSystem: $ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  origin: SystemSchemaOrigin,
  relation?: ?SystemRelation,
  schemaFeatures: SystemFieldSchemaFeatures,
  table: SystemTable,
|};

/** Table Field Create Input */
export type SystemTableFieldCreateInput = {|
  computedMode?: ?SystemComputedFieldMode,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType: SystemFieldType,
  fieldTypeAttributes?: ?SystemFieldTypeAttributesInput,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  initialValue?: ?$ElementType<Scalars, 'String'>,
  isList: $ElementType<Scalars, 'Boolean'>,
  isRequired: $ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  position?: ?$ElementType<Scalars, 'Int'>,
  relation?: ?SystemRelationCreateInput,
  tableId: $ElementType<Scalars, 'ID'>,
|};

/** Table Field Delete Input */
export type SystemTableFieldDeleteInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

/** Table Field Position Update Input */
export type SystemTableFieldPositionUpdateInput = {|
  id: $ElementType<Scalars, 'ID'>,
  newPosition: $ElementType<Scalars, 'Int'>,
|};

/** Table Field Update Input */
export type SystemTableFieldUpdateInput = {|
  computedMode?: ?SystemComputedFieldMode,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType?: ?SystemFieldType,
  fieldTypeAttributes?: ?SystemFieldTypeAttributesInput,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  initialValue?: ?$ElementType<Scalars, 'String'>,
  isList?: ?$ElementType<Scalars, 'Boolean'>,
  isRequired?: ?$ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  position?: ?$ElementType<Scalars, 'Int'>,
  relation?: ?SystemRelationUpdateInput,
|};

/** Table Index */
export type SystemTableIndex = {|
  columns?: ?Array<SystemTableIndexColumn>,
  id: $ElementType<Scalars, 'ID'>,
  isSystem: $ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  table: SystemTable,
  type: $ElementType<Scalars, 'String'>,
|};

/** Table Index Column */
export type SystemTableIndexColumn = {|
  name: $ElementType<Scalars, 'String'>,
|};

/** Table Index Column Input */
export type SystemTableIndexColumnInput = {|
  name: $ElementType<Scalars, 'String'>,
|};

/** Table List Application Filter */
export type SystemTableListApplicationFilter = {|
  id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** Table List Filter */
export type SystemTableListFilter = {|
  applications?: ?Array<?SystemTableListApplicationFilter>,
  onlyUserTables?: ?$ElementType<Scalars, 'Boolean'>,
  tableNames?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

/** Table List Response */
export type SystemTableListResponse = {|
  count?: ?$ElementType<Scalars, 'Int'>,
  items?: ?Array<?SystemTable>,
|};

/** Table Schema Create Features */
export type SystemTableSchemaCreateFeatures = {|
  DATE: $ElementType<Scalars, 'Boolean'>,
  FILE: $ElementType<Scalars, 'Boolean'>,
  GEO: $ElementType<Scalars, 'Boolean'>,
  ID: $ElementType<Scalars, 'Boolean'>,
  JSON: $ElementType<Scalars, 'Boolean'>,
  MISSING_RELATION: $ElementType<Scalars, 'Boolean'>,
  NUMBER: $ElementType<Scalars, 'Boolean'>,
  ONE_WAY_RELATION: $ElementType<Scalars, 'Boolean'>,
  RELATION: $ElementType<Scalars, 'Boolean'>,
  SMART: $ElementType<Scalars, 'Boolean'>,
  SWITCH: $ElementType<Scalars, 'Boolean'>,
  TEXT: $ElementType<Scalars, 'Boolean'>,
  UUID: $ElementType<Scalars, 'Boolean'>,
|};

/** Table Schema Features */
export type SystemTableSchemaFeatures = {|
  computedFields: $ElementType<Scalars, 'Boolean'>,
  create: SystemTableSchemaCreateFeatures,
  update?: ?SystemTableSchemaMetaFieldFeatures,
|};

/** Table Schema Meta Field Features */
export type SystemTableSchemaMetaFieldFeatures = {|
  displayName: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'Boolean'>,
|};

/** Table Update Input */
export type SystemTableUpdateInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** Team Invitation Details */
export type SystemTeamInvitationDetails = {|
  id?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  isRegistered?: ?$ElementType<Scalars, 'Boolean'>,
  resentOn?: ?$ElementType<Scalars, 'DateTime'>,
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  acceptedOn?: ?$ElementType<Scalars, 'DateTime'>,
  apiHost?: ?$ElementType<Scalars, 'String'>,
  invitedBy?: ?SystemInvitedByName,
|};

/** Text Field Attributes */
export type SystemTextFieldTypeAttributes = {|
  fieldSize?: ?$ElementType<Scalars, 'Int'>,
  format: $ElementType<Scalars, 'String'>,
|};

export const SystemTextTypeFormatEnumValues = Object.freeze({
  Ein: 'EIN',
  Email: 'EMAIL',
  Html: 'HTML',
  Markdown: 'MARKDOWN',
  Name: 'NAME',
  Unformatted: 'UNFORMATTED'
});


/** Text Type Format Enum */
export type SystemTextTypeFormatEnum = $Values<typeof SystemTextTypeFormatEnumValues>;

export type SystemTransferParticipant = {|
  identity: $ElementType<Scalars, 'String'>,
  image?: ?GraphQlFileItemResponse,
  name: $ElementType<Scalars, 'String'>,
  type: SystemTransferParticipantType,
|};

export const SystemTransferParticipantTypeValues = Object.freeze({
  Account: 'account',
  Organization: 'organization'
});


export type SystemTransferParticipantType = $Values<typeof SystemTransferParticipantTypeValues>;

export type SystemUserBillingConfigurationResponse = {|
  availablePlans: Array<SystemBillingPlanBaseInfo>,
  isCancelSubscriptionAvailable: $ElementType<Scalars, 'Boolean'>,
  isFreePlanAvailable: $ElementType<Scalars, 'Boolean'>,
|};

/** User Invitation Details */
export type SystemUserInvitationDetails = {|
  id?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  isRegistered?: ?$ElementType<Scalars, 'Boolean'>,
  resentOn?: ?$ElementType<Scalars, 'DateTime'>,
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  acceptedOn?: ?$ElementType<Scalars, 'DateTime'>,
  apiHost?: ?$ElementType<Scalars, 'String'>,
  invitedBy?: ?SystemInvitedByName,
|};

/** User Invitation List */
export type SystemUserInvitationList = {|
  items?: ?Array<?SystemUserInvitationDetails>,
  count?: ?$ElementType<Scalars, 'Int'>,
|};

export const SystemUserTypeValues = Object.freeze({
  Agency: 'Agency',
  MyOwn: 'MyOwn',
  Team: 'Team'
});


/** User Type */
export type SystemUserType = $Values<typeof SystemUserTypeValues>;

/** UUID Field Attributes */
export type SystemUuidFieldTypeAttributes = {|
  fieldSize?: ?$ElementType<Scalars, 'Int'>,
|};

/** View Attributes */
export type SystemViewAttributes = {|
  query?: ?$ElementType<Scalars, 'String'>,
|};

/** View Create Input */
export type SystemViewCreateInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  query: $ElementType<Scalars, 'String'>,
|};

/** Dry Run Response for previewing SQL Views */
export type SystemViewDryRunOutput = {|
  count?: ?$ElementType<Scalars, 'Int'>,
  timeMs?: ?$ElementType<Scalars, 'Int'>,
  items?: ?$ElementType<Scalars, 'JSON'>,
|};

/** View Update Input */
export type SystemViewUpdateInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  query?: ?$ElementType<Scalars, 'String'>,
|};

/** WorkspaceCreateMutationInput */
export type SystemWorkspaceCreateMutationInput = {|
  billingPlanId?: ?$ElementType<Scalars, 'ID'>,
  cardToken?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  image?: ?GraphQlCreateFileItemInput,
  kind?: ?SystemWorkspaceKind,
  name: $ElementType<Scalars, 'String'>,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
|};

export type SystemWorkspaceCreateResponse = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  kind?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** WorkspaceDeleteMutationInput */
export type SystemWorkspaceDeleteMutationInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type SystemWorkspaceImage = {|
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemWorkspaceItem = {|
  apiHost?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?SystemWorkspaceImage,
  isCiCdEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  isOwner: $ElementType<Scalars, 'Boolean'>,
  kind?: ?$ElementType<Scalars, 'String'>,
  lastAccess?: ?$ElementType<Scalars, 'DateTime'>,
  name: $ElementType<Scalars, 'String'>,
  nextPlan?: ?SystemBillingNextPlanResponse,
  organization?: ?SystemOrganizationBaseItem,
  owner?: ?SystemMemberAccountInfo,
  plan?: ?SystemBillingCurrentPlanResponse,
  region?: ?$ElementType<Scalars, 'String'>,
  teamMemberCount?: ?$ElementType<Scalars, 'Int'>,
  webSocket?: ?$ElementType<Scalars, 'String'>,
|};

export const SystemWorkspaceKindValues = Object.freeze({
  Frontend: 'frontend',
  General: 'general'
});


/** Workspace Kind */
export type SystemWorkspaceKind = $Values<typeof SystemWorkspaceKindValues>;

/** SystemWorkspaceListResponse output */
export type SystemWorkspaceListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<SystemWorkspaceItem>,
|};

/** WorkspacePaymentDetailsUpdateMutationInput */
export type SystemWorkspacePaymentDetailsUpdateMutationInput = {|
  cardToken?: ?$ElementType<Scalars, 'String'>,
|};

export const SystemWorkspaceStatusValues = Object.freeze({
  Active: 'active',
  Blocked: 'blocked',
  Canceled: 'canceled',
  Canceling: 'canceling',
  Pending: 'pending',
  Suspended: 'suspended'
});


export type SystemWorkspaceStatus = $Values<typeof SystemWorkspaceStatusValues>;

export type SystemWorkspaceTransferItem = {|
  card?: ?SystemBillingDetailsResponse,
  createdAt: $ElementType<Scalars, 'DateTime'>,
  from: SystemTransferParticipant,
  initiator: $ElementType<Scalars, 'String'>,
  plans?: ?Array<?SystemBillingPlanBaseScalarInfo>,
  status: $ElementType<Scalars, 'String'>,
  to: SystemTransferParticipant,
  workspace: SystemWorkspaceTransferWorkspaceItem,
|};

export type SystemWorkspaceTransferWorkspaceItem = {|
  apiHost?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  description?: ?$ElementType<Scalars, 'String'>,
  image?: ?SystemWorkspaceImage,
  kind?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  organization?: ?SystemOrganizationBaseItem,
  owner?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  webSocket?: ?$ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
|};

/** WorkspaceUpdateMutationInput */
export type SystemWorkspaceUpdateMutationInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?GraphQlCreateFileItemInput,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type SystemWorkspaceUpdateResponse = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  image?: ?GraphQlFileItemResponse,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** Table */
export type Table = {|
  application?: ?Application,
  attributes?: ?TableAttributes,
  dataFeatures: TableDataFeatures,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  fields?: ?Array<TableField>,
  fieldsForIndexing?: ?Array<?TableField>,
  id: $ElementType<Scalars, 'ID'>,
  indexes?: ?Array<TableIndex>,
  isSystem: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  origin: SchemaOrigin,
  schemaFeatures: TableSchemaFeatures,
|};

/** Table Attributes */
export type TableAttributes = ViewAttributes;

/** Table Create Input */
export type TableCreateInput = {|
  applicationId?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
|};

/** Table Data Features */
export type TableDataFeatures = {|
  create: $ElementType<Scalars, 'Boolean'>,
  delete: $ElementType<Scalars, 'Boolean'>,
  update: $ElementType<Scalars, 'Boolean'>,
|};

/** Table Delete Input */
export type TableDeleteInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

/** TableField */
export type TableField = {|
  computedMode?: ?ComputedFieldMode,
  dataFeatures: FieldDataFeatures,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType: FieldType,
  fieldTypeAttributes?: ?FieldTypeAttributes,
  id: $ElementType<Scalars, 'ID'>,
  isList: $ElementType<Scalars, 'Boolean'>,
  isMeta: $ElementType<Scalars, 'Boolean'>,
  isRequired: $ElementType<Scalars, 'Boolean'>,
  isSystem: $ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  origin: SchemaOrigin,
  relation?: ?Relation,
  schemaFeatures: FieldSchemaFeatures,
  table: Table,
|};

/** Table Field Create Input */
export type TableFieldCreateInput = {|
  computedMode?: ?ComputedFieldMode,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType: FieldType,
  fieldTypeAttributes?: ?FieldTypeAttributesInput,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  initialValue?: ?$ElementType<Scalars, 'String'>,
  isList: $ElementType<Scalars, 'Boolean'>,
  isRequired: $ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  position?: ?$ElementType<Scalars, 'Int'>,
  relation?: ?RelationCreateInput,
  tableId: $ElementType<Scalars, 'ID'>,
|};

/** Table Field Delete Input */
export type TableFieldDeleteInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

/** Table Field Position Update Input */
export type TableFieldPositionUpdateInput = {|
  id: $ElementType<Scalars, 'ID'>,
  newPosition: $ElementType<Scalars, 'Int'>,
|};

/** Table Field Update Input */
export type TableFieldUpdateInput = {|
  computedMode?: ?ComputedFieldMode,
  defaultValue?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  expression?: ?$ElementType<Scalars, 'String'>,
  fieldType?: ?FieldType,
  fieldTypeAttributes?: ?FieldTypeAttributesInput,
  force?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  initialValue?: ?$ElementType<Scalars, 'String'>,
  isList?: ?$ElementType<Scalars, 'Boolean'>,
  isRequired?: ?$ElementType<Scalars, 'Boolean'>,
  isUnique?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  position?: ?$ElementType<Scalars, 'Int'>,
  relation?: ?RelationUpdateInput,
|};

/** Table Index */
export type TableIndex = {|
  columns?: ?Array<TableIndexColumn>,
  id: $ElementType<Scalars, 'ID'>,
  isSystem: $ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  table: Table,
  type: $ElementType<Scalars, 'String'>,
|};

/** Table Index Column */
export type TableIndexColumn = {|
  name: $ElementType<Scalars, 'String'>,
|};

/** Table Index Column Input */
export type TableIndexColumnInput = {|
  name: $ElementType<Scalars, 'String'>,
|};

export const TableIndexTypeValues = Object.freeze({
  Index: 'INDEX',
  Unique: 'UNIQUE'
});


export type TableIndexType = $Values<typeof TableIndexTypeValues>;

/** Table List Application Filter */
export type TableListApplicationFilter = {|
  id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** Table List Filter */
export type TableListFilter = {|
  applications?: ?Array<?TableListApplicationFilter>,
  onlyUserTables?: ?$ElementType<Scalars, 'Boolean'>,
  tableNames?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

/** Table List Response */
export type TableListResponse = {|
  count?: ?$ElementType<Scalars, 'Int'>,
  items?: ?Array<?Table>,
|};

/** Table Schema Create Features */
export type TableSchemaCreateFeatures = {|
  DATE: $ElementType<Scalars, 'Boolean'>,
  FILE: $ElementType<Scalars, 'Boolean'>,
  GEO: $ElementType<Scalars, 'Boolean'>,
  ID: $ElementType<Scalars, 'Boolean'>,
  JSON: $ElementType<Scalars, 'Boolean'>,
  MISSING_RELATION: $ElementType<Scalars, 'Boolean'>,
  NUMBER: $ElementType<Scalars, 'Boolean'>,
  ONE_WAY_RELATION: $ElementType<Scalars, 'Boolean'>,
  RELATION: $ElementType<Scalars, 'Boolean'>,
  SMART: $ElementType<Scalars, 'Boolean'>,
  SWITCH: $ElementType<Scalars, 'Boolean'>,
  TEXT: $ElementType<Scalars, 'Boolean'>,
  UUID: $ElementType<Scalars, 'Boolean'>,
|};

/** Table Schema Features */
export type TableSchemaFeatures = {|
  computedFields: $ElementType<Scalars, 'Boolean'>,
  create: TableSchemaCreateFeatures,
  update?: ?TableSchemaMetaFieldFeatures,
|};

/** Table Schema Meta Field Features */
export type TableSchemaMetaFieldFeatures = {|
  displayName: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'Boolean'>,
|};

/** Table Update Input */
export type TableUpdateInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type TeamInvitation = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  uuid?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  resentOn?: ?$ElementType<Scalars, 'DateTime'>,
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  acceptedOn?: ?$ElementType<Scalars, 'DateTime'>,
  invitee?: ?TeamMember,
  invitedBy?: ?TeamMember,
  _description?: ?$ElementType<Scalars, 'String'>,
|};

export type TeamInvitation_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  uuid?: ?StringPredicate,
  email?: ?StringPredicate,
  firstName?: ?StringPredicate,
  lastName?: ?StringPredicate,
  resentOn?: ?DateTimePredicate,
  accepted?: ?BoolPredicate,
  acceptedOn?: ?DateTimePredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  invitee?: ?TeamMember_PermissionFilter,
  invitedBy?: ?TeamMember_PermissionFilter,
  AND?: ?Array<TeamInvitation_PermissionFilter>,
  OR?: ?Array<TeamInvitation_PermissionFilter>,
|};

export type TeamInvitation_PermissionRelationFilter = {|
  some?: ?TeamInvitation_PermissionFilter,
  every?: ?TeamInvitation_PermissionFilter,
  none?: ?TeamInvitation_PermissionFilter,
|};

/** TeamInvitations delete input */
export type TeamInvitationDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** TeamInvitationFieldsPermissions create input */
export type TeamInvitationFieldsPermissions = {|
  createdAt?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt?: ?$ElementType<Scalars, 'Boolean'>,
  uuid?: ?$ElementType<Scalars, 'Boolean'>,
  email?: ?$ElementType<Scalars, 'Boolean'>,
  firstName?: ?$ElementType<Scalars, 'Boolean'>,
  lastName?: ?$ElementType<Scalars, 'Boolean'>,
  resentOn?: ?$ElementType<Scalars, 'Boolean'>,
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  acceptedOn?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type TeamInvitationFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  uuid?: ?StringPredicate,
  email?: ?StringPredicate,
  firstName?: ?StringPredicate,
  lastName?: ?StringPredicate,
  resentOn?: ?DateTimePredicate,
  accepted?: ?BoolPredicate,
  acceptedOn?: ?DateTimePredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  invitee?: ?TeamMemberFilter,
  invitedBy?: ?TeamMemberFilter,
  AND?: ?Array<TeamInvitationFilter>,
  OR?: ?Array<TeamInvitationFilter>,
|};

export type TeamInvitationGroupBy = {|
  query: TeamInvitationGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type TeamInvitationGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  uuid?: ?Array<GroupByField>,
  email?: ?Array<GroupByField>,
  firstName?: ?Array<GroupByField>,
  lastName?: ?Array<GroupByField>,
  resentOn?: ?Array<GroupByField>,
  accepted?: ?Array<GroupByField>,
  acceptedOn?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  invitee?: ?TeamMemberGroupByQuery,
  invitedBy?: ?TeamMemberGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type TeamInvitationKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  uuid?: ?$ElementType<Scalars, 'String'>,
|};

/** TeamInvitationListResponse output */
export type TeamInvitationListResponse = {|
  /** List items */
  items: Array<TeamInvitation>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

export const TeamInvitationOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  UuidAsc: 'uuid_ASC',
  UuidDesc: 'uuid_DESC',
  EmailAsc: 'email_ASC',
  EmailDesc: 'email_DESC',
  FirstNameAsc: 'firstName_ASC',
  FirstNameDesc: 'firstName_DESC',
  LastNameAsc: 'lastName_ASC',
  LastNameDesc: 'lastName_DESC',
  ResentOnAsc: 'resentOn_ASC',
  ResentOnDesc: 'resentOn_DESC',
  AcceptedAsc: 'accepted_ASC',
  AcceptedDesc: 'accepted_DESC',
  AcceptedOnAsc: 'acceptedOn_ASC',
  AcceptedOnDesc: 'acceptedOn_DESC'
});


/** No longer supported. Use `sort` instead. */
export type TeamInvitationOrderBy = $Values<typeof TeamInvitationOrderByValues>;

/** TeamInvitations subscription payload */
export type TeamInvitationPayload = {|
  mutation: MutationType,
  node?: ?TeamInvitation,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?TeamInvitation,
|};

export type TeamInvitationRelationFilter = {|
  some?: ?TeamInvitationFilter,
  every?: ?TeamInvitationFilter,
  none?: ?TeamInvitationFilter,
|};

export type TeamInvitationSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  uuid?: ?SortOrder,
  email?: ?SortOrder,
  firstName?: ?SortOrder,
  lastName?: ?SortOrder,
  resentOn?: ?SortOrder,
  accepted?: ?SortOrder,
  acceptedOn?: ?SortOrder,
  createdBy?: ?UserSort,
  invitee?: ?TeamMemberSort,
  invitedBy?: ?TeamMemberSort,
|};

/** TeamInvitations subscription filter */
export type TeamInvitationSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?TeamInvitationFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

export type TeamMember = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  user?: ?User,
  status?: ?$ElementType<Scalars, 'String'>,
  isOwner?: ?$ElementType<Scalars, 'Boolean'>,
  avatar?: ?File,
  roles?: ?RoleListResponse,
  receivedTeamInvitations?: ?TeamInvitationListResponse,
  sentTeamInvitations?: ?TeamInvitationListResponse,
  _description?: ?$ElementType<Scalars, 'String'>,
|};


export type TeamMemberRolesArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
|};


export type TeamMemberReceivedTeamInvitationsArgs = {|
  filter?: ?TeamInvitationFilter,
  orderBy?: ?Array<?TeamInvitationOrderBy>,
  sort?: ?Array<TeamInvitationSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?TeamInvitationGroupBy,
|};


export type TeamMemberSentTeamInvitationsArgs = {|
  filter?: ?TeamInvitationFilter,
  orderBy?: ?Array<?TeamInvitationOrderBy>,
  sort?: ?Array<TeamInvitationSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?TeamInvitationGroupBy,
|};

export type TeamMember_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  status?: ?StringPredicate,
  isOwner?: ?BoolPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?User_PermissionFilter,
  user?: ?User_PermissionFilter,
  avatar?: ?File_PermissionFilter,
  roles?: ?Role_PermissionRelationFilter,
  receivedTeamInvitations?: ?TeamInvitation_PermissionRelationFilter,
  sentTeamInvitations?: ?TeamInvitation_PermissionRelationFilter,
  AND?: ?Array<TeamMember_PermissionFilter>,
  OR?: ?Array<TeamMember_PermissionFilter>,
|};

export type TeamMember_PermissionRelationFilter = {|
  some?: ?TeamMember_PermissionFilter,
  every?: ?TeamMember_PermissionFilter,
  none?: ?TeamMember_PermissionFilter,
|};

/** TeamMembers delete input */
export type TeamMemberDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** TeamMemberFieldsPermissions create input */
export type TeamMemberFieldsPermissions = {|
  createdAt?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt?: ?$ElementType<Scalars, 'Boolean'>,
  status?: ?$ElementType<Scalars, 'Boolean'>,
  isOwner?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type TeamMemberFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  status?: ?StringPredicate,
  isOwner?: ?BoolPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  createdBy?: ?UserFilter,
  user?: ?UserFilter,
  avatar?: ?FileFilter,
  roles?: ?RoleRelationFilter,
  receivedTeamInvitations?: ?TeamInvitationRelationFilter,
  sentTeamInvitations?: ?TeamInvitationRelationFilter,
  AND?: ?Array<TeamMemberFilter>,
  OR?: ?Array<TeamMemberFilter>,
|};

export type TeamMemberGroupBy = {|
  query: TeamMemberGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type TeamMemberGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  status?: ?Array<GroupByField>,
  isOwner?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  user?: ?UserGroupByQuery,
  avatar?: ?FileGroupByQuery,
  roles?: ?RoleGroupByQuery,
  receivedTeamInvitations?: ?TeamInvitationGroupByQuery,
  sentTeamInvitations?: ?TeamInvitationGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

export type TeamMemberKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};

/** TeamMemberListResponse output */
export type TeamMemberListResponse = {|
  /** List items */
  items: Array<TeamMember>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** TeamMemberManyResponse output */
export type TeamMemberManyResponse = {|
  /** List items */
  items: Array<TeamMember>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const TeamMemberOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  StatusAsc: 'status_ASC',
  StatusDesc: 'status_DESC',
  IsOwnerAsc: 'isOwner_ASC',
  IsOwnerDesc: 'isOwner_DESC'
});


/** No longer supported. Use `sort` instead. */
export type TeamMemberOrderBy = $Values<typeof TeamMemberOrderByValues>;

/** TeamMembers subscription payload */
export type TeamMemberPayload = {|
  mutation: MutationType,
  node?: ?TeamMember,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?TeamMember,
|};

export type TeamMemberRelationFilter = {|
  some?: ?TeamMemberFilter,
  every?: ?TeamMemberFilter,
  none?: ?TeamMemberFilter,
|};

/** Files create input from teamMembers_avatar */
export type TeamMembers_Avatar_FileCreateInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarRelationInput,
|};

/** Files update input from teamMembers_avatar */
export type TeamMembers_Avatar_FileUpdateInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarUpdateRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarUpdateRelationInput,
|};

/** Roles create input from teamMembers */
export type TeamMembers_RoleCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersRelationInput,
  permissions?: ?PermissionsInput,
  apiTokens?: ?RolesApiTokensRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesRelationInput,
  teamMembers?: ?RolesTeamMembersRelationInput,
|};

/** Roles update input from teamMembers */
export type TeamMembers_RoleUpdateInput = {|
  filter?: ?RoleKeyFilter,
  data: RoleUpdateInput,
|};

/** TeamMembers relation input */
export type TeamMembersAvatarUpdateRelationInput = {|
  connect?: ?FileKeyFilter,
  disconnect?: ?FileKeyFilter,
  reconnect?: ?FileKeyFilter,
  create?: ?TeamMembers_Avatar_FileCreateInput,
  update?: ?TeamMembers_Avatar_FileUpdateInput,
|};

export type TeamMemberSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  status?: ?SortOrder,
  isOwner?: ?SortOrder,
  createdBy?: ?UserSort,
  user?: ?UserSort,
  avatar?: ?FileSort,
|};

/** TeamMembers relation input */
export type TeamMembersReceivedTeamInvitationsUpdateRelationInput = {|
  connect?: ?Array<TeamInvitationKeyFilter>,
  disconnect?: ?Array<TeamInvitationKeyFilter>,
  reconnect?: ?Array<TeamInvitationKeyFilter>,
|};

/** TeamMembers relation input */
export type TeamMembersRolesUpdateRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  disconnect?: ?Array<RoleKeyFilter>,
  reconnect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?TeamMembers_RoleCreateInput>,
  update?: ?Array<?TeamMembers_RoleUpdateInput>,
|};

/** TeamMembers relation input */
export type TeamMembersSentTeamInvitationsUpdateRelationInput = {|
  connect?: ?Array<TeamInvitationKeyFilter>,
  disconnect?: ?Array<TeamInvitationKeyFilter>,
  reconnect?: ?Array<TeamInvitationKeyFilter>,
|};

/** TeamMembers subscription filter */
export type TeamMemberSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?TeamMemberFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** TeamMembers update input */
export type TeamMemberUpdateByFilterInput = {|
  status?: ?Array<?UpdateByFilterStringSwitchInput>,
  isOwner?: ?Array<?UpdateByFilterBooleanSwitchInput>,
|};

/** TeamMembers update input */
export type TeamMemberUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  avatar?: ?TeamMembersAvatarUpdateRelationInput,
  roles?: ?TeamMembersRolesUpdateRelationInput,
  receivedTeamInvitations?: ?TeamMembersReceivedTeamInvitationsUpdateRelationInput,
  sentTeamInvitations?: ?TeamMembersSentTeamInvitationsUpdateRelationInput,
|};

/** Text Field Attributes */
export type TextFieldTypeAttributes = {|
  fieldSize?: ?$ElementType<Scalars, 'Int'>,
  format: $ElementType<Scalars, 'String'>,
|};

export const TextTypeFormatEnumValues = Object.freeze({
  Ein: 'EIN',
  Email: 'EMAIL',
  Html: 'HTML',
  Markdown: 'MARKDOWN',
  Name: 'NAME',
  Unformatted: 'UNFORMATTED'
});


/** Text Type Format Enum */
export type TextTypeFormatEnum = $Values<typeof TextTypeFormatEnumValues>;


export type TrimFunctionArguments = {|
  str: $ElementType<Scalars, 'String'>,
  mode?: ?StringTrimMode,
|};

export type UpdateByFilterBooleanSwitchInput = {|
  set?: ?$ElementType<Scalars, 'Boolean'>,
  invert?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type UpdateByFilterJsonInput = {|
  set?: ?$ElementType<Scalars, 'JSON'>,
|};

export type UpdateByFilterListStringInput = {|
  set?: ?Array<?$ElementType<Scalars, 'String'>>,
  push?: ?Array<?$ElementType<Scalars, 'String'>>,
  unshift?: ?Array<?$ElementType<Scalars, 'String'>>,
  insert?: ?UpdateByFilterListStringInsertOperationInput,
  remove?: ?Array<?$ElementType<Scalars, 'Int'>>,
  removeValue?: ?$ElementType<Scalars, 'String'>,
  swap?: ?Array<?$ElementType<Scalars, 'Int'>>,
|};

export type UpdateByFilterListStringInsertOperationInput = {|
  start: $ElementType<Scalars, 'Int'>,
  values: Array<$ElementType<Scalars, 'String'>>,
|};

export type UpdateByFilterStringInput = {|
  prefix?: ?$ElementType<Scalars, 'String'>,
  postfix?: ?$ElementType<Scalars, 'String'>,
  set?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateByFilterStringSwitchInput = {|
  set?: ?$ElementType<Scalars, 'String'>,
|};

/** UpdatedFieldsFilter */
export type UpdatedFieldsFilter = {|
  contains?: ?Array<?$ElementType<Scalars, 'String'>>,
  every?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type UploadcareSignInfoResponse = {|
  expire: $ElementType<Scalars, 'String'>,
  publicKey: $ElementType<Scalars, 'String'>,
  signature: $ElementType<Scalars, 'String'>,
|};

export type User = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'Int'>,
  createdBy?: ?User,
  email?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  origin?: ?$ElementType<Scalars, 'String'>,
  is8base?: ?$ElementType<Scalars, 'Boolean'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?File,
  roles?: ?RoleListResponse,
  permissions?: ?UserPermissionList,
  _description?: ?$ElementType<Scalars, 'String'>,
|};


export type UserRolesArgs = {|
  filter?: ?RoleFilter,
  orderBy?: ?Array<?RoleOrderBy>,
  sort?: ?Array<RoleSort>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  after?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  groupBy?: ?RoleGroupBy,
|};


export type UserPermissionsArgs = {|
  filter?: ?PermissionInputFilter,
|};

export type User_PermissionFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  email?: ?StringPredicate,
  status?: ?StringPredicate,
  origin?: ?StringPredicate,
  is8base?: ?BoolPredicate,
  firstName?: ?StringPredicate,
  lastName?: ?StringPredicate,
  timezone?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  is_self?: ?$ElementType<Scalars, 'Boolean'>,
  not_self?: ?$ElementType<Scalars, 'Boolean'>,
  createdBy?: ?User_PermissionFilter,
  avatar?: ?File_PermissionFilter,
  roles?: ?Role_PermissionRelationFilter,
  AND?: ?Array<User_PermissionFilter>,
  OR?: ?Array<User_PermissionFilter>,
|};

export type User_PermissionRelationFilter = {|
  some?: ?User_PermissionFilter,
  every?: ?User_PermissionFilter,
  none?: ?User_PermissionFilter,
|};

export type UserBillingConfigurationResponse = {|
  availablePlans: Array<BillingPlanBaseInfo>,
  isCancelSubscriptionAvailable: $ElementType<Scalars, 'Boolean'>,
  isFreePlanAvailable: $ElementType<Scalars, 'Boolean'>,
|};

/** Users create input */
export type UserCreateInput = {|
  email: $ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?UsersAvatarRelationInput,
  roles?: ?UsersRolesRelationInput,
|};

/** Users create many input */
export type UserCreateManyInput = {|
  email: $ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?UsersAvatarManyRelationInput,
  roles?: ?UsersRolesManyRelationInput,
|};

/** Users delete input */
export type UserDeleteInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  force?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** UserFieldsPermissions create input */
export type UserFieldsPermissions = {|
  createdAt?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt?: ?$ElementType<Scalars, 'Boolean'>,
  email?: ?$ElementType<Scalars, 'Boolean'>,
  status?: ?$ElementType<Scalars, 'Boolean'>,
  origin?: ?$ElementType<Scalars, 'Boolean'>,
  is8base?: ?$ElementType<Scalars, 'Boolean'>,
  firstName?: ?$ElementType<Scalars, 'Boolean'>,
  lastName?: ?$ElementType<Scalars, 'Boolean'>,
  timezone?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type UserFilter = {|
  id?: ?IdPredicate,
  createdAt?: ?DateTimePredicate,
  updatedAt?: ?DateTimePredicate,
  deletedAt?: ?IntPredicate,
  email?: ?StringPredicate,
  status?: ?StringPredicate,
  origin?: ?StringPredicate,
  is8base?: ?BoolPredicate,
  firstName?: ?StringPredicate,
  lastName?: ?StringPredicate,
  timezone?: ?StringPredicate,
  _fullText?: ?$ElementType<Scalars, 'String'>,
  is_self?: ?$ElementType<Scalars, 'Boolean'>,
  not_self?: ?$ElementType<Scalars, 'Boolean'>,
  createdBy?: ?UserFilter,
  avatar?: ?FileFilter,
  roles?: ?RoleRelationFilter,
  AND?: ?Array<UserFilter>,
  OR?: ?Array<UserFilter>,
|};

export type UserGroupBy = {|
  query: UserGroupByQuery,
  sort?: ?Array<GroupBySort>,
  having?: ?Having,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
|};

export type UserGroupByQuery = {|
  id?: ?Array<GroupByField>,
  createdAt?: ?Array<GroupByField>,
  updatedAt?: ?Array<GroupByField>,
  email?: ?Array<GroupByField>,
  status?: ?Array<GroupByField>,
  origin?: ?Array<GroupByField>,
  is8base?: ?Array<GroupByField>,
  firstName?: ?Array<GroupByField>,
  lastName?: ?Array<GroupByField>,
  timezone?: ?Array<GroupByField>,
  createdBy?: ?UserGroupByQuery,
  avatar?: ?FileGroupByQuery,
  roles?: ?RoleGroupByQuery,
  _group?: ?Array<GroupIdentifiersGroupByField>,
|};

/** User Invitation Details */
export type UserInvitationDetails = {|
  id?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  isRegistered?: ?$ElementType<Scalars, 'Boolean'>,
  resentOn?: ?$ElementType<Scalars, 'DateTime'>,
  accepted?: ?$ElementType<Scalars, 'Boolean'>,
  acceptedOn?: ?$ElementType<Scalars, 'DateTime'>,
  apiHost?: ?$ElementType<Scalars, 'String'>,
  invitedBy?: ?InvitedByName,
|};

/** User Invitation List */
export type UserInvitationList = {|
  items?: ?Array<?UserInvitationDetails>,
  count?: ?$ElementType<Scalars, 'Int'>,
|};

export type UserKeyFilter = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  email?: ?$ElementType<Scalars, 'String'>,
|};

/** UserListResponse output */
export type UserListResponse = {|
  /** List items */
  items: Array<User>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** Aggregated items */
  groups: Array<GroupByResponse>,
|};

/** UserLoginInput */
export type UserLoginInput = {|
  authProfileId?: ?$ElementType<Scalars, 'ID'>,
  email: $ElementType<Scalars, 'String'>,
  fromInvitation?: ?$ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
|};

/** UserManyResponse output */
export type UserManyResponse = {|
  /** List items */
  items: Array<User>,
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
|};

export const UserOrderByValues = Object.freeze({
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC',
  DeletedAtAsc: 'deletedAt_ASC',
  DeletedAtDesc: 'deletedAt_DESC',
  EmailAsc: 'email_ASC',
  EmailDesc: 'email_DESC',
  StatusAsc: 'status_ASC',
  StatusDesc: 'status_DESC',
  OriginAsc: 'origin_ASC',
  OriginDesc: 'origin_DESC',
  IsOwnerAsc: 'isOwner_ASC',
  IsOwnerDesc: 'isOwner_DESC',
  Is8baseAsc: 'is8base_ASC',
  Is8baseDesc: 'is8base_DESC',
  FirstNameAsc: 'firstName_ASC',
  FirstNameDesc: 'firstName_DESC',
  LastNameAsc: 'lastName_ASC',
  LastNameDesc: 'lastName_DESC',
  TimezoneAsc: 'timezone_ASC',
  TimezoneDesc: 'timezone_DESC'
});


/** No longer supported. Use `sort` instead. */
export type UserOrderBy = $Values<typeof UserOrderByValues>;

/** Users subscription payload */
export type UserPayload = {|
  mutation: MutationType,
  node?: ?User,
  updatedFields?: ?Array<?$ElementType<Scalars, 'String'>>,
  previousValues?: ?User,
|};

/** User Permission */
export type UserPermission = {|
  resourceType?: ?$ElementType<Scalars, 'String'>,
  resource?: ?$ElementType<Scalars, 'String'>,
  permission?: ?$ElementType<Scalars, 'JSON'>,
|};

/** User Permission List */
export type UserPermissionList = {|
  items?: ?Array<UserPermission>,
  count: $ElementType<Scalars, 'Int'>,
|};

export type UserRelationFilter = {|
  some?: ?UserFilter,
  every?: ?UserFilter,
  none?: ?UserFilter,
|};

/** Files create input from users_avatar */
export type Users_Avatar_FileCreateInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarRelationInput,
|};

/** Files update input from users_avatar */
export type Users_Avatar_FileUpdateInput = {|
  fileId?: ?$ElementType<Scalars, 'String'>,
  public?: ?$ElementType<Scalars, 'Boolean'>,
  filename?: ?$ElementType<Scalars, 'String'>,
  meta?: ?$ElementType<Scalars, 'JSON'>,
  mods?: ?$ElementType<Scalars, 'JSON'>,
  users_avatar?: ?FilesUsers_AvatarUpdateRelationInput,
  teamMembers_avatar?: ?FilesTeamMembers_AvatarUpdateRelationInput,
|};

/** Roles create input from users */
export type Users_RoleCreateInput = {|
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  users?: ?RolesUsersRelationInput,
  permissions?: ?PermissionsInput,
  apiTokens?: ?RolesApiTokensRelationInput,
  authenticationProfiles?: ?RolesAuthenticationProfilesRelationInput,
  teamMembers?: ?RolesTeamMembersRelationInput,
|};

/** Roles update input from users */
export type Users_RoleUpdateInput = {|
  filter?: ?RoleKeyFilter,
  data: RoleUpdateInput,
|};

/** Users relation input */
export type UsersAvatarManyRelationInput = {|
  connect?: ?FileKeyFilter,
|};

/** Users relation input */
export type UsersAvatarRelationInput = {|
  connect?: ?FileKeyFilter,
  create?: ?Users_Avatar_FileCreateInput,
|};

/** Users relation input */
export type UsersAvatarUpdateRelationInput = {|
  connect?: ?FileKeyFilter,
  disconnect?: ?FileKeyFilter,
  reconnect?: ?FileKeyFilter,
  create?: ?Users_Avatar_FileCreateInput,
  update?: ?Users_Avatar_FileUpdateInput,
|};

export type UserSort = {|
  id?: ?SortOrder,
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  deletedAt?: ?SortOrder,
  email?: ?SortOrder,
  status?: ?SortOrder,
  origin?: ?SortOrder,
  is8base?: ?SortOrder,
  firstName?: ?SortOrder,
  lastName?: ?SortOrder,
  timezone?: ?SortOrder,
  createdBy?: ?UserSort,
  avatar?: ?FileSort,
|};

/** Users relation input */
export type UsersRolesManyRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
|};

/** Users relation input */
export type UsersRolesRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?Users_RoleCreateInput>,
|};

/** Users relation input */
export type UsersRolesUpdateRelationInput = {|
  connect?: ?Array<RoleKeyFilter>,
  disconnect?: ?Array<RoleKeyFilter>,
  reconnect?: ?Array<RoleKeyFilter>,
  create?: ?Array<?Users_RoleCreateInput>,
  update?: ?Array<?Users_RoleUpdateInput>,
|};

/** Users subscription filter */
export type UserSubscriptionFilter = {|
  mutation_in?: ?Array<?MutationType>,
  node?: ?UserFilter,
  updatedFields?: ?UpdatedFieldsFilter,
|};

/** Users update input */
export type UserUpdateByFilterInput = {|
  email?: ?Array<?UpdateByFilterStringInput>,
  status?: ?Array<?UpdateByFilterStringSwitchInput>,
  origin?: ?Array<?UpdateByFilterStringSwitchInput>,
  isOwner?: ?Array<?UpdateByFilterBooleanSwitchInput>,
  is8base?: ?Array<?UpdateByFilterBooleanSwitchInput>,
  firstName?: ?Array<?UpdateByFilterStringInput>,
  lastName?: ?Array<?UpdateByFilterStringInput>,
  timezone?: ?Array<?UpdateByFilterStringInput>,
|};

/** Users update input */
export type UserUpdateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  avatar?: ?UsersAvatarUpdateRelationInput,
  roles?: ?UsersRolesUpdateRelationInput,
|};

/** UUID Field Attributes */
export type UuidFieldTypeAttributes = {|
  fieldSize?: ?$ElementType<Scalars, 'Int'>,
|};

/** VerificationEmailResendInput */
export type VerificationEmailResendInput = {|
  email: $ElementType<Scalars, 'String'>,
|};

/** View Attributes */
export type ViewAttributes = {|
  query?: ?$ElementType<Scalars, 'String'>,
|};

/** View Create Input */
export type ViewCreateInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  query: $ElementType<Scalars, 'String'>,
|};

/** Dry Run Response for previewing SQL Views */
export type ViewDryRunOutput = {|
  count?: ?$ElementType<Scalars, 'Int'>,
  timeMs?: ?$ElementType<Scalars, 'Int'>,
  items?: ?$ElementType<Scalars, 'JSON'>,
|};

/** View Update Input */
export type ViewUpdateInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  query?: ?$ElementType<Scalars, 'String'>,
|};

/** WorkspaceCreateMutationInput */
export type WorkspaceCreateMutationInput = {|
  billingPlanId?: ?$ElementType<Scalars, 'ID'>,
  cardToken?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  image?: ?GraphQlCreateFileItemInput,
  kind?: ?WorkspaceKind,
  name: $ElementType<Scalars, 'String'>,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  authIntegration?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type WorkspaceCreateResponse = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  kind?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

/** WorkspaceDeleteMutationInput */
export type WorkspaceDeleteMutationInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type WorkspaceImage = {|
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

/** LoginResponseWorkspace name and id */
export type WorkspaceInfo = {|
  name?: ?$ElementType<Scalars, 'String'>,
  workspace?: ?$ElementType<Scalars, 'ID'>,
|};

export type WorkspaceItem = {|
  apiHost?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?WorkspaceImage,
  isCiCdEnabled?: ?$ElementType<Scalars, 'Boolean'>,
  isOwner: $ElementType<Scalars, 'Boolean'>,
  kind?: ?$ElementType<Scalars, 'String'>,
  lastAccess?: ?$ElementType<Scalars, 'DateTime'>,
  name: $ElementType<Scalars, 'String'>,
  nextPlan?: ?BillingNextPlanResponse,
  organization?: ?SystemOrganizationBaseItem,
  owner?: ?SystemMemberAccountInfo,
  plan?: ?BillingCurrentPlanResponse,
  region?: ?$ElementType<Scalars, 'String'>,
  teamMemberCount?: ?$ElementType<Scalars, 'Int'>,
  webSocket?: ?$ElementType<Scalars, 'String'>,
|};

export const WorkspaceKindValues = Object.freeze({
  Frontend: 'frontend',
  General: 'general'
});


/** Workspace Kind */
export type WorkspaceKind = $Values<typeof WorkspaceKindValues>;

/** WorkspaceListResponse output */
export type WorkspaceListResponse = {|
  /** List items count */
  count: $ElementType<Scalars, 'Int'>,
  /** List items */
  items: Array<WorkspaceItem>,
|};

export const WorkspaceStatusValues = Object.freeze({
  Active: 'active',
  Blocked: 'blocked',
  Canceled: 'canceled',
  Canceling: 'canceling',
  Pending: 'pending',
  Suspended: 'suspended'
});


export type WorkspaceStatus = $Values<typeof WorkspaceStatusValues>;

/** WorkspaceUpdateMutationInput */
export type WorkspaceUpdateMutationInput = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  image?: ?GraphQlCreateFileItemInput,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type WorkspaceUpdateResponse = {|
  description?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  image?: ?GraphQlFileItemResponse,
  name?: ?$ElementType<Scalars, 'String'>,
|};

type $Pick<Origin: Object, Keys: Object> = $ObjMapi<Keys, <Key>(k: Key) => $ElementType<Origin, Key>>;

export type AuthFragment = $Pick<Auth, {| refreshToken?: *, idToken?: * |}>;

export type RoleFragmentFragment = $Pick<Role, {| id?: *, name?: *, description?: * |}>;

export type TeamMemberFragmentFragment = ({
    ...$Pick<SystemEnvironmentMember, {| email: *, status: *, firstName?: *, lastName?: *, createdAt?: * |}>,
  ...{| permissions?: ?{| items?: ?Array<$Pick<SystemEnvironmentMemberPermission, {| resource?: *, resourceType?: *, permission?: * |}>> |}, avatar?: ?$Pick<SystemEnvironmentMemberAvatar, {| id?: *, downloadUrl?: * |}>, roles?: ?{| items?: ?Array<$Pick<SystemEnvironmentMemberRole, {| id: *, name: * |}>> |} |}
});

export type AvatarFragment = ({
    ...$Pick<User, {| id?: *, firstName?: * |}>,
  ...{| avatar?: ?$Pick<File, {| id?: *, fileId?: *, public?: *, filename?: *, downloadUrl?: * |}> |}
});

export type UserFragmentFragment = ({
    ...$Pick<User, {| id?: *, createdAt?: *, email?: *, firstName?: *, lastName?: *, status?: *, origin?: * |}>,
  ...{| avatar?: ?$Pick<File, {| id?: *, fileId?: *, public?: *, filename?: *, downloadUrl?: * |}>, roles?: ?{| items: Array<$Pick<Role, {| id?: *, name?: * |}>> |} |}
});

export type UserAccountInfoFragmentFragment = ({
    ...$Pick<SystemMemberAccountInfo, {| email: *, createdAt?: *, firstName?: *, lastName?: *, timezone?: *, learningMode?: *, role?: *, type?: *, isDeveloper?: *, companyName?: *, projectDescription?: *, website?: *, githubUsername?: *, linkedInUsername?: *, twitterUsername?: *, aboutMe?: *, country?: *, addressLine1?: *, addressLine2?: *, city?: *, state?: *, zipCode?: * |}>,
  ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
});

export type EnvInvitationDetailsFragment = ({
    ...$Pick<SystemInboxEventEnvironmentInvitationDetails, {| status?: *, environmentName?: * |}>,
  ...{| workspace?: ?({
      ...$Pick<SystemInboxEventWorkspace, {| id: *, name?: *, apiHost?: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }), invitedBy?: ?({
      ...$Pick<SystemInboxEventInvitedBy, {| email?: *, firstName?: *, lastName?: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }) |}
});

export type OrgInvitationDetailsFragment = ({
    ...$Pick<SystemInboxEventOrganizationInvitationDetails, {| status?: * |}>,
  ...{| invitedBy?: ?({
      ...$Pick<SystemInboxEventInvitedBy, {| email?: *, firstName?: *, lastName?: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }), organization?: ?({
      ...$Pick<SystemInboxEventOrganization, {| id: *, name?: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }) |}
});

export type TransferAcceptDetailsFragment = ({
    ...$Pick<SystemInboxEventWorkspaceTransferDetails, {| status?: * |}>,
  ...{| workspace?: ?({
      ...$Pick<SystemInboxEventWorkspace, {| id: *, name?: *, apiHost?: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }), from?: ?({
      ...$Pick<SystemInboxEventTransferParticipant, {| name?: *, type: *, identity: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }), to?: ?({
      ...$Pick<SystemInboxEventTransferParticipant, {| name?: *, type: *, identity: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }) |}
});

export type NotificationDetailsFragment = $Pick<SystemInboxEventNotificationDetailsType, {| details?: *, kind?: * |}>;

export type InboxItemFragment = ({
    ...$Pick<SystemInboxEventItem, {| id: *, isCompleted?: *, createdAt: *, type?: * |}>,
  ...{| details?: ?EnvInvitationDetailsFragment | NotificationDetailsFragment | OrgInvitationDetailsFragment | TransferAcceptDetailsFragment |}
});

export type OrganizationItemBaseInfoFragment = $Pick<SystemOrganizationItem, {| id: *, name: *, type?: *, description?: * |}>;

export type OrganizationItemShortInfoFragment = ({
    ...{| image?: ?$Pick<SystemOrganizationItemImage, {| id?: *, downloadUrl?: * |}> |},
  ...OrganizationItemBaseInfoFragment
});

export type OrganizationItemFullInfoFragment = ({
    ...{| image?: ?$Pick<SystemOrganizationItemImage, {| id?: *, downloadUrl?: * |}>, users?: ?Array<?({
      ...$Pick<SystemOrganizationUserInfo, {| id: *, firstName?: *, lastName?: *, email: *, role: *, status: * |}>,
    ...{| workspaces?: ?Array<?({
        ...$Pick<SystemOrganizationWorkspaceItem, {| id: *, name: *, description?: *, kind: *, apiHost?: * |}>,
      ...{| image?: ?$Pick<SystemFileBaseInfo, {| id?: *, downloadUrl?: * |}>, organization?: ?$Pick<SystemOrganizationBaseItem, {| id: *, name: *, type?: *, description?: * |}> |}
    })>, avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  })> |},
  ...OrganizationItemBaseInfoFragment
});

export type FieldForIndexingFragment = $Pick<SystemTableField, {| id: *, name: *, displayName?: *, fieldType: * |}>;

export type WorkspaceItemFragment = ({
    ...$Pick<SystemWorkspaceItem, {| id: *, name: *, description?: *, createdAt?: *, kind?: *, isOwner: *, lastAccess?: *, teamMemberCount?: *, isCiCdEnabled?: *, region?: *, apiHost?: *, webSocket?: * |}>,
  ...{| organization?: ?({
      ...$Pick<SystemOrganizationBaseItem, {| id: *, name: * |}>,
    ...{| image?: ?$Pick<SystemFileBaseInfo, {| downloadUrl?: * |}> |}
  }), owner?: ?({
      ...$Pick<SystemMemberAccountInfo, {| firstName?: *, lastName?: * |}>,
    ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
  }), plan?: ?$Pick<SystemBillingCurrentPlanResponse, {| id?: *, name?: *, displayName?: *, trialEnd?: *, status?: *, price?: * |}>, nextPlan?: ?$Pick<SystemBillingNextPlanResponse, {| id?: *, name?: *, displayName?: *, price?: * |}>, image?: ?$Pick<SystemWorkspaceImage, {| id?: *, downloadUrl?: * |}> |}
});

export type BillingDetailsUpdateMutationVariables = {
  data: BillingDetailsUpdateMutationInput,
};


export type BillingDetailsUpdateMutation = {| billingDetailsUpdate?: ?$Pick<BillingDetailsResponse, {| expYear?: *, expMonth?: *, last4?: *, brand?: * |}> |};

export type UserPaymentDetailsUpdateMutationVariables = {
  data: SystemMemberPaymentDetailsUpdateMutationInput,
};


export type UserPaymentDetailsUpdateMutation = {| system?: ?{| memberPaymentDetailsUpdate?: ?$Pick<SystemPaymentDetailsResponse, {| expYear?: *, expMonth?: *, last4?: *, brand?: * |}> |} |};

export type WorkspacePaymentDetailsUpdateMutationVariables = {
  data?: ?SystemWorkspacePaymentDetailsUpdateMutationInput,
};


export type WorkspacePaymentDetailsUpdateMutation = {| system?: ?{| workspacePaymentDetailsUpdate?: ?$Pick<SystemPaymentDetailsResponse, {| expYear?: *, expMonth?: *, last4?: *, brand?: *, origin: * |}> |} |};

export type OrganizationPaymentDetailsUpdateMutationVariables = {
  data: SystemOrganizationPaymentDetailsUpdateMutationInput,
};


export type OrganizationPaymentDetailsUpdateMutation = {| system?: ?{| organizationPaymentDetailsUpdate?: ?$Pick<SystemPaymentDetailsResponse, {| expYear?: *, expMonth?: *, last4?: *, brand?: * |}> |} |};

export type BillingPlanUpdateMutationVariables = {
  data: BillingPlanUpdateMutationInput,
};


export type BillingPlanUpdateMutation = {| billingPlanUpdate?: ?({
      ...$Pick<BillingCurrentPlanResponse, {| id?: *, name?: *, displayName?: *, trialEnd?: *, status?: *, price?: * |}>,
    ...{| nextPlan?: ?$Pick<BillingNextPlanResponse, {| id?: *, name?: *, displayName?: *, price?: * |}> |}
  }) |};

export type CiInstallMutationVariables = {};


export type CiInstallMutation = {| system?: ?{| ciInstall?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type ApiTokenDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type ApiTokenDeleteMutation = {| apiTokenDelete?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type ApiTokenUpdateMutationVariables = {
  data: ApiTokenUpdateInput,
};


export type ApiTokenUpdateMutation = {| apiTokenUpdate: ({
      ...$Pick<ApiToken, {| id?: *, name?: * |}>,
    ...{| roles?: ?{| items: Array<$Pick<Role, {| id?: *, name?: * |}>> |} |}
  }) |};

export type ApiTokenCreateMutationVariables = {
  data: ApiTokenCreateInput,
};


export type ApiTokenCreateMutation = {| apiTokenCreate: $Pick<ApiTokenResponse, {| id?: *, name?: *, token: * |}> |};

export type EnvironmentVariableDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type EnvironmentVariableDeleteMutation = {| environmentVariableDelete?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type EnvironmentVariableUpdateMutationVariables = {
  data: EnvironmentVariableUpdateInput,
  filter?: ?EnvironmentVariableKeyFilter,
};


export type EnvironmentVariableUpdateMutation = {| environmentVariableUpdate: $Pick<EnvironmentVariable, {| id?: *, name?: *, value?: * |}> |};

export type EnvironmentVariableCreateMutationVariables = {
  data: EnvironmentVariableCreateInput,
};


export type EnvironmentVariableCreateMutation = {| environmentVariableCreate: $Pick<EnvironmentVariable, {| id?: *, name?: *, value?: * |}> |};

export type NotificationUpdateMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  status: NotificationStatusType,
};


export type NotificationUpdateMutation = {| system?: ?{| notificationUpdate?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type OrganizationUpdateMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  image?: ?GraphQlCreateFileItemInput,
};


export type OrganizationUpdateMutation = {| system?: ?{| organizationUpdate?: ?OrganizationItemShortInfoFragment |} |};

export type OrganizationUserUpdateMutationVariables = {
  organizationId: $ElementType<Scalars, 'ID'>,
  email: $ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
};


export type OrganizationUserUpdateMutation = {| system?: ?{| organizationUserUpdate?: ?({
        ...$Pick<SystemOrganizationUserInfo, {| id: *, email: *, firstName?: *, lastName?: *, role: *, status: * |}>,
      ...{| avatar?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
    }) |} |};

export type OrganizationInvitationAcceptMutationVariables = {
  invitationId: $ElementType<Scalars, 'String'>,
};


export type OrganizationInvitationAcceptMutation = {| system?: ?{| organizationInviteUserAccept?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type OrganizationInvitationCancelMutationVariables = {
  invitationId: $ElementType<Scalars, 'String'>,
};


export type OrganizationInvitationCancelMutation = {| system?: ?{| organizationInviteUserCancel?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type OrganizationInviteTeamMemberMutationVariables = {
  organizationId: $ElementType<Scalars, 'ID'>,
  email: $ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  workspaceRoles?: ?Array<?SystemOrganizationWorkspaceWithEnvironmentRolesInput>,
};


export type OrganizationInviteTeamMemberMutation = {| system?: ?{| organizationInviteUser?: ?$Pick<OrganizationUserInvitationResponse, {| invitationId: * |}> |} |};

export type RoleUpdateMutationVariables = {
  data: RoleUpdateInput,
  filter?: ?RoleKeyFilter,
};


export type RoleUpdateMutation = {| roleUpdate: RoleFragmentFragment |};

export type RoleDeleteMutationVariables = {
  data?: ?RoleDeleteInput,
  filter?: ?RoleKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
};


export type RoleDeleteMutation = {| roleDelete?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type RoleCreateMutationVariables = {
  data: RoleCreateInput,
};


export type RoleCreateMutation = {| roleCreate: $Pick<Role, {| id?: *, name?: *, description?: * |}> |};

export type TableFieldDeleteMutationVariables = {
  data: TableFieldDeleteInput,
};


export type TableFieldDeleteMutation = {| fieldDelete: $Pick<SuccessResponse, {| success?: * |}> |};

export type TableUpdateMutationVariables = {
  data: TableUpdateInput,
};


export type TableUpdateMutation = {| tableUpdate: $Pick<Table, {| id: *, name: *, displayName?: *, description?: * |}> |};

export type TeamInvitationAcceptMutationVariables = {
  data: SystemMemberInvitationAcceptInput,
};


export type TeamInvitationAcceptMutation = {| system?: ?{| memberInvitationAccept: $Pick<SystemMemberInvitationAcceptResponse, {| success: * |}> |} |};

export type TeamInvitationCancelMutationVariables = {
  data: SystemMemberInvitationCancelInput,
};


export type TeamInvitationCancelMutation = {| system?: ?{| memberInvitationCancel?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type TeamInvitationResendMutationVariables = {
  data: SystemMemberInvitationResendInput,
};


export type TeamInvitationResendMutation = {| system?: ?{| memberInvitationResend?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type TeamMemberUpdateMutationVariables = {
  data: SystemEnvironmentMemberUpdateData,
  filter?: ?SystemEnvironmentMembersFilter,
};


export type TeamMemberUpdateMutation = {| system?: ?{| environmentMemberUpdate?: ?TeamMemberFragmentFragment |} |};

export type TeamMemberDeleteMutationVariables = {
  filter: SystemEnvironmentMembersFilter,
};


export type TeamMemberDeleteMutation = {| system?: ?{| environmentMemberDelete: $Pick<SuccessResponse, {| success?: * |}> |} |};

export type AcceptTransferMutationVariables = {
  workspaceId: $ElementType<Scalars, 'ID'>,
  planId: $ElementType<Scalars, 'ID'>,
  cardToken?: ?$ElementType<Scalars, 'String'>,
  workspaceName?: ?$ElementType<Scalars, 'String'>,
};


export type AcceptTransferMutation = {| system?: ?{| workspaceTransferAccept?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type CancelTransferOwnershipMutationVariables = {
  transferId: $ElementType<Scalars, 'ID'>,
};


export type CancelTransferOwnershipMutation = {| system?: ?{| workspaceTransferAbort?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type UserUpdateMutationVariables = {
  data: UserUpdateInput,
  filter?: ?UserKeyFilter,
};


export type UserUpdateMutation = {| userUpdate: UserFragmentFragment |};

export type UserDeleteMutationVariables = {
  data?: ?UserDeleteInput,
  filter?: ?UserKeyFilter,
  force?: ?$ElementType<Scalars, 'Boolean'>,
};


export type UserDeleteMutation = {| userDelete?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type UserAccountInfoUpdateMutationVariables = {
  data: SystemMemberAccountUpsertDataInput,
};


export type UserAccountInfoUpdateMutation = {| system?: ?{| memberAccountUpsert?: ?UserAccountInfoFragmentFragment |} |};

export type UserAccountInviteMutationVariables = {
  inviteEmail: $ElementType<Scalars, 'String'>,
};


export type UserAccountInviteMutation = {| sendInvitationTo8base?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type WorkspaceLeaveMutationVariables = {
  force?: ?$ElementType<Scalars, 'Boolean'>,
};


export type WorkspaceLeaveMutation = {| workspaceLeave?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type CreateWorkspaceMutationVariables = {
  data: WorkspaceCreateMutationInput,
};


export type CreateWorkspaceMutation = {| workspaceCreate?: ?$Pick<WorkspaceCreateResponse, {| id?: *, name?: * |}> |};

export type WorkspaceDeleteMutationVariables = {
  data: WorkspaceDeleteMutationInput,
};


export type WorkspaceDeleteMutation = {| workspaceDelete?: ?$Pick<SuccessResponse, {| success?: * |}> |};

export type WorkspaceUpdateMutationVariables = {
  data: SystemWorkspaceUpdateMutationInput,
};


export type WorkspaceUpdateMutation = {| system?: ?{| workspaceUpdate?: ?({
        ...$Pick<SystemWorkspaceUpdateResponse, {| id?: *, name?: *, description?: * |}>,
      ...{| image?: ?$Pick<GraphQlFileItemResponse, {| id?: *, downloadUrl?: * |}> |}
    }) |} |};

export type WorkspaceChangeOwnerShipMutationVariables = {
  owner: $ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
};


export type WorkspaceChangeOwnerShipMutation = {| system?: ?{| workspaceTransferRegister?: ?$Pick<SuccessWithMessageResponse, {| success?: * |}> |} |};

export type WorkspaceShareWithEnvsMutationVariables = {
  email: $ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'ID'>,
  environmentRoles?: ?Array<?SystemOrganizationWorkspaceEnvironmentRolesInput>,
};


export type WorkspaceShareWithEnvsMutation = {| system?: ?{| organizationWorkspaceUserShare?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type WorkspaceOrganizationUserRemoveMutationVariables = {
  email: $ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'String'>,
};


export type WorkspaceOrganizationUserRemoveMutation = {| system?: ?{| organizationWorkspaceUserRemove?: ?$Pick<SuccessResponse, {| success?: * |}> |} |};

export type BillingInvoicesListQueryVariables = {};


export type BillingInvoicesListQuery = {| billingInvoicesList: {| items: Array<$Pick<BillingInvoiceItem, {| periodStart?: *, periodEnd?: *, paid?: *, invoicePdf?: *, amountDue?: *, amountPaid?: *, amountRemaining?: *, endingBalance?: *, number?: *, status?: *, total?: *, description?: * |}>> |} |};

export type BillingCurrentPlanQueryVariables = {};


export type BillingCurrentPlanQuery = {| billingCurrentPlan?: ?({
      ...$Pick<BillingCurrentPlanResponse, {| id?: *, name?: *, displayName?: *, trialEnd?: *, status?: *, price?: *, pdf?: * |}>,
    ...{| nextPlan?: ?$Pick<BillingNextPlanResponse, {| id?: *, name?: *, displayName?: *, price?: * |}> |}
  }) |};

export type WorkspacePaymentDetailsQueryVariables = {};


export type WorkspacePaymentDetailsQuery = {| system?: ?{| workspacePaymentDetails?: ?$Pick<SystemPaymentDetailsResponse, {| expYear?: *, expMonth?: *, last4?: *, brand?: *, origin: * |}> |} |};

export type UserPaymentDetailsQueryVariables = {};


export type UserPaymentDetailsQuery = {| system?: ?{| memberPaymentDetails?: ?$Pick<SystemPaymentDetailsResponse, {| expYear?: *, expMonth?: *, last4?: *, brand?: * |}> |} |};

export type BillingPlansQueryVariables = {
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  region?: ?$ElementType<Scalars, 'String'>,
};


export type BillingPlansQuery = {| system?: ?{| userBillingConfiguration: ({
        ...$Pick<SystemUserBillingConfigurationResponse, {| isFreePlanAvailable: * |}>,
      ...{| availablePlans: Array<({
          ...$Pick<SystemBillingPlanBaseInfo, {| description?: *, displayName?: *, id?: *, isCustom?: *, isLegacy?: *, name?: *, price?: *, pdf?: * |}>,
        ...{| limitMetrics?: ?Array<?$Pick<SystemBillingPlanLimitMetricItem, {| displayName?: *, hardLimit?: *, name?: *, overagePrice?: *, softLimit?: * |}>> |}
      })> |}
    }) |} |};

export type BillingMetricUsageQuotasQueryVariables = {};


export type BillingMetricUsageQuotasQuery = {| system?: ?{| billingMetricUsageQuotasList: {| items: Array<({
          ...$Pick<SystemBillingMetricUsageQuotaItem, {| value?: * |}>,
        ...{| limitMetric?: ?$Pick<SystemBillingLimitMetricItem, {| name?: *, displayName?: *, showPriority?: *, unit?: *, tooltip?: *, description?: * |}> |}
      })> |} |} |};

export type BillingMetricUsagesQueryVariables = {
  date: $ElementType<Scalars, 'DateTime'>,
};


export type BillingMetricUsagesQuery = {| system?: ?{| billingMetricUsagesList: {| items: Array<({
          ...$Pick<SystemBillingMetricUsageItem, {| value?: * |}>,
        ...{| limitMetric?: ?$Pick<SystemBillingLimitMetricItem, {| name?: * |}>, overage?: ?$Pick<SystemBillingMetricOverageItem, {| value?: *, warning?: * |}> |}
      })> |} |} |};

export type OrganizationPaymentDetailsQueryVariables = {
  organizationId: $ElementType<Scalars, 'ID'>,
};


export type OrganizationPaymentDetailsQuery = {| system?: ?{| organizationPaymentDetails?: ?$Pick<SystemPaymentDetailsResponse, {| last4?: *, expMonth?: *, expYear?: *, brand?: * |}> |} |};

export type FrontendPlanQueryVariables = {};


export type FrontendPlanQuery = {| system?: ?{| getFrontendPlanId?: ?$Pick<SystemFrontendPlanIdItem, {| id?: * |}> |} |};

export type LicenseCostQueryVariables = {
  organizationId: $ElementType<Scalars, 'ID'>,
  date: $ElementType<Scalars, 'DateTime'>,
};


export type LicenseCostQuery = {| system?: ?{| billingMetricUsageQuotasList: {| items: Array<({
          ...$Pick<SystemBillingMetricUsageQuotaItem, {| value?: * |}>,
        ...{| limitMetric?: ?$Pick<SystemBillingLimitMetricItem, {| displayName?: *, name?: *, description?: *, showPriority?: *, unit?: *, tooltip?: * |}> |}
      })> |}, billingMetricUsagesList: {| items: Array<({
          ...$Pick<SystemBillingMetricUsageItem, {| value?: * |}>,
        ...{| limitMetric?: ?$Pick<SystemBillingLimitMetricItem, {| name?: * |}>, overage?: ?$Pick<SystemBillingMetricOverageItem, {| value?: *, warning?: * |}> |}
      })> |}, billingCurrentPlan?: ?({
        ...$Pick<SystemBillingCurrentPlanResponse, {| id?: *, name?: *, price?: *, displayName?: *, trialEnd?: *, status?: *, pdf?: * |}>,
      ...{| nextPlan?: ?$Pick<SystemBillingNextPlanResponse, {| id?: *, name?: *, displayName?: * |}> |}
    }) |} |};

export type EnvironmentsListQueryVariables = {
  email?: ?$ElementType<Scalars, 'String'>,
  workspaceId: $ElementType<Scalars, 'String'>,
};


export type EnvironmentsListQuery = {| system?: ?{| getEnvironmentRoles?: ?Array<?$Pick<SystemEnvironmentRoleList, {| environmentId: *, environmentName: *, exists?: * |}>> |} |};

export type GetWorkspaceEnvironmentsRolesQueryVariables = {
  workspaceId: $ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
};


export type GetWorkspaceEnvironmentsRolesQuery = {| system?: ?{| getEnvironmentRoles?: ?Array<?({
        ...$Pick<SystemEnvironmentRoleList, {| environmentId: *, environmentName: *, exists?: * |}>,
      ...{| roles?: ?Array<?$Pick<SystemEnvironmentRoleBaseInfo, {| id: *, name: * |}>>, assignedRoles?: ?Array<?$Pick<SystemEnvironmentRoleBaseInfo, {| id: *, name: * |}>> |}
    })> |} |};

export type InboxListQueryVariables = {
  first?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  filter?: ?InboxEventsListFilter,
};


export type InboxListQuery = {| system?: ?{| inboxEventsList?: ?({
        ...$Pick<SystemInboxEventsListResponse, {| count: * |}>,
      ...{| items: Array<InboxItemFragment> |}
    }), inboxEventsListCount?: ?$Pick<SystemInboxEventsListResponse, {| count: * |}> |} |};

export type UndoneInboxCountQueryVariables = {};


export type UndoneInboxCountQuery = {| system?: ?{| undoneInboxCount?: ?$Pick<SystemInboxEventsListResponse, {| count: * |}> |} |};

export type ApiTokensListQueryVariables = {};


export type ApiTokensListQuery = {| apiTokensList: {| items: Array<({
        ...$Pick<ApiToken, {| id?: *, name?: * |}>,
      ...{| roles?: ?{| items: Array<$Pick<Role, {| id?: *, name?: * |}>> |} |}
    })> |} |};

export type ApiTokenQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type ApiTokenQuery = {| apiToken?: ?({
      ...$Pick<ApiToken, {| id?: *, name?: * |}>,
    ...{| roles?: ?{| items: Array<$Pick<Role, {| id?: *, name?: * |}>> |} |}
  }) |};

export type EnvironmentVariablesListQueryVariables = {};


export type EnvironmentVariablesListQuery = {| environmentVariablesList: {| items: Array<$Pick<EnvironmentVariable, {| id?: *, name?: *, value?: * |}>> |} |};

export type OrganizationListQueryVariables = {};


export type OrganizationListQuery = {| system?: ?{| organizationsListByUser?: ?({
        ...$Pick<SystemOrganizationsListResponse, {| count: * |}>,
      ...{| items: Array<OrganizationItemShortInfoFragment> |}
    }) |} |};

export type OrganizationQueryVariables = {
  organizationId: $ElementType<Scalars, 'String'>,
};


export type OrganizationQuery = {| system?: ?{| organizationById?: ?OrganizationItemFullInfoFragment |} |};

export type OrganizationInvitationDetailsQueryVariables = {
  invitationId: $ElementType<Scalars, 'String'>,
};


export type OrganizationInvitationDetailsQuery = {| system?: ?{| organizationInvitationById?: ?({
        ...$Pick<SystemOrganizationInvitation, {| id: *, emailFrom: *, firstNameFrom?: *, lastNameFrom?: * |}>,
      ...{| organization: $Pick<SystemOrganizationBaseItem, {| id: *, name: * |}> |}
    }) |} |};

export type RoleQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type RoleQuery = {| role?: ?({
      ...$Pick<Role, {| id?: *, name?: *, description?: * |}>,
    ...{| users?: ?{| items: Array<$Pick<User, {| id?: * |}>> |} |}
  }) |};

export type RolesListQueryVariables = {};


export type RolesListQuery = {| rolesList: {| items: Array<$Pick<Role, {| id?: *, name?: *, description?: * |}>> |} |};

export type RolesListWithUsersQueryVariables = {
  query?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?Array<?RoleOrderBy>,
};


export type RolesListWithUsersQuery = {| rolesList: {| items: Array<({
        ...$Pick<Role, {| id?: *, name?: *, description?: *, membersCount: * |}>,
      ...{| users?: ?({
          ...$Pick<UserListResponse, {| count: * |}>,
        ...{| items: Array<$Pick<User, {| id?: * |}>> |}
      }) |}
    })> |} |};

export type TableFieldsForIndexingQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type TableFieldsForIndexingQuery = {| system?: ?{| table?: ?{| fieldsForIndexing?: ?Array<?FieldForIndexingFragment> |} |} |};

export type InvitationDetailInfoFragment = ({
    ...$Pick<SystemMemberInvitation, {| id?: *, firstName?: *, lastName?: *, email?: *, isRegistered?: *, apiHost?: * |}>,
  ...{| invitedBy?: ?$Pick<SystemInvitedByName, {| email?: *, firstName?: *, lastName?: *, workspaceName?: * |}> |}
});

export type InvitationDetailsQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type InvitationDetailsQuery = {| system?: ?{| memberInvitation?: ?InvitationDetailInfoFragment |} |};

export type TeamMemberQueryVariables = {
  filter?: ?SystemEnvironmentMemberFilter,
};


export type TeamMemberQuery = {| system?: ?{| environmentMember?: ?TeamMemberFragmentFragment |} |};

export type TeamMembersListQueryVariables = {
  first?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  sort?: ?Array<SystemEnvironmentMembersListSort>,
  filter?: ?SystemEnvironmentMembersListFilter,
};


export type TeamMembersListQuery = {| system?: ?{| environmentMembersCount?: ?$Pick<SystemEnvironmentMembersListResponse, {| count: * |}>, environmentMembersList?: ?{| items: Array<TeamMemberFragmentFragment> |} |} |};

export type TransferOwnershipDetailsQueryVariables = {
  transferId: $ElementType<Scalars, 'ID'>,
};


export type TransferOwnershipDetailsQuery = {| system?: ?{| workspaceTransferInfo?: ?({
        ...$Pick<SystemWorkspaceTransferItem, {| createdAt: *, status: * |}>,
      ...{| workspace: ({
          ...$Pick<SystemWorkspaceTransferWorkspaceItem, {| workspaceId: *, name: *, region?: *, owner?: *, kind?: *, apiHost?: * |}>,
        ...{| organization?: ?$Pick<SystemOrganizationBaseItem, {| id: *, name: *, type?: *, description?: * |}> |}
      }), from: $Pick<SystemTransferParticipant, {| name: *, type: *, identity: * |}>, to: $Pick<SystemTransferParticipant, {| name: *, type: *, identity: * |}>, plans?: ?Array<?$Pick<SystemBillingPlanBaseScalarInfo, {| id?: *, name?: *, description?: *, displayName?: *, price?: * |}>>, card?: ?$Pick<SystemBillingDetailsResponse, {| last4?: *, expMonth?: *, expYear?: *, brand?: *, origin: * |}> |}
    }) |} |};

export type UsersListQueryVariables = {
  first?: ?$ElementType<Scalars, 'Int'>,
  skip?: ?$ElementType<Scalars, 'Int'>,
  sort?: ?Array<UserSort>,
  filter?: ?UserFilter,
};


export type UsersListQuery = {| usersList: ({
      ...$Pick<UserListResponse, {| count: * |}>,
    ...{| items: Array<UserFragmentFragment> |}
  }) |};

export type UsersEditFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type UsersEditFormQuery = {| user?: ?({
      ...{| roles?: ?{| items: Array<$Pick<Role, {| id?: * |}>> |} |},
    ...UserFragmentFragment
  }) |};

export type UsersStatisticsQueryVariables = {
  newUsersDt: $ElementType<Scalars, 'DateTime'>,
  newUsersWeekDt: $ElementType<Scalars, 'DateTime'>,
};


export type UsersStatisticsQuery = {| totalAppUsers: $Pick<UserListResponse, {| count: * |}>, appUsersActive: $Pick<UserListResponse, {| count: * |}>, appUsersInactive: $Pick<UserListResponse, {| count: * |}>, newAppUsers: $Pick<UserListResponse, {| count: * |}>, newWeekAppUsers: $Pick<UserListResponse, {| count: * |}> |};

export type UserAccountInfoQueryVariables = {};


export type UserAccountInfoQuery = {| system?: ?{| memberAccount?: ?UserAccountInfoFragmentFragment |} |};

export type WorkspacesListQueryVariables = {};


export type WorkspacesListQuery = {| system?: ?{| workspacesList?: ?({
        ...$Pick<SystemWorkspaceListResponse, {| count: * |}>,
      ...{| items: Array<WorkspaceItemFragment> |}
    }), workspacesFrontendList?: ?({
        ...$Pick<SystemWorkspaceListResponse, {| count: * |}>,
      ...{| items: Array<WorkspaceItemFragment> |}
    }) |} |};

export type CheckWorkspaceCreationQueryVariables = {
  workspaceId: $ElementType<Scalars, 'String'>,
};


export type CheckWorkspaceCreationQuery = {| asyncSessionStatus?: ?$Pick<AsyncSessionStatusResponse, {| status: *, message?: * |}> |};

export type AppServicesRoleEditFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AppServicesRoleEditFormQuery = {| role?: ?({
      ...$Pick<Role, {| id?: *, name?: *, description?: * |}>,
    ...{| users?: ?{| items: Array<$Pick<User, {| id?: * |}>> |} |}
  }) |};

export type AppServicesRolesListWithUsersQueryVariables = {
  query?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?Array<?RoleOrderBy>,
};


export type AppServicesRolesListWithUsersQuery = {| rolesList: {| items: Array<({
        ...$Pick<Role, {| id?: *, name?: *, description?: * |}>,
      ...{| users?: ?({
          ...$Pick<UserListResponse, {| count: * |}>,
        ...{| items: Array<$Pick<User, {| id?: * |}>> |}
      }) |}
    })> |} |};

export type AppServicesUsersListQueryVariables = {};


export type AppServicesUsersListQuery = {| usersList: {| items: Array<$Pick<User, {| id?: *, firstName?: *, lastName?: *, email?: * |}>> |} |};

export type RoleWithCustomPermissionsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type RoleWithCustomPermissionsQuery = {| role?: ?({
      ...$Pick<Role, {| id?: * |}>,
    ...{| permissions?: ?{| items: Array<$Pick<Permission, {| resource?: *, permission?: * |}>> |} |}
  }) |};

export type FunctionsListQueryVariables = {
  filterName?: ?$ElementType<Scalars, 'String'>,
};


export type FunctionsListQuery = {| functionsList?: ?{| items: Array<({
        ...$Pick<FunctionWebhookInfo, {| name: *, functionType: *, description?: * |}>,
      ...{| application?: ?$Pick<Application, {| id: *, name: *, displayName?: * |}> |},
      ...FunctionWebhookInfoFragment
    }) | ({
        ...$Pick<FunctionTriggerInfo, {| name: *, functionType: *, description?: * |}>,
      ...{| application?: ?$Pick<Application, {| id: *, name: *, displayName?: * |}> |},
      ...FunctionTriggerInfoFragment
    }) | ({
        ...$Pick<FunctionResolverInfo, {| name: *, functionType: *, description?: * |}>,
      ...{| application?: ?$Pick<Application, {| id: *, name: *, displayName?: * |}> |},
      ...FunctionResolverInfoFragment
    }) | ({
        ...$Pick<FunctionTaskInfo, {| name: *, functionType: *, description?: * |}>,
      ...{| application?: ?$Pick<Application, {| id: *, name: *, displayName?: * |}> |},
      ...FunctionTaskInfoFragment
    })> |} |};

export type FunctionWebhookInfoFragment = $Pick<FunctionWebhookInfo, {| httpMethod: *, workspaceRelativePath: *, workspaceFullPath: * |}>;

export type FunctionTriggerInfoFragment = $Pick<FunctionTriggerInfo, {| operation: *, tableName: *, type: * |}>;

export type FunctionResolverInfoFragment = $Pick<FunctionResolverInfo, {| gqlType: * |}>;

export type FunctionTaskInfoFragment = $Pick<FunctionTaskInfo, {| scheduleExpression?: * |}>;

export type LogsQueryVariables = {
  functionName: $ElementType<Scalars, 'String'>,
  applicationId?: ?$ElementType<Scalars, 'String'>,
};


export type LogsQuery = {| system?: ?{| logsList?: ?Array<?$Pick<SystemCloudLogsEntry, {| message?: *, timestamp?: * |}>> |} |};
