import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getUserAccountInfo = (userAccountInfo) => userAccountInfo || {};

export const getId = createSelector(getUserAccountInfo, R.prop('id'));
export const getUserCreatedAt = createSelector(getUserAccountInfo, R.prop('createdAt'));
export const getFirstName = createSelector(getUserAccountInfo, R.prop('firstName'));
export const getLastName = createSelector(getUserAccountInfo, R.prop('lastName'));
export const getEmail = createSelector(getUserAccountInfo, R.prop('email'));
export const getAvatar = createSelector(getUserAccountInfo, R.prop('avatar'));
export const getLearningMode = createSelector(getUserAccountInfo, R.prop('learningMode'));
export const getPermissions = createSelector(getUserAccountInfo, R.prop('permissions'));
export const getRole = createSelector(getUserAccountInfo, R.prop('role'));
export const getIsDeveloper = createSelector(getUserAccountInfo, R.prop('isDeveloper'));
export const getType = createSelector(getUserAccountInfo, R.prop('type'));
export const getCompanyName = createSelector(getUserAccountInfo, R.prop('companyName'));
export const getProjectDescription = createSelector(getUserAccountInfo, R.prop('projectDescription'));


export const getFullName = createSelector(
  getFirstName,
  getLastName,
  (firstName = '', lastName = '') => `${firstName} ${lastName}`,
);


export const getAvatarUrl = createSelector(
  getAvatar,
  R.prop('downloadUrl'),
);


export const getIsBasicInformationStepCompleted = createSelector(
  getUserAccountInfo,
  userAccountInfo =>
    !!userAccountInfo.firstName &&
    !!userAccountInfo.lastName,
);

export const getIsAdditionalInformationStepCompleted = createSelector(
  getUserAccountInfo,
  userAccountInfo =>
    !R.isNil(userAccountInfo.isDeveloper) &&
    !!userAccountInfo.role &&
    !!userAccountInfo.intendedUse,
);

export const getIsAllOnboardingStepsCompleted = createSelector(
  getIsBasicInformationStepCompleted,
  getIsAdditionalInformationStepCompleted,
  getUserCreatedAt,
  (isFirstStepCompeted, isSecondStepCompeted) => {

    return (isFirstStepCompeted && isSecondStepCompeted);
  },
);

export const getIsNoOneOnboardingStepCompleted = createSelector(
  getIsBasicInformationStepCompleted,
  getIsAdditionalInformationStepCompleted,
  (isFirstStepCompeted, isSecondStepCompeted) => !isFirstStepCompeted && !isSecondStepCompeted,
);
