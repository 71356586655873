//@flow
// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Dialog, Button, useModal, Column, Text } from '@8base/boost';
import { useMutation } from 'react-apollo';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { i18n } from 'i18n';
import { Trans } from 'utils/translate';
import { PlanCard } from 'common/PlanCard';
import { BILLING_PLAN_UPDATE_MUTATION } from 'graphql/mutations';
import { BILLING_CURRENT_PLAN_QUERY } from 'graphql/queries';
import { sendGoogleHit } from 'utils/sendGoogleHit';
// $FlowFixMe waiting for update Flow
import { useHistory } from 'react-router-dom';

import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';

const BILLING_CANCEL_DOWNGRADE_DIALOG_ID = 'BILLING_CANCEL_DOWNGRADE_DIALOG_ID';

const BillingCancelDowngradeDialog = () => {
  const { closeModal, args = {}} = useModal(BILLING_CANCEL_DOWNGRADE_DIALOG_ID);
  const history = useHistory();
  const plan = { ...args.plan };
  const projectID = args.project ? args.project.id : '';

  const [planUpdate, { loading }] = useMutation(BILLING_PLAN_UPDATE_MUTATION, {
    update: (cache, { data }) => {

      cache.writeQuery({ query: BILLING_CURRENT_PLAN_QUERY, data: {
        billingCurrentPlan: data.billingPlanUpdate,
      }});
    },
    onCompleted: (data) => {
      if (!!data && !!data.billingPlanUpdate) {
        sendGoogleHit({
          planId: data.billingPlanUpdate.id,
          revenueAmount: data.billingPlanUpdate.price,
        });
      }
    },
  });

  const { refetchPlansData } = useBillingInfo();

  const onClose = useCallback(() => {
    closeModal(BILLING_CANCEL_DOWNGRADE_DIALOG_ID);
  }, [closeModal]);

  const onContinue = useCallback(async () => {
    await planUpdate({ variables: { data: { planId: R.prop('id', plan), projectID }}});
    onClose();

    await refetchPlansData();

    history.go(0);
    toast.success(i18n.t('dialogs.cancelDowngrade.successNotification'));
  }, [planUpdate, plan, projectID, onClose, refetchPlansData, history]);

  const nextPaymentDt = DateTime.local().plus({ months: 1 }).set({ day: 1 }).toFormat('MM/dd/yyyy');
  const planPrice = R.prop('price', plan);

  return (
    <Dialog id={ BILLING_CANCEL_DOWNGRADE_DIALOG_ID } onClose={ onClose } size="lg" { ...E2E.$props('dialogs.cancelDowngrade.body') }>
      <Dialog.Header title={ i18n.t('dialogs.cancelDowngrade.title') } onClose={ onClose } />
      <Dialog.Body scrollable>
        <Column gap="lg">
          <Text>
            <Trans i18nKey="dialogs.cancelDowngrade.note">
              Are you sure you want to keep your current plan?
              You will no longer be downgrading your plan and will be charged for the following at the end of the billing cycle.
            </Trans>
          </Text>
          <PlanCard plan={ plan } status={ R.prop('status', plan) } />
          <Text color="SECONDARY_TEXT_COLOR" { ...E2E.$props('dialogs.cancelDowngrade.paymentDate') }>
            <Trans i18nKey="dialogs.cancelDowngrade.chargeNote" planPrice={ planPrice } nextPaymentDt={ nextPaymentDt }>
             You will be charged <Text weight="semibold">${{ planPrice }} + overages</Text> on <Text weight="semibold">{{ nextPaymentDt }}</Text>.
            </Trans>
          </Text>
        </Column>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          type="button"
          color="neutral"
          variant="outlined"
          onClick={ onClose }
        >
          No, Do not cancel
        </Button>
        <Button type="submit" onClick={ onContinue } loading={ loading }>
          Cancel Downgrade
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

BillingCancelDowngradeDialog.ID = BILLING_CANCEL_DOWNGRADE_DIALOG_ID;

export { BillingCancelDowngradeDialog };
