// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
import { Row, Column, Text, Link } from '@8base/boost';
import styled from '@emotion/styled';
// $FlowFixMe waiting for update Flow
import { getClientUrl } from 'utils/processEnv';
import { APP_URL, useBuildUrl } from 'common/routing';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';
import { getDisplayWorkspaceKind } from 'utils';

const WorkspaceImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;

  width: 32px;
  height: 32px;
  margin-right: 18px;
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
  border-radius: 5px;
`;

type WorkspaceCardProps = {
  image?: any,
  kind: string,
  workspaceName: string,
  workspaceId?: string,
  onClose?: () => void,
};

export const WorkspaceCard = ({ image, kind, workspaceName, workspaceId, onClose }: WorkspaceCardProps) => {
  const buildUrl = useBuildUrl();
  const workspaceKind = useMemo(() => getDisplayWorkspaceKind(kind), [kind]);
  const clientUrl = getClientUrl();
  const url = buildUrl(APP_URL.projectHome, {
    pathParams: { projectId: workspaceId },
    ignoreWorkspaceId: true,
  });
  const workspacePath = `${clientUrl}${url}`;

  return (
    <Row alignItems="center">
      <WorkspaceImage src={ (image && image.downloadUrl) || workspaceDefaultLogo } />
      <Column gap="none" css={{ overflow: 'hidden' }}>
        <Link
          href={ workspacePath }
          component="span"
          css={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{ workspaceName }
        </Link>
        <Text kind="small-1" color="GRAY_50">
          { workspaceKind }
        </Text>
      </Column>
    </Row>);
};
