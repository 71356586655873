// @flow
// $FlowFixMe
import React from 'react';
// $FlowFixMe
import { Row, Column } from '@8base/boost';
import { Trans } from 'utils/translate';
import styled from '@emotion/styled';
import { useAuth } from '@8base-react/auth';

import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { ButtonToggleTab } from 'common/components';
import { checkIs8baseUser } from 'utils/help';

const RowTabs = styled(Row)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_30};
`;

const BetaTeg = styled.div`
  background: linear-gradient(80.58deg, #F42E18 -33.85%, #5C92FF 118.56%);
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  color: #FFFFFF;
  padding: 0 5px;
`;

type DeveloperHomeWorkspacesTabsProps = {
  countProjectWorkspaces?: number;
  countBackendWorkspaces: number;
  countFrontendWorkspaces: number | string;
  countProjectWorkspaces?: number | string,
  backendWorkspaces: React$Node;
  frontendWorkspaces: React$Node;
  projectWorkspaces?: React$Node,
  showFrontendWorkspaces?: boolean;
  showProjectWorkspaces?: boolean;
  activeTab?: $Values<typeof WORKSPACE_KIND>;
  onToggleTab?: (kind: $Values<typeof WORKSPACE_KIND>) => () => void;
}

export const DeveloperHomeWorkspacesTabs = ({
  countBackendWorkspaces,
  countFrontendWorkspaces,
  countProjectWorkspaces,
  backendWorkspaces,
  frontendWorkspaces,
  projectWorkspaces,
  showFrontendWorkspaces = false,
  showProjectWorkspaces = true,
  activeTab = WORKSPACE_KIND.general,
  onToggleTab,
}: DeveloperHomeWorkspacesTabsProps) => {
  const { authState } = useAuth();

  const isBackendTab = activeTab === WORKSPACE_KIND.general;
  const isFrontendTab = activeTab === WORKSPACE_KIND.frontend;
  const isProjectTab = activeTab === WORKSPACE_KIND.project;

  return (
    <Column gap="xl">
      <RowTabs gap="xl">
        <ButtonToggleTab
          iconName="Backend"
          isActive={ isBackendTab }
          count={ countBackendWorkspaces }
          onClick={ onToggleTab && onToggleTab(WORKSPACE_KIND.general) }
          tagColor="GRAY"
        >
          <Trans i18nKey="developerHome.workspaces.toggleButtonBackendWorkspaces">
            Backend
          </Trans>
        </ButtonToggleTab>
        <If condition={ showFrontendWorkspaces } >
          <ButtonToggleTab
            iconName="Frontend"
            isActive={ isFrontendTab }
            hideCount
            count={ countFrontendWorkspaces }
            onClick={ onToggleTab && onToggleTab(WORKSPACE_KIND.frontend) }
            tagColor={ checkIs8baseUser(authState) ? 'GRAY' : 'BLUE' }
          >
            <Row alignItems="center">
              <div>
                <Trans i18nKey="developerHome.workspaces.toggleButtonFrontendWorkspaces">
                  Frontend
                </Trans>
              </div>
              <BetaTeg>BETA</BetaTeg>
            </Row>
          </ButtonToggleTab>
        </If>
        <If condition={ showProjectWorkspaces }>
          <ButtonToggleTab
            iconName="Project"
            isActive={ isProjectTab }
            hideCount
            count={ countProjectWorkspaces }
            onClick={ onToggleTab && onToggleTab(WORKSPACE_KIND.project) }
            tagColor={ checkIs8baseUser(authState) ? 'GRAY' : 'BLUE' }
          >
            <Row alignItems="center">
              <div>
                <Trans i18nKey="developerHome.workspaces.toggleButtonProjectWorkspaces">
                  Project
                </Trans>
              </div>
              <BetaTeg>BETA</BetaTeg>
            </Row>
          </ButtonToggleTab>
        </If>

      </RowTabs>


      <If condition={ isBackendTab } >
        { backendWorkspaces }
      </If>

      <If condition={ isFrontendTab && showFrontendWorkspaces } >
        { frontendWorkspaces }
      </If>

      <If condition={ isProjectTab && showProjectWorkspaces }>
        { projectWorkspaces }
      </If>

    </Column>
  );
};
