// @flow

import _ from 'lodash';
import fp from 'lodash/fp';
import fpUtils from 'utils/fp';
import { RELATION_TYPES } from 'store/dataBuilder/dataBuilder.constants';

export type OptionsSelectorType = Array<{value: mixed, label: string}>;

export const withPrefix = (prefix?: string = '') => (value: string) =>
  fp.isEmpty(prefix) ? value : `${prefix}${value}`;

/**
 * transform simple array to react-select options with key as value
 * @prop {*} data array with options strings
 * @prop {*} prefixValue string which can be added to the value as a prefix
 */
export const arrayToSelector = (data: Array<string>): OptionsSelectorType =>
  fp.map(value => ({ value, label: value }), data);

/** transform simple object to react-select options with value as label */
export const objectToSelector = (data: Object): OptionsSelectorType => fp.pipe(
  fp.keys,
  fp.map(key => ({ value: key, label: data[key].toString() })),
)(data);

/** transform simple object to react-select options with key as label */
export const objectToSelectorWithKeyAsLabel = (data: Object): OptionsSelectorType => fp.pipe(
  fp.keys,
  fp.map(key => ({ value: data[key], label: key })),
)(data);

export const formatArrayToSelector = (data: Array<Object>, name: string = 'name') => {
  return _.map(data, value => {
    return _.mapKeys(value, (val, key) => {
      if (key === 'id') return 'value';
      if (key === name) return 'label';
      return key;
    });
  });
};

type FormatArrayToSelectorCurryType = {
  (labelName: string, valueName: string): (arr: Array<*>) => Array<*>,
  (labelName: string, valueName: string, arr: Array<*>): Array<*>,
}

/** sa formatArrayToSelector but curried */
export const formatArrayToSelectorCurry: FormatArrayToSelectorCurryType = fp.curry(
  (labelName: string, valueName: string, arr: Array<*>): Array<*> => fp.map(
    fp.pipe(
      fpUtils.renameKey(labelName, 'label'),
      fpUtils.renameKey(valueName, 'value'),
    ), arr),
);

export const omitDeep = (collection: Object, keysArray?: Array<string> | string = ['__typename', '_description']) => {
  const newCollection = _.cloneDeep(collection);
  return _.cloneDeepWith(newCollection, (value: any) => {
    if (!_.isObject(value)) return;
    _.forEach(_.flattenDeep([keysArray]), key => {
      _.unset(value, key);
    });
  });
};

type OmitDeepCurryType = {
  (keys?: string | string[]): (collection: Object) => Object,
  (keys: ?(string | string[]), collection: Object): Object,
}

export const omitDeepCurry: OmitDeepCurryType = fp.curry(
  (keys: *, collection: *) => omitDeep(collection, keys),
);

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr: any = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const formatRelationData = (isList: boolean, refFieldIsList: boolean) => {
  if (isList && refFieldIsList) {
    return RELATION_TYPES.manyToMany;
  }

  if (refFieldIsList && !isList) {
    return RELATION_TYPES.manyToOne;
  }

  if (!isList && !refFieldIsList) {
    return RELATION_TYPES.oneToOne;
  }

  return RELATION_TYPES.oneToOne;
};
