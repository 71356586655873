/* eslint-disable react-hooks/rules-of-hooks */
// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';
import { useApiHost } from 'providers';
import { HIDE_TOAST_ERROR_MESSAGE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { PROJECT_FRONTEND_QUERY } from 'graphql/queries';

export const useProjectFrontend = (projectId: string, apiHost?: string, options?: QueryHookOptions) => {

  const { isAuthorized, isEmailVerified } = useAuth();
  const apiHostProvider = useApiHost();
  apiHost = apiHost === undefined ? apiHostProvider.apiHost : apiHost;

  const { data, ...rest }: QueryResult<any, any> = useQuery(PROJECT_FRONTEND_QUERY, {
    skip: !projectId || !isAuthorized || !isEmailVerified,
    fetchPolicy: 'cache-and-network',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [ENDPOINT_URI]: apiHost,
    },
    ...options,
    variables: {
      ...options,
      projectId,
    },
  });

  const projectFrontend: any | null = (data && data.system && data.system.projectFrontend) || null;
  return {
    projectFrontend, ...rest,
  };
};
