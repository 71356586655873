import React from 'react';
import memoize from 'lodash/memoize';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { withRouter, matchPath } from 'react-router-dom';
import { Grid, Breadcrumbs, Link } from '@8base/boost';
import { TableSchemaContext } from '@8base-react/table-schema-provider';
import { css } from '@emotion/core';

import { APP_URL } from 'common/routing';
import { Link as RouterLink } from 'common/router';
import { apolloSelectors } from 'graphql/selectors';
import { Trans } from 'utils/translate';

type AppFlowBreadcrumbsProps = {
  location: Object,
};

const ROLE_QUERY = gql`
  query RoleName($id: ID!) {
    role(id: $id) {
      name
    }
  }
`;

const createDataPropertyRenderer = memoize((selector: (Object) => string) => ({ data, loading }) => loading ? '...' : selector(data));

const BREADCRUMBS_ROUTES = [
  {
    path: APP_URL.workspaceHome,
    component: () => <Trans i18nKey="mainSections.home">Home</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.functions,
    component: () => <Trans i18nKey="mainSections.functions">Functions</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.users,
    component: () => <Trans i18nKey="settings.breadcrumbs.appUsers">User Management</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settingsTeam,
    component: () => <Trans i18nKey="settings.breadcrumbs.team">Team</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.integrations,
    component: () => <Trans i18nKey="settings.sections.integrations">Integrations</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServices,
    component: () => <Trans i18nKey="mainSections.appServices">App Services</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settings,
    component: () => <Trans i18nKey="mainSections.settings">Settings</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settingsGeneral,
    component: () => <Trans i18nKey="settings.sections.general">General</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesRoles,
    component: () => <Trans i18nKey="settings.sections.roles">Roles</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesRolesEdit,
    component: ({ match }) => (
      <Query query={ ROLE_QUERY } variables={{ id: match.params.roleId }}>
        { createDataPropertyRenderer(apolloSelectors.role.getName) }
      </Query>
    ),
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesRolesEditData,
    component: () => <Trans i18nKey="settings.breadcrumbs.data">Data</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesRolesEditApps,
    component: () => <Trans i18nKey="settings.breadcrumbs.apps">Apps</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesRolesEditUsers,
    component: () => <Trans i18nKey="settings.breadcrumbs.users">Users</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesRolesEditTeam,
    component: () => <Trans i18nKey="settings.breadcrumbs.team">Team</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settingsLocalization,
    component: () => <Trans i18nKey="settings.sections.localization">Localization</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settingsEnvironmentVariables,
    component: () => <Trans i18nKey="settings.sections.environmentVariables">Environment Variables</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesAuthentication,
    component: () => <Trans i18nKey="settings.sections.authentication">Authentication</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.appServicesStorageService,
    component: () => <Trans i18nKey="settings.sections.storageservice">Storage Service</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settingsApiTokens,
    component: () => <Trans i18nKey="settings.sections.apiTokens">API Tokens</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.settingsFileConfiguration,
    component: () => <Trans i18nKey="settings.sections.fileConfiguration">File Upload Configuration</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.apiExplorer,
    component: () => <Trans i18nKey="dataBuilder.breadcrumbs.apiExplorer">API Explorer</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.apiExplorerGraph,
    component: () => <Trans i18nKey="dataBuilder.breadcrumbs.apiExplorerGraph">Interactive Graph</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.dataRoot,
    component: () => <Trans i18nKey="dataBuilder.breadcrumbs.data">Data</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.dataTable,
    component: ({ match }) => (
      <TableSchemaContext.Consumer>
        { ({ tablesList }) => {

          if (match.params.tableId) {
            const table = R.find(R.propEq('id', match.params.tableId), tablesList);

            if (table) {
              return table.displayName;
            }
          }

          return null;
        } }
      </TableSchemaContext.Consumer>
    ),
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.dataBuilder,
    component: () => <Trans i18nKey="dataBuilder.breadcrumbs.schema">Schema</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.dataViewer,
    component: () => <Trans i18nKey="dataBuilder.breadcrumbs.data">Data</Trans>,
    matchOptions: { exact: true },
  },
  {
    path: APP_URL.dataSettings,
    component: () => <Trans i18nKey="dataBuilder.breadcrumbs.settings">Settings</Trans>,
    matchOptions: { exact: true },
  },
];

const AppFlowBreadcrumbsItemLink = (props) => <Link tagName={ RouterLink } color="PRIMARY_TEXT_COLOR" { ...props } />;

let AppFlowBreadcrumbs = ({ location, className }: AppFlowBreadcrumbsProps) => (
  <Grid.Box css={ className } area="breadcrumbs">
    <Breadcrumbs
      css={ css`padding-left: 24px; align-items: center; display: flex; background-color: #fff; box-shadow: 0 2px 4px 0 rgba(163,163,163,0.33); height: 32px; z-index: 90;` }
      pathname={ location.pathname }
      routes={ BREADCRUMBS_ROUTES }
      matchPath={ matchPath }
      itemTagName={ AppFlowBreadcrumbsItemLink }
    />
  </Grid.Box>
);

AppFlowBreadcrumbs = withRouter(AppFlowBreadcrumbs);

export { AppFlowBreadcrumbs };
