// @flow
// $FlowFixMe waiting for update Flow
import React, { useState, useEffect } from 'react';
import { Grid, Navigation } from '@8base/boost';
import { css } from '@emotion/core';

import { APP_URL, useBuildUrl } from 'common/routing';

import logo from 'images/8base-logo-white.svg';
import logoEmblem from 'images/8base-emblem.svg';
import { useWorkspace } from '../../providers/WorkspaceProvider';
// $FlowFixMe waiting for update Flow
import { useHistory } from 'react-router-dom';

type AppFlowNavProps = {
  children: ?React$Node,
};

const Logo = ({ expanded }) => {
  const { project } = useWorkspace();
  const buildUrl = useBuildUrl();
  const history = useHistory();
  const projectId = project?.id;

  const logoClicked = (location:string) => {
    history.replace(location);
  };

  return (
    <div css={ css`cursor: pointer;` } onClick={ () => logoClicked(buildUrl(APP_URL.projectDashboard, { pathParams: { projectId }})) }>

      <div css={ css`height: 56px; background-color: #3c4751; display: flex; align-items: center; justify-content: center; position: relative;` }>
        <a href={ buildUrl(APP_URL.projectDashboard, { pathParams: { projectId }}) } >
          <img css={{ opacity: expanded ? 0 : 1, transition: 'opacity 0.15s', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} src={ logoEmblem } alt="" { ...E2E.$props('root.header.logo') } />
        </a>
        <a href={ buildUrl(APP_URL.projectDashboard, { pathParams: { projectId }}) }>
          <img css={{ opacity: expanded ? 1 : 0, minWidth: 96, transition: 'opacity 0.15s', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} src={ logo } alt="" { ...E2E.$props('root.header.logo') } />
        </a>

      </div>
    </div>
  );
};

const AppFlowNav = ({ children, ...rest }: AppFlowNavProps) => {
  const [expanded, setExpanded] = useState(localStorage.getItem('nav') !== 'false');

  useEffect(() => {
    window.addEventListener('storage', () => {
      setExpanded(localStorage.getItem('nav') !== 'false');
    });
  });

  return (
    <Grid.Box area="nav" style={{ overflow: 'hidden' }}>
      <Navigation expanded={ expanded } expandedWidth="196px" { ...rest } >
        <Logo expanded={ expanded } />
        { children }
      </Navigation>
    </Grid.Box>
  );
};

export { AppFlowNav };

