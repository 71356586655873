// @flow

import React from 'react';
import { Field, Form } from '@8base-react/forms';
import {
  Button,
  Dialog,
  Grid,
  InputField,
  SelectField,
  useModal,
} from '@8base/boost';
import { email, required, requiredArray } from '../utils/formValidations';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import {
  DIALOG_ID,
  FORCE_TOAST_ERROR_MESSAGE, TOAST_ERROR_MESSAGE_FORMATTER,
  TOAST_SUCCESS_MESSAGE, WORKSPACE_ID, ENDPOINT_URI,
} from '../common/constants/apolloOperationContextOptions';
import { checkIs8baseUser } from '../utils/help';
import i18n from 'i18next';
import { INVITE_MEMBERS_MUTATION, TEAM_MEMBER_UPDATE_MUTATION } from '../graphql/mutations';

const PROJECT_TEAM_MEMBER_INVITE_DIALOG = 'PROJECT_TEAM_MEMBER_INVITE_DIALOG';

const { Header, Body, Footer } = Dialog;


const isSingleRole = false;

const ProjectTeamMemberInviteDialog = () => {
  const {
    closeModal,
    args: { backendWorkspaceId, refetch, roles, initialValues, isEdit = false, apiHost },
  } = useModal(PROJECT_TEAM_MEMBER_INVITE_DIALOG);

  const roleOptions = (roles || []).map(
    (item) => {
      return { value: item.id, label: item.name };
    },
  );

  const { t } = useTranslation();

  const onClose = () => closeModal(PROJECT_TEAM_MEMBER_INVITE_DIALOG);

  const [editInvitation] = useMutation(TEAM_MEMBER_UPDATE_MUTATION, {
    notifyOnNetworkStatusChange: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('inviteUserDialog.success'),
      [FORCE_TOAST_ERROR_MESSAGE]: true,
      [WORKSPACE_ID]: backendWorkspaceId,
      [ENDPOINT_URI]: apiHost,
      [DIALOG_ID]: PROJECT_TEAM_MEMBER_INVITE_DIALOG,
    },
  });


  const [inviteUser] = useMutation(INVITE_MEMBERS_MUTATION, {
    notifyOnNetworkStatusChange: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('inviteUserDialog.success'),
      [FORCE_TOAST_ERROR_MESSAGE]: true,
      [WORKSPACE_ID]: backendWorkspaceId,
      [ENDPOINT_URI]: apiHost,
      // this message shows only if there is no nextPlan on error response
      [TOAST_ERROR_MESSAGE_FORMATTER]: error => error.code === 'BillingPlanLimitError' ? 'Need to add more members? please contact Support' : error,
      [DIALOG_ID]: PROJECT_TEAM_MEMBER_INVITE_DIALOG,
    },
  });

  const onSubmit = async (data) => {
    // TODO MAF Implement invitation for workspaces
    const force = checkIs8baseUser({ email: data.email });

    isEdit
      ? await editInvitation({ variables: { data: { roles: data.roles }, filter: { email: data?.email }}})
      : await inviteUser({ variables: { data: { recipients: { ...data }}, force }});

    refetch();

    window.trackEvent('Project Team Members', 'Invite member');

    onClose();
  };

  return (
    <Dialog id={ PROJECT_TEAM_MEMBER_INVITE_DIALOG } size="sm" onClose={ onClose }>
      <Form onSubmit={ (data) => onSubmit(data) } initialValues={ initialValues }>
        { ({ handleSubmit, submitting }) => (
          <form onSubmit={ handleSubmit }>
            <input type="hidden" name="invitationId" />
            <Header title={ isEdit ? t('dialogs.teamMemberEdit.title') : t('inviteUserDialog.title') } onClose={ onClose } />
            <Body>
              <Grid.Layout
                columns="1fr"
                autoRows="auto"
                gap="lg"
                areas={ [
                  ['email', 'email'],
                  ['roles', 'roles'],
                ] }
              >
                <Grid.Box area="email">
                  <Field
                    name="email"
                    label={ t('shared.emailAddress') }
                    placeholder={ t('shared.email') }
                    component={ InputField }
                    validate={ (...args) => required(...args) || email(...args) }
                    disabled={ submitting || isEdit }
                  />
                </Grid.Box>
                <Grid.Box area="roles">
                  <Field
                    name="roles"
                    label={ t('shared.roles') }
                    placeholder={ t('shared.chooseRoles') }
                    component={ SelectField }
                    options={ roleOptions }
                    validate={ requiredArray }
                    multiple={ !isSingleRole }
                    disabled={ submitting }
                  />
                </Grid.Box>
              </Grid.Layout>
            </Body>
            <Footer>
              <Button
                type="button"
                color="neutral"
                variant="outlined"
                disabled={ submitting }
                onClick={ onClose }
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={ false }
                loading={ submitting }
                onClick={ handleSubmit }
              >
                Send Invite
              </Button>
            </Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

ProjectTeamMemberInviteDialog.ID = PROJECT_TEAM_MEMBER_INVITE_DIALOG;

export { ProjectTeamMemberInviteDialog };
