import * as React from 'react';

import appStateReducer from './reducer';
import * as types from './types';

const AppStateContext = React.createContext();

const initialState = {
  workspaceCreated: null,
  auth: {
    isEmailVerified: true,
  },
};

function AppStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(appStateReducer, initialState);
  const value = { state, dispatch };

  return <AppStateContext.Provider value={ value }>{ children }</AppStateContext.Provider>;
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}

export { AppStateProvider, useAppState, types };
