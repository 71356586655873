import { CALLBACK_URL } from '../../common/constants/localStorageKeys';
import { useAuth } from '@8base-react/auth';
import * as Sentry from '@sentry/browser';
import { logger } from '../logger';

export const useCallbackUrl = () => {
  const { authClient } = useAuth();

  const getAuthData = async () => {
    try {
      return await authClient.getState();
    } catch (error) {
      Sentry.captureException(error);
      logger.error(error);
      authClient.authorize();
      return null;
    }
  };

  const captureCallbackParam = (location) => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const callbackUrl = urlParams.get('callback');
    return callbackUrl;
  };

  const transformToStandardURL = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const standardizedUrl = new URL(decodedUrl).href;
    return standardizedUrl;
  };

  const validateURL = (url) => {
    const allowedDomains = ['8base.com', '8basedev.com', 'localhost', 'archie.studio', 'archie-ai.com'];
    const urlHostname = new URL(url).hostname;

    return allowedDomains.some(domain => urlHostname.endsWith(domain));
  };

  const setCallback = (location) => {
    const callbackParam = captureCallbackParam(location);
    if (!callbackParam) { return; }

    const standardizedUrl = transformToStandardURL(callbackParam);

    if (!validateURL(standardizedUrl)) { return; }

    sessionStorage.setItem(CALLBACK_URL, standardizedUrl);
  };

  const callbackWithAuth = async () => {
    const callback = sessionStorage.getItem(CALLBACK_URL);

    const { token: idToken } = await getAuthData();

    if (callback && idToken) {
      sessionStorage.removeItem(CALLBACK_URL);

      const newUrl = new URL(callback);
      const urlParams = new URLSearchParams(newUrl.search);

      urlParams.append('idToken', idToken);

      newUrl.search = urlParams.toString();

      window.location.replace(newUrl.href);
    }
  };

  const simpleCallback = async () => {
    const localCallback = sessionStorage.getItem(CALLBACK_URL);
    const { token: idToken } = await getAuthData();
    if (localCallback && idToken) {
      sessionStorage.removeItem(CALLBACK_URL);
      const newUrl = new URL(localCallback);
      window.location.replace(newUrl.href);
    }
  };

  const currentCallback = () => { return sessionStorage.getItem(CALLBACK_URL); };

  return {
    simpleCallback,
    callbackWithAuth,
    setCallback,
    currentCallback,
  };
};
