// @flow
// $FlowFixMe waiting for update Flow
import {
  Table, Text,
} from '@8base/boost';
import React from 'react';
// $FlowFixMe
import * as R from 'ramda';
// $FlowFixMe
import { css } from '@emotion/core';

import { Column } from 'common/components';
import { BankCardRow } from './BankCardRow';

import { Trans } from 'utils/translate';

export type CardDetails = {
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
  origin: string;
}

type PaymentsDetailsPropType = {
  card: CardDetails
}

/**
 * Organization payment method
 */
export const PaymentsDetails = ({ card }: PaymentsDetailsPropType) => {

  return (
    <Column stretch>
      <Choose>
        <When condition={ card && !!card.last4 }>
          <Table>
            <Table.Body>
              <Table.BodyRow columns="auto 1fr" css={ css`margin: 0; padding: 0 !important;` }>
                <Table.BodyCell css={ css`margin: 0; padding: 0 !important;` }>
                  <BankCardRow card={ card } />
                </Table.BodyCell>
                <Table.BodyCell { ...E2E.$props('settings.billing.cardNumber') } css={ css`margin: 0; padding: 0;` }>
                  <Trans i18nKey="projects.dashboard.planCard.ending">ending</Trans> { R.prop('last4', card) }
                </Table.BodyCell>
              </Table.BodyRow>
            </Table.Body>
          </Table>
        </When>
        <Otherwise>
          <Text color="LIGHT_TEXT_COLOR" css={ css`display: flex; justify-content: center; padding: 10px 0;` }>
            <Trans i18nKey="projects.dashboard.projectEmptyPaymentDetails">
              Project doesn’t have a saved payment method.
            </Trans>
          </Text>
        </Otherwise>
      </Choose>
    </Column>
  );
};
