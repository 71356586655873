// @flow
// $FlowFixMe
import { useContext } from 'react';

import { WorkspaceContext, type WorkspaceContextProps } from './WorkspaceContext';

export const useWorkspace = (options?: { workspaceId: string }): WorkspaceContextProps => {
  const { workspacesList, workspace: contextUrlWorkspace } = useContext(WorkspaceContext);

  if (options && options.workspaceId) {
    const { workspaceId } = options;

    const workspace = workspacesList.items.find(({ id }) => id === workspaceId) || {};

    // $FlowFixMe
    return {
      ...workspace,
      isOwner: process.env.REACT_APP_FORCE_OWNER === 'true' ? true : workspace.isOwner,
      isCiCdEnabled: !!workspace.isCiCdEnabled,
    };
  }

  return contextUrlWorkspace;
};
