// @flow

import { Icon, Row, Text } from '@8base/boost';
import React from 'react';
import styled from '@emotion/styled';
import { CountTag } from './CountTag';
import { css } from '@emotion/core';

type ButtonToggleTabProps = {
  count?: number | string;
  iconName?: string;
  children: React$Node;
  onClick?: () => void;
  isActive: boolean;
  tagColor?: 'BLUE' | 'RED' | 'GRAY';
  hideCount?: boolean;
}

const ButtonToggle = styled(Row)`
  position: relative;
  padding-bottom: 18px;
  color: ${({ isActive, theme }) => {
  if (isActive) return theme.COLORS.BLACK;
  return theme.COLORS.GRAY_50;
}};

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${({ isActive, theme }) => {
  if (isActive) return theme.COLORS.BLACK;
  return theme.COLORS.TRANSPARENT;
}};
  }

  &:hover {
    &:after {
      background: ${({ theme }) => theme.COLORS.GRAY_50};
    }
  }

  &:after  {
    transition: all .3s ease-in-out;
  }
`;

export const ButtonToggleTab = ({
  iconName,
  count,
  isActive = false,
  onClick,
  children,
  tagColor = 'GRAY',
  hideCount = false,
}: ButtonToggleTabProps) => {
  return (
    <ButtonToggle
      cursor="pointer"
      alignItems="center"
      isActive={ isActive }
      onClick={ onClick }
    >
      { iconName && <Icon name={ iconName } size="sm" css={ css`width: 16px; height: 16px;` } /> }
      <Text color={ isActive ? 'BLACK' : 'GRAY_50' }>
        { children }
      </Text>
      { !hideCount && (
        <CountTag color={ tagColor }>{ count }</CountTag>
      ) }
    </ButtonToggle>
  );
};
