import styled from '@emotion/styled';
import { Text } from '@8base/boost';

export const StyledCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardTitleText = styled(Text)`
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 600;
  color:  ${props => props.theme.COLORS.BLACK}
`;

export const StyledCard = styled('div')`
  font-family: Poppins,sans-serif !important;
  box-shadow: none;
  border: 1px solid #D1D7DD;
  border-radius: 8px;
  padding: 24.5px 24.5px 20px 24.5px;
`;

export const CenteredCardBody = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  align-items:center;
  justify-content:center;
`;
