// @flow

import gql from 'graphql-tag';

export const LEARNING_CENTER_QUERY = gql`
  query LearningCenterQuery {
    guidesList {
      items {
        id
        title
        href
        tags {
          items {
            id
            name
          }
        }
        priority
      }
    }
    videosList {
      items {
        id
        title
        yid
        duration
        tags {
          items {
            id
            name
          }
        }
        priority
      }
    }
    routesList {
      items {
        id
        path
        tags {
          items {
            id
            name
          }
        }
        exact
        strict
      }
    }
  }
`;
