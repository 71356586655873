// @flow

import * as R from 'ramda';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { getPathnameWithQueryString } from 'utils/url';

import { APP_URL } from './url';
import { REDIRECT_TO } from '../constants/localStorageKeys';
import { useCallbackUrl } from '../../utils/hooks/useCallbackUrl';

type PrivateRouteProps = {
  key?: string,
  path?: string,
  exact?: boolean,
  strict?: boolean,

  component?: React$ComponentType<*>,
  render?: (Object) => React$Node,
};

export const PrivateRoute = ({
  component: Component,
  render,
  ...rest
}: PrivateRouteProps) => {
  const { isAuthorized } = useAuth();
  const { setCallback, callbackWithAuth } = useCallbackUrl();

  return (
    <Route
      { ...rest }
      render={ (props) => {
        const componentToRender = R.isNil(render)
          ? Component && <Component { ...props } />
          : render && render(props);
        if (isAuthorized) {
          callbackWithAuth();
          return componentToRender;
        } else {
          localStorage.setItem(REDIRECT_TO, getPathnameWithQueryString());
          setCallback(window.location);
          return (
            <Redirect
              to={{
                pathname: APP_URL.authLogin,
                state: { from: props.location },
              }}
            />
          );
        }
      } }
    />
  );
};
