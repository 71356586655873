// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useEffect, useMemo } from 'react';
import { Dialog, Grid, Column, Text, Button, useModal } from '@8base/boost';
import { useAuth } from '@8base-react/auth';
import { Trans } from 'utils/translate';
// $FlowFixMe waiting for update Flow
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';

import { ORGANIZATION_INVITATION_ACCEPT_MUTATION, ORGANIZATION_INVITATION_CANCEL_MUTATION } from 'graphql/mutations';
import { i18n } from 'i18n';
import { IGNORE_LIMIT_METRIC_ERROR, TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { APP_URL } from 'common/routing';
import { WORKSPACES_LIST_QUERY, ORGANIZATIONS_LIST_QUERY, ORGANIZATION_INVITATIONS_DETAILS_QUERY } from 'graphql/queries';
import { useQueryParams } from 'utils/hooks';
import { toast } from 'react-toastify';

const getInviteInfo = (data: any) => {
  const invitation = data && data.system && data.system.organizationInvitationById;

  if (!invitation) {
    return { invitedByName: '', invitedByEmail: '', organizationName: '' };
  }

  const { firstNameFrom, lastNameFrom, organization } = invitation;

  const invitedByName = `${firstNameFrom || ''} ${lastNameFrom || ''}`.trim();
  const invitedByEmail = invitation.emailFrom;
  const organizationName = organization && organization.name;

  return { invitedByName, invitedByEmail, organizationName: organizationName || '' };
};

export const OrganizationAcceptInviteDialog = () => {
  const auth = useAuth();
  const [queryParams] = useQueryParams();
  const { closeModal, isOpen, openModal } = useModal('OrganizationAcceptInviteDialog');

  const invitationId = useMemo(() => queryParams.organization_invite_id, [queryParams]);
  const history = useHistory();
  const location = useLocation();

  const isOnboarding = !!matchPath(location.pathname, { path: APP_URL.onboarding });

  const { data } = useQuery(ORGANIZATION_INVITATIONS_DETAILS_QUERY, {
    skip: !invitationId || isOnboarding || !auth.isAuthorized || !auth.isEmailVerified,
    variables: {
      invitationId,
    },
  });

  const [accept, { loading: acceptLoading }] = useMutation(ORGANIZATION_INVITATION_ACCEPT_MUTATION, {
    context: {
      [IGNORE_LIMIT_METRIC_ERROR]: true,
      [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
    },
    onCompleted: () => {
      closeModal('OrganizationAcceptInviteDialog');
      history.replace(APP_URL.developerHomeProjects);
    },
    onError: (error) => {
      if (error.message.includes('already accepted')) {
        history.replace(APP_URL.developerHomeProjects);
      } else {
        toast.error(error.message);
      }
    },
    refetchQueries: [
      {
        query: ORGANIZATIONS_LIST_QUERY,
      },
      {
        query: WORKSPACES_LIST_QUERY,
      },
    ],
  });

  const [decline, { loading: declineLoading }] = useMutation(ORGANIZATION_INVITATION_CANCEL_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.declineSuccessfull'),
    },
    onClompleted: () => {
      history.replace(APP_URL.developerHomeProjects);

    },
    onError: (error) => {
      closeModal('OrganizationAcceptInviteDialog');
      toast.error(error.message);
    },
  });

  const onJoin = useCallback(async () => {
    accept({
      variables: {
        invitationId,
      },
    });
  }, [accept, invitationId]);

  const onDecline = useCallback(async () => {
    decline({
      variables: {
        invitationId,
      },
    });
  }, [decline, invitationId]);

  const { invitedByName, invitedByEmail, organizationName } = useMemo(() => getInviteInfo(data), [data]);

  useEffect(() => {
    if (!!invitationId && !!data) {
      openModal('OrganizationAcceptInviteDialog');
    }
  }, [data, invitationId, openModal]);

  return (
    <Dialog isOpen={ !!invitationId && !!data && isOpen } size="sm" padding="xl" shouldCloseOnOverlayClick={ false }>
      <Dialog.Body>
        <Column gap="xl">
          <Grid.Layout stretch columns="350px" justifyContent="center">
            <Grid.Box>
              <Text color="SECONDARY_TEXT_COLOR" align="center">
                <Trans i18nKey="home.organizationInvite.hasInvited">
                  { invitedByName } <Text weight="semibold" css={{ wordBreak: 'break-all' }}>({ invitedByEmail })</Text> has invited you to join the organization <Text weight="semibold">{ organizationName }</Text>. Join now to start collaborating.
                </Trans>
              </Text>
            </Grid.Box>
          </Grid.Layout>
          <Grid.Layout stretch columns="repeat(2, 12rem)" justifyContent="center" gap="md">
            <Grid.Box>
              <Button
                stretch
                color="neutral"
                variant="outlined"
                loading={ declineLoading }
                onClick={ onDecline }
              >
                <Trans i18nKey="shared.decline">Decline</Trans>
              </Button>
            </Grid.Box>
            <Grid.Box>
              <Button
                stretch
                color="primary"
                type="submit"
                loading={ acceptLoading }
                onClick={ onJoin }
              >
                <Trans i18nKey="shared.join">Join</Trans>
              </Button>
            </Grid.Box>
          </Grid.Layout>
        </Column>
      </Dialog.Body>
    </Dialog>
  );
};

OrganizationAcceptInviteDialog.ID = 'OrganizationAcceptInviteDialog';
