// @flow

// $FlowFixMe waiting for update Flow
import React, { useMemo } from 'react';
import { Form as FormLogic, Field } from '@8base-react/forms';
import {
  Dialog,
  Button,
  Text,
  Row,
  Column,
  useModal,
  SelectField,
} from '@8base/boost';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18n';
import * as R from 'ramda';

import { Trans } from 'utils/translate';
import { IGNORE_WORKSPACE, TOAST_SUCCESS_MESSAGE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { ORGANIZATION_QUERY } from 'graphql/queries';
import { PROJECT_SHARED_ORGANIZATION_MUTATION } from 'graphql/mutations/projects';
import { required } from 'utils/formValidations';
import { getUserRoleAtOrganization, checkManagerRole } from 'utils/organization';
import { useOrganization, useUserAccountInfo } from 'graphql/hooks';
import { ORGANIZATION_ROLE } from 'common/constants/organization';

const SHARE_ORGANIZATION_DIALOG_ID = 'SHARE_ORGANIZATION_DIALOG_ID';

/**
 * User list without access to workspace ?
 */
const getUserList = (organization: any, organizationId: string) => {
  if (!organizationId || !organization || !organization.users) {
    return [];
  }

  const getLabel = user => `${user.firstName} ${user.lastName} (${user.email})`;

  return organization && organization.users.map(el => ({ label: getLabel(el), value: el.email }));
};

/**
 * Dialog where user can add team member from organization to workspace
 */
export const ShareOrganizationDialog = () => {
  const { closeModal, args } = useModal(SHARE_ORGANIZATION_DIALOG_ID);

  const onClose = () => closeModal(SHARE_ORGANIZATION_DIALOG_ID);

  const { userAccountInfo, loading: userInfoLoading } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      [ENDPOINT_URI]: args.apiHost,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const { organization, loading: organizationLoading } = useOrganization(args.organizationId);

  const [shareWorkspaceWithEnvs] = useMutation(PROJECT_SHARED_ORGANIZATION_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('shared.dataUpdateSuccessful'),
      [ENDPOINT_URI]: args.apiHost,
    },
  });

  const userRoleAtOrganization = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  const onSubmit = async (formData) => {

    await shareWorkspaceWithEnvs({
      variables: {
        email: formData.email,
        projectId: formData.projectId,
      },
      refetchQueries: organization && organization.id ?
        [{
          query: ORGANIZATION_QUERY,
          variables: {
            organizationId: organization && organization.id,
          },
        }] : [],
    });

    window.trackEvent('Share workspace', 'Invite member');

    onClose();
  };
  const initialValues = useMemo(() => ({
    email: '',
    projectId: args.projectId,
  }), [args.projectId]);

  return (
    <Dialog
      id={ SHARE_ORGANIZATION_DIALOG_ID }
      onClose={ onClose }
      size="xl"
      { ...E2E.$props('settings.roles.editDialog.body') }
    >
      <FormLogic onSubmit={ onSubmit } initialValues={ initialValues } >
        { ({ handleSubmit, invalid, submitting, pristine, form, values }) => (
          <ShareWorkspaceDialogView
            handleSubmit={ handleSubmit }
            invalid={ invalid }
            submitting={ submitting }
            pristine={ pristine }
            form={ form }
            values={ values }
            organization={ organization }
            permissionRole={ userRoleAtOrganization }
            organizationLoading={ organizationLoading || userInfoLoading }
            onClose={ onClose }
            args={ args }
          />
        ) }
      </FormLogic>
    </Dialog>
  );
};

const ShareWorkspaceDialogView = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  permissionRole,
  form,
  values,
  organization,
  organizationLoading,
  onClose,
  args,
}) => {
  const { t } = useTranslation();
  const headerTitle = useMemo(() => {
    return t('dialogs.shareWorkspace.header',
      {
        name: organization && organization.name,
        defaultValue: 'Invite team member of the {{name}} organization',
      },
    );
  }, [t, organization]);

  const organizationUsersList = R.pathOr([], ['users'], organization);

  const invitedUserProfileData = useMemo(() =>
    organizationUsersList.find(({ email }) => email === values.email) || { firstName: '', lastName: '', email: '', workspaces: [], role: '' }
  , [organizationUsersList, values.email]);

  const invitedUserRole = useMemo(() =>
    getUserRoleAtOrganization(invitedUserProfileData, organization),
  [organization, invitedUserProfileData]);

  const isInvitedUserManager = useMemo(() => checkManagerRole(invitedUserRole), [invitedUserRole]);

  const userList = useMemo(() => {
    if (permissionRole === ORGANIZATION_ROLE.developer) {
      return [];
    }

    let list = getUserList(organization, args.organizationId);

    if (args.projectUsers) {
      const filter = (args.projectUsers || []).map(item => { return item.email; });
      list = list.filter(item => !filter.includes(item.value));
    }

    if (permissionRole === ORGANIZATION_ROLE.manager) {
      return list.filter(el => el.role === ORGANIZATION_ROLE.developer);
    }

    return list;
  },
  [args.organizationId, args.projectUsers, organization, permissionRole]);

  if (!organizationLoading && permissionRole === ORGANIZATION_ROLE.developer) {
    return (
      <React.Fragment>
        <Dialog.Header
          title={ headerTitle }
          onClose={ onClose }
        />
        <Dialog.Body>
          <Text weight="semibold" color="GRAY_50">
            <Trans i18nKey="dialogs.shareWorkspace.permissionDenied">
              You don’t have permission
            </Trans>
          </Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
            <Trans i18nKey="shared.cancel">Close</Trans>
          </Button>
        </Dialog.Footer>
      </React.Fragment>
    );
  }

  return (
    <>
      <Dialog.Header
        title={ headerTitle }
        onClose={ onClose }
      />
      <Dialog.Body>
        <Column stretch gap="md">
          <Row stretch>
            <Field
              name="email"
              label={ t('dialogs.shareWorkspace.teamMember.label', { defaultValue: 'Team Member' }) }
              placeholder={ t('dialogs.shareWorkspace.teamMember.placeholder', { defaultValue: 'Select team member' }) }
              component={ SelectField }
              options={ userList || [] }
              loading={ organizationLoading }
              validate={ required }
            />
          </Row>
        </Column>

      </Dialog.Body>
      <Dialog.Footer>
        <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={ !isInvitedUserManager && pristine }
          loading={ submitting }
          onClick={ handleSubmit }
        >
          <Trans i18nKey="shared.share">onSubmit</Trans>
        </Button>
      </Dialog.Footer>
    </>
  );
};

ShareOrganizationDialog.ID = SHARE_ORGANIZATION_DIALOG_ID;
