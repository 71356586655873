import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { APP_URL, useBuildUrl } from 'common/routing';
import { useQueryParams } from 'utils/hooks';
import { getPathnameWithQueryString } from 'utils/url';
import { REDIRECT_TO } from '../../../../../common/constants/localStorageKeys';

export const ProjectInviteContainer = () => {
  const [queryParams] = useQueryParams();
  const auth = useAuth();
  const buildUrl = useBuildUrl();

  if (!queryParams.id) {
    return <Redirect to={ buildUrl(APP_URL.inviteIncorrect) } />;
  }

  window.trackEvent('System', 'Accept project invite');

  if (auth.isAuthorized) {
    const url = buildUrl(APP_URL.developerHomeProjects, { queryParams: { project_invite_id: queryParams.id, host: queryParams.host }});

    return <Redirect to={ url } />;
  }
  window.location.search && localStorage.setItem(REDIRECT_TO, getPathnameWithQueryString());
  return <Redirect to={ buildUrl(APP_URL.authSignup) } />;
};
