import React, { FC } from 'react';
import { AuthProvider } from '@8base-react/auth';
import { Auth, AUTH_STRATEGIES } from '@8base/auth';
import { getAuthClientID, getAuthDomain } from '../../utils/processEnv';
// don't short url
import { APP_URL } from '../../common/routing/';
// import { useCookieStorage } from '../CookieStorageProvider';
import { AUTH_KEY } from './constans';

export const AuthenticationProvider:FC = ({ children }) => {
  // const { cookieStorage } = useCookieStorage();
  const url = new URL(window.location.href);
  const queryParmas = url.searchParams.toString();

  const authClient = Auth.createClient({
    strategy: AUTH_STRATEGIES.WEB_AUTH0,
    subscribable: true,
    storageOptions: {
      // storage: cookieStorage,
      storageKey: AUTH_KEY,
    },
  }, {
    domain: getAuthDomain(),
    clientId: getAuthClientID(),
    redirectUri: `${window.location.origin}${APP_URL.authCallback}`,
    logoutRedirectUri: `${window.location.origin}${APP_URL.authLogin}?${queryParmas}`,
  });

  return <AuthProvider authClient={ authClient }> { children } </AuthProvider>;
};
