//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';

import { useUserPaymentDetailsUpdate } from 'graphql/hooks';

import { BaseBillingUpdateDialog } from './components/BaseBillingUpdateDialog';
import {
  useModal,
} from '@8base/boost';
import { ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';

const USER_PAYMENT_DETAILS_UPDATE_ID = 'USER_PAYMENT_DETAILS_UPDATE_ID';

/**
 * Update user payment method
 */
const UserPaymentDetailsUpdateDialog = () => {
  const { args } = useModal(USER_PAYMENT_DETAILS_UPDATE_ID);

  const [updateCardMutation] = useUserPaymentDetailsUpdate();

  const onSubmitSuccess = useCallback(async (token, onSuccess) => {
    const cardToken = R.prop('id', token);
    await updateCardMutation({
      variables: { data: { cardToken }},
      context: {
        [ENDPOINT_URI]: args.apiHost,
      },


    });
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  }, [args.apiHost, updateCardMutation]);

  return (
    <BaseBillingUpdateDialog
      dialogId={ USER_PAYMENT_DETAILS_UPDATE_ID }
      onSubmitSuccess={ onSubmitSuccess }
    />
  );
};

UserPaymentDetailsUpdateDialog.ID = USER_PAYMENT_DETAILS_UPDATE_ID;

export { UserPaymentDetailsUpdateDialog };
