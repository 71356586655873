// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import { useModal } from '@8base/boost';
// $FlowFixMe waiting for update Flow
import { useHistory, useLocation } from 'react-router-dom';
import * as R from 'ramda';

import { useBuildUrl, APP_URL } from 'common/routing';
import { LogOutDialog } from 'dialogs/LogOutDialog';
import { useWorkspace } from 'providers/WorkspaceProvider';

import { UserDropdown } from 'pro/UserDropdown';
import { useUserAccountInfo, useUserAccountInfoUpdate } from 'graphql/hooks';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { updateUserAccountInfoCachedData } from 'utils/apollo/updateCache';

export const AppFlowUserDropdown = () => {
  const { openModal } = useModal();
  const buildUrl = useBuildUrl();
  const history = useHistory();
  const location = useLocation();
  const { isOwner } = useWorkspace();

  const { userAccountInfo } = useUserAccountInfo();

  const [userAccountInfoUpdate] = useUserAccountInfoUpdate();

  const {
    id,
    email,
    firstName,
    lastName,
    avatar,
    learningMode,
  } = userAccountInfo;

  const openMyAccount = (closeDropdown: Function) => () => {

    history.push(buildUrl(APP_URL.account), { prevLocation: location });

    closeDropdown();
  };

  const openLogOutModal = (closeDropdown: Function) => () => {
    openModal(LogOutDialog.ID);

    closeDropdown();
  };

  const onChangeLearningMode = useCallback(() => {
    userAccountInfoUpdate({
      variables: {
        data: {
          id,
          learningMode: !learningMode,
          email,
        },
      },
      optimisticResponse: {
        system: {
          memberAccountUpsert: R.mergeDeepRight(userAccountInfo, { learningMode: !learningMode }),
          __typename: 'SystemMutation',
        },
      },
      update: (cache, { data }) => {
        const userAccountInfoUpdate = R.pathOr({}, ['system', 'memberAccountUpsert'], data);
        updateUserAccountInfoCachedData({ cache, newData: userAccountInfoUpdate });
      },
      context: {
        [IGNORE_WORKSPACE]: true,
      },
    });
  }, [email, id, learningMode, userAccountInfo, userAccountInfoUpdate]);

  return (
    <UserDropdown
      firstName={ firstName }
      lastName={ lastName }
      email={ email }
      avatarDownloadUrl={ avatar && avatar.downloadUrl }
      isOwner={ isOwner }
      insideWorkspace
      openMyAccount={ openMyAccount }
      openLogOutModal={ openLogOutModal }
      learningMode={ learningMode }
      onChangeLearningMode={ onChangeLearningMode }
    />
  );
};
