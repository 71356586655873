import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import {
  FileCopyOutlined,
} from '@material-ui/icons';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';
import { getServerUrl } from 'utils/processEnv';

const StyledUrl = styled('div')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BackendTable = ({ item }) => {

  const handleCopy = (copy) => {

    toast.success('Copy successfully');

    navigator.clipboard.writeText(copy);
    FS.event(FULLSTORY_EVENT_TYPES.copyEndpoint, {
      endpoint_url: copy,
    });

  };

  const getEndpoint = ({ baseUrl = null, ignoreEnvironment = false, environment = '', workspaceId = '' } = {}) => {
    const serverUrl = !!baseUrl ? baseUrl : getServerUrl();

    if (!workspaceId) {
      return serverUrl;
    }

    return !environment || ignoreEnvironment
      ? `${serverUrl}/${workspaceId}`
      : `${serverUrl}/${workspaceId}_${environment}`;
  };


  return (
    <Table aria-label="simple table">

      <TableHead style={{ backgroundColor: '#F4F7F9' }}>
        <TableRow>
          <TableCell style={{ color: '#848A91', width: '5%', fontSize: 12, textAlign: 'center' }}>NAME</TableCell>
          <TableCell style={{ color: '#848A91', width: '5%', fontSize: 12, textAlign: 'left' }}>ID</TableCell>
          <TableCell style={{ color: '#848A91', width: '55%', fontSize: 12, textAlign: 'left' }} >ENDPOINT</TableCell>
          <TableCell style={{ color: '#848A91' }} />
          <TableCell style={{ color: '#848A91', width: '15%', fontSize: 12, textAlign: 'left' }}>TABLES</TableCell>
          <TableCell style={{ color: '#848A91', width: '15%', fontSize: 12, textAlign: 'left' }}>FUNCTIONS</TableCell>
          <TableCell style={{ color: '#848A91', width: '15%', fontSize: 12, textAlign: 'left' }}>FIELDS</TableCell>

        </TableRow>
      </TableHead>

      <TableBody>
        { item && item.map((row) => (
          <TableRow key={ row.backend }>
            <TableCell style={{ color: '#323C47', fontSize: '13px' }}>
              { row.name }
            </TableCell>
            <TableCell style={{ color: '#323C47', fontSize: '13px' }}>
              { row.workspaceId }
            </TableCell>
            <TableCell>
              <StyledUrl>
                { getEndpoint({ baseUrl: row.worksApiHost, baseEnvironment: row.name, workspaceId: row.workspaceId, environment: row.name }) }
                <FileCopyOutlined css={{ marginLeft: '10px', color: '#0973F8', cursor: 'pointer' }} onClick={ () => handleCopy(getEndpoint({ baseUrl: row.worksApiHost, baseEnvironment: row.name, workspaceId: row.workspaceId, environment: row.name })) } />
              </StyledUrl>
            </TableCell>
            <TableCell />
            <TableCell style={{ color: '#323C47', fontSize: '13px', textAlign: 'left' }}>
              { row.tables }
            </TableCell>
            <TableCell style={{ color: '#323C47', fontSize: '13px', textAlign: 'left' }}>
              { row.functions }
            </TableCell>
            <TableCell style={{ color: '#323C47', fontSize: '13px', textAlign: 'left' }}>
              { row.fields }
            </TableCell>
          </TableRow>
        )) }
      </TableBody>
    </Table>
  );
};


export default BackendTable;
