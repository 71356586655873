// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Text } from '@8base/boost';

import type { CardDetails } from './PaymentsDetails';

import { BRANDS_IMAGES } from 'common/constants/brands';

type BankCardRowProps = {
  card: CardDetails
}

const BankCardImageTag = styled.img`
  height: 24px;
`;

export const BankCardRow = ({ card }: BankCardRowProps) => {
  const bankCardBrandImage = !!card ? BRANDS_IMAGES.find(({ name }) => name === card.brand) || {} : {};

  return (
    <>
      <BankCardImageTag css={ css`border: 1px solid #D1D7DD;padding: 1px 8px;height:33px;` } src={ bankCardBrandImage.image } alt={ bankCardBrandImage.name } />
      <Text css={ css`white-space: nowrap;text-transform: uppercase;padding-right: 5px;` }>{ bankCardBrandImage.name }</Text>
    </>
  );
};

