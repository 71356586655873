import React from 'react';
import { Field } from '@8base-react/forms';
import { InputField, TextAreaField, Row, Column, Heading } from '@8base/boost';
import { i18n } from 'i18n';
import { requiredWithSpaces } from 'utils/formValidations';
import { WorkspaceImageInput } from 'common/components/WorkspaceImageInput';

export const ProjectInfoFields = ({
  isOwner,
  e2eWorkspaceNameFieldProps,
  e2eWorkspaceDescriptionFieldProps,
}) => {
  return (
    <Row gap="lg" >
      <Column>
        <Field
          name="image"
          component={ WorkspaceImageInput }
          disabled={ !isOwner }
          size="xl"
        />
      </Column>
      <Column stretch gap="xl" >
        <Column stretch gap="lg" >
          <Heading type="h3" text={ i18n.t('dialogs.workspaceUpdate.basicInformationTitle') } weight="semibold" />

          <Field
            name="name"
            component={ InputField }
            validate={ requiredWithSpaces }
            label="Project Name"
            disabled={ !isOwner }
            { ...e2eWorkspaceNameFieldProps }
          />

          <Field
            name="description"
            component={ TextAreaField }
            label="Project Description"
            placeholder="Please tell us about your project"
            disabled={ !isOwner }
            { ...e2eWorkspaceDescriptionFieldProps }
          />
        </Column>
      </Column>
    </Row>
  );
};
