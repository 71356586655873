function intlFormat(num) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}

export function abbreviateNumber(value) {
  const num = Math.floor(value);
  if (num >= 1000000000)
    return `${intlFormat(num / 1000000000)}B`;
  if (num >= 1000000)
    return `${intlFormat(num / 1000000)}M`;
  if (num >= 1000)
    return `${intlFormat(num / 1000)}k`;
  return intlFormat(num);
}
