import React, { useCallback } from 'react';
import { Avatar, AsyncContent } from '@8base/boost';
import { get8BaseFileManagerUrl } from 'utils/processEnv';
import { FileInput } from '@8base/client-web-file-input';
import { useWorkspace } from 'providers';
import { useAuth } from '@8base-react/auth';
import { useFileSettings } from 'graphql/hooks';
import {
  // $FlowFixMe waiting for update Flow
  useLocation,
} from 'react-router-dom';
import { environmentAccessor, MASTER_ENVIRONMENT } from 'utils/environmentAccessor';

const AvatarField = ({
  input: { value, onChange },
  meta,
  firstName,
  lastName,
  ...rest
}) => {
  const location = useLocation();
  const isProfilePage = location.pathname === '/account/profile';
  const { authState } = useAuth();
  const { fileSettings, loading } = useFileSettings(!isProfilePage);
  const customS3Enable = fileSettings && fileSettings.isEnabled;
  const workspace = useWorkspace();

  const currentEnv = !isProfilePage ? environmentAccessor.getEnvironment(workspace.id) || MASTER_ENVIRONMENT : '';
  const onUploadDone = useCallback(async (file) => {
    onChange(file);
  }, [onChange]);

  return (
    <AsyncContent loading={ loading } stretch>
      <FileInput fetchPolicy="network-only"
        apiKey={ authState.token }
        workspace={ workspace.id }
        useFilestack={ !customS3Enable }
        environment={ currentEnv }
        maxFiles={ 1 }
        onUploadDone={ onUploadDone }
        uploadHost={ get8BaseFileManagerUrl() }
        value={ value } accept="image/*" public>
        { ({ pick, value }) =>
        {
          return (
            <Avatar css={{ flexShrink: 0 }} src={ value ? value.downloadUrl : null } firstName={ firstName } lastName={ lastName } onPick={ () => pick() } pickVariant="fullWidth" { ...rest } />
          );
        } }
      </FileInput>
    </AsyncContent>
  );
};

export { AvatarField };
