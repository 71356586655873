// @flow

export const FEATURE_NAMES = {
  selfSignup: 'selfSignup',
  openId: 'openId',
  customAuth0: 'customAuth0',
  sqlViews: 'sqlViews',
  sqlIndexes: 'sqlIndexes',
  salesforce: 'salesforce',
  advancedFileFunctions: 'advancedFileFunctions',
  advancedDataImport: 'advancedDataImport',
  backup: 'backup',
  ciCd: 'ciCd',
};

export const FEATURE_DISPLAY_NAMES = {
  [FEATURE_NAMES.selfSignup]: 'Self-Sign-Up',
  [FEATURE_NAMES.openId]: 'Open-Id',
  [FEATURE_NAMES.customAuth0]: 'Custom-Auth0',
  [FEATURE_NAMES.sqlViews]: 'SQL-Views',
  [FEATURE_NAMES.sqlIndexes]: 'SQL-Indexes',
  [FEATURE_NAMES.salesforce]: 'Salesforce',
  [FEATURE_NAMES.advancedFileFunctions]: 'Advanced File Functions',
  [FEATURE_NAMES.advancedDataImport]: 'Advanced Data Import',
  [FEATURE_NAMES.backup]: 'Backup',
  [FEATURE_NAMES.ciCd]: 'Ci/CD',
};

export const LIMITS_NAMES = {
  teamMembers: 'teamMembers',
};

export const PLANS = {
  freeTier: 'freeTier',
  hobbyist: 'hobbyist',
  developer: 'developer',
  professional: 'professional',
  enterprise: 'Enterprise',
};

export const COLOR_BY_PLAN_NAME = {
  freeTier: 'GREEN_40',
  hobbyist: 'BLUE_30',
  developer: 'PURPLE_20',
  professional: 'MAGENTA_10',
};
