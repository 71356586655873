// @flow
import { useQuery, type QueryHookOptions } from 'react-apollo';
import { useAuth } from '@8base-react/auth';
import { FILE_SETTINGS_QUERY } from 'graphql/queries';

export const useFileSettings = (shouldExecute: boolean = true, options?: QueryHookOptions = {}) => {
  const { isAuthorized } = useAuth();

  const {
    data,
    loading,
  } = useQuery(FILE_SETTINGS_QUERY, {
    ...options,
    skip: !isAuthorized || options.skip || !shouldExecute,
    fetchPolicy: 'network-only',
  });
  const filesSetting = data?.filesSetting || false;

  return {
    loading,
    fileSettings: filesSetting,
  };
};
