// @flow
// $FlowFixMe
import { Column, Grid, Row, Text } from '@8base/boost';
import React from 'react';

import { CountTag } from 'common/components';
import { DeveloperHomeProjectItem } from './DeveloperHomeProjectItem';
import { DeveloperHomeProjectListItem } from './DeveloperHomeProjectListItem';

// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeProjectsProps = {
  myProjectsList?: Object[];
  sharedProjectsList: Object[];
  onOpenProjectClick: OpenWorkspaceHandler;
  listView: boolean;
  isCompany: boolean;
  createButton: React$Node;
  organization: any;
}

export const DeveloperHomeProjects = ({
  myProjectsList = [],
  sharedProjectsList = [],
  onOpenProjectClick,
  listView,
  isCompany,
  createButton,
  organization,
}: DeveloperHomeProjectsProps) => {
  return (
    <Column gap="xl" stretch alignItems="stretch">
      <Column gap="lg" stretch alignItems="stretch">
        <If condition={ !listView }>
          <Grid.Layout columns="repeat(auto-fill, minmax(315px, 1fr))" gap="md" alignItems="stretch">
            { createButton }

            { myProjectsList.map(project => (
              <Grid.Box
                key={ project.id }
              >
                <DeveloperHomeProjectItem
                  isCompany={ isCompany }
                  project={ project }
                  onOpenProjectClick={ onOpenProjectClick }
                  organization={ organization }
                />
              </Grid.Box>
            )) }
          </Grid.Layout>
        </If>

        <If condition={ listView }>
          <DeveloperHomeProjectListItem
            createButton={ createButton }
            projectsList={ myProjectsList }
            onOpenProjectClick={ onOpenProjectClick }
            organization
          />
        </If>


      </Column>

      <If condition={ sharedProjectsList.length > 0 }>
        <Column gap="lg" alignItems="stretch">
          <Row alignItems="center">
            <Text kind="overline-2">
                Shared with me
            </Text>
            <CountTag color="GRAY">
              { sharedProjectsList.length }
            </CountTag>
          </Row>
          <If condition={ !listView }>
            <Grid.Layout columns="repeat(auto-fill, minmax(315px, 1fr))" gap="md" alignItems="stretch">
              { sharedProjectsList.map(project => (
                <Grid.Box key={ project.id }>
                  <DeveloperHomeProjectItem
                    isCompany={ isCompany }
                    project={ project }
                    onOpenProjectClick={ onOpenProjectClick }
                    organization={ organization }
                  />
                </Grid.Box>
              )) }
            </Grid.Layout>
          </If>
          <If condition={ listView }>
            <DeveloperHomeProjectListItem
              projectsList={ sharedProjectsList }
              onOpenProjectClick={ onOpenProjectClick }
              organization
            />
          </If>
        </Column>
      </If>
    </Column>
  );
};
