import React from 'react';
import { UIPage } from '../components/UIPage';
import { useProject } from 'providers/ProjectProvider/useProject';
import { useProjectFrontend } from 'graphql/hooks';

export const UIContainer = () => {

  const { project, loading, refetch, projectId } = useProject();
  const { projectFrontend, loadingFrontend, refetch: refetchFrontend } = useProjectFrontend(projectId);

  return (
    <UIPage
      projectId={ projectId }
      frontendWorkspaces={ projectFrontend?.frontendWorkspaces?.items || [] }
      organizationId={ project?.organization?.id }
      project={ project }
      loading={ loading || loadingFrontend }
      refetch={ refetch }
      refetchFrontend={ refetchFrontend }
    />
  );
};

