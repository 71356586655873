// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
import {
  Text,
  Radio,
  RadioGroupField,
  Column,
  Row,
  Tooltip,
} from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { COLOR_BY_PLAN_NAME, PLANS } from 'graphql/constants/billing';

const RadioGroupScrollableTag = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  padding-top: 10px;
  top: -10px;
`;

const PlanRadioItemTag = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
  props.active
    ? props.theme.COLORS.PRIMARY
    : props.theme.COLORS.PRIMARY_BORDER_COLOR};
  background-color: ${(props) =>
      props.disabled
        ? props.theme.COLORS.DISABLED_COLOR
        : props.theme.COLORS.TRANSPARENT};
`;

const PlanRadioItemPopularBannerWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: -50px;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0 0 auto auto;
  transform-origin: 0 0;
  transform: translate(41%, -70%) rotate(45deg);
  clip-path: inset(0 -100%);
`;

const PlanRadioItemPopularBanner = styled.div`
  height: 20px;
  width: 120px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #e83b34 0%, #5b92ff 100%);
`;

const PlanRadioItem = ({ active, disabled, disabledTooltipText, children }) => {
  const itemTag = (
    <PlanRadioItemTag active={ active } disabled={ disabled }>
      { children }
    </PlanRadioItemTag>
  );

  if (disabled) {
    return (
      <Tooltip
        placement="right"
        css={ css`
          height: 100%;
          flex: 1 1 0;
        ` }
        message={ <div style={{ width: '150px' }}>{ disabledTooltipText }</div> }
        cursor="default"
      >
        { itemTag }
      </Tooltip>
    );
  }

  return itemTag;
};

const PlanRadioItemLabel = ({ name, displayName, description, price }) => (
  <Column css={{ position: 'relative' }}>
    <Column style={{ marginBottom: 0 }}>
      <Text
        color={ COLOR_BY_PLAN_NAME[name] }
        weight="semibold"
        css={{ textTransform: 'uppercase', fontSize: 14 }}
      >
        { displayName }
      </Text>
      <Row alignItems="baseline" style={{ marginBottom: 0 }}>
        <Text
          css={ css`
            font-size: 40px;
          ` }
        >
          ${ price }
        </Text>
        <Text
          css={ css`
            font-size: 16px;
          ` }
          color="SECONDARY_TEXT_COLOR"
        >
          / mo.
        </Text>
      </Row>
    </Column>
    <Text color="SECONDARY_TEXT_COLOR" css={{ fontSize: 12 }}>
      { description }
    </Text>
  </Column>
);

const getAvailablePlans = (plans, isFreePlanAvailable) => {
  let availablePlans = plans;

  if (!isFreePlanAvailable) {
    availablePlans = availablePlans.filter(
      ({ name }) => name !== PLANS.freeTier,
    );
  }

  return availablePlans;
};

export const BillingPlanRadioGroupField = ({
  plans,
  isFreePlanAvailable,
  card,
  input,
  ...props
}: *) => {
  const availablePlans = useMemo(() => {
    return getAvailablePlans(plans, isFreePlanAvailable);
  }, [isFreePlanAvailable, plans]);


  const getIsDisabled = useCallback(
    (planName: string) => {
      return planName !== PLANS.freeTier && planName !== '8base' && !card;
    },
    [card],
  );

  const getDisabledTooltipText = useCallback((planName: string) => {
    if (planName !== PLANS.freeTier) {
      return 'Update your payment details by clicking on the link below.';
    }
  }, []);

  const onChange = useCallback(
    (value) => {
      const plan = availablePlans.find(({ id }) => id === value) || {};
      const isPlanDisabled = getIsDisabled(plan.name);

      if (!isPlanDisabled) {
        input && input.onChange(value);
      }
    },
    [availablePlans, getIsDisabled, input],
  );

  return (
    <RadioGroupScrollableTag>
      <RadioGroupField
        { ...props }
        input={{ ...input, onChange }}
        direction="row"
        gap="md"
        alignItems="stretch"
      >
        { availablePlans.map(({ id, name, displayName, description, price }) => (
          <Radio.Item
            key={ id }
            value={ id }
            css={ css`
              flex: 1 1 0;
              width: 238px;
              height: 196px;
            ` }
            { ...E2E.$props(`dialogs.workspaceCreate.plan[${name}]`) }
          >
            { ({ checked }) => (
              <PlanRadioItem
                active={ checked }
                disabled={ getIsDisabled(name) }
                disabledTooltipText={ getDisabledTooltipText(name) }
              >
                <PlanRadioItemLabel
                  name={ name }
                  displayName={ displayName }
                  description={ description }
                  price={ price }
                />
                <If condition={ name === PLANS.hobbyist }>
                  <PlanRadioItemPopularBannerWrapper>
                    <PlanRadioItemPopularBanner>
                      <Text
                        kind="overline-2"
                        color="WHITE"
                        css={{ paddingTop: '2px' }}
                      >
                        Popular
                      </Text>
                    </PlanRadioItemPopularBanner>
                  </PlanRadioItemPopularBannerWrapper>
                </If>
              </PlanRadioItem>
            ) }
          </Radio.Item>
        )) }
      </RadioGroupField>
    </RadioGroupScrollableTag>
  );
};
