import { PROJECT_UPDATE_MUTATION } from '../mutations';
import { useMutation } from 'react-apollo';
import { PROJECT_INFO_QUERY, PROJECTS_USER_LIST_QUERY } from '../queries';

export const useProjectUpdate = (projectId: string, graphqlOptions: Object = {}) => {
  const [updateProject, { data, ...rest }] = useMutation(PROJECT_UPDATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: PROJECT_INFO_QUERY,
      variables: {
        projectId,
      },
    }, {
      query: PROJECTS_USER_LIST_QUERY,
      variables: {
        organizationId: graphqlOptions?.organizationId || '',
      },
    }],
    ...graphqlOptions,
  });
  return [updateProject, { projectUpdate: { data, ...rest }}];
};
