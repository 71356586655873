// @flow

export const getEnvironment = () => process.env.NODE_ENV;

export const isProduction = () => process.env.NODE_ENV === 'production';

export const isStaging = () => process.env.NODE_ENV === 'staging';

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const shouldRedirectToErrorPage = () => process.env.REACT_APP_ERROR_PAGE === 'true';

export const getAuthDomain = () => process.env.REACT_APP_AUTH_DOMAIN;

export const getAuthClientID = () => process.env.REACT_APP_AUTH_CLIENT_ID;

export const getAuthRealm = () => process.env.REACT_APP_AUTH_REALM;

export const getSentryDSN = () => process.env.REACT_APP_SENTRY_DSN;

export const isSentryEnabled = () => process.env.REACT_APP_TRACK_ERRORS_ENABLED === 'true';

export const getAppEnvironment = () => process.env.REACT_APP_ENV;

export const isProductionEnv = () => process.env.REACT_APP_ENV === 'production';

export const isStagingEnv = () => process.env.REACT_APP_ENV === 'staging';

export const isDevEnv = () => process.env.REACT_APP_ENV === 'development';

export const getEnvID = (): string => process.env.AUTH_ID_ENV || '';

export const getClientUrl = () => process.env.REACT_APP_CLIENT_URL || '';

export const getAppBuilderUrl = () => process.env.REACT_APP_UI_BUILDER_URL || '';

export const getServerUrl = () => process.env.REACT_APP_SERVER_URL || '';

export const getUSServerUrl = () => process.env.REACT_APP_SERVER_URL_US || '';

export const getUKServerUrl = () => process.env.REACT_APP_SERVER_URL_UK || '';

export const getLearningCenterUrl = () => process.env.REACT_APP_LEARNING_CENTER_URL || '';

export const getStripeApiKey = () => process.env.REACT_APP_STRIPE_API_KEY || '';

export const getSalesforceClientId = () => process.env.REACT_APP_SALESFORCE_CLIENT_ID || '';

export const getGoogleAnalyticProductId = () => process.env.REACT_APP_GOOGLE_ANALYTICS_PRODUCT_ID || '';

export const showServicePage = () => process.env.REACT_APP_SERVICE_PAGE === 'true';


export const getWorkspacePollingLimit = () => Number.parseInt(
  process.env.REACT_APP_WORKSPACE_POLLING_LIMIT || '',
  10,
);

export const getOkQuotaLimit = () => process.env.REACT_APP_OK_QUOTA_LIMIT || 50;

export const getWarnQuotaLimit = () => process.env.REACT_APP_WARN_QUOTA_LIMIT || 80;

export const getMaxQuotaLimit = () => process.env.REACT_APP_MAX_QUOTA_LIMIT || 100;

export const getHostedLoginUrlBase = () => process.env.REACT_APP_HOSTED_LOGIN_BASE_URL || '';

export const getFileStackApiKey = () => process.env.REACT_APP_FILE_STACK_API_KEY || '';

export const getFileStackSecretApiKey = () => process.env.REACT_APP_FILE_STACK_API_SECRET || '';

export const getFileStackUrl = () => process.env.REACT_APP_FILE_STACK_URL || '';

export const get8BaseFileManagerUrl = () => process.env.REACT_APP_FILE_MANAGER_URL || '';
