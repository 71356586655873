import { AsyncContent, Column, Progress, Row, Scrollable, Text, Tooltip } from '@8base/boost';
import * as R from 'ramda';
import React from 'react';
import styled from '@emotion/styled';
import { processEnv } from 'utils';
import { Trans } from 'utils/translate';
import { abbreviateNumber } from 'utils/numberShorter';
import { apolloProjectDashboardSelectors } from 'graphql/selectors';
import { useProjectQuotas } from 'graphql/hooks';
import { CenteredCardBody, StyledCard, StyledCardHeader, StyledCardTitleText } from './StyledComponents';


type QuotasCardProps = {
  quotasData: Object,
  metricsData: Object,
};

const SHOULD_SHOW_UNIT = {
  gb: true,
  member: false,
  request: false,
  row: false,
};

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
   color:  ${props => props.theme.COLORS.GRAY_50}
`;

const StyledCardText = styled(Text)`
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 
  @media (min-width: 824px) {
    width: 80px;
  }
  @media (min-width: 1350px) {
    width: 130px;
  }
  @media (min-width: 1480px) {
    width: 170px;
  }
  @media (min-width: 1694px) {
    width: 230px;
  }
`;


const StyledProgress = styled(Progress)`
  > div > div:last-child{
    display:flex;
    // flex:1;
  }

  > div{
    justify-content: end;
  }

  justify-content: space-between;
  width: unset !important;
`;

const StyledProgressRow = styled(Row)`
    margin-bottom:0 !important;
    font-size: 19px !important;
    line-height: 3 !important;
    align-items: center;
    min-height: 35px;
    width: 100%;
    justify-content: space-between;
`;


const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledValueText = styled.div`
    // flex: 1;
    text-align: right;
    flex-shrink: 0;
    width: max-content;
    min-width: 55px;
    > span{
      font-weight: unset !important;
      color: #323C47 !important;
    }
`;

const getValueText = (value, limit, unit, showUnit) => {
  return (
    <React.Fragment>
      <Text color="GRAY_40">
        { abbreviateNumber(Number(value || 0)) }
      </Text>
      <Text weight="semibold">
        { ' ' } / { abbreviateNumber(Number(limit)) }
        <If condition={ showUnit }>
          { ' ' } { unit.toUpperCase() }
        </If>
      </Text>
    </React.Fragment>
  );
};

const getProgressColor = (percentage) => {
  const okQuotaLimit = processEnv.getOkQuotaLimit();
  const warnQuotaLimit = processEnv.getWarnQuotaLimit();
  const maxQuotaLimit = processEnv.getMaxQuotaLimit();
  let color = 'GREEN';
  if (percentage >= 0 && percentage <= okQuotaLimit) {
    color = 'GREEN';
  } else if (percentage > okQuotaLimit && percentage <= warnQuotaLimit) {
    color = 'YELLOW';
  } else if (percentage > warnQuotaLimit && percentage <= maxQuotaLimit) {
    color = 'RED';
  }

  return color;
};

const QuotasCard = ({ title, icon, link, projectId, apiHost, ...rest }: QuotasCardProps) => {

  const { projectQuotas, loading } = useProjectQuotas(projectId, apiHost);

  const quotas = apolloProjectDashboardSelectors.getQuotas(projectQuotas);
  const metrics = apolloProjectDashboardSelectors.getMetrics(projectQuotas);

  return (
    <StyledCard { ...rest }>
      <StyledCardHeader style={{ marginBottom: 14 }}>
        <Row alignItems="center">
          { icon }
          <StyledCardTitleText>
            { title }
          </StyledCardTitleText>
        </Row>
        { link }
      </StyledCardHeader>
      <CenteredCardBody>
        <AsyncContent loading={ loading }>
          <Choose>
            <When condition={ quotas.length }>
              <Column gap="lg" stretch>
                <Scrollable style={{ maxHeight: 280, overflowY: 'hidden' }}>
                  { quotas.map(({ displayName, name, value, unit }, index) => {
                    const metric = R.propOr(0, name, metrics);
                    const hasOverflow = metric > value;
                    let valueToDisplay = Math.min(100, 100 * metric / value);

                    if (hasOverflow) {
                      valueToDisplay = Math.max(1, 100 * value / metric);
                    }
                    return (
                      <Column stretch key={ name } css={{ height: 40 }}>
                        <StyledProgressRow id="row">
                          <Tooltip
                            modifiers={{ offset: { offset: '0, 4' }}}
                            message={ displayName }
                          >
                            <StyledCardText
                            >
                              { displayName }
                            </StyledCardText>
                          </Tooltip>
                          <StyledProgress id="progress"
                            size="sm"
                            color={ getProgressColor(valueToDisplay) }
                            valueWidth="52px"
                            valueText={ <StyledValueText id="valueText"> { getValueText(metric, value, unit, SHOULD_SHOW_UNIT[unit]) } </StyledValueText> }
                            value={ valueToDisplay }
                            showSeparator={ hasOverflow }
                            backgroundColor={ hasOverflow ? 'BLUE_20' : 'GRAY_20' }
                          />
                        </StyledProgressRow>
                        <If condition={ index < quotas.length - 1 }>
                          <DividerNav />
                        </If>
                      </Column>
                    );
                  }) }
                </Scrollable>
              </Column>
            </When>
            <Otherwise>
              <StyledCardContentText>
                <Trans i18nKey="projects.dashboard.noData">No data</Trans>
              </StyledCardContentText>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </CenteredCardBody>
    </StyledCard>
  );
};

export { QuotasCard };
