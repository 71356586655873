import React, { useCallback } from 'react';
import { FileInput } from '@8base-react/file-input';
import { Avatar, Badge, IconButton } from '@material-ui/core';
import { CreateOutlined, FolderOpen } from '@material-ui/icons';
import styled from '@emotion/styled';

const StyledIconButton = styled(IconButton)`
  height: 32px;
  width: 32px;
  background-color: #FFFFFF !important;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
`;

export const AvatarFieldWithButton = ({
  input: { value, onChange },
  ...rest
}) => {
  const onUploadDone = useCallback(async (file) => {
    onChange(file);
  }, [onChange]);
  return (
    <FileInput fetchPolicy="network-only" onUploadDone={ onUploadDone } value={ value } accept="image/*" public>
      { ({ pick, value }) => {
        return (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={ (
              <StyledIconButton
                onClick={ () => pick() }
              >
                <CreateOutlined style={{ fontSize: 22 }} />
              </StyledIconButton>
            ) }
          >
            <Avatar
              alt="Project image"
              src={ value?.downloadUrl }
              style={{ width: 128, height: 128, backgroundColor: '#ABB1B8' }}
              { ...rest }
            >
              <FolderOpen style={{ width: 62, height: 62 }} />
            </Avatar>
          </Badge>
        );
      } }
    </FileInput>
  );
};
