// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
// $FlowFixMe
import { useParams } from 'react-router-dom';
import { UIContainer as UIPage } from './containers/UI.container';
import { useProjectFrontend } from 'graphql/hooks';

export const ProjectUI = ({ projectInfo, refetch, loading }: *) => {
  const { projectId } = useParams();
  const { refetch: refetchFrontend } = useProjectFrontend(projectId);

  return (
    <UIPage
      projectId={ projectId }
      project={ projectInfo }
      refetch={ refetch }
      refetchFrontend={ refetchFrontend }
      loading={ loading }
    />
  );
};
