import React from 'react';
import { SettingsPage } from '../components/settingsPage';
import { useProjectInfo } from 'graphql/hooks';
import { useProjectUpdate } from 'graphql/hooks/useProjectUpdate';
import { useModal } from '@8base/boost';
import ProjectDeleteDialog from 'dialogs/ProjectDeleteDialog';

export const SettingsPageContainer = ({ projectId }) => {
  const { project } = useProjectInfo(projectId);
  const [projectInfoUpdate, { projectUpdate: { loading: submitting }}] = useProjectUpdate(projectId);
  const { openModal } = useModal();

  const onDeleteProjectHandler = (event) => {
    event.stopPropagation();
    openModal(ProjectDeleteDialog.ID, {
      projectId,
      projectName: project?.name,
    });
  };

  const onSubmit = (data) => {
    const { image } = data;
    const getNewAvatar = () => {
      if (image && project.image) {
        if (image.downloadUrl === project.image.downloadUrl) {
          return undefined;
        }
      }

      if (image && image.fileId) {
        return {
          create: {
            fileId: image.fileId,
            filename: image.filename,
            public: true,
          },
        };
      }

      return undefined;
    };

    const avatarData = getNewAvatar();

    projectInfoUpdate({
      variables: {
        data: {
          ...data,
          image: avatarData,
          id: projectId,
        },
      },
    });
  };

  const initialValues = {
    name: project?.name,
    description: project?.description || undefined,
    image: project?.image,
  };

  return (
    <SettingsPage
      onSubmit={ onSubmit }
      initialValues={ initialValues }
      submitting={ submitting }
      onDeleteProject={ onDeleteProjectHandler }
    />
  );
};
