export const muiBaseTheme = {
  palette: {
    primary: {
      main: '#0973F8',
    },
    error: {
      main: '#FF2925',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Poppins',
      textTransform: 'unset',
    },
  },
  props: {
    MuiButton: {
      style: {
        fontSize: '12px',
        width: 'fit-content',
      },
    },
  },
};
