// @flow
// $FlowFixMe
import React, { useCallback, useMemo, useState } from 'react';
// $FlowFixMe
import * as R from 'ramda';
import { Dialog, useModal, Button, Grid, InputField, SelectField, Text, Column, Row, AsyncContent } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import { useQuery, useMutation } from 'react-apollo';
import { composeValidators, email, required } from 'utils/formValidations';
import { useOrganizationPaymentDetails, useProjectsList, useUserAccountInfo } from 'graphql/hooks';
import { TOAST_SUCCESS_MESSAGE, DIALOG_ID, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_DISPLAY_STR, ORGANIZATION_ROLE_LIST } from 'common/constants/organization';
import { ProjectCheckbox, BankCardLastFour } from 'common/components';
import { ORGANIZATION_QUERY, BILLING_LICENSE_CONST_QUERY } from 'graphql/queries';
import { ORGANIZATION_INVITE_TEAM_MEMBER } from 'graphql/mutations';
import { apolloBillingDataSelectors, apolloProjectsListSelectors } from 'graphql/selectors';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { i18n } from 'i18n';
import { getDateFromFirstDayOfMonth } from 'utils/date';
import { FORM_ERROR } from 'final-form';
const INVITE_USER_PROJECT_DIALOG_ID = 'INVITE_USER_TO_PROJECT_ORGANIZATION_DIALOG_ID';

const SeatsLimitReachedWarning = styled(Row)`
  padding: 12px 20px;
  background: #EBF4FF;
  border-radius: 12px;
  width: 100%;
`;

const getRoleOptions = (permissionRole) => {
  if (permissionRole === ORGANIZATION_ROLE.admin || permissionRole === ORGANIZATION_ROLE.owner) {
    return R.pipe(
      R.reject(R.equals('owner')),
      R.map((name) => ({ label: ORGANIZATION_ROLE_DISPLAY_STR[name], value: name })),
    )(ORGANIZATION_ROLE_LIST);
  }

  if (permissionRole === ORGANIZATION_ROLE.manager) {
    return R.pipe(
      R.reject(R.equals('owner')),
      R.reject(R.equals('admin')),
      R.reject(R.equals('manager')),
      R.map((name) => ({ label: ORGANIZATION_ROLE_DISPLAY_STR[name], value: name })),
    )(ORGANIZATION_ROLE_LIST);
  }

  return [];
};

const checkOrganizationUsersLimit = (data) => {
  const quotas = apolloBillingDataSelectors.getQuotas(data);
  const metrics = apolloBillingDataSelectors.getMetrics(data);
  const organizationSeatsQuota = R.find(R.propEq('name', 'organizationSeats'))(quotas);

  if (!organizationSeatsQuota) {
    return false;
  }

  const quotaValue = R.pathOr(1, ['value'], organizationSeatsQuota);
  const currentOrganizationSeats = R.pathOr(1, ['organizationSeats'], metrics);

  return quotaValue <= currentOrganizationSeats;
};

const getUsersLimitWarning = (data) => {
  const overages = apolloBillingDataSelectors.getOverages(data);
  const warning = R.path(['organizationSeats', 'warning'])(overages);

  if (warning) {
    return warning;
  }

  return i18n.t('dialogs.inviteTeamMemberToOrganization.seatsLimitReachedWarning', {
    defaultValue: 'The free seat limit is exceeded, you will be extra charged for inviting this user',
  });
};

export const TeamMemberInviteToProjectOrganizationDialog = () => {

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const dataUser = R.pick(['email', 'firstName', 'lastName'], userAccountInfo);

  const { closeModal, args: { organizationId, permissionRole }} = useModal(INVITE_USER_PROJECT_DIALOG_ID);

  const {
    data: billingLicenseData,
    loading: billingLicenseLoading,
  } = useQuery(BILLING_LICENSE_CONST_QUERY, {
    variables: {
      organizationId,
      date: getDateFromFirstDayOfMonth(),
    },
    skip: !organizationId,
    fetchPolicy: 'cache-first',
  });


  const {
    card: organizationCard,
    loading: organizationPaymentDetailsLoading,
  } = useOrganizationPaymentDetails(organizationId, { fetchPolicy: 'cache-first' });

  const isSeatsLimitReached = checkOrganizationUsersLimit(billingLicenseData);

  const roleOptions = useMemo(() => getRoleOptions(permissionRole), [permissionRole]);

  const { myProjects } = useProjectsList(organizationId);

  const myProjectsList = apolloProjectsListSelectors.getSharedProjectsList(myProjects, { withAlphabetSorting: true });

  const getEmptyWorkspacesIds = (values) =>
    values.projectRoles
      .filter((project) => !project.environmentRoles.length)
      .map(({ projectId }) => projectId);

  const [inviteUser] = useMutation(ORGANIZATION_INVITE_TEAM_MEMBER, {
    notifyOnNetworkStatusChange: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('inviteUserDialog.success'),
      [DIALOG_ID]: INVITE_USER_PROJECT_DIALOG_ID,
    },
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: ORGANIZATION_QUERY,
      variables: {
        organizationId,
      },
    }],
  });

  const [expandedRows, setExpandedRows] = useState<[string]>([]);
  const [failedValidationRows, setFailedValidationRows] = useState<[string]>([]);

  const onClose = useCallback(() => {
    setExpandedRows([]);
    setFailedValidationRows([]);

    closeModal(INVITE_USER_PROJECT_DIALOG_ID);
  }, [closeModal]);


  const onOpenRow = useCallback((id: string) => {
    if (expandedRows.includes(id)) {
      const newExpandedRows = expandedRows.filter((rowId) => rowId !== id);
      setExpandedRows(newExpandedRows);
    } else {
      setExpandedRows([id, ...expandedRows]);
    }
  }, [expandedRows]);

  const onSubmit = useCallback(async (values) => {
    if (!values.organizationId) {
      return null;
    }

    const emptyWorkspacesIds = getEmptyWorkspacesIds(values);
    if (values.role === ORGANIZATION_ROLE.developer && emptyWorkspacesIds.length) {
      setExpandedRows([expandedRows, ...emptyWorkspacesIds]);
      setFailedValidationRows(emptyWorkspacesIds);
      return { [FORM_ERROR]: 'environment validation failed' };
    }

    const valuesToShared = {};
    valuesToShared.projectRoles = values.projectRoles;
    valuesToShared.organizationId = values.organizationId;

    valuesToShared.firstName = dataUser.firstName;
    valuesToShared.lastName = dataUser.lastName;
    valuesToShared.email = values.email;
    valuesToShared.role = values.role;

    await inviteUser({
      variables: valuesToShared,
    });

    window.trackEvent('Organization Team Members', 'Invite member');

    onClose();
  }, [dataUser.firstName, dataUser.lastName, expandedRows, inviteUser, onClose]);

  const initialValues = useMemo(() => ({
    organizationId,
    email: '',
    role: '',
    projectRoles: [],
  }), [organizationId]);


  return (
    <Dialog id={ INVITE_USER_PROJECT_DIALOG_ID } size="xl" onClose={ onClose } { ...E2E.$props('dialogs.teamMemberInvite.body') }>
      <Form onSubmit={ onSubmit } initialValues={ initialValues }>
        { ({ handleSubmit, submitting, values, form }) => {
          return (
            <>
              <Dialog.Header title={ i18n.t('dialogs.inviteTeamMemberToOrganization.title', { defaultValue: 'Invite Team Member to Organization' }) } onClose={ onClose } />
              <Dialog.Body scrollable>
                <Grid.Layout
                  gap="md"
                  columns="1fr 200px"
                  areas={ [
                    ['email', 'role'],
                    ['seatLimitWarning', 'seatLimitWarning'],
                    ['workspaces', 'workspaces'],
                  ] }
                >
                  <Grid.Box area="email">
                    <Field
                      name="email"
                      label="Email Address"
                      validate={ composeValidators(required, email) }
                      component={ InputField }
                      disabled={ submitting }
                    />
                  </Grid.Box>
                  <Grid.Box area="role">
                    <Field
                      name="role"
                      label="Organization Role"
                      options={ roleOptions }
                      component={ SelectField }
                      validate={ required }
                      disabled={ submitting }
                    />
                  </Grid.Box>
                  <If condition={ isSeatsLimitReached }>
                    <Grid.Box area="seatLimitWarning">
                      <AsyncContent stretch loading={ organizationPaymentDetailsLoading || billingLicenseLoading }>
                        <SeatsLimitReachedWarning alignItems="center" justifyContent="between">
                          <Text css={ css`flex: 1;` }>
                            { getUsersLimitWarning(billingLicenseData) }
                          </Text>
                          <If condition={ !!organizationCard }>
                            <BankCardLastFour css={ css`background: #fff; border: none;` } card={ organizationCard } />
                          </If>
                        </SeatsLimitReachedWarning>
                      </AsyncContent>
                    </Grid.Box>
                  </If>
                  <Grid.Box area="workspaces">
                    { /* <Text kind="h4">
                      { getWorkspaceAccessText(values.role) }
                    </Text> */ }
                    <Column>
                      { myProjectsList && myProjectsList.map((project) => (
                        <ProjectCheckbox
                          key={ project.id }
                          workspaceImage={ project.image }
                          workspaceKind="workspace.kind"
                          workspaceName={ project.name }
                          workspaceApiHost={ project.apiHost }
                          projectId={ project.id }
                          workspaceDescription={ project.description }
                          formValues={ values }
                          form={ form }
                          submitting={ submitting }
                          expandedRows={ expandedRows }
                          onOpenRow={ onOpenRow }
                          failedValidationRows={ failedValidationRows }
                          setFailedValidationRows={ setFailedValidationRows }
                        />
                      )) }
                    </Column>
                  </Grid.Box>
                </Grid.Layout>
              </Dialog.Body>
              <Dialog.Footer>
                <Row alignItems="center">
                  { ORGANIZATION_ROLE.developer && failedValidationRows.length !== 0 &&
                  <Text color="DANGER">
                    { i18n.t('dialogs.inviteTeamMemberToOrganization.emptyEnvironmentsWarning', { defaultValue: 'Choose role for at least one environment for each selected Workspace' }) }
                  </Text> }
                  <Row>
                    <Button
                      type="button"
                      color="neutral"
                      disabled={ submitting }
                      onClick={ onClose }
                    >
                      { i18n.t('shared.cancel') }
                    </Button>
                    <Button
                      css={ css`width: 145px;` }
                      type="submit"
                      onClick={ handleSubmit }
                      loading={ submitting }
                    >
                      { i18n.t('dialogs.inviteTeamMemberToOrganization.sendInvite', { defaultValue: 'Send Invite' }) }
                    </Button>
                  </Row>
                </Row>
              </Dialog.Footer>
            </>
          );
        } }
      </Form>
    </Dialog>
  );
};

TeamMemberInviteToProjectOrganizationDialog.ID = INVITE_USER_PROJECT_DIALOG_ID;
