import React from 'react';
import { Row } from '@8base/boost';

import { FancyButton } from 'common/components';
import { HOME_LINK } from 'common/routing';
import { UserFlow } from 'common/UserFlow';
import { AuthTextUnderline } from 'routes/auth/components';
import { Trans } from 'utils/translate';
import meditationGuy from 'images/8base-man.svg';

export const ServicePage = () => {
  window.htmlLoader.hide();

  return (
    <UserFlow.Page>
      <UserFlow.Header
        position="absolute"
        transparent
        size="md"
        rightContent={ (
          <Row gap="lg" alignItems="center">
            <a href={ HOME_LINK } target="_self">
              <FancyButton rounded uppercase>
                <Trans i18nKey="header.title.home">Home</Trans>
              </FancyButton>
            </a>
          </Row>
        ) }
      />
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center', flexDirection: 'column' }}>
        <img src={ meditationGuy } alt="meditation guy" style={{ height: 240 }} />

        <UserFlow.PageTitle style={{ marginTop: '2rem' }}>
          <UserFlow.HeadingWhite type="h1" style={{ fontWeight: 900 }}>
            We're making <AuthTextUnderline>amazing changes!</AuthTextUnderline>
          </UserFlow.HeadingWhite>

          <UserFlow.HeadingWhite type="h3" style={{ fontWeight: 400 }}>
            Please look for an email if you are an existing customer,
          </UserFlow.HeadingWhite>

          <UserFlow.HeadingWhite type="h3" style={{ fontWeight: 400 }}>
            or check back soon
          </UserFlow.HeadingWhite>
        </UserFlow.PageTitle>
      </div>
    </UserFlow.Page>
  );
};
