import React from 'react';
import { Text, Column, Row } from '@8base/boost';
import { ReactComponent as FullStackImage } from '../images/8base-full-stack.svg';
import { ReactComponent as BackedImage } from '../images/8base-backend.svg';
import { ReactComponent as RocketImage } from '../images/rocket.svg';
import { ReactComponent as ToolImage } from '../images/tool.svg';

import { ImageDisplay, ToggleContainer } from './CssUtils';

export const SharedRadioItem = ({
  name,
  displayName,
  description,
  isTouched,
  hasIcons = true,
  textAlign = 'center',
  icon,
  display = 'flex',
  alignItems = 'center',
}) => (
  <Column>
    <Row alignItems="center" style={{ flexDirection: 'column', gap: '10px', alignItems }}>
      <ToggleContainer className={ hasIcons && !isTouched ? 'show' : 'hideee' }>
        <Column>
          <ImageDisplay>
            <If condition={ icon === 'Fullstack' }>
              <FullStackImage />
            </If>
            <If condition={ icon === 'Backend' }>
              <BackedImage />
            </If>
            <If condition={ icon === 'rocket' }>
              <RocketImage />
            </If>
            <If condition={ icon === 'tool' }>
              <ToolImage />
            </If>
          </ImageDisplay>
        </Column>
      </ToggleContainer>
      <Column alignContent="center" justifyContent="left" css={{ margin: '0px', marginLeft: '9px', paddingTop: '10px', display }}>
        <Text weight="semibold" css={{ lineHeight: '0.2', fontSize: '16px', margin: '0 0 7px 0 !important', textAlign }}>{ displayName }</Text>
      </Column>
      <ToggleContainer className={ isTouched ? 'hideee' : 'show' }>
        <Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text color="SECONDARY_TEXT_COLOR" css={{ fontSize: '11px', marginTop: '1px', textAlign, lineHeight: '14.4px' }}>
            { description }
          </Text>
        </Column>
      </ToggleContainer>
    </Row>
  </Column>
);
