// @flow
// $FlowFixMe waiting for update Flow
import React, { useEffect } from 'react';
// $FlowFixMe waiting for update Flow
import { Route, Redirect, Switch, useParams, Link as RouterLink, useHistory } from 'react-router-dom';
import { Row, Icon, Column, Text } from '@8base/boost';
import css from '@emotion/css';
import styled from '@emotion/styled';
import * as R from 'ramda';
import { i18n } from 'i18n';

import { AppFlow } from 'common/AppFlow';
import { APP_URL, useBuildUrl } from 'common/routing';
import { CommonFlowBars } from 'common/CommonFlow/CommonFlowBars';
import { UserFlow } from 'common/UserFlow';
import { useOrganization, useOrganizationPaymentDetails, useUserAccountInfo } from 'graphql/hooks';

import { OrganizationGeneral } from './routes/General';
import { OrganizationTeam } from './routes/Team';
import { OrganizationBilling } from './routes/Billing';
import { OrganizationPage, OrganizationPlate } from './components';

import defaultLogo from 'images/workspace-default-logo.svg';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { getUserRoleAtOrganization, isUserAtOrganization } from 'utils/organization';


const StyledButtonBack = styled(RouterLink)`
  display: flex;
  color: ${props => props.theme.COLORS.BLACK};
  transition: all .3s ease-in-out;
  h4 {
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.COLORS.BLUE};
    h4 {
      color: ${props => props.theme.COLORS.BLUE};
    }
  }
`;

const OrganizationImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: ${props => props.theme.SIZES.MAIN_BORDER_RADIUS};
`;

const StyledCardTitle = styled(Text)`
  line-height: 1.2;
  width: 100%;
  transition: all .3s ease-in-out;
`;

const StyledOrganizationLink = styled(RouterLink)`
  &:hover {
    ${StyledCardTitle} {
      color: ${props => props.theme.COLORS.BLUE};
    }
  }
`;

const BILLING_AVAILABLE_ROLES = [
  ORGANIZATION_ROLE.owner,
  ORGANIZATION_ROLE.admin,
];

const Organizations = () => {
  const { organizationId } = useParams();
  const buildUrl = useBuildUrl();
  const history = useHistory();

  const { organization, loading: organizationLoading } = useOrganization(organizationId);
  const { userAccountInfo, loading: userAccountInfoLoading } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  //Preload organization payment details(card)
  useOrganizationPaymentDetails(organizationId);

  useEffect(() => {
    if (!organizationLoading) {
      requestAnimationFrame(window.htmlLoader.hide);
    }
  }, [organizationLoading]);


  const organizationAvatar = R.pathOr(defaultLogo, ['image', 'downloadUrl'], organization);
  const organizationName = R.pathOr('', ['name'], organization);
  const permissionRole = !organizationLoading && !userAccountInfoLoading && getUserRoleAtOrganization(userAccountInfo, organization);

  if (!isUserAtOrganization(userAccountInfo, organization)) {
    history.push(APP_URL.developerHome);
  }

  return (
    <OrganizationPage>
      <CommonFlowBars />
      <UserFlow.HeaderWhite
        position="relative"
        size="md"
        rightContent={ (
          <Row gap="lg" alignItems="center">
            <UserFlow.LearningCenter />
            <UserFlow.UserDropdown />
          </Row>
        ) }
      />
      <OrganizationPlate
        nav={ (
          <AppFlow.SecondaryNav.Plate css={ css`width: 100%;` }>
            <Column css={ css`padding: 0 24px 24px 24px;` } gap="lg">
              <StyledOrganizationLink
                to={ buildUrl(APP_URL.developerHomeOrganization, { pathParams: { organizationId }}) }
                css={ css`width: 100%;` }
              >
                <Row stretch>
                  <OrganizationImage
                    src={ organizationAvatar }
                  />
                  <Column gap="none" grow={ 1 } stretch css={ css`overflow: hidden;` } justifyContent="center">
                    <StyledCardTitle
                      ellipsis
                      kind="h4"
                      color="DARK_BLACK"
                    >
                      { organizationName }
                    </StyledCardTitle>
                    <Text kind="small-1">
                      Organization
                    </Text>
                  </Column>
                </Row>
              </StyledOrganizationLink>
              <StyledButtonBack
                to={ buildUrl(APP_URL.developerHomeOrganization, { pathParams: { organizationId }}) }
                alignItems="center"
              >
                <Icon name="ArrowBack" size="md" />
                <Text kind="h4" css={ css`display: flex; align-items: center; margin-left: 12px;` }>
                    Back
                </Text>
              </StyledButtonBack>
            </Column>
            <AppFlow.SecondaryNav.Item
              label={ i18n.t('organization.nav.general', { defaultValue: 'General' }) }
              to={{
                pathname: buildUrl(APP_URL.organizationSettingsGeneral, { pathParams: { organizationId }}),
              }}
            />
            <AppFlow.SecondaryNav.Item
              label={ i18n.t('organization.nav.team', { defaultValue: 'Team' }) }
              to={{
                pathname: buildUrl(APP_URL.organizationSettingsTeam, { pathParams: { organizationId }}),
              }}
            />
            { BILLING_AVAILABLE_ROLES.includes(permissionRole) && (
              <AppFlow.SecondaryNav.Item
                label={ i18n.t('organization.nav.billing', { defaultValue: 'Billing' }) }
                to={{
                  pathname: buildUrl(APP_URL.organizationSettingsBilling, { pathParams: { organizationId }}),
                }}
              />
            ) }
          </AppFlow.SecondaryNav.Plate>
        ) }
        content={ (
          <AppFlow.Content>
            <Switch>
              <Route exact path={ APP_URL.organizationSettingsGeneral } component={ OrganizationGeneral } />
              <Route path={ APP_URL.organizationSettingsTeam } component={ OrganizationTeam } />
              <Route
                exact
                path={ APP_URL.organizationSettingsBilling }
                render={ () => (
                  <OrganizationBilling
                    shouldRedirect={ permissionRole && !BILLING_AVAILABLE_ROLES.includes(permissionRole) }
                  />
                ) }
              />
              <Redirect from={ APP_URL.organization } to={ APP_URL.organizationSettingsGeneral } />
            </Switch>
          </AppFlow.Content>
        ) }
      />
    </OrganizationPage>
  );
};

export default Organizations;
