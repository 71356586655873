import React from 'react';
import { Form, Field } from '@8base-react/forms';
import { requiredWithSpaces } from 'utils/formValidations';
import { Divider } from '@material-ui/core';
import styled from '@emotion/styled';
import { AvatarFieldWithButton } from 'pro';
import { InputField, TextAreaField, Button } from '@8base/boost';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18n';

const StyledFormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: end;
  margin-top: 38px;
`;

const StyledSubFormContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const StyledFormInputsContainer = styled('div')`
  width: 70%;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 120px;
  font-family: "Poppins",serif !important;
  font-weight: 500;
  color: #FF2925;
`;

const StyledAvatarUpdateContainer = styled('div')`
 width: 30%;
`;

export const SettingsPage = ({ initialValues, onSubmit, submitting, onDeleteProject }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={ onSubmit } initialValues={ initialValues }>
      { ({ handleSubmit, submitError, pristine }) => (
        <StyledFormContainer onSubmit={ handleSubmit }>
          <If condition={ submitError }>
            <div>{ submitError }</div>
          </If>
          <StyledSubFormContainer>
            <StyledFormInputsContainer>
              <Field
                name="name"
                component={ InputField }
                label={ i18n.t('project.settings.placeholder.projectName', { defaultValue: 'Project name' }) }
                placeholder={ t('project.settings.placeholder.projectName') }
                autoComplete={ false }
                validate={ value => requiredWithSpaces(value) }
                disabled={ submitting }
              />

              <Field
                name="description"
                component={ TextAreaField }
                label={ i18n.t('project.settings.placeholder.projectDescription', { defaultValue: 'Description' }) }
                placeholder={ t('project.settings.placeholder.projectDescription') }
                rows={ 5 }
                autoComplete={ false }
                disabled={ submitting }
              />

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                  disabled={ submitting || pristine }
                >
                  Save
                </Button>
              </div>

              <Divider />

              <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <p style={{ fontSize: 14 }}>
                  { t('settings.general.deleteProjectTitle') }
                </p>
                <p style={{ fontSize: 12 }}>
                  { t('settings.general.deleteProjectNote') }
                </p>
                <StyledButton
                  variant="outlined"
                  color="error"
                  disabled={ submitting }
                  onClick={ (event) => onDeleteProject(event) }
                >
                  { t('settings.general.deleteProjectButton') }
                </StyledButton>
              </div>
            </StyledFormInputsContainer>

            <StyledAvatarUpdateContainer style={{ marginLeft: 48 }}>
              <Field name="image" component={ AvatarFieldWithButton } />
            </StyledAvatarUpdateContainer>
          </StyledSubFormContainer>
        </StyledFormContainer>
      ) }
    </Form>
  );
};
