// @flow
import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { Billing } from './components/';
// $FlowFixMe
import { useParams, Redirect } from 'react-router-dom';
import { APP_URL, useBuildUrl } from 'common/routing';
import { getDateFromFirstDayOfMonth } from 'utils/date';
import { useProjectPlan, useProjectFrontend } from 'graphql/hooks';

type ProjectBillingProps = {
  projectInfo: Object | null,
  shouldRedirect: any
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

// $FlowFixMe waiting for update Flow
export const ProjectBilling = ({ shouldRedirect, projectInfo }: ProjectBillingProps) => {
  const buildUrl = useBuildUrl();
  const { projectId } = useParams();
  const apiHost = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'apiHost'], projectInfo);
  const { projectPlan, refetch: refetchProjectPlan } = useProjectPlan(projectId, apiHost);
  const { projectFrontend } = useProjectFrontend(projectId);

  const paymentDetails = R.prop('paymentDetail', projectPlan);
  const plan = R.prop('plan', projectPlan);
  const paymentDate = DateTime.fromISO(R.propOr('', 'paymentDate', plan)).toLocaleString(DateTime.DATE_SHORT);
  const amountDue = R.propOr(0, 'overagesPrice', projectPlan) + R.propOr(0, 'price', plan);

  let fromPaymentDate = undefined;

  if (projectPlan) {
    const calculateDate = new Date(getDateFromFirstDayOfMonth(paymentDate));
    if (isValidDate(calculateDate)) {
      fromPaymentDate = DateTime.fromISO(calculateDate.toISOString()).toLocaleString(DateTime.DATE_SHORT);
    }
  }
  if (shouldRedirect) {
    const fallbackUrl = buildUrl(APP_URL.projectDashboard, {
      pathParams: {
        projectId,
      },
      ignoreWorkspaceId: true,
    });

    return <Redirect to={ fallbackUrl } />;
  }
  return (
    <Billing
      card={ paymentDetails }
      countUsers={ projectInfo?.projectUsers?.count || 0 }
      countWorksPaces={ projectInfo?.backendWorkspaces?.count + projectFrontend?.frontendWorkspaces?.count }
      price={ amountDue }
      apiHost={ apiHost }
      fromPaymentDate={ fromPaymentDate }
      nextPaymentDate={ paymentDate }
      projectId={ projectInfo?.id }
      refetchProjectPlan={ refetchProjectPlan }
    />
  );
};
