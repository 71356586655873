import { AsyncContent, Column, Row, Scrollable, Text } from '@8base/boost';
import { css } from '@emotion/core';
import React from 'react';
import { Trans } from 'utils/translate';

import SuccessIcon from '@material-ui/icons/Check';
import FailedIcon from '@material-ui/icons/ErrorOutline';

import FrontendIcon from '@material-ui/icons/DesktopWindows';

import styled from '@emotion/styled';

import { DateTime } from 'luxon';

import { apolloProjectDashboardSelectors } from 'graphql/selectors';
import { CenteredCardBody, StyledCard, StyledCardHeader, StyledCardTitleText } from './StyledComponents';

const StyledFrontendIcon = styled(FrontendIcon)`
  color: ${props => props.theme.COLORS.WHITE};

`;

const DeploySuccessIcon = styled(SuccessIcon)`
    color: ${props => props.theme.COLORS.GREEN_40} !important;
`;

const DeployFailedIcon = styled(FailedIcon)`
    color: ${props => props.theme.COLORS.RED_40} !important;
`;

const DividerNav = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledCardSubTitleText = styled(Text)`
   font-size: 14px !important;
   color:  ${props => props.theme.COLORS.GRAY_60}
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
   color:  ${props => props.theme.COLORS.GRAY_50}
`;

const StyledCardBodyText = styled(Text)`
   font-size: 13px !important;
   margin-bottom: 0 !important;
   color:  ${props => props.theme.COLORS.GRAY_60}
`;

const KindIconBackground = styled.div`
        width: 20px;
        height: 20px;
        display: flex;
        /* align-content: center; */
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #ABB1B8;
`;

const LatestDeploymentsCard = ({ title, icon, link, projectInfo, loading }: *) => {
  const deployments = apolloProjectDashboardSelectors.getProjectDeployments(projectInfo);

  const today = new Date();
  const days = deployments.flatMap((deploy) => DateTime.fromJSDate(today).diff(DateTime.fromISO(deploy.dateDeploy), 'day').days.toFixed());
  const lastDays = Math.max(...days) === 0 ? 1 : Math.max(...days);

  return (
    <StyledCard>
      <StyledCardHeader style={{ marginBottom: 14 }}>
        <Row alignItems="center">
          { icon }
          <StyledCardTitleText>
            { title }
          </StyledCardTitleText>
        </Row>
        { link }
      </StyledCardHeader>
      <CenteredCardBody>
        <AsyncContent loading={ loading }>
          <Choose>
            <When condition={ deployments.length > 0 }>
              <Column gap="lg" stretch>
                <StyledCardSubTitleText>{ `${deployments.length} deployments in the last ${lastDays} days` }</StyledCardSubTitleText>
                <Scrollable css={ css`max-height: 200px;` }>
                  { deployments.map(({ versionDeploy, versionFrontEnd, name, statusDeploy, kind, dateDeploy }) => {
                    return (
                      <Column key={ versionDeploy }>
                        <DividerNav />
                        <Row stretch alignItems="center" justifyContent="evenly">
                          <If condition={ statusDeploy.toUpperCase() === 'RESOLVED' }>
                            <DeploySuccessIcon css={ css`padding-left: 0;` } />
                          </If>
                          <If condition={ statusDeploy.toUpperCase() === 'REJECTED' }>
                            <DeployFailedIcon css={ css`padding-left: 0;` } />
                          </If>
                          <Column css={ css`padding-left: 0;` }>
                            <StyledCardBodyText>{ `${versionDeploy} Version ${versionFrontEnd} - ${DateTime.fromISO(dateDeploy).toLocaleString(DateTime.DATETIME_MED)}` }</StyledCardBodyText>
                            <Row alignItems="center" justifyContent="flex-start" css={ css`padding-left: 0;` }>
                              <KindIconBackground>
                                <StyledFrontendIcon />
                              </KindIconBackground>
                              <Text css={ css`margin-bottom: 5px !important;` }>{ `${name}` }</Text>
                            </Row>

                          </Column>
                        </Row>
                      </Column>
                    );
                  }) }
                </Scrollable>
              </Column>
            </When>
            <Otherwise>
              <StyledCardContentText>
                <Trans i18nKey="projects.dashboard.noDeployments">No deployments found</Trans>
              </StyledCardContentText>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </CenteredCardBody>
    </StyledCard>
  );
};

export { LatestDeploymentsCard };
