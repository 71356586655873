// @flow
import React from 'react';
import { Avatar, Column, Dropdown, Icon, Link, Row, Switch, Text, Tooltip } from '@8base/boost';

import { Trans } from 'utils/translate';
import styled from '@emotion/styled';

const StyledIcon = styled(Icon)(props => ({
  transform: props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
  transition: 'all .3s ease',
}));

const StyledText = styled(Text)(props => ({
  fontSize: '1rem',
}));

type UserDropdownProps = {
  firstName: string;
  lastName: string;
  email: string;
  isOwner?: boolean;
  insideWorkspace?: boolean;
  avatarDownloadUrl?: string;
  openMyAccount?: (callbackAfterAction: Function) => any;
  openLogOutModal: (callbackAfterAction: Function) => any;
  onChangeLearningMode: () => any;
  learningMode: boolean;
  hideLearningMode?: boolean;
}

export const UserDropdownDetailed = ({
  firstName,
  lastName,
  isOwner,
  insideWorkspace,
  email,
  avatarDownloadUrl,
  openMyAccount,
  openLogOutModal,
  onChangeLearningMode,
  learningMode,
  hideLearningMode,
}: UserDropdownProps) => {

  const tooltipMessage = (
    <span css={{ fontSize: 12 }}>
      <b css={{ fontWeight: 500 }}>Learning Mode</b> shows you how to<br />get started using 8base.
    </span>
  );

  const learningModeLabel = (
    <Row alignItems="center">
      <Text color="WHITE">
        <Trans i18nKey="shared.learningMode">Learning Mode</Trans>
      </Text>
      <Tooltip css={{ display: 'flex' }} placement="bottom-end" message={ tooltipMessage } cursor="default">
        <Icon name="Info" size="xs" />
      </Tooltip>
    </Row>
  );

  return (
    <Dropdown defaultOpen={ false }>
      <Dropdown.Head data-e2e-id={ E2E.$id('root.userMenuToggle') }>
        <Row alignItems="center">
          <Column gap="none" alignItems="end">
            <If condition={ insideWorkspace }>
              <Text kind="small-1" color="LIGHT_TEXT_COLOR">
                <Choose>
                  <When condition={ isOwner }>
                    <Trans i18nKey="shared.workspaceOwner">Workspace Owner</Trans>
                  </When>
                  <Otherwise>
                    <Trans i18nKey="shared.workspaceMember">Workspace Member</Trans>
                  </Otherwise>
                </Choose>
              </Text>
            </If>
            <StyledText color="WHITE" weight="semibold">{ `${firstName || ''} ${lastName || ''}` }</StyledText>
            <StyledText kind="overline-1" color="GRAY_20">{ email }</StyledText>
          </Column>
          <Avatar size="sm" firstName={ firstName } lastName={ lastName } src={ avatarDownloadUrl } />
          <StyledIcon isOpen="true" name="ChevronDown" size="xs" color="WHITE" />
        </Row>
      </Dropdown.Head>
      <Dropdown.Body
        background="dark"
        withPortal
        offset="sm"
        pin="right"
        padding="lg"
        data-e2e-id={ E2E.$id('root.userMenuBody') }
      >
        { ({ closeDropdown }) => (
          <Column>
            <div css={{ height: 1, width: '100%', background: '#A5AFB6', opacity: 0.2 }} />

            <If condition={ !!openMyAccount }>
              <Link
                css={{ fontSize: 12, '&:active': { color: 'white' }}}
                color="WHITE" onClick={ openMyAccount && openMyAccount(closeDropdown) }
                { ...E2E.$props('root.myAccountLink') }>
                  My Account
              </Link>
            </If>
            <Link
              css={{ fontSize: 12, '&:active': { color: 'white' }}}
              color="WHITE"
              onClick={ openLogOutModal(closeDropdown) }>
              Logout
            </Link>
            <If condition={ !hideLearningMode } >
              <div css={{ height: 1, width: '100%', background: '#A5AFB6', opacity: 0.2 }} />

              <Switch label={ learningModeLabel } value={ learningMode } onChange={ onChangeLearningMode } />
            </If>
          </Column>
        ) }
      </Dropdown.Body>
    </Dropdown>
  );
};
