// $FlowFixMe waiting for update Flows
import React, { Suspense, lazy, useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { useAuth } from '@8base-react/auth';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContextProvider } from 'react-dnd';
import { BoostProvider, createTheme, Loader } from '@8base/boost';
import { TableSchemaProvider } from '@8base-react/table-schema-provider';
import { PermissionsProvider } from '@8base-react/permissions-provider';
import { StripeProvider } from 'react-stripe-elements';
import { MuiThemeProvider, createTheme as createThemeMUI } from '@material-ui/core/styles';
import { getUserRoleAtOrganization } from 'utils/organization';
import { APP_URL } from 'common/routing';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import {
  ErrorBoundary,
  ToastNotification,
  WaitingComponent,
  AuthSessionChecker,
  ClickjackingProtector,
} from 'common';
import { SentryScope } from 'common/SentryScope';
import { TEAM_MEMBER_QUERY } from 'graphql/queries';
import {
  AnalyticsProvider,
  AppStateProvider,
  AsyncApolloProvider,
  BillingProvider,
  ReferralProvider,
  UIProvider,
  useWorkspace,
  WorkspaceProxyProvider,
  ApiHostProvider,
  CookieStorageProvider,
  AuthenticationProvider,
} from 'providers';
import { getStripeApiKey, showServicePage } from 'utils/processEnv';
import { useUserAccountInfo, useWorkspacesList } from 'graphql/hooks';

import { i18n } from './i18n';
import { Routes } from './routes';
import * as icons from './icons';


import './productTour';
import './styles/main.scss';

import CliRoutes from './routes/cli';
import { IGNORE_WORKSPACE } from './common/constants/apolloOperationContextOptions';
import { ServicePage } from './routes/servicePage/ServicePage';
import { muiBaseTheme } from './styles/muiTheme';
import { ProjectProvider } from './providers/ProjectProvider/ProjectProvider';
import { NavigationHistoryProvider } from 'providers/HistoryProvider/HistoryProvider';

// const AuthRoute = lazy(() =>
//   import(/* webpackChunkName: "auth" */ './routes/auth'),
// );

const ArchieRoute = lazy(() => import(/* webpackChunkName: "archie" */ './routes/archie'));

const theme = createTheme({
  COLORS: {
    DARK_BLACK: '#000000',
  },
  SIZES: {
    USER_FLOW: {
      headerLgHeight: '80px',
      headerMdHeight: '56px',
    },
  },
});

const newTheme = createThemeMUI(muiBaseTheme);

const OtherRoutes = () => {
  const { isAuthorized, isEmailVerified } = useAuth();
  const { id: workspaceId, project, organization } = useWorkspace();
  localStorage.setItem('projectId', project?.id);
  const { loading: workspacesListLoading } = useWorkspacesList('', {
    notifyOnNetworkStatusChange: false,
  });
  const { loading: userAccountInfoLoading, userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
  });

  const permissionRole = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  return (
    <TableSchemaProvider
      notifyOnNetworkStatusChange={ false }
      skip={ !isAuthorized || !workspaceId || !isEmailVerified }
      context={{ noBatch: true }}
    >
      <PermissionsProvider
        customQuery={ TEAM_MEMBER_QUERY }
        notifyOnNetworkStatusChange={ false }
        skip={ !isAuthorized || !workspaceId || !isEmailVerified }
        context={{ noBatch: true }}
      >
        { ({ loading }) => (
          <If condition={ !loading && !workspacesListLoading && !userAccountInfoLoading }>
            <BillingProvider skip={ permissionRole === ORGANIZATION_ROLE.developer }>
              <AnalyticsProvider>
                <Routes />
              </AnalyticsProvider>
            </BillingProvider>
          </If>
        ) }
      </PermissionsProvider>
    </TableSchemaProvider>
  );
};

const App = () => {
  if (showServicePage()) {
    return (
      <BoostProvider icons={ icons } theme={ theme }>
        <BrowserRouter>
          <ServicePage />
        </BrowserRouter>
      </BoostProvider>
    );
  }

  return (
    <ClickjackingProtector>
      <MuiThemeProvider theme={ newTheme }>
        <CookieStorageProvider>
          <BoostProvider icons={ icons } theme={ theme }>
            <BrowserRouter>
              <NavigationHistoryProvider>
                <Suspense fallback={ <Loader /> }>
                  <ErrorBoundary>
                    <DragDropContextProvider backend={ HTML5Backend }>
                      <StripeProvider apiKey={ getStripeApiKey() }>
                        <I18nextProvider i18n={ i18n }>
                          <ReferralProvider>
                            <AuthenticationProvider>
                              <AuthSessionChecker />
                              <WorkspaceProxyProvider>
                                <AppStateProvider>
                                  <UIProvider>
                                    <AsyncApolloProvider>
                                      <ApiHostProvider>
                                        <ProjectProvider>
                                          <SentryScope>
                                            <Switch>
                                              <Route path={ APP_URL.auth } render={ () => (
                                                <WaitingComponent>
                                                  <ArchieRoute />
                                                </WaitingComponent>
                                              ) } />
                                              <Route path={ APP_URL.cli } render={ () => (
                                                <CliRoutes />
                                              ) } />
                                              { /* <Route path={ APP_URL.archie } component={ ArchieRoute } /> */ }
                                              <Route component={ OtherRoutes } />
                                            </Switch>
                                            <ToastNotification />
                                          </SentryScope>
                                        </ProjectProvider>
                                      </ApiHostProvider>
                                    </AsyncApolloProvider>
                                  </UIProvider>
                                </AppStateProvider>
                              </WorkspaceProxyProvider>
                            </AuthenticationProvider>
                          </ReferralProvider>
                        </I18nextProvider>
                      </StripeProvider>
                    </DragDropContextProvider>
                  </ErrorBoundary>
                </Suspense>
              </NavigationHistoryProvider>
            </BrowserRouter>
          </BoostProvider>
        </CookieStorageProvider>
      </MuiThemeProvider>
    </ClickjackingProtector>
  );
};

export default App;
