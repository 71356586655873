// @flow

import React from 'react';
import { compose } from 'recompose';
import { Dialog, Button, useModal } from '@8base/boost';
import { getCookieDomain } from 'utils/getCookieDomain';
import { withTranslation } from 'react-i18next';
import { withApollo } from 'react-apollo';
import Cookies from 'js-cookie';

import { SELECTED_COLUMNS } from 'common/constants/localStorageKeys';
// $FlowFixMe
import { AUTH_KEY, withCookieStorage } from '../providers';
import { withAuth } from '@8base-react/auth';

export const LOGOUT_DIALOG_ID = 'LOGOUT_DIALOG_ID';

let LogOutDialog = ({ t, cookieStorage, auth }) => {
  const { closeModal } = useModal();

  const onClose = () => {
    closeModal(LOGOUT_DIALOG_ID);
  };

  return (
    <Dialog id={ LOGOUT_DIALOG_ID } size="sm">
      <Dialog.Header title={ t('root.logoutDialog.title') } onClose={ onClose } />
      <Dialog.Body>
        { t('root.logoutDialog.text') }
      </Dialog.Body>
      <Dialog.Footer>
        <Button color="neutral" variant="outlined" onClick={ async () => {
          window.localStorage.removeItem(SELECTED_COLUMNS);
          cookieStorage.setItem(AUTH_KEY, '{}');
          cookieStorage.removeItem(AUTH_KEY);
          const domain = getCookieDomain();
          auth.authClient.logout();
          Cookies.set('8base-authenticated', 'false', { expires: 1, domain });
        } }>
          { t('shared.yes') }
        </Button>
        <Button color="neutral" variant="outlined" onClick={ onClose }>
          { t('shared.no') }
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

LogOutDialog = compose(
  withApollo,
  withCookieStorage,
  withAuth,
  withTranslation(),
)(LogOutDialog);

LogOutDialog.ID = LOGOUT_DIALOG_ID;

export { LogOutDialog };
