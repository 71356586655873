// @flow
// $FlowFixMe
import React from 'react';
import { Text, Row, Icon, styled, Button } from '@8base/boost';
import * as R from 'ramda';
import { i18n } from 'i18n';
import { useMutation } from 'react-apollo';
// $FlowFixMe
import { useHistory } from 'react-router-dom';

import type { InboxItemFragment } from 'graphql/__generated__';
import { NOTIFICATION_UPDATE_MUTATION, ORGANIZATION_INVITATION_ACCEPT_MUTATION, ORGANIZATION_INVITATION_CANCEL_MUTATION, TEAM_INVITATION_ACCEPT_MUTATION } from 'graphql/mutations';
import {
  INBOX_LIST_QUERY,
  UNDONE_INBOX_COUNT_QUERY,
  ORGANIZATIONS_LIST_QUERY,
  WORKSPACES_LIST_QUERY,
  PROJECTS_USER_LIST_QUERY,
} from 'graphql/queries';
import { ENDPOINT_URI, TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { INBOX_NOTIFICATION_KIND, INBOX_STATUS, INBOX_TYPE } from 'common/constants/inbox';
import { Trans } from 'utils/translate';
import { APP_URL, buildUrl } from 'common';

import { PROJECT_INVITATION_ACCEPT_MUTATION, PROJECT_INVITATION_CANCEL_MUTATION } from 'graphql/mutations/projectInvitations';

type InboxActionManagerProps = {
  inbox: InboxItemFragment;
};

const DeclinedText = styled(Text)`
  color: ${({ theme }) => theme.COLORS.GRAY_50};
`;

const PAGE_SIZE = 15;
const QUERY_VARIABLES_DONE = { first: PAGE_SIZE, filter: { isCompleted: { equals: 1 }}};
const QUERY_VARIABLES_UNDONE = { first: PAGE_SIZE, filter: { isCompleted: { equals: 0 }}};
const COUNT_PATH = ['system', 'inboxEventsListCount', 'count'];
const ITEMS_PATH = ['system', 'inboxEventsList', 'items'];

/**
 * update apollo cache
 */
const completeInboxItem = (inbox: InboxItemFragment, status) => (cache, { data }) => {
  if (!data || !inbox.id) {
    return;
  }

  const undoneCachedData = cache.readQuery({ query: UNDONE_INBOX_COUNT_QUERY });

  const inboxDoneCachedData = cache.readQuery({ query: INBOX_LIST_QUERY, variables: QUERY_VARIABLES_DONE });
  const inboxUndoneCachedData = cache.readQuery({ query: INBOX_LIST_QUERY, variables: QUERY_VARIABLES_UNDONE });

  const doneInboxList = R.pathOr([], ITEMS_PATH, inboxDoneCachedData);
  const undoneInboxList = R.pathOr([], ITEMS_PATH, inboxUndoneCachedData);

  const inboxList = [...doneInboxList, ...undoneInboxList];
  const currentIndex = inboxList.findIndex(el => el.id === inbox.id && el.createdAt === inbox.createdAt);

  const newInboxList = [...inboxList];
  newInboxList[currentIndex].isCompleted = true;
  newInboxList[currentIndex].details.status = status;
  const firstCompletedIndex = inboxList.findIndex(el => el.isCompleted);
  const movedInboxList = R.move(currentIndex, firstCompletedIndex, inboxList);

  const newInboxDoneList = movedInboxList.filter((item) => item.isCompleted);
  const newInboxUndoneList = movedInboxList.filter((item) => !item.isCompleted);

  const doneCount = R.pathOr(0, COUNT_PATH, inboxDoneCachedData);
  const newDoneCount = doneCount + 1;

  const undoneCount = R.pathOr(1, COUNT_PATH, inboxUndoneCachedData);
  const newUnDoneCount = undoneCount - 1;

  const newDoneData = R.mergeDeepRight(inboxDoneCachedData, { system: {
    inboxEventsList: {
      items: newInboxDoneList,
    },
    inboxEventsListCount: {
      count: newDoneCount,
    },
  }});

  const newUndoneData = R.mergeDeepRight(inboxUndoneCachedData, { system: {
    inboxEventsList: {
      items: newInboxUndoneList,
    },
    inboxEventsListCount: {
      count: newUnDoneCount,
    },
  }});

  const newUndoneCountData = R.mergeDeepRight(undoneCachedData, {
    system: {
      undoneInboxCount: {
        count: newUnDoneCount,
      },
    },
  });

  cache.writeQuery({
    query: UNDONE_INBOX_COUNT_QUERY,
    data: newUndoneCountData,
  });
  cache.writeQuery({
    query: INBOX_LIST_QUERY,
    variables: QUERY_VARIABLES_DONE,
    data: newDoneData,
  });
  cache.writeQuery({
    query: INBOX_LIST_QUERY,
    variables: QUERY_VARIABLES_UNDONE,
    data: newUndoneData,
  });
};

const getNotificationLink = (inbox: InboxItemFragment) => {
  const notificationKind = R.path(['details', 'kind'], inbox);
  const getDefaultLink = () => {
    switch (notificationKind) {
      case INBOX_NOTIFICATION_KIND.appBuilderBeta:
      {
        return APP_URL.developerHomeWorkspacesFrontend;
      }

      default:
      {
        return '/';
      }

    }
  };

  const defaultLink = getDefaultLink();
  const link = R.path(
    ['details', 'details', 'link'],
    inbox,
  );

  return { link, defaultLink };
};

/**
 * return list of actions
 */
const useActionManager = (inbox: InboxItemFragment) => {
  const history = useHistory();

  const acceptEnvMutation = useMutation(TEAM_INVITATION_ACCEPT_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.accepted),
    refetchQueries: [
      {
        query: WORKSPACES_LIST_QUERY,
      },
    ],
  });

  const declineEnvMutation = useMutation(TEAM_INVITATION_ACCEPT_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.declined),
  });

  const acceptOrgInvite = useMutation(ORGANIZATION_INVITATION_ACCEPT_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.accepted),
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
    },
    refetchQueries: [
      {
        query: ORGANIZATIONS_LIST_QUERY,
      },
      {
        query: WORKSPACES_LIST_QUERY,
      },
    ],
  });

  const declineOrgInvite = useMutation(ORGANIZATION_INVITATION_CANCEL_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.declined),
    context: { [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.declineSuccessfull') },
  });

  /**
   * @deprecated since reverse project users
   */
  const acceptProjectInvite = useMutation(PROJECT_INVITATION_ACCEPT_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.accepted),
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ORGANIZATIONS_LIST_QUERY,
      },
      {
        query: PROJECTS_USER_LIST_QUERY,
        variables: { organizationId: '' },
      },
    ],
  });

  /**
   * @deprecated since reverse project users
   */
  const declineProjectInvite = useMutation(PROJECT_INVITATION_CANCEL_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.declined),
    context: { [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.declineSuccessfull') },
  });

  const readNotification = useMutation(NOTIFICATION_UPDATE_MUTATION, {
    update: completeInboxItem(inbox, INBOX_STATUS.declined),
  });

  switch (inbox.type) {
    case INBOX_TYPE.envInvite: {
      const [accept, { loading: acceptLoading }] = acceptEnvMutation;
      const [decline, { loading: declineLoading }] = declineEnvMutation;
      const id = R.path(['id'], inbox);
      const apiHost = R.path(['details', 'workspace', 'apiHost'], inbox);

      const onAccept = async () => {
        if (!id) {
          return;
        }
        try {
          await accept({
            context: {
              [ENDPOINT_URI]: apiHost,
              [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
            },
            variables: {
              data: { id, accepted: true },
            },
          });
        } catch (e) {}
      };

      const onDecline = async () => {
        if (!id) {
          return;
        }

        try {
          await decline({
            context: {
              [ENDPOINT_URI]: apiHost,
              [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.declineSuccessfull'),
            },
            variables: {
              data: { id, accepted: false },
            },
          });
        } catch (e) {}
      };

      return [
        { action: onDecline, loading: declineLoading, disabled: acceptLoading, btnText: 'Decline', btnColor: 'neutral' },
        { action: onAccept, loading: acceptLoading, disabled: declineLoading, btnText: 'Accept', btnColor: undefined },
      ];
    }

    case INBOX_TYPE.projectInvite:
    case INBOX_TYPE.orgInvite: {
      const [accept, { loading: acceptLoading }] = inbox.type === INBOX_TYPE.orgInvite ? acceptOrgInvite : acceptProjectInvite;
      const [decline, { loading: declineLoading }] = inbox.type === INBOX_TYPE.orgInvite ? declineOrgInvite : declineProjectInvite;
      const invitationId = R.path(['id'], inbox);

      const onAccept = async () => {
        if (!invitationId) {
          return;
        }

        try {
          await accept({
            variables: {
              invitationId,
            },
          });
          if (inbox.type === INBOX_TYPE.projectInvite) {
            const projectId = R.path(['details', 'project', 'id'], inbox);

            const url = buildUrl(APP_URL.projectHome, {
              pathParams: { projectId },
            });
            history.push(url);
          }
        } catch (e) {}

      };

      const onDecline = async () => {
        if (!invitationId) {
          return;
        }

        try {
          await decline({
            variables: {
              invitationId,
            },
          });
        } catch (e) {}
      };

      return [
        { action: onDecline, loading: declineLoading, disabled: acceptLoading, btnText: 'Decline', btnColor: 'neutral' },
        { action: onAccept, loading: acceptLoading, disabled: declineLoading, btnText: 'Accept', btnColor: undefined },
      ];
    }

    case INBOX_TYPE.notification: {
      const [read, { loading: readLoading }] = readNotification;
      const notificationId = R.path(['id'], inbox);
      const { link, defaultLink } = getNotificationLink(inbox);

      const onReadNotification = async () => {
        if (!notificationId) {
          return;
        }
        try {
          await read({ variables: { id: notificationId, status: 'done' }});
        } catch (e) {}
      };

      const onViewNotification = async () => {
        if (link) {
          window.open(link);
        } else {
          history.push(defaultLink);
        }
      };

      return [
        { action: onReadNotification, loading: readLoading, disabled: readLoading, btnText: 'Mark as Read', btnColor: 'neutral' },
        { action: onViewNotification, loading: false, disabled: false, btnText: 'View', btnColor: undefined },
      ];
    }

    default: {
      return [];
    }
  }
};

export const InboxActionManager = ({ inbox }: InboxActionManagerProps) => {
  const status = R.path(['details', 'status'], inbox);
  const actionList = useActionManager(inbox);
  const history = useHistory();

  if (inbox.isCompleted) {
    if (inbox.type === INBOX_TYPE.notification) {
      const { link, defaultLink } = getNotificationLink(inbox);

      return (
        <Row alignItems="center">
          <Button onClick={ () => link ? window.open(link) : history.push(defaultLink) } >
            View
          </Button>
        </Row>
      );
    }

    switch (status) {
      case INBOX_STATUS.accepted: {
        return (
          <Row alignItems="center">
            <Icon name="Check" color="GREEN_40" size="sm" />
            <Text kind="h4">
              <Trans i18nKey="inbox.status.accepted">
                Accepted
              </Trans>
            </Text>
          </Row>
        );
      }

      case INBOX_STATUS.declined: {
        return (
          <Row alignItems="center">
            <Icon name="Close" color="GRAY_50" size="custom" customSize="14px" />
            <DeclinedText kind="h4">
              <Trans i18nKey="inbox.status.declined">
                Declined
              </Trans>
            </DeclinedText>
          </Row>
        );
      }

      default: {
        return null;
      }
    }
  }

  return (
    <Row gap="lg">
      { actionList.map(el => (
        <Button
          key={ el.btnText }
          color={ el.btnColor }
          disabled={ el.disabled }
          loading={ el.loading }
          onClick={ el.action }
        >
          { el.btnText }
        </Button>
      )) }
    </Row>
  );
};
