import { Column, Grid, Link, Row, useModal } from '@8base/boost';
import css from '@emotion/css';
import styled from '@emotion/styled';
import ArrowDownwardIcon from '@material-ui/icons/KeyboardArrowDown';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import React, { useState } from 'react';
import { WorkspaceCard } from './WorkspaceCard';
import * as R from 'ramda';
import { useProjectInfo, useProjectFrontend } from 'graphql/hooks';
import { Trans } from 'utils/translate';
import { apolloProjectDashboardSelectors } from 'graphql/selectors';
import { mapRegionToEndPoint } from 'utils';


const WorkspaceSelectorContainer = styled(Grid.Layout)`
  position: relative;
  z-index: 9999;
`;

const WorkspaceSelectorHead = styled(Grid.Layout)`
  background-color: ${props => props.theme.COLORS.WHITE};
  border: 1px solid #A3AFB7;
  box-shadow: 0px 2px 10px rgb(50 60 71 / 10%);
  border-radius: ${props => !props?.isOpen ? '8px;' : '8px 8px 0 0;'};
  display: grid;
  grid-template-columns: 11fr 1fr;
  overflow: hidden;
`;


const WorkspaceSelectorBodyContainer = styled.div`
  background-color: white;
  border-radius: 0 0 8px 8px;
  border-radius: 8px;
  border-top: none;
  border: 1px solid #A3AFB7;
  box-shadow: 0px 2px 10px rgb(50 60 71 / 10%);
  display: ${props => props?.isOpen ? 'block' : 'none'};
  opacity: ${props => props?.isOpen ? '1' : '0'};
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: 0.4s ease-in-out;
  width: 100%;
`;


const PointerCursorRow = styled(Row)`
  cursor: pointer !important;
`;

const StyledIconButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  ${props => props.isOpen && `
  transform: rotatex(180deg);
  `}
`;

const StyledIconContainer = styled.div`
  width: 100% !important;
  height:100% !important;
  border-radius:0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPointerZone = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left:0;
  z-index: 999;
`;

export const WorkspaceSelector = ({ projectInfo, projectDeployments, workspaces }) => {
  const [isOpen, setIsOpen] = useState(false);

  const deployments = apolloProjectDashboardSelectors.getProjectDeployments(projectDeployments);

  const { openModal } = useModal();
  const [selectedWorkspace, setSelectedWorkspace] = useState(workspaces?.items[0]);

  const latestDeployment = deployments.filter((deployment) => deployment.workspaceId === selectedWorkspace.id).at(0);

  const projectId = R.prop('id', projectInfo);
  const apiHost = R.prop('apiHost', projectInfo);
  const organizationId = R.path(['organization', 'id'], projectInfo);
  const authProfiles = R.path(['authenticationInfo'], projectInfo);
  const region = mapRegionToEndPoint(R.path(['region'], projectInfo));
  const { refetch: refetchFrontend } = useProjectFrontend(projectId, apiHost);


  const { refetch } = useProjectInfo(projectId, apiHost);
  const onCreateNewWorkspace = () => {
    openModal(WorkspaceCreateDialog.ID,
      {
        organizationId,
        projectId,
        kind: WORKSPACE_KIND.frontend,
        authProfiles,
        region,
        workspaces: workspaces?.items,
        onSuccess: async (_workspaceId, is8BaseAuthEnbled) => {
          refetch();
          refetchFrontend();

        },
      },
    );
  };

  const handleIsOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <If condition={ isOpen } >
        <StyledPointerZone onClick={ handleIsOpen } />
      </If>
      <WorkspaceSelectorContainer >
        <WorkspaceSelectorHead isOpen={ isOpen } >
          <WorkspaceCard stretch workspaceInfo={ selectedWorkspace } latestDeployment={ latestDeployment } withOpenButton />
          <StyledIconContainer >
            <StyledIconButton isOpen={ isOpen } onClick={ handleIsOpen } >
              <ArrowDownwardIcon fontSize="large" />
            </StyledIconButton>
          </StyledIconContainer>
        </WorkspaceSelectorHead>
        <WorkspaceSelectorBodyContainer isOpen={ isOpen } >
          <Column gap="none">
            { workspaces?.items.map((workspace) => (
              <PointerCursorRow
                key={ workspace.id }
                stretch
                onClick={ () => {
                  setSelectedWorkspace(workspace);
                  handleIsOpen();
                } }
              >
                <WorkspaceCard isItem workspaceInfo={ workspace } projectInfo={ projectInfo }
                  latestDeployment={ deployments.filter((deployment) => deployment.workspaceId === workspace.id).at(0) } />
              </PointerCursorRow>
            )) }
            <Link
              css={ css`cursor: pointer !important; padding: 16px 20px 16px 16px;` }
              onClick={ () => {
                onCreateNewWorkspace(WorkspaceCreateDialog.ID);
              } }
            >
              <Trans i18nKey="projects.dashboard.newUserInterfaceLink">Add New Frontend</Trans>
            </Link>
          </Column>
        </WorkspaceSelectorBodyContainer>
      </WorkspaceSelectorContainer>
    </>
  );
};
