// @flow
import { Button } from '@8base/boost';

import styled from '@emotion/styled';

const UserFlowButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;

  height: ${props => {
  switch (props.size) {
    case 'lg': return '60px';
    case 'sm': return '36px';
    case 'md':
    default: return '48px';
  }
}};

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 0 24px;
  }
`;

export { UserFlowButton };

