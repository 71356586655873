// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import {
  Card,
  Text,
  Heading,
  TableBuilder,
  Link,
  Tag,
} from '@8base/boost';
// $FlowFixMe
import { useParams } from 'react-router-dom';
// $FlowFixMe
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';

import { Trans } from 'utils/translate';

const BILLING_INVOICES_LIST_QUERY = gql`
  query OrganizationBillingHistory($organizationId: ID!) {
    system {
      organizationBillingHistory(organizationId: $organizationId) {
        items {
          periodStart
          periodEnd
          paid
          invoicePdf
          amountDue
          amountPaid
          amountRemaining
          endingBalance
          number
          status
          total
          description
          plan {
            displayName
          }
          project {
            name
          }
        }
      }
    }
  }
`;

const BILLING_HISTORY_COLUMNS = [
  { name: 'date', title: 'Date', width: 'minmax(120px, 1fr)' },
  { name: 'invoiceId', title: 'Invoice ID', width: 'minmax(160px, 1fr)' },
  { name: 'description', title: 'Description', width: 'minmax(500px, 1fr)' },
  { name: 'status', title: 'Status', width: 'minmax(140px, 1fr)' },
  { name: 'total', title: 'Amount', width: 'minmax(120px, 1fr)' },
  { name: 'invoicePdf', width: 'minmax(120px, 1fr)' },
];


const COLOR_BY_STATUS = {
  open: 'GRAY_40',
  draft: 'GRAY_40',
  void: 'GRAY_40',
  paid: 'GREEN',
  uncollectible: 'YELLOW',
};

const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)),
);

const getInvoices = R.pipe(
  R.pathOr([], ['system', 'organizationBillingHistory', 'items']),
  R.map(
    renameKeys({
      periodStart: 'date',
      invoicePdf: 'invoicePdf',
      total: 'total',
      number: 'invoiceId',
    }),
  ),
);

export const BillingHistory = () => {
  const { organizationId } = useParams();
  const { data, loading } = useQuery(BILLING_INVOICES_LIST_QUERY, {
    variables: {
      organizationId,
    },
    skip: !organizationId,
  });

  // TODO: add shared component for this table (account-billing, workspace-billing, organization-billing)
  return (
    <Card>
      <Card.Header>
        <Heading type="h2" padding="none">
          <Trans i18nKey="settings.billing.billingHistory.title">Billing History</Trans>
        </Heading>
      </Card.Header>
      <Card.Body padding="none">
        <TableBuilder
          columns={ BILLING_HISTORY_COLUMNS }
          data={ getInvoices(data) }
          loading={ loading }
          renderCell={ ({ name }, data) => {
            if (name === 'invoicePdf') {
              return (
                <Link
                  css={ css`
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  ` }
                  href={ data[name] }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="settings.billing.billingHistory.viewPdf">View PDF</Trans>
                </Link>
              );
            }

            if (name === 'date') {
              return (
                <Text
                  css={ css`
                    white-space: nowrap;
                  ` }
                >
                  { DateTime.fromISO(data[name]).toFormat('MM/dd/yyyy') }
                </Text>
              );
            }

            if (name === 'total') {
              return (
                <Text
                  css={ css`
                    white-space: nowrap;
                    width: 100%;
                  ` }
                  weight="semibold"
                >
                  ${ data[name].toFixed(2) }
                </Text>
              );
            }

            if (name === 'status') {
              return (
                <Tag
                  css={ css`
                    text-transform: capitalize;
                  ` }
                  color={ COLOR_BY_STATUS[data[name]] }
                  bordered
                >
                  { data[name] }
                </Tag>
              );
            }

            return (
              <Text
                css={ css`
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                ` }
                weight="semibold"
              >
                { data[name] }
              </Text>
            );
          } }
        />
      </Card.Body>
    </Card>
  );
};
