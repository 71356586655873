// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';

import { HIDE_TOAST_ERROR_MESSAGE, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { ORGANIZATION_QUERY } from 'graphql/queries';

import type { OrganizationItemFullInfoFragment, OrganizationQuery, OrganizationQueryVariables } from '../__generated__';

export const useOrganization = (organizationId: string, options?: QueryHookOptions) => {
  const { isAuthorized } = useAuth();

  const {
    data,
    ...rest
  }: QueryResult<OrganizationQuery, OrganizationQueryVariables> = useQuery(ORGANIZATION_QUERY, {
    skip: !organizationId || !isAuthorized,
    fetchPolicy: 'cache-first',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
    },
    ...options,
    variables: {
      ...options,
      organizationId,
    },
  });

  const organization: OrganizationItemFullInfoFragment | null =
    (data && data.system && data.system.organizationById) || null;

  return {
    organization, ...rest,
  };
};
