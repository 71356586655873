import { useQuery, useLazyQuery } from 'react-apollo';
import { useAuth } from '@8base-react/auth';

import { USER_ACCOUNT_INFO_QUERY, GET_ACCOUNT_FROM_EMAIL } from '../queries';

const getUserAccountInfoData = (data, tokenData) => {
  const userAccountInfo = (data && data.system && data.system.memberAccount) || tokenData;

  return userAccountInfo;
};

export const useUserAccountInfo = (graphqlOptions = {}) => {
  const auth = useAuth();
  const tokenInfo = auth.authClient.getTokenInfo() || {};

  const tokenPredefinedResponseData = {
    email: tokenInfo.email,
    firstName: tokenInfo.given_name,
    lastName: tokenInfo.family_name,
    avatar: {
      id: '',
      downloadUrl: tokenInfo.picture,
      __typename: 'UserInfoAvatar',
    },
    companyName: '',
    intendedUse: '',
    createdAt: null,
    isDeveloper: null,
    isEmailVerified: null,
    learningMode: true,
    permissions: null,
    projectDescription: '',
    role: '',
    timezone: '',
    type: null,
    __typename: 'SystemUserAccountInfo',
  };

  const { data, ...rest } = useQuery(USER_ACCOUNT_INFO_QUERY, {
    skip: !auth.isAuthorized,
    ...graphqlOptions,
    onCompleted: data => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(getUserAccountInfoData(data, tokenPredefinedResponseData));
    },
  });

  const userAccountInfo = getUserAccountInfoData(data, tokenPredefinedResponseData);

  return {
    userAccountInfo,
    ...rest,
  };
};

export const useUserAccountFromEmailLazy = (email) => {
  const [getUserAccountFromEmail, { data, ...rest }] = useLazyQuery(GET_ACCOUNT_FROM_EMAIL, {
    variables: { email },
  });

  return {
    getUserAccountFromEmail,
    userAccountInfo: data && data.system && data.system.memberAccountFromEmail,
    ...rest,
  };
};
