// @flow
// $FlowFixMe
import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { Column, Dropdown, Icon, Menu, Row, Tag, Text, Tooltip, useModal } from '@8base/boost';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { useAuth } from '@8base-react/auth';

import { COLOR_BY_PLAN_NAME } from 'graphql/constants/billing';
import { useBuildUrl } from 'common/routing';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { checkIs8baseUser } from 'utils/help';
import { getWorkspaceUrl } from '../utils';

// $FlowFixMe
import { ReactComponent as BackendIcon } from 'images/icons/icon-database.svg';

import { FolderOpen } from '@material-ui/icons';

import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';
import { PROJECT_KIND } from 'common/constants/projectKind';
import { Avatar, Card } from '@material-ui/core';
import ProjectDeleteDialog from '../../../dialogs/ProjectDeleteDialog';
import { ConfirmDialog } from '../../../dialogs/ConfirmDialog';
import i18n from 'i18next';
import { useMutation } from 'react-apollo';
import { PROJECT_LEAVE_USER } from '../../../graphql/mutations';
import { TOAST_SUCCESS_MESSAGE } from '../../../common/constants/apolloOperationContextOptions';
import { useProjectsList } from '../../../graphql/hooks';

type DeveloperHomeProjectItemProps = {
  project: any;
  isCompany: boolean;
  onOpenProjectClick: OpenWorkspaceHandler;
  organization: any;
}

const StyledMyProjectsIcon = styled(FolderOpen)`
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 28px;
  border: 1px solid #E8EFF5;
  background-color: #ABB1B8;
  color: white;
  font-size: 44px !important;
  padding: 6px;
`;

const MoreWrapper = styled.div`
  position: absolute;
  padding: 10px;
  top: 40px;
  right: 5px;
`;

const MoreIcon = styled(Icon)`

  box-sizing: content-box;
  transform: rotate(0deg);
  transition: color .3s ease;

  &:hover {
    color: ${({ theme }) => theme.COLORS.BLUE_20}
  }
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  font-family: 'Poppins SemiBold', sans-serif !important;
  padding: 16px;
`;

type ColoredTextContentProps = {
  children: React$Node,
  color?: string,
};

const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledProjectKindText = styled(Text)`
  text-transform: uppercase;
  color: ${props => props.theme.COLORS.BLACK};
  font-size: 10px;
  font-weight: 500;
`;

const withColoredTextContent = (Component: React$ComponentType<any>, color) => (props: ColoredTextContentProps) => {
  const { children } = props;

  return (
    <Component { ...props }>
      <Text color={ color || 'BLACK' }>
        { children }
      </Text>
    </Component>
  );
};

const BlackMenuItem = withColoredTextContent(Menu.Item);
const RedMenuItem = withColoredTextContent(Menu.Item, 'RED_40');

const ContextMenu = ({ isOwner, workspace, onOpenWorkspaceClick, organization }) => {
  const { authState } = useAuth();
  const { openModal } = useModal();
  const { refetch: refetchProjectList } = useProjectsList(organization?.id);

  const onDeleteProjectHandler = (event) => {
    event.stopPropagation();
    openModal(ProjectDeleteDialog.ID, {
      projectId: workspace?.id,
      projectName: workspace?.name,
      organizationId: organization ? organization.id : null,
    });
  };

  const [projectUserRemove, { loading }] = useMutation(
    PROJECT_LEAVE_USER,
    {
      notifyOnNetworkStatusChange: true,
      context: {
        [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.teamMemberLeave.successMessage'),
      },
    },
  );

  const onLeaveProjectHandler = (event, project) => {
    event.stopPropagation();

    openModal(ConfirmDialog.ID, {
      loading,
      title: i18n.t('dialogs.teamMemberLeave.title', { count: 1 }),
      message: i18n.t('dialogs.teamMemberLeave.message', { count: 1 }),
      onConfirm: async () => {
        await projectUserRemove({
          variables: { projectId: project.id },
        });
        await refetchProjectList();
      },
    });
  };

  const copyWorkspaceIdToClipboard = useCallback(() => {
    const el = document.createElement('textarea');

    el.value = workspace.id;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    if (!!document.body) {
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      // $FlowFixMe
      document.body.removeChild(el);

      toast.success('Project ID successfully copied to the clipboard');
    }
  }, [workspace.id]);

  return (
    <Dropdown defaultOpen={ false }>
      <Dropdown.Head stopClickPropagation>
        <MoreWrapper { ...E2E.$props(`developerHome.workspaces.item.menu[${workspace.name}]`) }>
          <MoreIcon name="More" color="GRAY_30" />
        </MoreWrapper>
      </Dropdown.Head>
      <Dropdown.Body withPortal pin="left" placement="bottom-end" offset="lg">
        { ({ closeDropdown }) => (
          <Menu>
            <BlackMenuItem onClick={ (event) => {
              onOpenWorkspaceClick({ projectId: workspace.id });
              event.stopPropagation();
            } }
            >
              Open
            </BlackMenuItem>
            <If condition={ workspace.kind === WORKSPACE_KIND.general || checkIs8baseUser(authState) }>
              <BlackMenuItem onClick={ (event) => {
                event.stopPropagation();
                copyWorkspaceIdToClipboard();
                closeDropdown();
              } }>
                Copy Project ID to Clipboard
              </BlackMenuItem>
            </If>
            <DividerNav />
            <If condition={ isOwner }>
              <RedMenuItem onClick={ (event) => onDeleteProjectHandler(event) }>
                Delete
              </RedMenuItem>
            </If>
            <If condition={ !isOwner }>
              <RedMenuItem onClick={ (event) => onLeaveProjectHandler(event, workspace) }>
                Leave
              </RedMenuItem>
            </If>
          </Menu>
        ) }
      </Dropdown.Body>
    </Dropdown>
  );
};

export const DeveloperHomeProjectItem = ({
  project,
  onOpenProjectClick,
  isCompany,
  organization,
}: DeveloperHomeProjectItemProps) => {
  const buildUrl = useBuildUrl();
  const { authState } = useAuth();
  const [isHovering, setIsHovering] = useState(false);
  const plan = R.propOr({}, 'plan', project);
  const Owner = R.pathOr(false, ['owner'], project);
  const isOwner = authState.email === Owner.email;
  const ownerFirstName = R.pathOr('', ['owner', 'firstName'], project);
  const ownerLastName = R.pathOr('', ['owner', 'lastName'], project);
  const ownerFullName = `${ownerFirstName} ${ownerLastName}`.trim();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <a
      css={ css`height: 100%;` }
      href={ getWorkspaceUrl({ project, buildUrl }) }
      onClick={ (event) => {
        if (!event.metaKey && !event.ctrlKey) {
          event.preventDefault();
          onOpenProjectClick({ projectId: project.id, apiHost: project.apiHost });
        }
      } }
    >
      <StyledCard
        onMouseEnter={ handleMouseEnter } onMouseLeave={ handleMouseLeave }
        variant="outlined"
        elevation={ 3 }
        style={{ boxShadow: isHovering ? '0 4px 12px 0 rgba(171,177,184,0.3)' : '' }}
        { ...E2E.$props(`developerHome.workspaces.item[${project.name}]`) }
      >
        <Column justifyContent="between" stretch>
          <Row stretch gap="notSet" css={ css` align-items: center;` }>
            { project.image && project.image.downloadUrl &&
              (<Avatar
                src={ (project.image && project.image.downloadUrl) }
                style={{ width: 48, height: 48, fontSize: 6, marginRight: 20 }}
              />
              ) }

            <If condition={ !project.image && !project.image?.downloadUrl }>
              <StyledMyProjectsIcon />
            </If>


            <Column gap="none" grow={ 1 } stretch css={ css`overflow: hidden;` }>
              <Row justifyContent="between" css={ css`width: 100%;` }>
                <Choose>
                  <When condition={ !!project.plan && !isCompany }>
                    <Text
                      kind="small-2"
                      weight="semibold"
                      color={ COLOR_BY_PLAN_NAME[plan.name] }
                    >
                      { plan.displayName }
                    </Text>
                  </When>
                  <When condition={ !!project.owner }>
                    <Row alignItems="center" css={ css`overflow: hidden;` }>
                      <Tooltip
                        modifiers={{ offset: { offset: '0, 4' }}}
                        message={ ownerFullName }
                      >
                        <Avatar
                          src={ project?.owner?.avatar?.downloadUrl }
                          style={{ width: 12, height: 12, fontSize: 6, flex: '1 0 12px' }}
                        />
                      </Tooltip>
                      <Text
                        ellipsis
                        css={ css`color: #202B38;
                          font-family: Poppins;
                          font-size: 10px;
                          letter-spacing: 0;
                          line-height: 16px;` }
                        title={ ownerFullName }
                      >
                        { ownerFullName }
                      </Text>
                    </Row>
                  </When>
                </Choose>
                <Row growChildren css={ css`position: relative;
                  padding-right: 0;` }>

                  { /*project type*/ }
                  <Tag condensed color="GRAY_60" css={ css`border-radius: 2px;
                    border: none;
                    padding: 4px 8px;` }>
                    <Row alignItems="center">
                      { project?.kind === PROJECT_KIND.backend && (<BackendIcon style={{ height: 14 }} />) }
                      { project?.kind === PROJECT_KIND.fullstack && (<StyledProjectKindText>FS</StyledProjectKindText>) }
                    </Row>
                  </Tag>

                  { /*project counter*/ }
                  <Tag condensed color="GRAY_60" css={ css`border-radius: 2px;
                    border: none;
                    padding: 4px 8px;` }>
                    <Row alignItems="center">
                      <Icon name="Man" css={ css`width: 8px;
                        height: 8px;` } />
                      <Text kind="small-2">{ project?.usersCount }</Text>
                    </Row>
                  </Tag>
                  { /* project menu */ }
                  { /*  <ContextMenu workspace={ project } onOpenWorkspaceClick={ onOpenProjectClick } /> */ }
                </Row>
              </Row>
              <Text
                ellipsis
                kind="body"
                color="DARK_BLACK"
                css={ css`padding-right: 14px;
                  margin: 4px 0 !important;
                  max-width: 100%;
                  color: #323C47;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 600;
                  letter-spacing: 0;
                  line-height: 20px;` }
                title={ project.name }
              >
                { project.name }
              </Text>
              <Row css={ css`width: 100%;` }>
                <Text kind="small-1" css={ css`color: #848A91;
                  font-family: Poppins;
                  font-size: 10px;
                  letter-spacing: 0;
                  line-height: 16px;
                width: 100%;` }>Created at { DateTime.fromISO(project.createdAt).toFormat('LLL d, yyyy') }
                </Text>
                { isHovering &&
                  (<ContextMenu
                    isOwner={ isOwner }
                    workspace={ project }
                    onOpenWorkspaceClick={ onOpenProjectClick }
                    organization={ organization }
                  />) }
              </Row>
            </Column>
          </Row>
        </Column>
      </StyledCard>
    </a>
  );
};
