import React, { useCallback } from 'react';
import { FileInput } from '@8base-react/file-input';
import { Avatar } from '@8base/boost';

const AvatarProfileField = ({
  input: { value, onChange },
  meta,
  firstName,
  lastName,
  ...rest
}) => {
  const onUploadDone = useCallback(async (file) => {
    onChange(file);
  }, [onChange]);

  return (
    <FileInput fetchPolicy="network-only" onUploadDone={ onUploadDone } value={ value } accept="image/*" public>
      { ({ pick, value }) => <Avatar css={{ flexShrink: 0 }} src={ value ? value.downloadUrl : null } firstName={ firstName } lastName={ lastName } onPick={ () => pick() } pickVariant="fullWidth" { ...rest } /> }
    </FileInput>
  );
};

export { AvatarProfileField };
