// @flow
// $FlowFixMe waiting for update Flow
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Heading, Row, Select, withModal } from '@8base/boost';
import TeamIcon from '@material-ui/icons/GroupOutlined';
import * as R from 'ramda';
// $FlowFixMe waiting for update Flow
import { Redirect, useParams } from 'react-router-dom';
import { APP_URL, useBuildUrl } from 'common/routing';
import { useTranslation } from 'react-i18next';
// $FlowFixMe waiting for update Flow
import { ProjectDashboardProps } from '../Dashboard';
import styled from '@emotion/styled';
import { Add } from '@material-ui/icons';
import { ProjectMembersTable, Title } from './components/index';
import { Trans } from 'utils/translate';
import { ProjectTeamMemberInviteDialog } from 'dialogs/ProjectTeamMemberInviteDialog';
import { ShareOrganizationDialog } from 'dialogs/ShareOrganizationDialog';
import { ORGANIZATION_TYPES } from 'common/constants/organization';
// $FlowFixMe waiting for update Flow
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY,
  TEAM_MEMBERS_LIST_QUERY,
} from 'graphql/queries';
import {
  TEAM_INVITATION_CANCEL_MUTATION,
  TEAM_INVITATION_RESEND_MUTATION,
  TEAM_MEMBER_DELETE_MUTATION,
} from 'graphql/mutations';
import i18n from 'i18next';
import {
  DIALOG_ID, FORCE_TOAST_ERROR_MESSAGE, TOAST_ERROR_MESSAGE_FORMATTER, ENDPOINT_URI,
  TOAST_SUCCESS_MESSAGE, WORKSPACE_ID,
} from 'common/constants/apolloOperationContextOptions';
import { ConfirmDialog } from 'dialogs/ConfirmDialog';
import { useAuth } from '@8base-react/auth';
import { environmentAccessor } from '../../../../utils';
import { MASTER_ENVIRONMENT } from '../../../../utils/environmentAccessor';
import { css } from '@emotion/core';


const CustomTeamIcon = styled(TeamIcon)`
  font-size: 30px !important;
`;

const getEnvironmentResponse = (EnvironmentList) => R.pathOr([], ['system', 'getEnvironmentRoles'], EnvironmentList).filter(({ exists }) => Boolean(exists));

const environments = (data) => data.map(role => ({
  label: role.environmentName,
  value: role.environmentName,
}));

let ProjectTeam = ({ shouldRedirect, openModal, projectInfo, environmentIds, isWorkspaceAdministrator }: ProjectDashboardProps) => {
  const { t } = useTranslation();
  const buildUrl = useBuildUrl();
  const backendWorkspaceId = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'id'], projectInfo);
  const apiHost = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'apiHost'], projectInfo);
  const currentEnv = environmentAccessor.getEnvironment(backendWorkspaceId) || MASTER_ENVIRONMENT;
  const { authState } = useAuth();
  const { projectId } = useParams();
  const [environment, setEnvironment] = useState(currentEnv);
  const [environmentRoles, setEnvironmentRoles] = useState([]);
  const [members, setMembers] = useState([]);
  const organizationId = R.path(['organization', 'id'], projectInfo);
  const organizationType = R.path(['organization', 'type'], projectInfo);
  const organizationName = R.path(['organization', 'name'], projectInfo);

  const onChangeEnvironment = useCallback((env) => {
    if (backendWorkspaceId) {
      environmentAccessor.setEnvironment(backendWorkspaceId, env);
      setEnvironment(env);
    }

    window.location.reload();
  }, [backendWorkspaceId]);

  const [fetchEnvironments, { data: EnvironmentList, loading: environmentsLoading }] = useLazyQuery(GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, {
    variables: {
      email: authState.email,
      projectId: '',
      workspaceId: backendWorkspaceId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (backendWorkspaceId) {
      setTimeout(() => { fetchEnvironments({ context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost }}); }, 100);
    }
  }, [apiHost, backendWorkspaceId, fetchEnvironments]);

  useEffect(() => {
    if (environment) {
      setEnvironmentRoles(getRolesByEnvironmentName(getEnvironmentResponse(EnvironmentList), environment));
    }
  }, [EnvironmentList, environment]);

  const environmentOptions = useMemo(() => {
    return environments(getEnvironmentResponse(EnvironmentList));
  }, [EnvironmentList]);


  const getRolesByEnvironmentName = (data, name) => {
    const environmentRoles = data.find(role => role.environmentName === name);
    return environmentRoles ? environmentRoles.roles.map(role => ({ id: role.id, name: role.name })) : [];
  };


  const [getUsersPerEnvironment, { loading: loadingUsers }] = useLazyQuery(
    TEAM_MEMBERS_LIST_QUERY, {
      fetchPolicy: 'network-only',
      [WORKSPACE_ID]: backendWorkspaceId,
      onCompleted: (data) => {
        setMembers(data?.system?.environmentMembersList?.items || []);
      },
    },
  );

  useEffect(
    () => {
      if (!backendWorkspaceId) { return; }
      if (environmentIds && environmentIds.length > 1) {
        setTimeout(() => { getUsersPerEnvironment({ context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost }}); }, 100);
      } else {
        setTimeout(() => {
          getUsersPerEnvironment({
            variables: {
              environmentIds,
            },
            context: { [WORKSPACE_ID]: backendWorkspaceId },
          });
        }, 100);
      }

    }, [getUsersPerEnvironment, backendWorkspaceId, environmentIds, apiHost],
  );

  const [projectResendInvitation] = useMutation(TEAM_INVITATION_RESEND_MUTATION, {
    notifyOnNetworkStatusChange: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('resendInvitation.successMessage'),
      [FORCE_TOAST_ERROR_MESSAGE]: true,
      // this message shows only if there is no nextPlan on error response
      [TOAST_ERROR_MESSAGE_FORMATTER]: error => error.code === 'BillingPlanLimitError' ? 'Need to add more members? please contact Support' : error,
      [DIALOG_ID]: ProjectTeamMemberInviteDialog.ID,
      [ENDPOINT_URI]: apiHost,
      [WORKSPACE_ID]: backendWorkspaceId,
    },
  });

  const [projectInvitationCancel] = useMutation(
    TEAM_INVITATION_CANCEL_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
      context: {
        [WORKSPACE_ID]: backendWorkspaceId,
        [TOAST_SUCCESS_MESSAGE]: i18n.t('cancelInvitation.success'),
        [DIALOG_ID]: ProjectTeamMemberInviteDialog.ID,
      },
    },
  );

  const [projectUserRemove, { loading }] = useMutation(
    TEAM_MEMBER_DELETE_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
      context: {
        [WORKSPACE_ID]: backendWorkspaceId,
        [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.teamMemberDelete.successMessage'),
        [DIALOG_ID]: ProjectTeamMemberInviteDialog.ID,
        [ENDPOINT_URI]: apiHost,
      },
    },
  );

  if (shouldRedirect) {
    const fallbackUrl = buildUrl(APP_URL.projectDashboard, {
      pathParams: {
        projectId,
      },
      ignoreWorkspaceId: true,
    });

    return <Redirect to={ fallbackUrl } />;
  }

  const deleteUser = ({ email }) => {
    openModal(ConfirmDialog.ID, {
      loading,
      title: i18n.t('dialogs.teamMemberDelete.title', { count: 1 }),
      message: i18n.t('dialogs.teamMemberDelete.message', { count: 1 }),
      onConfirm: async () => {
        await projectUserRemove({ variables: { filter: { email }}});
        await refetchUsersPerEnvironment();
      },
    });
  };

  const cancelInvitation = (data) => {
    projectInvitationCancel({
      variables: { data: { email: data.email }},
      context: {
        [ENDPOINT_URI]: apiHost,
        [WORKSPACE_ID]: backendWorkspaceId,
      },
    })
      .then(() => refetchUsersPerEnvironment());
  };

  const getRoles = (data) => {
    const roles = [];
    for (const rol of data.role?.items) {
      roles.push(rol.id);
    }
    return roles;
  };

  const editInvitation = (data) => {
    const roles = getRoles(data);

    openModal(ProjectTeamMemberInviteDialog.ID, {
      projectId,
      refetch: refetchUsersPerEnvironment,
      backendWorkspaceId,
      roles: environmentRoles,
      apiHost,
      isEdit: true,
      initialValues: {
        email: data?.email,
        roles,
      },
    });
  };

  const resendInvitation = ({ email }) => {
    return projectResendInvitation({
      variables: {
        data: { email },
      },
    });
  };

  function refetchUsersPerEnvironment () {
    getUsersPerEnvironment({ context: {
      [WORKSPACE_ID]: backendWorkspaceId,
      [ENDPOINT_URI]: apiHost,
    }});
  }

  return (
    <Row flexWrap="wrap">
      <Row alignItems="center">
        <CustomTeamIcon />
        <Heading type="h1">
          <Trans i18nKey="projects.components.team.title">Teams</Trans>
        </Heading>
      </Row>
      <Title top="4px" bottom="24px">
        <Trans i18nKey="projects.components.team.description">
          Teams description
        </Trans>
      </Title>

      <Row css={{ marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Button
          css={{ padding: '7px 14px' }}
          color="primary"
          disabled={ !isWorkspaceAdministrator }
          onClick={ () => {
            if (organizationType && (organizationType === ORGANIZATION_TYPES.agency.toLowerCase())) {
              openModal(ShareOrganizationDialog.ID, {
                organizationId,
                organizationName,
                projectId,
                apiHost,
                projectUsers: members,
              });
            } else {
              openModal(ProjectTeamMemberInviteDialog.ID, {
                projectId,
                backendWorkspaceId,
                apiHost,
                roles: environmentRoles,
                refetch: refetchUsersPerEnvironment,
              });
            }
          } }
        >
          <Add fontSize="large" />
          { t('projects.components.team.addUserButton') }
        </Button>
        <If condition={ environmentOptions?.length > 1 }>
          <Select
            css={ css`width: 172px;` }
            options={ environmentOptions }
            loading={ environmentsLoading }
            onChange={ (value) => {
              onChangeEnvironment(value);
            } }
            value={ environmentAccessor.getEnvironment(backendWorkspaceId) || MASTER_ENVIRONMENT }
          />
        </If>
      </Row>
      <ProjectMembersTable
        key="internal"
        data={ members }
        headers={ [
          t('shared.name'),
          t('shared.email'),
          t('shared.roles'),
          t('shared.status'),
          '',
        ] }
        title={ t('projects.components.team.users', { count: members.length }) }
        loading={ loadingUsers }
        showOptions={ isWorkspaceAdministrator }
        handlers={{
          deleteUser,
          cancelInvitation,
          editInvitation,
          resendInvitation,
        }}
      />
    </Row>
  );
};

ProjectTeam = withModal(ProjectTeam);

export { ProjectTeam };
