// @flow
import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { css } from '@emotion/core';
import { Card, Text, TableBuilder, Link, Tag } from '@8base/boost';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Trans } from 'utils/translate';
import { ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';

const BILLING_HISTORY_COLUMNS = [
  { name: 'date', title: 'Date', width: 'minmax(120px, 1fr)' },
  { name: 'projectName', title: 'PROJECT', width: 'minmax(120px, 1fr)' },
  { name: 'planName', title: 'PLAN', width: 'minmax(120px, 1fr)' },
  { name: 'number', title: 'INVOICE ID', width: 'minmax(120px, 1fr)' },
  { name: 'description', title: 'DESCRIPTION', width: 'minmax(120px, 1fr)' },
  { name: 'status', title: 'STATUS', width: 'minmax(120px, 1fr)' },
  { name: 'total', title: 'AMOUNT', width: 'minmax(120px, 1fr)' },
  { name: 'invoicePdf', width: 'minmax(120px, 1fr)' },
];

const COLOR_BY_STATUS = {
  open: 'GRAY',
  draft: 'GRAY',
  void: 'GRAY',
  paid: 'GREEN',
  uncollectible: 'YELLOW',
};

export const BILLING_INVOICES_LIST_QUERY = gql`
  query BillingInvoicesList($projectId: ID!,$limit: Int!) {
    billingInvoicesList(projectId: $projectId,limit: $limit,by: PROJECT) {
      items {
        id
        periodStart
        periodEnd
        paid
        invoicePdf
        amountDue
        amountPaid
        amountRemaining
        endingBalance
        number
        status
        total
        plan{
          id
          name
        }
        description
        project{
          id
          name
        }
      }
    }
  }
`;

const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)),
);

const getInvoices = R.pipe(
  R.pathOr([], ['billingInvoicesList', 'items']),
  R.map(renameKeys({
    periodStart: 'date',
    invoicePdf: 'invoicePdf',
    total: 'total',
  })),
);

type BillingHistoryProps = {
  projectId?: string,
  apiHost?: string
}

export const BillingHistory = ({ projectId, apiHost }:BillingHistoryProps) => {
  const { data, loading } = useQuery(BILLING_INVOICES_LIST_QUERY, {
    variables: {
      projectId,
      limit: 10,
    },
    context: {
      [ENDPOINT_URI]: apiHost,
    },
    skip: !projectId,
  });

  return (
    <div >
      <div style={{ marginBottom: 16 }}>
        <Text css={ css`font-size: 14px; font-weight: 600; margin-bottom: 0 !important;` }>
          <Trans i18nKey="settings.billing.billingHistory.title">Billing History</Trans>
        </Text>
      </div>

      <Card.Body padding="none">
        <TableBuilder
          columns={ BILLING_HISTORY_COLUMNS }
          data={ getInvoices(data) }
          loading={ loading }
          condensed
          renderCell={ ({ name }, data) => {
            if (name === 'invoicePdf') {
              return (
                <Link
                  css={ css`
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  ` }
                  href={ data[name] }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="settings.billing.billingHistory.view">View</Trans>
                </Link>
              );
            } else if (name === 'projectName') {
              const projectName = R.path(['project', 'name'], data);
              return (
                <Text
                  css={ css`
                      width: 100%;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    ` }
                >
                  { projectName }
                </Text>
              );
            } else if (name === 'planName') {
              const planName = R.path(['plan', 'name'], data);
              return (
                <Text
                  css={ css`
                      width: 100%;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    ` }
                >
                  { planName }
                </Text>
              );
            } else if (name === 'date') {
              return <Text css={ css`white-space: nowrap;` }>{ DateTime.fromISO(data[name]).toFormat('MM/dd/yyyy') }</Text>;
            } else if (name === 'total') {
              return <Text css={ css`white-space: nowrap;` } weight="semibold">${ data[name].toFixed(2) }</Text>;
            } else if (name === 'status') {
              return <Tag css={ css`text-transform: capitalize;` } color={ COLOR_BY_STATUS[data[name]] } bordered>{ data[name] }</Tag>;
            }

            return data[name];
          } }
        />

      </Card.Body>
    </div>
  );
};

