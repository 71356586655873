import React from 'react';
import {
  Button, Card, Column, Label, Row, Text,
} from '@8base/boost';
import css from '@emotion/css';
import styled from '@emotion/styled';
import Check from '@material-ui/icons/Check';
import FrontendIcon from '@material-ui/icons/DesktopWindows';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

import { APP_URL, useBuildUrl } from 'common/routing';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { isPwa, processEnv } from 'utils';
import { Trans } from 'utils/translate';

import { DateTime } from 'luxon';

// $FlowIgnore
import { ReactComponent as BackendIcon } from 'images/icons/icon-data-cloud_w.svg';

const StyledFrontendIcon = styled(FrontendIcon)`
  font-size: 20px !important;
  color: ${props => props.theme.COLORS.WHITE};
`;

const StyledBackendIcon = styled(BackendIcon)`
  font-size: 30px !important;
  color: ${props => props.theme.COLORS.WHITE};
`;

const StyledOpenButton = styled(Button)`
  width: 130px;
  color: ${props => props.theme.COLORS.WHITE};
  background-color: ${props => props.theme.COLORS.BLUE_30};
`;

const DeploySuccessIcon = styled(Check)`
  color: ${props => props.theme.COLORS.GREEN_40} !important;
`;

const DeployFailedIcon = styled(ErrorOutline)`
  color: ${props => props.theme.COLORS.RED_40} !important;
`;

const KindIconBackground = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ABB1B8;
`;

const StyledCardTitleText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
  max-width: 294px;
  font-weight: 600;
  margin: 8px;
  color:  ${props => props.theme.COLORS.BLACK};
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
  color:  ${props => props.theme.COLORS.GRAY_50};
`;

const ConditionalBorderedCard = styled(Card)`
  width: 100%;
  border-radius:8px 0 0 8px;
  box-shadow: none;
  font-family: 'Poppins SemiBold',sans-serif !important;
  max-height: unset !important;
  ${(props) => props?.noBordersRadius &&
  `
    border-radius: 0 !important;
  `}
  ${(props) => props?.bordered === true &&
  ` box-shadow: 0px 2px 10px rgb(50 60 71 / 10%);
    border: 1px solid ${props?.theme.COLORS.GRAY_40};
    border-radius: 8px;
  `}
`;
const StyledDateLabel = styled(Label)`
  color:  ${props => props.theme.COLORS.GRAY_50};
`;

const CardBody = ({ isItem }) => css`
  padding: 0;
  overflow:hidden;
  ${isItem === true &&
  `
  border-radius: 0;
  border-bottom: 1px solid #d2d7dd;
  &:hover {
    background-color: #f6fbff;
  }
  `}
`;

export const WorkspaceCard = ({ workspaceInfo, bordered, withOpenButton, projectInfo, latestDeployment, isItem }) => {
  const buildUrl = useBuildUrl();

  const menuLocationChanged = (location:string) => {
    window.open(location, '_blank');
  };

  const onOpenWorkspace = (event, workspaceId) => {
    event.preventDefault();

    const url = buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId }});
    const appBuilderUrl = processEnv.getAppBuilderUrl();
    openWorkspace(`${appBuilderUrl}${url}`);
  };

  const openWorkspace = (path: string) => {
    if (isPwa()) {
      window.location.assign(path);
      return;
    }
    window.open(path, '_blank');
  };

  return (
    <Choose>
      <When condition={ workspaceInfo }>
        <ConditionalBorderedCard bordered={ bordered } noBordersRadius={ isItem } >
          <Card.Body css={ CardBody({ isItem }) } >
            <Row css={ css`padding: 16px 20px 16px 16px;` } justifyContent="between" alignItems="center">
              <Row stretch alignItems="center" justifyContent="evenly">
                <KindIconBackground>
                  <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.frontend }>
                    <StyledFrontendIcon />
                  </If>
                  <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.general }>
                    <StyledBackendIcon />
                  </If>
                </KindIconBackground>
                <Column >
                  <StyledCardTitleText ellipsis>
                    <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.frontend }>
                      <Trans i18nKey="projects.dashboard.frontend">Frontend</Trans>: { workspaceInfo?.name }
                    </If>
                    <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.general }>
                      <Trans i18nKey="projects.dashboard.backend">Backend</Trans>: { workspaceInfo?.name }
                    </If>
                  </StyledCardTitleText>
                  <Row>
                    <If condition={ workspaceInfo?.statusDeploy === 'Success' }>
                      <DeploySuccessIcon />
                    </If>
                    <If condition={ workspaceInfo?.statusDeploy === 'Failed' }>
                      <DeployFailedIcon />
                    </If>
                    <If condition={ projectInfo?.createdAt && workspaceInfo?.kind === WORKSPACE_KIND.general }>
                      <StyledDateLabel text={ DateTime.fromISO(projectInfo?.createdAt).toLocaleString(DateTime.DATETIME_MED) } />
                    </If>
                    <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.frontend }>
                      <Row stretch alignItems="center" justifyContent="evenly">
                        <Choose>
                          <When condition={ latestDeployment?.statusDeploy }>
                            <If condition={ latestDeployment?.statusDeploy.toUpperCase() === 'RESOLVED' }>
                              <DeploySuccessIcon css={ css`padding-left: 0;` } />
                            </If>
                            <If condition={ latestDeployment?.statusDeploy.toUpperCase() === 'REJECTED' }>
                              <DeployFailedIcon css={ css`padding-left: 0;` } />
                            </If>
                            <StyledDateLabel text={ DateTime.fromISO(latestDeployment?.dateDeploy).toLocaleString(DateTime.DATETIME_MED) } />
                          </When>
                          <Otherwise>
                            <StyledDateLabel>
                              <Trans i18nKey="projects.dashboard.noDeployments">No deployments found</Trans>
                            </StyledDateLabel>
                          </Otherwise>
                        </Choose>

                      </Row>
                    </If>
                  </Row>
                </Column>
              </Row>
              <If condition={ withOpenButton && workspaceInfo?.id }>
                <Column css={{ paddingLeft: '12px' }}>
                  <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.frontend }>
                    <StyledOpenButton onClick={ (event) => onOpenWorkspace(event, workspaceInfo?.id) } variant="contained">
                      <Trans i18nKey="projects.dashboard.openWorkspaceButtonFrontend">Open Frontend</Trans>
                    </StyledOpenButton>
                  </If>
                  <If condition={ workspaceInfo?.kind === WORKSPACE_KIND.general }>
                    <StyledOpenButton onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId: workspaceInfo?.id }})) } variant="contained">
                      <Trans i18nKey="projects.dashboard.openWorkspaceButtonBackend">Open Backend</Trans>
                    </StyledOpenButton>
                  </If>
                </Column>
              </If>
            </Row>
          </Card.Body>
        </ConditionalBorderedCard>
      </When>
      <Otherwise>
        <StyledCardContentText>
          <Trans i18nKey="projects.dashboard.noData">No data</Trans>
        </StyledCardContentText>
      </Otherwise>
    </Choose>
  );
};
