// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
import { Grid, Text, AsyncContent, Select, Column } from '@8base/boost';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY } from 'graphql/queries';
import { getEnvironmentRolesOptions } from 'utils';

type ProjectEnvironmentsSelectGroupProps = {
  projectId: string,
  workspaceIndex: number,
  workspaceApiHost: string,
  values: any,
  form: any,
  submitting: boolean,
  failedValidationRows: [string],
  setFailedValidationRows: any,
}

const Container = styled.div`
  background: ${props => props.isWorkspaceChosen ? 'none' : 'none'};
  border-radius: 0 0 5px 5px;
  width: 100%;
  padding: 16px 32px;
`;

const getEnvIndex = (environmentId, environmentRoles) => {
  if (!environmentId || !environmentRoles) {
    return -1;
  }
  return environmentRoles.findIndex((el) => el && el.environmentId === environmentId);
};

const onSelectChange = ({
  environmentId,
  form,
  workspaceIndex,
  value,
  values,
  envRoleIndex,
  projectId,
  failedValidationRows,
  setFailedValidationRows,
}) => {


  if (!value) {
    const newEnvRoles = values.projectRoles[workspaceIndex].environmentRoles.filter((el) => el.environmentId !== environmentId);

    return form && form.change(`projectRoles[${workspaceIndex}].environmentRoles`, newEnvRoles);
  }

  if (!values.projectRoles[workspaceIndex]) {
    return form && form.change('projectRoles', [...values.projectRoles, { projectId, environmentRoles: [{ environmentId, roles: value }] }]);
  }

  if (values.projectRoles[workspaceIndex] && !values.projectRoles[workspaceIndex].environmentRoles[envRoleIndex]) {
    const newFailedRows = failedValidationRows.filter((id) => id !== projectId);
    setFailedValidationRows(newFailedRows);

    return form && form.change(
      `projectRoles[${workspaceIndex}].environmentRoles`,
      [{ environmentId, roles: value }, ...values.projectRoles[workspaceIndex].environmentRoles]);
  }

  return form && form.change(`projectRoles[${workspaceIndex}].environmentRoles[${envRoleIndex}].roles`, value);
};

const getFieldValue = ({ values, workspaceIndex, envRoleIndex }) => {
  if (envRoleIndex === -1) {
    return null;
  }

  return values.projectRoles[workspaceIndex].environmentRoles[envRoleIndex].roles;
};

export const ProjectEnvironmentsSelectGroup = ({
  projectId,
  workspaceIndex,
  workspaceApiHost,
  values,
  form,
  submitting,
  failedValidationRows,
  setFailedValidationRows,
}: ProjectEnvironmentsSelectGroupProps) => {

  const { data, loading } = useQuery(GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, {
    skip: !projectId,
    variables: {
      projectId,
      workspaceId: '',
    },
    context: {
      [ENDPOINT_URI]: workspaceApiHost,
    },
  });


  const isWorkspaceChosen = useMemo(() => workspaceIndex >= 0, [workspaceIndex]);

  const environmentsList = R.pathOr([], ['system', 'getEnvironmentRoles'], data);

  return (
    <Container isWorkspaceChosen={ isWorkspaceChosen }>
      <AsyncContent stretch loading={ loading }>
        <Column>
          { environmentsList.map(({ environmentId, environmentName, roles }) => {
            const roleOptions = getEnvironmentRolesOptions(roles);

            const envRoles = (values.projectRoles[workspaceIndex] && values.projectRoles[workspaceIndex].environmentRoles) || [];
            const envRoleIndex = getEnvIndex(environmentId, envRoles);
            const fieldValue = getFieldValue({ values, workspaceIndex, envRoleIndex });

            return (
              <Grid.Layout key={ environmentId } columns="160px 1fr" stretch>
                <Grid.Box justifyContent="center">
                  <Text kind="subtitle" color="GRAY_50">{ environmentName }</Text>
                </Grid.Box>
                <Grid.Box>
                  <Select
                    multiple
                    disabled={ submitting }
                    options={ roleOptions }
                    onChange={ (value) => {
                      onSelectChange({
                        projectId,
                        workspaceIndex,
                        environmentId,
                        form,
                        values,
                        value,
                        envRoleIndex,
                        failedValidationRows,
                        setFailedValidationRows,
                      });
                    } }
                    value={ fieldValue }
                  />
                </Grid.Box>
              </Grid.Layout>);
          }) }
        </Column>
      </AsyncContent>
    </Container>
  );
};
