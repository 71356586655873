import React, { useCallback } from 'react';
import { Card, CardContent } from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useProjectQuotas, useProjectPlan, useWorkspacePaymentDetails, useProjectInfo } from 'graphql/hooks';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
import { apolloProjectDashboardSelectors, apolloBillingDataSelectors } from 'graphql/selectors';
import { AsyncContent, Column, Progress, Row, Text, Button, Grid, Icon, useModal } from '@8base/boost';
import * as R from 'ramda';
import { processEnv } from 'utils';
import { Trans } from 'utils/translate';
import { abbreviateNumber } from 'utils/numberShorter';
import { PlanName } from 'common/PlanName';
import { DateTime } from 'luxon';
import { PRICING_URL } from 'config/links';
import { BillingChangePlanDialog } from 'dialogs/BillingChangePlanDialog';
import { BillingCancelDowngradeDialog } from 'dialogs/BillingCancelDowngradeDialog';
export const StyledCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardTitleText = styled(Text)`
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 600;
  color:  ${props => props.theme.COLORS.BLACK}
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
   color:  ${props => props.theme.COLORS.GRAY_50}
`;

const StyledColumn = styled(Column)`
  border-right: 1px solid ${props => props.theme.COLORS.GRAY_20};
  padding-right: 32px;
  min-width: 730px;
`;

const BILLING_PLAN_STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  BLOCKED: 'blocked',
  CANCELING: 'canceling',
  CANCELED: 'canceled',
  PENDING: 'pending',
  UPCOMING: 'upcoming',
};

const PAYED_PLAN_STATUSES = [
  BILLING_PLAN_STATUS.ACTIVE,
  BILLING_PLAN_STATUS.SUSPENDED,
  BILLING_PLAN_STATUS.BLOCKED,
];

export const COLOR_BY_PLAN_NAME = {
  freeTier: 'GREEN_40',
  hobbyist: 'BLUE_30',
  developer: 'PURPLE_20',
  professional: 'MAGENTA_10',
};


const SHOULD_SHOW_UNIT = {
  gb: true,
  member: false,
  request: false,
  row: false,
};

const getValueText = (value, limit, unit, showUnit) => {
  return (
    <React.Fragment>
      <Text color="GRAY_40">
        { abbreviateNumber(Number(value || 0)) }
      </Text>
      <Text weight="semibold">
        { ' ' } / { abbreviateNumber(Number(limit)) }
        <If condition={ showUnit }>
          { ' ' } { unit.toUpperCase() }
        </If>
      </Text>
    </React.Fragment>
  );
};

const getProgressColor = (percentage) => {
  const okQuotaLimit = processEnv.getOkQuotaLimit();
  const warnQuotaLimit = processEnv.getWarnQuotaLimit();
  const maxQuotaLimit = processEnv.getMaxQuotaLimit();
  let color = 'GREEN';
  if (percentage >= 0 && percentage <= okQuotaLimit) {
    color = 'GREEN';
  } else if (percentage > okQuotaLimit && percentage <= warnQuotaLimit) {
    color = 'YELLOW';
  } else if (percentage > warnQuotaLimit && percentage <= maxQuotaLimit) {
    color = 'RED';
  }

  return color;
};

const BillingQuotasUsage = ({ projectId, apiHost }) => {
  const { projectQuotas, loadingProjectQuotas } = useProjectQuotas(projectId, apiHost);
  const { projectPlan, loadingProjectPlan } = useProjectPlan(projectId, apiHost);
  const { remainTrialDays } = useBillingInfo();
  const { card } = useWorkspacePaymentDetails();

  const plan = R.prop('plan', projectPlan);

  const BILLING_CHANGE_PLAN_DIALOG_ID = 'BILLING_CHANGE_PLAN_DIALOG_ID';

  const nextPaymentDt = DateTime.local().plus({ months: 1 }).set({ day: 1 }).toFormat('LLL d, yyyy');
  const nextPlan = { ...plan?.nextPlan };
  const planPrice = R.prop('price', plan);
  const trialEndDt = DateTime.fromISO(R.prop('trialEnd', plan)).toFormat('LLL d, yyyy');
  const planStatus = R.prop('status', plan);
  const hasNextPayment = PAYED_PLAN_STATUSES.indexOf(planStatus) !== -1;

  const hasNextPlan = Object.keys(nextPlan).length > 0;

  const quotas = apolloProjectDashboardSelectors.getQuotas(projectQuotas);
  const metrics = apolloProjectDashboardSelectors.getMetrics(projectQuotas);
  const overages = apolloBillingDataSelectors.getOveragesValues(metrics);
  const overagesPrice = apolloBillingDataSelectors.getTotalOverages(metrics);
  const planColor = COLOR_BY_PLAN_NAME[R.prop('displayName', plan)] || 'GREEN';
  const showPlanDetails = false;
  const { openModal } = useModal(BILLING_CHANGE_PLAN_DIALOG_ID);
  const { project } = useProjectInfo(projectId, apiHost);

  const openChangePlanDialog = useCallback((projectInfo) => {
    openModal(BillingChangePlanDialog.ID, projectInfo, apiHost);
  }, [apiHost, openModal]);

  const openCancelDowngradeDialog = useCallback((projectInfo) => {
    openModal(BillingCancelDowngradeDialog.ID, { plan, project: projectInfo });
  }, [openModal, plan]);


  return (
    <Card variant="outlined" css={ css`
      margin-top: 23.0px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.04);
  ` }>
      <StyledCardHeader style={{ padding: '2em 2.5em', display: 'flex' }}>
        <Row alignItems="center">
          <StyledCardTitleText>
            <Trans i18nKey="settings.billing.projectUsage.title">Project Usage</Trans>
          </StyledCardTitleText>
        </Row>
        <Row alignItems="center">
          <Choose>
            <When condition={ loadingProjectQuotas } >
              <React.Fragment />
            </When>
            <When condition={ hasNextPlan }>
              <Button
                color="neutral"
                variant="outlined"
                onClick={ () => openCancelDowngradeDialog(project) }
                { ...E2E.$props('settings.billing.cancelDowngradeBtn') }
              >
                <Trans i18nKey="settings.billing.plan.cancelDowngrade">Cancel Downgrade</Trans>
              </Button>
            </When>
            <Otherwise>
              <Button
                color="neutral"
                variant="outlined"
                onClick={ () => openChangePlanDialog(project) }
                { ...E2E.$props('settings.billing.changePlanBtn') }
              >
                <Trans i18nKey="settings.billing.plan.changePlan">Change Plan</Trans>
              </Button>
            </Otherwise>
          </Choose>
        </Row>
      </StyledCardHeader>
      <CardContent style={{ padding: '0px 24px 24px' }}>
        <AsyncContent loading={ loadingProjectQuotas }>
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Column gap="lg" stretch style={{ flex: 2, padding: '0px 10px' }}>
              <StyledColumn
                gap="lg"
                stretch
              >
                <Row alignItem="center">
                  <Icon name="Logo8base" size="sm" color={ planColor } />
                  <PlanName plan={ plan } />
                </Row>
                <Grid.Layout columns="1fr 1fr" gap="md" stretch>
                  { quotas.map(({ displayName, name, value, unit, description }) => {
                    const metric = R.propOr(0, name, metrics);
                    const hasOverflow = metric > value;
                    let valueToDisplay = Math.min(100, 100 * metric / value);

                    if (hasOverflow) {
                      valueToDisplay = Math.max(1, 100 * value / metric);
                    }

                    return (
                      <Row key={ name }>
                        <Progress
                          size="xs"
                          color={ getProgressColor(valueToDisplay) }
                          valueWidth="calc(100% - 136px)"
                          description={ description && <Text kind="small-1"> { description } </Text> }
                          label={ <Text kind="overline-1" color="BLACK"> { displayName } </Text> }
                          valueText={ getValueText(metric, value, unit, SHOULD_SHOW_UNIT[unit]) }
                          value={ valueToDisplay }
                          backgroundColor={ hasOverflow ? 'RED_20' : 'GRAY_20' }
                        />
                      </Row>
                    );
                  }) }
                </Grid.Layout>
                <If condition={ showPlanDetails }>
                  <a href={ PRICING_URL } target="_blank" rel="noopener noreferrer">
                    <Text kind="overline-2" color="PRIMARY" weight="semibold" >
                      show plan details
                    </Text>
                  </a>
                </If>
              </StyledColumn>

            </Column>
            <Column stretch style={{ flex: 1 }}>
              <StyledCardContentText>
                <AsyncContent loading={ loadingProjectPlan }>
                  <Choose>
                    <When condition={ plan }>
                      <Column
                        css={ css` min-width: 220px; max-width: 220px;` }
                        gap="sm"
                        offsetX="md"
                        justifyContent="center"
                        stretch
                        { ...E2E.$props('settings.billing.note') }
                      >
                        <Choose>
                          <When condition={ Number.isInteger(remainTrialDays) && remainTrialDays < 1 && R.isNil(card) }>
                            <Text color="LIGHT_TEXT_COLOR" >
                              <Trans i18nKey="settings.billing.plan.trialEnd">
                                Your FREE TRIAL has ended, please update your payment details.
                              </Trans>
                            </Text>
                          </When>
                          <When condition={ Number.isInteger(remainTrialDays) && R.isNil(card) }>
                            <Text color="LIGHT_TEXT_COLOR" >
                              <Trans i18nKey="settings.billing.plan.trial" trialEnd={ trialEndDt }>
                                Your trial will end on {{ trialEndDt }}, please update your payment details.
                              </Trans>
                            </Text>
                          </When>
                          <When condition={ Number.isInteger(remainTrialDays) }>
                            <Text color="LIGHT_TEXT_COLOR" >
                              <Trans i18nKey="settings.billing.plan.trialRemain" trialEnd={ trialEndDt }>
                                Your trial will end on {{ trialEndDt }}.
                              </Trans>
                            </Text>
                          </When>
                          <When condition={ hasNextPayment || nextPlan }>
                            <Text kind="h2" color="GRAY_60" weight="semibold">
                              ${ planPrice + overagesPrice }
                            </Text>
                            <Text kind="overline-1" color="GRAY_60" weight="semibold">
                              Total based <br /> on current usage
                            </Text>
                            <Text kind="overline-1" color="GRAY_60">
                              ${ planPrice } Subscription
                            </Text>
                            { quotas.map(({ name, displayName }) => {
                              const overageValue = R.propOr(0, name, overages);

                              if (!overageValue) {
                                return null;
                              }

                              return (
                                <Text kind="overline-1" color="GRAY_60" key={ `overage-${name}` }>
                                  ${ overageValue } { displayName }
                                </Text>
                              );
                            }) }
                            <Text kind="overline-1" color="GRAY_60" weight="semibold">
                              Next payment:
                            </Text>
                            <Text kind="overline-1" color="GRAY_60">
                              { nextPaymentDt }
                            </Text>
                          </When>
                          <When condition={ planStatus === BILLING_PLAN_STATUS.CANCELING }>
                            <Text color="LIGHT_TEXT_COLOR">
                              <Trans i18nKey="settings.billing.plan.subscriptionWillExpire" nextPaymentDt={ nextPaymentDt }>
                                Your subscription will expire on <Text weight="semibold">{{ nextPaymentDt }}.</Text>
                              </Trans>
                            </Text>
                          </When>
                          <When condition={ planStatus === BILLING_PLAN_STATUS.CANCELED }>
                            <Text color="LIGHT_TEXT_COLOR" >
                              <Trans i18nKey="settings.billing.plan.subscriptionExpired">
                                You don’t have any upcoming payments.
                              </Trans>
                            </Text>
                          </When>
                        </Choose>
                      </Column>
                    </When>
                    <Otherwise>
                      <StyledCardContentText>
                        <Trans i18nKey="projects.dashboard.noData">No data</Trans>
                      </StyledCardContentText>
                    </Otherwise>
                  </Choose>
                </AsyncContent>
              </StyledCardContentText>
            </Column>
          </Row>
        </AsyncContent>
      </CardContent>
    </Card>
  );
};

export { BillingQuotasUsage };
