import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationHistoryContext } from './HistoryConstext';

export function NavigationHistoryProvider({ children }) {
  const history = useHistory();
  const [previousPage, setPreviousPage] = useState(null);

  useEffect(() => {
    return history.listen(location => {
      setPreviousPage(location.pathname);
    });
  }, [history]);

  return (
    <NavigationHistoryContext.Provider value={{ previousPage }}>
      { children }
    </NavigationHistoryContext.Provider>
  );
}
