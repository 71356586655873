// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import {
  Card,
  Row,
  Heading,
  TableBuilder,
  Link,
  Icon,
  useModal,
  Text,
  Dropdown,
  Menu,
} from '@8base/boost';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { css } from '@emotion/core';
import { i18n } from 'i18n';

import { useBuildUrl, APP_URL, buildUrl } from 'common/routing';
import { LAST_PROJECT_ID } from 'common/constants/localStorageKeys';
import { Trans } from 'utils/translate';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
// $FlowFixMe waiting for update Flow
import { useHistory, useParams } from 'react-router-dom';
import { useProjectsList, useUserAccountInfo } from '../../../../graphql/hooks';
import { ProjectUpdateDialog } from '../../../../dialogs/ProjectUpdateDialog';

const { Item: MenuItem } = Menu;
const { Body: DropdownBody, Head: DropdownHead } = Dropdown;

const getProjectColumns = () => [
  {
    name: 'name',
    title: i18n.t('account.projects.name'),
  },
  {
    name: 'id',
    title: i18n.t('account.projects.id'),
    width: '270px',
  },
  {
    name: 'plan',
    title: i18n.t('account.projects.plan'),
  },
  {
    name: 'nextCharge',
    title: i18n.t('account.projects.nextCharge'),
    width: '140px',
  },
  {
    name: 'amount',
    title: i18n.t('account.projects.amount'),
    width: '110px',
  },
  {
    name: 'actions',
    width: '70px',
  },
];

const renderCell =
  (plans, changeProject, openModal) =>
    ({ name }, data) => {
      const { userAccountInfo } = useUserAccountInfo();
      const history = useHistory();
      const trialEnd = R.prop('trialEnd', data.plan);

      if (name === 'plan') {
        if (data?.plan) {
          return (
            <Text
              css={ css`
              white-space: nowrap;
            ` }
            >
              { R.prop('displayName', data[name]) }
            </Text>
          );
        }
        return '-';
      } else if (name === 'nextCharge') {
        return trialEnd
          ? '-'
          : DateTime.local()
            .plus({ months: 1 })
            .set({ day: 1 })
            .toFormat('MM/dd/yyyy');
      } else if (name === 'amount') {
        const plan = R.find(R.propEq('id', R.prop('id', data.plan)), plans);

        let value = '-';
        if (plan && !trialEnd) {
          value = `$${plan.price.toFixed(2)}`;
        }
        return <Text align="right" css={{ width: '100%' }} weight="semibold">{ value }</Text>;

      } else if (name === 'name') {
        return <Link onClick={ () => changeProject(data.id) }>{ data[name] }</Link>;
      } else if (name === 'actions') {
        return (
          <Dropdown defaultOpen={ false }>
            <DropdownHead>
              <Icon
                name="More"
                color="GRAY_30"
                { ...E2E.$props('tableEditDropdownToggle') }
              />
            </DropdownHead>
            <DropdownBody withPortal preventOverflow pin="right">
              { ({ closeDropdown }) => (
                <Menu>
                  <MenuItem
                    onClick={ () => {
                      const url = buildUrl(APP_URL.projectHome, {
                        pathParams: { projectId: data.id },
                        ignoreWorkspaceId: true,
                      });
                      history.push(url);
                      closeDropdown();
                    } }
                  >
                    { i18n.t('shared.open', 'Open') }
                  </MenuItem>
                  <MenuItem
                    onClick={ () => {
                      openModal(ProjectUpdateDialog.ID, {
                        projectId: data.id,
                        projectName: data.name,
                        projectImage: data.image,
                        projectDescription: data.description,
                        apiHost: data.apiHost,
                        webSocketUri: data.webSocket,
                        isOwner: data.owner.email === userAccountInfo.email,
                      });
                      closeDropdown();
                    } }
                  >
                    { i18n.t('shared.edit', 'Edit') }
                  </MenuItem>
                </Menu>
              ) }
            </DropdownBody>
          </Dropdown>
        );
      }

      return data[name];
    };

const Projects = () => {
  const buildUrl = useBuildUrl();
  const history = useHistory();

  const changeProject = async (projectId) => {
    const url = buildUrl(APP_URL.projectHome, {
      pathParams: { projectId },
    });

    if (window.localStorage) {
      localStorage.setItem(LAST_PROJECT_ID, projectId);
    }
    history.push(url);
  };

  const { openModal } = useModal();
  const { plans = [] } = useBillingInfo();
  const { organizationId } = useParams();
  const { myProjects, sharedProjects, loading } = useProjectsList(organizationId ? organizationId : '');

  const projects = [].concat(myProjects?.items || [], sharedProjects?.items || []);

  return (
    <Card>
      <Card.Header>
        <Row alignItems="center" gap="md">
          <Heading type="h2" padding="none">
            <Trans i18nKey="account.projects.title">Projects</Trans>
          </Heading>
        </Row>
      </Card.Header>

      <Card.Body padding="none">
        <TableBuilder
          columns={ getProjectColumns() }
          data={ projects }
          loading={ loading }
          renderCell={ renderCell(plans, changeProject, openModal) }
          columnGap="0"
          groupBy=""
        />
      </Card.Body>
    </Card>
  );
};

export { Projects };
