// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import { useModal } from '@8base/boost';
import * as R from 'ramda';
// $FlowFixMe
import { useHistory, useLocation, matchPath, Location } from 'react-router-dom';

import { LogOutDialog } from 'dialogs/LogOutDialog';
import { apolloUserAccountInfoSelectors } from 'graphql/selectors';
import { APP_URL, useBuildUrl } from 'common/routing';
import { useUserAccountInfo, useUserAccountInfoUpdate } from 'graphql/hooks';
import { updateUserAccountInfoCachedData } from 'utils/apollo/updateCache';
import { UserDropdownDetailed } from 'pro/UserDropdownDetailed';

const ACCOUNT_PAGES = [
  APP_URL.account,
  APP_URL.accountProfile,
  APP_URL.accountProjects,
  APP_URL.signInAndSecurity,
  APP_URL.billing,
];

const getNextLocationState = (location, prevLocation) => {
  const isAccountPage = ACCOUNT_PAGES.some((url) => matchPath(location.pathname, { path: url }));
  if (isAccountPage) {
    if (prevLocation) {
      return { prevLocation };
    }
    return undefined;
  }

  return { prevLocation: location };
};

type UserFlowUserDropdownProps = {
  prevLocation?: Location;
};

export const UserFlowUserDropdown = ({ prevLocation }: UserFlowUserDropdownProps) => {
  const { openModal } = useModal();
  const { userAccountInfo } = useUserAccountInfo();
  const buildUrl = useBuildUrl();
  const history = useHistory();
  const location = useLocation();
  const [userAccountInfoUpdate] = useUserAccountInfoUpdate();

  const firstName = apolloUserAccountInfoSelectors.getFirstName(userAccountInfo);
  const lastName = apolloUserAccountInfoSelectors.getLastName(userAccountInfo);
  const email = apolloUserAccountInfoSelectors.getEmail(userAccountInfo);
  const learningMode = apolloUserAccountInfoSelectors.getLearningMode(userAccountInfo);
  const avatarUrl = apolloUserAccountInfoSelectors.getAvatarUrl(userAccountInfo);

  const openMyAccount = (closeDropdown: Function) => () => {
    const locationState = getNextLocationState(location, prevLocation);
    history.push(buildUrl(APP_URL.account), locationState);

    closeDropdown();
  };

  const openLogOutModal = (closeDropdown: Function) => () => {
    openModal(LogOutDialog.ID);

    closeDropdown();
  };


  const onChangeLearningMode = useCallback(() => {
    userAccountInfoUpdate({
      variables: {
        data: {
          learningMode: !learningMode,
          email,
        },
      },
      optimisticResponse: {
        system: {
          memberAccountUpsert: {
            ...userAccountInfo,
            learningMode: !learningMode,
            __typename: 'SystemUserAccountInfo',
          },
          __typename: 'SystemMutation',
        },
      },
      update: (cache, { data }) => {
        const userAccountInfoUpdate = R.pathOr({}, ['system', 'memberAccountUpsert'], data);
        updateUserAccountInfoCachedData({ cache, newData: userAccountInfoUpdate });
      },
    });
  }, [email, learningMode, userAccountInfo, userAccountInfoUpdate]);


  return (
    <UserDropdownDetailed
      firstName={ firstName }
      lastName={ lastName }
      email={ email }
      avatarDownloadUrl={ avatarUrl }
      openMyAccount={ openMyAccount }
      openLogOutModal={ openLogOutModal }
      learningMode={ learningMode }
      onChangeLearningMode={ onChangeLearningMode }
      hideLearningMode
    />
  );
};
