// @flow

import gql from 'graphql-tag';

export const PROJECTS_INVITATION_LIST_QUERY = gql`
  query ProjectInvitationList($projectId: ID!) {
    system {
      list: projectInvitationListQuery(projectId: $projectId) {
        items {
          id
          firstName
          lastName
          email
          accepted
          role {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_INVITATIONS_DETAILS_QUERY = gql`
  query ProjectInvitationDetails($invitationId: String!) {
    system {
      projectInvitationById(invitationId: $invitationId) {
        id
        emailFrom
        firstNameFrom
        lastNameFrom
        accepted
        project {
          id
          name
        }
      }
    }
  }
`;
