// @flow
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

const ENVIRONMENT_SETTINGS_QUERY = gql`
  query EnvironmentSettings {
    system {
      environmentSettings {
        deleteLock
        relationMode
      }
    }
  }
`;

export const useGetEnvironmentSettings = () => {
  const { data, loading } = useQuery(ENVIRONMENT_SETTINGS_QUERY);

  const { relationMode, deleteLock } = data?.system?.environmentSettings || {};

  return { environmentSettings: { relationMode, deleteLock }, loading };
};
