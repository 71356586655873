import * as React from 'react';

export const UIContext = React.createContext();

export const SET_DIALOG = 'SET_DIALOG';
export const DIALOGS_IDS = {
  WORKSPACE_CREATE: 'WORKSPACE_CREATE',
  PROJECT_CREATE: 'PROJECT_CREATE',
  ORGANIZATION: 'ORGANIZATION',
};

const initialState = {
  dialog: {
    isOpen: false,
    type: null,
    component: null,
  },
};

function uiReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_DIALOG: {
      return {
        ...state,
        dialog: payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function UIProvider({ children }) {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);
  const value = { state, dispatch };

  return <UIContext.Provider value={ value }>{ children }</UIContext.Provider>;
}

function useUI() {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
}

export { UIProvider, useUI };
