import React, { createContext, useEffect, useMemo } from 'react';
import { useProjectInfo } from 'graphql/hooks';
import { useWorkspace, useApiHost } from 'providers';
import { matchPath, useLocation } from 'react-router-dom';
import { APP_URL } from 'common';

const ProjectContext = createContext({ projectId: '', project: {}, refetch: () => {}, loading: false });

const ProjectProvider = ({ children }) => {

  const location = useLocation();

  const { workspaceId, projectId: routeProjectId } = useMemo(() => {
    const projectMatch = matchPath(location?.pathname, { path: APP_URL.projectHome, exact: false, strict: false });
    const workspaceMatch = matchPath(location?.pathname, { path: APP_URL.workspaceHome, exact: false, strict: false });

    const workspaceId = workspaceMatch?.params?.workspaceId;
    const projectId = projectMatch?.params?.projectId;

    return { workspaceId, projectId };
  }, [location]);

  const { apiHost } = useApiHost();
  //console.log('apiHost', apiHost);
  const workspace = useWorkspace(workspaceId, {});
  const projectId = routeProjectId || workspace?.project?.id;

  const { project, refetch, loading } = useProjectInfo(projectId, apiHost, {});

  useEffect(() => { projectId && refetch(); }, [projectId, refetch]);

  return (
    <ProjectContext.Provider
      value={{
        projectId,
        project,
        refetch,
        loading,
      }}
    >
      { children }
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectProvider };
