// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { Paper, Row, Column, Text, Icon } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as R from 'ramda';

import { PlanName } from 'common/PlanName';
import { BILLING_PLAN_STATUS } from 'common/constants/billing';
import { COLOR_BY_PLAN_NAME } from 'graphql/constants/billing';

type BillingPlanDowngradeProgressProps = {
  currentPlan: any;
  nextPlan: any;
  nextPaymentDt: string;
};

const StyledPaper = styled(Paper)`
  box-shadow: none;
  background: #EBF4FF;
  padding: 20px;
  width: 100%;
  margin-top: 10px;
`;

const StyledRow = styled(Row)`
  border-right: 1px solid ${props => props.theme.COLORS.GRAY_20};
  min-width: 160px;
  padding-right: 8px;
  margin-right: 20px;
`;

const PlanCard = ({ plan, status }: *) => {
  const planName = R.prop('name', plan);
  const planColor = COLOR_BY_PLAN_NAME[planName] || 'GREEN';
  return (
    <Row
      css={ css` background: #FFFFFF; border-radius: 8px; padding: 8px; min-width: 300px;` }
      alignItems="center"
      gap="noSet"
    >
      <StyledRow alignItems="center">
        <Icon name="Logo8base" size="sm" color={ planColor } />
        <Column gap="none">
          <Row alignItem="center">
            <PlanName plan={ plan } />
          </Row>
          <If condition={ !!status }>
            <Text kind="small-1">
              <Choose>
                <When condition={ status === BILLING_PLAN_STATUS.ACTIVE }>Current Plan</When>
                <When condition={ status === BILLING_PLAN_STATUS.UPCOMING }>Upcoming Plan</When>
                <Otherwise>{ status }</Otherwise>
              </Choose>
            </Text>
          </If>
        </Column>
      </StyledRow>

      <Text kind="h2" weight="semibold">${ R.prop('price', plan) } /
        <If condition={ R.prop('price', plan) > 0 }>
          <Text color="SECONDARY_TEXT_COLOR" kind="overline-1"> mo.</Text>
        </If>
      </Text>
    </Row>
  );

};

export const BillingPlanDowngradeProgress = ({
  currentPlan,
  nextPlan,
  nextPaymentDt,
}: BillingPlanDowngradeProgressProps) => {
  const nextPlanName = R.prop('displayName', nextPlan);

  return (
    <StyledPaper>
      <Column gap="md">
        <Text>
          Your project plan will change to { nextPlanName } on { nextPaymentDt }
        </Text>

        <Row gap="md" alignItems="center">
          <PlanCard plan={ currentPlan } workspaceName="" status="active" />
          <Icon name="ArrowRight" />
          <PlanCard plan={ nextPlan } workspaceName="" status="upcoming" />
        </Row>
      </Column>
    </StyledPaper>
  );
};
