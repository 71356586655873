import React, { useCallback, useMemo } from 'react';
import { Radio, Tooltip } from '@8base/boost';
import styled from '@emotion/styled';

import { PlanRadioItemTag, ItemsContainer, showCompleteItem, hiddenCompleteItem } from './CssUtils';

import { PLANS } from 'graphql/constants/billing';

import { SharedRadioItem } from './SharedRadioItem';

const RadioGroupScrollableTag = styled.div`
  position: relative;
  overflow: auto;
  padding-top: 5px;
  top: -10px;
`;

const RadioItem = styled(Radio.Item)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const ItemTag = styled(PlanRadioItemTag)`
  width: 100%;
  height: 120px;
  transition: min-height 0.3s ease-in;
  overflow: hidden;

  &.large {
    min-height: 120px;
  }

  &.short {
    min-height: 0;
    height: 50px;
  }
`;

const PlanRadioItem = ({ active, disabled, disabledTooltipText, children, isTouched }) => {
  const itemTag = (
    <ItemTag active={ active } disabled={ disabled } className={ !isTouched ? 'large' : 'short' }>
      { children }
    </ItemTag>
  );

  if (disabled) {
    return (
      <Tooltip
        message={ (
          <div >
            { disabledTooltipText }
          </div>
        ) }
        cursor="default"
      >
        { itemTag }
      </Tooltip>
    );
  }

  return itemTag;
};

const getAvailablePlans = (plans, with8base, isFreePlanAvailable) => {
  let availablePlans = plans;

  if (with8base) {
    availablePlans = [...availablePlans, {
      name: '8base',
      displayName: '8base',
      price: 0,
      id: '8base',
      description: 'Unlimited plan for the 8base team.',
    }];
  }

  if (!isFreePlanAvailable) {
    availablePlans = availablePlans.filter(({ name }) => name !== PLANS.freeTier);
  }

  return availablePlans;
};

export const KindOfProjectRadioGroupField = ({ plans, isFreePlanAvailable, card, with8base, input, ...props }) => {
  const { howStartSelected } = props;

  const availablePlans = useMemo(() => {
    return getAvailablePlans(plans, with8base, isFreePlanAvailable);
  }, [isFreePlanAvailable, plans, with8base]);

  const getIsDisabled = useCallback((planName) => {
    return false;
  }, []);

  const getDisabledTooltipText = useCallback((planName) => {
    if (planName !== PLANS.freeTier) {
      return 'Update your payment details by clicking on the link below.';
    }
  }, []);

  const onChange = useCallback(value => {
    const plan = availablePlans.find(({ id }) => id === value) || {};
    const isPlanDisabled = getIsDisabled(plan.name);

    if (!isPlanDisabled) {
      input && input.onChange(value);
    }

  }, [availablePlans, getIsDisabled, input]);

  const isTouched = input.value === 'fullstack' && howStartSelected === 'fromScratch';

  return (
    <RadioGroupScrollableTag>
      <ItemsContainer
        { ...props }
        input={{ ...input, onChange }}
        direction="row"
        gap="md"
        alignItems="center"
        stretch
      >
        <RadioItem
          key="fullstack"
          value="fullstack"
          isTouched={ isTouched }
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ input.value === 'fullstack' }
              disabled={ getIsDisabled('Fullstack') }
              disabledTooltipText={ getDisabledTooltipText('Fullstack') }
              isTouched={ isTouched }
              className={ isTouched ? showCompleteItem : hiddenCompleteItem }
            >
              <SharedRadioItem
                name="Fullstack"
                displayName="Full-Stack"
                description="Create an end-to-end solution with both frontend interfaces and backend services."
                isTouched={ isTouched }
                textAlignment="center"
                icon="Fullstack"
              />
            </PlanRadioItem>
          ) }
        </RadioItem>
        <RadioItem
          key="backend"
          value="backend"
          { ...E2E.$props('dialogs.projectCreate.planFullstack') }
        >
          { ({ checked }) => (
            <PlanRadioItem
              active={ checked }
              disabled={ getIsDisabled('Backend-Only') }
              disabledTooltipText={ getDisabledTooltipText('Backend-Only') }
              isTouched={ isTouched }
              className={ isTouched ? showCompleteItem : hiddenCompleteItem }
            >
              <SharedRadioItem
                name="Backend-Only"
                displayName="Backend-Only"
                description="Create a project focused solely on backend services and APIs."
                isTouched={ isTouched }
                textAlignment="center"
                icon="Backend"
              />
            </PlanRadioItem>
          ) }
        </RadioItem>

      </ItemsContainer>
    </RadioGroupScrollableTag>
  );
};
