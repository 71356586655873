import React, { useState, useCallback, useMemo, useEffect } from 'react';
import * as R from 'ramda';
import { useQuery, useMutation } from 'react-apollo';
import { Form, Field, FormSpy } from '@8base-react/forms';
import { Dialog, Button, InputField, useModal, Column, Row, Text, SelectField } from '@8base/boost';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { toast } from 'react-toastify';
import { WORKSPACE_CREATE_MUTATION } from 'graphql/mutations';
import { CHECK_WORKSPACE_CREATION_QUERY } from 'graphql/queries';
import { ENDPOINT_URI, HIDE_TOAST_ERROR_MESSAGE, IGNORE_GRAPHQL_ERRORS, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { composeValidators, required } from 'utils/formValidations';
import { REGIONS_ENDPOINTS } from 'common/constants/regions';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';
import { PROFILE_TYPE } from 'graphql/constants';
import {
  AUTH_PROJECT_KIND,
} from 'common/constants/authProjectKind';
import { MASTER_ENVIRONMENT } from 'utils/environmentAccessor';
import { useProjectFrontend, useWorkspacesList } from '../graphql/hooks';
import { requiredWithSpaces } from '../utils/formValidations';
import { getServerUrl } from 'utils/processEnv';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import styled from '@emotion/styled';
import { HowStartProjectRadioGroupField } from 'common/HowStartProjectRadioGroupField';
import { KindOfAuthenticationRadioGroupField } from 'common/KindOfAuthenticationGroupField';
import { IntegratedAuthConfirmationDialog } from 'dialogs/IntegratedAuthConfirmationDialog';
import { useAppState, types } from 'providers/AppStateProvider';

import { ProjectLoader } from 'common/ProjectLoader';
const WORKSPACE_CREATE_DIALOG_ID = 'WORKSPACE_CREATE_DIALOG_ID';

const POLL_INTERVAL = 5000;

const CREATE_WORKSPACE_STATUS_SUCCESS = 'complete_success';
const CREATE_WORKSPACE_STATUS_ERROR = 'complete_error';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

const WorkspaceCreateDialog = () => {
  const [step, setStep] = useState('form');
  const { t } = useTranslation(); const [isDone, setIsDone] = useState(false);
  const { closeModal, args } = useModal(WORKSPACE_CREATE_DIALOG_ID);
  const [howToStartOptions, setHowToStartOptions] = useState(false);
  const [showProfilesList, setShowProfilesList] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const { openModal: openAuthModal } = useModal(IntegratedAuthConfirmationDialog.ID);
  const { dispatch } = useAppState();
  const [success, setSuccess] = useState(false);
  const { projectFrontend } = useProjectFrontend(args?.projectId);

  const {
    setRegionEndpoint,
  } = useBillingInfo();
  const { workspacesFrontendList } = useWorkspacesList(args.region, {});

  const uniqWorkspace = useCallback(
    value => workspaceList
      .filter((w) => w?.project?.id === args?.projectId)
      .some((workspace) => workspace.name === value)
      ? `Frontend with name '${value}' already exists.` : null,
    [args, workspaceList],
  );

  useEffect(() => {
    if (args?.projectId && workspaceList.length === 0) {
      setWorkspaceList(workspacesFrontendList.items);
    }
  }, [args, workspaceList.length, workspacesFrontendList.items]);

  const filteredData = (Array.isArray(args?.authProfiles) ? args.authProfiles : []).filter((element) => {
    return element.environmentName === MASTER_ENVIRONMENT &&
      Array.isArray(element.authProfile) &&
      element.authProfile.filter((profile) => profile.type === PROFILE_TYPE['8BASE_COGNITO']);
  });

  const selectOptions = (filteredData ?? []).reduce((acc, element) => {
    const authProfiles = element.authProfile.map((profile) => {
      return {
        value: profile.id,
        label: profile.name,
      };
    });
    // Add new item to authProfiles array
    authProfiles.unshift({
      value: 'new',
      label: 'Create New',
    });
    return acc.concat(authProfiles);
  }, []);

  const [workspaceCreate, { loading: workspaceLoading }] = useMutation(WORKSPACE_CREATE_MUTATION);

  const onClose = useCallback(() => {
    const region = args && args.region;
    setRegionEndpoint(region || getServerUrl());
    closeModal(WORKSPACE_CREATE_DIALOG_ID);
    setSuccess(false);
    setAwaitWorkspaceId(null);
    setStep('form');
  }, [args, closeModal, setRegionEndpoint]);


  const [awaitWorkspaceId, setAwaitWorkspaceId] = useState(null);
  const [awaitWorkspaceEndpoint, setWorkspaceEndpoint] = useState(null);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (success && projectFrontend?.frontendWorkspaces?.count !== args?.workspaces?.length) {
      openAuthModal(IntegratedAuthConfirmationDialog.ID, { kind: 'frontend', workspaceId: awaitWorkspaceId });
      onClose();
    }
  }, [args, args.workspaces, awaitWorkspaceId, onClose, openAuthModal, projectFrontend, success]);


  const onCheckWorkspaceCreation = useCallback(async (completedData) => {
    if (!completedData) {
      setAwaitWorkspaceId(null);
    }
    const status = R.path(['system', 'asyncSessionStatus', 'status'], completedData);
    const result = R.path(['system', 'asyncSessionStatus', 'result'], completedData);

    switch (status) {
      case CREATE_WORKSPACE_STATUS_SUCCESS: {
        const onFinish = async () => {
          const onSuccess = R.prop('onSuccess', args);
          if (typeof onSuccess === 'function') {
            onSuccess(awaitWorkspaceId, isAuth);
            setSuccess(true);
          } else {
            dispatch({ type: types.SET_WORKSPACE_CREATED, payload: awaitWorkspaceId });
            setIsDone(false);
            onClose();
            openAuthModal(IntegratedAuthConfirmationDialog.ID, { kind: 'frontend', workspaceId: awaitWorkspaceId });
          }
        };
        await onFinish();
        break;
      }

      case CREATE_WORKSPACE_STATUS_ERROR: {
        setAwaitWorkspaceId(null);
        setIsDone(false);
        onClose();

        if (result) {
          toast.error(result);
        }

        break;
      }

      default: {
        break;
      }
    }
  }, [args, awaitWorkspaceId, dispatch, isAuth, onClose, openAuthModal]);

  useQuery(CHECK_WORKSPACE_CREATION_QUERY, {
    variables: {
      sessionId: awaitWorkspaceId,
    },
    context: {
      [ENDPOINT_URI]: awaitWorkspaceEndpoint,
      [IGNORE_GRAPHQL_ERRORS]: true,
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
    },
    skip: !awaitWorkspaceId || !awaitWorkspaceEndpoint,
    fetchPolicy: 'network-only',
    pollInterval: awaitWorkspaceEndpoint && awaitWorkspaceId ? POLL_INTERVAL : 0,
    onCompleted: onCheckWorkspaceCreation,
  });

  const onSubmit = useCallback(async (data) => {
    let id = null;

    if (args?.organizationId) {
      data = {
        ...data,
        organizationId: args.organizationId,
      };
    }

    if (data.authType !== AUTH_PROJECT_KIND.no_authentication) {
      setIsAuth(true);
      if (!(selectOptions && selectOptions.length) || data.profileId === undefined) {
        data = {
          ...data,
          profileId: undefined,
        };
      }
    }

    if (data.profileId === 'new') {
      data.profileId = '';
    }

    if (args?.kind) {
      data = {
        ...data,
        kind: args.kind,
      };
    }

    data.projectId = args.projectId;

    data = {
      ...data,
    };

    setWorkspaceList([...workspaceList, { ...data, project: { id: args.projectId }}]);

    try {
      ({ data: { workspaceCreate: { id }}} = await workspaceCreate({
        variables: { data: R.omit(['region', 'billingPlanId'], data) },
        context: {
          [ENDPOINT_URI]: data.region,
          [IGNORE_WORKSPACE]: true,
        },
      }));

      FS.event(FULLSTORY_EVENT_TYPES.createWorkspace, {
        pricing_plan: null,
        workspace_name: data.name,
        workspace_id: id,
      });
    } catch (e) {
      setStep('form');

      throw e;
    }

    setAwaitWorkspaceId(id);
    setWorkspaceEndpoint(data.region);
  }, [args, selectOptions, workspaceCreate, workspaceList]);

  const initialValues = useMemo(() => {
    if (args && args.kind && args.kind === WORKSPACE_KIND.frontend) {
      return {
        region: args.region || REGIONS_ENDPOINTS.US,
        howStart: 'withAccelerators',
        authType: 'coded_login',
      };
    }

    if (args && args.region) {
      return {
        region: args.region,
        howStart: 'withAccelerators',
        authType: 'coded_login',
      };
    }

    return {
      region: getServerUrl(),
      howStart: 'withAccelerators',
      authType: 'coded_login',
    };

  }, [args]);

  return (
    <Dialog
      id={ WORKSPACE_CREATE_DIALOG_ID }
      onClose={ onClose }
      shouldCloseOnOverlayClick={ false }
      shouldCloseOnEscPress={ false }
      size="xxl"
      { ...E2E.$props('dialogs.workspaceCreate.body') }
    >
      <Form onSubmit={ onSubmit } initialValues={ initialValues } >
        { ({ handleSubmit, invalid, submitting, pristine, values, dirtySinceLastSubmit, form }) => {

          if (workspaceLoading || awaitWorkspaceId || success) {
            let template = null;
            if (values.authType === AUTH_PROJECT_KIND.no_authentication) {
              template = 'FRONTEND_NO_AUTH';
            } else {
              template = 'FRONTEND_AUTH';
            }

            return (
              <div style={{ padding: '120px 0' }}>
                <ProjectLoader isDone={ isDone } name={ values.name } template={ template } />
              </div>
            );
          }

          return (
            <form onSubmit={ handleSubmit } css={{ overflow: 'auto' }} { ...E2E.$props('dialogs.workspaceCreate.form') }>
              <FormSpy
                onChange={ (args) => {
                  const newRegion = R.path(['values', 'region'], args);
                  const oldRegion = values.region;
                  if (newRegion !== oldRegion) {
                    setRegionEndpoint(newRegion);
                    form.change('billingPlanId', null);
                  }

                  if (args.values.howStart === 'fromScratch') {
                    setHowToStartOptions(true);
                  } else {
                    setHowToStartOptions(false);
                  }

                  if ((args.values.authType === AUTH_PROJECT_KIND.coded_login || args.values.authType === AUTH_PROJECT_KIND.hosted_login) && args.values.howStart !== 'withAccelerators') {
                    setShowProfilesList(true);
                  } else {
                    setShowProfilesList(false);
                  }
                } }
              />
              <Dialog.Header title={ t('dialogs.interfaceCreate.title') } onClose={ onClose } />
              <Dialog.Body>
                <Column css={ step !== 'form' ? { display: 'none', padding: '0 20px' } : { padding: '0 20px' } } gap="md">
                  <Row stretch style={{ padding: '0 20px' }}>
                    <Field
                      { ...E2E.$props('dialogs.workspaceCreate.workspaceNameInput') }
                      stretch
                      name="name"
                      label="Name"
                      placeholder={ t('dialogs.interfaceCreate.Placeholder') }
                      component={ InputField }
                      validate={ composeValidators(requiredWithSpaces, uniqWorkspace) }
                      autoFocus
                    />
                  </Row>
                  <CardsContainer>
                    <Text css={{ fontSize: '12.4px', fontWeight: '600' }}>
                      { t(
                        'dialogs.projectCreate.chooseHowStartProject',
                        'How do you want to start?',
                      ) }
                    </Text>
                    <Field
                      name="howStart"
                      component={ HowStartProjectRadioGroupField }
                      kindSelected="fullstack"
                      howStartSelected={ values.howStart }
                      { ...E2E.$props(
                        'dialogs.projectCreate.howStartOptions',
                      ) }
                    />
                  </CardsContainer>

                  <CardsHiddenToggle
                    isHidden={ !howToStartOptions }
                  >
                    <Text css={{ fontSize: '12.4px', fontWeight: '600' }}>
                      { t(
                        'dialogs.projectCreate.codedLogin',
                        'Do you want to include a ready-to-use Integrated Authentication solution?',
                      ) }
                    </Text>
                    <Field
                      name="authType"
                      component={ KindOfAuthenticationRadioGroupField }
                      { ...E2E.$props('dialogs.projectCreate.billingPlanRadio') }
                    />
                    <Footer>
                      Both types of <b>Integrated Authentication</b> are based on our own&nbsp;
                      <a href="https://docs.8base.com/projects/backend/authentication/#8base-authentication" target="_blank" rel="noopener noreferrer">
                      8base Authentication
                      </a> profile, which will be automatically created in
                      your backend. However, the authentication frontend is implemented
                      differently, depending on your needs.
                    </Footer>
                  </CardsHiddenToggle>
                  <If condition={ !(values?.authType === AUTH_PROJECT_KIND.no_authentication) && values.howStart === 'fromScratch' }>
                    <Row stretch>
                      <Column stretch>
                        <CardsHiddenToggle
                          isHidden={ !showProfilesList }
                        >
                          <Field
                            stretch
                            name="profileId"
                            label="Choose an Authentication Profile"
                            component={ SelectField }
                            options={ selectOptions }
                            validate={ required }
                            { ...E2E.$props(
                              'dialogs.projectCreate.authOptions',
                            ) }
                          />
                        </CardsHiddenToggle>
                      </Column>
                      <Column stretch>
                        <></>
                      </Column>
                    </Row>
                  </If>
                </Column>
              </Dialog.Body>
              <Dialog.Footer>
                <Row alignItems="center" justifyContent={ step === 'form' ? 'end' : 'between' } stretch>
                  <Row>
                    <Button
                      type="button"
                      color="neutral"
                      variant="outlined"
                      onClick={ onClose }
                      disabled={ submitting }
                    >
                      { t('shared.cancel') }
                    </Button>
                    <Button
                      type="submit"
                      disabled={ pristine || (invalid && !dirtySinceLastSubmit) }
                      loading={ submitting }
                      onClick={ undefined }
                      { ...E2E.$props('dialogs.workspaceCreate.createBtn') }
                    >
                      { t('shared.create') }
                    </Button>
                  </Row>
                </Row>
              </Dialog.Footer>
            </form>
          );
        } }
      </Form>
    </Dialog>
  );
};

WorkspaceCreateDialog.ID = WORKSPACE_CREATE_DIALOG_ID;

export { WorkspaceCreateDialog };

const CardsHiddenToggle = styled(CardsContainer)(
  ({ isHidden }) => css`
    display: ${isHidden ? 'none' : 'flex'};
    animation: ${isHidden ? 'fadeOut' : 'fadeIn'} 0.3s ease-in-out;
  `,
);

const Footer = styled.div`
  color: #5b636c;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  b {
    font-weight: bold;
  }

  a {
    color: #0874F9;
    font-weight: 600;
  }
`;
