import React from 'react';
import { Button, Dialog, useModal } from '@8base/boost';
import { LogoutIcon } from '../icons';
import { useTranslation } from 'react-i18next';

export const SESSION_CLOSE_DIALOG_ID = 'SESSION_CLOSED_DIALOG_ID';

const { Header, Body, Footer } = Dialog;

const SessionCloseDialog = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const onClose = () => {
    closeModal(SESSION_CLOSE_DIALOG_ID);
    window.location.reload();
  };

  const title = t('dialogs.endSession.title', { defaultValue: 'Logout' });
  const body = t('dialogs.endSession.content', { defaultValue: 'You have been logged out. Please sign back in to continue.' });
  const confirmButton = t('dialogs.endSession.confirm', { defaultValue: 'OK' });

  return (
    <Dialog id={ SESSION_CLOSE_DIALOG_ID } size="md">
      <Header title={ title } onClose={ onClose } />
      <Body style={{ gap: 16, alignItems: 'center' }}>
        <LogoutIcon />
        { body }
      </Body>
      <Footer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16, width: '100%' }}>
          <Button
            color="neutral"
            variant="outlined"
            size="sm"
            onClick={ onClose }
          >
            { confirmButton }
          </Button>
        </div>
      </Footer>
    </Dialog>
  );
};

SessionCloseDialog.ID = SESSION_CLOSE_DIALOG_ID;

export { SessionCloseDialog };
