import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { AsyncContent, InputField, SelectField, Text } from '@8base/boost';
import { Field as _Field } from '@8base-react/forms';
import {
  composeValidators,
  maxLength,
  requiredWithSpaces,
  required,
} from 'utils/formValidations';
import { useTranslation } from 'react-i18next';
import { useProjectsList, useWorkspacesList } from '../../../graphql/hooks';
import { REGIONS_ENDPOINTS } from 'common/constants/regions';
import { KindOfProjectRadioGroupField } from 'common/KindOfProjectRadioGroupField';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { HowStartProjectRadioGroupField } from 'common/HowStartProjectRadioGroupField';
import { KindOfAuthenticationRadioGroupField } from 'common/KindOfAuthenticationGroupField';
import { checkIs8baseUser } from 'utils/help';

const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 0 auto;
  padding: 0px 20px;
  padding-bottom: 16px;
  width: 100%;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  padding: 0 20px;
  height: auto;
  width: 100%;
`;
const ToggleContainer = styled.div`
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;

  &.show {
    opacity: 1;
    max-height: 490px;
  }

  &.hideee {
    opacity: 0;
    max-height: 0;
  }
`;
const Footer = styled.div`
  color: #5b636c;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  b {
    font-weight: bold;
  }

  a {
    color: #0874F9;
    font-weight: 600;
  }
`;

const Field = styled(_Field)`
  margin-bottom: 2px;
  padding: 0;
`;

const AsyncContentStyled = styled(AsyncContent)`
  bottom: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
`;

export const CreateProjectForm = ({
  organizationCardLoading,
  userCardLoading,
  planDataLoading,
  plansLoading,
  currentCard,
  isFreePlanAvailable,
  plans,
  authState,
  showAuthenticationOptions,
  howToStartOptions,
  isBackendOnly = false,
  howStartSelected,
  kindSelected,
  organizationId = '',
}) => {
  const { allWorkspacesList } = useWorkspacesList('', {});
  const { myProjects } = useProjectsList(organizationId);
  const { t } = useTranslation();

  const uniqProjectName = useCallback(
    value => allWorkspacesList.items
      .filter((w) => w.kind === WORKSPACE_KIND.general)
      .some((workspace) => workspace.name === value)
      ? `Workspace with name '${value}' already exists.` : null,
    [allWorkspacesList.items],
  );

  const uniqAgencyProjectName = useCallback(
    (value) =>
      myProjects?.items?.some(({ name }) => name === value)
        ? `Project with name '${value}' already exists.`
        : null,
    [myProjects],
  );

  const REGION_OPTIONS = [
    {
      value: REGIONS_ENDPOINTS.US,
      label: 'us-east-1 (N. Virginia)',
    },
    {
      value: REGIONS_ENDPOINTS.UK,
      label: 'eu-west-2 (London)',
    },
  ].filter((el) => !!el.value);

  return (
    <Body>
      <AsyncContentStyled
        loading={ organizationCardLoading ||
          userCardLoading ||
          planDataLoading ||
          plansLoading }
        stretch
      >
        <Row>
          <div style={{ display: 'flex', padding: '20px 20px 10px' }}>
            <span
              style={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '600',
                color: '#323C47',
                lineHeight: '16px',
              }}
            >
              Project Details
            </span>
          </div>
        </Row>
        <FormContainer>
          <Field
            stretch
            name="name"
            label={ t('dialogs.projectCreate.name') }
            placeholder={ t('dialogs.projectCreate.namePlaceholder') }
            component={ InputField }
            validate={ composeValidators(
              requiredWithSpaces,
              maxLength(30),
              organizationId ? uniqAgencyProjectName : uniqProjectName,
            ) }
            autoFocus
            { ...E2E.$props('dialogs.projectCreate.workspaceNameInput') }
          />
          <Field
            stretch
            name="region"
            label="Select your AWS Region"
            component={ SelectField }
            options={ REGION_OPTIONS }
            validate={ required }
            { ...E2E.$props('dialogs.projectCreate.regionInput') }
          />
        </FormContainer>
        <CardsContainer>
          <Text css={{ fontSize: '12.4px', fontWeight: '600' }}>
            { t(
              'dialogs.projectCreate.chooseKindProject',
              'What kind of project do you want to start with?',
            ) }
          </Text>
          <Field
            name="kind"
            component={ KindOfProjectRadioGroupField }
            isFreePlanAvailable={ organizationId ? false : isFreePlanAvailable }
            card={ currentCard }
            plans={ plans }
            with8base={ checkIs8baseUser(authState) }
            isBackendOnly={ isBackendOnly }
            howStartSelected={ howStartSelected }
            { ...E2E.$props('dialogs.projectCreate.billingPlanRadio') }
          />
        </CardsContainer>
        <ToggleContainer className={ !isBackendOnly ? 'show' : 'hideee' }>
          <CardsContainer >
            <Text css={{ fontSize: '12.4px', fontWeight: '600' }}>
              { t(
                'dialogs.projectCreate.chooseHowStartProject',
                'How do you want to start?',
              ) }
            </Text>
            <Field
              name="howStart"
              component={ HowStartProjectRadioGroupField }
              isFreePlanAvailable={ organizationId ? false : isFreePlanAvailable }
              card={ currentCard }
              plans={ plans }
              with8base={ checkIs8baseUser(authState) }
              isBackendOnly={ isBackendOnly }
              kindSelected={ kindSelected }
              { ...E2E.$props('dialogs.projectCreate.billingPlanRadio') }
            />
          </CardsContainer>
          <CardsContainer style={{ display: (showAuthenticationOptions && howToStartOptions) ? 'flex' : 'none' }}>
            <Text css={{ fontSize: '12.4px', fontWeight: '600' }}>
              { t(
                'dialogs.projectCreate.integratedAuth',
                'Do you want to include a ready-to-use Integrated Authentication solution?',
              ) }
            </Text>
            <Field
              name="authType"
              component={ KindOfAuthenticationRadioGroupField }
              isFreePlanAvailable={ organizationId ? false : isFreePlanAvailable }
              card={ currentCard }
              plans={ plans }
              with8base={ checkIs8baseUser(authState) }
              { ...E2E.$props('dialogs.projectCreate.billingPlanRadio') }
            />
            <Footer>
              Both types of <b>Integrated Authentication</b> are based on our own
              <a href="https://docs.8base.com/projects/backend/authentication/#8base-authentication" target="_blank" rel="noopener noreferrer"> 8base Authentication</a> profile, which will be automatically created in
              your backend. However, the authentication frontend is implemented
              differently, depending on your needs.
            </Footer>
          </CardsContainer>
        </ToggleContainer>
      </AsyncContentStyled>
    </Body>
  );
};

