import { useEffect, useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { i18n } from 'i18n';

import { APP_URL } from 'common/routing';
import { WORKSPACES_LIST_QUERY } from 'graphql/queries';
import { useOrganizationsList } from 'graphql/hooks';
import { apolloWorkspacesListSelectors as workspacesListSelectors } from 'graphql/selectors';
import { useWorkspace } from 'providers/WorkspaceProvider';

const DEFAULT_TITLE = i18n.t('documentTitles.default', { defaultValue: '8base' });

const DOCUMENT_TITLES = [
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.home', { defaultValue: 'Home - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.workspaceHome,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.functions', { defaultValue: 'Functions - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.functions,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.general', { defaultValue: 'General - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.settingsGeneral,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.roles', { defaultValue: 'Roles - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.appServicesRoles,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.localization', { defaultValue: 'Localization - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.settingsLocalization,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.environmentVariables', { defaultValue: 'Environment Variables - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.settingsEnvironmentVariables,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.authentication', { defaultValue: 'Authentication - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.appServicesAuthentication,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.apiTokens', { defaultValue: 'API Tokens - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.settingsApiTokens,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.integrations', { defaultValue: 'Integrations - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.integrations,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.apiExplorer', { defaultValue: 'API Explorer - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.apiExplorer,
      exact: true,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.data', { defaultValue: 'Data - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.data,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.appUsers', { defaultValue: 'User Managment - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.users,
    },
  },
  {
    get: ({ workspaceName }) => i18n.t('documentTitles.teamMembers', { defaultValue: 'Team - {{workspaceName}} - 8base', workspaceName }),
    match: {
      path: APP_URL.settingsTeam,
    },
  },
  {
    get: ({ organizationName }) => i18n.t('documentTitles.organization', { defaultValue: 'Organization - {{organizationName}} - 8base', organizationName }),
    match: {
      path: APP_URL.developerHomeOrganization,
    },
  },
  {
    get: () => i18n.t('documentTitles.accountProfile', { defaultValue: 'Account - My Profile - 8base' }),
    match: {
      path: APP_URL.accountProfile,
    },
  },
  {
    get: () => i18n.t('documentTitles.accountProjects', { defaultValue: 'Account - Projects - 8base' }),
    match: {
      path: APP_URL.accountProjects,
    },
  },
  {
    get: () => i18n.t('documentTitles.accountBilling', { defaultValue: 'Account - Billing - 8base' }),
    match: {
      path: APP_URL.billing,
    },
  },
  {
    get: () => i18n.t('documentTitles.signInAndSecurity', { defaultValue: 'Account - Sign-In & Security - 8base' }),
    match: {
      path: APP_URL.signInAndSecurity,
    },
  },
  {
    get: () => i18n.t('documentTitles.organizationsSettingsGeneral', { defaultValue: 'Organization Settings - General - 8base' }),
    match: {
      path: APP_URL.organizationSettingsGeneral,
    },
  },
  {
    get: () => i18n.t('documentTitles.organizationsSettingsTeam', { defaultValue: 'Organization Settings - Team - 8base' }),
    match: {
      path: APP_URL.organizationSettingsTeam,
    },
  },
];

const getOrganizationIdFromPath = (pathname) => {
  const match = matchPath(pathname, { path: APP_URL.developerHomeOrganization });
  return (match && match.params && match.params.organizationId) || undefined;
};

const getOrganizationName = (organizationsList, organizationId) => {
  const list = organizationsList.items;

  const item = list.find(el => el.id === organizationId);
  if (item && item.name) {
    return item.name;
  }

  return null;
};

const DocumentTitle = ({ pathname }) => {
  const { id: workspaceId } = useWorkspace();
  const { data, loading: loadingWorkspacesList } = useQuery(WORKSPACES_LIST_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const { organizationsList, loading: loadingOrganizationsList } = useOrganizationsList({
    fetchPolicy: 'cache-first',
  });

  const organizationId = useMemo(() => getOrganizationIdFromPath(pathname), [pathname]);

  useEffect(() => {
    const title = DOCUMENT_TITLES.find(({ match }) => !!matchPath(pathname, match));

    if (loadingOrganizationsList || loadingWorkspacesList) {
      return;
    }

    const workspaceList = workspacesListSelectors.getWorkspacesByKind(data);
    const workspaceName = workspacesListSelectors.getWorkspaceName(workspaceList, workspaceId);
    const organizationName = getOrganizationName(organizationsList, organizationId);

    if (title && workspaceName) {
      document.title = title.get({ workspaceName });
    } else if (title && organizationName) {
      document.title = title.get({ organizationName });
    } else if (title) {
      let tmpTitle = DEFAULT_TITLE;
      try {
        tmpTitle = title.get();
      } catch (e) { }
      document.title = tmpTitle;
    } else {
      document.title = DEFAULT_TITLE;
    }
  }, [pathname, data, organizationsList, workspaceId, organizationId, loadingOrganizationsList, loadingWorkspacesList]);

  return null;
};

export { DocumentTitle };
