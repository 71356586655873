// @flow
// $FlowFixMe waiting for update Flows
import React, { useLayoutEffect, lazy } from 'react';
// $FlowFixMe waiting for update Flows
import { Redirect, Route, matchPath, Switch, useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';
import { Elements } from 'react-stripe-elements';

import { APP_URL } from 'common/routing';
import { PrivateRoute, WaitingComponent } from 'common';
import { isMobile } from 'utils';
import { AppFlow } from 'common/AppFlow';
import { DocumentTitle } from 'common/DocumentTitle';
import { DialogWrapper } from 'common/DialogWrapper';

import { APITokenCreateDialog } from 'dialogs/APITokenCreateDialog';
import { APITokenDeleteDialog } from 'dialogs/APITokenDeleteDialog';
import { APITokenEditDialog } from 'dialogs/APITokenEditDialog';
import { APITokenShowDialog } from 'dialogs/APITokenShowDialog';
import { BillingCancelDowngradeDialog } from 'dialogs/BillingCancelDowngradeDialog';
import { BillingCancelSubscriptionDialog } from 'dialogs/BillingCancelSubscriptionDialog';
import { BillingChangePlanDialog } from 'dialogs/BillingChangePlanDialog';
import { BillingChooseCardDialog } from 'dialogs/BillingChooseCardDialog';
import { CreateOrganizationDialog } from 'dialogs/CreateOrganizationDialog';
import { ConfirmDialog } from 'dialogs/ConfirmDialog';
import { EndpointDialog } from 'dialogs/EndpointDialog';
import { EnvironmentVariableDeleteDialog } from 'dialogs/EnvironmentVariableDeleteDialog';
import { EnvironmentVariableEditDialog } from 'dialogs/EnvironmentVariableEditDialog';
import { ForcibleErrorDialog } from 'dialogs/ForcibleErrorDialog';
import { IndexCreateDialog } from 'dialogs/IndexCreateDialog';
import { IndexDeleteDialog } from 'dialogs/IndexDeleteDialog';
import { IndexUpdateDialog } from 'dialogs/IndexUpdateDialog';
import { LogOutDialog } from 'dialogs/LogOutDialog';
import { RecordDeleteDialog } from 'dialogs/RecordDeleteDialog';
import { RequireCCFeatureDialog } from 'dialogs/RequireCCFeatureDialog';
import { RequirePlanUpgradeDialog } from 'dialogs/RequirePlanUpgradeDialog';
import { RoleAddTeamMemberDialog } from 'dialogs/RoleAddTeamMemberDialog';
import { RoleAddUserDialog } from 'dialogs/RoleAddUserDialog';
import { RoleCreateDialog } from 'dialogs/RoleCreateDialog';
import { RoleDeleteDialog } from 'dialogs/RoleDeleteDialog';
import { RoleUpdateDialog } from 'dialogs/RoleUpdateDialog';
import { ProjectTeamMemberInviteDialog } from 'dialogs/ProjectTeamMemberInviteDialog';
import { ShareWorkspaceDialog } from 'dialogs/ShareWorkspaceDialog';
import { ShareOrganizationDialog } from 'dialogs/ShareOrganizationDialog';
import { TableDeleteDialog } from 'dialogs/TableDeleteDialog';
import { TableFieldDeleteDialog } from 'dialogs/TableFieldDeleteDialog';
import { TeamMemberDeleteDialog } from 'dialogs/TeamMemberDeleteDialog';
import { TeamMemberEditDialog } from 'dialogs/TeamMemberEditDialog';
import { TeamMemberInviteDialog } from 'dialogs/TeamMemberInviteDialog';
import { TeamMemberInviteToOrganizationDialog } from 'dialogs/TeamMemberInviteToOrganizationDialog';
import { TeamMemberInviteToProjectOrganizationDialog } from 'dialogs/TeamMemberInviteToProjectOrganizationDialog';
import { TeamMemberRemoveFromOrganizationDialog } from 'dialogs/TeamMemberRemoveFromOrganizationDialog';
import { TeamMemberRemoveFromRoleDialog } from 'dialogs/TeamMemberRemoveFromRoleDialog';
import { IntegratedAuthConfirmationDialog } from 'dialogs/IntegratedAuthConfirmationDialog';
import { UserDeleteDialog } from 'dialogs/UserDeleteDialog';
import { UserEditDialog } from 'dialogs/UserEditDialog';
import { UserRemoveFromRoleDialog } from 'dialogs/UserRemoveFromRoleDialog';
import { ViewCreateDialog } from 'dialogs/ViewCreateDialog';
import { ViewDeleteDialog } from 'dialogs/ViewDeleteDialog';
import { ViewJSONFieldDialog } from 'dialogs/ViewJSONFieldDialog';
import { ViewReachTextFieldDialog } from 'dialogs/ViewReachTextFieldDialog';
import { ViewUpdateDialog } from 'dialogs/ViewUpdateDialog';
import { WarningLimitsDialog } from 'dialogs/WarningLimitsDialog';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import { WorkspaceDeleteDialog } from 'dialogs/WorkspaceDeleteDialog';
import { WorkspaceLeaveDialog } from 'dialogs/WorkspaceLeaveDialog';
import { WorkspaceUpdateDialog } from 'dialogs/WorkspaceUpdateDialog';
import { OrganizationAcceptInviteDialog } from 'dialogs/OrganizationAcceptInviteDialog';
import { OrganizationRoleChangingConfirmationDialog } from 'dialogs/OrganizationRoleChangingConfirmationDialog';
import { OrganizationRemoveAccessToWorkspacesDialog } from 'dialogs/OrganizationRemoveAccessToWorkspacesDialog';
import { OrganizationTeamMemberEditAccessDialog } from 'dialogs/OrganizationTeamMemberEditAccessDialog';
import { OrganizationAddAccessToWorkspacesDialog } from 'dialogs/OrganizationAddAccessToWorkspacesDialog';
import { PaymentWarningDialog } from 'dialogs/PaymentWarningDialog';

// Billing Payment Card Update Dialogs
import { UserWorkspacePaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/UserWorkspacePaymentDetailsUpdateDialog';
import { UserProjectPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/UserProjectPaymentDetailsUpdateDialog';
import { UserPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/UserPaymentDetailsUpdateDialog';
import { OrganizationPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/OrganizationPaymentDetailsUpdateDialog';
import { PaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/PaymentDetailsUpdateDialog';

import { Invite } from './invite';
import { Mobile } from './mobile';
import { Error } from './error';
import { RootContainer } from './Root.container';
import DeveloperHome from './developer-home';
import Account from './account';
import Organization from './organization';
import Project from './project';
// import { ProjectCreateDialog } from 'dialogs/ProjectCreateDialog';
import { ProjectCreateDialog } from 'dialogs/ProjectCreateDialog/index';
import UserInterfaceMembersDialog from '../dialogs/UserInterfaceMembersDialog';
import ProjectDeleteDialog from '../dialogs/ProjectDeleteDialog';
import FrontEndDeleteDialog from '../dialogs/FrontEndDeleteDialog';
import { ProjectAcceptInviteDialog } from '../dialogs/ProjectAcceptInviteDialog';
import { ProjectUpdateDialog } from '../dialogs/ProjectUpdateDialog';
import { SessionCloseDialog } from '../dialogs/SessionClosedDialog';
import { REDIRECT_TO } from '../common/constants/localStorageKeys';
import { useCallbackUrl } from 'utils/hooks/useCallbackUrl';

const FileExplorer = lazy(() =>
  import(/* webpackChunkName: "file-explorer" */ './file-explorer'),
);

const Users = lazy(() => import(/* webpackChunkName: "users" */ './users'));

const Onboarding = lazy(() =>
  import(/* webpackChunkName: "onboarding" */ './onboarding'),
);

const Data = lazy(() => import(/* webpackChunkName: "data" */ './data'));

const Settings = lazy(() =>
  import(/* webpackChunkName: "settings" */ './settings'),
);

const APIExplorer = lazy(() =>
  import(/* webpackChunkName: "api-explorer" */ './api-explorer'),
);

const Functions = lazy(() =>
  import(/* webpackChunkName: "functions" */ './functions'),
);

const AppServices = lazy(() =>
  import(/* webpackChunkName: "app-services" */ './app-services'),
);

const Home = lazy(() => import(/* webpackChunkName: "home" */ './root/index'));

const Integrations = lazy(() =>
  import(/* webpackChunkName: "integrations" */ './integrations'),
);

const Referral = lazy(() =>
  import(/* webpackChunkName: "integrations" */ './referral'),
);

const Dialogs = () => {
  const auth = useAuth();

  return (
    <If condition={ auth.isAuthorized }>
      <OrganizationAcceptInviteDialog />
      <ProjectAcceptInviteDialog />
      <OrganizationRoleChangingConfirmationDialog />
      <OrganizationTeamMemberEditAccessDialog />
      <OrganizationRemoveAccessToWorkspacesDialog />
      <OrganizationAddAccessToWorkspacesDialog />
      <APITokenCreateDialog />
      <APITokenDeleteDialog />
      <APITokenEditDialog />
      <APITokenShowDialog />
      <BillingCancelDowngradeDialog />
      <BillingCancelSubscriptionDialog />
      <BillingChangePlanDialog />
      <BillingChooseCardDialog />
      <CreateOrganizationDialog />
      <ConfirmDialog />
      <DialogWrapper
        id={ RecordDeleteDialog.ID }
        component={ RecordDeleteDialog }
      />
      <EndpointDialog />
      <EnvironmentVariableDeleteDialog />
      <EnvironmentVariableEditDialog />
      <ForcibleErrorDialog />
      <IndexCreateDialog />
      <IndexDeleteDialog />
      <IndexUpdateDialog />
      <LogOutDialog />
      <RequireCCFeatureDialog />
      <RequirePlanUpgradeDialog />
      <RoleAddTeamMemberDialog />
      <RoleAddUserDialog />
      <RoleCreateDialog />
      <RoleDeleteDialog />
      <RoleUpdateDialog />
      <ProjectTeamMemberInviteDialog />
      <ShareWorkspaceDialog />
      <SessionCloseDialog />
      <ShareOrganizationDialog />
      <TableDeleteDialog />
      <TableFieldDeleteDialog />
      <TeamMemberDeleteDialog />
      <TeamMemberEditDialog />
      <TeamMemberInviteDialog />
      <TeamMemberInviteToOrganizationDialog />
      <TeamMemberInviteToProjectOrganizationDialog />
      <TeamMemberRemoveFromOrganizationDialog />
      <TeamMemberRemoveFromRoleDialog />
      <UserDeleteDialog />
      <UserEditDialog />
      <UserRemoveFromRoleDialog />
      <UserInterfaceMembersDialog />
      <ViewCreateDialog />
      <ViewDeleteDialog />
      <ViewJSONFieldDialog />
      <ViewReachTextFieldDialog />
      <ViewUpdateDialog />
      <WarningLimitsDialog />
      <WorkspaceCreateDialog />
      <ProjectCreateDialog />
      <ProjectDeleteDialog />
      <FrontEndDeleteDialog />
      <ProjectUpdateDialog />
      <WorkspaceDeleteDialog />
      <WorkspaceLeaveDialog />
      <WorkspaceUpdateDialog />
      <PaymentWarningDialog />
      <IntegratedAuthConfirmationDialog />

      <Elements
        fonts={ [
          {
            cssSrc:
              'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700',
          },
        ] }
      >
        <React.Fragment>
          <PaymentDetailsUpdateDialog />
          <UserPaymentDetailsUpdateDialog />
          <UserWorkspacePaymentDetailsUpdateDialog />
          <UserProjectPaymentDetailsUpdateDialog />
          <OrganizationPaymentDetailsUpdateDialog />
        </React.Fragment>
      </Elements>
    </If>
  );
};

const WorkspaceRoutes = () => {
  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  const location = useLocation();

  return (
    <AppFlow.Plate
      withSecondaryNav={ !!matchPath(location.pathname, { path: APP_URL.settings }) ||
        !!matchPath(location.pathname, { path: APP_URL.appServices }) }
    >
      <Switch>
        <PrivateRoute
          exact
          path={ APP_URL.workspaceHome }
          render={ () => (
            <WaitingComponent>
              <Home />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.settings }
          render={ () => (
            <WaitingComponent>
              <Settings />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.data }
          render={ () => (
            <WaitingComponent>
              <Data />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.apiExplorer }
          render={ () => (
            <WaitingComponent>
              <APIExplorer />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.functions }
          render={ () => (
            <WaitingComponent>
              <Functions />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.users }
          render={ () => (
            <WaitingComponent>
              <Users />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.integrations }
          render={ () => (
            <WaitingComponent>
              <Integrations />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.appServices }
          render={ () => (
            <WaitingComponent>
              <AppServices />
            </WaitingComponent>
          ) }
        />
        <Redirect to={ APP_URL.workspaceHome } />
      </Switch>
    </AppFlow.Plate>
  );
};

export const Routes = () => {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { callbackWithAuth, currentCallback } = useCallbackUrl();

  if (currentCallback && auth.isAuthorized) {
    callbackWithAuth();
  }

  if (isMobile() && auth.isAuthorized && auth.isEmailVerified && !currentCallback) {
    return <Mobile />;
  }

  let redirectTo: string = localStorage.getItem(REDIRECT_TO) || '';
  if (!location.pathname.startsWith(APP_URL.onboarding) && redirectTo) {
    localStorage.removeItem(REDIRECT_TO);
    if (redirectTo.indexOf('?') >= 0) {
      history.push(redirectTo);
    }
  } else {
    redirectTo = APP_URL.developerHome;
  }

  return (
    <RootContainer>
      <Switch>
        <Route path={ APP_URL.error } component={ Error } />
        <Route path={ APP_URL.invite } component={ Invite } />
        <Route path={ APP_URL.referralAppbuilder } component={ Referral } />
        <PrivateRoute
          path={ APP_URL.fileExplorer }
          render={ () => (
            <WaitingComponent>
              <FileExplorer />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.developerHome }
          render={ () => <DeveloperHome /> }
        />
        <PrivateRoute path={ APP_URL.account } render={ () => <Account /> } />
        <PrivateRoute
          path={ APP_URL.organization }
          render={ () => <Organization /> }
        />
        <PrivateRoute
          path={ APP_URL.onboarding }
          render={ () => (
            <WaitingComponent>
              <Onboarding />
            </WaitingComponent>
          ) }
        />
        <PrivateRoute
          path={ APP_URL.workspace }
          render={ () => <WorkspaceRoutes /> }
        />
        <PrivateRoute path={ APP_URL.projectHome } render={ () => <Project /> } />
        <Redirect to={ redirectTo } />;
      </Switch>
      <Dialogs />
      <DocumentTitle pathname={ location.pathname } />
    </RootContainer>
  );
};
