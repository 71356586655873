//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Dialog, Button, Text, Heading, Column, useModal, Grid } from '@8base/boost';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Trans } from 'utils/translate';
import { PLANS, COLOR_BY_PLAN_NAME, FEATURE_DISPLAY_NAMES, LIMITS_NAMES } from 'graphql/constants/billing';

import upgradeImage from 'images/upgrade.svg';
import { useTranslation } from 'react-i18next';
import { UserProjectPaymentDetailsUpdateDialog } from './BillingUpdateDialog/UserProjectPaymentDetailsUpdateDialog';

// import { numberToWord } from 'utils/numberToWord';
// import { UserProjectPaymentDetailsUpdateDialog } from './BillingUpdateDialog/UserProjectPaymentDetailsUpdateDialog';
// import { useBillingInfo } from '../providers/BillingProvider/useBillingInfo';

const REQUIRE_PLAN_UPGRADE_DIALOG_ID = 'REQUIRE_PLAN_UPGRADE_DIALOG_ID';

const UpperCaseText = styled.span`
  text-transform: uppercase;
`;

const RequirePlanUpgradeByLimit = ({ limitMetricName, nextPlan, onClose }) => {
  const { t } = useTranslation();
  // const { plan: currentPlan } = useBillingInfo();
  // const limitMetrics = R.propOr([], 'limitMetrics', currentPlan);
  // const limitMetricInfo = limitMetrics.find(({ name }) => name === limitMetricName) || {};

  let header = '';
  let title = '';
  let note = '';

  if (limitMetricName === LIMITS_NAMES.teamMembers) {
    header = t('dialogs.requirePlanUpgrade.limit.teamMember.header', { defaultValue: 'Share Workspace' });
    title = t('dialogs.requirePlanUpgrade.limit.teamMember.title', { defaultValue: 'Need an additional team member?' });

    // TODO: add hardlimitWord
    // if (currentPlan && currentPlan.name !== PLANS.freeTier) {
    //   const hardLimitWord = numberToWord(limitMetricInfo.hardLimit);
    //
    //   note = (
    //     <Trans i18nKey="dialogs.requirePlanUpgrade.limit.teamMember.note">
    //       Our <UpperCaseText>{ currentPlan.displayName }</UpperCaseText> Plan only accomodates a team of { hardLimitWord } developers,
    //       upgrade to our <UpperCaseText>{ nextPlan.displayName }</UpperCaseText> Plan.
    //     </Trans>
    //   );
    // } else {
    //   note = (
    //     <Trans i18nKey="dialogs.requirePlanUpgrade.limit.teamMember.freeTierNote">
    //       Before you can add another team member you must upgrade to the <UpperCaseText>{ nextPlan.displayName }</UpperCaseText> Plan.
    //     </Trans>
    //   );
    // }

    note = (
      <Trans i18nKey="dialogs.requirePlanUpgrade.limit.teamMember.freeTierNote">
        Before you can add another team member you must upgrade to the <UpperCaseText>{ nextPlan.displayName }</UpperCaseText> Plan.
      </Trans>
    );
  }

  return (
    <React.Fragment>
      <Dialog.Header title={ header } onClose={ onClose } />
      <Dialog.Body>
        <Column alignItems="center" css={ css`text-align: center;` }>
          <img src={ upgradeImage } alt="" />
          <Heading type="h3">
            { title }
          </Heading>
          <Text color="LIGHT_TEXT_COLOR">
            { note }
          </Text>
        </Column>
      </Dialog.Body>
    </React.Fragment>
  );
};


const RequirePlanUpgradeByFeature = ({ featureName, nextPlan, onClose }) => {
  const { t } = useTranslation();
  const featureNameMessage = `${FEATURE_DISPLAY_NAMES[featureName]}` || 'This';

  return (
    <React.Fragment>
      <Dialog.Header title={ t('dialogs.requirePlanUpgrade.feature.title') } onClose={ onClose } />
      <Dialog.Body>
        <Column alignItems="center" css={ css`text-align: center;` }>
          <img src={ upgradeImage } alt="" />
          <Heading type="h3">
            <Trans i18nKey="dialogs.requirePlanUpgrade.feature.header">
              { featureNameMessage } is a premium feature.
            </Trans>
          </Heading>
          <Text color="LIGHT_TEXT_COLOR">
            <Trans i18nKey="dialogs.requirePlanUpgrade.feature.note" >
              Upgrade to the <Text color={ COLOR_BY_PLAN_NAME[nextPlan.name] } weight="semibold" css={{ textTransform: 'uppercase' }}>{ nextPlan.displayName }</Text> plan to unlock it.
            </Trans>
          </Text>
        </Column>
      </Dialog.Body>
    </React.Fragment>
  );
};

const RequirePlanUpgradeDialog = () => {
  const { closeModal, openModal, args: { nextPlan = {}, limitMetricName, featureName, project }} = useModal(REQUIRE_PLAN_UPGRADE_DIALOG_ID);

  const onClose = useCallback(() => {
    closeModal(REQUIRE_PLAN_UPGRADE_DIALOG_ID);
  }, [closeModal]);

  const openUpdatePaymentDetailsDialog = (nextPlan) => () => {
    const name = R.prop('name', nextPlan);

    if (!nextPlan || name === PLANS.enterprise) {
      if (window.HubSpotConversations && typeof window.HubSpotConversations.widget.open === 'function') {
        window.document.body.classList.remove('hide-hubspot');

        window.HubSpotConversations.widget.open();
      }
    } else {
      openModal(UserProjectPaymentDetailsUpdateDialog.ID, { plan: nextPlan, project });
      onClose();
    }
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Dialog id={ REQUIRE_PLAN_UPGRADE_DIALOG_ID } onClose={ onClose } { ...E2E.$props('requirePlanUpgradeDialog.body') }>
      <Choose>
        <When condition={ !!featureName }>
          <RequirePlanUpgradeByFeature nextPlan={ nextPlan } featureName={ featureName } onClose={ onClose } />
        </When>
        <When condition={ !!limitMetricName }>
          <RequirePlanUpgradeByLimit nextPlan={ nextPlan } limitMetricName={ limitMetricName } onClose={ onClose } />
        </When>
      </Choose>
      <Dialog.Footer>
        <Grid.Layout columns="1fr 1fr" stretch gap="md">
          <Grid.Box>
            <Button color="neutral" onClick={ onClose }>
              <Trans i18nKey="shared.cancel">
                Cancel
              </Trans>
            </Button>
          </Grid.Box>
          <Grid.Box>
            <Button onClick={ openUpdatePaymentDetailsDialog(nextPlan) } stretch { ...E2E.$props('requirePlanUpgradeDialog.submitButton') }>
              <Choose>
                <When condition={ !nextPlan || nextPlan.name === PLANS.enterprise }>
                  <div onClick={ () => openInNewTab('https://8base-dev.atlassian.net/servicedesk/customer/portals') }>
                    <Trans i18nKey="dialogs.requirePlanUpgrade.contactSales">
                      Contact Sales
                    </Trans>
                  </div>
                </When>
                <Otherwise>
                  <Trans i18nKey="dialogs.requirePlanUpgrade.upgrade">
                    Upgrade
                  </Trans>
                </Otherwise>
              </Choose>
            </Button>
          </Grid.Box>
        </Grid.Layout>
      </Dialog.Footer>
    </Dialog>
  );
};

RequirePlanUpgradeDialog.ID = REQUIRE_PLAN_UPGRADE_DIALOG_ID;

export { RequirePlanUpgradeDialog };
