// @flow
import React from 'react';
import { BillingPaymentDetails } from './BillingPaymentDetails';
import { BillingHistory } from './BillingHistory';
import { BillingWorkspaceUsage } from './BillingWorkspaceUsage';
import BillingIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Text, Row } from '@8base/boost';
import { BillingQuotasUsage } from './BillingQuotasUsage';
import { useProjectPlan } from 'graphql/hooks';
import { BillingPlanDowngradeProgress } from './BillingPlanDowngradeProgress';
import * as R from 'ramda';

type BillingProps = {
  card: string,
  countUsers: string,
  countWorksPaces: string,
  nextPaymentDate: string,
  fromPaymentDate: string,
  price: string,
  apiHost: string,
  projectId: string,
  refetchProjectPlan: () => boolean,
}

export const Billing = ({ card, countUsers, countWorksPaces, nextPaymentDate,
  fromPaymentDate, price, projectId, refetchProjectPlan, apiHost }:BillingProps) => {

  const { projectPlan, loading } = useProjectPlan(projectId, apiHost);
  const plan = R.path(['plan'], projectPlan);
  const nextPlan = { ...plan?.nextPlan };

  return (
    <div>
      <Row>
        <BillingIcon style={{ marginTop: '10px', fontSize: 32 }} />
        <Text kind="h1">Billing</Text>
      </Row>

      { (Object.keys(nextPlan).length > 0 && !loading) && (
        <BillingPlanDowngradeProgress
          currentPlan={ plan }
          nextPlan={ nextPlan }
          nextPaymentDt={ nextPaymentDate }
        />
      ) }

      { card && (
        <BillingPaymentDetails apiHost={ apiHost } card={ card } refetch={ refetchProjectPlan } />
      ) }

      <BillingQuotasUsage projectId={ projectId } apiHost={ apiHost } />

      <BillingWorkspaceUsage
        price={ price }
        nextPaymentDate={ nextPaymentDate }
        fromPaymentDate={ fromPaymentDate }
        countWorksPaces={ countWorksPaces }
        countUsers={ countUsers }
      />
      <br />
      { projectId && <BillingHistory projectId={ projectId } apiHost={ apiHost } /> }
    </div>
  );
};

