/* eslint-disable react/jsx-closing-tag-location */
// @flow
// $FlowFixMe
import css from '@emotion/css';
import styled from '@emotion/styled';
import * as R from 'ramda';
// $FlowIgnore
import React, { useEffect, useState } from 'react';

import { AsyncContent, Grid, Link, Row, Text, useModal } from '@8base/boost';
// $FlowFixMe
import { useParams } from 'react-router-dom';

// import LatesDeploymentsIcon from '@material-ui/icons/BackupOutlined';
// import BackendIcon from '@material-ui/icons/CloudOutlined';
import CurrentPlanIcon from '@material-ui/icons/CreditCard';
import FrontendIcon from '@material-ui/icons/DesktopWindows';
import TeamIcon from '@material-ui/icons/PeopleOutlined';
import QuotasIcon from '@material-ui/icons/Speed';

import { APP_URL, useBuildUrl } from 'common/routing';
import { ENDPOINT_URI, WORKSPACE_ID } from 'common/constants/apolloOperationContextOptions';
import { DashboardCard } from './components/DashboardCard';
import { LatestDeploymentsCard } from './components/LatestDeploymentsCard';
import { PlanCard } from './components/PlanCard';
import { QuotasCard } from './components/QuotasCard';
import { TeamCard } from './components/TeamCard';
import { WorkspaceCard } from './components/WorkspaceCard';
import { WorkspaceSelector } from './components/WorkspaceSelector';

import { Link as RouterLink } from 'common/router';
import { Trans } from 'utils/translate';
import { useAuth } from '@8base-react/auth';

// $FlowIgnore
import { ReactComponent as LatestDeploymentsIcon } from 'images/icons/icon-latest-deployments.svg';
// $FlowIgnore
import { ReactComponent as BackendIcon } from 'images/icons/icon-data-cloud.svg';
// $FlowIgnore
import { ReactComponent as DashboardIcon } from 'images/icons/icon-dashboard.svg';

import { BillingChangePlanDialog } from 'dialogs/BillingChangePlanDialog';

import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
// $FlowIgnore
import { useLazyQuery } from 'react-apollo';
import {
  TEAM_MEMBERS_LIST_QUERY,
  PROJECT_DEPLOYMENTS_QUERY,
  PROJECT_FRONTEND_QUERY,
} from '../../../../graphql/queries';


const TitleSpacedRow = styled(Row)`
  margin-bottom: 35px;
`;

const SpacedRow = styled(Row)`
  margin-bottom: 24px;
`;

const DASHBOARD_AREAS = [
  ['backend', 'frontend', 'quotas'],
  ['team', 'deployments', 'plan'],
];

const WORKSPACES_AREAS = [['backend', 'frontend']];

const BILLING_CHANGE_PLAN_DIALOG_ID = 'BILLING_CHANGE_PLAN_DIALOG_ID';
const ACTIVE_STATUS = 'active';

type ProjectDashboardProps = {
  projectInfo?: any;
  loading: boolean;
  isOwnerOrAdminOrganization: boolean;
  environmentIds?: string[];
  isWorkspaceAdministrator: boolean;
}

export const ProjectDashboard = ({ projectInfo, loading, isOwnerOrAdminOrganization }: ProjectDashboardProps) => {
  const { authState } = useAuth();
  const { openModal } = useModal(BILLING_CHANGE_PLAN_DIALOG_ID);
  const apiHost = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'apiHost'], projectInfo);
  const { projectId } = useParams();
  const backendWorkspaceId = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'id'], projectInfo);
  const [frontend, setFrontend] = useState([]);
  const [deployments, setDeployments] = useState([]);
  const [members, setMembers] = useState([]);
  const openChangePlanDialog = (projectInfo) => {
    openModal(BillingChangePlanDialog.ID, projectInfo);
  };

  const [getFrontend, { loading: loadingFrontend }] = useLazyQuery(
    PROJECT_FRONTEND_QUERY, {
      skip: !projectId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setFrontend(data?.system?.projectFrontend || {});
      },
    },
  );

  const [getUserDeployments, { loading: loadingDeployments }] = useLazyQuery(
    PROJECT_DEPLOYMENTS_QUERY, {
      skip: !projectId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setDeployments(data?.system?.projectDeployments || {});
      },
    },
  );

  const [getUsersPerEnvironment, { loading: loadingUsers }] = useLazyQuery(
    TEAM_MEMBERS_LIST_QUERY, {
      skip: !backendWorkspaceId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setMembers(data?.system?.environmentMembersList || {});
      },
    },
  );

  useEffect(() => {
    if (!backendWorkspaceId) { return; }

    setTimeout(() => {
      getFrontend({
        context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost },
        variables: { projectId },
      });
    }, 100);

    setTimeout(() => {
      getUsersPerEnvironment({
        context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost },
        variables: { filter: { status: { equals: ACTIVE_STATUS }}},
      });
    }, 200);

    setTimeout(() => {
      getUserDeployments({
        context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost },
        variables: { projectId },
      });
    }, 300);
  }, [getUsersPerEnvironment, getUserDeployments, getFrontend, projectId, backendWorkspaceId, apiHost]);

  const buildUrl = useBuildUrl();
  const Owner = R.pathOr(false, ['owner'], projectInfo);
  const isOwner = authState.email === Owner.email;

  const { plans } = useBillingInfo();

  return (
    <>
      <TitleSpacedRow justifyContent="start" alignItems="center">
        <DashboardIcon fontSize="large" />
        <Text kind="h1">
          <Trans i18nKey="projects.dashboard.title">
            Project Dashboard:
          </Trans>
          { projectInfo?.name }
        </Text>
      </TitleSpacedRow>

      <SpacedRow gap="lg" justifyContent="between" alignItems="stretch">
        <AsyncContent loading={ loading }>
          <Grid.Layout
            stretch
            justifyContent="flex-start"
            alignItems="center"
            areas={ WORKSPACES_AREAS }
            gap="lg"
            columns="repeat(2, minmax(0, 1fr))"
            rows="82px"
          >
            <WorkspaceCard
              bordered
              withOpenButton
              workspaceInfo={ projectInfo?.backendWorkspaces?.items[0] }
              projectInfo={ projectInfo }
              css={ css` grid-area: backend; ` }
            />

            <If condition={ frontend?.frontendWorkspaces?.count }>
              <WorkspaceSelector
                css={ css` grid-area: frontend; ` }
                projectInfo={ projectInfo }
                projectDeployments={ deployments }
                workspaces={ frontend?.frontendWorkspaces }
              />
            </If>
          </Grid.Layout>
        </AsyncContent>
      </SpacedRow>

      <Row gap="lg">
        <Grid.Layout
          stretch
          justifyContent="flex-start"
          alignItems="stretch"
          areas={ DASHBOARD_AREAS }
          gap="lg"
          columns="repeat(3, minmax(0, 1fr))"
          rows="350px 320px"
        >
          <DashboardCard
            loading={ loading }
            title="All Environments"
            data={ projectInfo?.backendUtilization }
            projectInfo={ projectInfo }
            apiHost={ apiHost }
            icon={ <BackendIcon style={{ fontSize: 25, marginRight: '10px' }} /> }
            css={ css` grid-area: backend; ` }
          />
          <DashboardCard
            loading={ loading || loadingFrontend }
            title="Frontends Utilization"
            data={ frontend?.frontendUtilization }
            apiHost={ apiHost }
            projectInfo={ projectInfo }
            frontendWorkspaces={ frontend?.frontendWorkspaces }
            icon={ <FrontendIcon style={{ fontSize: 25, marginRight: '10px' }} /> }
            css={ css` grid-area: frontend; ` }
            plans={ plans }
          />
          <QuotasCard
            title="Quotas"
            loading={ loading }
            apiHost={ apiHost }
            icon={ <QuotasIcon style={{ fontSize: 25, marginRight: '10px' }} /> }
            projectId={ projectId }
            link={ (isOwner || isOwnerOrAdminOrganization) && <Link tagName={ RouterLink }
              to={ buildUrl(APP_URL.projectBilling, { pathParams: { projectId }}) }>
              <Trans i18nKey="projects.dashboard.manageButton">
                  Manage
              </Trans>
            </Link> }
            css={ css` grid-area: quotas; ` }
          />
          <TeamCard
            title="Team"
            loading={ loadingUsers }
            projectInfo={ projectInfo }
            projectUsers={ members }
            icon={ <TeamIcon style={{ fontSize: 25, marginRight: '10px' }} /> }
            link={ <Link tagName={ RouterLink }
              to={ buildUrl(APP_URL.projectTeam, { pathParams: { projectId }}) }>
              <Trans i18nKey="projects.dashboard.manageButton">
                  Change
              </Trans>
            </Link> }
            owner={{}}
            css={ css` grid-area: team; ` }
          />
          <LatestDeploymentsCard
            loading={ loading || loadingDeployments }
            title="Latest Deployments"
            projectInfo={ deployments }
            icon={ <LatestDeploymentsIcon style={{ fontSize: 25, marginRight: '10px' }} /> }
            css={ css` grid-area: deployments; ` }
          />
          { /* TODO: change plan to use project plan instead of workspace plan projectInfo?.plan */ }
          <If condition={ isOwner || isOwnerOrAdminOrganization }>
            <PlanCard
              loading={ loading }
              projectId={ projectId }
              title="Current Plan"
              apiHost={ apiHost }
              icon={ <CurrentPlanIcon style={{ fontSize: 25, marginRight: '10px' }} /> }
              link={ <Link onClick={ () => openChangePlanDialog(projectInfo) }
                { ...E2E.$props('settings.billing.changePlanBtn') }>
                <Trans i18nKey="settings.billing.plan.change">
                   Change
                </Trans>
              </Link> }
              css={ css`
              grid-area: plan;
            ` }
            />
          </If>
        </Grid.Layout>
      </Row>
    </>
  );
};
