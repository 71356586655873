// @flow
import * as R from 'ramda';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import fp from 'lodash/fp';


export type BuildUrlOptions = {
  queryParams?: { [string]: any },
  pathParams?: { [string]: string | number | boolean | null | void },
  ignoreWorkspaceId?: boolean,
};

/**
 * Build url with query and path params.
 *
 * @example
 * buildUrl('/products/:id', { pathParams: { id: 123 }, queryParams: { filter: 'someFilter' } }) // "/products/123?filter=someFilter"
 */
export const buildUrl = (path: string, options: BuildUrlOptions = {}) => {
  let query = '';

  if (options.queryParams) {
    const queryParamsString = qs.stringify(options.queryParams);
    query = queryParamsString.length > 0 ? `?${queryParamsString}` : '';
  }

  const filteredPathParams = R.mapObjIndexed(num => !!num ? num : undefined, options.pathParams);

  const generatedPath = generatePath(path, filteredPathParams);

  return `${generatedPath}${query}`;
};


export const buildUrlWithWorkspace = fp.memoize(
  (workspaceId?: string | null) => (path: string, { pathParams, queryParams, ignoreWorkspaceId }: BuildUrlOptions = {}) => {
    const pathParamsWithWorkspaceId = ignoreWorkspaceId
      ? pathParams || {}
      : {
        workspaceId,
        ...(pathParams || {}),
      };

    return buildUrl(path, { pathParams: pathParamsWithWorkspaceId, queryParams });
  });
