// @flow
// $FlowFixMe waiting for update Flow
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Grid, Button, Row, Icon, Heading, Select } from '@8base/boost';
// $FlowFixMe waiting for update Flows
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';
import { i18n } from 'i18n';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import { APP_URL, useBuildUrl } from 'common/routing';
import { ENVIRONMENTS_LIST_QUERY } from 'graphql/queries';
import { useWorkspace } from 'providers/WorkspaceProvider';
import { environmentAccessor, MASTER_ENVIRONMENT } from 'utils/environmentAccessor';

import { AppFlowUserDropdown } from './AppFlowUserDropdown';
import { AppFlowLearningCenter } from './AppFlowLearningCenter';
import { useCiInstall } from '../../graphql/hooks';

type AppFlowEmptyHeaderProps = {
  children: React$Node,
};

const AppFlowHeaderTag = styled(Grid.Layout)({
  height: '56px',
  padding: '0 24px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #E9EFF4',
  alignItems: 'center',
});

// $FlowFixMe
const AppFlowEmptyHeader = React.memo(({ children, ...rest }: AppFlowEmptyHeaderProps) => {
  const [expanded, setExpanded] = useState(localStorage.getItem('nav') !== 'false');
  const { pathname } = useLocation();

  const toggleNav = () => {
    const value = localStorage.getItem('nav') === 'false';

    localStorage.setItem('nav', String(value));

    setExpanded(value);

    window.dispatchEvent(new Event('storage'));
  };

  return (
    <AppFlowHeaderTag columns="auto 1fr auto" gap="lg" { ...rest }>
      <Row alignItems="center" gap="none">
        <If condition={ !/^file/.test(pathname) }>
          <Button color="neutral" variant="outlined" squared onClick={ toggleNav } { ...PT.$props('mainNav.toggleBtn') }>
            <Icon cursor="pointer" name={ expanded ? 'CloseMenu' : 'OpenMenu' } />
          </Button>
          <Heading css={{ marginLeft: 12 }} type="h3">
            <Switch>
              <Route path={ APP_URL.workspaceHome } exact component={ () => i18n.t('header.title.home', { defaultValue: 'Home' }) } />
              <Route path={ APP_URL.data } component={ () => i18n.t('header.title.dataBuilder', { defaultValue: 'Data' }) } />
              <Route path={ APP_URL.functions } component={ () => i18n.t('header.title.functions', { defaultValue: 'Functions' }) } />
              <Route path={ APP_URL.apiExplorer } component={ () => i18n.t('header.title.apiExplorer', { defaultValue: 'API Explorer' }) } />
              <Route path={ APP_URL.settings } component={ () => i18n.t('header.title.settings', { defaultValue: 'Settings' }) } />
              <Route path={ APP_URL.users } component={ () => i18n.t('header.title.users', { defaultValue: 'User Management' }) } />
              <Route path={ APP_URL.appServices } component={ () => i18n.t('header.title.appServices', { defaultValue: 'App Services' }) } />
              <Route path={ APP_URL.integrations } component={ () => i18n.t('header.title.integrations', { defaultValue: 'Integrations' }) } />
            </Switch>
          </Heading>
        </If>
      </Row>
      { children }
    </AppFlowHeaderTag>
  );
});

const AppFlowHeader = () => {
  const { authState } = useAuth();
  const workspace = useWorkspace();
  const history = useHistory();
  const buildUrl = useBuildUrl();
  const { pathname } = useLocation();

  const [ciInstall, { loading: ciInstallLoading }] = useCiInstall({ workspaceId: workspace.id });

  // $FlowFixMe waiting for update Flow
  const is8base = React.useMemo(() => /@8base.com$/.test(R.propOr('', 'email', authState)), [authState]);

  const { data, loading: envListLoading } = useQuery(ENVIRONMENTS_LIST_QUERY, {
    skip: !(is8base || workspace.isCiCdEnabled),
    variables: {
      email: authState.email,
      workspaceId: workspace.id,
    },
    onCompleted: (completedData) => {
      // redirect to env that user have access to
      const envNamesList = R.pathOr([], ['system', 'getEnvironmentRoles'], completedData)
        .filter(({ exists }) => Boolean(exists))
        .map(({ environmentName }) => environmentName);

      const currentEnv = environmentAccessor.getEnvironment(workspace.id) || MASTER_ENVIRONMENT;

      if (!envNamesList.includes(currentEnv) && workspace.id) {
        if (envNamesList.length > 0) {
          const env = envNamesList[0];
          environmentAccessor.setEnvironment(workspace.id, env);

        } else {
          toast.error('You have not access to this environment, contact with workspace owner please');
        }
      }
    },
  });

  // $FlowFixMe waiting for update Flow
  const environmentOptions = React.useMemo(() => {
    const envList = R.pathOr([], ['system', 'getEnvironmentRoles'], data);

    return envList
      .filter(({ exists }) => Boolean(exists))
      .map(({ environmentName: name }) => ({ label: name, value: name }));
  }, [data]);

  // $FlowFixMe waiting for update Flow
  const onChangeEnvironment = React.useCallback((env) => {
    const regex = /^\/workspace\/[a-zA-Z0-9_-]+\/app-services\/roles\/[a-zA-Z0-9_-]+\/(data|users|apps|team)$/;
    if (workspace.id) {
      environmentAccessor.setEnvironment(workspace.id, env);
      if (regex.test(pathname)) {
        history.push(buildUrl(APP_URL.appServicesRoles));
      }
    }
    window.location.reload();
  }, [buildUrl, history, pathname, workspace.id]);

  return (
    <Grid.Box area="header">
      <AppFlowEmptyHeader>
        <Grid.Box justifyContent="center" alignItems="flex-end">
          <Row alignItems="center" gap="lg">
            <Choose>
              <When condition={ workspace.isCiCdEnabled }>
                <Select
                  css={ css`width: 172px;` }
                  onChange={ onChangeEnvironment }
                  options={ environmentOptions }
                  loading={ envListLoading }
                  value={ environmentAccessor.getEnvironment(workspace.id) || MASTER_ENVIRONMENT }
                />
              </When>
              <When condition={ !workspace.isCiCdEnabled }>
                <Button
                  color="neutral"
                  size="sm"
                  css={ css`padding: 0 32px;` }
                  onClick={ ciInstall }
                  loading={ ciInstallLoading }
                >
                  Enable CI/CD
                </Button>
              </When>
            </Choose>

            <AppFlowLearningCenter />
          </Row>
        </Grid.Box>
        <Grid.Box justifyContent="center">
          <AppFlowUserDropdown />
        </Grid.Box>
      </AppFlowEmptyHeader>
    </Grid.Box>
  );
};

export { AppFlowHeader, AppFlowEmptyHeader };
