/* eslint-disable react/jsx-closing-tag-location */
// @flow
// $FlowFixMe waiting for update Flow
import React, { useEffect, useMemo, useState } from 'react';
// $FlowFixMe waiting for update Flow
import { Row } from '@8base/boost';
import css from '@emotion/css';
import styled from '@emotion/styled';
// $FlowFixMe
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppFlow } from 'common/AppFlow';
import { CommonFlowBars } from 'common/CommonFlow/CommonFlowBars';
import { APP_URL } from 'common/routing';
import { UserFlow } from 'common/UserFlow';
import { useUserAccountInfo, useOrganization } from 'graphql/hooks';
import * as R from 'ramda';
import { ENDPOINT_URI, IGNORE_WORKSPACE, WORKSPACE_ID } from 'common/constants/apolloOperationContextOptions';
import { ProjectBackend } from './routes/Backend';
import { ProjectBilling } from './routes/Billing';
import { ProjectDashboard } from './routes/Dashboard';
import { ProjectSettings } from './routes/Settings';
import { ProjectTeam } from './routes/Team';
import { ProjectUI } from './routes/UI';
import { useAuth } from '@8base-react/auth';
import { ProjectHomeNav, ProjectHomeNavContent, ProjectHomeNavInfo, ProjectMainPlate, ProjectPage } from './components';
import { getUserRoleAtOrganization } from 'utils/organization';
import { ORGANIZATION_ROLE, ORGANIZATION_TYPES } from 'common/constants/organization';
import { useProject } from '../../providers/ProjectProvider/useProject';
import { useFetchEnvironments } from 'utils/hooks';
// $FlowIgnore
import { useLazyQuery } from 'react-apollo';
import { USER_ACCOUNT_PERMISSIONS } from '../../graphql/queries';


const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const Separator = styled('div')`
  width: 1px;
  height: 40px;
  background-color: gray;
`;

const Projects = () => {
  const { authState } = useAuth();

  const { project, loading: projectLoading, refetch } = useProject();

  const Owner = R.pathOr(false, ['owner'], project);

  const organizationID = R.pathOr('', ['organization', 'id'], project);
  const type = R.pathOr('', ['organization', 'type'], project);
  const backendWorkspaceId = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'id'], project);
  const { organization, loading: organizationLoading } = useOrganization(organizationID);
  const [isWorkspaceAdministrator, setIsWorkspaceAdministrator] = useState(false);


  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const [userPermissions] = useLazyQuery(USER_ACCOUNT_PERMISSIONS, {
    fetchPolicy: 'network-only',
    skip: !project?.apiHost || backendWorkspaceId,
    onCompleted: (data) => {
      const permissionsResponse = data?.system?.environmentMember?.permissions?.items || [];
      const workspacePermission = permissionsResponse.find(item => !!item?.permission?.workspaceAdministration);
      setIsWorkspaceAdministrator(workspacePermission?.permission?.workspaceAdministration?.allow || false);
    },
    onError: (_error) => {
      setIsWorkspaceAdministrator(false);
    },
  });

  const permissionRole = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  useEffect(() => {
    if (!backendWorkspaceId) { return; }

    setTimeout(() => {
      userPermissions({
        context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: project?.apiHost },
      });
    }, 100);
  }, [userPermissions, backendWorkspaceId, project]);

  const isOwner = authState.email === Owner.email;
  const isOwnerOrAdminOrganization = (permissionRole === ORGANIZATION_ROLE.owner ||
    permissionRole === ORGANIZATION_ROLE.admin) && (type === ORGANIZATION_TYPES.agency.toLowerCase());

  const { environmentIds } = useFetchEnvironments(backendWorkspaceId, project?.apiHost);

  useEffect(() => {
    if (!projectLoading && !organizationLoading) {
      requestAnimationFrame(window.htmlLoader.hide);
    }
  }, [projectLoading, organizationLoading]);

  const projectMainPlateNav = (
    <ProjectHomeNav>
      <ProjectHomeNavInfo projectInfo={ project } loading={ projectLoading && organizationLoading } />
      <ProjectHomeNavContent
        isOwner={ isOwner }
        isOwnerOrAdminOrganization={ isOwnerOrAdminOrganization }
        isWorkspaceAdministrator={ type === ORGANIZATION_TYPES.agency.toLowerCase() ? true : isWorkspaceAdministrator }
      />
      <DividerNav />
    </ProjectHomeNav>

  );

  return (
    <ProjectPage>
      <CommonFlowBars />
      <UserFlow.HeaderWhite
        position="relative"
        size="md"
        rightContent={ (
          <Row gap="lg" alignItems="center">
            <UserFlow.LearningCenter />
            <Separator />
            <UserFlow.UserDropdown />
          </Row>
        ) }
      />
      <ProjectMainPlate
        nav={ projectMainPlateNav }
        content={ (
          <AppFlow.Content css={ css`flex-grow: 1;min-width: 85%;max-width: 85%;` } padding="projects">
            { <Switch>
              <Route path={ APP_URL.projectDashboard } render={ () => (
                <ProjectDashboard
                  loading={ projectLoading }
                  projectInfo={ project }
                  isOwnerOrAdminOrganization={ isOwnerOrAdminOrganization }
                  isWorkspaceAdministrator={ type === ORGANIZATION_TYPES.agency.toLowerCase() ? true : isWorkspaceAdministrator }
                />
              ) } />
              <Route path={ APP_URL.projectBackend } render={ () => (
                <ProjectBackend projectInfo={ project } />
              ) } />
              <Route path={ APP_URL.projectUI } render={ () => (
                <ProjectUI projectInfo={ project } refetch={ refetch } loading={ projectLoading } />
              ) } />
              <Route path={ APP_URL.projectTeam } render={ () => (
                 <>
                   {
                     environmentIds.length > 0 && <ProjectTeam
                       projectInfo={ project }
                       environmentIds={ environmentIds }
                       isWorkspaceAdministrator={ type === ORGANIZATION_TYPES.agency.toLowerCase() ? true : isWorkspaceAdministrator }
                     />
                   }
                 </>
              ) } />
              <Route path={ APP_URL.projectBilling } render={ () => (
                <ProjectBilling projectInfo={ project } />
              ) } />
              <Route path={ APP_URL.projectSettings } component={ ProjectSettings } />
              <Redirect to={ APP_URL.projectDashboard } />;
            </Switch> }
          </AppFlow.Content>
        ) }
      />
    </ProjectPage>
  );
};

export default Projects;
