// @flow
// $FlowFixMe
import React, { useEffect, useState } from 'react';
// $FlowFixMe
import { Redirect, Route, Switch } from 'react-router-dom';
// $FlowFixMe
import { Row } from '@8base/boost';
import styled from '@emotion/styled';
import { useAuth } from '@8base-react/auth';

import { APP_URL } from 'common/routing';
import { UserFlow } from 'common/UserFlow';
import { useOrganizationsList, useUserAccountInfo, useInboxList, useUndoneInboxCount, useUserAccountInfoUpdate } from 'graphql/hooks';
import { CommonFlowBars } from 'common/CommonFlow/CommonFlowBars';
import { useOnOpenWorkspace } from 'utils/hooks';

import { DeveloperHomeNav, DeveloperHomeNavContent, DeveloperHomePage, DeveloperHomeMainPlate } from './components';

// Routes
import { DeveloperHomeProjectsContainer } from './routes/projects/containers/DeveloperHomeProjects.container';
import { DeveloperHomeInboxContainer } from './routes/inbox/containers/DeveloperHomeInbox.container';

const Separator = styled('div')`
  width: 1px;
  height: 40px;
  background-color: gray;
`;

let preventReRenders = false;
const DeveloperHome = () => {
  const [isCompletedUserAccountInfo, setCompletedUserAccountInfo] = useState(false);
  const [isCompletedOrganizationList, setCompletedOrganizationList] = useState(false);
  const [userUpdate] = useUserAccountInfoUpdate();
  const { authClient } = useAuth();

  const { userAccountInfo } = useUserAccountInfo({
    onCompleted: () => {
      setCompletedUserAccountInfo(true);
    },
  });

  if (userAccountInfo && !userAccountInfo.createdAt && !preventReRenders) {
    const tokenInfo = authClient.getTokenInfo() || {};
    const source = localStorage.getItem('source');
    userUpdate({
      variables: {
        data: {
          firstName: tokenInfo?.given_name || tokenInfo?.nickname,
          lastName: tokenInfo?.family_name || '',
          sourceAccount: source ? source.toUpperCase() : '8BASE',
          email: tokenInfo?.email,
        },
      },
    }, userUpdate);
    preventReRenders = true;
    localStorage.removeItem('source');
  }

  useOrganizationsList({
    onCompleted: () => {
      setCompletedOrganizationList(true);
    },
  });

  useEffect(() => {
    if (isCompletedUserAccountInfo && isCompletedOrganizationList) {
      requestAnimationFrame(window.htmlLoader.hide);
    }
  }, [isCompletedOrganizationList, isCompletedUserAccountInfo]);

  const onOpenWorkspaceClick = useOnOpenWorkspace();

  // need for refetch new undone inbox items
  const [oldUnDoneInboxCount, setOldInboxCount] = useState(0);
  const { count: undoneInboxCount, loading: undoneInboxCountLoading } = useUndoneInboxCount();

  const { refetch: refetchDoneList, loading: doneListLoading } = useInboxList({
    variables: {
      filter: { isCompleted: { equals: 1 },
      }},
  });
  const { refetch: refetchUndoneList, loading: undoneListLoading } = useInboxList({
    variables: {
      filter: { isCompleted: { equals: 0 },
      }},
  });

  useEffect(() => {
    if (undoneInboxCountLoading) {
      return;
    }

    // start background refresh
    if (undoneInboxCount !== oldUnDoneInboxCount) {
      setOldInboxCount(undoneInboxCount);
      refetchDoneList();
      refetchUndoneList();
      return;
    }
  }, [oldUnDoneInboxCount, refetchDoneList, refetchUndoneList, undoneInboxCountLoading, undoneInboxCount]);


  const developerHomeMainPlateNav = (
    <DeveloperHomeNav>
      <DeveloperHomeNavContent
        onOpenWorkspaceClick={ onOpenWorkspaceClick }
      />
    </DeveloperHomeNav>
  );

  return (
    <DeveloperHomePage { ...E2E.$props('developerHome.root') }>
      <CommonFlowBars />
      <UserFlow.HeaderWhite
        rightContent={ (
          <Row gap="lg" alignItems="center">
            <UserFlow.LearningCenter />
            <Separator />
            <UserFlow.UserDropdown />
          </Row>
        ) }
      />
      <Switch>
        <Route exact path={ APP_URL.developerHomeProjects } render={ () => (
          <DeveloperHomeMainPlate
            nav={ developerHomeMainPlateNav }
            content={ (
              <DeveloperHomeProjectsContainer
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
              />
            ) } />
        ) } />
        <Route exact path={ APP_URL.developerHomeOrganization } render={ () => (
          <DeveloperHomeMainPlate
            nav={ developerHomeMainPlateNav }
            content={ (
              <DeveloperHomeProjectsContainer
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
              />
            ) } />
        ) } />
        <Route path={ APP_URL.developerHomeInbox } render={ () => (
          <DeveloperHomeMainPlate
            nav={ developerHomeMainPlateNav }
            content={ (
              <DeveloperHomeInboxContainer loading={ doneListLoading || undoneListLoading } />
            ) } />
        ) } />
        <Redirect to={ APP_URL.developerHomeProjects } />
      </Switch>

    </DeveloperHomePage>
  );
};

export default DeveloperHome;
