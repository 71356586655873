import gql from 'graphql-tag';

export const FILE_SETTINGS_QUERY = gql`
  query FileSettings {
    __typename
    filesSetting(filemanagername: "s3") {
      id
      isEnabled
      filemanagername
    }
  }
`;
