// @flow
// $FlowFixMe
import React, { useCallback, useMemo, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Grid, Text, Checkbox } from '@8base/boost';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { ProjectCard, ProjectEnvironmentsSelectGroup } from 'common/components';
import styled from '@emotion/styled';


type ProjectCheckboxLabelProps = {
  workspaceImage?: any,
  workspaceKind: string,
  workspaceName: string,
  projectId: string,
  workspaceDescription?: string,
}

type ProjectCheckboxProps = {
  workspaceImage?: any,
  workspaceKind: string,
  workspaceName: string,
  workspaceApiHost: string,
  projectId: string,
  workspaceDescription?: string,
  formValues: any,
  form: any,
  submitting: boolean,
  expandedRows: [string],
  onOpenRow: any,
  failedValidationRows: [string],
  setFailedValidationRows: any,
  setFailedValidationRows: any
}

const StyledCheckbox = styled(Checkbox)`
  background: ${props => props.checked && !props.isAdmin ? props.theme.COLORS.GRAY_10 : 'none'};
  margin-bottom: ${props => props.isExpanded ? '0 !important' : '8px'};
  border-radius: ${props => props.isExpanded ? '5px 5px 0 0' : '5px'};
  width: 50%;
  padding: 8px;
`;

const Container = styled.div`
    background: ${props => props.checked && !props.isAdmin ? props.theme.COLORS.GRAY_10 : 'none'};
    margin-bottom: ${props => props.isExpanded ? '0 !important' : '8px'};
    border-radius: ${props => props.isExpanded ? '5px 5px 0 0' : '5px'};
    width: 100%;
    padding: 0px;
`;
const ContainerText = styled.div`
    position: absolute;
    left: -62px;
    top: 13px;
`;

const StyledAccordion = styled(Accordion)` 
  width: 100%;
  
  margin-top: 0 !important;
  
  box-shadow: none;
  '&$expanded': {
    box-shadow: none;
  }
`;

const isWorkspaceIncluded = (projectId:string, projectRoles): boolean => {

  const index = projectRoles.findIndex((el) => el.projectId === projectId);
  return index !== -1;
};

const getWorkspaceIndex = (projectId: string, workspaceRoles):number => workspaceRoles.findIndex((el) => el.projectId === projectId);

const onWorkspaceChoose = ({ projectId, projectRoles, form }): void => {
  if (isWorkspaceIncluded(projectId, projectRoles)) {
    const filteredWorkspaceRole = projectRoles.filter((el) => el.projectId !== projectId);

    form && form.change('projectRoles', filteredWorkspaceRole);
  } else {
    const projectRole = { projectId, environmentRoles: [] };
    const newProjectRoles = [projectRole, ...projectRoles];

    form && form.change('projectRoles', newProjectRoles);
  }
};

const checkIsEnvironmentsExpanded = ({ role, projectId, expandedRows }): boolean =>
  role === ORGANIZATION_ROLE.developer && expandedRows.includes(projectId);

const ProjectCheckboxLabel = ({
  workspaceImage,
  workspaceKind,
  workspaceName,
  projectId,
  workspaceDescription,
}: ProjectCheckboxLabelProps) => {


  return (
    <Grid.Layout
      columns="repeat(2, 217px) 18px"
      alignItems="center"
      gap="md"
    >
      <Grid.Box>
        <ProjectCard
          image={ workspaceImage }
          kind={ workspaceKind }
          workspaceName={ workspaceName }
          workspaceId={ projectId }
        />
      </Grid.Box>
      <Grid.Box>
        <Text ellipsis>{ workspaceDescription }</Text>
      </Grid.Box>
    </Grid.Layout>
  );
};

export const ProjectCheckbox = ({
  workspaceImage,
  workspaceKind,
  workspaceName,
  projectId,
  workspaceDescription,
  formValues,
  workspaceApiHost,
  form,
  submitting,
  expandedRows,
  onOpenRow,
  failedValidationRows,
  setFailedValidationRows,
}: ProjectCheckboxProps) => {

  const isAdmin = useMemo(() => formValues.role === ORGANIZATION_ROLE.admin, [formValues.role]);

  const isWorkspaceEnvironmentsExpanded = useMemo(() =>
    checkIsEnvironmentsExpanded({
      projectId,
      role: formValues.role,
      expandedRows,
    })
  , [expandedRows, formValues.role, projectId]);

  const [expanded, setExpanded] = useState(false);

  const onChange = useCallback(() => {
    onWorkspaceChoose({
      projectId,
      projectRoles: formValues.projectRoles,
      form,
    });

    if (!isWorkspaceIncluded(projectId, formValues.projectRoles) && !isWorkspaceEnvironmentsExpanded) {
      onOpenRow(projectId);
    }
  }, [form, formValues.projectRoles, isWorkspaceEnvironmentsExpanded, onOpenRow, projectId]);


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Container
        checked={ isAdmin || isWorkspaceIncluded(projectId, formValues.projectRoles) }
        isAdmin={ isAdmin }
        disabled={ isAdmin || submitting }
        isExpanded={ isWorkspaceEnvironmentsExpanded }
      >
        <StyledCheckbox
          onChange={ onChange }
          checked={ isAdmin || isWorkspaceIncluded(projectId, formValues.projectRoles) }
          isAdmin={ isAdmin }
          disabled={ isAdmin || submitting }
          isExpanded={ isWorkspaceEnvironmentsExpanded }
          label={ <ProjectCheckboxLabel
            formValues={ formValues }
            form={ form }
            projectId={ projectId }
            role={ formValues.role }
            workspaceImage={ workspaceImage }
            workspaceKind={ workspaceKind }
            workspaceName={ workspaceName }
            workspaceDescription={ workspaceDescription }
            isWorkspaceEnvironmentsExpanded={ isWorkspaceEnvironmentsExpanded }
            onOpenRow={ onOpenRow }
            failedValidationRows={ failedValidationRows }
          /> }
        />
        <If condition={ isWorkspaceEnvironmentsExpanded }>
          <StyledAccordion expanded={ expanded } onChange={ handleChange(true) }>
            <AccordionSummary
              expandIcon={ <ExpandMore
                style={{
                  width: 20,
                  height: 20,
                  marginTop: expanded ? '13px' : '3px',
                }}
              /> }
              style={{
                backgroundColor: expanded ? '#0000' : '#0000',
                flexDirection: 'row-reverse',
                position: 'absolute',
                top: '-50px',
                right: '0px',
                gap: 8,

              }}
              aria-controls="showInformation"
              id="showInformation"
            >
              <ContainerText>Environments</ContainerText>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: expanded ? '#FFFFFF' : '#F4F7F9', padding: '0px 54px 0px 0px' }}>
              <If condition={ isWorkspaceEnvironmentsExpanded }>
                <ProjectEnvironmentsSelectGroup
                  projectId={ projectId }
                  workspaceApiHost={ workspaceApiHost }
                  workspaceIndex={ getWorkspaceIndex(
                    projectId,
                    formValues.projectRoles,
                  ) }
                  values={ formValues }
                  form={ form }
                  submitting={ submitting }
                  failedValidationRows={ failedValidationRows }
                  setFailedValidationRows={ setFailedValidationRows }
                />
              </If>
            </AccordionDetails>
          </StyledAccordion>
        </If>
      </Container>
    </>
  );
};
