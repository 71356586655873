import gql from 'graphql-tag';

import { TeamMemberFragment } from 'graphql/fragments';


export const TEAM_MEMBER_QUERY = gql`
  query TeamMember($filter: SystemEnvironmentMemberFilter) {
    system {
      environmentMember(filter: $filter) {
        ...TeamMemberFragment
      }
    }
  }

  ${TeamMemberFragment}
`;

export const TEAM_MEMBERS_LIST_QUERY = gql`
  query TeamMembersList(
    $first: Int
    $skip: Int
    $sort: [SystemEnvironmentMembersListSort!]
    $filter: SystemEnvironmentMembersListFilter
    $environmentIds: [String] 
  ) {
    system {
      environmentMembersCount: environmentMembersList(filter: $filter) {
        count
      }
      environmentMembersList(
        first: $first
        skip: $skip
        sort: $sort
        filter: $filter
        environmentIds: $environmentIds
      ) {
        items {
          ...TeamMemberFragment
        }
      }
    }
  }

  ${TeamMemberFragment}
`;
