import { useMutation } from 'react-apollo';

import { USER_ACCOUNT_INFO_UPDATE_MUTATION } from 'graphql/mutations';
import { USER_ACCOUNT_INFO_QUERY } from 'graphql/queries';


const getUserAccountInfoData = (data) => {
  const userAccountInfo = (data && data.system && data.system.memberAccountUpsert) || {};

  return userAccountInfo;
};

export const useUserAccountInfoUpdate = (graphqlOptions = {}) => {
  return useMutation(USER_ACCOUNT_INFO_UPDATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: USER_ACCOUNT_INFO_QUERY,
    }],
    ...graphqlOptions,
    onCompleted: (data) => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(getUserAccountInfoData(data));
    },
  });
};

export const useUserAccountUpdate = (graphqlOptions = {}) => {
  return useMutation(USER_ACCOUNT_INFO_UPDATE_MUTATION, {
    ...graphqlOptions,
    onCompleted: (data) => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(getUserAccountInfoData(data));
    },
  });
};
