// @flow
import styled from '@emotion/styled';

export const ProjectHomeNav = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  min-width: 225px;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  background-color: ${props => props.theme.COLORS.GRAY_10};
`;
