// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
import { Dropdown, Icon, Menu, Column, Avatars, Row, Text } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Trans } from 'utils/translate';

import workspaceDefaultLogo from 'images/workspace-default-logo.svg';
import { ORGANIZATION_ROLE } from 'common/constants/organization';

type DeveloperHomeOrganizationIndividualHeaderProps = {
  name: string;
  type?: string | null;
  description?: string | null;
  image?: string | null;
  userList: Object[];
  onClickSettings: () => void;
  onAddUserClick: () => void;
  onUserClick: () => void;
  onRemoveFromOrganization: () => void;
  permissionRole: string;
}

const organizationImageHeight = 50;

const MoreWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.COLORS.WHITE};
`;

export const OrganizationInfo = styled(Column)`
  @media screen and (min-width: 991px) {
    flex: 1;
  }
`;

const MoreIcon = styled(Icon)`
  box-sizing: content-box;
  transition: color .3s ease;

  &:hover {
    color: ${({ theme }) => theme.COLORS.BLUE_20}
  }
`;

const OrganizationImage = styled.img`
  flex-shrink: 0;

  object-fit: cover;
  min-width: ${organizationImageHeight}px;
  min-height: ${organizationImageHeight}px;
  max-width: ${organizationImageHeight}px;
  max-height: ${organizationImageHeight}px;
  border: 0px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
  border-radius:50%;
`;

const StyledDescription = styled('div')`
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #D1D7DD;
  background-color: #FFFFFF;
  border-radius: 0 0 8px 8px;
  margin-top: 0 !important;
  padding-bottom: 2px;
  padding: 20px;

`;

const StyledColumn = styled(Column)`
  height: 100%;
  width: 100%;
  min-width: 315px;
  background: #FFFFFF;
 
  box-sizing: border-box;
  border: 1px solid #D1D7DD;
  border-radius: 8px;

  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.04);
`;

const VerticalDivider = styled('div')`
    border-left: 1px solid #CFD7DE;
    height: 32px;
`;


const StyledMyProjectHeading = styled('d')`
    color: #323C47;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
`;

const DescriptionText = styled('div')`
      color: #5B636C;
      font-family: Poppins;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
`;

const checkReadOnly = role => (
  role !== ORGANIZATION_ROLE.admin &&
  role !== ORGANIZATION_ROLE.owner &&
  role !== ORGANIZATION_ROLE.manager
);

const checkIsOwner = role => (
  role === ORGANIZATION_ROLE.owner
);

export const DeveloperHomeOrganizationIndividualHeader = ({
  name,
  type,
  description,
  userList,
  permissionRole,
  image,
  onClickSettings,
  onRemoveFromOrganization,
  onAddUserClick,
  onUserClick,
}: DeveloperHomeOrganizationIndividualHeaderProps) => {
  const readOnly = useMemo(() => {
    return checkReadOnly(permissionRole);
  }, [permissionRole]);

  const isOwner = useMemo(() => {
    return checkIsOwner(permissionRole);
  }, [permissionRole]);

  const handlerAddUserClick = useCallback(() => {
    if (checkReadOnly(permissionRole)) {
      return;
    }

    onAddUserClick();
  }, [onAddUserClick, permissionRole]);

  const handlerUserClick = useCallback(() => {
    if (checkReadOnly(permissionRole)) {
      return;
    }

    onUserClick();
  }, [onUserClick, permissionRole]);


  return (

    <StyledColumn
      alignItems="stretch"
      // { ...E2E.$props(`developerHome.workspaces.item[${workspace.name}]`) }
    >
      <Row gap="md" alignItems="center" css={ css`padding: 7px 20px 0px 20px;` } >
        <Column>
          <OrganizationImage src={ image || workspaceDefaultLogo } />
        </Column>
        <Column stretch>
          <Row flexWrap="wrap" justifyContent="between" stretch alignItems="center" >
            <OrganizationInfo gap="none">
              <StyledMyProjectHeading >
                { name }
              </StyledMyProjectHeading>

            </OrganizationInfo>
            <Row alignItems="center">
              <Avatars
                size="sm"
                users={ userList }
                onAvatarsClick={ handlerUserClick }
                onCounterClick={ handlerUserClick }
                onPlusClick={ handlerAddUserClick }
                withPlusButton={ !readOnly }
              />
              <VerticalDivider />
              <Dropdown defaultOpen={ false }>
                <Dropdown.Head stopClickPropagation >
                  <MoreWrapper>
                    <MoreIcon name="More" />
                  </MoreWrapper>
                </Dropdown.Head>
                <Dropdown.Body withPortal pin="right">
                  { ({ closeDropdown }) => (
                    <Menu>
                      <If condition={ !readOnly }>
                        <Menu.Item onClick={ () => {
                          closeDropdown();
                          onClickSettings();
                        } }>
                          <Text>
                            <Trans i18nKey="organization.settings">Settings</Trans>
                          </Text>
                        </Menu.Item>
                      </If>
                      <If condition={ !isOwner }>
                        <Menu.Item onClick={ () => {
                          closeDropdown();
                          onRemoveFromOrganization();
                        } }>
                          <Text>
                            <Trans i18nKey="organization.leave">Leave Organization</Trans>
                          </Text>
                        </Menu.Item>
                      </If>
                    </Menu>
                  ) }
                </Dropdown.Body>
              </Dropdown>
            </Row>
          </Row>
        </Column>
      </Row>
      <StyledDescription>
        <DescriptionText >
          { description }
        </DescriptionText>
      </StyledDescription>

    </StyledColumn>

  );
};
