import React from 'react';
import { css, keyframes } from '@emotion/core';
import { AsyncContent, Radio, RadioGroupField } from '@8base/boost';
import styled from '@emotion/styled';
import { Elements } from 'react-stripe-elements';

import PaymentCard from './PaymentCard';

const scaleUpRight = keyframes`
  0%{
    transform:scale(.5);
    transform-origin:right center
  }
  100%{
    transform:scale(1);
    transform-origin:right center
  }
`;

const Body = styled.div`
  animation: ${ scaleUpRight } 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;
const ContainerNamePlan = styled.div`
  color: #323C47;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  min-height: 80px;

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`;
const SelectButtonPlan = styled.div`
  background: #FFF;
  border-radius: 60px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 15px 80px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: #0874F9;
    color: #FFF;
    font-weight: 800;
  }
`;
const FeatureTitle = styled.span`
  color: #323C47;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
`;
const FeaturesList = styled.ul`
  list-style: inside;
  padding-inline-start: 10px;

  li {
    color: #323C47;
    display: list-item;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
`;
const Eyebrow = styled.div`
  color: #323C47;
  font-family: Poppins;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
const PlanPrice = styled.h2`
  color: #323C47;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const GroupContainer = styled(RadioGroupField)`
  display: block;

  div:nth-child(1) {
    align-items: center;
  }
`;
const MiniumDevs = styled.p`
  color: #7D828C;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0em;
  line-height: 16px;
  text-align: center;
`;
const FeatureContainer = styled.div`
  min-height: 200px;
`;
const AsyncContentStyled = styled(AsyncContent)`
  bottom: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
`;

const PlanCard = ({
  title,
  planSubtitle,
  planPrice,
  features,
  underTitle,
  selected,
  ...plan
}) => {
  const isSpecialCard = title.toLowerCase().includes('professional');

  const maximumDeves = plan.limitMetrics[0].softLimit;

  return (
    <ContainerCardLayout specialBox={ isSpecialCard }>
      <ContainerCard specialBox={ isSpecialCard }>
        <ContainerNamePlan>
          <h3>{ title }</h3>
          <h4>{ planSubtitle }</h4>
        </ContainerNamePlan>
        <PlanPrice>
          ${ planPrice }
          <MiniumDevs>
            { maximumDeves > 0 ? `(Maximum ${ maximumDeves } Developers)` : '' }
          </MiniumDevs>
        </PlanPrice>
        <FeatureContainer>
          <FeatureTitle>
            { plan.featuresTitle || 'Features' }
          </FeatureTitle>
          <FeaturesList>
            { features.slice(1, 6).map((feature, index) => (<li key={ index }>{ feature.displayName } </li>)) }
          </FeaturesList>
        </FeatureContainer>
        <Eyebrow>
          { plan.capacity || underTitle }
        </Eyebrow>
        <SelectButtonPlan className="btnPlan">
          { selected ? 'Change' : 'SELECT' }
        </SelectButtonPlan>
      </ContainerCard>
    </ContainerCardLayout>
  );
};

export const CreateProjectPlanSelect = (
  {
    isTouched,
    setIsTouched,
    plans,
    organizationCardLoading,
    userCardLoading,
    planDataLoading,
    plansLoading,
    input,
    onSubmit,
    currentCard,
    ...props
  }) => {
  const plantSelectByUser = plans.find((plan) => plan.id === input?.value);

  const onChange = (value) => {
    const selectedPlan = plans.find((plan) => plan.id === value);
    if (input) {
      const prev = input.value;
      input.onChange(prev === value ? '' : value);

      if (selectedPlan.price === 0) {
        onSubmit();
      }
    }
  };

  return (
    <Body>
      <AsyncContentStyled
        loading={ organizationCardLoading ||
          userCardLoading ||
          planDataLoading ||
          plansLoading }
        stretch
      >
        <div className={ Row }>
          <div style={{ display: 'flex', padding: '0 10px' }}>
            <span style={{ fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: '600',
              color: '#323C47',
              lineHeight: '16px',
            }}>
              Select the plan that best fits your needs
            </span>
          </div>
        </div>
        <Elements
          fonts={ [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700',
            },
          ] }
        >
          <Row hasSelectedPlan={ input?.value }>
            <If condition={ !plantSelectByUser }>
              <GroupContainer
                { ...props }
                input={{ ...input, onChange }}
                direction="row"
                gap="md"
                alignItems="center"
                justifyContent="center"
                className="row"
              >
                { plans.map((plan) => (
                  <Radio.Item
                    key={ plan.id }
                    value={ plan.id }
                    selectedValue={ input.value }
                    className="radio-item"
                  >
                    { ({ checked }) => (
                      <PlanCard
                        planId={ plan.id }
                        title={ plan.displayName }
                        planSubtitle={ plan.description }
                        planPrice={ plan.price }
                        features={ plan.limitMetrics }
                        underTitle="Capacity for production applications with the option to scale."
                        selected={ checked }
                        { ...plan }
                      />
                    ) }
                  </Radio.Item>
                )) }
              </GroupContainer>
            </If>
            <If condition={ plantSelectByUser }>
              <GroupContainer
                { ...props }
                input={{ ...input, onChange }}
                direction="row"
                gap="md"
                alignItems="center"
                justifyContent="center"
                className="row"
              >
                <Radio.Item
                  key={ plantSelectByUser.id }
                  value={ plantSelectByUser.id }
                  selectedValue={ input.value }
                  equalsFunc={ () => false }
                >
                  { () => (
                    <PlanCard
                      key={ plantSelectByUser.id }
                      planId={ plantSelectByUser.id }
                      title={ plantSelectByUser.displayName }
                      planSubtitle={ plantSelectByUser.description }
                      planPrice={ plantSelectByUser.price }
                      features={ plantSelectByUser.limitMetrics }
                      underTitle="Generous capacity to get started."
                      selected
                      { ...plantSelectByUser }
                    />
                  ) }
                </Radio.Item>
              </GroupContainer>
              <PaymentCard
                currentCard={ currentCard }
                isFormInvalid={ props.isFormInvalid }
                onFormSubmit={ onSubmit }
                plan={ plantSelectByUser }
              />
            </If>
          </Row>
        </Elements>
      </AsyncContentStyled>
    </Body>
  );
};

const sideLeft = keyframes`
  0%{
    transform:translateX(50px)
  }
  100%{
    transform:translateX(0)
  }
`;
const fadeIn = keyframes`
  0%{
    opacity:0
  }
  100%{
    opacity:1
  }
`;

const Row = styled('div')(
  ({ hasSelectedPlan }) => css`
    display: grid;
    gap: ${hasSelectedPlan ? '20px' : '10px'};
    max-width: 1250px;
    overflow-x: auto;
    padding: 10px;

    ${hasSelectedPlan && css`
      grid-template-columns: 296px 1fr;
    `}

    @media screen and (max-height: 768px) {
      height: 510px;
      max-width: calc(990px - 25px);
      overflow-y: scroll;
    }

    @media screen and (max-height: 685px) {
      height: 490px;
      overflow-y: scroll;
    }

    label {
      position: relative;
      &::before {
        content: "";
        background: linear-gradient(341.48deg, rgba(205, 73, 86, 0) 37.19%, #4C70FF 99.05%);
        border-radius: 8px;
        inset: 0;
        mask-composite: exclude;
        padding: 4px;
        position: absolute;
        -webkit-mask-composite: xor;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      }
    }

    ${ hasSelectedPlan ? css`
      animation: ${ sideLeft } 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    ` : css` animation: ${ fadeIn } 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;`};
  `,
);
const ContainerCardLayout = styled.div`
  background-color: #F4F8FA;
  border-radius: 8px;
  min-height: 600px;

  &:hover {
    background-color: #EDF5FF;

    div.btnPlan {
      background-color: #0874F9;
      color: #FFF;
      font-weight: 800;
    }
  }
`;
const ContainerCard = styled('div')(
  ({ specialBox }) => css`
    align-items: center;
    background-color: #F4F8FA;
    border-radius: 8px;
    display: grid;
    flex-direction: column;
    flex: 1 0 0;
    height: 596px;
    padding-top: 0;
    padding: 30px 16px 16px 16px;
    position: relative;
    width: 296px;
    gap: 15px;

    &:hover {
      background-color: ${specialBox ? 'initial' : '#EDF5FF'};

      > div.btnPlan {
        background-color: #0874F9;
        color: #FFF;
        font-weight: 800;
      }
    }

    ${ specialBox && css`
      background-color: initial;
    `}
  `,
);


