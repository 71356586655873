//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { injectStripe } from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';
import { css } from '@emotion/core';
import { Form as FormLogic } from '@8base-react/forms';
import { FORM_ERROR } from 'final-form';
import { Dialog, Button, Form, Link, Column, Text, Icon, useModal } from '@8base/boost';
import { toast } from 'react-toastify';

import { i18n } from 'i18n';
import * as LINKS from 'config/links';

import { StripeCardFormFields } from './StripeCardFormFields';
import { PaymentSubmitting } from './PaymentSubmitting';
import { PaymentSubmitSuccess } from './PaymentSubmitSuccess';
import { PaymentSubmitFailed } from './PaymentSubmitFailed';

type BaseBillingUpdateDialogProps = {
  stripe: Object;
  dialogId: string;
  beforeFieldsSlot?: React$Node;
  submittingSlot?: React$Node;
  submitSuccessSlot?: React$Node;
  submitFailedSlot?: React$Node;
  hideCardFields?: boolean;
  title?: string;
  onSubmitSuccess: (cardToken?: *, onSuccess?: *) => Promise<void>;
  onSubmitFail?: (error: *) => void;
};

let BaseBillingUpdateDialog = ({
  stripe,
  dialogId,
  hideCardFields = false,
  title = i18n.t('billingUpdateDialog.updateTitle'),
  beforeFieldsSlot,
  submittingSlot,
  submitSuccessSlot,
  submitFailedSlot,
  onSubmitSuccess,
  onSubmitFail,
}: BaseBillingUpdateDialogProps) => {
  const { closeModal, args } = useModal(dialogId);
  const [stripeError, setStripeError] = useState(null);

  const onSubmit = useCallback(async (data) => {
    if (hideCardFields) {
      await onSubmitSuccess();
      return;
    }

    let response = null;

    window.trackEvent('Billing', 'Update Payment Details');

    try {
      response = await stripe.createToken({
        type: 'card',
        name: data.cardHolder,
        address_line1: data.street1,
        address_line2: data.street2,
        address_city: data.city,
        address_state: data.state,
        address_zip: data.zip,
        address_country: data.address_country,
      });
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('type', 'Billing Error');

        Sentry.captureException(error);
      });

      setStripeError(error);
    }

    if (R.has('error', response)) {
      const errorMessage = R.path(['error', 'message'], response);

      toast.error(errorMessage);
      setStripeError(R.prop('error', response));
      onSubmitFail && onSubmitFail(errorMessage);

      return { [FORM_ERROR]: errorMessage };
    } else {
      setStripeError(null);
      const token = R.path(['token'], response);
      const onSuccess = R.prop('onSuccess', args);
      if (typeof onSuccess === 'function') {
        await onSubmitSuccess(token, onSuccess);
      } else {
        await onSubmitSuccess(token);
      }

    }
  }, [args, hideCardFields, onSubmitFail, onSubmitSuccess, stripe]);

  const onClose = useCallback(() => {
    closeModal(dialogId);
  }, [closeModal, dialogId]);

  return (
    <Dialog
      id={ dialogId }
      onClose={ onClose }
      size="lg"
      shouldCloseOnOverlayClick={ false }
      shouldCloseOnEscPress={ false }
      { ...E2E.$props('dialogs.billingUpdate.body') }
    >
      <FormLogic onSubmit={ onSubmit }>
        { ({ handleSubmit, invalid, submitting, submitSucceeded, submitFailed, dirty }) => (
          <form onSubmit={ handleSubmit } css={{ overflow: 'auto' }}>
            <Dialog.Header
              title={ title }
              onClose={ onClose }
            />
            <Dialog.Body scrollable>
              <Choose>
                <When condition={ submitting }>
                  <Column css={{ height: 250, textAlign: 'center' }} alignItems="center" justifyContent="center">
                    <Icon name="PaymentLoading" css={{ width: 64, height: 64 }} color="GRAY_20" />
                    <Choose>
                      <When condition={ !!submittingSlot }>
                        { submittingSlot }
                      </When>
                      <Otherwise>
                        <PaymentSubmitting type="simple" />
                      </Otherwise>
                    </Choose>
                  </Column>
                </When>

                <When condition={ submitSucceeded && !stripeError }>
                  <Column css={{ height: 250, textAlign: 'center' }} alignItems="center" justifyContent="center">
                    <Icon name="PaymentSuccess" css={{ width: 64, height: 64 }} color="GREEN" />
                    <Choose>
                      <When condition={ !!submitSuccessSlot }>
                        { submitSuccessSlot }
                      </When>
                      <Otherwise>
                        <PaymentSubmitSuccess type="simple" />
                      </Otherwise>
                    </Choose>
                  </Column>
                </When>

                <When condition={ submitFailed && !invalid && !dirty }>
                  <Column css={{ height: 250, textAlign: 'center' }} alignItems="center" justifyContent="center">
                    <Icon name="PaymentError" css={{ width: 64, height: 64 }} color="RED" />
                    <Choose>
                      <When condition={ !!submitFailedSlot }>
                        { submitFailedSlot }
                      </When>
                      <Otherwise>
                        <PaymentSubmitFailed type="simple" />
                      </Otherwise>
                    </Choose>
                  </Column>
                </When>
              </Choose>

              <Form component="div" css={ ((!invalid && !dirty && submitFailed) || submitting || (submitSucceeded && !stripeError)) ? { position: 'absolute', opacity: 0, zIndex: -1 } : {} }>
                { beforeFieldsSlot }
                <If condition={ !hideCardFields }>
                  <StripeCardFormFields />
                </If>
                <Text color="LIGHT_TEXT_COLOR" css={ css`text-align: center; font-size: 1.2rem;` }>
                  By clicking “Submit Changes” you agree to our <Link href={ LINKS.USER_AGREEMENT_URL } target="_blank" rel="noopener noreferrer">Terms of Service</Link>.
                </Text>
              </Form>
            </Dialog.Body>
            <Dialog.Footer>
              <Choose>
                <When condition={ ((!invalid && !dirty && submitFailed) || submitting || (submitSucceeded && !stripeError)) }>
                  <Button
                    type="button"
                    { ...E2E.$props('dialogs.billingUpdate.closeButton') }
                    onClick={ onClose }
                    disabled={ submitting }
                    loading={ submitting }
                    stretch
                  >
                    { i18n.t('shared.close') }
                  </Button>
                </When>
                <Otherwise>
                  <Button
                    type="button"
                    color="neutral"
                    variant="outlined"
                    onClick={ onClose }
                    { ...E2E.$props('dialogs.billingUpdate.cancelButton') }
                  >
                    { i18n.t('shared.cancel') }
                  </Button>
                  <Button
                    type="submit"
                    disabled={ submitting }
                    loading={ submitting }
                    { ...E2E.$props('dialogs.billingUpdate.submitButton') }
                  >
                    { i18n.t('shared.submitChanges') }
                  </Button>
                </Otherwise>
              </Choose>
            </Dialog.Footer>
          </form>
        ) }
      </FormLogic>
    </Dialog>
  );
};

BaseBillingUpdateDialog = R.compose(injectStripe)(BaseBillingUpdateDialog);


export { BaseBillingUpdateDialog };
