// @flow
import fp from 'lodash/fp';
import { i18n } from 'i18n';

export type Validator = (value: any) => ?string;

export const composeValidators = (...validators: Validator[]) => (
  value: any,
) =>
  validators.reduce((error, validator) => error || validator(value), undefined);


export const json: Validator = value => {
  if (typeof value === 'string' && value.length > 0) {
    try {
      JSON.parse(value);

      return null;
    } catch (e) {
      return e.message;
    }
  }

  return null;
};

export const required: Validator =
  value => ((fp.isNil(value) || value === '') ? i18n.t('validation.required') : null);

export const requiredTrue: Validator =
  value => ((fp.isNil(value) || Boolean(value) === false) ? i18n.t('validation.required') : null);

export const requiredWithSpaces: Validator =
  value => ((fp.isNil(value) || value === '' || (value && value.trim() === '')) ? i18n.t('validation.required') : null);

export const requiredArray: Validator =
  value => ((fp.isNil(value) || fp.isEmpty(value)) ? i18n.t('validation.required') : null);

/** for the cases where null is valid value */
export const requiredWithoutNull: Validator =
  value => ((fp.isUndefined(value) || value === '') ? i18n.t('validation.required') : null);

export const requiredMinValue: Validator =
  (value, min) => ((!value || value.length < min) ? i18n.t('validation.minLength', { count: min }) : null);

export const maxLength = fp.memoize(
  (max: number): Validator => value => value && value.length > max
    ? i18n.t('validation.maxLength', { count: max })
    : null,
);

export const minLength = fp.memoize(
  (min: number): Validator => (value) => value && value.length < min
    ? i18n.t('validation.minLength', { count: min })
    : null,
);

const getDecimalRegexp = (maxDecimals: number) =>
  parseInt(maxDecimals, 10) === 0
    ? new RegExp('^[0-9]+$')
    : new RegExp(`^[0-9]+([.][0-9]{0,${maxDecimals}})?$`);

export const maxDecimals = fp.memoize(
  (maxDecimals: number) => {
    const maxDecimalRegexp = getDecimalRegexp(maxDecimals);

    return (value: string) => value && !maxDecimalRegexp.test(value)
      ? i18n.t('validation.maxDecimals', { count: maxDecimals })
      : null;
  },
);

export const number: Validator =
  value => value && isNaN(Number(value))
    ? i18n.t('validation.number')
    : null;

export const minValue = fp.memoize(
  (min: number): Validator => value => !fp.isNil(value) && !fp.isNil(min) && value < min
    ? i18n.t('validation.minValue', { min })
    : null,
);

export const maxValue = fp.memoize(
  (max: number): Validator => value => !fp.isNil(value) && !fp.isNil(max) && value > max
    ? i18n.t('validation.maxValue', { max })
    : null,
);

export const names: Validator =
  value => (value && !/^[a-z ,.'-]+$/i.test(value))
    ? i18n.t('validation.unallowedValue')
    : null;

export const address: Validator =
  value => (value && !/^[a-zA-Z0-9\s,./-]+$/i.test(value))
    ? i18n.t('validation.unallowedValue')
    : null;

export const notSpace: Validator =
  value => (value && /^[\s]+$/i.test(value))
    ? i18n.t('validation.unallowedValue')
    : null;

export const withoutSpaces: Validator =
  value => (value && /^.*\s.*$/i.test(value))
    ? i18n.t('validation.withoutSpaces')
    : null;

export const word: Validator =
  value => (value && !/[\w]/i.test(value))
    ? i18n.t('validation.unallowedValue')
    : null;

export const email: Validator =
  value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value))
    ? i18n.t('validation.email')
    : null;

export const emailsArray: Validator =
  value => value && value.map(email);

// eslint-disable-next-line max-len
const URL_REGEX = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

export const url: Validator = value => (value && !URL_REGEX.test(value) ? i18n.t('validation.url', { defaultValue: 'Invalid url' }) : null);

export const phoneNumber: Validator =
  value => (value && !/^(0|[1-9][0-9]{9})$/i.test(value))
    ? i18n.t('validation.phoneNumber')
    : null;

export const ssn: Validator =
  value => value && !/^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/.test(value)
    ? i18n.t('validation.ssn')
    : null;

export const ein: Validator =
  value => value && !/^\d{2}[- ]{0,1}\d{7}$/.test(value)
    ? i18n.t('validation.ein')
    : null;

export const password: Validator =
  value => value && value.length < 6
    ? i18n.t('validation.password')
    : null;

export const strengthPassword: Validator = value =>
  /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)
    ? undefined
    : i18n.t('validation.strengthPassword');

export const confirmEnter =
  (values: any, name: string, confirmValue: any, errorMessage?: string) => {
    const errors = {};
    if (!values[name] || fp.isEmpty(values[name]) || !fp.isEqual(values[name], confirmValue)) {
      errors[name] = errorMessage || i18n.t('validation.valueNoMatch');
    }
    return errors;
  };

export const confirmValue =
  (value: any, confirmValue: any) =>
    value && value !== confirmValue
      ? i18n.t('validation.valueNoMatch')
      : null;

export const createPasswordConfirmation =
  (passwordName: string, passwordConfirmationName: string) => (value: string, allValues: Object) => {
    if (allValues[passwordName] !== allValues[passwordConfirmationName]) {
      return i18n.t('validation.passwordConfirmation');
    }
  };

export const hasNoFirstDigit: Validator = (value: any) => value && /^\d.*$/.test(value)
  ? i18n.t('validation.hasNoFirstDigit')
  : null;
