// @flow
import React from 'react';
import { Tooltip, Text } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { USER_STATUSES } from 'common/constants/organization';
import { i18n } from 'i18n';
import { FolderOpen } from '@material-ui/icons';

type SquareAvatarsProps = {
  data: Array<{ srcUrl: string, name: string }>;
  onClick?: () => void;
  status: string;
}

const Wrapper = styled('div')`
  display: flex;
`;

const imgBaseCss = css`
  border-radius: 40px;
  background: #FFFFFF;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  object-fit: cover;
`;

const Img = styled('img')`
  ${imgBaseCss}
`;

const CounterTag = styled('div')`
  ${imgBaseCss}

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.COLORS.GRAY_40};
  color: ${props => props.theme.COLORS.WHITE};
  white-space: nowrap;
`;


const offsetCss = css`
  &:not(:first-of-type) {
    margin-left: 4px;
  }
`;

const StyledMyProjectsIcon = styled(FolderOpen)`
  flex-shrink: 0;
  object-fit: cover;
  width: 26px;
  height: 26px;
  border-radius: 28px;
  border: 1px solid #E8EFF5;
  background-color: #ABB1B8;
  color: white;
  font-size: 20px !important;
  padding: 2px;
`;

const TooltipStyled = styled('div')`
  overflow: auto;
  padding-bottom: 60px;
  margin-bottom: -60px;
`;

export const SquareAvatars = ({ data, status, onClick }: SquareAvatarsProps) => {
  if (status === USER_STATUSES.invitationPending) return null;
  return (
    <Wrapper onClick={ onClick }>
      <Choose>
        <When condition={ !!data.length }>
          <TooltipStyled>
            { data.slice(0, 3).map(({ srcUrl, name }, index) => (
              <Tooltip key={ index } css={ offsetCss } message={ name }>
                { !srcUrl.includes('default-logo') && <Img src={ srcUrl } /> }
                <If condition={ srcUrl.includes('default-logo') }>
                  <StyledMyProjectsIcon />
                </If>
              </Tooltip>
            )) }
          </TooltipStyled>
          <If condition={ data.length > 3 }>
            <CounterTag css={{ marginLeft: '4px', fontSize: '10px', paddingTop: '3px' }}>+ { data.length - 3 }
            </CounterTag>
          </If>
        </When>
        <Otherwise>
          <Text color="GRAY_40">
            { i18n.t('shared.noProjects', { defaultValue: 'No Projects' }) }.&nbsp;
          </Text>
        </Otherwise>
      </Choose>
    </Wrapper>
  );
};
