import { useEffect, useState, useCallback } from 'react';
import { useLazyQuery } from 'react-apollo';
import * as R from 'ramda';
import { GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY } from 'graphql/queries';
import { WORKSPACE_ID, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { useAuth } from '@8base-react/auth';
import { environmentAccessor } from 'utils';
import { MASTER_ENVIRONMENT } from 'utils/environmentAccessor';

export const useFetchEnvironments = (backendWorkspaceId, apiHost) => {
  const { authState } = useAuth();
  const [environmentIds, setEnvironmentIds] = useState([]);
  const [currentEnv, setCurrentEnv] = useState(environmentAccessor.getEnvironment(backendWorkspaceId) || MASTER_ENVIRONMENT);

  const [fetchEnvironments, { data: EnvironmentList, loading: environmentsLoading }] = useLazyQuery(GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, {
    variables: {
      email: authState.email,
      projectId: '',
      workspaceId: backendWorkspaceId,
    },
    context: {
      [WORKSPACE_ID]: backendWorkspaceId,
      [ENDPOINT_URI]: apiHost,
    },
    fetchPolicy: 'network-only',
  });

  const onChangeEnvironment = useCallback((env) => {
    if (backendWorkspaceId) {
      environmentAccessor.setEnvironment(backendWorkspaceId, env);
      setCurrentEnv(env);
    }

    window.location.reload();
  }, [backendWorkspaceId]);

  useEffect(() => {
    if (backendWorkspaceId) {
      setTimeout(() => { fetchEnvironments({ context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost }}); }, 100);
    }
  }, [apiHost, backendWorkspaceId, fetchEnvironments]);

  useEffect(() => {
    if (currentEnv && EnvironmentList) {
      const environmentRoles = getRolesIds(getEnvironmentResponse(EnvironmentList));
      setEnvironmentIds(environmentRoles);
    }
  }, [EnvironmentList, currentEnv]);

  const getEnvironmentResponse = (EnvironmentList) => R.pathOr([], ['system', 'getEnvironmentRoles'], EnvironmentList).filter(({ exists }) => Boolean(exists));

  const getRolesIds = (data) => {
    return data ? data.map(item => (item.environmentId)) : [];
  };


  return { environmentIds, currentEnv, onChangeEnvironment, environmentsLoading };
};
