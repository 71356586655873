// @flow
import styled from '@emotion/styled';

export const DeveloperHomeNav = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  min-width: 320px;
  height: 100%;
  background-color: ${props => props.theme.COLORS.GRAY_10};
`;
