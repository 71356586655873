// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { Card, Button, CardContent, CardActions } from '@material-ui/core';
import {
  Avatar,
  Column,
  Grid,
  Text,
  Row,
  Label,
} from '@8base/boost';
// $FlowFixMe
import { ReactComponent as BackendIcon } from 'images/icons/icon-data-cloud.svg';
import { BackendTable } from './components/BackendTable';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { APP_URL, useBuildUrl } from 'common/routing';

const BackendAvatar = styled('div')`
width: 56px;
height: 56px;
border-radius: 40px;
background-color: #ABB1B8;
`;

const StyledTitle = styled('div')`
  height: 22px;
  color: #323c47;
  font-family: Poppins,serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 35px;
  margin-bottom: 25px;
`;

const StyledProjectTitle = styled('div')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 35px;
`;

type ProjectBackendProps = {
  projectInfo: Object | null
}

export const ProjectBackend = ({ projectInfo }: ProjectBackendProps) => {
  const buildUrl = useBuildUrl();

  const menuLocationChanged = (location:string) => {
    window.open(location, '_blank');
  };

  const { user: owner = {}} = (projectInfo?.projectUsers?.items || [])
    .find(item => (item.role.items || [])
      .find(role => role.name === ORGANIZATION_ROLE.owner)) || {};
  const projectOwnerName = `${owner?.firstName || ''} ${owner?.lastName || ''}`;

  return (
    <React.Fragment css={{ flexGrow: '1' }}>
      <Card variant="outlined" css={{ width: '100%' }}>
        <CardContent style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '25px 15px 15px 34px',
        }}>
          <Row>
            <BackendAvatar>
              <BackendIcon fontSize="27px" css={{ color: 'white', margin: '15px', scale: '130%' }} />
            </BackendAvatar>

            <Column css={{ paddingLeft: '20px' }}>
              <StyledProjectTitle>
                { projectInfo && projectInfo?.name }
              </StyledProjectTitle>
              <Row>
                <Avatar
                  size="xs"
                  src={ owner?.avatar?.downloadUrl }
                  firstName={ owner?.firstName }
                  lastName={ owner?.lastName }
                />
                <Label text={ projectOwnerName } />
                <Text kind="small-1">Created at { DateTime.fromISO(projectInfo && projectInfo?.createdAt).toFormat('LLL d, yyyy') }</Text>
              </Row>
            </Column>
          </Row>

          <div style={{ display: 'grid', alignItems: 'center' }}>
            <Button
              onClick={ (event) => menuLocationChanged(buildUrl(APP_URL.workspaceHome,
                { pathParams: { workspaceId: projectInfo?.backendWorkspaces?.items[0].id }})) }
              variant="contained"
              color="primary"
            >
              Open Backend
            </Button>
          </div>
        </CardContent>

        <CardActions style={{ paddingLeft: 34, backgroundColor: '#F4F7F9' }} >
          <Grid.Layout inline gap="custom" customGap="50px" columns="auto auto auto auto">
            <Grid.Box>
              <Row >
                <Text color="SECONDARY_TEXT_COLOR"> Cloud Provider: </Text>
                <Text> AWS </Text>
              </Row>
            </Grid.Box>

            <Grid.Box>
              <Row>
                <Text color="SECONDARY_TEXT_COLOR"> Region: </Text>
                <Text> { projectInfo && projectInfo?.region } </Text>
              </Row>
            </Grid.Box>

            <Grid.Box>
              <Row>
                <Text color="SECONDARY_TEXT_COLOR"> Database Type: </Text>
                <Text> AWS </Text>
              </Row>
            </Grid.Box>

            <Grid.Box>
              <Row>
                <Text color="SECONDARY_TEXT_COLOR"> Object Storage Method: </Text>
                <Text> Filestack </Text>
              </Row>
            </Grid.Box>

          </Grid.Layout>
        </CardActions>
      </Card>

      <StyledTitle>
        Environments
      </StyledTitle>
      { projectInfo && <BackendTable item={ projectInfo?.environmentsProject.items } projectId={ projectInfo?.id } /> }

    </React.Fragment>
  );
};
