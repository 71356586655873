import { Avatar, Avatars, Column, Heading, Link, Row, Text, AsyncContent } from '@8base/boost';
import { css } from '@emotion/core';
import * as R from 'ramda';
import React from 'react';
import { Trans } from 'utils/translate';

import styled from '@emotion/styled';
import { CenteredCardBody, StyledCard, StyledCardHeader, StyledCardTitleText } from './StyledComponents';


type TeamCardProps = {};


const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
   color:  ${props => props.theme.COLORS.GRAY_50}
`;


const getUsersForAvatars = R.map((user) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  avatarUrl: R.pathOr(null, ['avatar', 'downloadUrl'], user),
}));

const TeamCard = ({ title, icon, link, projectUsers, loading, projectInfo, ...rest }: TeamCardProps) => {
  const owner = R.pathOr(null, ['owner'], projectInfo);
  const teamMembers = (projectUsers?.items || []).filter((user) => user.status === 'active') || [];
  const teamMembersCount = teamMembers.length || 0;
  const users = getUsersForAvatars(teamMembers);

  return (
    <StyledCard { ...rest }>
      <StyledCardHeader style={{ marginBottom: 14 }}>
        <Row alignItems="center">
          { icon }
          <StyledCardTitleText>
            { title }
          </StyledCardTitleText>
        </Row>
        { link }
      </StyledCardHeader>
      <CenteredCardBody>
        <AsyncContent loading={ loading }>
          <Choose>
            <When condition={ projectUsers }>
              <Column padding="none" stretch>
                <Text color="BLACK"><Trans i18nKey="projects.dashboard.owner">No data</Trans></Text>
                <Column gap="md" stretch>
                  <Row alignItems="center" gap="sm">
                    <Avatar
                      size="stretch"
                      css={ css`width: 40px; max-width: 40px; height: 40px; flex: 1 0 40px;` }
                      firstName={ owner?.firstName || '' }
                      lastName={ owner?.lastName || '' }
                      src={ owner?.avatar && owner?.avatar.downloadUrl }
                    />
                    <Column gap="none" css={ css`word-break: break-all;` }>
                      <Heading type="h2">{ owner?.firstName } { owner?.lastName }</Heading>
                      <Link kind="overline-1">{ owner?.email }</Link>
                    </Column>
                  </Row>

                  <DividerNav />
                  <Column gap="none">
                    <Text css={ css`line-height:20px;font-size:13px; height: 19px` } color="BLACK">
                      <Trans i18nKey="projects.dashboard.activeTeamMembers">
                        No data
                      </Trans>
                    </Text>
                    <Text css={ css`line-height:42px;font-size:28px; height: 39px` } color="BLACK" kind="overline-1">
                      { teamMembersCount }
                    </Text>
                  </Column>
                  <Avatars
                    size="sm"
                    users={ users }
                  />
                </Column>
              </Column>
            </When>
            <Otherwise>
              <StyledCardContentText>
                <Trans i18nKey="projects.dashboard.noData">No data</Trans>
              </StyledCardContentText>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </CenteredCardBody>
    </StyledCard>
  );
};

export { TeamCard };
