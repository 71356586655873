// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
import { Dialog, useModal, Grid, Text, Button } from '@8base/boost';
import { i18n } from 'i18n';
import { WorkspaceCard } from 'common/components';
import { useOrganization } from 'graphql/hooks';
import { getOrganizationUserProjects } from 'utils/organization';
import { OrganizationTeamMemberEditAccessDialog } from 'dialogs/OrganizationTeamMemberEditAccessDialog';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as R from 'ramda';

const WorkspacesListItem = styled(Grid.Layout)`
  cursor: pointer;
  border-radius: 5px;
  transition: background .3s ease-in-out;

  &:hover {
    background: ${props => props.theme.COLORS.GRAY_10};
  }
`;

const ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID = 'ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID';

const OrganizationAddAccessToWorkspacesDialog = () => {
  const { openModal, closeModal, args } = useModal(ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID);
  const { userEmail, profileEmail, profileRole, organizationId, updateTeamMemberWorkspaceEnvironments } = args;

  const { organization } = useOrganization(organizationId);

  const organizationUsersList = R.pathOr([], ['users'], organization);

  const userProjects = useMemo(() =>
    getOrganizationUserProjects(userEmail, organizationUsersList)
  , [organizationUsersList, userEmail]);

  const profileData = useMemo(() =>
    organizationUsersList.find((el) => el.email === profileEmail) || { firstName: '', lastName: '', email: '', workspaces: [], profileRole: '' }
  , [profileEmail, organizationUsersList]);

  const { projects: profileProjects } = profileData;

  const unavailableWorkspaces = useMemo(() => {

    const availableWorkspacesIdList = profileProjects ? profileProjects.map(el => el.id) : [];

    const list = userProjects && userProjects.filter(el => !availableWorkspacesIdList.includes(el.id));

    return list;
  }, [profileProjects, userProjects]);

  const onClose = useCallback(() => {
    closeModal(ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID);
  }, [closeModal]);

  const handleSubmit = useCallback(() => {
    if (unavailableWorkspaces.length === 1) {
      closeModal(ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID);
    }
  }, [closeModal, unavailableWorkspaces.length]);


  return (
    <Dialog id={ ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID } size="xl">
      <Dialog.Header
        title={ i18n.t('dialogs.organizationAddAccessToWorkspaces.title') }
        onClose={ onClose }
      />
      <Dialog.Body>
        <If condition={ unavailableWorkspaces && unavailableWorkspaces.length !== 0 }>
          { unavailableWorkspaces.map(({ name, kind, image, id, description }) => (
            <WorkspacesListItem
              key={ id }
              stretch
              alignItems="center"
              columns="200px 1fr 100px"
              gap="sm"
              padding="sm"
              onClick={ () => {
                openModal(
                  OrganizationTeamMemberEditAccessDialog.ID,
                  {
                    projectId: id,
                    email: profileEmail,
                    workspaceName: name,
                    kind,
                    image,
                    role: profileRole,
                    updateTeamMemberWorkspaceEnvironments,
                    organizationId,
                    handleSubmit,
                  });
              } }
            >
              <Grid.Box>
                <WorkspaceCard
                  image={ image }
                  kind={ kind }
                  workspaceName={ name }
                  //onClose={ () => closeModal(ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID) }
                  workspaceId={ id }
                />
              </Grid.Box>
              <Grid.Box css={{ overflow: 'hidden' }}>
                <Text ellipsis>{ description }</Text>
              </Grid.Box>
              <Grid.Box>
                <Button
                  color="primary"
                  variant="link"
                  css={ css`font-weight: normal;` }
                >
                    Environments
                </Button>
              </Grid.Box>
            </WorkspacesListItem>
          )) }
        </If>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          type="button"
          color="neutral"
          variant="outlined"
          onClick={ onClose }
        >
          { i18n.t('shared.close') }
        </Button>
      </Dialog.Footer>
    </Dialog>);
};

OrganizationAddAccessToWorkspacesDialog.ID = ORGANIZATION_ADD_ACCESS_TO_WORKSPACES_DIALOG_ID;

export { OrganizationAddAccessToWorkspacesDialog };
