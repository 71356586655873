// @flow

import { ENVIRONMENT } from '../common/constants/localStorageKeys';

export const MASTER_ENVIRONMENT = 'Master';

export const getEnvironments = () => {
  let environments = {};

  try {
    const environmentJSON = localStorage.getItem(ENVIRONMENT) || '';

    environments = JSON.parse(environmentJSON) || {};
  }
  catch {
    environments = {};

    localStorage.setItem(ENVIRONMENT, JSON.stringify(environments));
  }

  return environments;
};

const setEnvironment = (workspacesId: string, environment: string) => {
  if (!workspacesId || !environment) {
    return;
  }

  const environments = getEnvironments();

  localStorage.setItem(ENVIRONMENT, JSON.stringify({
    ...environments,
    [workspacesId]: environment,
  }));
};

const getEnvironment = (workspacesId?: string) => {
  if (!workspacesId) {
    return null;
  }

  const environments = getEnvironments();

  return environments[workspacesId] || null;
};

const removeEnvironment = (workspacesId?: string) => {
  if (!workspacesId) {
    return;
  }

  const environments = getEnvironments();

  localStorage.setItem(ENVIRONMENT, JSON.stringify({
    ...environments,
    [workspacesId]: undefined,
  }));
};

const isMasterEnvironment = (workspacesId?: string) => {
  if (!workspacesId) {
    return;
  }

  const environment = getEnvironment(workspacesId);

  return !environment || environment === MASTER_ENVIRONMENT;
};

export const environmentAccessor = {
  getEnvironment,
  setEnvironment,
  removeEnvironment,
  isMasterEnvironment,
};
