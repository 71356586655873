// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { pathOr } from 'ramda';

import { TABLE_FIELDS_FOR_INDEXING_QUERY } from 'graphql/queries';
import {
  HIDE_TOAST_ERROR_MESSAGE,
} from 'common/constants/apolloOperationContextOptions';
import type {
  TableFieldsForIndexingQuery,
  TableFieldsForIndexingQueryVariables,
} from '../__generated__';

export const useFieldsForIndexing = (tableId: string, options?: QueryHookOptions = {}) => {
  const {
    data,
    ...rest
  }: QueryResult<TableFieldsForIndexingQuery, TableFieldsForIndexingQueryVariables> = useQuery(TABLE_FIELDS_FOR_INDEXING_QUERY, {
    fetchPolicy: 'cache-first',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
    },
    ...options,
    variables: {
      ...options,
      id: tableId,
    },
  });

  return {
    fieldsForIndexing: pathOr([], ['system', 'table', 'fieldsForIndexing'], data),
    ...rest,
  };
};
