import gql from 'graphql-tag';

export const PROJECT_INVITATION_ACCEPT_MUTATION = gql`
  mutation ProjectInvitationAccept($invitationId: String!) {
    system {
      projectInviteUserAccept(invitationId: $invitationId) {
        success
      }
    }
  }
`;

export const PROJECT_INVITATION_CANCEL_MUTATION = gql`
  mutation ProjectInvitationCancel($invitationId: String!) {
    system {
      projectInviteUserCancel(invitationId: $invitationId) {
        success
      }
    }
  }
`;

export const PROJECT_USER_REMOVE_MUTATION = gql`
  mutation ProjectUserRemove($projectId: ID!, $email: String!) {
    system {
      projectUserRemove(projectId: $projectId, email: $email) {
        success
      }
    }
  }
`;

export const PROJECT_LEAVE_USER = gql`
  mutation ProjectUserLeft ($projectId: ID!){
    system {
      projectLeave(projectId: $projectId) {
        success
      }
    }
  }
`;


//TODO call invitation
export const PROJECT_INVITE_TEAM_MEMBER = gql`
  mutation ProjectInviteTeamMember(
    $projectId: ID!
    $invitationId: ID
    $email: String!
    $roles: [String!]!
    $firstName: String
    $lastName: String
    $workspaceRoles: [SystemProjectWorkspaceWithEnvironmentRolesInput]
    $force: Boolean
  ) {
    system {
      projectInviteUser(
        projectId: $projectId
        invitationId: $invitationId
        email: $email
        role: $roles
        firstName: $firstName
        lastName: $lastName
        workspaceRoles: $workspaceRoles
        force: $force
      ) {
        invitationId
      }
    }
  }
`;
