import React from 'react';
import * as R from 'ramda';
import { Icon, Form, Select } from '@8base/boost';
import styled from '@emotion/styled';
import { FieldSchema, tableFieldSelectors, FIELD_TYPE } from '@8base/utils';
import { useDrag, useDrop } from 'react-dnd';
import { ParametricSelector } from 'reselect';

import { isInvalid } from 'utils/forms/isInvalid';
import { useFieldsForIndexing } from '../graphql/hooks';

const getIndexFieldByName: ParametricSelector<FieldSchema[], string, FieldSchema | void> = (
  fields: FieldSchema[],
  fieldName: string,
) => R.find(R.propEq('name', fieldName), fields);

const IndexColumnsFieldItemTag = styled.div`
  display: flex;
  border: 1px solid #D0D7DD;
  height: 36px;
  border-radius: 5px;
  align-items: center;
  overflow: hidden;
  margin: 4px;
`;

const IndexColumnsFieldItem = ({
  item,
  fieldsForIndexing,
  index,
  onDeleteField,
  draggedIndex,
  setDraggedIndex,
  setHoveredIndex,
  onDragBegin,
  onDragEnd,
}) => {
  const field = getIndexFieldByName(fieldsForIndexing, item.name);

  const [, dragRef] = useDrag({
    item: { id: index, type: 'NODE' },
    begin: () => {
      return onDragBegin(index);
    },
    end: () => {
      onDragEnd();
    },
  });

  const [, dropRef] = useDrop({
    accept: 'NODE',
    hover: (item, monitor) => {
      if (!monitor.isOver({ shallow: true }) || !monitor.canDrop()) {
        return;
      }

      setHoveredIndex(index);
    },
  });

  const onDelete = React.useCallback(() => {
    onDeleteField(index);
  }, [onDeleteField, index]);

  return (
    <IndexColumnsFieldItemTag ref={ (node) => dropRef(dragRef(node)) }>
      <Icon name="DragHandle" color="SECONDARY_TEXT_COLOR" cursor="pointer" />
      { field && field.displayName }
      <Icon name="Delete" color="SECONDARY_TEXT_COLOR" cursor="pointer" size="sm" onClick={ onDelete } css={{ margin: '0 4px' }} />
    </IndexColumnsFieldItemTag>
  );
};

const IndexColumnsFieldPlusTag = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IndexColumnsFieldPlus = () => {
  return (
    <IndexColumnsFieldPlusTag>
      +
    </IndexColumnsFieldPlusTag>
  );
};

const IndexColumnsFieldTag = styled.div`
  border: 1px dashed ${({ invalid }) => invalid ? '#FE4B3D' : '#D0D7DD'};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const IndexColumnsFieldCounterTag = styled.div`
  position: absolute;
  right: 4px;
  bottom: 4px;
  font-size: 10px;
  color: #878C93;
`;

export const IndexColumnsField = ({ label, stretch, input, meta, tableId, ...props }) => {
  const { fieldsForIndexing, loading } = useFieldsForIndexing(tableId);

  const [draggedIndex, setDraggedIndex] = React.useState(null);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  const invalid = isInvalid(meta);

  const value = Array.isArray(input.value) ? input.value : [];

  const options = React.useMemo(() => R.pipe(
    R.map(({ name, displayName }) => ({ label: displayName || name, value: name })),
    R.reject((item) => {
      const field = getIndexFieldByName(fieldsForIndexing, item.value);

      return R.anyPass([
        tableFieldSelectors.isRelationField,
        tableFieldSelectors.isFileField,
        tableFieldSelectors.isSmartField,
        R.propEq('fieldType', FIELD_TYPE.JSON),
      ])(field) || R.any(R.propEq('name', item.value), value);
    }),
  )(fieldsForIndexing), [value, fieldsForIndexing]);

  const onSelectNewField = React.useCallback((name) => {
    input.onChange([...value, { name }]);
  }, [input, value]);

  const onDeleteField = React.useCallback((index) => {
    input.onChange(R.remove(index, 1, value));
  }, [input, value]);

  const onDragBegin = React.useCallback((index) => {
    setDraggedIndex(index);

    return {
      id: index,
      type: 'NODE',
    };
  }, [setDraggedIndex]);

  const onDragEnd = React.useCallback(() => {
    const item = value[draggedIndex];

    input.onChange(R.pipe(
      R.remove(draggedIndex, 1),
      R.insert(hoveredIndex, item),
    )(value));

    setDraggedIndex(null);
    setHoveredIndex(null);
  }, [input, value, draggedIndex, hoveredIndex, setDraggedIndex, setHoveredIndex]);

  return (
    <Form.Field label={ label } stretch={ stretch } input={ input } meta={ meta }>
      <IndexColumnsFieldTag invalid={ invalid }>
        { value.map((item, index) => (
          <React.Fragment key={ item.name }>
            <IndexColumnsFieldItem
              item={ item }
              fieldsForIndexing={ fieldsForIndexing }
              index={ index }
              onDeleteField={ onDeleteField }
              draggedIndex={ draggedIndex }
              hoveredIndex={ hoveredIndex }
              setDraggedIndex={ setDraggedIndex }
              setHoveredIndex={ setHoveredIndex }
              onDragEnd={ onDragEnd }
              onDragBegin={ onDragBegin }
            />
            { (index !== value.length + options.length - 1) && <IndexColumnsFieldPlus /> }
          </React.Fragment>
        )) }

        <Choose>
          <When condition={ loading }>
            <Select css={{ margin: 4 }} options={ options } stretch={ false } placeholder="Loading..." disabled />
          </When>
          <Otherwise>
            { options.length > 0 && <Select css={{ margin: 4 }} options={ options } onChange={ onSelectNewField } stretch={ false } /> }
          </Otherwise>
        </Choose>

        <IndexColumnsFieldCounterTag>{ value.length }/{ value.length + options.length }</IndexColumnsFieldCounterTag>
      </IndexColumnsFieldTag>
    </Form.Field>
  );
};
