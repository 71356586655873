//@flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { Form, Field } from '@8base-react/forms';
import { Dialog, Button, Link, Text, useModal, Column, Row, AsyncContent } from '@8base/boost';
import { css } from '@emotion/core';
import { i18n } from 'i18n';

import { BillingPlanRadioGroupField } from 'common/BillingPlanRadioGroupField';
import { mapRegionToEndPoint } from 'utils';

import { useBillingInfo } from '../providers/BillingProvider/useBillingInfo';
import { useWorkspace } from '../providers/WorkspaceProvider';

import { BankCardLastFour } from 'common/components';
import { UserPaymentDetailsUpdateDialog } from './BillingUpdateDialog/UserPaymentDetailsUpdateDialog';
import { UserProjectPaymentDetailsUpdateDialog } from './BillingUpdateDialog/UserProjectPaymentDetailsUpdateDialog';
import { useUserPaymentDetails, useProjectPlan } from 'graphql/hooks';

const BILLING_CHANGE_PLAN_DIALOG_ID = 'BILLING_CHANGE_PLAN_DIALOG_ID';

const getInfo = (args, workspace) => {
  const workspaceId = args.workspaceId || workspace.id;

  const plan = workspace && workspace.plan;
  const region = workspace && workspace.region;
  const newRegionsEndpoint = mapRegionToEndPoint(region);

  return { workspaceId, plan, region, newRegionsEndpoint };
};

const BillingChangePlanDialog = () => {
  const { closeModal, openModal, args = {}} = useModal(BILLING_CHANGE_PLAN_DIALOG_ID);
  const workspace = useWorkspace({ workspaceId: args.organization ? args.id : args.workspaceId });
  const { projectPlan } = useProjectPlan(args.id, args.apiHost);
  const project = args;
  const {
    plans,
    isFreePlanAvailable,
    setRegionEndpoint,
    regionEndpoint,
    planDataLoading,
  } = useBillingInfo();

  const { card, loading } = useUserPaymentDetails();
  const { plan: workspacePlan, newRegionsEndpoint } = getInfo(args, workspace);
  const plan = projectPlan ? { ...projectPlan.plan } : workspacePlan;

  useEffect(() => {
    if (!args.workspaceId) {
      return;
    }

    if (newRegionsEndpoint !== regionEndpoint) {
      setRegionEndpoint(newRegionsEndpoint);
    }
  }, [args.workspaceId, newRegionsEndpoint, regionEndpoint, setRegionEndpoint]);

  const onClose = useCallback(() => {
    closeModal(BILLING_CHANGE_PLAN_DIALOG_ID);
  }, [closeModal]);

  const onContinue = useCallback(({ planId }) => {
    const newPlan = R.find(R.propEq('id', planId))(plans);
    openModal(UserProjectPaymentDetailsUpdateDialog.ID, { plan: newPlan, workspace, project });

    onClose();
  }, [plans, openModal, workspace, project, onClose]);

  const openPaymentModal = useCallback(() => {
    openModal(UserPaymentDetailsUpdateDialog.ID, workspace, project);
  }, [openModal, project, workspace]);

  const initialValues = useMemo(() => ({
    planId: R.prop('price', plan) > 0 ? R.prop('id', plan) : (R.find(R.complement(R.propEq('price', 0)), plans) || {}).id,
  }), [plan, plans]);

  return (
    <Dialog id={ BILLING_CHANGE_PLAN_DIALOG_ID } onClose={ onClose } size="xl" { ...E2E.$props('dialogs.billingChangePlan.body') }>
      <Form onSubmit={ onContinue } initialValues={ initialValues }>
        { ({ handleSubmit, values }) => (
          <form onSubmit={ handleSubmit } css={{ overflow: 'auto' }}>
            <Dialog.Header title={ i18n.t('dialogs.billingChangePlan.title') } onClose={ onClose } />
            <Dialog.Body scrollable css={ css`min-height: 240px;` }>
              <AsyncContent loading={ loading || planDataLoading || newRegionsEndpoint !== regionEndpoint } stretch>
                <Column stretch>
                  <Field
                    name="planId"
                    component={ BillingPlanRadioGroupField }
                    isFreePlanAvailable={ isFreePlanAvailable }
                    card={ card }
                    plans={ plans }
                    style={{ padding: '10px' }}
                    { ...E2E.$props('dialogs.billingChangePlan.planRadio') }
                  />
                  <If condition={ !card }>
                    <Text color="LIGHT_TEXT_COLOR">
                      <Text weight="bold">No card on file.</Text>&nbsp;
                        In order to upgrade to a paid plan, you’ll need to&nbsp;
                      <Link onClick={ openPaymentModal }>update your payment details.</Link>
                    </Text>
                  </If>

                  <If condition={ !!card }>
                    <Row alignItems="center">
                      <BankCardLastFour card={ card } />
                      <Link onClick={ openPaymentModal }>update your payment details</Link>
                    </Row>
                  </If>

                </Column>
              </AsyncContent>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                type="button"
                color="neutral"
                variant="outlined"
                onClick={ onClose }
              >
                { i18n.t('shared.cancel') }
              </Button>
              <Button type="submit" disabled={ R.prop('id', plan) === values.planId } { ...E2E.$props('dialogs.billingChangePlan.continueBtn') }>
                { i18n.t('shared.continue') }
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

BillingChangePlanDialog.ID = BILLING_CHANGE_PLAN_DIALOG_ID;

export { BillingChangePlanDialog };
