import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button } from '@material-ui/core';
import {
  Language,
  FileCopyOutlined,
  ExpandMore,
  InsertDriveFileOutlined,
  CloudQueue,
  WallpaperOutlined,
  Check,
  Clear,
} from '@material-ui/icons';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Avatars, useModal, Link } from '@8base/boost';
import { useOnOpenWorkspace } from 'utils/hooks';
import interfaceDefaultLogo from 'images/interface-default-avatar.svg';
import { ReactComponent as IconCDN } from 'images/icons/icon_cdn.svg';
import { ReactComponent as IconFunctions } from 'images/icons/icon_functions.svg';
import { ReactComponent as IconCustomStates } from 'images/icons/icon_custom_states.svg';
import { ReactComponent as IconLayouts } from 'images/icons/icon_layouts.svg';
import { ReactComponent as IconResources } from 'images/icons/icon_resources.svg';
import UserInterfaceMembersDialog from 'dialogs/UserInterfaceMembersDialog';
import FrontEndDeleteDialog from 'dialogs/FrontEndDeleteDialog';
import { Trans } from 'utils/translate';
import { CONSTANTS_DEPLOYMENT_STATUS } from '../constats';
import { toast } from 'react-toastify';

const StyledCard = styled('div')`
  box-sizing: border-box;
  border: 1px solid #D1D7DD;
  border-radius: 8px;
  background-color: #F4F7F9;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.04);
`;

const StyledHeader = styled('div')`
  min-height: 97px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-color: #FFFFFF;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #D1D7DD;
  background-color: #F4F7F9;
  border-radius: 0 0 8px 8px;
  margin-top: 0 !important;
  padding-bottom: 2px;
  box-shadow: none;
  '&$expanded': {
    box-shadow: none;
  }
`;

const StyledTitle = styled('span')`
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 35px;
  cursor: pointer;
  & :hover{
    text-decoration: underline;
  }
`;

const StyledUrl = styled('div')`
  color: #0973F8;
  font-family: Poppins,serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledSummaryContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 200px;
  height: 32px;
  gap: 12px;
  border-top: 1px #D1D7DD solid;
  border-bottom: 1px #D1D7DD solid;
  margin-bottom: -1px;
  padding: 0 8px;
`;

const StyledIconAndName = styled('div')`
  display: flex;
  gap: 12px;
`;

const StyledVersion = styled('div')`
  display: flex;
  justify-content: space-between;
  height: 19px;
  color: #323C47;
  font-family: Poppins,serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
`;

const StyledVersionNumber = styled('div')`
  color: #7D828C;
`;

const cssContainerCards = css`
  width: 100%;
  gap: 0 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

// TODO: add projectId
export const FrontendProjectCard = ({ projectId, workspace, project, users, refetch, refetchFrontend }) => {
  const { openModal } = useModal();
  const onOpenWorkspaceClick = useOnOpenWorkspace(projectId);
  const usersCount = project?.projectUsers?.count || 0;
  const deployment = workspace?.lastDeployABInfo?.statusDeploy || null;
  const version = workspace?.lastDeployABInfo?.versionDeploy || null;
  const summary = workspace?.summaryABInfo;
  const urlLastDeploy = workspace?.lastDeployABInfo?.urlDeploy;

  const handleCopyLinkClick = () => {
    if (deployment) {
      navigator.clipboard.writeText(urlLastDeploy).then(() => {
        toast.success('Copy successfully');
      });
    }
  };

  const [expanded, setExpanded] = React.useState(true);

  const onOpenWorkspace = (event) => {
    event.preventDefault();

    onOpenWorkspaceClick({ workspaceId: workspace.id || '' });
  };

  const onDeleteFrontEndHandler = (event) => {
    event.stopPropagation();
    openModal(FrontEndDeleteDialog.ID, {
      workspaceId: workspace?.id,
      workspaceName: workspace?.name,
      projectName: workspace?.name,
      apiHost: workspace.apiHost,
      kind: workspace.kind,
      onSuccess: async() => {
        refetch();
        refetchFrontend();
      },
    });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (!(event.target.tagName === 'A')) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  return (
    <StyledCard variant="outlined">
      <StyledHeader>
        <div style={{ display: 'flex', gap: 25 }}>
          <Avatar
            style={{ width: 56, height: 56, backgroundColor: '#ABB1B8', padding: workspace.image ? 0 : 10 }}
            alt="frontend logo"
            src={ workspace.image || interfaceDefaultLogo }
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledTitle onClick={ onOpenWorkspace }>{ workspace.name || 'invalid name' }</StyledTitle>
            { deployment !== null && (
              <StyledUrl>
                <a
                  style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  href={ urlLastDeploy || '' }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Language />
                  <div>
                    { urlLastDeploy || 'https://www.8base.com' }
                  </div>
                </a>
                <FileCopyOutlined onClick={ handleCopyLinkClick } />
              </StyledUrl>
            ) }

          </div>
        </div>

        <div style={{ display: 'flex', gap: 24 }}>
          <Avatars users={ users } size="sm" onPlusClick={ () =>
            openModal(UserInterfaceMembersDialog.ID,
              {
                projectId,
                workspace,
                users,
                usersCount,
              }) } />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={ onOpenWorkspace }
          >
            Open Frontend
          </Button>
        </div>
      </StyledHeader>

      <StyledAccordion expanded={ expanded } onChange={ handleChange(true) }>
        <AccordionSummary
          expandIcon={ <ExpandMore style={{ width: 20, height: 20 }} /> }
          style={{ backgroundColor: expanded ? '#FFFFFF' : '#F4F7F9', flexDirection: 'row-reverse', gap: 8 }}
          aria-controls="showInformation"
          id="showInformation"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            { deployment === CONSTANTS_DEPLOYMENT_STATUS.success && (
              <div style={{ display: 'flex', gap: 5 }}>
                <span>Latest deployment:
                  { deployment === CONSTANTS_DEPLOYMENT_STATUS.success &&
                    <Check style={{ color: '#06C27D', verticalAlign: 'middle' }} /> }
                  { deployment === CONSTANTS_DEPLOYMENT_STATUS.failed &&
                    <Clear style={{ color: 'red', verticalAlign: 'middle' }} /> }
                  by { `${workspace?.lastDeployABInfo?.userDeploy?.firstName} ${workspace?.lastDeployABInfo?.userDeploy?.lastName}` }
                </span>
              </div>
            ) }

            { deployment !== CONSTANTS_DEPLOYMENT_STATUS.success && (
              <div style={{ display: 'flex', gap: 5 }}>
                <span>No deployments yet</span>
              </div>
            ) }


            <StyledVersion>
              <Link style={{ marginRight: '20px' }} onClick={ (event) => onDeleteFrontEndHandler(event) }
                { ...E2E.$props('settings.billing.delete') }>
                <Trans i18nKey="settings.billing.plan.delete">
                   Delete
                </Trans>
              </Link>
              <StyledVersionNumber>
                Version:&nbsp;
              </StyledVersionNumber>
              { version || '0.0.0' }
            </StyledVersion>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: expanded ? '#FFFFFF' : '#F4F7F9', padding: '8px 54px 24px 54px' }}>
          <div css={ cssContainerCards }>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <InsertDriveFileOutlined />
                Pages
              </StyledIconAndName>
              { summary?.pages || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <IconResources style={{ height: 15, fill: '#475059' }} />
                Resources
              </StyledIconAndName>
              { summary?.resources || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <IconFunctions style={{ height: 15, fill: '#475059' }} />
                Functions
              </StyledIconAndName>
              { summary?.functions || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <WallpaperOutlined />
                Assets
              </StyledIconAndName>
              { summary?.assets || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <IconLayouts style={{ height: 15, fill: '#475059' }} />
                Layouts
              </StyledIconAndName>
              { summary?.layouts || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <CloudQueue />
                Requests
              </StyledIconAndName>
              { summary?.requests || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <IconCustomStates style={{ height: 15, fill: '#475059' }} />
                Custom States
              </StyledIconAndName>
              { summary?.customStates || 0 }
            </StyledSummaryContainer>
            <StyledSummaryContainer>
              <StyledIconAndName>
                <IconCDN style={{ height: 15, fill: '#475059' }} />
                CDN
              </StyledIconAndName>
              { summary?.cdn || 'No' }
            </StyledSummaryContainer>
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </StyledCard>
  );
};
