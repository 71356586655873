import gql from 'graphql-tag';

export const FILED_FOR_INDEXING_FRAGMENT = gql`
  fragment FieldForIndexing on SystemTableField {
    id
    name
    displayName
    fieldType
  }
`;
