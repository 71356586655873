// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Grid } from '@8base/boost';

import { Link as RouterLink } from 'common/router';
import { UserFlowLogo } from './UserFlowLogo';
import { APP_URL } from 'common/routing';

type UserFlowHeaderProps = {
  leftContent?: React$Node;
  rightContent?: React$Node;
  centerContent?: React$Node;
  position?: 'absolute' | 'relative';
  transparent?: boolean;
  size?: 'md' | 'lg';
};

const UserFlowHeaderTag = styled(Grid.Layout)`
  ${props => props.position === 'absolute' ? css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  ` : ''}
  ${props => props.size === 'lg' ? css`
    height: ${props.theme.SIZES.USER_FLOW.headerLgHeight};
    padding: 0 160px;
  ` : css`
    height: ${props.theme.SIZES.USER_FLOW.headerMdHeight};
    padding: 0 32px;
  `}

  ${props => {
  if (!props.transparent) {
    return css`
        background-color: #0A0A0A;
      `;
  }
}
}

  align-items: center;
  z-index: 3;

  @media (max-width: 767px) {
    padding: 0 2.4rem;
  }
`;

const UserFlowHeaderCenterContent = styled.div`
  justify-self: center;
`;

const UserFlowHeaderLeftContent = styled.div``;
const UserFlowHeaderRightContent = styled.div``;

const UserFlowHeader = ({ transparent, leftContent, rightContent, centerContent, position = 'absolute', size = 'lg', ...rest }: UserFlowHeaderProps) => {
  return (
    <UserFlowHeaderTag columns="auto auto 1fr auto" gap="lg" position={ position } transparent={ transparent } size={ size } { ...rest }>
      <RouterLink to={ APP_URL.developerHomeProjects }>
        <UserFlowLogo size={ size } { ...E2E.$props('root.header.logo') } />
      </RouterLink>
      <UserFlowHeaderLeftContent>
        { leftContent || null }
      </UserFlowHeaderLeftContent>
      <UserFlowHeaderCenterContent>
        { centerContent || null }
      </UserFlowHeaderCenterContent>
      <UserFlowHeaderRightContent>
        { rightContent || null }
      </UserFlowHeaderRightContent>
    </UserFlowHeaderTag>
  );
};


export { UserFlowHeader };
