// @flow

import gql from 'graphql-tag';

import { FILED_FOR_INDEXING_FRAGMENT } from '../fragments';

export const TABLE_FIELDS_FOR_INDEXING_QUERY = gql`
  query TableFieldsForIndexing($id: ID!) {
    system {
      table(id: $id) {
        fieldsForIndexing {
          ...FieldForIndexing
        }
      }
    }
  }

  ${FILED_FOR_INDEXING_FRAGMENT}
`;
