/* eslint-disable react/react-in-jsx-scope */

import { createContext, useContext, useState, useEffect } from 'react';

const ApiHostContext = createContext();

export const ApiHostProvider = ({ children }) => {

  const [apiHost, setApiHost] = useState(() => {
    return localStorage.getItem('apiHost') || '';
  });

  useEffect(() => {
    localStorage.setItem('apiHost', apiHost);
  }, [apiHost]);

  return (
    <ApiHostContext.Provider value={{ apiHost, setApiHost }}>
      { children }
    </ApiHostContext.Provider>
  );
};

export const useApiHost = () => useContext(ApiHostContext);
