// @flow
import React from 'react';
import { Row } from '@8base/boost';
import { Link as RouterLink } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Trans } from 'utils/translate';
import { UserFlow } from 'common/UserFlow';
import { Responsive, FancyButton } from 'common/components';
import { APP_URL, useBuildUrl } from 'common/routing';

import { AuthTextUnderline } from './AuthTextUnderline';

type AuthLoginLayoutProps = {
  children: React$Node;
  showSingupButton?: boolean,
  showLoginButton?: boolean,
};

const FormWrapper = styled.div`
  max-width: 580px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const AuthLoginLayout = ({
  children,
  showSingupButton = false,
  showLoginButton = false,
}: AuthLoginLayoutProps) => {
  const buildUrl = useBuildUrl();

  return (
    <UserFlow.Page>
      <UserFlow.Header
        position="relative"
        transparent
        rightContent={ (
          <Row gap="md" alignItems="center">
            <a
              href="https://8base-dev.atlassian.net/servicedesk/customer/portals"
              target="_blank"
              rel="noopener noreferrer"
            >
              <UserFlow.TextWhite kind="button">
                <Trans i18nKey="shared.help">Help</Trans>
              </UserFlow.TextWhite>
            </a>
            <If condition={ showSingupButton } >
              <RouterLink to={ buildUrl(APP_URL.authSignup) }>
                <FancyButton rounded uppercase>
                  <Trans i18nKey="shared.signUp">Sign Up</Trans>
                </FancyButton>
              </RouterLink>
            </If>
            <If condition={ showLoginButton } >
              <RouterLink to={ buildUrl(APP_URL.authLogin) }>
                <FancyButton rounded uppercase>
                  <Trans i18nKey="shared.logIn">Log In</Trans>
                </FancyButton>
              </RouterLink>
            </If>
          </Row>
        ) }
      />
      <UserFlow.PageContent>
        <UserFlow.PageTitle withoutOffset>
          <UserFlow.HeadingWhite type="h1">
            <Trans i18nKey="auth.layout.startBuildingSomething">Start building something <AuthTextUnderline>amazing!</AuthTextUnderline></Trans>
          </UserFlow.HeadingWhite>
          <Responsive.HideOn breakpoints={ ['xs', 'sm'] } css={ css`text-align: center;` }>
            <UserFlow.TextWhite kind="subtitle">
              <Trans i18nKey="auth.layout.notice">A suite of full-stack, low-code development tools is waiting for you.</Trans>
            </UserFlow.TextWhite>
          </Responsive.HideOn>
        </UserFlow.PageTitle>

        <UserFlow.FormContent>
          <FormWrapper>
            { children }
          </FormWrapper>
        </UserFlow.FormContent>
      </UserFlow.PageContent>
    </UserFlow.Page>
  );
};
