// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { css } from '@emotion/core';
import * as R from 'ramda';
import { Column, Row, Text, useModal, Link } from '@8base/boost';
import { Trans } from 'utils/translate';
import { BankCardRow } from './BankCardRow';
import { UserPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/UserPaymentDetailsUpdateDialog';
import { StyledText } from './StyledComponents';
import { Card, CardContent } from '@material-ui/core';

type BillingPaymentDetailsProps = {
  card?: any,
  refetch: ()=> boolean,
  apiHost: string
}

export const BillingPaymentDetails = ({ card, apiHost, refetch }: BillingPaymentDetailsProps) => {
  const { openModal } = useModal();

  const updateCardModal = () => openModal(UserPaymentDetailsUpdateDialog.ID,
    {
      apiHost,
      onSuccess: () => { refetch(); },
    },
  );

  return (
    <Card variant="outlined" css={ css`margin-top: 23.0px;` }>
      <CardContent style={{ padding: 24 }}>
        <Row >
          <Column>
            <Text css={ css`font-size: 14px; font-weight: 600; margin-bottom: 0 !important;` }>
              <Trans i18nKey="project.billing.paymentDetails.title">Payment Methods</Trans>
            </Text>
            <StyledText>
              <Trans i18nKey="project.billing.paymentDetails.note">
                This is an estimate of the amount you owe based on your current month-to-date usage after credits & prepayments.
              </Trans>
            </StyledText>
            <br />
            <If condition={ !card }>
              <Text color="LIGHT_TEXT_COLOR">
                <Text weight="bold">No card on file.</Text>&nbsp; In
                            order to upgrade to a paid plan, you’ll need
                            to&nbsp;
                <Link onClick={ updateCardModal }>
                              Update your payment details.
                </Link>
              </Text>
            </If>
            <If condition={ !!card }>
              <Row css={ css`border: 1px solid #D1D7DD; border-radius: 5px; padding: 4px;padding-left: 12px;padding-right: 12px; background-color: #F4F7F9; ` } >
                <BankCardRow card={ card } />
                <Text>
               ending on <span css={ css`font-weight: bold;` }>  { R.prop('last4', card) }</span> expiring on  <span css={ css`font-weight: bold;` }>{ card?.expMonth }/{ card?.expYear }</span>
                </Text>
                <Text
                  cursor="pointer"
                  onClick={ updateCardModal }
                  color="BLUE_30" weight="semi-bold"
                >
                  <Trans i18nKey="shared.update">
                  Update
                  </Trans>
                </Text>
              </Row>
            </If>
          </Column>
        </Row>
      </CardContent>
    </Card>
  );
};
