// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { Dialog, Grid, Column, Text, Button, Link, useModal } from '@8base/boost';
import { Trans } from 'utils/translate';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { APP_URL, useBuildUrl } from 'common/routing';
import * as LINKS from 'config/links';
import { getAppBuilderUrl } from 'utils/processEnv';

import logo from 'images/8base-logo.svg';

const INTEGRATED_AUTH_CONFIRMATION_DIALOG = 'INTEGRATED_AUTH_CONFIRMATION_DIALOG';

const StyledContarinerLogo = styled('div') `
  display: flex;
  align-items: center;
  margin-top:3%;
  margin-bottom: -1%;
`;

const StyledLogo = styled('img') `
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 992px) {
    height: 3.5rem
  }
`;

const StyledDescriptionText = styled(Text)`
  border-bottom: 1px solid;
  border-style: inset;
  padding-bottom: 15px;
  margin-top: -10px;
`;

const StyledInstructionsText = styled(Text)`
  padding-bottom: 3%;
`;

const StyledSeparator = styled('br')`
  display: block;
`;

const StyledGridBox = styled(Grid.Box)`
  margin-top: -7px;
  margin-bottom: -7px;
`;

export const IntegratedAuthConfirmationDialog = () => {
  const {
    closeModal,
    args: {
      kind,
      workspaceId,
    },
    isOpen,
  } = useModal(INTEGRATED_AUTH_CONFIRMATION_DIALOG);
  const onClose = () => closeModal(INTEGRATED_AUTH_CONFIRMATION_DIALOG);
  const buildUrl = useBuildUrl();
  const appBuilderUrl = getAppBuilderUrl();

  return (
    <Dialog isOpen={ isOpen } size="md" padding="xl" shouldCloseOnOverlayClick={ false }>
      <Choose>
        <When condition={ kind === 'project' }>
          <Dialog.Body>
            <Column gap="xl">
              <Grid.Layout gap="lg" stretch columns="400px" justifyContent="center">
                <StyledContarinerLogo>
                  <StyledLogo src={ logo } alt="8base logo" />
                </StyledContarinerLogo>
                <Text color="PRIMARY_TEXT_COLOR" align="center">
                  <Trans i18nKey="integratedAuthConfirmationDialog.headline">
                    <Text weight="semibold" css={{ wordBreak: 'break-all', fontSize: '2rem' }}>Welcome to your New Project!</Text>
                  </Trans>
                </Text>
                <StyledDescriptionText color="TEXT_COLOR" align="center">
                  <Trans i18nKey="integratedAuthConfirmationDialog.descriptionTop">
                    <Text color="TEXT_COLOR" css={{ wordBreak: 'break-all', color: '#7D828C' }}>Congratulations on creating your new project!</Text>
                  </Trans>
                  <StyledSeparator />
                  <Trans i18nKey="integratedAuthConfirmationDialog.descriptionBottom">
                    <Text color="TEXT_COLOR" css={{ wordBreak: 'break-all', color: '#7D828C' }}>You’re on your way to developing something great.</Text>
                  </Trans>
                </StyledDescriptionText>
                <StyledGridBox>
                  <StyledInstructionsText color="LIGHT_TEXT_COLOR" align="center">
                    <Trans i18nKey="integratedAuthConfirmationDialog.instructionsDocumentation">
                      <Text color="LIGHT_TEXT_COLOR" css={{ wordBreak: 'break-all' }}>View our documentation at </Text>
                    </Trans>
                    <Link tagName="a" href={ LINKS.TEMPLATE_INSTRUCTIONS } target="_blank" rel="noopener noreferrer">docs.8base.com</Link>
                  </StyledInstructionsText>
                  <Text color="LIGHT_TEXT_COLOR" align="center">
                    <Trans i18nKey="integratedAuthConfirmationDialog.instructionsCommunity">
                      <Text color="LIGHT_TEXT_COLOR" css={{ wordBreak: 'break-all' }}>Check out our Developer Community at </Text>
                    </Trans>
                    <Link tagName="a" href={ LINKS.DEVELOPER_COMMUNITY } target="_blank" rel="noopener noreferrer">community.8base.com</Link>
                  </Text>
                </StyledGridBox>
              </Grid.Layout>
            </Column>
          </Dialog.Body>
        </When>
        <When condition={ kind === 'frontend' }>
          <Dialog.Body>
            <Column gap="xl">
              <Grid.Layout gap="lg" stretch columns="400px" justifyContent="center">
                <StyledContarinerLogo>
                  <StyledLogo src={ logo } alt="8base logo" />
                  <div>
                    <IconButton aria-label="close" onClick={ onClose }>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </StyledContarinerLogo>
                <Text color="PRIMARY_TEXT_COLOR" align="center">
                  <Trans i18nKey="integratedAuthConfirmationDialog.frontendHeadline">
                    <Text weight="semibold" css={{ wordBreak: 'break-all', fontSize: '2rem' }}>Your new Frontend is Ready!</Text>
                  </Trans>
                </Text>
                <StyledDescriptionText color="TEXT_COLOR" align="center">
                  <Trans i18nKey="integratedAuthConfirmationDialog.descriptionFrontend">
                    <Text color="TEXT_COLOR" css={{ wordBreak: 'break-all', color: '#7D828C' }}>Click below to start building</Text>
                  </Trans>
                  <StyledSeparator />
                </StyledDescriptionText>
                <StyledGridBox>
                  <StyledInstructionsText color="LIGHT_TEXT_COLOR" align="center">
                    <Trans i18nKey="integratedAuthConfirmationDialog.instructionsDocumentation">
                      <Text color="LIGHT_TEXT_COLOR" css={{ wordBreak: 'break-all' }}>View our documentation at </Text>
                    </Trans>
                    <Link tagName="a" href={ LINKS.TEMPLATE_INSTRUCTIONS } target="_blank" rel="noopener noreferrer">docs.8base.com</Link>
                  </StyledInstructionsText>
                  <Text color="LIGHT_TEXT_COLOR" align="center">
                    <Trans i18nKey="integratedAuthConfirmationDialog.instructionsCommunity">
                      <Text color="LIGHT_TEXT_COLOR" css={{ wordBreak: 'break-all' }}>Check out our Developer Community at </Text>
                    </Trans>
                    <Link tagName="a" href={ LINKS.DEVELOPER_COMMUNITY } target="_blank" rel="noopener noreferrer">community.8base.com</Link>
                  </Text>
                </StyledGridBox>
              </Grid.Layout>
            </Column>
          </Dialog.Body>
        </When>
      </Choose>
      <Dialog.Footer>
        <Button
          stretch
          color="primary"
          type="submit"
          onClick={ () => {
            if (kind === 'frontend') {
              const url = `${appBuilderUrl}${buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId }})}`;
              window.open(url, '_blank');
              onClose();
            } else {
              onClose();
            }
          } }
        >
          <Choose>
            <When condition={ kind === 'project' }>
              <Trans i18nKey="integratedAuthConfirmationDialog.startButon">Start Building</Trans>
            </When>
            <When condition={ kind === 'frontend' }>
              <Trans i18nKey="integratedAuthConfirmationDialog.startButonFrontend">Open Frontend</Trans>
            </When>
          </Choose>
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

IntegratedAuthConfirmationDialog.ID = INTEGRATED_AUTH_CONFIRMATION_DIALOG;
