// $FlowFixMe
import { DateTime } from 'luxon';
import { i18n } from 'i18n';
import * as R from 'ramda';

import type { InboxItemFragment } from 'graphql/__generated__';

import { INBOX_NOTIFICATION_KIND, INBOX_TYPE } from 'common/constants/inbox';
import DEFAULT_LOGO from 'images/workspace-default-logo.svg';
import APPBUILDER_LOGO from 'images/8base-appbuilder-logo.svg';

const formatDate = (date) => {
  if (date) {
    return DateTime.fromISO(date).setLocale('en').toLocaleString(DateTime.DATETIME_MED);
  }
  return '';
};

export const transformInviteInfo = (data: InboxItemFragment) => {
  const firstName = R.pathOr('', ['details', 'invitedBy', 'firstName'], data);
  const lastName = R.pathOr('', ['details', 'invitedBy', 'lastName'], data);

  const transformOgrInviteMessage = (firstName = '', lastName = '', organizationName) =>
    i18n.t('inbox.item.message.invite.org', {
      defaultValue: '{{userName}} has invited you to join their organization: {{organizationName}}',
      userName: `${firstName} ${lastName}`,
      organizationName,
    });

  const transformProjectInviteMessage = (firstName = '', lastName = '', projectName) =>
    i18n.t('inbox.item.message.invite.project', {
      defaultValue: '{{userName}} has invited you to join their project: {{projectName}}',
      userName: `${firstName} ${lastName}`,
      projectName,
    });

  const getTransformedMsg = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {

      case INBOX_TYPE.orgInvite: {
        const organizationName = R.pathOr('', ['details', 'organization', 'name'], inboxInfo);
        return transformOgrInviteMessage(firstName, lastName, organizationName);
      }
      case INBOX_TYPE.envInvite:
      case INBOX_TYPE.projectInvite: {
        const projectName = R.pathOr('', ['details', 'project', 'name'], inboxInfo);
        return transformProjectInviteMessage(firstName, lastName, projectName);
      }

      default: {
        return '';
      }
    }
  };

  const getEntityImage = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {
      case INBOX_TYPE.envInvite: {
        return R.pathOr(
          DEFAULT_LOGO,
          ['details', 'workspace', 'avatar', 'downloadUrl'],
          inboxInfo,
        );
      }

      case INBOX_TYPE.orgInvite: {
        return R.pathOr(
          DEFAULT_LOGO,
          ['details', 'organization', 'avatar', 'downloadUrl'],
          inboxInfo,
        );
      }

      default: {
        return null;
      }
    }
  };

  const getEntityName = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {

      case INBOX_TYPE.orgInvite: {
        return R.pathOr('', ['details', 'organization', 'name'], inboxInfo);
      }

      case INBOX_TYPE.envInvite:
      case INBOX_TYPE.projectInvite: {
        return R.pathOr('', ['details', 'project', 'name'], inboxInfo);
      }

      default: {
        return null;
      }
    }
  };

  const getEntityDetails = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {
      case INBOX_TYPE.envInvite: {
        return R.pathOr('', ['details', 'environmentName'], inboxInfo);
      }

      default: {
        return null;
      }
    }
  };


  const userImg = R.path(['details', 'invitedBy', 'avatar', 'downloadUrl'], data);
  const infoImg = getEntityImage(data);
  const infoText = getEntityName(data);
  const infoDetails = getEntityDetails(data);

  return {
    message: getTransformedMsg(data),
    date: formatDate(data.createdAt),
    userImg,
    infoImg,
    infoText,
    infoDetails,
    firstName,
    lastName,
  };
};

export const transformNotificationInfo = (
  (data: InboxItemFragment) => {
    const kind = R.path(['details', 'kind'], data);
    const getDefaultValues = () => {
      switch (kind) {
        case INBOX_NOTIFICATION_KIND.appBuilderBeta:
        {
          return {
            header: '8base invites you to join App Builder beta',
            body: 'Work in a powerful, frontend development environment through an in-browser low-code IDE',
            image: { src: APPBUILDER_LOGO },
          };
        }

        default:
        {
          return { header: '', body: '', image: null };
        }

      }
    };

    const defaultValues = getDefaultValues();

    const header = R.pathOr(
      defaultValues.header,
      ['details', 'details', 'header'],
      data,
    );
    const body = R.pathOr(
      defaultValues.body,
      ['details', 'details', 'body'],
      data,
    );
    const image = R.pathOr(
      defaultValues.image,
      ['details', 'details', 'image'],
      data,
    );

    const date = formatDate(data.createdAt);

    return { header, body, image, date };
  }
);
