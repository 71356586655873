import { useAuth } from '@8base-react/auth';

import { useTeamMember } from 'graphql/hooks';


export const RootContainer = ({ children }) => {
  const auth = useAuth();

  useTeamMember({
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: false,
    onCompleted: (teamMember) => {
      if (!!teamMember) {
        auth.authClient.setState({
          email: teamMember.email,
        });
      }
    },
  });

  return children;
};
