//@flow
// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Dialog, Button, useModal, Column, Text } from '@8base/boost';
import { useMutation } from 'react-apollo';
import { Form } from '@8base-react/forms';
import { toast } from 'react-toastify';

import { i18n } from 'i18n';
import { Trans } from 'utils/translate';
import { BILLING_PLAN_UPDATE_MUTATION } from 'graphql/mutations';
import { BILLING_CURRENT_PLAN_QUERY } from 'graphql/queries';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';

import { sendGoogleHit } from '../utils/sendGoogleHit';

const BILLING_CANCEL_SUBSCRIPTION_DIALOG_ID = 'BILLING_CANCEL_SUBSCRIPTION_DIALOG_ID';

const BillingCancelSubscriptionDialog = () => {
  const { closeModal } = useModal();

  const [planUpdate] = useMutation(BILLING_PLAN_UPDATE_MUTATION, {
    update: (cache, { data }) => {
      cache.writeQuery({ query: BILLING_CURRENT_PLAN_QUERY, data: {
        billingCurrentPlan: data.billingPlanUpdate,
      }});
    },
    onCompleted: (data) => {
      if (!!data && !!data.billingPlanUpdate) {
        sendGoogleHit({
          planId: data.billingPlanUpdate.id,
          revenueAmount: data.billingPlanUpdate.price,
        });
      }
    },
  });

  const { plans, refetchPlansData } = useBillingInfo();

  const freePlanId = R.pipe(
    R.find(R.propEq('price', 0)),
    R.prop('id'),
  )(plans);

  const onClose = useCallback(() => {
    closeModal(BILLING_CANCEL_SUBSCRIPTION_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async () => {
    await planUpdate({ variables: { data: { planId: freePlanId }}});

    await refetchPlansData();

    toast.success(i18n.t('dialogs.cancelSubscription.successNotification'));

    onClose();
  }, [planUpdate, freePlanId, onClose, refetchPlansData]);

  return (
    <Dialog id={ BILLING_CANCEL_SUBSCRIPTION_DIALOG_ID } onClose={ onClose } { ...E2E.$props('dialogs.cancelSubscription.body') }>
      <Form onSubmit={ onSubmit }>
        { ({ handleSubmit, submitting }) => (
          <form onSubmit={ handleSubmit }>
            <Dialog.Header title={ i18n.t('dialogs.cancelSubscription.title') } onClose={ onClose } />
            <Dialog.Body scrollable>
              <Column gap="lg">
                <Text>
                  <Trans i18nKey="dialogs.cancelSubscription.note">
                      Are you sure you want to cancel subscription for the current workspace?
                  </Trans>
                </Text>
              </Column>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                type="button"
                color="neutral"
                variant="outlined"
                onClick={ onClose }
              >
                No, Do not Cancel
              </Button>
              <Button type="submit" color="danger" loading={ submitting }>
                Cancel Subscription
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

BillingCancelSubscriptionDialog.ID = BILLING_CANCEL_SUBSCRIPTION_DIALOG_ID;

export { BillingCancelSubscriptionDialog };
