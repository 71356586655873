// @flow
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { APP_URL } from 'common/routing';

import { InviteContainer } from './routes/root';
import { InviteExpired } from './routes/expired';
import { InviteIncorrect } from './routes/incorrect';
import { OrganizationInviteContainer } from './routes/organization';
import { ProjectInviteContainer } from './routes/project';

const Invite = () => (
  <Switch>
    <Route exact path={ APP_URL.invite } component={ InviteContainer } />
    <Route exact path={ APP_URL.inviteOrganization } component={ OrganizationInviteContainer } />
    <Route exact path={ APP_URL.inviteProject } component={ ProjectInviteContainer } />
    <Route path={ APP_URL.inviteExpired } component={ InviteExpired } />
    <Route path={ APP_URL.inviteIncorrect } component={ InviteIncorrect } />
    <Redirect exact from={ APP_URL.invite } to={ APP_URL.invite } />
  </Switch>
);

export { Invite };
