import {
  SET_WORKSPACE_CREATED,
  SET_AUTH,
} from './types';

function appStateReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_WORKSPACE_CREATED: {
      return {
        ...state,
        workspaceCreated: payload,
      };
    }
    case SET_AUTH: {
      return {
        ...state,
        auth: {
          ...state.auth,
          ...payload,
        },
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export default appStateReducer;
