import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { AsyncContent, useModal, Column, Text, NoData } from '@8base/boost';
import styled from '@emotion/styled';
import { FrontendProjectCard } from './FrontendProjectCard';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { Trans } from 'utils/translate';
import { IntegratedAuthConfirmationDialog } from 'dialogs/IntegratedAuthConfirmationDialog';
import * as R from 'ramda';
import { ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { mapRegionToEndPoint } from 'utils';
import { useLazyQuery } from 'react-apollo';
import { TEAM_MEMBERS_LIST_QUERY } from '../../../../../graphql/queries';
import { WORKSPACE_ID } from '../../../../../common/constants/apolloOperationContextOptions';

const StyledFrontendsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const EmptyFrontendsContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DividerNav = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.COLORS.GRAY_20};
`;

const StyledCardContentText = styled(Text)`
  font-size: 14px !important;
  line-height: 22px;
  justify-self:flex-start;
  color:  ${props => props.theme.COLORS.GRAY_50}
`;

export const UIPage = ({ frontendWorkspaces, projectId, project, organizationId, loading, refetch, refetchFrontend }) => {
  const { openModal } = useModal();
  const { openModal: openAuthModal } = useModal(IntegratedAuthConfirmationDialog.ID);
  const authProfiles = R.path(['authenticationInfo'], project);
  const region = mapRegionToEndPoint(R.path(['region'], project));
  const backendWorkspaceId = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'id'], project);
  const apiHost = R.pathOr(null, ['backendWorkspaces', 'items', 0, 'apiHost'], project);
  const [members, setMembers] = useState([]);

  const [getUsersPerEnvironment] = useLazyQuery(
    TEAM_MEMBERS_LIST_QUERY, {
      fetchPolicy: 'network-only',
      [WORKSPACE_ID]: backendWorkspaceId,
      onCompleted: (data) => {
        setMembers(data?.system?.environmentMembersList?.items || []);
      },
    },
  );

  useEffect(
    () => {
      if (!backendWorkspaceId) { return; }
      setTimeout(() => { getUsersPerEnvironment({ context: { [WORKSPACE_ID]: backendWorkspaceId, [ENDPOINT_URI]: apiHost }}); }, 100);
    }, [getUsersPerEnvironment, backendWorkspaceId, apiHost],
  );

  const onCreateNewWorkspace = () => {

    openModal(WorkspaceCreateDialog.ID,
      {
        organizationId,
        projectId,
        kind: WORKSPACE_KIND.frontend,
        authProfiles,
        region,
        apiHost,
        onSuccess: async() => {
          refetch();
          refetchFrontend();
          openAuthModal(IntegratedAuthConfirmationDialog.ID, { kind: WORKSPACE_KIND.frontend });
        },
      },
    );
  };

  return (
    <AsyncContent loading={ loading } stretch>
      <If condition={ frontendWorkspaces.length > 0 }>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginBottom: 24, fontSize: 12, width: 'fit-content', fontWeight: 'bold' }}
          startIcon={ <Add /> }
          onClick={ onCreateNewWorkspace }
        >
        Add Frontend
        </Button>
      </If>
      <If condition={ frontendWorkspaces.length <= 0 }>
        <Column id="column" gap="md" justifyContent="center" alignItems="start">
          <StyledCardContentText>
            <Trans i18nKey="projects.dashboard.noFrontendLinked">There are no frontends linked to this project</Trans>
          </StyledCardContentText>
          <DividerNav />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ marginBottom: 24, fontSize: 12, width: 'fit-content', fontWeight: 'bold' }}
            startIcon={ <Add /> }
            onClick={ onCreateNewWorkspace }
          >
        Add Frontend
          </Button>
        </Column>
      </If>
      <Choose>
        <When condition={ frontendWorkspaces.length > 0 }>
          <StyledFrontendsContainer>
            { frontendWorkspaces && (
              frontendWorkspaces.map(frontendWorkspace =>
                (<FrontendProjectCard
                  key={ frontendWorkspace.id }
                  refetch={ refetch }
                  refetchFrontend={ refetchFrontend }
                  workspace={ frontendWorkspace }
                  projectId={ projectId }
                  project={ project }
                  users={ members }
                />),
              )
            ) }
          </StyledFrontendsContainer>
        </When>
        <Otherwise>
          <EmptyFrontendsContainer>
            <NoData />
          </EmptyFrontendsContainer>
        </Otherwise>
      </Choose>

    </AsyncContent>
  );
};
