import React, { createContext } from 'react';
import { CookieStorage } from './CookieStorage';
import { AUTH_KEY } from '../AuthenticationProvider';

const CookieStorageContext = createContext({ cookieStorage: null, getCookieStorage: null });

const CookieStorageProvider = ({ children }) => {
  const cookieStorage = new CookieStorage();

  const getCookieStorage = () => {
    const stringItem = cookieStorage.getItem(AUTH_KEY);
    return stringItem ? JSON.parse(stringItem) : {};
  };

  return (
    <CookieStorageContext.Provider
      value={{ cookieStorage, getCookieStorage }}
    >
      { children }
    </CookieStorageContext.Provider>);
};

const withCookieStorage = (WrappedComponent) => {
  const cookieStorage = new CookieStorage();

  return (props) => (
    <WrappedComponent { ...props } cookieStorage={ cookieStorage } />
  );
};

export { CookieStorageProvider, CookieStorageContext, withCookieStorage };
