//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import { useModal } from '@8base/boost';
import * as R from 'ramda';
import { useMutation } from 'react-apollo';

import { useCiInstall, useUserPaymentDetails, useUserPaymentDetailsUpdate } from 'graphql/hooks';
import { BILLING_PLAN_UPDATE_MUTATION } from 'graphql/mutations';

import { BaseBillingUpdateDialog } from './components/BaseBillingUpdateDialog';
import { BILLING_CURRENT_PLAN_QUERY } from 'graphql/queries';
import { WORKSPACE_ID } from 'common/constants/apolloOperationContextOptions';
import { sendGoogleHit } from 'utils/sendGoogleHit';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';
import { useWorkspace } from 'providers';
import { updateWorkspaceCachedData } from 'utils/apollo/updateCache';
import { PaymentSubmitting } from './components/PaymentSubmitting';
import { PaymentSubmitSuccess } from './components/PaymentSubmitSuccess';
import { PaymentSubmitFailed } from './components/PaymentSubmitFailed';
import { PlanCard } from 'common/PlanCard';
import { i18n } from 'i18n';
// $FlowFixMe waiting for update Flow
import { useLocation } from 'react-router-dom';

const USER_PROJECT_BILLING_UPDATE_PAYMENT_DETAILS_ID = 'USER_PROJECT_BILLING_UPDATE_PAYMENT_DETAILS_ID';

/**
 * Update personal workspace payment method
 * used only with workspaces without organization
 */
const UserProjectPaymentDetailsUpdateDialog = () => {
  const { closeModal, args } = useModal(USER_PROJECT_BILLING_UPDATE_PAYMENT_DETAILS_ID);
  const { id: workspaceId, name, plan: currentPlan = {}} = useWorkspace({ workspaceId: args.workspace ? args.workspace.id : args.workspaceId });
  // const [prevRegionEndpoint, setPrevRegionEndpoint] = useState(mapRegionToEndPoint(region));
  // const [regionEndpoint, setCurrentRegionEndpoint] = useState(mapRegionToEndPoint(region));
  const [updateCardMutation] = useUserPaymentDetailsUpdate();
  const { card } = useUserPaymentDetails();
  const projectID = args.project ? args.project.id : '';
  const { pathname } = useLocation();
  const workspace = useWorkspace();
  const [ciInstall] = useCiInstall({ workspaceId: workspace.id });


  const [billingPlanUpdate] = useMutation(BILLING_PLAN_UPDATE_MUTATION, {
    context: {
      [WORKSPACE_ID]: workspaceId,
    },
    update: (cache, { data }) => {
      const workspaceNewData = {
        plan: data.billingPlanUpdate,
        nextPlan:
          data.billingPlanUpdate.nextPlan
            ? { ...data.billingPlanUpdate.nextPlan, __typename: 'BillingNextPlanResponse' }
            : undefined,
      };

      updateWorkspaceCachedData({ projectID, newData: workspaceNewData, cache });

      cache.writeQuery({ query: BILLING_CURRENT_PLAN_QUERY, data: {
        billingCurrentPlan: data.billingPlanUpdate,
      }});
    },
    onCompleted: (data) => {
      if (!!data && !!data.billingPlanUpdate) {
        sendGoogleHit({
          planId: data.billingPlanUpdate.id,
          revenueAmount: data.billingPlanUpdate.price,
        });

        FS.setUserVars({
          pricing_plan: data.billingPlanUpdate.displayName,
        });

        FS.event(FULLSTORY_EVENT_TYPES.changePricingPlan, {
          pricing_plan: data.billingPlanUpdate.displayName,
        });
      }
    },
  });


  const onSubmitSuccess = useCallback(async (token) => {
    const cardToken = R.prop('id', token);
    if (cardToken) {
      await updateCardMutation({ variables: { data: { cardToken }}});
    }

    const planId = R.path(['plan', 'id'], args);

    if (planId) {
      window.trackEvent('Billing', 'Upgrade Plan');

      await billingPlanUpdate({ variables: { data: { planId, projectID }}});
    }
    if (pathname.includes('workspace')) {
      await ciInstall();
    }
    closeModal(USER_PROJECT_BILLING_UPDATE_PAYMENT_DETAILS_ID);
  }, [args, pathname, closeModal, updateCardMutation, billingPlanUpdate, projectID, ciInstall]);

  const plan = R.prop('plan', args);

  return (
    <BaseBillingUpdateDialog
      dialogId={ USER_PROJECT_BILLING_UPDATE_PAYMENT_DETAILS_ID }
      onSubmitSuccess={ onSubmitSuccess }
      hideCardFields={ !!plan && !!card }
      title={ !plan ? i18n.t('billingUpdateDialog.updateTitle') : i18n.t('billingUpdateDialog.upgradeTitle') }
      beforeFieldsSlot={ (
        <If condition={ !!plan }>
          <div>
            Your are changing your plan to the following
            <PlanCard plan={ plan } workspaceName={ name } css={{ marginTop: 8 }} />
          </div>
        </If>
      ) }
      submittingSlot={ (
        <Choose>
          <When condition={ !!plan && currentPlan.price > plan.price }>
            <PaymentSubmitting type="downgrading" />
          </When>
          <When condition={ !!plan }>
            <PaymentSubmitting type="withNote" />
          </When>
          <Otherwise>
            <PaymentSubmitting type="simple" />
          </Otherwise>
        </Choose>
      ) }
      submitSuccessSlot={ (
        <Choose>
          <When condition={ !!plan && currentPlan.price > plan.price }>
            <PaymentSubmitSuccess type="downgrading" plan={ plan } />
          </When>
          <When condition={ !!plan }>
            <PaymentSubmitSuccess type="upgrading" plan={ plan } />
          </When>
          <Otherwise>
            <PaymentSubmitSuccess type="simple" plan={ plan } />
          </Otherwise>
        </Choose>
      ) }
      submitFailedSlot={ (
        <Choose>
          <When condition={ !!plan && currentPlan.price > plan.price }>
            <PaymentSubmitFailed type="downgrading" />
          </When>
          <When condition={ !!plan }>
            <PaymentSubmitFailed type="default" />
          </When>
          <Otherwise>
            <PaymentSubmitFailed type="simple" />
          </Otherwise>
        </Choose>
      ) }
    />
  );
};

UserProjectPaymentDetailsUpdateDialog.ID = USER_PROJECT_BILLING_UPDATE_PAYMENT_DETAILS_ID;

export { UserProjectPaymentDetailsUpdateDialog };
