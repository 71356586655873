// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';

import { HIDE_TOAST_ERROR_MESSAGE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { WORKSPACES_LIST_QUERY } from 'graphql/queries';
import { apolloWorkspacesListSelectors as workspacesListSelectors } from 'graphql/selectors';

import type { SystemWorkspaceListResponse, WorkspacesListQuery, WorkspacesListQueryVariables } from '../__generated__';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

export const useWorkspacesList = (apiHost?: string, options?: QueryHookOptions) => {

  const { isAuthorized } = useAuth();

  const { data, ...rest }: QueryResult<WorkspacesListQuery, WorkspacesListQueryVariables> = useQuery(WORKSPACES_LIST_QUERY, {
    skip: !isAuthorized,
    fetchPolicy: 'cache-first',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [ENDPOINT_URI]: apiHost,
    },
    ...options,
  });

  const workspacesList: SystemWorkspaceListResponse = workspacesListSelectors.getWorkspacesByKind(data, WORKSPACE_KIND.general);
  const workspacesFrontendList: SystemWorkspaceListResponse = workspacesListSelectors.getWorkspacesByKind(data, WORKSPACE_KIND.frontend);
  const allWorkspacesList: SystemWorkspaceListResponse = workspacesListSelectors.getWorkspacesByKind(data);

  return {
    ...rest,
    workspacesList,
    workspacesFrontendList,
    allWorkspacesList,
  };
};
