import React, { useCallback } from 'react';
import { css } from '@emotion/core';
import { Dialog, Button, Column, Text, Paragraph, InputField, useModal } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import { useApolloClient, useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { i18n } from 'i18n';

import { APP_URL } from 'common/routing';
import { LAST_WORKSPACE_ID } from 'common/constants/localStorageKeys';
import { WORKSPACE_ID, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { WORKSPACE_DELETE_MUTATION } from 'graphql/mutations';
import { environmentAccessor } from 'utils';
import { Trans } from 'utils/translate';
import { deleteWorkspaceFromCache } from 'utils/apollo/updateCache';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

const WORKSPACE_DELETE_DIALOG_ID = 'WORKSPACE_DELETE_DIALOG_ID';

const WorkspaceDeleteDialog = () => {
  const {
    closeModal,
    args: { workspaceId, workspaceName, withReload, apiHost, kind, shouldRedirect },
  } = useModal(WORKSPACE_DELETE_DIALOG_ID);

  const history = useHistory();

  const client = useApolloClient();

  const { refetchPlansData } = useBillingInfo();

  const [workspaceDelete, { loading }] = useMutation(WORKSPACE_DELETE_MUTATION, {
    context: {
      [WORKSPACE_ID]: kind === WORKSPACE_KIND.frontend ? undefined : workspaceId,
      [ENDPOINT_URI]: apiHost,
    },
    update: (cache) => {
      deleteWorkspaceFromCache({ workspaceId, cache });
    },
  });

  const onClose = useCallback(() => {
    closeModal(WORKSPACE_DELETE_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async () => {
    await workspaceDelete({ variables: { data: { id: workspaceId }}});

    onClose();

    environmentAccessor.removeEnvironment(workspaceId);

    window.trackEvent('Settings', 'Delete workspace');

    localStorage.removeItem(LAST_WORKSPACE_ID);

    if (shouldRedirect) {
      history.push(APP_URL.developerHome);
    }

    if (withReload) {
      await client.resetStore();
    }

    await refetchPlansData();
  }, [workspaceDelete, workspaceId, onClose, refetchPlansData, history, withReload, client, shouldRedirect]);

  const validate = useCallback((value) => value === workspaceName ? null : i18n.t('validation.valueNoMatch'), [workspaceName]);


  return (
    <Dialog
      id={ WORKSPACE_DELETE_DIALOG_ID }
      size="md"
      onClose={ onClose }
      shouldCloseOnOverlayClick={ !loading }
      shouldCloseOnEscPress={ !loading }
    >
      <Form onSubmit={ onSubmit }>
        { ({ handleSubmit, invalid, pristine, submitting }) => (
          <form onSubmit={ handleSubmit }>
            <Dialog.Header title={ i18n.t('settings.general.workspaceDeleteDialog.title') } onClose={ onClose } />
            <Dialog.Body>
              <Column gap="lg">
                <Paragraph css={ css`width: 100%;` } align="center">
                  <Trans i18nKey="settings.general.workspaceDeleteDialog.note">
                      You are deleting the workspace <Text weight="bold">{ workspaceName }</Text>.<br />
                      This action is irreversible and will delete all associated data, tables and functions.
                  </Trans>
                </Paragraph>
                <Field
                  name="name"
                  type="text"
                  placeholder="Type the workspace's name to delete it"
                  validate={ validate }
                  component={ InputField }
                  { ...E2E.$props('settings.general.workspaceDeleteDialog.nameInput') }
                />
              </Column>
            </Dialog.Body>
            <Dialog.Footer>
              <Button type="button" color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>Cancel</Button>
              <Button
                color="danger"
                type="submit"
                disabled={ invalid || pristine }
                loading={ submitting }
                { ...E2E.$props('settings.general.workspaceDeleteDialog.submitBtn') }
              >
                Delete
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

WorkspaceDeleteDialog.ID = WORKSPACE_DELETE_DIALOG_ID;

export { WorkspaceDeleteDialog };
