// @flow
import React from 'react';
import { Column, Table, Grid, Text, AsyncContent, Select } from '@8base/boost';
import styled from '@emotion/styled';
import { useQuery } from 'react-apollo';
import { GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY } from 'graphql/queries';
import { getEnvironmentRolesOptions } from 'utils';
import * as R from 'ramda';

type OrganizationTeamMemberProjectEnvironmentsEnvironmentsProps= {
  email: string,
  rowData: { id: string }
}

const EnvironmentsContainer = styled(Table.BodyCell)`
  grid-column: 1 / -1;
  padding: 12px 72px 24px 64px;
`;

const getAssignedRolesIds = (roles:Array<{id: string, name: string}>):Array<string> => {
  if (!roles) {
    return [];
  }
  return roles.map(({ id }) => id);
};

export const OrganizationTeamMemberProjectEnvironments = (
  { rowData, email }:OrganizationTeamMemberProjectEnvironmentsEnvironmentsProps,
) => {
  const { id: projectId } = rowData;
  const { data, loading } = useQuery(GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, {
    skip: !projectId || !email,
    variables: {
      projectId,
      workspaceId: '',
      email,
    },
  });

  const environmentsList = R.pathOr([], ['system', 'getEnvironmentRoles'], data);

  return (
    <EnvironmentsContainer bordered={ false }>
      <AsyncContent stretch loading={ loading }>
        <Column stretch >
          { environmentsList.map(({ environmentId, environmentName, roles, assignedRoles }) => {
            const roleOptions = getEnvironmentRolesOptions(roles);
            const assignedRolesIds = getAssignedRolesIds(assignedRoles);

            return (
              <Grid.Layout key={ environmentId } columns="160px 1fr" stretch>
                <Grid.Box justifyContent="center">
                  <Text kind="subtitle" color="GRAY_50">{ environmentName }</Text>
                </Grid.Box>
                <Grid.Box>
                  <Select
                    value={ assignedRolesIds }
                    options={ roleOptions }
                    multiple
                    disabled
                  />
                </Grid.Box>
              </Grid.Layout>);
          }) }
        </Column>
      </AsyncContent>
    </EnvironmentsContainer>
  );
};
