// @flow

export const APP_URL = {
  root: '/',
  mobile: '/mobile',
  workspace: '/workspace',
  workspaceHome: '/workspace/:workspaceId',

  archie: '/archie',
  archieLogin: '/archie/login',
  archieSignup: '/archie/signup',
  archieCallback: '/archie/callback',
  archieConfirm: '/archie/confirm',
  archieConfirmCallback: '/archie/confirm/callback',
  archiePasswordReset: '/archie/password-reset',

  auth: '/auth',
  authLogin: '/auth/login',
  authSignup: '/auth/signup',
  authCallback: '/auth/callback',
  authConfirm: '/auth/confirm',
  authConfirmCallback: '/auth/confirm/callback',
  authPasswordReset: '/auth/password-reset',
  authPasswordResetInvitationsSent: '/auth/password-reset/invitation-sent',
  authVerifyAccount: '/auth/verify-account/:token',

  appServices: '/workspace/:workspaceId/app-services',
  appServicesRoles: '/workspace/:workspaceId/app-services/roles',
  appServicesRolesEdit: '/workspace/:workspaceId/app-services/roles/:roleId',
  appServicesRolesEditData:
    '/workspace/:workspaceId/app-services/roles/:roleId/data',
  appServicesRolesEditApps:
    '/workspace/:workspaceId/app-services/roles/:roleId/apps',
  appServicesRolesEditUsers:
    '/workspace/:workspaceId/app-services/roles/:roleId/users',
  appServicesRolesEditTeam:
    '/workspace/:workspaceId/app-services/roles/:roleId/team',
  appServicesAuthentication:
    '/workspace/:workspaceId/app-services/authentication',
  appServicesStorageService:
    '/workspace/:workspaceId/app-services/storageservice',

  apiExplorer: '/workspace/:workspaceId/api-explorer',
  apiExplorerGraph: '/workspace/:workspaceId/api-explorer/graph',

  cli: '/cli',
  cliCallback: '/cli/callback',
  cliError: '/cli/error',
  cliSuccess: '/cli/success',

  developerHome: '/developer-home',
  developerHomeProjects: '/developer-home/projects',
  developerHomeWorkspaces: '/developer-home/workspaces',
  developerHomeWorkspacesFrontend: '/developer-home/workspaces/frontend',
  developerHomeWorkspacesProject: '/developer-home/workspaces/project',
  developerHomeOrganization: '/developer-home/organizations/:organizationId',
  developerHomeOrganizationWorkspacesFrontend:
    '/developer-home/organizations/:organizationId/workspaces/frontend',
  developerHomeOrganizationWorkspacesProject:
    '/developer-home/organizations/:organizationId/workspaces/project',
  developerHomeInbox: '/developer-home/inbox',
  developerHomeInboxDone: '/developer-home/inbox/done',
  developerHomeInboxUndone: '/developer-home/inbox/undone',

  organization: '/organization/:organizationId',
  organizationSettingsGeneral: '/organization/:organizationId/settings/general',
  organizationSettingsTeam: '/organization/:organizationId/settings/team',
  organizationSettingsBilling: '/organization/:organizationId/settings/billing',

  organizationSettingsTeamUser:
    '/organization/:organizationId/settings/team/user/:userId',

  project: '/project',
  projectHome: '/project/:projectId',
  projectDashboard: '/project/:projectId/dashboard',
  projectBackend: '/project/:projectId/backend',
  projectUI: '/project/:projectId/ui',
  projectTeam: '/project/:projectId/team',
  projectBilling: '/project/:projectId/billing',
  projectSettings: '/project/:projectId/settings',

  account: '/account',
  accountProfile: '/account/profile',
  accountProjects: '/account/projects',
  signInAndSecurity: '/account/sign-in-and-security',
  billing: '/account/billing',

  error: '/error',

  fileExplorer: '/file/:workspaceId/:fileId',

  invite: '/invite',
  inviteExpired: '/invite/expired',
  inviteIncorrect: '/invite/incorrect',
  inviteOrganization: '/invite/organization',
  inviteProject: '/invite/project',

  integrations: '/workspace/:workspaceId/integrations',
  integrationsNew: '/workspace/:workspaceId/integrations/new/:appType',
  integrationsEdit: '/workspace/:workspaceId/integrations/:id',

  data: '/workspace/:workspaceId/data/:tableId?/:type?/:fieldId?',
  dataRoot: '/workspace/:workspaceId/data',
  dataTable: '/workspace/:workspaceId/data/:tableId?',
  dataBuilder: '/workspace/:workspaceId/data/:tableId?/builder',
  dataBuilderNew: '/workspace/:workspaceId/data/:tableId?/builder/new',
  dataBuilderFile: '/workspace/:workspaceId/data/:tableId?/builder/:fieldId?',
  dataViewer: '/workspace/:workspaceId/data/:tableId?/viewer',
  dataSettings: '/workspace/:workspaceId/data/:tableId?/settings',

  functions: '/workspace/:workspaceId/functions',
  functionsFunctionTabs:
    '/workspace/:workspaceId/functions/:functionId/:tabName',
  functionsFunctionConfiguration:
    '/workspace/:workspaceId/functions/:functionId/configuration',
  functionsFunctionLogs: '/workspace/:workspaceId/functions/logs',

  onboarding: '/onboarding',
  onboardingBasicInformation: '/onboarding/basic-information',
  onboardingAdditionalInformation: '/onboarding/additional-information',

  users: '/workspace/:workspaceId/users',

  settings: '/workspace/:workspaceId/settings',
  settingsGeneral: '/workspace/:workspaceId/settings/general',
  settingsLocalization: '/workspace/:workspaceId/settings/localization',
  settingsTeam: '/workspace/:workspaceId/settings/team',
  settingsTeamInvitations: '/workspace/:workspaceId/settings/team/invitations',
  settingsEnvironmentVariables:
    '/workspace/:workspaceId/settings/environment-variables',
  settingsAuthentication: '/workspace/:workspaceId/settings/authentication',
  settingsApiTokens: '/workspace/:workspaceId/settings/api-tokens',
  settingsBilling: '/workspace/:workspaceId/settings/billing',

  referralAppbuilder: '/appbuilder',
};

export const ROUTES_MATCHES_WITHOUT_WORKSPACE = [
  'auth',
  'cli',
  'invite',
  'error',
  'onboarding',
  'developer-home',
  'account',
];

export const HOME_LINK = 'https://8base.com';
