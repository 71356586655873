import { useCallback } from 'react';

import { useProjectInfo, useProjectFrontend } from 'graphql/hooks';
import { APP_URL, useBuildUrl } from 'common/routing';
import { isPwa, processEnv } from 'utils';


export type OpenWorkspaceOptions = {
  workspaceId: string,
  openAsBackend?: boolean,
  urlKey?: 'workspaceHome' | 'settings',
}

export type OpenWorkspaceHandler = (options: $Exact<OpenWorkspaceOptions>) => void

export const useOnOpenWorkspace = (projectId = '') => {
  const buildUrl = useBuildUrl();
  const { projectInfo = {}} = useProjectInfo(projectId);
  const { projectFrontend } = useProjectFrontend(projectId);

  const onOpenWorkspaceClick: OpenWorkspaceHandler = useCallback(async ({
    workspaceId,
    openAsBackend = false,
    urlKey = 'workspaceHome',
  }) => {

    const workspace = projectInfo?.backendWorkspaces || [];
    const frontendWorkspace = projectFrontend?.frontendWorkspaces || [];

    const url = buildUrl(APP_URL[urlKey], { pathParams: { workspaceId }});
    const clientUrl = processEnv.getClientUrl();
    const appBuilderUrl = processEnv.getAppBuilderUrl();

    const openWorkspace = (path: string) => {
      if (isPwa()) {
        window.location.assign(path);
        return;
      }
      if (openAsBackend) {
        window.open(path, '_parent');
      } else {
        window.open(path, '_blank');
      }

    };

    if (frontendWorkspace) {
      if (openAsBackend) {
        openWorkspace(`${clientUrl}${url}`);
        return;
      }

      openWorkspace(`${appBuilderUrl}${url}`);
      return;
    }

    if (!workspace) {
      return;
    }

    openWorkspace(`${clientUrl}${url}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildUrl, projectInfo?.backendWorkspaces, projectFrontend?.frontendWorkspaces]);

  return onOpenWorkspaceClick;
};
