// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
// $FlowFixMe
import { matchPath, useLocation } from 'react-router-dom';

import { APP_URL, ROUTES_MATCHES_WITHOUT_WORKSPACE } from 'common/routing';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { useWorkspacesList } from 'graphql/hooks';
import { apolloWorkspacesListSelectors as workspacesListSelectors } from 'graphql/selectors';

import { useWorkspaceProxy } from '../WorkspaceProxyProvider/useWorkspaceProxy';
import { WorkspaceContext } from './WorkspaceContext';

type WorkspaceIdContextProps = {
  children: React$Node;
}

const getInfo = (location, { workspacesList, workspacesFrontendList }) => {
  const match = matchPath(location.pathname, { path: APP_URL.workspaceHome, exact: false, strict: false });

  const workspaceId = (match && match.params && match.params.workspaceId) || undefined;

  const mergedList = [...workspacesList.items, ...workspacesFrontendList.items];

  const workspace = mergedList.find(({ id }) => id === workspaceId) || {};

  return {
    workspace,
    workspaceId,
    workspaceApiUrl: workspace.apiHost,
    workspaceWebSocketUri: workspace.webSocket,
  };
};


export const WorkspaceProvider = ({ children } : WorkspaceIdContextProps) => {
  const location = useLocation();
  const { setApiUri, apiUri, webSocketUri, setWebSocketUri, setListLoaded } = useWorkspaceProxy();

  const { workspacesList, workspacesFrontendList } = useWorkspacesList('', {
    onCompleted: (completedData) => {
      const loadedWorkspacesList = workspacesListSelectors.getWorkspacesByKind(completedData, WORKSPACE_KIND.general);
      const loadedWorkspacesFrontendList = workspacesListSelectors.getWorkspacesByKind(completedData, WORKSPACE_KIND.frontend);

      const { workspaceApiUrl, workspaceWebSocketUri } = getInfo(location, {
        workspacesList: loadedWorkspacesList,
        workspacesFrontendList: loadedWorkspacesFrontendList,
      });

      if (workspaceApiUrl && workspaceApiUrl !== apiUri) {
        setApiUri(workspaceApiUrl);
      }

      if (workspaceWebSocketUri && workspaceWebSocketUri !== webSocketUri) {
        setWebSocketUri(workspaceWebSocketUri);
      }

      setListLoaded(true);
    },
  });

  const { workspace, workspaceId } = useMemo(() =>
    getInfo(location, { workspacesList, workspacesFrontendList })
  , [location, workspacesFrontendList, workspacesList]);

  return (
    <WorkspaceContext.Provider
      value={{
        workspacesList,
        workspace: {
          ...workspace,
          id: !!ROUTES_MATCHES_WITHOUT_WORKSPACE.find(routeMatch => routeMatch === workspaceId) ? undefined : workspaceId,
          name: workspace.name,
          isOwner: process.env.REACT_APP_FORCE_OWNER === 'true' ? true : workspace.isOwner,
          isCiCdEnabled: !!workspace.isCiCdEnabled,
        },
      }}
    >
      { children }
    </WorkspaceContext.Provider>);
};

