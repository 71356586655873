import React, { useState, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import { Form, Field as _Field, FormSpy } from '@8base-react/forms';
import {
  Dialog,
  useModal,
  Row,
} from '@8base/boost';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';
import MuiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useAuth } from '@8base-react/auth';
import { toast } from 'react-toastify';

import { PROJECT_CREATE_MUTATION } from 'graphql/mutations';
import {
  CHECK_PROJECT_CREATION_QUERY,
  BILLING_PLANS_QUERY,
  WORKSPACES_LIST_QUERY,
} from 'graphql/queries';
import {
  ENDPOINT_URI,
  HIDE_TOAST_ERROR_MESSAGE,
  IGNORE_GRAPHQL_ERRORS,
  IGNORE_WORKSPACE,
} from 'common/constants/apolloOperationContextOptions';
import { required } from 'utils/formValidations';
import {
  getRegionNameByEndpoint,
} from 'common/constants/regions';
import {
  AUTH_PROJECT_KIND,
} from 'common/constants/authProjectKind';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';
import { ProjectLoader } from 'common/ProjectLoader';
import {
  useProjectsList,
  useOrganizationPaymentDetails,
  useUserPaymentDetails,
} from '../../graphql/hooks';
import { getServerUrl } from 'utils/processEnv';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { APP_URL, buildUrl } from '../../common';

import { CreateProjectForm } from './components/CreateProjectForm';

import { useUI, SET_DIALOG, DIALOGS_IDS } from 'providers/UIProvider';
import { CreateProjectPlanSelect } from './components/CreateProjectPlanSelect';
import { IntegratedAuthConfirmationDialog } from 'dialogs/IntegratedAuthConfirmationDialog';

const POLL_INTERVAL = 5000;

const CREATE_WORKSPACE_STATUS_SUCCESS = 'complete_success';
const CREATE_WORKSPACE_STATUS_ERROR = 'complete_error';

const { Footer: FooterBase } = Dialog;

const getCurrentCard = ({
  newCard,
  organizationCard,
  userCard,
  isOrganization,
}) => {
  if (isOrganization) {
    if (newCard) {
      return newCard.card;
    }

    if (organizationCard) {
      return organizationCard;
    }
    return null;
  }

  return userCard;
};

const getBillingPlansVars = ({
  filterPlanProjects,
  organizationId,
  regionEndpoint,
}) => {
  return {
    filterPlanProjects,
    organizationId,
    region: getRegionNameByEndpoint(regionEndpoint),
  };
};

const Field = styled(_Field)`
  margin-bottom: 2px;
`;

const Footer = styled(FooterBase)`
  background-color: #ffffff;
  border-top: none;
  padding-bottom: 0;
  padding-left: 9px;
  padding-top: 10px;
  position: absolute;

  ${({ step }) => (step === 'plans' ? 'left: 24px; top: 24px;' : 'right: 24px; bottom: 32px;')};
`;
const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Stepper = styled(MuiStepper)`
  width: 260px;
  padding: 16px !important;

  .MuiSvgIcon-root {
    height: 24px;
    width: 24px;
  }

  .MuiStepConnector-root {
    flex: inherit !important;
    width: 16px;
  }

  .MuiStepConnector-line {
    border-color: #D1D7DD;
  }

  .MuiTypography-body2 {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: 600;
  }

  .MuiStepIcon-text {
    font-size: 14px;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #7D828C;
  }

  .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: 400;
  }
`;

const StepLabel = styled(MuiStepLabel)`
`;

const ButtonBase = withStyles({
  root: {
    backgroundColor: '#F4F8FA',
    height: '40px !important',
    width: '89px !important',
  },
  primary: {
    backgroundColor: '#0874F9',
  },
})(MuiButton);

const ModalTitle = styled.div`
  h1 {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: center;
  }

  button {
    background-color: transparent;
    position: absolute;
    right: 32px;
    top: 20px;

    &:hover {
      background-color: transparent;
    }
  }
`;

const RowBase = styled(Row)`
  .MuiButton-containedPrimary {
    background-color: #0874F9;
  }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    height: '662px',
    justifyContent: 'unset !important',
    overflow: 'hidden',
    padding: '25px',
    position: 'absolute',
    width: '1293px',
  },
}));

const ContainerModalStyled = styled.div`
  transition: height 0.5s ease-in-out;

  @media screen and (max-height: 768px) {
    height: ${({ isSubmitting, step }) => isSubmitting ? 'auto' : step === 'plans' ? '662px !important' : '700px'};
    width: 990px !important;
  }

`;

const stepsBase = {
  form: 'Details',
  plans: 'Plans',
};

const steps = Object.values(stepsBase);

const getSteps = (step) => {
  // return index of array steps
  return Object.keys(stepsBase).findIndex((item) => item === step);
};

let formRef = null;

const ProjectCreateDialog = () => {
  const classes = useStyles();
  const [newCardToken] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('form');
  const activeStep = getSteps(step);
  const { authState } = useAuth();
  const [, setIsAuth] = useState(false);
  const [, setAwaitWorkspaceId] = useState(null);
  const [awaitProjectId, setAwaitProjectId] = useState(null);
  const [, setAwaitWorkspaceFrontEndId] = useState(null);
  const [awaitProjectEndpoint, setProjectEndpoint] = useState(null);
  const [showAuthenticationOptions, setShowAuthenticationOptions] = useState(false);
  const [howToStartOptions, setHowToStartOptions] = useState(false);
  const [isBackend, setIsBackend] = useState(false);
  const { openModal: openAuthModal } = useModal(IntegratedAuthConfirmationDialog.ID);
  const [, setHowStartSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { dispatch, state } = useUI();
  const { dialog } = state;

  const {
    planDataLoading,
    regionEndpoint,
    setRegionEndpoint,
  } = useBillingInfo();
  const { refetch } = useProjectsList(dialog.organizationId);
  const history = useHistory();

  const handleClose = useCallback((event, reason) => {
    if (reason !== 'backdropClick') {
      formRef.reset();
      setStep('form');
      setHowToStartOptions(false);
      setShowAuthenticationOptions(false);
      dispatch({
        type: SET_DIALOG,
        payload: {
          isOpen: false,
          title: '',
          message: '',
          type: null,
        },
      });
    }
  }, [dispatch]);

  const { card: userCard, loading: userCardLoading } = useUserPaymentDetails();
  const { card: organizationCard, loading: organizationCardLoading } =
    useOrganizationPaymentDetails(dialog.organizationId);

  const [fetchPlans, { data: plansData, loading: plansLoading }] = useLazyQuery(
    BILLING_PLANS_QUERY,
    {
      variables: getBillingPlansVars({
        filterPlanProjects: true,
        organizationId: dialog.organizationId,
        regionEndpoint,
      }),
      fetchPolicy: 'cache-and-network',
      context: {
        [IGNORE_WORKSPACE]: true,
        [ENDPOINT_URI]: regionEndpoint,
      },
    },
  );
  const [fetchWorkspaces] = useLazyQuery(WORKSPACES_LIST_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (dialog.isOpen) {
      fetchPlans();
    }
  }, [dialog.isOpen, fetchPlans]);

  const plans = R.pathOr(
    [],
    ['system', 'userBillingConfiguration', 'availablePlans'],
    plansData,
  );

  const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ ref } { ...props } />;
  });

  const [projectCreate] = useMutation(
    PROJECT_CREATE_MUTATION,
  );

  const onClose = useCallback(() => {
    setRegionEndpoint(getServerUrl());
    setIsSubmitting(false);
    setStep('form');
  }, [setRegionEndpoint]);

  const [awaitProjectCreation, setAwaitProjectCreation] = useState({
    kind: undefined,
  });

  const onCheckWorkspaceCreation = useCallback(
    async (completedData) => {
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      if (!completedData) {
        setAwaitWorkspaceId(null);
      }

      const status = R.path(
        ['system', 'asyncSessionStatus', 'status'],
        completedData,
      );
      const result = R.path(
        ['system', 'asyncSessionStatus', 'result'],
        completedData,
      );

      switch (status) {
        case CREATE_WORKSPACE_STATUS_SUCCESS: {
          const projectIdResult = result?.projectId;
          const redirectToProject = (projectId) => {
            const url = buildUrl(APP_URL.projectHome, {
              pathParams: { projectId },
              ignoreWorkspaceId: true,
            });
            history.push(url);
          };

          const onFinish = () => {
            fetchWorkspaces();
            setIsDone(true);
            sleep(2000).then(() => {
              setAwaitProjectId(null);
              setIsLoading(false);
              setIsDone(false);
              onClose();
              redirectToProject(projectIdResult);
              handleClose();
              openAuthModal(IntegratedAuthConfirmationDialog.ID, { kind: 'project' });
            });
          };

          if (refetch) {
            refetch().then(() => onFinish());
          } else {
            onFinish();
          }

          break;
        }

        case CREATE_WORKSPACE_STATUS_ERROR: {
          setIsDone(false);
          setIsLoading(false);
          setAwaitProjectId(null);
          setAwaitWorkspaceId(null);
          setAwaitWorkspaceFrontEndId(null);
          handleClose();

          toast.error(result || status);
          setIsSubmitting(false);
          setIsBackend(false);

          break;
        }

        default: {
          break;
        }
      }
    },
    [refetch, history, fetchWorkspaces, onClose, handleClose, openAuthModal],
  );

  useQuery(CHECK_PROJECT_CREATION_QUERY, {
    variables: {
      sessionId: awaitProjectId,
    },
    context: {
      [ENDPOINT_URI]: awaitProjectEndpoint,
      [IGNORE_GRAPHQL_ERRORS]: true,
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
    },
    skip: !awaitProjectId,
    fetchPolicy: 'network-only',
    pollInterval: awaitProjectEndpoint && awaitProjectId ? POLL_INTERVAL : 0,
    onCompleted: (data) => onCheckWorkspaceCreation(data),
  });

  const onSubmit = useCallback(
    async (data) => {
      const { organizationId } = state.dialog;
      setIsSubmitting(true);
      let sessionId = null;
      const chosenPlan =
        plans.find(({ id }) => data.billingPlanId === id) || {};

      if (data.billingPlanId === '8base') {
        data = {
          ...data,
          billingPlanId: null,
        };
      }
      if (data.authType !== AUTH_PROJECT_KIND.no_authentication) {
        setIsAuth(true);
      }

      const dataToSend = {
        organizationId: organizationId ? organizationId : '',
        ...R.omit(['address_country', 'cardHolder', 'city', 'state', 'street1', 'street2', 'zip'], data),
      };

      if (dataToSend.kind === 'backend') {
        delete dataToSend.authType;
      }

      setAwaitProjectCreation(
        (awaitProjectCreation) => awaitProjectCreation,
        (awaitProjectCreation.kind = dataToSend.kind),
      );

      setHowStartSelected(dataToSend.howStart);

      try {
        setIsLoading(true);
        ({
          data: {
            system: {
              projectCreate: { sessionId },
            },
          },
        } = await projectCreate({
          variables: { data: R.omit(['region'], dataToSend) },
          context: {
            [ENDPOINT_URI]: dataToSend.region,
            [IGNORE_WORKSPACE]: true,
          },
        }));

        setAwaitProjectId(sessionId);
        FS.event(FULLSTORY_EVENT_TYPES.createWorkspace, {
          pricing_plan: chosenPlan && chosenPlan.displayName,
          workspace_name: dataToSend.name,
          workspace_id: sessionId,
        });
      } catch (e) {
        setIsLoading(false);
        setStep('form');
        setIsSubmitting(false);

        throw e;
      } finally {
        setProjectEndpoint(dataToSend.region);
      }
    },
    [state.dialog, plans, awaitProjectCreation.kind, projectCreate],
  );

  const initialValues = {
    kind: 'fullstack',
    region: getServerUrl(),
    howStart: 'withAccelerators',
    authType: 'coded_login',
  };

  const currentCard = getCurrentCard({
    isOrganization: !!dialog.organizationId,
    newCard: newCardToken,
    organizationCard,
    userCard,
  });

  const goBackStep = () => {
    setStep('form');
  };

  let heightModal = '662px';
  if (step === 'plans' && !isSubmitting) {
    heightModal = '760px';
  } else if (isBackend && !isSubmitting) {
    heightModal = '480px';
  } else if (isSubmitting) {
    heightModal = '460px';
  } else {
    heightModal = '662px';
  }

  const body = (
    <ContainerModalStyled
      step={ step }
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '16',
        position: 'absolute',
        width: step === 'plans' && !isSubmitting ? '1293px' : '1040px',
        height: heightModal,
      }}
      className={ classes.paper }
      isSubmitting={ isSubmitting }
    >
      <ModalTitle>
        <If condition={ !isSubmitting }>
          <>
            <h1>{ step === 'form' ? 'Create a New Project' : 'Choose a Plan' }</h1>
            <StepperContainer>
              <Stepper activeStep={ activeStep }>
                { steps.map((label, index) => {
                  return (
                    <Step key={ `${label}-${index}` }>
                      <StepLabel>{ label }</StepLabel>
                    </Step>
                  );
                }) }
              </Stepper>
            </StepperContainer>
            <IconButton aria-label="close" onClick={ handleClose }>
              <CloseIcon />
            </IconButton>
          </>
        </If>
      </ModalTitle>


      <Form onSubmit={ onSubmit } initialValues={ initialValues } >
        { ({
          handleSubmit,
          invalid,
          submitting,
          pristine,
          values,
          dirtySinceLastSubmit,
          form,
        }) => {
          formRef = form;

          if (values.kind === 'backend') {
            setIsBackend(true);
          } else {
            setIsBackend(false);
          }

          if (isLoading) {
            let template = null;
            if (values.kind === 'backend') {
              template = 'BACKEND_NO_AUTH';
            } else {
              template = 'ALL_FEATURES';
            }

            return (
              <div style={{ padding: '80px 0' }}>
                <ProjectLoader isDone={ isDone } name={ values.name } template={ template } />
              </div>
            );
          }

          return (
            <form
              onSubmit={ handleSubmit }
              { ...E2E.$props('dialogs.projectCreate.form') }
              style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}
            >
              <FormSpy
                onChange={ (args) => {
                  const newRegion = R.path(['values', 'region'], args);
                  const oldRegion = values.region;
                  if (newRegion !== oldRegion) {
                    setRegionEndpoint(newRegion);
                    form.change('billingPlanId', null);
                  }

                  if (values.kind === 'backend') {
                    form.change('howStart', 'withAccelerators');
                    setHowToStartOptions(false);
                    setShowAuthenticationOptions(false);
                  }

                  if (args.values.howStart === 'fromScratch' && args.values.kind === 'fullstack') {
                    setShowAuthenticationOptions(true);
                    setHowToStartOptions(true);
                  } else {
                    setHowToStartOptions(false);
                    setShowAuthenticationOptions(false);
                  }
                } }
              />
              { step === 'form' && (
                <CreateProjectForm
                  organizationCardLoading={ organizationCardLoading }
                  organizationId={ dialog.organizationId }
                  userCardLoading={ userCardLoading }
                  planDataLoading={ planDataLoading }
                  plansLoading={ plansLoading }
                  currentCard={ currentCard }
                  regionEndpoint={ regionEndpoint }
                  setRegionEndpoint={ setRegionEndpoint }
                  plans={ plans }
                  authState={ authState }
                  howToStartOptions={ howToStartOptions }
                  showAuthenticationOptions={ showAuthenticationOptions }
                  isBackendOnly={ values.kind === 'backend' }
                  howStartSelected={ values.howStart }
                  kindSelected={ values.kind }
                />
              ) }
              { step === 'plans' && (
                <Field
                  name="billingPlanId"
                  validate={ required }
                  component={ CreateProjectPlanSelect }
                  plans={ plans }
                  organizationCardLoading={ organizationCardLoading }
                  userCardLoading={ userCardLoading }
                  planDataLoading={ planDataLoading }
                  plansLoading={ plansLoading }
                  isFormInvalid={ invalid }
                  onSubmit={ form.submit }
                  currentCard={ currentCard }
                />
              ) }
              <Footer step={ step }>
                <RowBase
                  alignItems="center"
                  justifyContent={ step === 'form' ? 'end' : 'between' }
                  stretch
                >
                  <If condition={ step !== 'form' }>
                    <ButtonBase
                      type="button"
                      onClick={ () => {
                        goBackStep();
                      } }
                    >
                      <ArrowBackIcon fontSize="large" />
                      Back
                    </ButtonBase>
                  </If>
                  <Row>
                    <If condition={ step === 'form' }>
                      <ButtonBase type="button" onClick={ () => {
                        form.reset();
                        handleClose();
                      } }
                      >
                        Cancel
                      </ButtonBase>
                      <ButtonBase
                        type="button"
                        disabled={ pristine ||
                          (invalid && !dirtySinceLastSubmit) ||
                          userCardLoading ||
                          organizationCardLoading }
                        onClick={ () => setStep('plans') }
                        variant="contained"
                        color="primary"
                      >
                        Next
                      </ButtonBase>
                    </If>
                  </Row>
                </RowBase>
              </Footer>
            </form>
          );
        } }
      </Form>
    </ContainerModalStyled>
  );

  return (
    <div style={{ position: 'relative' }}>
      <Modal
        open={ dialog.isOpen && dialog.type === DIALOGS_IDS.PROJECT_CREATE }
        TransitionComponent={ Transition }
        onClose={ handleClose }
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        { body }
      </Modal>
    </div>
  );
};

export { ProjectCreateDialog };
